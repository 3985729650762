/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SwapTrackingIdForShipmentIdResponse
 */
export interface SwapTrackingIdForShipmentIdResponse {
    /**
     * 
     * @type {string}
     * @memberof SwapTrackingIdForShipmentIdResponse
     */
    shipmentId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SwapTrackingIdForShipmentIdResponse
     */
    hasAccess: boolean;
}

/**
 * Check if a given object implements the SwapTrackingIdForShipmentIdResponse interface.
 */
export function instanceOfSwapTrackingIdForShipmentIdResponse(value: object): boolean {
    if (!('hasAccess' in value)) return false;
    return true;
}

export function SwapTrackingIdForShipmentIdResponseFromJSON(json: any): SwapTrackingIdForShipmentIdResponse {
    return SwapTrackingIdForShipmentIdResponseFromJSONTyped(json, false);
}

export function SwapTrackingIdForShipmentIdResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SwapTrackingIdForShipmentIdResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentId': json['shipmentId'] == null ? undefined : json['shipmentId'],
        'hasAccess': json['hasAccess'],
    };
}

export function SwapTrackingIdForShipmentIdResponseToJSON(value?: SwapTrackingIdForShipmentIdResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentId': value['shipmentId'],
        'hasAccess': value['hasAccess'],
    };
}

