import Logo from 'Cargo/Icons/Logo';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Stack from 'Cargo/Layout/Stack';
import { isDemo } from 'environment';
import React from 'react';
import styled from 'styled-components/macro';

const DemoText = styled.div`
    font-weight: var(--nhu-font-weight-light);
    font-size: 12px;
    color: var(--freightsimple-color-light-text);
    position: relative;
    top: -8px;
`;

export function NavLeftSide() {
    const showDemoText = isDemo();

    return (
        <HorizontalStack>
            <Stack align="right">
                <Logo></Logo>
                {showDemoText && <DemoText>Demo</DemoText>}
            </Stack>
        </HorizontalStack>
    );
}
