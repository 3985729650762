/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckIfAddressIsResidentialResponse
 */
export interface CheckIfAddressIsResidentialResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CheckIfAddressIsResidentialResponse
     */
    isResidential: boolean;
}

/**
 * Check if a given object implements the CheckIfAddressIsResidentialResponse interface.
 */
export function instanceOfCheckIfAddressIsResidentialResponse(value: object): boolean {
    if (!('isResidential' in value)) return false;
    return true;
}

export function CheckIfAddressIsResidentialResponseFromJSON(json: any): CheckIfAddressIsResidentialResponse {
    return CheckIfAddressIsResidentialResponseFromJSONTyped(json, false);
}

export function CheckIfAddressIsResidentialResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckIfAddressIsResidentialResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'isResidential': json['isResidential'],
    };
}

export function CheckIfAddressIsResidentialResponseToJSON(value?: CheckIfAddressIsResidentialResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isResidential': value['isResidential'],
    };
}

