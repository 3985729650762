import {
    configureStore,
    getDefaultMiddleware,
    ThunkAction,
} from '@reduxjs/toolkit';
import { reactionsSlice } from 'Cargo/Hooks/Reaction';
import { modalsSlice } from 'Cargo/Modal/modalsSlice';
import {
    authenticationSlice,
    writeAuthenticationSliceToLocalStorage,
} from 'Features/Authentication/Slices/authenticationSlice';
import { bookShipmentSlice } from 'Features/BookShipment/Slices/bookShipmentSlice';
import {
    nmfcDescriptionCacheSlice,
    writeNmfcDescriptionCacheSliceToLocalStorage,
} from 'Features/LineItems/Slices/nmfcDescriptionCacheSlice';
import LogRocket from 'logrocket';
import { Action, combineReducers } from 'redux';
import { couponSlice } from './Features/Coupons/Slices/couponSlice';

const rootReducer = combineReducers({
    authentication: authenticationSlice.reducer,
    modals: modalsSlice.reducer,
    reactions: reactionsSlice.reducer,
    bookShipment: bookShipmentSlice.reducer,
    nmfcDesciptionCache: nmfcDescriptionCacheSlice.reducer,
    coupon: couponSlice.reducer,
});

const middleware = getDefaultMiddleware();

if (
    process.env.REACT_APP_FS_ENVIRONMENT === 'preprod' ||
    process.env.REACT_APP_FS_ENVIRONMENT === 'demo' ||
    process.env.REACT_APP_FS_ENVIRONMENT === 'production'
) {
    middleware.push(LogRocket.reduxMiddleware());
}

export const store = configureStore({
    reducer: rootReducer,

    // LogRocket should go last
    middleware: middleware,
});

store.subscribe(() => {
    const state = store.getState();

    writeAuthenticationSliceToLocalStorage(state.authentication);
    writeNmfcDescriptionCacheSliceToLocalStorage(state.nmfcDesciptionCache);
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export function clearSlices() {
    localStorage.clear();
}
