import Colors from 'Cargo/Colors';
import Banner, { BannerStyle } from 'Cargo/Controls/Banner';
import Button from 'Cargo/Controls/Button';
import Icon from 'Cargo/Icons/Icon';
import { UUID } from 'Cargo/Types/types';

import { useUploadCustomsDocsModal } from './UploadCustomsDocsModal';

interface CustomsDocsSectionForTrackingProps {
    trackingId: UUID;
    onUploaded: () => void;
}

export function CustomsDocsSectionForTracking(
    props: CustomsDocsSectionForTrackingProps
) {
    const showUploadModal = useUploadCustomsDocsModal(props.trackingId);

    async function onUpload() {
        const uploaded = await showUploadModal();

        if (uploaded) {
            await props.onUploaded();
        }
    }

    return (
        <div style={{ marginBottom: '16px', width: '100%' }}>
            <Banner
                bannerStyle={BannerStyle.Warning}
                title="Missing Customs Documents"
                message="Without customs documents, shipments can be delayed at pickup and in-transit."
                right={
                    <Button onClick={onUpload}>
                        <Icon
                            name="file-pdf"
                            color={Colors.White}
                            size={16}
                            solid
                            style={{ marginRight: '4px' }}
                        />{' '}
                        Upload PDF
                    </Button>
                }
            />
        </div>
    );
}
