import Colors from 'Cargo/Colors';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import React from 'react';
import styled from 'styled-components/macro';

const Link = styled.a`
    &:hover {
        text-decoration: none;
    }
`;

const LogoGlyph = function () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="0">
                <g>
                    <path fill={Colors.Blue} d="M0 0H24V8H0z"></path>
                    <path fill={Colors.Blue} d="M0 8H16V16H0z"></path>
                    <path fill="#FACC0B" d="M8 16H24V24H8z"></path>
                    <path fill={Colors.Blue} d="M0 16H8V24H0z"></path>
                    <path fill="#FACC0B" d="M16 8H24V16H16z"></path>
                </g>
            </g>
        </svg>
    );
};

const LogoText1 = styled.span`
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 24px;

    color: #383838;
`;

const LogoText2 = styled.span`
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 24px;

    color: #606060;
`;

const Logo: React.FC = () => {
    return (
        <Link href="https://www.freightsimple.com">
            <HorizontalStack verticalAlign="middle">
                {LogoGlyph()}
                <Spacer width={9} />
                <LogoText1>freight</LogoText1>
                <LogoText2>simple</LogoText2>
            </HorizontalStack>
        </Link>
    );
};
export default Logo;
