/* eslint-disable local-rules/no-relative-imports */
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AppRouter from 'AppRouter';
import { getStripeKey } from 'environment';
import { initializeAnalytics } from 'Integrations/analytics';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import './setup/setup';
import { store } from './store';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(getStripeKey());

initializeAnalytics();

ReactDOM.render(
    <Provider store={store}>
        <Elements stripe={stripePromise}>
            <CookiesProvider>
                <BrowserRouter>
                    <AppRouter />
                </BrowserRouter>
            </CookiesProvider>
        </Elements>
    </Provider>,
    document.getElementById('fs-root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// expose store when run in Cypress
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (window.Cypress) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.store = store;
}
