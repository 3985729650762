import TabBar from 'Cargo/Controls/TabBar';
import React from 'react';

export enum DangerousGoodsTabs {
    EnterDetails,
    Lookup,
}

interface DangerousGoodsTabBar {
    selectedTab: DangerousGoodsTabs;
    onTabSelected: (newTab: DangerousGoodsTabs) => void;
}

export function DangerousGoodsTabBar(props: DangerousGoodsTabBar) {
    const tabs = [
        {
            type: DangerousGoodsTabs.EnterDetails,
            title: 'Enter UN Number',
        },
        {
            type: DangerousGoodsTabs.Lookup,
            title: 'Lookup From Description',
        },
    ];

    return (
        <TabBar<DangerousGoodsTabs>
            selectedTab={props.selectedTab}
            onTabSelected={props.onTabSelected}
            tabs={tabs}
            showFilter={false}
            filter=""
            onFilterChange={() => {}}
        />
    );
}
