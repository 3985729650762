import { useReactionOverlay } from 'Cargo/Hooks/Reaction';
import { useModalsSlice } from 'Cargo/Modal/modalsSlice';
import { UUID } from 'Cargo/Types/types';
import LoadingShipment from 'Features/BookShipment/Components/LoadingShipment';
import { ReactNode } from 'react';
import styled from 'styled-components/macro';

interface ModalsProviderProps {
    children: ReactNode;
}

export interface ModalInstance {
    id: UUID;
    contents: ReactNode;
    onDone: ((userData: unknown) => void) | undefined;
    onBackdropClick: (userData?: unknown) => void;
    padding?: string;
    simpleModal?: boolean;
}

const LoadingBackdrop = styled.div`
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0vh;
    left: 0vw;
    backdrop-filter: blur(6px) brightness(90%);
    z-index: 1000;
`;

function ModalsProvider(props: ModalsProviderProps) {
    const { renderModals, showLoading } = useModalsSlice();
    const { renderReactions } = useReactionOverlay();

    return (
        <>
            {showLoading && (
                <LoadingBackdrop>
                    <LoadingShipment />
                </LoadingBackdrop>
            )}
            {renderModals()}
            {renderReactions()}
            {props.children}
        </>
    );
}

export function useModalsProvider() {
    return ModalsProvider;
}
