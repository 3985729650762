import LinkButton from 'Cargo/Controls/LinkButton';
import {
    NormalQuestionBubble,
    QuestionBubbleHeader,
    QuestionBubbleLabel,
} from 'Cargo/Controls/QuestionBubble';
import {
    getPluralFormForHandlingUnitType,
    getSingularFormForHandlingUnitType,
} from 'Data/HandlingUnitTypes';
import { HandlingUnitType } from 'generated-openapi-client';
import React from 'react';

interface DimensionsQuestionBubble {
    handlingUnitType: HandlingUnitType | undefined;
    onAddLineItem: (() => void) | undefined;
}

const DimensionsQuestionBubble: React.FC<DimensionsQuestionBubble> = (
    props: DimensionsQuestionBubble
) => {
    return (
        <NormalQuestionBubble
            description={
                <>
                    <QuestionBubbleHeader>Dimensions</QuestionBubbleHeader>
                    <QuestionBubbleLabel>
                        {`Correct dimensions ensure you get accurate quotes from carriers.`}
                    </QuestionBubbleLabel>
                    {props.handlingUnitType &&
                    props.handlingUnitType !== HandlingUnitType.Other ? (
                        <>
                            <QuestionBubbleLabel>
                                {`If you are shipping multiple identical ${getPluralFormForHandlingUnitType(
                                    props.handlingUnitType
                                ).toLowerCase()}
                            , this should be the dimensions and weight of each single ${getSingularFormForHandlingUnitType(
                                props.handlingUnitType
                            ).toLowerCase()}
                            .`}
                            </QuestionBubbleLabel>
                            {props.onAddLineItem && (
                                <QuestionBubbleLabel>
                                    Please{' '}
                                    <LinkButton
                                        regularWeight
                                        onClick={props.onAddLineItem}
                                    >
                                        add an additional row
                                    </LinkButton>{' '}
                                    if you are shipping multiple{' '}
                                    {getPluralFormForHandlingUnitType(
                                        props.handlingUnitType
                                    ).toLowerCase()}{' '}
                                    with different products, dimensions or
                                    weights.
                                </QuestionBubbleLabel>
                            )}
                        </>
                    ) : (
                        <>
                            <QuestionBubbleLabel>
                                {`If you are shipping multiple identical handling units, this should be the dimensions and weight of each individual one.`}
                            </QuestionBubbleLabel>
                            {props.onAddLineItem && (
                                <QuestionBubbleLabel>
                                    {`If you are shipping multiple handling units that have different dimensions or weights, please `}
                                    <LinkButton
                                        regularWeight
                                        onClick={props.onAddLineItem}
                                    >
                                        add an additional row
                                    </LinkButton>
                                    {` for each one.`}
                                </QuestionBubbleLabel>
                            )}
                        </>
                    )}
                    <QuestionBubbleLabel>
                        {`Maximum handling unit dimensions: 50ft long x 96" wide x 102" high`}
                    </QuestionBubbleLabel>
                </>
            }
            learnMoreUrl="https://help.freightsimple.com/using-freightsimple/quoting/shipment-contents#dimensions"
        />
    );
};
export default DimensionsQuestionBubble;
