import { UUID } from 'Cargo/Types/types';
import { Dispatch, SetStateAction, useState } from 'react';
import { useModal } from 'Cargo/Modal/useModal';
import { AnimatedSizeContianer } from 'Cargo/Animations/AnimatedSizeContainer';
import { UpdateCustomsDocsSelectModeStage } from './UpdateCustomsDocsSelectModeStage';
import { UpdateCustomsDocsSubmitStage } from './UpdateCustomsDocsSubmitStage';
import { CustomsDocsUploadMode } from 'Apis/CustomsDocsApi';

export interface UpdateCustomsDocsModalProps {
    shipmentId: UUID;
    onDone: () => void;
}

export interface UpdateCustomsDocsStageProps
    extends UpdateCustomsDocsModalProps {
    stage: UpdateCustomsDocsStage;
    setStage: Dispatch<SetStateAction<UpdateCustomsDocsStage>>;
    mode: CustomsDocsUploadMode | undefined;
    setMode: Dispatch<SetStateAction<CustomsDocsUploadMode | undefined>>;
}

export enum UpdateCustomsDocsStage {
    SELECT_MODE = 'select',
    SUBMIT = 'submit',
    FINISH = 'finish',
}

export function UpdateCustomsDocsModal(props: UpdateCustomsDocsModalProps) {
    const [mode, setMode] = useState<CustomsDocsUploadMode>();
    const [stage, setStage] = useState(UpdateCustomsDocsStage.SELECT_MODE);

    const stageProps: UpdateCustomsDocsStageProps = {
        mode,
        setMode,
        setStage,
        stage,
        ...props,
    };

    return (
        <div style={{maxWidth: "800px"}}>
            {stage === UpdateCustomsDocsStage.SELECT_MODE && (
                <UpdateCustomsDocsSelectModeStage {...stageProps} />
            )}
            {stage === UpdateCustomsDocsStage.SUBMIT &&   (
                <UpdateCustomsDocsSubmitStage {...stageProps} />
            )}
        </div>
    );
}

export function useUpdateCustomDocsModal(
    shipmentId: UUID | undefined,
    onReload?: () => Promise<void>
): () => void | null {
    const showModal = useModal<UUID>();

    function show() {
        if (shipmentId === undefined) {
            return null;
        }

        showModal(
            (done) => {
                return (
                    <AnimatedSizeContianer skipOnFirstRender>
                        <UpdateCustomsDocsModal
                            shipmentId={shipmentId}
                            onDone={done}
                        />
                    </AnimatedSizeContianer>
                );
            },
            onReload,
            {
                preventBackdropClick: true,
            }
        );
    }

    return show;
}
