/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InvoiceType = {
    ShipmentCharge: 'ShipmentCharge',
    AdditionalCharge: 'AdditionalCharge',
    ShipmentRefund: 'ShipmentRefund',
    AdditionalChargeRefund: 'AdditionalChargeRefund'
} as const;
export type InvoiceType = typeof InvoiceType[keyof typeof InvoiceType];


export function instanceOfInvoiceType(value: any): boolean {
    return Object.values(InvoiceType).includes(value);
}

export function InvoiceTypeFromJSON(json: any): InvoiceType {
    return InvoiceTypeFromJSONTyped(json, false);
}

export function InvoiceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceType {
    return json as InvoiceType;
}

export function InvoiceTypeToJSON(value?: InvoiceType | null): any {
    return value as any;
}

