/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PreferredBrokerType = {
    All: 'all',
    Northbound: 'northbound',
    Southbound: 'southbound',
    None: 'none'
} as const;
export type PreferredBrokerType = typeof PreferredBrokerType[keyof typeof PreferredBrokerType];


export function instanceOfPreferredBrokerType(value: any): boolean {
    return Object.values(PreferredBrokerType).includes(value);
}

export function PreferredBrokerTypeFromJSON(json: any): PreferredBrokerType {
    return PreferredBrokerTypeFromJSONTyped(json, false);
}

export function PreferredBrokerTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreferredBrokerType {
    return json as PreferredBrokerType;
}

export function PreferredBrokerTypeToJSON(value?: PreferredBrokerType | null): any {
    return value as any;
}

