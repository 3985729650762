import Colors from 'Cargo/Colors';
import Dropdown from 'Cargo/Controls/Dropdown';
import Box, { BoxStyle } from 'Cargo/Layout/Box';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { ErrorMessageType } from 'Cargo/Validation';
import { DeadlineDateDropdown } from 'Features/Locations/Components/DeadlineDateDropdown';
import moment from 'moment';
import styled from 'styled-components/macro';
import BookShipmentSmallSection from './BookShipmentSmallSection';

const QuestionLabel = styled.div`
    font-size: 18px;
    color: ${Colors.LightText};
`;

interface DeadlineSectionProps {
    titleContext: string;
    pickupDate: string;

    deadlineDate: string | undefined;
    onDeadlineDateChange: (_: moment.Moment | undefined) => void;
    deadlineErrors: ErrorMessageType;

    hasDeadline: boolean | undefined;
    onHasDeadlineChange: (_: boolean) => void;
    hasDeadlineErrors: ErrorMessageType;

    forceValidation: boolean;
}

export function DeadlineSection({
    deadlineDate,
    onDeadlineDateChange,
    deadlineErrors,
    pickupDate,
    titleContext,
    forceValidation,
    hasDeadline,
    onHasDeadlineChange,
    hasDeadlineErrors,
}: DeadlineSectionProps) {
    const options = [
        { item: true, description: 'Yes' },
        { item: false, description: 'No' },
    ];
    function handleOptionSelected(option: boolean | undefined) {
        if (option !== undefined) {
            onHasDeadlineChange(option);
        }
    }
    return (
        <BookShipmentSmallSection
            id="delivery-deadline"
            index={0}
            header={<>{titleContext} Deadline</>}
            description={
                <Stack>
                    Information about distribution centre deadlines can help
                    carriers prioritize your freight accordingly
                </Stack>
            }
        >
            <Box
                width={608}
                style={{ minHeight: '230px', padding: '32px' }}
                boxStyle={BoxStyle.Tight}
            >
                <Stack align="left">
                    <Spacer height={12} />
                    <HorizontalStack verticalAlign="middle">
                        <QuestionLabel>
                            Is there a {titleContext.toLowerCase()} deadline?
                        </QuestionLabel>
                        <Spacer width={32} />
                        <div>
                            <Dropdown<boolean | undefined>
                                unselectedDescription="Select"
                                selectedItem={hasDeadline}
                                onOptionSelected={handleOptionSelected}
                                options={options}
                                errorMessage={hasDeadlineErrors}
                                forceValidation={forceValidation}
                            />
                        </div>
                    </HorizontalStack>

                    <Spacer height={48} />
                    <HorizontalStack
                        verticalAlign="middle"
                        style={{
                            opacity: hasDeadline ? '1.0' : '0.2',
                        }}
                    >
                        <QuestionLabel>What is the deadline?</QuestionLabel>
                        <Spacer width={32} />
                        <div style={{ marginTop: '-12px' }}>
                            <DeadlineDateDropdown
                                pickupDate={pickupDate}
                                date={
                                    deadlineDate === undefined
                                        ? undefined
                                        : moment(deadlineDate)
                                }
                                onDateChange={onDeadlineDateChange}
                                errorMessage={deadlineErrors}
                                forceValidation={forceValidation}
                                disabled={hasDeadline !== true}
                            />
                        </div>
                    </HorizontalStack>
                </Stack>
            </Box>
        </BookShipmentSmallSection>
    );
}
