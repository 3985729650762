/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Document } from './Document';
import {
    DocumentFromJSON,
    DocumentFromJSONTyped,
    DocumentToJSON,
} from './Document';

/**
 * 
 * @export
 * @interface ListDocumentsResponse
 */
export interface ListDocumentsResponse {
    /**
     * 
     * @type {Array<Document>}
     * @memberof ListDocumentsResponse
     */
    documents: Array<Document>;
}

/**
 * Check if a given object implements the ListDocumentsResponse interface.
 */
export function instanceOfListDocumentsResponse(value: object): boolean {
    if (!('documents' in value)) return false;
    return true;
}

export function ListDocumentsResponseFromJSON(json: any): ListDocumentsResponse {
    return ListDocumentsResponseFromJSONTyped(json, false);
}

export function ListDocumentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListDocumentsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'documents': ((json['documents'] as Array<any>).map(DocumentFromJSON)),
    };
}

export function ListDocumentsResponseToJSON(value?: ListDocumentsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'documents': ((value['documents'] as Array<any>).map(DocumentToJSON)),
    };
}

