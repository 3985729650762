import Button from 'Cargo/Controls/Button';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { useModal } from 'Cargo/Modal/useModal';
import { H2, Microcopy } from 'Cargo/Text/Text';
import React, { ReactElement, ReactNode } from 'react';

interface MultiOptionConfirmModalOptions {
    buttons: string[];
}

interface ConfirmModalProps {
    onDone: (index: number | undefined) => void;
    message: ReactNode;
    title: ReactNode;
    options: MultiOptionConfirmModalOptions;
}

function ConfirmModal(props: ConfirmModalProps): ReactElement {
    return (
        <Stack align="center" id="ConfirmModal">
            <H2>{props.title}</H2>
            <Microcopy>{props.message}</Microcopy>
            <Spacer height={24} />
            <HorizontalStack spacing={16}>
                <Button
                    id="cancelButton"
                    size="large"
                    secondary
                    onClick={() => {
                        props.onDone(undefined);
                    }}
                >
                    Cancel
                </Button>
                {props.options.buttons.map(function (buttonText, index) {
                    return (
                        <Button
                            id="doneButton"
                            size="large"
                            onClick={() => {
                                props.onDone(index);
                            }}
                        >
                            {buttonText}
                        </Button>
                    );
                })}
            </HorizontalStack>
        </Stack>
    );
}

function useMultiOptionConfirmModal(
    title: ReactNode,
    message: ReactNode,
    options: MultiOptionConfirmModalOptions
): () => Promise<number | undefined> {
    const showModal = useModal<number>();

    function confirm(): Promise<number | undefined> {
        const p = new Promise<number | undefined>((resolve) => {
            showModal(
                (done) => {
                    return (
                        <ConfirmModal
                            onDone={(index: number | undefined) => {
                                done(index);
                            }}
                            message={message}
                            title={title}
                            options={options}
                        />
                    );
                },
                (confirmed) => {
                    resolve(confirmed);
                },
                {
                    preventBackdropClick: true,
                }
            );
        });

        return p;
    }
    return confirm;
}

export default useMultiOptionConfirmModal;
