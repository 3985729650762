import { BookShipmentShipmentType } from '../Slices/bookShipmentSlice';
import { useFinishBookingFlow } from './useFinishBookingFlow';

export function useCompletedFlowItems(
    shipment: BookShipmentShipmentType,
    completedFlowItems: Array<string>
) {
    console.log('useCompletedFlowItems', { shipment, completedFlowItems });
    const finishBookingFlow = useFinishBookingFlow(shipment);

    function generateUnlockedFlowItems(): Array<string> {
        // Walk through the request quote flow, and then the finish booking flow, and find the first
        // step that hasn't been completed

        const unlockedItems: Array<string> = [];

        for (let i = 0; i < finishBookingFlow.length; i++) {
            const item = finishBookingFlow[i];
            unlockedItems.push(item.identifier);
            if (!completedFlowItems.includes(item.identifier)) {
                return unlockedItems;
            }
        }

        return unlockedItems;
    }

    const unlockedFlowItems = generateUnlockedFlowItems();

    return {
        unlockedFlowItems,
    };
}
