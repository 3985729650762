/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LookUpCityErrorResponse,
  LookUpCityResponse,
} from '../models/index';
import {
    LookUpCityErrorResponseFromJSON,
    LookUpCityErrorResponseToJSON,
    LookUpCityResponseFromJSON,
    LookUpCityResponseToJSON,
} from '../models/index';

export interface GetPostalCodesLookUpRequest {
    postalCode: string;
}

export interface GetPostalCodesLookUpPotentialAddressesRequest {
    postalCode: string;
}

/**
 * 
 */
export class PostalCodesApi extends runtime.BaseAPI {

    /**
     * Returns the city/coordinates of a given postal code
     * Your GET endpoint
     */
    async getPostalCodesLookUpRaw(requestParameters: GetPostalCodesLookUpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LookUpCityResponse>> {
        if (requestParameters['postalCode'] == null) {
            throw new runtime.RequiredError(
                'postalCode',
                'Required parameter "postalCode" was null or undefined when calling getPostalCodesLookUp().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['postalCode'] != null) {
            queryParameters['postalCode'] = requestParameters['postalCode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/postal-codes/look-up-city`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LookUpCityResponseFromJSON(jsonValue));
    }

    /**
     * Returns the city/coordinates of a given postal code
     * Your GET endpoint
     */
    async getPostalCodesLookUp(requestParameters: GetPostalCodesLookUpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LookUpCityResponse> {
        const response = await this.getPostalCodesLookUpRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return a list of addresses for a given Postal Code
     * Your GET endpoint
     */
    async getPostalCodesLookUpPotentialAddressesRaw(requestParameters: GetPostalCodesLookUpPotentialAddressesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['postalCode'] == null) {
            throw new runtime.RequiredError(
                'postalCode',
                'Required parameter "postalCode" was null or undefined when calling getPostalCodesLookUpPotentialAddresses().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['postalCode'] != null) {
            queryParameters['postalCode'] = requestParameters['postalCode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/postal-codes/look-up-potential-addresses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Return a list of addresses for a given Postal Code
     * Your GET endpoint
     */
    async getPostalCodesLookUpPotentialAddresses(requestParameters: GetPostalCodesLookUpPotentialAddressesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getPostalCodesLookUpPotentialAddressesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
