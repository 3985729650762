import { useModal } from 'Cargo/Modal/useModal';
import { CreateAccountForm2 } from 'Features/Authentication/Components/CreateAccountForm2';
import { SignInForm } from 'Features/Authentication/Components/SignInForm';
import { useIsAuthViewHeightToSmall } from 'Features/Authentication/Hooks/useIsAuthViewHeightTooSmall';
import {
    useRandomCreateAccountBackgroundImage,
    useRandomSigninBackgroundImage,
} from 'Helpers/randomBackgroundImage';
import { trackAnalyticsEvent } from 'Integrations/analytics';
import { ReactNode, useState } from 'react';

function AuthenticationPaneContainer({
    children,
    image,
}: {
    children: ReactNode;
    image: string;
}) {
    const isViewHeightTooSmall = useIsAuthViewHeightToSmall();

    return (
        <main
            style={{
                maxHeight: '883px',
                width: '80vw',
                border: '1px solid #bbb',
                backgroundColor: 'white',
                display: 'flex',
            }}
        >
            <section
                style={{
                    width: '688px', // Fixed width of 560px + 64px padding on each side
                    paddingBlock: isViewHeightTooSmall ? '32px' : '64px',
                    paddingInline: isViewHeightTooSmall ? '40px' : '64px',
                }}
            >
                {children}
            </section>
            <aside
                style={{
                    flex: 1,
                    overflow: 'hidden',
                    backgroundImage: image,
                    backgroundPosition: 'bottom center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}
            />
        </main>
    );
}

interface AuthenticateForQuotesModalProps {
    onDone: () => void;
    onCancel: () => void;
}

interface SignInPaneProps {
    onDone: () => void;
    onCancel: () => void;
    onCreateAccountInstead: () => void;
}

function SignInPane(props: SignInPaneProps) {
    const { onCreateAccountInstead } = props;
    const backgroundImage = useRandomSigninBackgroundImage();

    function onForgotPassword() {
        window.open('/request-reset-password', '_new');
    }

    return (
        <AuthenticationPaneContainer image={backgroundImage}>
            <SignInForm
                onCreateAccountInstead={onCreateAccountInstead}
                onForgotPassword={onForgotPassword}
                onSuccess={props.onDone}
                redirectAfterSignin={false}
            />
        </AuthenticationPaneContainer>
    );
}

interface CreateAccountPaneProps {
    onDone: () => void;
    onCancel: () => void;
    onSignInInstead: () => void;
}

function CreateAccountPane(props: CreateAccountPaneProps) {
    const { onSignInInstead } = props;
    const backgroundImage = useRandomCreateAccountBackgroundImage();

    async function onSuccess() {
        trackAnalyticsEvent('Get Started/Account Created');
        props.onDone();
    }

    return (
        <AuthenticationPaneContainer image={backgroundImage}>
            <CreateAccountForm2
                onSignInInstead={onSignInInstead}
                onSuccess={onSuccess}
            />
        </AuthenticationPaneContainer>
    );
}

function AuthenticateForQuotesModal(props: AuthenticateForQuotesModalProps) {
    const [showSignIn, setShowSignIn] = useState(false);
    async function onSignInInstead() {
        setShowSignIn(true);
    }

    async function onCreateAccountInstead() {
        setShowSignIn(false);
    }

    if (showSignIn) {
        return (
            <SignInPane
                {...props}
                onCreateAccountInstead={onCreateAccountInstead}
            />
        );
    } else {
        return (
            <CreateAccountPane {...props} onSignInInstead={onSignInInstead} />
        );
    }
}

export function useAuthenticateForQuotesModal() {
    const showModal = useModal<boolean>();
    function show() {
        const p = new Promise<boolean | undefined>((resolve) => {
            showModal(
                (done) => {
                    return (
                        <AuthenticateForQuotesModal
                            onDone={() => done(true)}
                            onCancel={() => done(false)}
                        />
                    );
                },
                (confirmed) => {
                    resolve(confirmed);
                },
                {
                    preventBackdropClick: true,
                    simpleModal: true,
                }
            );
        });

        return p;
    }

    return show;
}
