/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';

/**
 * 
 * @export
 * @interface CreateAccountRequest
 */
export interface CreateAccountRequest {
    /**
     * 
     * @type {boolean}
     * @memberof CreateAccountRequest
     */
    isPersonalSelected: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAccountRequest
     */
    isBrokerSelected: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    phoneNumberExtension: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    utmSource?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    utmMedium?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    utmCampaign?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    utmTerm?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    gclid?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    shipmentFrequency: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    referralCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountRequest
     */
    prefilledCode?: string;
    /**
     * 
     * @type {Currency}
     * @memberof CreateAccountRequest
     */
    companyCurrency: Currency;
}

/**
 * Check if a given object implements the CreateAccountRequest interface.
 */
export function instanceOfCreateAccountRequest(value: object): boolean {
    if (!('isPersonalSelected' in value)) return false;
    if (!('isBrokerSelected' in value)) return false;
    if (!('email' in value)) return false;
    if (!('password' in value)) return false;
    if (!('name' in value)) return false;
    if (!('companyName' in value)) return false;
    if (!('phoneNumber' in value)) return false;
    if (!('phoneNumberExtension' in value)) return false;
    if (!('shipmentFrequency' in value)) return false;
    if (!('companyCurrency' in value)) return false;
    return true;
}

export function CreateAccountRequestFromJSON(json: any): CreateAccountRequest {
    return CreateAccountRequestFromJSONTyped(json, false);
}

export function CreateAccountRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAccountRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'isPersonalSelected': json['isPersonalSelected'],
        'isBrokerSelected': json['isBrokerSelected'],
        'email': json['email'],
        'password': json['password'],
        'name': json['name'],
        'companyName': json['companyName'],
        'phoneNumber': json['phoneNumber'],
        'phoneNumberExtension': json['phoneNumberExtension'],
        'utmSource': json['utmSource'] == null ? undefined : json['utmSource'],
        'utmMedium': json['utmMedium'] == null ? undefined : json['utmMedium'],
        'utmCampaign': json['utmCampaign'] == null ? undefined : json['utmCampaign'],
        'utmTerm': json['utmTerm'] == null ? undefined : json['utmTerm'],
        'gclid': json['gclid'] == null ? undefined : json['gclid'],
        'shipmentFrequency': json['shipmentFrequency'],
        'referralCode': json['referralCode'] == null ? undefined : json['referralCode'],
        'prefilledCode': json['prefilledCode'] == null ? undefined : json['prefilledCode'],
        'companyCurrency': CurrencyFromJSON(json['companyCurrency']),
    };
}

export function CreateAccountRequestToJSON(value?: CreateAccountRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isPersonalSelected': value['isPersonalSelected'],
        'isBrokerSelected': value['isBrokerSelected'],
        'email': value['email'],
        'password': value['password'],
        'name': value['name'],
        'companyName': value['companyName'],
        'phoneNumber': value['phoneNumber'],
        'phoneNumberExtension': value['phoneNumberExtension'],
        'utmSource': value['utmSource'],
        'utmMedium': value['utmMedium'],
        'utmCampaign': value['utmCampaign'],
        'utmTerm': value['utmTerm'],
        'gclid': value['gclid'],
        'shipmentFrequency': value['shipmentFrequency'],
        'referralCode': value['referralCode'],
        'prefilledCode': value['prefilledCode'],
        'companyCurrency': CurrencyToJSON(value['companyCurrency']),
    };
}

