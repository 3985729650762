/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SavedLocation } from './SavedLocation';
import {
    SavedLocationFromJSON,
    SavedLocationFromJSONTyped,
    SavedLocationToJSON,
} from './SavedLocation';

/**
 * 
 * @export
 * @interface AddSavedLocationRequest
 */
export interface AddSavedLocationRequest {
    /**
     * 
     * @type {SavedLocation}
     * @memberof AddSavedLocationRequest
     */
    location: SavedLocation;
}

/**
 * Check if a given object implements the AddSavedLocationRequest interface.
 */
export function instanceOfAddSavedLocationRequest(value: object): boolean {
    if (!('location' in value)) return false;
    return true;
}

export function AddSavedLocationRequestFromJSON(json: any): AddSavedLocationRequest {
    return AddSavedLocationRequestFromJSONTyped(json, false);
}

export function AddSavedLocationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddSavedLocationRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'location': SavedLocationFromJSON(json['location']),
    };
}

export function AddSavedLocationRequestToJSON(value?: AddSavedLocationRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'location': SavedLocationToJSON(value['location']),
    };
}

