import Button from 'Cargo/Controls/Button';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { ShipmentMap } from 'Cargo/Maps/ShipmentMap';
import LoadingShipment from 'Features/BookShipment/Components/LoadingShipment';
import DetailsSectionForTracking from 'Features/ViewShipments/Components/DetailsSectionForTracking';
import DocumentsSection, {
    DocumentsSectionItem,
} from 'Features/ViewShipments/Components/DocumentsSection';
import NextStepsForDeliverySection from 'Features/ViewShipments/Components/NextStepsForDeliverySection';
import NextStepsSection from 'Features/ViewShipments/Components/NextStepsSection';
import TopBar from 'Features/ViewShipments/Components/TopBar';
import TrackingSection from 'Features/ViewShipments/Components/TrackingSection';
import { describeDocumentType } from 'Helpers/describeDocumentType';
import useInterval from 'Hooks/useInterval';
import { useOnce } from 'Hooks/useOnce';
import useQuery from 'Hooks/useQuery';
import { useTrackingService } from 'Services/TrackingService';
import { useShipmentsApi } from 'apis';
import {
    Document,
    ShipmentForTracking,
    ShipmentState,
    TrackingInfo,
} from 'generated-openapi-client';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useAuthentication } from '../Authentication/Slices/authenticationSlice';
import { CustomsDocsSectionForTracking } from '../ViewShipments/Components/CustomsDocsSectionForTracking';

interface ShowShipmentProps {
    shipment: ShipmentForTracking;
    onReloadShipment: () => void;
}

const Container = styled.div`
    border: 1px solid #ccc;
    width: 1216px;
    position: relative;
    z-index: 100;
    background-color: white;
    top: -72px;
    border-radius: 3px;
`;

const CallToAction = styled.div`
    font-weight: var(--nhu-font-weight-light);
    color: var(--freightsimple-color-light-text);
    font-size: 24px;
`;

function mapForShipment(shipment: ShipmentForTracking) {
    return (
        <ShipmentMap
            pickup={shipment.pickupLocation}
            delivery={shipment.deliveryLocation}
            shipmentState={shipment.shipmentState}
        />
    );
}

interface NextStepsSectionForShipmentProps {
    shipment: ShipmentForTracking;
}

function NextStepsSectionForShipment(props: NextStepsSectionForShipmentProps) {
    const { shipment } = props;
    const { downloadPickupPackage } = useTrackingService();

    if (shipment.shipmentState !== ShipmentState.BookingConfirmed) {
        return <></>;
    }

    function onDownload() {
        downloadPickupPackage(shipment.trId);
    }

    return <NextStepsSection onDownload={onDownload} />;
}

function NextStepsForDeliverySectionForShipment(
    props: NextStepsSectionForShipmentProps
) {
    const { shipment } = props;

    if (shipment.shipmentState !== ShipmentState.InTransit) {
        return <></>;
    }

    const deliveringByAppointment =
        shipment.deliveryLocation.accessorials.includes(
            'SCHEDULING_APPOINTMENT_REQUIRED'
        ) || shipment.appointmentDate !== undefined;

    return (
        <NextStepsForDeliverySection
            deliveringByAppointment={deliveringByAppointment}
            appointmentSet={shipment.appointmentDate !== undefined}
        />
    );
}

interface DocumentsSectionForShipmentProps {
    shipment: ShipmentForTracking;
}

function DocumentsSectionForShipment(props: DocumentsSectionForShipmentProps) {
    const { shipment } = props;
    const { getDocumentsForTracking, downloadDocument } = useTrackingService();
    const [documentItems, setDocumentItems] = useState<
        Array<DocumentsSectionItem>
    >([]);

    function convert(document: Document): DocumentsSectionItem {
        return {
            onDownloadDocument: () => {
                downloadDocument(
                    shipment.trId,
                    document.documentId,
                    document.documentType
                );
            },
            documentDescription: describeDocumentType(document.documentType),
            creationDate: moment(document.createdAt),
            existsYet: true,
            documentType: document.documentType,
        };
    }

    useOnce(async () => {
        const documents = (await getDocumentsForTracking(shipment.trId)).map(
            (d) => convert(d)
        );

        setDocumentItems(documents);
    });

    return <DocumentsSection items={documentItems} />;
}

function topBarForShipment(shipment: ShipmentForTracking) {
    const proNumber = shipment.proNumber;

    if (!proNumber) {
        return <></>;
    }

    const deliveryAppointment = shipment.deliveryLocation.accessorials.includes(
        'SCHEDULING_APPOINTMENT_REQUIRED'
    );

    return (
        <TopBar
            shipmentState={shipment.shipmentState}
            pickupLocation={shipment.pickupLocation}
            deliveryLocation={shipment.deliveryLocation}
            pickupDate={shipment.pickupDate}
            pickupHours={undefined}
            appointmentDate={shipment.appointmentDate}
            expectedDeliveryDate={shipment.expectedDeliveryDate}
            expectedDeliveryHours={shipment.expectedDeliveryHours}
            actualDeliveryDate={shipment.actualDeliveryDate}
            actualDeliveryTime={shipment.actualDeliveryTime}
            predictedLatestDeliveryDate={shipment.latestExpectedDeliveryDate}
            proNumber={proNumber}
            referenceNumber={shipment.deliveryReferenceNumber}
            preferRenderReferenceNumber={true}
            deliveryAppointment={deliveryAppointment}
        />
    );
}

interface DetailsSectionForShipmentProps {
    shipment: ShipmentForTracking;
    trackingInfo: TrackingInfo | undefined;
}

function DetailsSectionForShipment(props: DetailsSectionForShipmentProps) {
    const { shipment } = props;
    const lineItems = shipment.lineItems;
    const shipmentState = shipment.shipmentState;
    const pickupLocation = shipment.pickupLocation;
    const deliveryLocation = shipment.deliveryLocation;
    const deliveryDeadline = shipment.deliveryDeadline;
    const pickupContact = shipment.pickupContact;
    const deliveryContact = shipment.deliveryContact;
    const pickupDate = moment(shipment.pickupDate).startOf('day');
    const actualDeliveryDate = shipment.actualDeliveryDate;
    const actualDeliveryTime = shipment.actualDeliveryTime;
    const proNumber = shipment.proNumber;
    const expectedDeliveryDate = shipment.expectedDeliveryDate;
    const latestExpectedDeliveryDate = shipment.latestExpectedDeliveryDate;
    const pickupReferenceNumber = shipment.pickupReferenceNumber;
    const deliveryReferenceNumber = shipment.deliveryReferenceNumber;
    const pickupBoothNumber = shipment.pickupBoothNumber;
    const deliveryBoothNumber = shipment.deliveryBoothNumber;

    const quote = {
        carrierIdentifier: shipment.carrierIdentifier,
        serviceDisplayName: shipment.serviceDisplayName,
    };

    const trackingLines = props.trackingInfo?.trackingLines;
    const mostRecentTrackingLine =
        trackingLines !== undefined
            ? trackingLines[trackingLines.length - 1]
            : undefined;

    return (
        <DetailsSectionForTracking
            lineItems={lineItems}
            shipmentState={shipmentState}
            pickupLocation={pickupLocation}
            deliveryLocation={deliveryLocation}
            pickupContact={pickupContact}
            deliveryContact={deliveryContact}
            deliveryDeadline={deliveryDeadline}
            pickupDate={pickupDate}
            pickupHours={undefined}
            quote={quote}
            actualDeliveryDate={actualDeliveryDate}
            actualDeliveryTime={actualDeliveryTime}
            proNumber={proNumber}
            expectedDeliveryDate={expectedDeliveryDate}
            latestExpectedDeliveryDate={latestExpectedDeliveryDate}
            pickupReferenceNumber={pickupReferenceNumber}
            deliveryReferenceNumber={deliveryReferenceNumber}
            pickupBoothNumber={pickupBoothNumber}
            deliveryBoothNumber={deliveryBoothNumber}
            notes={undefined}
            broker={undefined}
            mostRecentTrackingLine={mostRecentTrackingLine}
        />
    );
}

interface TrackingSectionForShipmentProps {
    shipment: ShipmentForTracking;
    trackingInfo: TrackingInfo | undefined;
}

function TrackingSectionForShipment(props: TrackingSectionForShipmentProps) {
    const { shipment, trackingInfo } = props;

    if (trackingInfo === undefined) {
        return <></>;
    }

    const lines = trackingInfo.trackingLines.map((line) => {
        return {
            message: line.message,
            time: moment(line.timestamp),
        };
    });

    return (
        <TrackingSection
            shipmentState={shipment.shipmentState}
            lines={lines}
            trackingNote={shipment.trackingNote}
        />
    );
}

function customsDocsSectionForShipment(
    shipment: ShipmentForTracking,
    onUploaded: () => void
) {
    return (
        <CustomsDocsSectionForTracking
            trackingId={shipment.trId}
            onUploaded={onUploaded}
        />
    );
}

function ShowShipment(props: ShowShipmentProps) {
    const shipment = props.shipment;
    const { trId } = shipment;
    const trackingService = useTrackingService();

    const [trackingInfo, setTrackingInfo] = useState<
        TrackingInfo | undefined
    >();

    const { checkAuthenticated } = useAuthentication();
    const navigate = useNavigate();
    const shipmentApi = useShipmentsApi();

    useOnce(async () => {
        const isAuthenticated = await checkAuthenticated();

        // If we're authenticated, we should just redirect to the dashboard page
        console.log(`checking auth ${isAuthenticated}`);
        if (isAuthenticated) {
            console.log(`getting shipment id`);
            const response =
                await shipmentApi.postShipmentsSwapTrackingIdForShipmentId({
                    tId: trId,
                });

            console.log(`getting shipment id - response`, { response });
            if (response.hasAccess && response.shipmentId) {
                console.log(`navigating`);
                navigate(`/view-shipment?shipmentId=${response.shipmentId}`);
                return;
            }
        }

        loadTracking();
    });

    async function loadTracking() {
        const ti = await trackingService.getTracking(trId);

        setTrackingInfo(ti);
    }

    function showCustomsDocsSection() {
        return (
            shipment.needsCustomsDocs &&
            (shipment.shipmentState === ShipmentState.BookingConfirmed ||
                shipment.shipmentState === ShipmentState.OnHold ||
                shipment.shipmentState === ShipmentState.InTransit)
        );
    }

    return (
        <>
            {mapForShipment(shipment)}
            <Stack width="100%" align="center">
                <Container>
                    <Stack
                        width="1216px"
                        align="left"
                        style={{ padding: '64px', paddingTop: '32px' }}
                    >
                        {topBarForShipment(shipment)}
                        <Spacer height={64} />
                        {showCustomsDocsSection() &&
                            customsDocsSectionForShipment(
                                shipment,
                                props.onReloadShipment
                            )}
                        <NextStepsSectionForShipment shipment={shipment} />
                        <NextStepsForDeliverySectionForShipment
                            shipment={shipment}
                        />
                        <DetailsSectionForShipment
                            shipment={shipment}
                            trackingInfo={trackingInfo}
                        />
                        <TrackingSectionForShipment
                            shipment={shipment}
                            trackingInfo={trackingInfo}
                        />
                        <DocumentsSectionForShipment shipment={shipment} />
                    </Stack>
                </Container>
                <Spacer height={32} />
                <CallToAction>
                    Want your next shipment to be this easy?
                </CallToAction>
                <Spacer height={16} />
                <Button
                    size="large"
                    onClick={() =>
                        window.open('https://www.freightsimple.com/', '_blank')
                    }
                >
                    Book it on FreightSimple!
                </Button>
                <Spacer height={64} />
            </Stack>
        </>
    );
}

function TrackShipmentScreen() {
    const query = useQuery();

    const trackingService = useTrackingService();

    const trackingId = (query.tId ?? query.trackingId) as string;

    const [shipment, setShipment] = useState<ShipmentForTracking | undefined>();

    async function loadShipment() {
        const response = await trackingService.getShipmentForTracking(
            trackingId
        );
        console.log({ response });

        setShipment(response);
    }

    useOnce(async () => {
        loadShipment();
    });

    // Refresh the page every ten minutes
    useInterval(function () {
        loadShipment();
    }, 10 * 60 * 1000);

    if (!shipment) {
        return <LoadingShipment />;
    }

    return <ShowShipment shipment={shipment} onReloadShipment={loadShipment} />;
}
export default TrackShipmentScreen;
