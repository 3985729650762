import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from 'Cargo/Colors';
import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import Tooltip from './Tooltip';

interface LearnMoreProps {
    url: string | undefined;
}

const LearnMore: React.FC<LearnMoreProps> = (props: LearnMoreProps) => {
    function onClick() {
        window.open(props.url, '_new');
    }

    if (props.url === undefined) {
        return <></>;
    } else {
        return (
            <a
                target="_new"
                onClick={onClick}
                style={{
                    color: Colors.Blue,
                    fontSize: '12px',
                    cursor: 'pointer',
                }}
            >
                Learn more...
            </a>
        );
    }
};

export const QuestionBubbleLabel = styled.div`
    font-weight: var(--nhu-font-weight-light);
    font-size: 14px;
    color: var(--freightsimple-color-normal-text);
    margin-bottom: 7px;
`;

export const QuestionBubbleLabelStrong = styled.div`
    font-weight: var(--nhu-font-weight-medium);
    font-size: 14px;
    color: var(--freightsimple-color-normal-text);
    margin-bottom: 7px;
`;

export const Header = styled.div`
    font-weight: var(--nhu-font-weight-medium);
    font-size: 14px;
    color: var(--freightsimple-color-normal-text);
    margin-bottom: 12px;
`;

export enum QuestionBubbleContext {
    Normal,
    MicroError,
    NormalErrorExclamation,
    Small,
    VerySmall,
    ComingSoon,
}

interface QuestionBubbleProps {
    description: string | ReactNode;
    learnMoreUrl?: string;
    context: QuestionBubbleContext;
}

function icon(context: QuestionBubbleContext) {
    switch (context) {
        case QuestionBubbleContext.Normal: {
            return (
                <FontAwesomeIcon
                    icon={['fas', 'question-circle']}
                    style={{
                        width: '18px',
                        height: '18px',
                        marginBottom: '-1px',
                        marginLeft: '6px',
                        color: Colors.Blue,
                    }}
                />
            );
        }
        case QuestionBubbleContext.MicroError: {
            return (
                <FontAwesomeIcon
                    icon={['fas', 'question-circle']}
                    style={{
                        width: '10px',
                        height: '10px',
                        marginBottom: '1px',
                        marginLeft: '2px',
                        color: Colors.Red,
                    }}
                />
            );
        }

        case QuestionBubbleContext.NormalErrorExclamation: {
            return (
                <FontAwesomeIcon
                    icon={['fas', 'exclamation-circle']}
                    style={{
                        width: '18px',
                        height: '18px',
                        marginBottom: '1px',
                        marginLeft: '2px',
                        color: Colors.Red,
                    }}
                />
            );
        }

        case QuestionBubbleContext.VerySmall: {
            return (
                <FontAwesomeIcon
                    icon={['fas', 'question-circle']}
                    style={{
                        width: '12px',
                        height: '12px',
                        marginLeft: '4px',
                        color: Colors.Blue,
                    }}
                />
            );
        }

        case QuestionBubbleContext.Small: {
            return (
                <FontAwesomeIcon
                    icon={['fas', 'question-circle']}
                    style={{
                        width: '14px',
                        height: '14px',
                        marginLeft: '4px',
                        color: Colors.Blue,
                    }}
                />
            );
        }

        case QuestionBubbleContext.ComingSoon: {
            return (
                <FontAwesomeIcon
                    icon={['fas', 'question-circle']}
                    style={{
                        width: '14px',
                        height: '14px',
                        marginBottom: '1px',
                        marginLeft: '4px',
                        color: '#000',
                    }}
                />
            );
        }
    }
}

export const QuestionBubble: React.FC<QuestionBubbleProps> = (
    props: QuestionBubbleProps
) => {
    return (
        <Tooltip
            tooltip={
                <div style={{ padding: '10px', maxWidth: '320px' }}>
                    <QuestionBubbleLabel>
                        {props.description}
                    </QuestionBubbleLabel>
                    <LearnMore url={props.learnMoreUrl} />
                </div>
            }
        >
            {icon(props.context)}
        </Tooltip>
    );
};

export const QuestionBubbleHeader = Header;

type ResolvedContextQuestionBubbleType = Omit<QuestionBubbleProps, 'context'>;
export const NormalQuestionBubble: React.FC<
    ResolvedContextQuestionBubbleType
> = (props: ResolvedContextQuestionBubbleType) => {
    return <QuestionBubble context={QuestionBubbleContext.Normal} {...props} />;
};

export const SmallQuestionBubble: React.FC<
    ResolvedContextQuestionBubbleType
> = (props: ResolvedContextQuestionBubbleType) => {
    return <QuestionBubble context={QuestionBubbleContext.Small} {...props} />;
};

export const VerySmallQuestionBubble: React.FC<
    ResolvedContextQuestionBubbleType
> = (props: ResolvedContextQuestionBubbleType) => {
    return (
        <QuestionBubble context={QuestionBubbleContext.VerySmall} {...props} />
    );
};

export const MicroErrorQuestionBubble: React.FC<
    ResolvedContextQuestionBubbleType
> = (props: ResolvedContextQuestionBubbleType) => {
    return (
        <QuestionBubble context={QuestionBubbleContext.MicroError} {...props} />
    );
};

export function NormalErrorExclamation(
    props: ResolvedContextQuestionBubbleType
) {
    return (
        <QuestionBubble
            context={QuestionBubbleContext.NormalErrorExclamation}
            {...props}
        />
    );
}

export const ComingSoonQuestionBubble: React.FC<
    ResolvedContextQuestionBubbleType
> = (props: ResolvedContextQuestionBubbleType) => {
    return (
        <QuestionBubble context={QuestionBubbleContext.ComingSoon} {...props} />
    );
};

interface ToolTipWrapperProps {
    description: ReactNode;
    learnMoreUrl?: string;
    children: ReactNode;
}

export function ToolTipWrapper(props: ToolTipWrapperProps) {
    return (
        <Tooltip
            tooltip={
                <div style={{ padding: '10px' }}>
                    <QuestionBubbleLabel>
                        {props.description}
                    </QuestionBubbleLabel>
                    <LearnMore url={props.learnMoreUrl} />
                </div>
            }
        >
            {props.children}
        </Tooltip>
    );
}
