import YellowExclamationAnimatedIcon from 'Cargo/Icons/YellowExclamationAnimatedIcon';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { Microcopy } from 'Cargo/Text/Text';
import React from 'react';

function ShipmentNotFound() {
    return (
        <Stack align="center">
            <Spacer height={128} />
            <YellowExclamationAnimatedIcon />
            <Spacer height={16} />
            <Microcopy>Sorry, we couldn&apos;t find this shipment</Microcopy>
        </Stack>
    );
}
export default ShipmentNotFound;
