import { isWeekend } from 'Features/BookShipment/Helpers/nextBusinessDay';
import { isHolidayForCountry } from 'freightsimple-utilities/dist/holidays/moment';
import { CountryCode } from 'generated-openapi-client';
import moment, { Moment } from 'moment';
import React, { ReactNode, useState } from 'react';
import { DayPickerSingleDateController } from 'react-dates';
import styled from 'styled-components/macro';
import { HolidayMessageAlert } from './DateHolidayMessage';
import Tooltip from './Tooltip';
interface DatePickerControlProps {
    country?: CountryCode;
    date: string;
    onDateChange: (date: moment.Moment) => void;
}

const CalendarDescription = styled.div`
    color: var(--freightsimple-color-light-text);
    font-size: 12px;
    margin-top: 16px;
    height: 16px;
`;

const DatePickerControl: React.FC<DatePickerControlProps> = (
    props: DatePickerControlProps
) => {
    const [focused, setFocused] = useState<boolean>(false);
    const momentDate = moment(props.date).clone().startOf('day');
    const today = moment().startOf('day');
    const firstAllowedDate = today;
    const lastAllowedDate = today.clone().add(1, 'month');

    function isOutsideRange(date: Moment) {
        const baseCheck =
            date.isBefore(firstAllowedDate) ||
            date.isAfter(lastAllowedDate) ||
            isWeekend(date);

        if (props.country) {
            return baseCheck || isHolidayForCountry(date, props.country);
        }

        return baseCheck;
    }

    function tooltipForDate(date: moment.Moment) {
        const startOfDate = date.clone().startOf('day');

        // In the past
        if (startOfDate.isBefore(today)) {
            return '❌ This date is in the past';
        }

        if (startOfDate.isSame(today)) {
            return 'Note: For same day pickups, typically a carrier needs at least 3 hours notice to be able to pick up a shipment. If the carrier is unable to pick it up, they will pick it up on the next business day.';
        }

        // Too far in the future
        if (startOfDate.isAfter(today.clone().add(1, 'month'))) {
            return 'FreightSimple only supports booking shipments within the next month';
        }

        // Weekends (6 = Sat, 7 = Sun)
        if (startOfDate.isoWeekday() === 6 || startOfDate.isoWeekday() === 7) {
            return '❌ Pickup date can not be on a weekend';
        }
    }

    function onDateChange(date: moment.Moment | null) {
        if (!date) return;

        props.onDateChange(date);
    }

    const renderDayContents = (m: moment.Moment): ReactNode => {
        const fullWidthAndHeightStyle = {
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        };
        if (props.country && isHolidayForCountry(m, props.country)) {
            return (
                <Tooltip
                    cursor="not-allowed"
                    tooltip={
                        <div style={{ color: '#000000' }}>
                            <HolidayMessageAlert
                                date={m}
                                country={props.country}
                            />
                        </div>
                    }
                >
                    <div style={fullWidthAndHeightStyle}>
                        {m.date().toString()}
                    </div>
                </Tooltip>
            );
        }
        if (isWeekend(m)) {
            return (
                <div style={{ color: '#8f8f8f', ...fullWidthAndHeightStyle }} title={tooltipForDate(m)}>
                    {m.date().toString()}
                </div>
            );
        }
        return (
            <div title={tooltipForDate(m)} style={fullWidthAndHeightStyle}>
                {m.date().toString()}
            </div>
        );
    };

    // Any changes to the parameters here, shold be considered for DateDropdown.tsx
    // too
    return (
        <>
            <DayPickerSingleDateController
                date={momentDate}
                onDateChange={onDateChange}
                focused={focused}
                onFocusChange={({ focused }) => setFocused(focused || false)}
                hideKeyboardShortcutsPanel={true}
                isOutsideRange={(date) => {
                    return isOutsideRange(date);
                }}
                numberOfMonths={2}
                renderDayContents={renderDayContents}
                initialVisibleMonth={() => {
                    return moment();
                }}
            />
            <CalendarDescription>
                {tooltipForDate(momentDate)}
                <HolidayMessageAlert
                    date={momentDate}
                    country={props.country}
                />
            </CalendarDescription>
        </>
    );
};
export default DatePickerControl;
