import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Stack from 'Cargo/Layout/Stack';
import { describeLocationType } from 'Data/LocationTypes';
import { AddLineItemType } from 'Features/LineItems/Types/lineItemTypes';
import { AddLocationLocationType } from 'Features/Locations/Types/locationTypes';
import { capitalize } from 'Helpers/capitalize';
import { totalWeightForLineItems } from 'Helpers/lineItemCalculations';
import moment from 'moment';
import { DescribeConfirmQuote } from '../Components/AdditionalNavigationContent/DescribeConfirmQuote';
import { BookShipmentShipmentType } from '../Slices/bookShipmentSlice';

export function useAdditionalContentProvider(
    pickupDate: string | undefined,
    pickup: AddLocationLocationType | undefined,
    delivery: AddLocationLocationType | undefined,
    lineItems: Array<AddLineItemType>,
    creditCardBrand: string | undefined,
    shipment: BookShipmentShipmentType
) {
    function additionalContentProvider(flowIdentifier: string) {
        function describePickupDate() {
            if (pickupDate === undefined) {
                return <></>;
            }

            return (
                <div>
                    Pickup: {moment(pickupDate).format('dddd, MMMM Do YYYY')}
                </div>
            );
        }

        function describeShipmentContents() {
            if (
                lineItems.length === 0 ||
                lineItems[0].weightPerHandlingUnit === undefined
            ) {
                return <></>;
            }
            return (
                <div>Total Weight: {totalWeightForLineItems(lineItems)}lb</div>
            );
        }

        function describeAddress(location: AddLocationLocationType) {
            const detailParts = [
                location.address.city,
                location.address.stateOrProvinceCode,
                location.address.postalCode,
            ];

            const detailsDescription = detailParts.filter((p) => p).join(', ');

            const parts = [
                `Type: ${describeLocationType(location.locationType)}`,
                location.businessName,
                location.address.addressLine,
                detailsDescription,
            ];

            return parts
                .filter((p) => p)
                .map((part, index) => {
                    return <div key={index}>{part}</div>;
                });
        }

        function describeBroker() {
            if (shipment === undefined || shipment.broker === undefined) {
                return <></>;
            }
            const detailParts = [
                shipment.broker.address.city,
                shipment.broker.address.stateOrProvinceCode,
                shipment.broker.address.postalCode,
            ];

            const detailsDescription = detailParts.filter((p) => p).join(', ');

            const parts = [
                shipment.broker.businessName,
                shipment.broker.address.addressLine,
                detailsDescription,
            ];

            return parts
                .filter((p) => p)
                .map((part, index) => {
                    return <div key={index}>{part}</div>;
                });
        }

        function describePickupAddress() {
            if (!shipment) {
                return <></>;
            }
            return describeAddress(shipment.pickupLocation);
        }

        function describeDeliveryAddress() {
            if (!shipment) {
                return <></>;
            }
            return describeAddress(shipment.deliveryLocation);
        }

        function describePayment() {
            if (!shipment) {
                return <></>;
            }

            if (!creditCardBrand || !shipment.stripePaymentMethodId) {
                return <></>;
            }

            return (
                <HorizontalStack>
                    <div>{capitalize(creditCardBrand)} Securely Stored</div>
                </HorizontalStack>
            );
        }

        if (flowIdentifier === 'details') {
            return (
                <Stack align="left">
                    {describePickupDate()}
                    {describeShipmentContents()}
                </Stack>
            );
        } else if (flowIdentifier === 'quotes') {
            return <DescribeConfirmQuote shipment={shipment} />;
        } else if (flowIdentifier === 'pickup-address') {
            return describePickupAddress();
        } else if (flowIdentifier === 'delivery-address') {
            return describeDeliveryAddress();
        } else if (flowIdentifier === 'broker') {
            return describeBroker();
        } else if (flowIdentifier === 'payment') {
            return describePayment();
        } else {
            return '';
        }
    }
    return { additionalContentProvider };
}
