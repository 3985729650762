/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FreightClaimItemCondition } from './FreightClaimItemCondition';
import {
    FreightClaimItemConditionFromJSON,
    FreightClaimItemConditionFromJSONTyped,
    FreightClaimItemConditionToJSON,
} from './FreightClaimItemCondition';
import type { FreightClaimType } from './FreightClaimType';
import {
    FreightClaimTypeFromJSON,
    FreightClaimTypeFromJSONTyped,
    FreightClaimTypeToJSON,
} from './FreightClaimType';

/**
 * 
 * @export
 * @interface FreightClaimLineItem
 */
export interface FreightClaimLineItem {
    /**
     * 
     * @type {string}
     * @memberof FreightClaimLineItem
     */
    handlingUnitType?: string;
    /**
     * 
     * @type {number}
     * @memberof FreightClaimLineItem
     */
    numberHandlingUnits?: number;
    /**
     * 
     * @type {string}
     * @memberof FreightClaimLineItem
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof FreightClaimLineItem
     */
    length?: number;
    /**
     * 
     * @type {number}
     * @memberof FreightClaimLineItem
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof FreightClaimLineItem
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof FreightClaimLineItem
     */
    weightPerHandlingUnit?: number;
    /**
     * 
     * @type {FreightClaimItemCondition}
     * @memberof FreightClaimLineItem
     */
    condition?: FreightClaimItemCondition;
    /**
     * 
     * @type {number}
     * @memberof FreightClaimLineItem
     */
    piecesAffected?: number;
    /**
     * 
     * @type {number}
     * @memberof FreightClaimLineItem
     */
    piecesTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof FreightClaimLineItem
     */
    unitCost?: number;
    /**
     * 
     * @type {FreightClaimType}
     * @memberof FreightClaimLineItem
     */
    claimType?: FreightClaimType;
}

/**
 * Check if a given object implements the FreightClaimLineItem interface.
 */
export function instanceOfFreightClaimLineItem(value: object): boolean {
    return true;
}

export function FreightClaimLineItemFromJSON(json: any): FreightClaimLineItem {
    return FreightClaimLineItemFromJSONTyped(json, false);
}

export function FreightClaimLineItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): FreightClaimLineItem {
    if (json == null) {
        return json;
    }
    return {
        
        'handlingUnitType': json['handlingUnitType'] == null ? undefined : json['handlingUnitType'],
        'numberHandlingUnits': json['numberHandlingUnits'] == null ? undefined : json['numberHandlingUnits'],
        'description': json['description'] == null ? undefined : json['description'],
        'length': json['length'] == null ? undefined : json['length'],
        'width': json['width'] == null ? undefined : json['width'],
        'height': json['height'] == null ? undefined : json['height'],
        'weightPerHandlingUnit': json['weightPerHandlingUnit'] == null ? undefined : json['weightPerHandlingUnit'],
        'condition': json['condition'] == null ? undefined : FreightClaimItemConditionFromJSON(json['condition']),
        'piecesAffected': json['piecesAffected'] == null ? undefined : json['piecesAffected'],
        'piecesTotal': json['piecesTotal'] == null ? undefined : json['piecesTotal'],
        'unitCost': json['unitCost'] == null ? undefined : json['unitCost'],
        'claimType': json['claimType'] == null ? undefined : FreightClaimTypeFromJSON(json['claimType']),
    };
}

export function FreightClaimLineItemToJSON(value?: FreightClaimLineItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'handlingUnitType': value['handlingUnitType'],
        'numberHandlingUnits': value['numberHandlingUnits'],
        'description': value['description'],
        'length': value['length'],
        'width': value['width'],
        'height': value['height'],
        'weightPerHandlingUnit': value['weightPerHandlingUnit'],
        'condition': FreightClaimItemConditionToJSON(value['condition']),
        'piecesAffected': value['piecesAffected'],
        'piecesTotal': value['piecesTotal'],
        'unitCost': value['unitCost'],
        'claimType': FreightClaimTypeToJSON(value['claimType']),
    };
}

