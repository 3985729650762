import { useSavedLocationsApi } from 'apis';
import { cookieDomain } from 'environment';
import { useAuthentication } from 'Features/Authentication/Slices/authenticationSlice';
import { SavedLocation, User } from 'generated-openapi-client';
import { useOnce } from 'Hooks/useOnce';
import moment from 'moment';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useUserService } from 'Services/UserService';
import { BranchFilterOption, UserDropdown } from '../Components/UserDropdown';

export enum UserDropdownType {
    Quoting,
    Booking,
}

export function useUserDropdown(type: UserDropdownType) {
    const { user } = useAuthentication();
    const [loading, setLoading] = useState(true);
    const [cookies, setCookie, removeCookie] = useCookies([
        'fsFilterBookedShipmentsByUserId',
        'fsFilterBookedShipmentsByBranchId',
    ]);

    const userCookie = cookies.fsFilterBookedShipmentsByUserId as
        | string
        | undefined;

    const branchCookie = cookies.fsFilterBookedShipmentsByBranchId as
        | string
        | undefined;

    const [filterByUserId, setFilterByUserId] = useState(userCookie);
    const [filterByBranchId, setFilterByBranchId] = useState<
        string | undefined | BranchFilterOption
    >(branchCookie);

    const service = useUserService();
    const savedLocationsApi = useSavedLocationsApi();
    const [users, setUsers] = useState<undefined | User[]>();
    const [branches, setBranches] = useState<undefined | SavedLocation[]>();

    useOnce(async function () {
        setLoading(true);
        const _users = await service.listUsersForCompany();
        const _branches = await savedLocationsApi.getBranches();
        setUsers(_users);
        setBranches(_branches.items);
        setLoading(false);
    });

    if (user === undefined) {
        throw new Error('Should not use this if not authenticated');
    }

    if (loading) {
        return { element: () => <></>, usersLoading: true };
    }

    if (users === undefined || branches === undefined) {
        return {
            element: () => <></>,
            usersLoading: false,
            filterByUserId: undefined,
            filterByBranchId: undefined,
        };
    }

    if (users.length <= 1 && branches?.length === 0) {
        // Only one user and no branches - so don't offer the dropdown choice
        return {
            element: () => <></>,
            usersLoading: false,
            filterByUserId: undefined,
            filterByBranchId: undefined,
        };
    }

    return {
        usersLoading: false,
        element: (showUncodedOption: boolean) => (
            <UserDropdown
                type={type}
                user={user}
                users={users || []}
                branches={branches || []}
                selectedUserId={userCookie}
                selectedBranchId={branchCookie}
                onSelectUser={function (value) {
                    console.log(`Updating user cookie to ${value}`);
                    setFilterByUserId(value);
                    setFilterByBranchId(undefined);
                    if (value === undefined) {
                        removeCookie('fsFilterBookedShipmentsByUserId', {
                            domain: cookieDomain(),
                        });
                    } else {
                        setCookie('fsFilterBookedShipmentsByUserId', value, {
                            expires: moment().add(1, 'year').toDate(),
                            domain: cookieDomain(),
                            secure: true,
                        });
                    }
                }}
                onSelectBranch={function (value) {
                    console.log(`Updating branch cookie to ${value}`);
                    setFilterByBranchId(value);
                    if (value === undefined) {
                        removeCookie('fsFilterBookedShipmentsByBranchId', {
                            domain: cookieDomain(),
                        });
                    } else {
                        setCookie('fsFilterBookedShipmentsByBranchId', value, {
                            expires: moment().add(1, 'year').toDate(),
                            domain: cookieDomain(),
                            secure: true,
                        });
                    }
                }}
                showUncodedOption={showUncodedOption}
            />
        ),
        filterByUserId: filterByUserId,
        filterByBranchId: filterByBranchId,
    };
}
