import axios, { AxiosRequestConfig } from 'axios';
import { UUID } from 'Cargo/Types/types';

export enum CustomsDocsUploadMode {
    Initial = 'Initial',
    Add = 'Add',
    Replace = 'Replace',
}
export class CustomsDocsApi {
    baseUrl: string;
    accessToken: string;

    constructor(baseUrl: string, accessToken: string) {
        this.baseUrl = baseUrl;
        this.accessToken = accessToken;
    }

    async upload(
        shipmentId: UUID,
        mode: CustomsDocsUploadMode,
        files: Array<File>
    ): Promise<void> {
        const options: AxiosRequestConfig = {
            headers: {
                Authorization: `Bearer ${this.accessToken}`,
                'Content-Type': 'multipart/form-data',
            },

            // CS: Mar 2024, we had some issues with large files and timeouts after
            // what appeared to be 30 seconds, so increasing timeout to 2 minutes
            timeout: 120000,

            // CS: April 2023, why did we do this?
            // This basically means any status code resolves (rather than throws an exception)
            validateStatus: () => true,
        };

        const formData = new FormData();
        files.forEach(function (file, index) {
            console.log(`Appending ${file.name} ${file.size}`);
            formData.append(`file${index}`, file, file.name);
        });

        try {
            await axios.post(
                `${this.baseUrl}/customs-docs/upload?shipmentId=${shipmentId}&mode=${mode}`,
                formData,
                options
            );
        } catch (e) {
            if (axios.isAxiosError(e)) {
                console.error(
                    `Axios Error in Upload : ${e.code}, ${e.message}, ${e.stack}`
                );
            } else {
                console.error(`Unknown error on upload : ${e}`);
            }
        }
    }

    async uploadWithTrackingId(
        trackingId: UUID,
        mode: CustomsDocsUploadMode,
        files: Array<File>
    ): Promise<void> {
        const options = {
            headers: {
                Authorization: `Bearer ${this.accessToken}`,
                'Content-Type': 'multipart/form-data',
            },
            validateStatus: () => true,
        };

        const formData = new FormData();
        files.forEach(function (file, index) {
            console.log(`Appending ${file.name} ${file.size}`);
            formData.append(`file${index}`, file, file.name);
        });

        await axios.post(
            `${this.baseUrl}/customs-docs/upload-with-tracking-id?trackingId=${trackingId}&mode=${mode}`,
            formData,
            options
        );
    }
}
