/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LookupNmfcCodeNoDimensionsRequest
 */
export interface LookupNmfcCodeNoDimensionsRequest {
    /**
     * 
     * @type {string}
     * @memberof LookupNmfcCodeNoDimensionsRequest
     */
    nmfcItemNumber: string;
}

/**
 * Check if a given object implements the LookupNmfcCodeNoDimensionsRequest interface.
 */
export function instanceOfLookupNmfcCodeNoDimensionsRequest(value: object): boolean {
    if (!('nmfcItemNumber' in value)) return false;
    return true;
}

export function LookupNmfcCodeNoDimensionsRequestFromJSON(json: any): LookupNmfcCodeNoDimensionsRequest {
    return LookupNmfcCodeNoDimensionsRequestFromJSONTyped(json, false);
}

export function LookupNmfcCodeNoDimensionsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LookupNmfcCodeNoDimensionsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'nmfcItemNumber': json['nmfcItemNumber'],
    };
}

export function LookupNmfcCodeNoDimensionsRequestToJSON(value?: LookupNmfcCodeNoDimensionsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'nmfcItemNumber': value['nmfcItemNumber'],
    };
}

