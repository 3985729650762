import { AddLocationLocationType } from 'Features/Locations/Types/locationTypes';
import { LatitudeLongitude, ShipmentState } from 'generated-openapi-client';
import { SymbolLayer } from 'mapbox-gl';
import { getMarkerName } from './images';
import { latitudeLongitudeToNumbers } from './latitudeLongitudeToNumbers';

function getFeatureForPoint(
    latitudeLongitude: Required<LatitudeLongitude>
): GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> {
    return {
        type: 'Feature',
        properties: {},
        geometry: {
            type: 'Point',
            coordinates: latitudeLongitudeToNumbers(latitudeLongitude),
        },
    };
}

function getLayerForPoint(
    pickupOrDelivery: 'pickup' | 'delivery',
    shipmentState: ShipmentState
): SymbolLayer {
    return {
        id: pickupOrDelivery + 'Point',
        source: pickupOrDelivery + 'PointSource',
        type: 'symbol',
        layout: {
            'icon-image': getMarkerName(pickupOrDelivery, shipmentState),
            'icon-rotate': ['get', 'bearing'],
            'icon-rotation-alignment': 'map',
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
        },
    };
}

function addPickupMapLayer(
    map: mapboxgl.Map,
    latitudeLongitude: Required<LatitudeLongitude>,
    shipmentState: ShipmentState
) {
    map.addSource('pickupPointSource', {
        type: 'geojson',
        data: getFeatureForPoint(latitudeLongitude),
    });

    map.addLayer(getLayerForPoint('pickup', shipmentState));
}

function removePickupMapLayer(map: mapboxgl.Map) {
    if (map.getLayer('pickupPoint')) {
        map.removeLayer('pickupPoint');
    }
    if (map.getSource('pickupPointSource')) {
        map.removeSource('pickupPointSource');
    }
}

function addDeliveryMapLayer(
    map: mapboxgl.Map,
    latitudeLongitude: Required<LatitudeLongitude>,
    shipmentState: ShipmentState
) {
    map.addSource('deliveryPointSource', {
        type: 'geojson',
        data: getFeatureForPoint(latitudeLongitude),
    });

    map.addLayer(getLayerForPoint('delivery', shipmentState));
}

function removeDeliveryMapLayer(map: mapboxgl.Map) {
    if (map.getLayer('deliveryPoint')) {
        map.removeLayer('deliveryPoint');
    }
    if (map.getSource('deliveryPointSource')) {
        map.removeSource('deliveryPointSource');
    }
}

export function setPickupLocation(
    map: mapboxgl.Map,
    pickup: Partial<AddLocationLocationType> | undefined,
    shipmentState: ShipmentState
) {
    removePickupMapLayer(map);

    if (pickup === undefined || pickup.latitudeLongitude === undefined) {
        return;
    }

    const latitudeLongitude = pickup.latitudeLongitude;

    if (
        latitudeLongitude.latitude === undefined ||
        latitudeLongitude.longitude === undefined
    ) {
        return;
    }

    addPickupMapLayer(
        map,
        latitudeLongitude as Required<LatitudeLongitude>,
        shipmentState
    );
}

export function setDeliveryLocation(
    map: mapboxgl.Map,
    delivery: Partial<AddLocationLocationType> | undefined,
    shipmentState: ShipmentState
) {
    removeDeliveryMapLayer(map);

    if (delivery === undefined || delivery.latitudeLongitude === undefined) {
        return;
    }

    const latitudeLongitude = delivery.latitudeLongitude;

    if (
        latitudeLongitude.latitude === undefined ||
        latitudeLongitude.longitude === undefined
    ) {
        return;
    }

    addDeliveryMapLayer(
        map,
        latitudeLongitude as Required<LatitudeLongitude>,
        shipmentState
    );
}
