import Stack from 'Cargo/Layout/Stack';
import { ModalTitle } from 'Cargo/Text/Text';
import { UUID } from 'Cargo/Types/types';
import { Broker, SavedBroker } from 'generated-openapi-client';
import React, { useState } from 'react';
import ModifyBrokerModalAddressPage from '../Components/ModifyBrokerModalAddressPage';
import ModifyBrokerModalContactPage from '../Components/ModifyBrokerModalContactPage';

export interface ModifyBrokerModalCallbackData {
    broker: Broker;
    savedBrokerId: UUID;
}

interface BrokerModalProps {
    brokerToModify: SavedBroker;
    onFinished: (params: ModifyBrokerModalCallbackData) => void;
    onCancel: () => void;
}

function ModifyBrokerModal(props: BrokerModalProps) {
    const { onCancel, onFinished } = props;

    const [savedBroker, setSavedBroker] = useState<SavedBroker>(
        props.brokerToModify
    );

    enum Page {
        Address,
        Contact,
    }

    const [currentPage, setCurrentPage] = useState(Page.Address);

    function onSwitchToAddressPage() {
        setCurrentPage(Page.Address);
    }

    function onSwitchToContactPage() {
        setCurrentPage(Page.Contact);
    }

    async function onDone() {
        onFinished({
            broker: savedBroker.broker,
            savedBrokerId: savedBroker.savedBrokerId,
        });
    }

    return (
        <Stack align="center">
            <Stack align="left">
                <ModalTitle>Modify Broker</ModalTitle>

                <div style={{ minHeight: '330px' }}>
                    {currentPage === Page.Address && (
                        <ModifyBrokerModalAddressPage
                            businessName={savedBroker.broker.businessName}
                            setBusinessName={function (updatedBusinessName) {
                                setSavedBroker({
                                    ...savedBroker,
                                    broker: {
                                        ...savedBroker.broker,
                                        businessName: updatedBusinessName,
                                    },
                                });
                            }}
                            setAddress={function (updatedAddress) {
                                setSavedBroker({
                                    ...savedBroker,
                                    broker: {
                                        ...savedBroker.broker,
                                        address: updatedAddress,
                                    },
                                });
                            }}
                            address={savedBroker.broker.address}
                            onPrevious={onCancel}
                            onDone={onSwitchToContactPage}
                        />
                    )}

                    {currentPage === Page.Contact && (
                        <ModifyBrokerModalContactPage
                            setContact={function (updatedContact) {
                                setSavedBroker({
                                    ...savedBroker,
                                    broker: {
                                        ...savedBroker.broker,
                                        contact: updatedContact,
                                    },
                                });
                            }}
                            contact={savedBroker.broker.contact}
                            onPrevious={onSwitchToAddressPage}
                            onDone={onDone}
                        />
                    )}
                </div>
            </Stack>
        </Stack>
    );
}

export default ModifyBrokerModal;
