import { usePaymentMethodsApi } from 'apis';
import { CircleButton } from 'Cargo/Controls/CircleButton';
import Link from 'Cargo/Controls/Link';
import LinkButton from 'Cargo/Controls/LinkButton';
import FullWidthLayout from 'Cargo/Layout/FullWidthLayout';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import useConfirmModal from 'Cargo/Modal/useConfirmModal';
import PageTitle from 'Cargo/Text/PageTitle';
import { H1, Microcopy } from 'Cargo/Text/Text';
import { CreditCard, PaymentMethod } from 'generated-openapi-client';
import { BankAccount } from 'generated-openapi-client/models/BankAccount';
import { useEffect, useState } from 'react';
import { AddCreditCardButton } from './Components/AddCreditCardButton';
import CreditCardsSection from './Components/CreditCardsSection';
import useAddCreditCardModal from './Hooks/useAddCreditCardModal';
import { useShareCreditCardModal } from './Modals/ShareCreditCardModal';

function CreditCardsScreen() {
    const [creditCards, setCreditCards] = useState<Array<CreditCard>>([]);
    const [bankAccounts, setBankAccounts] = useState<Array<BankAccount>>([]);
    const [initialLoading, setInitialLoading] = useState(true);
    const [allowRemoveCardButton, setAllowRemoveCardButton] = useState(false);

    const paymentMethodsApi = usePaymentMethodsApi();
    const confirm = useConfirmModal(
        'Confirm',
        'Are you sure you want to remove this card'
    );

    async function loadCards() {
        const response = await paymentMethodsApi.getAllPaymentMethods();
        setCreditCards(response.creditCards);
        setBankAccounts(response.bankAccounts);
        setAllowRemoveCardButton(
            response.creditCards.length + response.bankAccounts.length > 1
        );
        setInitialLoading(false);
    }

    const showAddCreditCardModal = useAddCreditCardModal(() => {
        loadCards();
    });

    useEffect(() => {
        loadCards();
    }, []);

    async function removeCard(stripePaymentMethodId: string) {
        const confirmed = await confirm();

        if (confirmed) {
            await paymentMethodsApi.postPaymentMethodsRemove({
                removePaymentMethodRequest: {
                    stripePaymentMethodId,
                },
            });
            await loadCards();
        }
    }

    async function setDefaultCard(stripePaymentMethodId: string) {
        await paymentMethodsApi.postPaymentMethodsSetDefaultPaymentMethod({
            setDefaultPaymentMethodRequest: {
                stripePaymentMethodId: stripePaymentMethodId,
                paymentMethod: PaymentMethod.CreditCard,
                newPaymentMethod: false,
            },
        });
        await loadCards();
    }

    const noCreditCards = creditCards === undefined || creditCards.length === 0;
    const showShareModal = useShareCreditCardModal();

    return (
        <>
            <PageTitle>Credit Cards</PageTitle>
            <FullWidthLayout
                header={
                    <>
                        Credit Cards
                        <span
                            style={{
                                position: 'relative',
                                top: '-3px',
                                marginLeft: '6px',
                            }}
                        >
                            <CircleButton onClick={showAddCreditCardModal} />
                        </span>
                    </>
                }
                microcopy={
                    <>
                        You can manage your credit cards here. Don&apos;t have
                        access to the company credit card?{' '}
                        <Link onClick={() => showShareModal()}>
                            Click here to invite someone else to add it
                        </Link>
                        .
                    </>
                }
                loading={initialLoading}
                showEmptyState={noCreditCards}
                emptyState={
                    <Stack>
                        <H1>You don&apos;t have any credit cards saved yet</H1>
                        <Microcopy>
                            Adding a credit card to FreightSimple makes it
                            convenient and easy to pay for your freight.
                        </Microcopy>
                        <Spacer height={32} />
                        <AddCreditCardButton onClick={showAddCreditCardModal} />
                        <Spacer height={32} />
                        <LinkButton onClick={() => showShareModal()}>
                            Don&apos;t have access to the company credit card?
                            Click here to invite someone who does
                        </LinkButton>
                    </Stack>
                }
                content={
                    <>
                        <CreditCardsSection
                            creditCards={creditCards}
                            bankAccounts={bankAccounts}
                            setDefaultCard={setDefaultCard}
                            removeCard={removeCard}
                            allowRemoveCardButton={allowRemoveCardButton}
                        />
                    </>
                }
            />
        </>
    );
}

export default CreditCardsScreen;
