import Fade from 'Cargo/Animations/Fade';
import Colors from 'Cargo/Colors';
import Pill, { PillSize, PillStyle } from 'Cargo/Controls/Pill';
import CarrierLogo from 'Cargo/Icons/CarrierLogo';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import MediumPrice from 'Cargo/Text/MediumPrice';
import { Currency } from 'generated-openapi-client';
import moment from 'moment';
import { ReactNode } from 'react';
import styled from 'styled-components/macro';

interface QuoteRowHeaderProps {
    showSecondaryInfo: boolean;
    carrierIdentifier: string;
    serviceDisplayName: string;
    price: number;
    currency: Currency;
    deliveryDate: moment.Moment | undefined;
    earliestTransitDays: number | undefined;
    latestTransitDays: number | undefined;
    preferredDescription?: string;
    someRisk?: boolean;
    highRisk?: boolean;
}

const ServiceType = styled.div`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 20px;
    color: var(--freightsimple-color-normal-text);
`;

const HeaderLabel = styled.div`
    font-weight: var(--nhu-font-weight-light);
    font-size: 14px;
    color: var(--freightsimple-color-light-text);
`;

interface ExpectedDeliveryDateProps {
    children: ReactNode;
}

const ExpectedDeliveryDate = styled.div<ExpectedDeliveryDateProps>`
    font-weight: var(--nhu-font-weight-medium);
    font-size: 18px;
    color: ${(props) =>
        props.children === <>Unknown</>
            ? Colors.VeryLightText
            : Colors.NormalText};
`;

function QuoteRowHeader(props: QuoteRowHeaderProps) {
    const serviceType = props.serviceDisplayName;

    const formattedDeliveryDate =
        props.deliveryDate?.format('ddd Do MMMM YYYY') || 'Unknown';

    const carrierLogoColumnWidth = 206;
    const serviceTypeColumnWidth = 170;
    const expectedDeliveryDateColumnWidth = 296;
    const transitDaysColumnWidth = 140;

    enum TransitDaysType {
        Unknown,
        SameDay,
        Regular,
    }

    function getTransitDaysType(): TransitDaysType {
        if (props.earliestTransitDays === undefined) {
            return TransitDaysType.Unknown;
        } else if (props.latestTransitDays === undefined) {
            return TransitDaysType.Regular;
        } else {
            if (props.earliestTransitDays === props.latestTransitDays) {
                if (props.earliestTransitDays === 0) {
                    return TransitDaysType.SameDay;
                }
            }
            return TransitDaysType.Regular;
        }
    }

    function formatTransitDays(): ReactNode {
        if (props.earliestTransitDays === undefined) {
            return <>Unknown</>;
        } else if (props.latestTransitDays === undefined) {
            return <>{props.earliestTransitDays}</>;
        } else {
            // same, so just show one day
            if (props.earliestTransitDays === props.latestTransitDays) {
                if (props.earliestTransitDays === 0) {
                    return <>Same Day</>;
                }

                return <>props.earliestTransitDays</>;
            }
            return (
                <>
                    {props.earliestTransitDays}
                    <span style={{ color: '#BBB' }}>+</span>
                </>
            );
        }

        return <>Unknown</>;
    }

    const formattedTransitDays = formatTransitDays();
    const transitDaysType = getTransitDaysType();

    return (
        <HorizontalStack
            align="left"
            verticalAlign="middle"
            style={{ height: '96px' }}
        >
            <HorizontalStack
                verticalAlign="middle"
                style={{ width: carrierLogoColumnWidth + 'px', height: '100%' }}
            >
                <Stack align="left">
                    <CarrierLogo
                        carrierIdentifier={props.carrierIdentifier}
                        align={'left'}
                        width={150}
                        height={48}
                    />
                    {props.preferredDescription && (
                        <Pill
                            pillSize={PillSize.Small}
                            pillStyle={PillStyle.Blue}
                        >
                            {props.preferredDescription} Preferred
                        </Pill>
                    )}
                </Stack>
            </HorizontalStack>

            <Fade show={props.showSecondaryInfo}>
                <HorizontalStack align="spread" verticalAlign="middle">
                    <Stack align="left" width={serviceTypeColumnWidth + 'px'}>
                        <HeaderLabel>Service Level</HeaderLabel>
                        <ServiceType>{serviceType}</ServiceType>
                    </Stack>
                    <Spacer width={24} />
                    <Stack
                        align="left"
                        width={expectedDeliveryDateColumnWidth + 'px'}
                    >
                        <HeaderLabel>Earliest Delivery </HeaderLabel>
                        <ExpectedDeliveryDate>
                            {props.highRisk && (
                                <Pill pillStyle={PillStyle.Red}>High Risk</Pill>
                            )}
                            {props.someRisk && (
                                <Pill pillStyle={PillStyle.Yellow}>
                                    Some Risk
                                </Pill>
                            )}
                            <span
                                style={{
                                    marginLeft:
                                        props.someRisk || props.highRisk
                                            ? '8px'
                                            : '0',
                                }}
                            >
                                {formattedDeliveryDate}
                            </span>
                        </ExpectedDeliveryDate>
                    </Stack>
                    <Spacer width={24} />
                    <Stack align="left" width={transitDaysColumnWidth + 'px'}>
                        <HeaderLabel>Transit</HeaderLabel>
                        <HorizontalStack>
                            <ExpectedDeliveryDate>
                                {formattedTransitDays}
                            </ExpectedDeliveryDate>
                            {transitDaysType == TransitDaysType.Regular && (
                                <Stack
                                    align="left"
                                    style={{ marginLeft: '4px' }}
                                >
                                    <div
                                        style={{
                                            marginTop: '3px',
                                            fontSize: '8px',
                                            color: '#888',
                                        }}
                                    >
                                        Business
                                    </div>
                                    <div
                                        style={{
                                            marginTop: '-5px',
                                            fontSize: '8px',
                                            color: '#888',
                                        }}
                                    >
                                        Days
                                    </div>
                                </Stack>
                            )}
                        </HorizontalStack>
                    </Stack>
                    <Spacer width={24} />
                    <MediumPrice
                        currency={props.currency}
                        price={props.price}
                    />
                </HorizontalStack>
            </Fade>
        </HorizontalStack>
    );
}

export default QuoteRowHeader;
