import { useModal } from 'Cargo/Modal/useModal';
import { SavedBroker } from 'generated-openapi-client';
import React from 'react';
import ModifyBrokerModal, {
    ModifyBrokerModalCallbackData,
} from '../Modals/ModifyBrokerModal';

function useModifyBrokerModal() {
    const showModal = useModal<ModifyBrokerModalCallbackData>();

    async function show(brokerToModify: SavedBroker) {
        const p = new Promise<ModifyBrokerModalCallbackData | undefined>(
            (resolve) => {
                showModal(
                    (done) => {
                        return (
                            <ModifyBrokerModal
                                onCancel={() => done()}
                                onFinished={done}
                                brokerToModify={brokerToModify}
                            />
                        );
                    },
                    resolve,
                    {
                        preventBackdropClick: true,
                    }
                );
            }
        );

        return p;
    }

    return show;
}

export default useModifyBrokerModal;
