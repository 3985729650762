import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export const CURRENT_NMFC_DESCRIPTION_CACHE_SLICE_VERSION = 1;

interface NmfcDescriptionCacheItem {
    nmfcCode: string;
    description: string;
}

export interface NmfcDescriptionCacheSliceType {
    items: Array<NmfcDescriptionCacheItem>;

    // We store this slice in localStorage, but if the schema changes, we need a way to recognize that
    // and not load slices unless they match the current version
    sliceVersion: number;
}

function emptyNmfcDescriptionCacheSlice(): NmfcDescriptionCacheSliceType {
    return {
        items: [],
        sliceVersion: CURRENT_NMFC_DESCRIPTION_CACHE_SLICE_VERSION,
    };
}

function initialState(): NmfcDescriptionCacheSliceType {
    const serializedState = localStorage.getItem('nmfcDescriptionCacheSlice');

    if (serializedState) {
        try {
            const parsedState = JSON.parse(
                serializedState
            ) as NmfcDescriptionCacheSliceType;

            if (
                parsedState.sliceVersion === undefined ||
                parsedState.sliceVersion !==
                CURRENT_NMFC_DESCRIPTION_CACHE_SLICE_VERSION
            ) {
                // The data we have is too old - so throw it away
                return emptyNmfcDescriptionCacheSlice();
            }

            return parsedState;
        } catch (e) {
            console.warn('Failed to parse serialized getStartedSlice', { e });
            return emptyNmfcDescriptionCacheSlice();
        }
    } else {
        return emptyNmfcDescriptionCacheSlice();
    }
}

export const nmfcDescriptionCacheSlice = createSlice({
    name: 'nmfcDescriptionCacheSlice',
    initialState: initialState(),
    reducers: {
        cacheNmfcDescription(
            state,
            action: PayloadAction<NmfcDescriptionCacheItem>
        ) {
            const nmfcCode = action.payload.nmfcCode;
            const previouslyCachedItem = state.items.find(
                (i) => i.nmfcCode === nmfcCode
            );

            if (previouslyCachedItem === undefined) {
                state.items.push(action.payload);
            }
        },
    },
});

export const { cacheNmfcDescription } = nmfcDescriptionCacheSlice.actions;

export const writeNmfcDescriptionCacheSliceToLocalStorage = (
    state: NmfcDescriptionCacheSliceType
) => {
    localStorage.setItem('nmfcDescriptionCacheSlice', JSON.stringify(state));
};

export default nmfcDescriptionCacheSlice.reducer;

export function useNmfcDescription(nmfcCode: string | undefined) {
    const items = useSelector(
        (state: RootState) => state.nmfcDesciptionCache.items
    );

    if (nmfcCode === undefined) {
        return undefined;
    }

    console.log(`!!!! useNmfcDescription`, { items, nmfcCode })

    const previouslyFullCodeCachedItem = items.find((i) => i.nmfcCode === nmfcCode);

    if (previouslyFullCodeCachedItem !== undefined) {
        return previouslyFullCodeCachedItem?.description;
    }

    const nmfcMainCode = nmfcCode.split('-')[0];

    const previouslyCachedItem = items.find((i) => i.nmfcCode === nmfcMainCode);

    console.log(`useNmfcDescription`, { nmfcMainCode, previouslyCachedItem });

    return previouslyCachedItem?.description;
}
