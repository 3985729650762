/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';

/**
 * 
 * @export
 * @interface Broker
 */
export interface Broker {
    /**
     * 
     * @type {string}
     * @memberof Broker
     */
    businessName: string;
    /**
     * 
     * @type {Address}
     * @memberof Broker
     */
    address: Address;
    /**
     * 
     * @type {Contact}
     * @memberof Broker
     */
    contact: Contact;
}

/**
 * Check if a given object implements the Broker interface.
 */
export function instanceOfBroker(value: object): boolean {
    if (!('businessName' in value)) return false;
    if (!('address' in value)) return false;
    if (!('contact' in value)) return false;
    return true;
}

export function BrokerFromJSON(json: any): Broker {
    return BrokerFromJSONTyped(json, false);
}

export function BrokerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Broker {
    if (json == null) {
        return json;
    }
    return {
        
        'businessName': json['businessName'],
        'address': AddressFromJSON(json['address']),
        'contact': ContactFromJSON(json['contact']),
    };
}

export function BrokerToJSON(value?: Broker | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'businessName': value['businessName'],
        'address': AddressToJSON(value['address']),
        'contact': ContactToJSON(value['contact']),
    };
}

