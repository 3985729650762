import { ShipmentState } from 'generated-openapi-client';
import { ReactNode } from 'react';

export function timeLabelForShipmentStateVerbose(
    state: ShipmentState,
    appointmentDescription: string | undefined
): string {
    switch (state) {
        case ShipmentState.Quoted:
            return 'Proposed pickup';
        case ShipmentState.QuoteRequested:
            return 'Proposed pickup';
        case ShipmentState.BookingConfirmed:
            return 'Pickup booked for';
        case ShipmentState.BookingRequested:
            return 'Pickup requested for';
        case ShipmentState.BookingFailed:
            return 'Pickup requested for';
        case ShipmentState.InTransit:
            if (appointmentDescription) {
                return 'Delivery Appointment';
            } else {
                return 'Earliest Delivery';
            }

        case ShipmentState.Delivered:
            return 'Delivered';
        case ShipmentState.Cancelled:
            return 'Cancelled';
        case ShipmentState.Lost:
            return 'Lost';
        case ShipmentState.OnHold:
            return 'Pickup';
    }
}

export function timeLabelForShipmentState(
    state: ShipmentState,
    appointmentDate: string | undefined
): string | undefined {
    switch (state) {
        case ShipmentState.Quoted:
            return 'Proposed pickup';
        case ShipmentState.QuoteRequested:
            return 'Proposed pickup';
        case ShipmentState.BookingConfirmed:
            return 'Pickup booked for';
        case ShipmentState.BookingRequested:
            return 'Pickup requested for';
        case ShipmentState.BookingFailed:
            return 'Pickup requested for';
        case ShipmentState.InTransit:
            if (appointmentDate) {
                return 'Delivery Appointment';
            } else {
                return 'Earliest Delivery';
            }
        case ShipmentState.Delivered:
            return 'Delivered';
        case ShipmentState.Cancelled:
            return undefined;
        case ShipmentState.Lost:
            return undefined;
    }
}

export function timeLabel2ForShipmentState(
    state: ShipmentState,
    appointmentDate: string | undefined
): string | undefined {
    switch (state) {
        case ShipmentState.Quoted:
            return undefined;
        case ShipmentState.QuoteRequested:
            return undefined;
        case ShipmentState.BookingConfirmed:
            return undefined;
        case ShipmentState.BookingRequested:
            return undefined;
        case ShipmentState.BookingFailed:
            return undefined;
        case ShipmentState.InTransit:
            if (appointmentDate) {
                return undefined;
            } else {
                return 'Latest Delivery';
            }
        case ShipmentState.Delivered:
            return undefined;
        case ShipmentState.Cancelled:
            return undefined;
        case ShipmentState.Lost:
            return undefined;
    }
}

export function timeLabelTooltipForShipmentState(
    state: ShipmentState
): ReactNode | undefined {
    switch (state) {
        case ShipmentState.Quoted:
            return undefined;
        case ShipmentState.QuoteRequested:
            return undefined;
        case ShipmentState.BookingConfirmed:
            return undefined;
        case ShipmentState.BookingRequested:
            return undefined;
        case ShipmentState.BookingFailed:
            return undefined;
        case ShipmentState.InTransit:
            return (
                <>
                    <p>
                        While carriers make every effort to deliver a shipment
                        as early as possible, delays can happen for a number of
                        reasons.
                    </p>
                    <p>
                        The <strong>Earliest Delivery</strong> represents the
                        date the shipment will be delivered if no delays at all
                        happen.
                    </p>
                    <p>
                        The <strong>Latest Delivery</strong> is the worst case
                        prediction based on many delays. Typically shipments
                        arrive in the middle of this range.
                    </p>
                    <p>
                        Please note, this does not take into account any '
                        <strong>Delivery Appointment</strong>' that may have
                        been scheduled.
                    </p>
                </>
            );
        case ShipmentState.Delivered:
            return undefined;
        case ShipmentState.Cancelled:
            return undefined;
        case ShipmentState.Lost:
            return undefined;
    }
}
