import { UUID } from 'Cargo/Types/types';
import { AddContactContactType } from 'Features/Contacts/Types/contactTypes';
import { AddLineItemType } from 'Features/LineItems/Types/lineItemTypes';
import { AddLocationLocationType } from 'Features/Locations/Types/locationTypes';
import { useGetStartedApi, useShipmentsApi } from 'apis';
import {
    Contact,
    CreateShipment2Request,
    LineItem,
    PreQuoteLocation,
} from 'generated-openapi-client';
import { Currency } from '../generated-openapi-client/models/Currency';

interface ShipmentService {
    createShipment: (
        shipmentId: UUID,
        pickupDate: string,
        pickup: AddLocationLocationType,
        delivery: AddLocationLocationType,
        pickupContact: AddContactContactType | undefined,
        deliveryContact: AddContactContactType | undefined,
        lineItems: Array<AddLineItemType>,
        pickupReferenceNumber: string,
        deliveryReferenceNumber: string,
        addInsuranceToShipment: boolean,
        insuranceAmount: number,
        insuranceCurrency: Currency,
        branchId: UUID | undefined,
        deliveryDeadline: string | undefined,
        pickupDeadline: string | undefined
    ) => Promise<UUID>;
    selectQuote: (shipmentId: UUID, quoteId: UUID) => Promise<void>;
    bookShipment: (shipmentId: UUID) => Promise<void>;
    setStripePaymentMethodId: (
        shipmentId: UUID,
        stripePaymentMethodId: string
    ) => Promise<void>;
}

export function useShipmentService2(): ShipmentService {
    const shipmentApi = useShipmentsApi();
    const getStartedApi = useGetStartedApi();

    return {
        createShipment: async function (
            shipmentId: UUID,
            pickupDate: string,
            pickup: AddLocationLocationType,
            delivery: AddLocationLocationType,
            pickupContact: AddContactContactType | undefined,
            deliveryContact: AddContactContactType | undefined,
            lineItems: Array<AddLineItemType>,
            pickupReferenceNumber: string,
            deliveryReferenceNumber: string,
            addInsuranceToShipment: boolean,
            insuranceAmount: number,
            insuranceCurrency: Currency,
            branchId: UUID | undefined,
            deliveryDeadline: string | undefined,
            pickupDeadline: string | undefined
        ): Promise<UUID> {
            if (pickup == undefined) {
                throw new Error('pickup is undefined');
            }

            if (delivery == undefined) {
                throw new Error('delivery is undefined');
            }

            const lineItemsForRequest = lineItems.map((li) => {
                return li as LineItem;
            });

            const request: CreateShipment2Request = {
                shipmentId,
                pickupDate,
                pickupLocation: pickup as PreQuoteLocation,
                deliveryLocation: delivery as PreQuoteLocation,
                pickupContact: pickupContact as Contact | undefined,
                deliveryContact: deliveryContact as Contact | undefined,
                lineItems: lineItemsForRequest,
                pickupReferenceNumber,
                deliveryReferenceNumber,
                addInsuranceToShipment,
                insuranceAmount,
                insuranceCurrency,
                branchId,
                deliveryDeadline,
                pickupDeadline,
            };

            try {
                await shipmentApi.postShipmentsCreate2({
                    createShipment2Request: request,
                });
            } catch (e) {
                console.error('ShipmentService.createShipment - exception', {
                    e,
                    request,
                });
                throw e;
            }

            return shipmentId;
        },

        selectQuote: async function (shipmentId: UUID, quoteId: UUID) {
            await shipmentApi.postShipmentsSelectQuote({
                selectQuoteRequest: {
                    shipmentId,
                    quoteId,
                },
            });
        },

        bookShipment: async function (shipmentId: UUID) {
            await shipmentApi.postBookShipment({
                bookShipmentRequest: {
                    shipmentId,
                },
            });
        },

        setStripePaymentMethodId: async function (
            shipmentId: UUID,
            stripePaymentMethodId: string
        ): Promise<void> {
            return await getStartedApi.postGetStartedSetStripePaymentMethodId({
                getStartedSetStripePaymentMethodId: {
                    stripePaymentMethodId: stripePaymentMethodId as string,
                    shipmentId: shipmentId as string,
                    newPaymentMethod: true,
                },
            });
        },
    };
}
