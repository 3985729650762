import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import { AddLineItemType } from 'Features/LineItems/Types/lineItemTypes';
import { AddLocationLocationType } from 'Features/Locations/Types/locationTypes';
import DeliveryColumn from 'Features/Quotes/Components/QuoteRow/DeliveryColumn';
import PickupColumnForPickup from 'Features/Quotes/Components/QuoteRow/PickupColumnForPickup';
import Progress from 'Features/Quotes/Components/QuoteRow/Progress';
import TransitColumnForTracking from 'Features/Quotes/Components/QuoteRow/TransitColumnForTracking';
import { describeHours } from 'Helpers/describeHours';
import {
    Broker,
    Contact,
    LineItem,
    Location,
    ShipmentState,
    TrackingLine,
} from 'generated-openapi-client';
import { MostRecentTrackingLine } from './DetailsSection';
import ViewShipmentSection from './ViewShipmentSection';

// Like Quote object, but with fewer required fields
export interface QuoteLike {
    carrierIdentifier: string;
    serviceDisplayName: string;
    transitBusinessDays?: number;
}

interface DetailsSectionForTrackingProps {
    pickupDate: moment.Moment;
    pickupHours: string | undefined;
    pickupLocation: Location | AddLocationLocationType;
    deliveryLocation: Location | AddLocationLocationType;
    pickupContact: Contact | undefined;
    deliveryContact: Contact | undefined;
    deliveryDeadline: string | undefined;
    lineItems: Array<LineItem | AddLineItemType>;
    quote: QuoteLike; // Might not have all the info when rendering for tracking
    shipmentState: ShipmentState;
    actualDeliveryDate: string | undefined;
    actualDeliveryTime: string | undefined;
    proNumber: string | undefined;
    expectedDeliveryDate: string | undefined;
    latestExpectedDeliveryDate: string | undefined;
    pickupReferenceNumber: string | undefined;
    deliveryReferenceNumber: string | undefined;
    notes: string | undefined;
    broker: Broker | undefined;
    mostRecentTrackingLine: TrackingLine | undefined;
    pickupBoothNumber: string | undefined;
    deliveryBoothNumber: string | undefined;
}
function DetailsSectionForTracking(props: DetailsSectionForTrackingProps) {
    const {
        pickupDate,
        pickupLocation,
        pickupContact,
        pickupReferenceNumber,
        deliveryLocation,
        deliveryContact,
        deliveryDeadline,
        lineItems,
        quote,
        shipmentState,
        actualDeliveryDate,
        actualDeliveryTime,
        expectedDeliveryDate,
        latestExpectedDeliveryDate,
        proNumber,
        deliveryReferenceNumber,
        notes,
        broker,
        mostRecentTrackingLine,
        pickupBoothNumber,
        deliveryBoothNumber,
    } = props;

    const deliveryHours = describeHours(deliveryLocation.hours);

    return (
        <ViewShipmentSection title="Details" id="details-section">
            <MostRecentTrackingLine
                mostRecentTrackingLine={mostRecentTrackingLine}
            />
            <Progress shipmentState={shipmentState} />
            <Spacer height={16}></Spacer>
            <HorizontalStack width="100%" verticalAlign="top">
                <PickupColumnForPickup
                    lineItems={lineItems}
                    pickupDate={pickupDate}
                    pickupLocation={pickupLocation}
                    pickupContact={pickupContact}
                    pickupReferenceNumber={pickupReferenceNumber}
                    pickupBoothNumber={pickupBoothNumber}
                    shipmentState={shipmentState}
                />
                <TransitColumnForTracking
                    quote={quote}
                    shipmentState={shipmentState}
                    proNumber={proNumber}
                    notes={notes}
                    broker={broker}
                />
                <DeliveryColumn
                    quote={quote}
                    deliveryHours={deliveryHours}
                    deliveryLocation={deliveryLocation}
                    deliveryContact={deliveryContact}
                    deliveryDeadline={deliveryDeadline}
                    shipmentState={shipmentState}
                    actualDeliveryDate={actualDeliveryDate}
                    actualDeliveryTime={actualDeliveryTime}
                    expectedDeliveryDate={expectedDeliveryDate}
                    deliveryReferenceNumber={deliveryReferenceNumber}
                    deliveryBoothNumber={deliveryBoothNumber}
                    latestExpectedDeliveryDate={latestExpectedDeliveryDate}
                    showNotesSection={true}
                />
            </HorizontalStack>
        </ViewShipmentSection>
    );
}
export default DetailsSectionForTracking;
