import Button from 'Cargo/Controls/Button';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import { useModal } from 'Cargo/Modal/useModal';
import { Label, ModalTitle } from 'Cargo/Text/Text';
import { UUID } from 'Cargo/Types/types';
import ContactQuestionBubble from 'Features/BookShipment/Components/Addresses/QuestionBubbles/ContactQuestionBubble';
import {
    Contact,
    LocationContext,
    SavedContact,
} from 'generated-openapi-client';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import LargeContactInput from '../Components/LargeContactInput';
import { AddContactContactType } from '../Types/contactTypes';
import {
    EmailRequired,
    errorMessagesForContact,
} from '../Validators/errorMessagesForContact';

interface AddContactProps {
    onAddNewContact: (_: SavedContact) => void;
    savedLocationId: UUID;
    onCancel: () => void;
}

function AddContact(props: AddContactProps) {
    const { onCancel } = props;
    const [newContact, setNewContact] = useState<AddContactContactType>({});
    const [forceValidation, setForceValidation] = useState(false);

    const errorMessages = errorMessagesForContact(
        newContact,
        EmailRequired.EmailNotRequired
    );

    function isValid() {
        if (
            errorMessages.contactName ||
            errorMessages.emailAddress ||
            errorMessages.phoneNumber
        ) {
            return false;
        } else {
            return true;
        }
    }

    async function onAdd() {
        if (!isValid()) {
            setForceValidation(true);
        }

        props.onAddNewContact({
            savedContactId: uuidv4(),
            savedLocationId: props.savedLocationId,
            contact: newContact as Contact,
        });
    }

    return (
        <>
            <Label>
                Contact Details
                <ContactQuestionBubble context={LocationContext.Any} />
            </Label>
            <LargeContactInput
                contact={newContact}
                contactChanged={function (
                    updatedContact: AddContactContactType
                ) {
                    setNewContact({
                        ...newContact,
                        ...updatedContact,
                    });
                }}
                forceValidation={forceValidation}
                errorMessages={errorMessages}
            ></LargeContactInput>
            <Spacer height={32} />
            <HorizontalStack width="100%" align="spread">
                <Button onClick={onCancel} secondary>
                    Cancel
                </Button>
                <Button onClick={onAdd}>Add New Contact</Button>
            </HorizontalStack>
        </>
    );
}

interface AddContactModalProps {
    savedLocationId: UUID;
    onAddNewContact: (_: SavedContact) => void;
    onCancel: () => void;
}

export function AddContactModal(props: AddContactModalProps) {
    return (
        <>
            <ModalTitle>Add another contact</ModalTitle>

            <AddContact
                onAddNewContact={props.onAddNewContact}
                onCancel={props.onCancel}
                savedLocationId={props.savedLocationId}
            />
        </>
    );
}

function useAddContactModal() {
    const showAddContactModal = useModal<SavedContact>();

    function show(savedLocationId: UUID) {
        const p = new Promise<SavedContact | undefined>((resolve) => {
            showAddContactModal(
                (done) => {
                    return (
                        <AddContactModal
                            savedLocationId={savedLocationId}
                            onAddNewContact={done}
                            onCancel={() => done()}
                        ></AddContactModal>
                    );
                },
                (data) => {
                    resolve(data);
                }
            );
        });

        return p;
    }

    return show;
}

export default useAddContactModal;
