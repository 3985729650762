import { InvalidLabel } from 'Cargo/Controls/Input';
import LinkButton from 'Cargo/Controls/LinkButton';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { ErrorMessageType } from 'Cargo/Validation';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import styled from 'styled-components/macro';

const LinkHolder = styled.div`
    margin-left: 16px;
    margin-top: 16px;
`;

interface DeadlineDateDropdownProps {
    date: moment.Moment | undefined;
    pickupDate: string;
    onDateChange: (date: moment.Moment | undefined) => void;
    errorMessage: ErrorMessageType;
    forceValidation: boolean;
    disabled: boolean;
}

export const DeadlineDateDropdown: React.FC<
    DeadlineDateDropdownProps
> = (props: DeadlineDateDropdownProps) => {
    const [focused, setFocused] = useState<boolean>(false);
    const momentDate = props.date ?? null;
    const today = moment().startOf('day');
    const firstAllowedDate = moment(props.pickupDate)
        .add(1, 'day')
        .startOf('day');
    const lastAllowedDate = today.clone().add(3, 'month');

    function isOutsideRange(date: Moment) {
        return date.isBefore(firstAllowedDate) || date.isAfter(lastAllowedDate);
    }

    function onDateChange(date: moment.Moment | null) {
        if (!date) return;

        props.onDateChange(date);
    }

    function showErrorMessage() {
        return props.forceValidation && props.errorMessage !== undefined;
    }

    // Any changes to the parameters here, shold be considered for DatePickerControl.tsx
    // too
    return (
        <>
            <Stack align="left">
                <HorizontalStack verticalAlign="middle" id="delivery-deadline">
                    <SingleDatePicker
                        id="delivery-deadline-date-dropdown"
                        date={momentDate}
                        onDateChange={onDateChange}
                        focused={focused}
                        onFocusChange={({ focused }) =>
                            setFocused(focused || false)
                        }
                        hideKeyboardShortcutsPanel={true}
                        isOutsideRange={(date) => {
                            return isOutsideRange(date);
                        }}
                        displayFormat="ddd, MMMM Do YYYY"
                        openDirection="down"
                        initialVisibleMonth={() => moment()}
                        placeholder="Select Deadline"
                        disabled={props.disabled}
                    />
                    {momentDate !== null && (
                        <LinkHolder>
                            <LinkButton
                                onClick={function () {
                                    props.onDateChange(undefined);
                                }}
                            >
                                Clear
                            </LinkButton>
                        </LinkHolder>
                    )}
                </HorizontalStack>
                {showErrorMessage() && (
                    <>
                        <Spacer height={8} />
                        <InvalidLabel>{props.errorMessage}</InvalidLabel>
                    </>
                )}
            </Stack>
        </>
    );
};
