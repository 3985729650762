import Colors from 'Cargo/Colors';
import React from 'react';
import styled from 'styled-components/macro';

const Bar = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${Colors.Blue};
    animation: animate 30s ease;

    @keyframes animate {
        0% {
            width: 0%;
        }
        100% {
            width: 100%;
        }
    }
`;

function ProgressBar() {
    return (
        <div
            style={{
                position: 'absolute',
                width: '510px',
                height: '5px',
                backgroundColor: Colors.LightGray,
            }}
        >
            <Bar />
        </div>
    );
}
export default ProgressBar;
