import Button from 'Cargo/Controls/Button';
import Input from 'Cargo/Controls/Input';
import { QuestionBubbleContext } from 'Cargo/Controls/QuestionBubble';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import { useModalsSlice } from 'Cargo/Modal/modalsSlice';
import { useModal } from 'Cargo/Modal/useModal';
import { Legalese, ModalTitle } from 'Cargo/Text/Text';
import NotesQuestionBubble from 'Features/BookShipment/Components/Addresses/QuestionBubbles/NotesQuestionBubble';
import {
    BoothNumberQuestionBubble,
    ReferenceNumberQuestionBubble,
} from 'Features/BookShipment/Components/Addresses/QuestionBubbles/ReferenceNumberQuestionBubble';
import { findSavedLocationForLocation } from 'Features/Locations/Helpers/findSavedLocationForLocation';
import { useSavedLocations } from 'Features/Locations/Hooks/useSavedLocations';
import { useSavedLocationsService } from 'Features/Locations/Services/SavedLocationsService';
import { useShipmentService } from 'Services/ShipmentService';
import {
    LocationType,
    SavedLocation,
    Shipment,
    ShipmentState,
} from 'generated-openapi-client';
import { LocationContext } from 'generated-openapi-client/models/LocationContext';
import { useState } from 'react';
import { useUpdatedBOLModal } from './UpdatedBOLModal';
import { ReferenceNumberInput } from 'Features/ReferenceNumbers/Components/ReferenceNumberInput';
import { useValidateReferenceNumber } from 'Features/ReferenceNumbers/Hooks/useValidateReferenceNumber';

function findSavedLocationId(
    locationContext: LocationContext,
    shipment: Shipment,
    savedLocations: Array<SavedLocation>
) {
    const location =
        locationContext === LocationContext.Pickup
            ? shipment.pickupLocation
            : shipment.deliveryLocation;

    return findSavedLocationForLocation(savedLocations, location)
        ?.savedLocationId;
}

interface ModifyReferenceAndNotesModalProps {
    shipmentId: string;
    referenceNumber: string;
    boothNumber: string;
    notes: string;
    onDone: (result: ReferenceNumberAndNotes) => void;
    onCancel: () => void;
    locationContext: LocationContext | undefined;
    locationType: LocationType;
}

function ModifyReferenceAndNotesModal(
    props: ModifyReferenceAndNotesModalProps
) {
    const { onCancel, locationContext } = props;
    const [referenceNumber, setReferenceNumber] = useState(
        props.referenceNumber
    );
    const [notes, setNotes] = useState(props.notes);
    const [boothNumber, setBoothNumber] = useState(props.boothNumber);

    const validation = useValidateReferenceNumber({
        locationContext: props.locationContext,
        originalReferenceNumber: props.referenceNumber,
        shipmentId: props.shipmentId,
    });

    function onDone() {
        props.onDone({ referenceNumber, boothNumber, notes });
    }

    const displayName =
        locationContext === LocationContext.Pickup ? 'Pickup' : 'Delivery';
    return (
        <div style={{ width: '420px' }}>
            <ModalTitle>Change Reference and Notes</ModalTitle>
            {props.locationContext === LocationContext.Pickup && (
                <Legalese>
                    Please include any notes or reference numbers that would
                    help the driver complete this pickup
                </Legalese>
            )}
            {props.locationContext === LocationContext.Delivery && (
                <Legalese>
                    Please include any notes or reference numbers that would
                    help the driver complete this delivery
                </Legalese>
            )}

            <Spacer height={16} />

            <ReferenceNumberInput
                validation={validation}
                label={
                    <>
                        {displayName} Reference Number
                        <ReferenceNumberQuestionBubble
                            displayName={displayName}
                            context={QuestionBubbleContext.VerySmall}
                        />
                    </>
                }
                width={400}
                value={referenceNumber}
                onChange={(newValue: string) => setReferenceNumber(newValue)}
            />

            {props.locationType === LocationType.TradeShow && (
                <>
                    <Spacer height={16} />
                    <Input
                        label={
                            <>
                                {displayName} Booth Number
                                <BoothNumberQuestionBubble
                                    displayName={displayName}
                                    context={QuestionBubbleContext.VerySmall}
                                />
                            </>
                        }
                        type="text"
                        width={400}
                        value={boothNumber}
                        onChange={(newValue: string) =>
                            setBoothNumber(newValue)
                        }
                    />
                </>
            )}

            <Spacer height={16} />
            <Input
                label={
                    <>
                        Notes
                        <NotesQuestionBubble
                            displayName={displayName}
                            context={QuestionBubbleContext.VerySmall}
                        />
                    </>
                }
                placeholder="eg. Blue dock door, or ring bell"
                type="text"
                width={400}
                value={notes}
                onChange={(newValue: string) => setNotes(newValue)}
                errorMessage={undefined}
            />

            <Spacer height={24} />
            <HorizontalStack width="100%" align="spread">
                <Button secondary onClick={onCancel}>
                    Cancel
                </Button>

                <Button onClick={onDone}>Done</Button>
            </HorizontalStack>
        </div>
    );
}

interface ReferenceNumberAndNotes {
    referenceNumber: string;
    boothNumber: string;
    notes: string;
}

function useModifyReferenceAndNotesAfterBookingModalImplementation(
    locationContext: LocationContext,
    locationType: LocationType
) {
    const showChangeContactModal = useModal<ReferenceNumberAndNotes>();

    async function show(
        shipmentId: string,
        referenceNumber: string,
        boothNumber: string,
        notes: string
    ) {
        const p = new Promise<ReferenceNumberAndNotes | undefined>(
            (resolve) => {
                showChangeContactModal(
                    (done) => {
                        return (
                            <ModifyReferenceAndNotesModal
                                shipmentId={shipmentId}
                                referenceNumber={referenceNumber}
                                boothNumber={boothNumber}
                                notes={notes}
                                onDone={done}
                                onCancel={done}
                                locationContext={locationContext}
                                locationType={locationType}
                            ></ModifyReferenceAndNotesModal>
                        );
                    },
                    (data) => {
                        resolve(data);
                    }
                );
            }
        );

        return p;
    }

    return show;
}

function useModifyReferenceAndNotesAfterBookingModal(
    shipment: Shipment,
    locationContext: LocationContext
) {
    const locationType =
        locationContext === LocationContext.Pickup
            ? shipment.pickupLocation.locationType
            : shipment.deliveryLocation.locationType;

    const showModal = useModifyReferenceAndNotesAfterBookingModalImplementation(
        locationContext,
        locationType
    );
    const showUpdatedBOLModal = useUpdatedBOLModal();
    const shipmentService = useShipmentService();
    const savedLocationsService = useSavedLocationsService();
    const { setLoading } = useModalsSlice();
    const { savedLocations } = useSavedLocations();

    // Returns if the broker has changed, hence we should reload
    async function show(): Promise<boolean> {
        const savedLocationId = findSavedLocationId(
            locationContext,
            shipment,
            savedLocations
        );

        const originalNotes =
            locationContext === LocationContext.Pickup
                ? shipment.pickupLocation.notes
                : shipment.deliveryLocation.notes;

        const originalReference =
            locationContext === LocationContext.Pickup
                ? shipment.pickupReferenceNumber
                : shipment.deliveryReferenceNumber;

        const originalBoothNumber =
            locationContext === LocationContext.Pickup
                ? shipment.pickupBoothNumber
                : shipment.deliveryBoothNumber;

        console.log(`savedLocationId=${savedLocationId}`);

        if (savedLocationId === undefined) {
            // We should handle this - seems like a real edge case
            // where they have modified the location after booking
            throw new Error('No saved location found');
        }

        const updatedInfo = await showModal(
            shipment.shipmentId,
            originalReference,
            originalBoothNumber,
            originalNotes
        );

        console.log(`updatedInfo`, { updatedInfo });

        if (updatedInfo === undefined) {
            // Cancel pressed
            return false;
        }

        if (
            originalNotes === updatedInfo.notes &&
            originalReference === updatedInfo.referenceNumber
        ) {
            // Nothing changed
            return false;
        }

        setLoading(true);

        const savedLocation = savedLocations.find(
            (sl) => sl.savedLocationId === savedLocationId
        );
        if (savedLocation === undefined) {
            throw new Error('Should not happen');
        }

        const locationToUpdate = savedLocation.location;
        locationToUpdate.notes = updatedInfo.notes;

        // Make an API call to add the notes changed into the saved location
        await savedLocationsService.modifyLocation(
            savedLocationId,
            locationToUpdate
        );

        // Make an API call to change the reference/notes on the shipment
        await shipmentService.updateReferenceAndNotes(
            shipment.shipmentId,
            locationContext,
            updatedInfo.referenceNumber,
            updatedInfo.boothNumber,
            updatedInfo.notes
        );
        setLoading(false);

        // If the shipment is in transit, then it's too late to give the driver
        // a new BOL. But if it's still showing as pending pickup, it'd worth showing them this screen
        if (shipment.shipmentState !== ShipmentState.InTransit) {
            await showUpdatedBOLModal();
        }

        // Do we need to do something with the custom documents?
        // CS: Jan 2022: I'm currently taking the stance that we do nothing
        // - If they're not yet uploaded then nothing to do anyway
        // - If they have been uploaded, then they're still probably mostly valid, and hopefully
        //   the carrier can work with them
        // We should at some point give the customer the ability to upload replacement customs docs

        return true;
    }

    return show;
}

export default useModifyReferenceAndNotesAfterBookingModal;
