/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetStartedSetStripePaymentMethodId
 */
export interface GetStartedSetStripePaymentMethodId {
    /**
     * 
     * @type {string}
     * @memberof GetStartedSetStripePaymentMethodId
     */
    stripePaymentMethodId: string;
    /**
     * 
     * @type {string}
     * @memberof GetStartedSetStripePaymentMethodId
     */
    shipmentId: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetStartedSetStripePaymentMethodId
     */
    newPaymentMethod: boolean;
}

/**
 * Check if a given object implements the GetStartedSetStripePaymentMethodId interface.
 */
export function instanceOfGetStartedSetStripePaymentMethodId(value: object): boolean {
    if (!('stripePaymentMethodId' in value)) return false;
    if (!('shipmentId' in value)) return false;
    if (!('newPaymentMethod' in value)) return false;
    return true;
}

export function GetStartedSetStripePaymentMethodIdFromJSON(json: any): GetStartedSetStripePaymentMethodId {
    return GetStartedSetStripePaymentMethodIdFromJSONTyped(json, false);
}

export function GetStartedSetStripePaymentMethodIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStartedSetStripePaymentMethodId {
    if (json == null) {
        return json;
    }
    return {
        
        'stripePaymentMethodId': json['stripePaymentMethodId'],
        'shipmentId': json['shipmentId'],
        'newPaymentMethod': json['newPaymentMethod'],
    };
}

export function GetStartedSetStripePaymentMethodIdToJSON(value?: GetStartedSetStripePaymentMethodId | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'stripePaymentMethodId': value['stripePaymentMethodId'],
        'shipmentId': value['shipmentId'],
        'newPaymentMethod': value['newPaymentMethod'],
    };
}

