import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Animation from 'Cargo/Animations/Animation';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import styled from 'styled-components/macro';

export enum ReactionType {
    Success,
}

export interface ReactionsSliceType {
    currentReaction: undefined | ReactionType;
}

function emptyReactionsSlice(): ReactionsSliceType {
    return {
        currentReaction: undefined,
    };
}

export const reactionsSlice = createSlice({
    name: 'reactions',
    initialState: emptyReactionsSlice(),
    reducers: {
        showReactionAction(state, action: PayloadAction<ReactionType>) {
            state.currentReaction = action.payload;
        },

        clearReactionAction(state) {
            state.currentReaction = undefined;
        },
    },
});

const { showReactionAction, clearReactionAction } = reactionsSlice.actions;

export function useReaction() {
    const { currentReaction } = useSelector(
        (state: RootState) => state.reactions
    );
    const dispatch = useDispatch();
    function showReaction(reaction: ReactionType) {
        dispatch(showReactionAction(reaction));
        setTimeout(function () {
            dispatch(clearReactionAction());
        }, 2500);
    }

    return { showReaction, currentReaction };
}

function reactionContent(currentReaction: ReactionType) {
    switch (currentReaction) {
        case ReactionType.Success:
            return (
                <div
                    style={{
                        width: '128px',
                        height: '128px',
                        margin: 'auto',
                        position: 'relative',
                        top: 'calc(50vh - 64px)',
                    }}
                >
                    <Animation
                        pathToAnimation="/animations/success-overlay.json"
                        lottieFilesUrl="https://lottiefiles.com/645-success"
                        loop={false}
                        speed={1.0}
                    />
                </div>
            );
    }
}

const Overlay = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000000;
`;

export function useReactionOverlay() {
    const { currentReaction } = useReaction();
    function renderReactions() {
        if (currentReaction === undefined) {
            return <></>;
        }

        return <Overlay>{reactionContent(currentReaction)}</Overlay>;
    }

    return { renderReactions };
}
