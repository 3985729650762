import { AddLocationLocationType } from 'Features/Locations/Types/locationTypes';
import { LatitudeLongitude, ShipmentState } from 'generated-openapi-client';
import { calculatePointOnArc } from 'Helpers/calculatePointsOnArc';
import { MutableRefObject } from 'react';
import { latitudeLongitudeToNumbers } from './latitudeLongitudeToNumbers';

export function animateMarker(
    map: mapboxgl.Map,
    pickup: Partial<AddLocationLocationType>,
    delivery: Partial<AddLocationLocationType>,
    position: number
) {
    const missingPickupPoint =
        pickup == undefined ||
        pickup.latitudeLongitude === undefined ||
        pickup.latitudeLongitude.latitude === undefined ||
        pickup.latitudeLongitude.longitude === undefined;

    const missingDeliveryPoint =
        delivery == undefined ||
        delivery.latitudeLongitude === undefined ||
        delivery.latitudeLongitude.latitude === undefined ||
        delivery.latitudeLongitude.longitude === undefined;

    if (missingPickupPoint || missingDeliveryPoint) {
        return;
    }

    const point = latitudeLongitudeToNumbers(
        calculatePointOnArc(
            pickup?.latitudeLongitude as Required<LatitudeLongitude>,
            delivery?.latitudeLongitude as Required<LatitudeLongitude>,
            position
        )
    );

    if (map.getSource('markerSource')) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        map.getSource('markerSource').setData({
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'Point',
                coordinates: point,
            },
        });
    }
}

function arrowMarker(state: ShipmentState) {
    switch (state) {
        case ShipmentState.Quoted:
            return 'animated-marker-pin';
        case ShipmentState.QuoteRequested:
            return 'animated-marker-pin';
        case ShipmentState.BookingConfirmed:
            return 'animated-marker-arrow';
        case ShipmentState.BookingRequested:
            return 'animated-marker-arrow';
        case ShipmentState.InTransit:
            return 'animated-marker-pin';
        case ShipmentState.Delivered:
            return 'animated-marker-arrow-green';
        case ShipmentState.Cancelled:
            return 'animated-marker-arrow';
        case ShipmentState.Lost:
            return 'animated-marker-arrow';
    }
}

function calculateBearing(
    pickup: Partial<AddLocationLocationType> | undefined,
    delivery: Partial<AddLocationLocationType> | undefined,
    position: number
) {
    const currentLoc = latitudeLongitudeToNumbers(
        calculatePointOnArc(
            pickup?.latitudeLongitude as Required<LatitudeLongitude>,
            delivery?.latitudeLongitude as Required<LatitudeLongitude>,
            position
        )
    );

    const locInFront = latitudeLongitudeToNumbers(
        calculatePointOnArc(
            pickup?.latitudeLongitude as Required<LatitudeLongitude>,
            delivery?.latitudeLongitude as Required<LatitudeLongitude>,
            position + 0.1
        )
    );

    /*
        
        
                        |
                        |
                        | deltaY
                        |
                        |
                        |
    -------------------
    deltaX

    So the angle from true north is (180-theta) - 90
    */

    const deltaX = locInFront[0] - currentLoc[0];
    const deltaY = locInFront[1] - currentLoc[1];

    // atan2 gives the angle from the positive x axis
    const atan2 = (180 / 3.14159) * Math.atan2(deltaY, deltaX);
    const bearing = 90 - atan2;

    return bearing;
}

function addMarker(
    map: mapboxgl.Map,
    pickup: Partial<AddLocationLocationType>,
    delivery: Partial<AddLocationLocationType>,
    shipmentState: ShipmentState
) {
    const missingPickupPoint =
        pickup == undefined ||
        pickup.latitudeLongitude === undefined ||
        pickup.latitudeLongitude.latitude === undefined ||
        pickup.latitudeLongitude.longitude === undefined;

    const missingDeliveryPoint =
        delivery == undefined ||
        delivery.latitudeLongitude === undefined ||
        delivery.latitudeLongitude.latitude === undefined ||
        delivery.latitudeLongitude.longitude === undefined;

    if (missingPickupPoint || missingDeliveryPoint) {
        return;
    }

    const point = latitudeLongitudeToNumbers(
        calculatePointOnArc(
            pickup?.latitudeLongitude as Required<LatitudeLongitude>,
            delivery?.latitudeLongitude as Required<LatitudeLongitude>,
            0.5
        )
    );

    const bearing = calculateBearing(pickup, delivery, 0.5);

    map.addSource('markerSource', {
        type: 'geojson',
        data: {
            type: 'Feature',
            properties: {
                bearing: bearing,
            },
            geometry: {
                type: 'Point',
                coordinates: point,
            },
        },
    });

    map.addLayer({
        id: 'marker',
        source: 'markerSource',
        type: 'symbol',
        layout: {
            'icon-image': arrowMarker(shipmentState),
            'icon-rotate': ['get', 'bearing'],
            'icon-rotation-alignment': 'map',
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
        },
    });
}

function removeMarker(map: mapboxgl.Map) {
    if (map.getLayer('marker')) {
        map.removeLayer('marker');
    }

    if (map.getSource('markerSource')) {
        map.removeSource('markerSource');
    }
}

function shouldAnimate(state: ShipmentState) {
    switch (state) {
        case ShipmentState.Quoted:
            return true;
        case ShipmentState.QuoteRequested:
            return true;
        case ShipmentState.BookingConfirmed:
            return false;
        case ShipmentState.BookingRequested:
            return false;
        case ShipmentState.InTransit:
            return true;
        case ShipmentState.Delivered:
            return false;
        case ShipmentState.Cancelled:
            return false;
    }
}

function stopAnimation(requestRef: MutableRefObject<number | undefined>) {
    if (requestRef.current !== undefined) {
        cancelAnimationFrame(requestRef.current);
    }
}

function startAnimation(
    map: mapboxgl.Map,
    pickup: Partial<AddLocationLocationType>,
    delivery: Partial<AddLocationLocationType>,
    requestRef: MutableRefObject<number | undefined>
) {
    const missingPickupPoint =
        pickup == undefined ||
        pickup.latitudeLongitude === undefined ||
        pickup.latitudeLongitude.latitude === undefined ||
        pickup.latitudeLongitude.longitude === undefined;

    const missingDeliveryPoint =
        delivery == undefined ||
        delivery.latitudeLongitude === undefined ||
        delivery.latitudeLongitude.latitude === undefined ||
        delivery.latitudeLongitude.longitude === undefined;

    if (missingPickupPoint || missingDeliveryPoint) {
        return;
    }

    let position = 0;

    function animate() {
        position += 0.001;

        if (position > 1) {
            position = 0;
        }

        animateMarker(map, pickup, delivery, position);

        requestRef.current = requestAnimationFrame(animate);
    }

    animate();
}

export function setupMarker(
    map: mapboxgl.Map,
    pickup: Partial<AddLocationLocationType>,
    delivery: Partial<AddLocationLocationType>,
    shipmentState: ShipmentState,
    requestRef: MutableRefObject<number | undefined>
) {
    removeMarker(map);
    stopAnimation(requestRef);

    const missingPickupPoint =
        pickup == undefined ||
        pickup.latitudeLongitude === undefined ||
        pickup.latitudeLongitude.latitude === undefined ||
        pickup.latitudeLongitude.longitude === undefined;

    const missingDeliveryPoint =
        delivery == undefined ||
        delivery.latitudeLongitude === undefined ||
        delivery.latitudeLongitude.latitude === undefined ||
        delivery.latitudeLongitude.longitude === undefined;

    if (missingPickupPoint || missingDeliveryPoint) {
        return;
    }

    addMarker(map, pickup, delivery, shipmentState);

    if (shouldAnimate(shipmentState)) {
        startAnimation(map, pickup, delivery, requestRef);
    }
}
