/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Invitation,
} from '../models/index';
import {
    InvitationFromJSON,
    InvitationToJSON,
} from '../models/index';

export interface GetInvitationByTokenRequest {
    tokenId: string;
}

/**
 * 
 */
export class InvitationsApi extends runtime.BaseAPI {

    /**
     * Get Invitation by token
     */
    async getInvitationByTokenRaw(requestParameters: GetInvitationByTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Invitation>> {
        if (requestParameters['tokenId'] == null) {
            throw new runtime.RequiredError(
                'tokenId',
                'Required parameter "tokenId" was null or undefined when calling getInvitationByToken().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['tokenId'] != null) {
            queryParameters['tokenId'] = requestParameters['tokenId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/invitations/get-invitation-by-token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvitationFromJSON(jsonValue));
    }

    /**
     * Get Invitation by token
     */
    async getInvitationByToken(requestParameters: GetInvitationByTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Invitation> {
        const response = await this.getInvitationByTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
