import { isProduction } from 'environment';

export function useLinkedInAdsConversions() {
    function generateConversionFunction(tag: string) {
        return function () {
            // Event snippet for Request quote conversion page
            if (!isProduction()) {
                return;
            }

            try {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.lintrk('track', { conversion_id: tag });
            } catch (e) {
                console.warn('Error in google tag', { e });
            }
        };
    }

    const onAccountCreated = generateConversionFunction('13346418');
    return { onAccountCreated };
}
