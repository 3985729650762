import {
    QuestionBubbleHeader,
    QuestionBubbleLabel,
    SmallQuestionBubble,
} from 'Cargo/Controls/QuestionBubble';
import React from 'react';

export function TechnicalNameQuestionBubble() {
    return (
        <SmallQuestionBubble
            description={
                <>
                    <QuestionBubbleHeader>
                        (Optional) Technical Name
                    </QuestionBubbleHeader>
                    <QuestionBubbleLabel>
                        <div>
                            Consult your MSDS data sheets for the goods you are
                            shipping and enter the technical name, if applicable
                        </div>
                    </QuestionBubbleLabel>
                </>
            }
        />
    );
}
