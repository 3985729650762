/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Hours
 */
export interface Hours {
    /**
     * 
     * @type {string}
     * @memberof Hours
     */
    openFrom: string;
    /**
     * 
     * @type {string}
     * @memberof Hours
     */
    openUntil: string;
}

/**
 * Check if a given object implements the Hours interface.
 */
export function instanceOfHours(value: object): boolean {
    if (!('openFrom' in value)) return false;
    if (!('openUntil' in value)) return false;
    return true;
}

export function HoursFromJSON(json: any): Hours {
    return HoursFromJSONTyped(json, false);
}

export function HoursFromJSONTyped(json: any, ignoreDiscriminator: boolean): Hours {
    if (json == null) {
        return json;
    }
    return {
        
        'openFrom': json['openFrom'],
        'openUntil': json['openUntil'],
    };
}

export function HoursToJSON(value?: Hours | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'openFrom': value['openFrom'],
        'openUntil': value['openUntil'],
    };
}

