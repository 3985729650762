import Stepper from 'Cargo/Controls/Stepper';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { ErrorMessageType } from 'Cargo/Validation';
import { HandlingUnitType } from 'generated-openapi-client';
import React from 'react';
import styled from 'styled-components/macro';
import HandlingUnitTypeControl from './HandlingUnitTypeControl';

interface HandlingUnitsControlProps {
    quantity: number;
    onQuantityChange: (_: number) => void;
    handlingUnitType: HandlingUnitType | undefined;
    onHandlingUnitTypeChange: (_: HandlingUnitType) => void;
    errorMessage: ErrorMessageType;
    forceValidation: boolean;
}

const InvalidLabel = styled.div`
    color: #ea554c;
    font-size: 12px;
    margin-top: 2px;
`;

function HandlingUnitsControl(props: HandlingUnitsControlProps) {
    return (
        <Stack align="left">
            <HorizontalStack width="349px">
                <Stepper
                    value={props.quantity}
                    onChange={props.onQuantityChange}
                />
                <Spacer width={150} />
                <HandlingUnitTypeControl
                    value={props.handlingUnitType}
                    onChange={props.onHandlingUnitTypeChange}
                    usePluralForm={props.quantity > 1}
                />
            </HorizontalStack>
            {props.errorMessage !== undefined && (
                <InvalidLabel>{props.errorMessage}</InvalidLabel>
            )}
        </Stack>
    );
}

export default HandlingUnitsControl;
