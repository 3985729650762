import { useModalsSlice } from 'Cargo/Modal/modalsSlice';
import { ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ModalInstance } from './useModalsProvider';

interface ShowModalOptions {
    preventBackdropClick: boolean;
    padding?: string;
    // Removes the standard chrome so it can present a custom view
    simpleModal?: boolean;
}

export function useModal<UserDataType>() {
    const { addModal, startClosingModalById, removeModal } = useModalsSlice();

    /**
     * Call this function to immediately present a modal to the user
     *
     * @param contentProvider
     *  Function which returns the actual contents of the modal.
     *  It provides a done method that can be used to close the modal.
     *  The done method accepts a payload of UserDataType, which can be used to pass a return value to the onDone callback
     * @param onDone
     *  Callback that is invoked when the modal is complete. It contains a parameter of UserDataType which can contain information passed
     *  onto the contentProvider's done method
     * @param options
     *  - preventBackdropClick : Determins if clicking on the backdrop to close the modal is prevented. If the modal is informational it is nice to be able to click anywhere.
     *     But if it is a critical screen we might want to force the user to provide an explicit answer
     */
    function showModal(
        contentProvider: (done: (userData?: UserDataType) => void) => ReactNode,
        onDone?: (userData?: UserDataType) => void,
        options?: ShowModalOptions
    ) {
        const modalInstanceId = uuidv4();

        function close(userData?: UserDataType) {
            setTimeout(function () {
                startClosingModalById(modalInstanceId);

                setTimeout(function () {
                    removeModal(modalInstanceId);
                }, 200);

                if (onDone) {
                    onDone(userData);
                }
            }, 100);
        }

        // Call the content provider
        const contents = contentProvider(close);

        const modal: ModalInstance = {
            // The id used to refer to the modal
            id: modalInstanceId,

            // The actual React components used to render the body of the modal
            contents,

            // Erase the specific userDataType
            onDone: onDone as (userData: unknown) => void,
            onBackdropClick: function () {
                if (options?.preventBackdropClick) {
                    return;
                }

                close();
            },
            padding: options?.padding,
            simpleModal: options?.simpleModal,
        };

        addModal(modal);
    }

    return showModal;
}
