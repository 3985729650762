/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetTrackingIdForProNumberResponse,
  ListDocumentsResponse,
  PdfResponse,
  ShipmentForTracking,
  TrackingInfo,
} from '../models/index';
import {
    GetTrackingIdForProNumberResponseFromJSON,
    GetTrackingIdForProNumberResponseToJSON,
    ListDocumentsResponseFromJSON,
    ListDocumentsResponseToJSON,
    PdfResponseFromJSON,
    PdfResponseToJSON,
    ShipmentForTrackingFromJSON,
    ShipmentForTrackingToJSON,
    TrackingInfoFromJSON,
    TrackingInfoToJSON,
} from '../models/index';

export interface GetTrackingDocumentPdfRequest {
    documentId: string;
    tId: string;
}

export interface GetTrackingGetShipmentForTrackingRequest {
    tId: string;
}

export interface GetTrackingGetTrackingRequest {
    tId: string;
}

export interface GetTrackingGetTrackingIdForProNumberRequest {
    proNumber: string;
}

export interface GetTrackingListDocumentsForTrackingRequest {
    tId: string;
}

export interface GetTrackingPickupPackagePdfRequest {
    tId: string;
}

/**
 * 
 */
export class TrackingApi extends runtime.BaseAPI {

    /**
     * Your GET endpoint
     */
    async getTrackingDocumentPdfRaw(requestParameters: GetTrackingDocumentPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PdfResponse>> {
        if (requestParameters['documentId'] == null) {
            throw new runtime.RequiredError(
                'documentId',
                'Required parameter "documentId" was null or undefined when calling getTrackingDocumentPdf().'
            );
        }

        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling getTrackingDocumentPdf().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['documentId'] != null) {
            queryParameters['documentId'] = requestParameters['documentId'];
        }

        if (requestParameters['tId'] != null) {
            queryParameters['tId'] = requestParameters['tId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/t/document-pdf`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PdfResponseFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getTrackingDocumentPdf(requestParameters: GetTrackingDocumentPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PdfResponse> {
        const response = await this.getTrackingDocumentPdfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getTrackingGetShipmentForTrackingRaw(requestParameters: GetTrackingGetShipmentForTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShipmentForTracking>> {
        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling getTrackingGetShipmentForTracking().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['tId'] != null) {
            queryParameters['tId'] = requestParameters['tId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/t/get-shipment-for-t`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShipmentForTrackingFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getTrackingGetShipmentForTracking(requestParameters: GetTrackingGetShipmentForTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShipmentForTracking> {
        const response = await this.getTrackingGetShipmentForTrackingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getTrackingGetTrackingRaw(requestParameters: GetTrackingGetTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrackingInfo>> {
        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling getTrackingGetTracking().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['tId'] != null) {
            queryParameters['tId'] = requestParameters['tId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/t/get-t`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrackingInfoFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getTrackingGetTracking(requestParameters: GetTrackingGetTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrackingInfo> {
        const response = await this.getTrackingGetTrackingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getTrackingGetTrackingIdForProNumberRaw(requestParameters: GetTrackingGetTrackingIdForProNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTrackingIdForProNumberResponse>> {
        if (requestParameters['proNumber'] == null) {
            throw new runtime.RequiredError(
                'proNumber',
                'Required parameter "proNumber" was null or undefined when calling getTrackingGetTrackingIdForProNumber().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['proNumber'] != null) {
            queryParameters['proNumber'] = requestParameters['proNumber'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/t/get-t-id-for-pro-number`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTrackingIdForProNumberResponseFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getTrackingGetTrackingIdForProNumber(requestParameters: GetTrackingGetTrackingIdForProNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTrackingIdForProNumberResponse> {
        const response = await this.getTrackingGetTrackingIdForProNumberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getTrackingListDocumentsForTrackingRaw(requestParameters: GetTrackingListDocumentsForTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListDocumentsResponse>> {
        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling getTrackingListDocumentsForTracking().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['tId'] != null) {
            queryParameters['tId'] = requestParameters['tId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/t/list-documents-for-t`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListDocumentsResponseFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getTrackingListDocumentsForTracking(requestParameters: GetTrackingListDocumentsForTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListDocumentsResponse> {
        const response = await this.getTrackingListDocumentsForTrackingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getTrackingPickupPackagePdfRaw(requestParameters: GetTrackingPickupPackagePdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PdfResponse>> {
        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling getTrackingPickupPackagePdf().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['tId'] != null) {
            queryParameters['tId'] = requestParameters['tId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/t/pickup-package-pdf`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PdfResponseFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getTrackingPickupPackagePdf(requestParameters: GetTrackingPickupPackagePdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PdfResponse> {
        const response = await this.getTrackingPickupPackagePdfRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
