/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AcceptInvitationRequest,
  ClearBranchRequest,
  CreateAccountRequest,
  DeleteUserRequest,
  GetCouponResponse,
  GetReferralCodeResponse,
  Invitation,
  LookUpEmailResponse,
  RequestPasswordResetRequest,
  ResendInvitationRequest,
  ResetPasswordRequest,
  RevokeInvitationRequest,
  SendInvitationRequest,
  SendInvitationResponse,
  SetBranchRequest,
  SharePrefilledInfoRequest,
  UpdateUserRequest,
  User,
} from '../models/index';
import {
    AcceptInvitationRequestFromJSON,
    AcceptInvitationRequestToJSON,
    ClearBranchRequestFromJSON,
    ClearBranchRequestToJSON,
    CreateAccountRequestFromJSON,
    CreateAccountRequestToJSON,
    DeleteUserRequestFromJSON,
    DeleteUserRequestToJSON,
    GetCouponResponseFromJSON,
    GetCouponResponseToJSON,
    GetReferralCodeResponseFromJSON,
    GetReferralCodeResponseToJSON,
    InvitationFromJSON,
    InvitationToJSON,
    LookUpEmailResponseFromJSON,
    LookUpEmailResponseToJSON,
    RequestPasswordResetRequestFromJSON,
    RequestPasswordResetRequestToJSON,
    ResendInvitationRequestFromJSON,
    ResendInvitationRequestToJSON,
    ResetPasswordRequestFromJSON,
    ResetPasswordRequestToJSON,
    RevokeInvitationRequestFromJSON,
    RevokeInvitationRequestToJSON,
    SendInvitationRequestFromJSON,
    SendInvitationRequestToJSON,
    SendInvitationResponseFromJSON,
    SendInvitationResponseToJSON,
    SetBranchRequestFromJSON,
    SetBranchRequestToJSON,
    SharePrefilledInfoRequestFromJSON,
    SharePrefilledInfoRequestToJSON,
    UpdateUserRequestFromJSON,
    UpdateUserRequestToJSON,
    UserFromJSON,
    UserToJSON,
} from '../models/index';

export interface GetUserLookupEmailRequest {
    email: string;
}

export interface PostUsersAcceptInvitationRequest {
    acceptInvitationRequest?: AcceptInvitationRequest;
}

export interface PostUsersClearBranchRequest {
    clearBranchRequest?: ClearBranchRequest;
}

export interface PostUsersCreateAccountRequest {
    createAccountRequest?: CreateAccountRequest;
}

export interface PostUsersDeleteRequest {
    deleteUserRequest?: DeleteUserRequest;
}

export interface PostUsersRequestPasswordResetRequest {
    requestPasswordResetRequest?: RequestPasswordResetRequest;
}

export interface PostUsersResendInvitationRequest {
    resendInvitationRequest?: ResendInvitationRequest;
}

export interface PostUsersResetPasswordRequest {
    resetPasswordRequest?: ResetPasswordRequest;
}

export interface PostUsersRevokeInvitationRequest {
    revokeInvitationRequest?: RevokeInvitationRequest;
}

export interface PostUsersSendInvitationRequest {
    sendInvitationRequest?: SendInvitationRequest;
}

export interface PostUsersSetBranchRequest {
    setBranchRequest?: SetBranchRequest;
}

export interface PostUsersSharePrefilledInfoRequest {
    sharePrefilledInfoRequest?: SharePrefilledInfoRequest;
}

export interface PostUsersUpdateRequest {
    updateUserRequest?: UpdateUserRequest;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * Looks up if the email is already used
     * Your GET endpoint
     */
    async getUserLookupEmailRaw(requestParameters: GetUserLookupEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LookUpEmailResponse>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling getUserLookupEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/look-up-email`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LookUpEmailResponseFromJSON(jsonValue));
    }

    /**
     * Looks up if the email is already used
     * Your GET endpoint
     */
    async getUserLookupEmail(requestParameters: GetUserLookupEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LookUpEmailResponse> {
        const response = await this.getUserLookupEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getUsersGetCouponRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCouponResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/get-coupon`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCouponResponseFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getUsersGetCoupon(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCouponResponse> {
        const response = await this.getUsersGetCouponRaw(initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getUsersGetReferralCodeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetReferralCodeResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/get-referral-code`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetReferralCodeResponseFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getUsersGetReferralCode(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetReferralCodeResponse> {
        const response = await this.getUsersGetReferralCodeRaw(initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getUsersGetUserHashForFrontRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/get-user-hash-for-front`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Your GET endpoint
     */
    async getUsersGetUserHashForFront(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getUsersGetUserHashForFrontRaw(initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getUsersListInvitationsForCompanyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Invitation>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/list-invitations-for-company`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InvitationFromJSON));
    }

    /**
     * Your GET endpoint
     */
    async getUsersListInvitationsForCompany(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Invitation>> {
        const response = await this.getUsersListInvitationsForCompanyRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async postUsersAcceptInvitationRaw(requestParameters: PostUsersAcceptInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/accept-invitation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AcceptInvitationRequestToJSON(requestParameters['acceptInvitationRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postUsersAcceptInvitation(requestParameters: PostUsersAcceptInvitationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postUsersAcceptInvitationRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postUsersClearBranchRaw(requestParameters: PostUsersClearBranchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/clear-branch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClearBranchRequestToJSON(requestParameters['clearBranchRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postUsersClearBranch(requestParameters: PostUsersClearBranchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postUsersClearBranchRaw(requestParameters, initOverrides);
    }

    /**
     * Create an account
     */
    async postUsersCreateAccountRaw(requestParameters: PostUsersCreateAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/create-account`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAccountRequestToJSON(requestParameters['createAccountRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create an account
     */
    async postUsersCreateAccount(requestParameters: PostUsersCreateAccountRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postUsersCreateAccountRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postUsersDeleteRaw(requestParameters: PostUsersDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteUserRequestToJSON(requestParameters['deleteUserRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postUsersDelete(requestParameters: PostUsersDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postUsersDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postUsersDeleteAccountRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/delete-account`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postUsersDeleteAccount(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postUsersDeleteAccountRaw(initOverrides);
    }

    /**
     * 
     */
    async postUsersListForCompanyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<User>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/list-for-company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     * 
     */
    async postUsersListForCompany(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<User>> {
        const response = await this.postUsersListForCompanyRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async postUsersRequestPasswordResetRaw(requestParameters: PostUsersRequestPasswordResetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/request-password-reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestPasswordResetRequestToJSON(requestParameters['requestPasswordResetRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postUsersRequestPasswordReset(requestParameters: PostUsersRequestPasswordResetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postUsersRequestPasswordResetRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postUsersResendInvitationRaw(requestParameters: PostUsersResendInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SendInvitationResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/resend-invitation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResendInvitationRequestToJSON(requestParameters['resendInvitationRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SendInvitationResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async postUsersResendInvitation(requestParameters: PostUsersResendInvitationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SendInvitationResponse> {
        const response = await this.postUsersResendInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async postUsersResetPasswordRaw(requestParameters: PostUsersResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordRequestToJSON(requestParameters['resetPasswordRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postUsersResetPassword(requestParameters: PostUsersResetPasswordRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postUsersResetPasswordRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postUsersRevokeInvitationRaw(requestParameters: PostUsersRevokeInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/revoke-invitation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RevokeInvitationRequestToJSON(requestParameters['revokeInvitationRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postUsersRevokeInvitation(requestParameters: PostUsersRevokeInvitationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postUsersRevokeInvitationRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postUsersSendInvitationRaw(requestParameters: PostUsersSendInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SendInvitationResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/send-invitation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendInvitationRequestToJSON(requestParameters['sendInvitationRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SendInvitationResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async postUsersSendInvitation(requestParameters: PostUsersSendInvitationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SendInvitationResponse> {
        const response = await this.postUsersSendInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async postUsersSetBranchRaw(requestParameters: PostUsersSetBranchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/set-branch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetBranchRequestToJSON(requestParameters['setBranchRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postUsersSetBranch(requestParameters: PostUsersSetBranchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postUsersSetBranchRaw(requestParameters, initOverrides);
    }

    /**
     * Share Prefilled Info
     */
    async postUsersSharePrefilledInfoRaw(requestParameters: PostUsersSharePrefilledInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/share-prefilled-info`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SharePrefilledInfoRequestToJSON(requestParameters['sharePrefilledInfoRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Share Prefilled Info
     */
    async postUsersSharePrefilledInfo(requestParameters: PostUsersSharePrefilledInfoRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postUsersSharePrefilledInfoRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postUsersUpdateRaw(requestParameters: PostUsersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserRequestToJSON(requestParameters['updateUserRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postUsersUpdate(requestParameters: PostUsersUpdateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postUsersUpdateRaw(requestParameters, initOverrides);
    }

}
