import { datadogRum } from '@datadog/browser-rum';
import LogRocket from 'logrocket';

function getLogRocketConfig() {
    if (process.env.REACT_APP_FS_ENVIRONMENT === 'preprod') {
        return {
            release: process.env.REACT_APP_GIT_SHA,
        };
    }

    if (process.env.REACT_APP_FS_ENVIRONMENT === 'production') {
        // This allows us to track single sessions across all subdomains (dashboard. www. etc)
        // https://docs.logrocket.com/reference#shouldcaptureip
        return {
            release: process.env.REACT_APP_GIT_SHA,
            rootHostname: 'freightsimple.com',
        };
    }

    if (process.env.REACT_APP_FS_ENVIRONMENT === 'demo') {
        return {
            release: process.env.REACT_APP_GIT_SHA,
        };
    }

    return undefined;
}

function getLogRocketProject() {
    // Initialize log rocket
    if (process.env.REACT_APP_FS_ENVIRONMENT === 'preprod') {
        //return 'oofxds/freightsimple-preprod';
        return undefined;
    }

    if (process.env.REACT_APP_FS_ENVIRONMENT === 'production') {
        return 'oofxds/freightsimple-production';
    }

    if (process.env.REACT_APP_FS_ENVIRONMENT === 'demo') {
        return 'oofxds/freightsimple-demo';
    }

    return undefined;
}

export const LogRocketProject: string | undefined = getLogRocketProject();

if (LogRocketProject) LogRocket.init(LogRocketProject, getLogRocketConfig());

export function isProduction(): boolean {
    return process.env.REACT_APP_FS_ENVIRONMENT === 'production';
}

export function isDemo(): boolean {
    return process.env.REACT_APP_FS_ENVIRONMENT === 'demo';
}

export function isLocal(): boolean {
    return process.env.REACT_APP_FS_ENVIRONMENT === undefined;
}

export function apiServerUrl(): string {
    return process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8080';
}

export function cookieDomain(): string {
    if (process.env.REACT_APP_FS_ENVIRONMENT === 'production') {
        return 'freightsimple.com';
    } else if (process.env.REACT_APP_FS_ENVIRONMENT === 'demo') {
        return 'freightsimpledemo.com';
    } else if (process.env.REACT_APP_FS_ENVIRONMENT === 'preprod') {
        return 'fspreprod.com';
    } else {
        return 'localhost';
    }
}

interface Auth0Creds {
    domain: string;
    clientId: string;
    audience: string;
}

export function auth0Creds(): Auth0Creds {
    if (!process.env.REACT_APP_AUTH0_DOMAIN) {
        throw new Error('Missing REACT_APP_AUTH0_DOMAIN');
    }

    if (!process.env.REACT_APP_AUTH0_CLIENT_ID) {
        throw new Error('Missing REACT_APP_AUTH0_CLIENT_ID');
    }

    if (!process.env.REACT_APP_AUTH0_API_AUDIENCE) {
        throw new Error('Missing REACT_APP_AUTH0_API_AUDIENCE');
    }

    return {
        domain: process.env.REACT_APP_AUTH0_DOMAIN,
        clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
        audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
    };
}

export function rootUrl(): string {
    if (process.env.REACT_APP_FS_ENVIRONMENT === 'preprod') {
        return 'https://dashboard.fspreprod.com/';
    }

    if (process.env.REACT_APP_FS_ENVIRONMENT === 'production') {
        return 'https://dashboard.freightsimple.com/';
    }

    if (process.env.REACT_APP_FS_ENVIRONMENT === 'demo') {
        return 'https://dashboard.freightsimpledemo.com/';
    }

    return 'http://localhost:3000/';
}

export function getStripeKey(): string {
    if (process.env.REACT_APP_FS_ENVIRONMENT === 'production') {
        return 'pk_live_23rWgGvkbZhsZ9dWnezporWD00KZhNv5FG';
    } else {
        return 'pk_test_6GHYhSyObuzDjZHy9zKOactB00hc9juaCE';
    }
}

function initializeDatadog() {
    if (isProduction()) {
        datadogRum.init({
            applicationId: 'd21ee269-2a42-44b5-b8e9-6f8a59d445eb',
            clientToken: 'pub60b84113f8fb4df5d2cbe9112ac05192',
            site: 'datadoghq.com',
            service: 'freightsimple-production',
            env: 'production',
            // Specify a version number to identify the deployed version of your application in Datadog
            // NOTE: This variable isn't passed through properly
            //version: process.env.REACT_APP_GIT_SHA,
            sampleRate: 100,

            // Record all sessions
            sessionReplaySampleRate: 100,
            trackInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'allow',
        });

        datadogRum.startSessionReplayRecording();
    }
}

initializeDatadog();
