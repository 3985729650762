/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SharedDedicated = {
    Shared: 'Shared',
    Dedicated: 'Dedicated',
    Either: 'Either'
} as const;
export type SharedDedicated = typeof SharedDedicated[keyof typeof SharedDedicated];


export function instanceOfSharedDedicated(value: any): boolean {
    return Object.values(SharedDedicated).includes(value);
}

export function SharedDedicatedFromJSON(json: any): SharedDedicated {
    return SharedDedicatedFromJSONTyped(json, false);
}

export function SharedDedicatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): SharedDedicated {
    return json as SharedDedicated;
}

export function SharedDedicatedToJSON(value?: SharedDedicated | null): any {
    return value as any;
}

