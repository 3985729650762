import { Page } from 'Cargo/Layout/Page';
import { useAuthentication } from 'Features/Authentication/Slices/authenticationSlice';
import React, { ReactElement, ReactNode, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface NotAuthedRouteProps {
    exact?: boolean;
    children: ReactNode;
    path: string;
    noMatchRedirect?: string;
}
function NotAuthedRoute(props: NotAuthedRouteProps): ReactElement {
    const { isAuthenticated } = useAuthentication();
    const navigate = useNavigate();

    const location = useLocation();

    function isMatch() {
        if (props.exact) {
            return location.pathname == props.path;
        } else {
            return location.pathname.startsWith(props.path);
        }
    }

    const match = isMatch();

    useEffect(function () {
        if (isAuthenticated && match) {
            navigate(props.noMatchRedirect || '/');
        }
    }, []);

    if (!isAuthenticated) {
        return (
            <Page exact={props.exact} path={props.path}>
                {() => props.children}
            </Page>
        );
    }

    return <></>;
}
export default NotAuthedRoute;
