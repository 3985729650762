/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EquipmentType } from './EquipmentType';
import {
    EquipmentTypeFromJSON,
    EquipmentTypeFromJSONTyped,
    EquipmentTypeToJSON,
} from './EquipmentType';
import type { SharedDedicated } from './SharedDedicated';
import {
    SharedDedicatedFromJSON,
    SharedDedicatedFromJSONTyped,
    SharedDedicatedToJSON,
} from './SharedDedicated';

/**
 * 
 * @export
 * @interface RequestManualQuotesRequest
 */
export interface RequestManualQuotesRequest {
    /**
     * 
     * @type {string}
     * @memberof RequestManualQuotesRequest
     */
    shipmentId: string;
    /**
     * 
     * @type {string}
     * @memberof RequestManualQuotesRequest
     */
    notes: string;
    /**
     * 
     * @type {EquipmentType}
     * @memberof RequestManualQuotesRequest
     */
    equipmentType: EquipmentType;
    /**
     * 
     * @type {SharedDedicated}
     * @memberof RequestManualQuotesRequest
     */
    sharedDedicated: SharedDedicated;
    /**
     * 
     * @type {boolean}
     * @memberof RequestManualQuotesRequest
     */
    tarpRequired?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RequestManualQuotesRequest
     */
    linearFeet?: number;
}

/**
 * Check if a given object implements the RequestManualQuotesRequest interface.
 */
export function instanceOfRequestManualQuotesRequest(value: object): boolean {
    if (!('shipmentId' in value)) return false;
    if (!('notes' in value)) return false;
    if (!('equipmentType' in value)) return false;
    if (!('sharedDedicated' in value)) return false;
    return true;
}

export function RequestManualQuotesRequestFromJSON(json: any): RequestManualQuotesRequest {
    return RequestManualQuotesRequestFromJSONTyped(json, false);
}

export function RequestManualQuotesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestManualQuotesRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentId': json['shipmentId'],
        'notes': json['notes'],
        'equipmentType': EquipmentTypeFromJSON(json['equipmentType']),
        'sharedDedicated': SharedDedicatedFromJSON(json['sharedDedicated']),
        'tarpRequired': json['tarpRequired'] == null ? undefined : json['tarpRequired'],
        'linearFeet': json['linearFeet'] == null ? undefined : json['linearFeet'],
    };
}

export function RequestManualQuotesRequestToJSON(value?: RequestManualQuotesRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentId': value['shipmentId'],
        'notes': value['notes'],
        'equipmentType': EquipmentTypeToJSON(value['equipmentType']),
        'sharedDedicated': SharedDedicatedToJSON(value['sharedDedicated']),
        'tarpRequired': value['tarpRequired'],
        'linearFeet': value['linearFeet'],
    };
}

