import Pill, { PillStyle } from 'Cargo/Controls/Pill';

interface PreferredBrokerPillProps {
    preferredNorthbound: boolean;
    preferredSouthbound: boolean;
}
function PreferredBrokerPill(props: PreferredBrokerPillProps) {
    if (!props.preferredNorthbound && !props.preferredSouthbound) {
        return <></>;
    }

    if (props.preferredNorthbound && props.preferredSouthbound) {
        return (
            <Pill pillStyle={PillStyle.Yellow}>Preferred : All Shipments</Pill>
        );
    }

    if (props.preferredNorthbound && !props.preferredSouthbound) {
        return (
            <Pill pillStyle={PillStyle.Yellow}>
                Preferred : United States Pickups 🇺🇸
            </Pill>
        );
    }

    if (!props.preferredNorthbound && props.preferredSouthbound) {
        return (
            <Pill pillStyle={PillStyle.Yellow}>
                Preferred : Canada Pickups 🇨🇦
            </Pill>
        );
    }

    throw new Error('Unhandled broker pill case');
}
export default PreferredBrokerPill;
