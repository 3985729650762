/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LocationType = {
    Warehouse: 'Warehouse',
    DistributionWarehouse: 'DistributionWarehouse',
    Residential: 'Residential',
    ConstructionSite: 'ConstructionSite',
    School: 'School',
    TradeShow: 'TradeShow',
    UniversityCollege: 'UniversityCollege',
    Farm: 'Farm',
    Mine: 'Mine',
    Retail: 'Retail',
    Hospitality: 'Hospitality',
    Medical: 'Medical',
    Airport: 'Airport',
    SelfStorage: 'SelfStorage',
    Port: 'Port',
    Prison: 'Prison',
    OtherLimitedAccess: 'OtherLimitedAccess',
    ReligiousSite: 'ReligiousSite',
    Military: 'Military',
    Utility: 'Utility'
} as const;
export type LocationType = typeof LocationType[keyof typeof LocationType];


export function instanceOfLocationType(value: any): boolean {
    return Object.values(LocationType).includes(value);
}

export function LocationTypeFromJSON(json: any): LocationType {
    return LocationTypeFromJSONTyped(json, false);
}

export function LocationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationType {
    return json as LocationType;
}

export function LocationTypeToJSON(value?: LocationType | null): any {
    return value as any;
}

