import { PreQuoteLocation, SavedLocation } from 'generated-openapi-client';
import { formatPostalCode } from 'Helpers/formatPostalCode';

export function findSavedLocationForLocation(
    savedLocations: Array<SavedLocation>,
    location: PreQuoteLocation
): SavedLocation | undefined {
    return savedLocations.find(
        (sl) =>
            sl.location.businessName === location.businessName &&
            formatPostalCode(sl.location.address.postalCode) ===
                formatPostalCode(location.address.postalCode)
    );
}
