/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Invoice } from './Invoice';
import {
    InvoiceFromJSON,
    InvoiceFromJSONTyped,
    InvoiceToJSON,
} from './Invoice';

/**
 * 
 * @export
 * @interface ListInvoicesResponse
 */
export interface ListInvoicesResponse {
    /**
     * 
     * @type {Array<Invoice>}
     * @memberof ListInvoicesResponse
     */
    invoices: Array<Invoice>;
}

/**
 * Check if a given object implements the ListInvoicesResponse interface.
 */
export function instanceOfListInvoicesResponse(value: object): boolean {
    if (!('invoices' in value)) return false;
    return true;
}

export function ListInvoicesResponseFromJSON(json: any): ListInvoicesResponse {
    return ListInvoicesResponseFromJSONTyped(json, false);
}

export function ListInvoicesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListInvoicesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'invoices': ((json['invoices'] as Array<any>).map(InvoiceFromJSON)),
    };
}

export function ListInvoicesResponseToJSON(value?: ListInvoicesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'invoices': ((value['invoices'] as Array<any>).map(InvoiceToJSON)),
    };
}

