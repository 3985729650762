import { usePaymentMethodsApi } from 'apis';
import Colors from 'Cargo/Colors';
import Button from 'Cargo/Controls/Button';
import LinkButton from 'Cargo/Controls/LinkButton';
import Icon from 'Cargo/Icons/Icon';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { useModal } from 'Cargo/Modal/useModal';
import { H3, Microcopy } from 'Cargo/Text/Text';
import { UUID } from 'Cargo/Types/types';
import { rootUrl } from 'environment';
import React from 'react';
import useClipboard from 'react-use-clipboard';
import styled from 'styled-components/macro';

interface ShareCreditCardModalProps {
    addCreditCardAuthorizationId: UUID;
    onDone: () => void;
}

const LinkText = styled.pre`
    background-color: ${Colors.VeryLightGray};
    border: 1px solid ${Colors.LightGray};
    color: ${Colors.NormalText};
    padding: 10px;
    margin: 0;
`;

const ShareCreditCardModal: React.FC<ShareCreditCardModalProps> = (
    props: ShareCreditCardModalProps
) => {
    const url = `${rootUrl()}add-credit-card?id=${
        props.addCreditCardAuthorizationId
    }`;
    const [isCopied, setCopied] = useClipboard(url);

    return (
        <Stack align="left" style={{ padding: '16px', width: '1024px' }}>
            <Spacer height={16} />
            <H3>Invite a colleague to enter the company credit card</H3>
            <Microcopy>
                If you don&apos;t have access to the credit card, you can send
                this link to a colleague, and they can enter the credit card
                with it.
            </Microcopy>
            <Spacer height={8} />
            <Microcopy>
                You can copy this link and send it to them via Email, Slack or
                similar.
            </Microcopy>
            <Spacer height={16} />
            <HorizontalStack verticalAlign="middle">
                <LinkText>{url}</LinkText>
                <Spacer width={8} />
                <LinkButton onClick={setCopied}>
                    Click here to copy to clipboard
                </LinkButton>
                {isCopied && (
                    <Icon
                        name="check"
                        color={Colors.Green}
                        size={16}
                        solid
                        style={{ marginLeft: '8px' }}
                    />
                )}
            </HorizontalStack>
            <Spacer height={32} />
            <Button onClick={props.onDone}>Done</Button>
            <Spacer height={16} />
        </Stack>
    );
};

export default ShareCreditCardModal;

export function useShareCreditCardModal() {
    const showModal = useModal();
    const creditCardsApi = usePaymentMethodsApi();

    async function showShareModal() {
        const response =
            await creditCardsApi.getCreditCardsGetAddCreditCardAuthorizationId();
        const addCreditCardAuthorizationId =
            response.addCreditCardAuthorizationId;
        showModal((done) => {
            return (
                <ShareCreditCardModal
                    onDone={done}
                    addCreditCardAuthorizationId={addCreditCardAuthorizationId}
                />
            );
        });
    }

    return showShareModal;
}
