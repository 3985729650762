/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FreightClaimItemCondition = {
    New: 'New',
    Used: 'Used'
} as const;
export type FreightClaimItemCondition = typeof FreightClaimItemCondition[keyof typeof FreightClaimItemCondition];


export function instanceOfFreightClaimItemCondition(value: any): boolean {
    return Object.values(FreightClaimItemCondition).includes(value);
}

export function FreightClaimItemConditionFromJSON(json: any): FreightClaimItemCondition {
    return FreightClaimItemConditionFromJSONTyped(json, false);
}

export function FreightClaimItemConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FreightClaimItemCondition {
    return json as FreightClaimItemCondition;
}

export function FreightClaimItemConditionToJSON(value?: FreightClaimItemCondition | null): any {
    return value as any;
}

