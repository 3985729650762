import Colors from 'Cargo/Colors';
import CarrierLogo from 'Cargo/Icons/CarrierLogo';
import Icon from 'Cargo/Icons/Icon';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { Shipment } from 'generated-openapi-client';
import styled from 'styled-components/macro';

interface ClaimSummaryProps {
    shipment: Shipment;
}

const Company = styled.div`
    font-weight: var(--nhu-font-weight-medium);
    font-size: 18px;
    color: #545454;
`;
const City = styled.div`
    font-weight: var(--nhu-font-weight-regular);
    font-size: 14px;
    color: #858585;
`;

const ProNumber = styled.div`
    font-weight: var(--nhu-font-weight-normal);
    font-size: 12px;
    color: #858585;
`;

export function ClaimSummary(props: ClaimSummaryProps) {
    const { shipment } = props;
    const selectedQuote = shipment.selectedQuote;

    if (selectedQuote === undefined) {
        return <></>;
    }

    const { pickupLocation, deliveryLocation } = shipment;

    const pickupCity = `${pickupLocation.address.city}, ${pickupLocation.address.stateOrProvinceCode}`;
    const deliveryCity = `${deliveryLocation.address.city}, ${deliveryLocation.address.stateOrProvinceCode}`;

    const pickupBusinessName = pickupLocation.businessName;
    const deliveryBusinessName = deliveryLocation.businessName;

    return (
        <HorizontalStack>
            <Stack align="left">
                <Company>{pickupBusinessName}</Company>
                <City>{pickupCity}</City>
            </Stack>
            <Icon
                name="arrow-right"
                color={Colors.NormalText}
                size={14}
                solid
                style={{
                    position: 'relative',
                    top: '-10px',
                    marginRight: '16px',
                    marginLeft: '16px',
                }}
            />
            <Stack align="left">
                <Company>{deliveryBusinessName}</Company>
                <City>{deliveryCity}</City>
            </Stack>
            <Spacer width={32} />
            <Stack align="center">
                <CarrierLogo
                    carrierIdentifier={selectedQuote.carrierIdentifier}
                    width={100}
                    height={40}
                />
                <ProNumber>Pro {shipment.proNumber}</ProNumber>
            </Stack>
        </HorizontalStack>
    );
}
