/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FreightClaimType = {
    NoDamageOrLoss: 'NoDamageOrLoss',
    Damage: 'Damage',
    Loss: 'Loss'
} as const;
export type FreightClaimType = typeof FreightClaimType[keyof typeof FreightClaimType];


export function instanceOfFreightClaimType(value: any): boolean {
    return Object.values(FreightClaimType).includes(value);
}

export function FreightClaimTypeFromJSON(json: any): FreightClaimType {
    return FreightClaimTypeFromJSONTyped(json, false);
}

export function FreightClaimTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FreightClaimType {
    return json as FreightClaimType;
}

export function FreightClaimTypeToJSON(value?: FreightClaimType | null): any {
    return value as any;
}

