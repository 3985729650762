import Colors from 'Cargo/Colors';
import Button from 'Cargo/Controls/Button';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import { ReactNode } from 'react';

interface StoreCreditCardButtonProps {
    onClick: () => void;
    storingInProgress: boolean;
    storingSuccess: boolean;
    storingFailure: boolean;
    failureMessage: ReactNode;
}

function StoreCreditCardButton(props: StoreCreditCardButtonProps) {
    return (
        <HorizontalStack style={{gap: "8px"}}>
            <Button
                textStyle={{width: "250px"}}
                id="store_credit_card"
                size="large"
                label="Securely Store Credit Card"
                icon="lock"
                onClick={props.onClick}
                loading={props.storingInProgress}
                loadingSuccess={props.storingSuccess}
                loadingFailure={props.storingFailure}
            />
            <span style={{ color: Colors.DarkRed, overflow: "hidden" }}>
                {!props.storingInProgress &&
                    props.storingFailure &&
                    props.failureMessage}
            </span>
        </HorizontalStack>
    );
}
export default StoreCreditCardButton;
