import CarrierLogo from 'Cargo/Icons/CarrierLogo';
import Stack from 'Cargo/Layout/Stack';
import { displayNameForCarrier } from 'Data/Carriers';
import { QuoteLike } from 'Features/ViewShipments/Components/DetailsSection';
import { Broker, ShipmentState } from 'generated-openapi-client';
import React from 'react';
import {
    Column,
    ColumnHeader,
    ColumnSectionHeader,
    SecondaryText,
} from './QuoteRowSharedStyles';

interface TransitColumnForTrackingProps {
    quote: QuoteLike;
    shipmentState: ShipmentState;
    proNumber: string | undefined;
    notes?: string;
    broker?: Broker;
}
function TransitColumnForTracking(props: TransitColumnForTrackingProps) {
    const { quote, shipmentState } = props;

    const showCarrierLogo = !(
        shipmentState === ShipmentState.Quoted ||
        shipmentState === ShipmentState.QuoteRequested
    );

    const carrierDisplayName = displayNameForCarrier(quote.carrierIdentifier);

    const transitTimeDescription = quote.transitBusinessDays
        ? `${quote.transitBusinessDays} Business Days`
        : 'Unknown';

    return (
        <Column>
            <Stack align="left">
                <ColumnHeader>Transit</ColumnHeader>

                <ColumnSectionHeader>Pro Number</ColumnSectionHeader>
                <SecondaryText>{props.proNumber}</SecondaryText>

                <ColumnSectionHeader>Carrier</ColumnSectionHeader>
                {showCarrierLogo && (
                    <div style={{ margin: '8px 0' }}>
                        <CarrierLogo
                            carrierIdentifier={quote.carrierIdentifier}
                            align="left"
                            width={160}
                            height={64}
                        />
                    </div>
                )}
                {!showCarrierLogo && (
                    <SecondaryText>{carrierDisplayName}</SecondaryText>
                )}

                <ColumnSectionHeader>Transit Time</ColumnSectionHeader>
                <SecondaryText>{transitTimeDescription}</SecondaryText>
            </Stack>
        </Column>
    );
}
export default TransitColumnForTracking;
