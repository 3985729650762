import React from 'react';

interface MiniMapProps {
    latitude: number | undefined;
    longitude: number | undefined;
    city: string | undefined;
    stateOrProvinceCode: string | undefined;
    postalCode: string | undefined;
    width?: number;
    height?: number;
}

const MiniMap: React.FC<MiniMapProps> = (props: MiniMapProps) => {
    const { latitude, longitude, city, stateOrProvinceCode, postalCode } =
        props;

    if (
        !latitude ||
        !longitude ||
        !city ||
        !stateOrProvinceCode ||
        !postalCode
    ) {
        return <></>;
    }

    function mapPinDescription() {
        return `${props.city},+${props.stateOrProvinceCode},+${props.postalCode}`;
    }

    const height = props.height || 156;
    const width = props.width || 349;

    return (
        <a
            href={`http://maps.apple.com/?ll=${latitude},${longitude}&q=${mapPinDescription()}`}
            target="_new"
        >
            <img
                src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+000(${longitude},${latitude})/${longitude},${latitude},11/${width}x${height}?access_token=pk.eyJ1IjoiY2hyaXN0b3BoZXJzdG90dCIsImEiOiJjazdmZnZ1eDIwMmR0M25wcnMxOWMzNTIzIn0.LBCxe4yd1d0SohHTSvzSmg`}
                alt="Map"
            />
            `
        </a>
    );
};
export default MiniMap;
