import { usePaymentMethodsApi } from 'apis';
import { CircleButton } from 'Cargo/Controls/CircleButton';
import Link from 'Cargo/Controls/Link';
import LinkButton from 'Cargo/Controls/LinkButton';
import FullWidthLayout from 'Cargo/Layout/FullWidthLayout';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import useConfirmModal from 'Cargo/Modal/useConfirmModal';
import PageTitle from 'Cargo/Text/PageTitle';
import { H1, Microcopy } from 'Cargo/Text/Text';
import { BankAccount, PaymentMethod } from 'generated-openapi-client';
import { CreditCard } from 'generated-openapi-client/models/CreditCard';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddBankAccountButton } from './Components/AddBankAccountButton';
import BankAccountsSection from './Components/BankAccountsSection';
import { useAddNewBankAccount } from './Hooks/useAddNewBankAccount';

function BankAccountsScreen() {
    const [bankAccounts, setBankAccounts] = useState<Array<BankAccount>>([]);
    const [creditCards, setCreditCards] = useState<Array<CreditCard>>([]);
    const [initialLoading, setInitialLoading] = useState(true);
    const [allowRemoveAccountButton, setAllowRemoveAccountButton] =
        useState(false);

    const navigate = useNavigate();

    const paymentMethodsApi = usePaymentMethodsApi();
    const confirm = useConfirmModal(
        'Confirm',
        'Are you sure you want to remove this bank account'
    );

    async function loadBankAccounts() {
        const response = await paymentMethodsApi.getAllPaymentMethods();
        setBankAccounts(response.bankAccounts);
        setCreditCards(response.creditCards);
        // Don't allow removing the last payment method
        setAllowRemoveAccountButton(
            response.creditCards.length + response.bankAccounts.length > 1
        );
        setInitialLoading(false);
    }

    const addNewBankAccount = useAddNewBankAccount(async function (
        newPaymentMethodId: string | undefined
    ) {
        // If we did successully add a new bank account, make that
        // the default payment method for this company
        if (newPaymentMethodId) {
            await paymentMethodsApi.postPaymentMethodsSetDefaultPaymentMethod({
                setDefaultPaymentMethodRequest: {
                    stripePaymentMethodId: newPaymentMethodId,
                    paymentMethod: PaymentMethod.BankAccount,
                    newPaymentMethod: true,
                },
            });
        }

        await loadBankAccounts();
    });

    useEffect(() => {
        loadBankAccounts();
    }, []);

    async function removeBankAccount(stripePaymentMethodId: string) {
        const confirmed = await confirm();
        if (confirmed) {
            await paymentMethodsApi.postPaymentMethodsRemove({
                removePaymentMethodRequest: {
                    stripePaymentMethodId,
                },
            });
            await loadBankAccounts();
        }
    }

    async function setDefaultBankAccount(stripePaymentMethodId: string) {
        console.log(`setDefaultBankAccount`, { stripePaymentMethodId });
        await paymentMethodsApi.postPaymentMethodsSetDefaultPaymentMethod({
            setDefaultPaymentMethodRequest: {
                stripePaymentMethodId: stripePaymentMethodId,
                paymentMethod: PaymentMethod.BankAccount,
                newPaymentMethod: false,
            },
        });
        await loadBankAccounts();
    }

    const noBankAccounts =
        bankAccounts === undefined || bankAccounts.length === 0;

    return (
        <>
            <PageTitle>Bank Accounts</PageTitle>
            <FullWidthLayout
                header={
                    <>
                        Bank Accounts
                        <span
                            style={{
                                position: 'relative',
                                top: '-3px',
                                marginLeft: '6px',
                            }}
                        >
                            <CircleButton onClick={addNewBankAccount} />
                        </span>
                    </>
                }
                microcopy={
                    <>
                        You can manage your bank accounts here. Don&apos;t have
                        access to the company bank account?{' '}
                        <Link onClick={() => navigate('/manage-users')}>
                            Click here to invite someone else to add it
                        </Link>
                        .
                    </>
                }
                loading={initialLoading}
                showEmptyState={noBankAccounts}
                emptyState={
                    <Stack>
                        <H1>You don&apos;t have any bank accounts saved yet</H1>
                        <Microcopy>
                            Adding a bank account to FreightSimple makes it
                            convenient and easy to pay for your freight.
                        </Microcopy>
                        <Spacer height={32} />
                        <AddBankAccountButton onClick={addNewBankAccount} />
                        <Spacer height={32} />
                        <LinkButton onClick={() => navigate('/manage-users')}>
                            Don&apos;t have access to the company bank account
                            details? Click here to invite someone who does
                        </LinkButton>
                    </Stack>
                }
                content={
                    <>
                        <BankAccountsSection
                            creditCards={creditCards}
                            bankAccounts={bankAccounts}
                            setDefaultBankAccount={setDefaultBankAccount}
                            removeBankAccount={removeBankAccount}
                            allowRemoveAccountButton={allowRemoveAccountButton}
                        />
                    </>
                }
            />
        </>
    );
}

export default BankAccountsScreen;
