import Colors from 'Cargo/Colors';
import Button from 'Cargo/Controls/Button';
import Dropdown from 'Cargo/Controls/Dropdown';
import LinkButton from 'Cargo/Controls/LinkButton';
import { formatPhoneNumberWithExtension } from 'Cargo/Controls/PhoneInput';
import Icon from 'Cargo/Icons/Icon';
import ProgressSpinner, {
    ProgressSpinnerSizes,
} from 'Cargo/Icons/ProgressSpinner';
import Box from 'Cargo/Layout/Box';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import useConfirmModal from 'Cargo/Modal/useConfirmModal';
import { useModal } from 'Cargo/Modal/useModal';
import { Legalese, ModalTitle } from 'Cargo/Text/Text';
import { UUID } from 'Cargo/Types/types';
import { useSavedLocationsService } from 'Features/Locations/Services/SavedLocationsService';
import { Contact, SavedContact, SavedLocation } from 'generated-openapi-client';
import { LocationContext } from 'generated-openapi-client/models/LocationContext';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import useAddContactModal from './useAddContactModal';
import useModifyContactModal from './useModifyContactModal';

const ContactLabel = styled.div`
    font-size: 12px;
    color: var(--freightsimple-color-light-text);
    font-weight: var(--nhu-font-weight-light);
    margin-top: 16px;
`;

const ContactName = styled.div`
    font-weight: var(--nhu-font-weight-medium);
    font-size: 18px;
    color: var(--freightsimple-color-normal-text);
`;

function dashIfMissing(s: string | undefined): string {
    if (s === undefined || s === '') {
        return '-';
    } else {
        return s;
    }
}
interface SwitchContactProps {
    contactId: UUID;
    setContactId: (_: UUID) => void;
    contacts: Array<SavedContact>;
    defaultSavedContactId: UUID | undefined;
    flavour: ManageContactsModalFlavour;
}

function SwitchContact(props: SwitchContactProps) {
    function description(c: SavedContact) {
        const name = c.contact.contactName;

        if (c.savedContactId === props.defaultSavedContactId) {
            if (props.flavour === ManageContactsModalFlavour.AddressBook) {
                return `${name} (Default)`;
            } else {
                return name;
            }
        } else {
            return name;
        }
    }

    const options = props.contacts.map((c) => {
        return {
            item: c.savedContactId,
            description: description(c),
        };
    });

    return (
        <Dropdown
            selectedItem={props.contactId}
            onOptionSelected={props.setContactId}
            options={options}
            errorMessage={undefined}
            width={420}
        />
    );
}

interface ManageContactsModalProps {
    savedLocationId: UUID;
    onDone: (updatedContact: Contact) => void;
    onCancel: () => void;
    flavour: ManageContactsModalFlavour;
    locationContext: LocationContext | undefined;
}

export function ManageContactsModal(props: ManageContactsModalProps) {
    const { flavour, locationContext } = props;
    const [selectedContactId, setSelectedContactId] = useState<
        UUID | undefined
    >();
    const [savedLocation, setSavedLocation] = useState<
        SavedLocation | undefined
    >();
    const showAddContactModal = useAddContactModal();
    const showModifyContactModal = useModifyContactModal();
    const service = useSavedLocationsService();
    const confirmDelete = useConfirmModal(
        'Confirm delete',
        'Are you sure you want to delete this contact?'
    );

    async function loadSavedLocations() {
        const sl = await service.getLocation(props.savedLocationId);
        setSavedLocation(sl);
        setSelectedContactId(sl.defaultSavedContactId);
    }

    useEffect(
        function () {
            loadSavedLocations();
        },
        [props.savedLocationId]
    );

    if (
        flavour === ManageContactsModalFlavour.ModifyAfterBooking &&
        locationContext === undefined
    ) {
        throw new Error('Missing locationContext');
    }

    const selectedContact = savedLocation?.contacts.find(
        (sc) => sc.savedContactId === selectedContactId
    );

    if (
        savedLocation === undefined ||
        selectedContactId === undefined ||
        selectedContact === undefined
    ) {
        return (
            <div style={{ width: '400px' }}>
                <ProgressSpinner size={ProgressSpinnerSizes.Small} />
            </div>
        );
    }

    async function onAddNewContact() {
        const newContact = await showAddContactModal(props.savedLocationId);
        if (newContact !== undefined) {
            await service.addContact(newContact);
            await loadSavedLocations();
            setSelectedContactId(newContact.savedContactId);
        }
    }

    async function onModifyContact() {
        const contactIdToModify = selectedContactId;

        if (contactIdToModify === undefined) {
            throw new Error('Missing contactIdToModify');
        }

        if (selectedContact === undefined) {
            throw new Error('Missing selectedContact');
        }

        const modifiedContact = await showModifyContactModal(selectedContact);

        if (modifiedContact !== undefined) {
            await service.modifyContact(
                contactIdToModify,
                modifiedContact.contact
            );
            await loadSavedLocations();
            setSelectedContactId(contactIdToModify);
        }
    }

    async function onDeleteContact() {
        if (selectedContactId === undefined) {
            throw new Error('Missing selectedContactId');
        }

        const confirmed = await confirmDelete();

        if (confirmed) {
            await service.deleteContact(selectedContactId);
            await loadSavedLocations();
        }
    }

    async function onSetContactAsDefault() {
        if (selectedContactId === undefined) {
            throw new Error('No selectedContactId');
        }

        await service.setDefaultContact(
            props.savedLocationId,
            selectedContactId
        );
        await loadSavedLocations();
    }

    async function onDone() {
        const contact = savedLocation?.contacts.find(function (c) {
            return c.savedContactId === selectedContactId;
        });

        if (contact === undefined) {
            throw new Error('onDone');
        }
        props.onDone(contact.contact);
    }

    return (
        <div style={{ width: '420px' }}>
            {props.flavour === ManageContactsModalFlavour.AddressBook && (
                <ModalTitle>Manage Contacts</ModalTitle>
            )}
            {props.flavour ===
                ManageContactsModalFlavour.ModifyAfterBooking && (
                <ModalTitle>Change Contact</ModalTitle>
            )}
            {props.flavour === ManageContactsModalFlavour.AddressBook && (
                <Legalese>Select a contact to manage</Legalese>
            )}
            {props.flavour ===
                ManageContactsModalFlavour.ModifyAfterBooking && (
                <Legalese>
                    You can edit details of the current contact, pick a
                    different one, or add a new one. Changes will also be saved
                    to your address book.
                </Legalese>
            )}
            <Spacer height={8} />

            {savedLocation.contacts.length > 1 && (
                <SwitchContact
                    contactId={selectedContactId}
                    setContactId={setSelectedContactId}
                    contacts={savedLocation.contacts}
                    defaultSavedContactId={savedLocation.defaultSavedContactId}
                    flavour={props.flavour}
                />
            )}

            <Spacer height={16} />

            <Box width={420} style={{ height: '208px', padding: '32px' }}>
                <HorizontalStack
                    verticalAlign="top"
                    align="spread"
                    style={{ height: '100%', width: '100%' }}
                >
                    <Stack align="left">
                        <HorizontalStack>
                            <Icon
                                name="user"
                                color={Colors.Blue}
                                size={20}
                                solid
                                style={{
                                    marginRight: '8px',
                                    position: 'relative',
                                    top: '2px',
                                }}
                            />
                            <ContactName>
                                {selectedContact.contact.contactName}
                            </ContactName>
                        </HorizontalStack>
                        <Stack align="left">
                            <ContactLabel>Phone Number</ContactLabel>
                            <div>
                                {formatPhoneNumberWithExtension(
                                    selectedContact.contact.phoneNumber,
                                    selectedContact.contact.phoneNumberExtension
                                )}
                            </div>
                        </Stack>
                        <Spacer width={16} />
                        <Stack align="left">
                            <ContactLabel>Email Address</ContactLabel>
                            <div>
                                {dashIfMissing(
                                    selectedContact.contact.emailAddress
                                )}
                            </div>
                        </Stack>
                    </Stack>
                    <Stack align="right">
                        <LinkButton onClick={onModifyContact}>
                            Modify
                        </LinkButton>
                        <Spacer height={4} />
                        {/* Can only delete if there is more than one left */}
                        {savedLocation.contacts.length > 1 &&
                            props.flavour ===
                                ManageContactsModalFlavour.AddressBook && (
                                <>
                                    <LinkButton onClick={onDeleteContact}>
                                        Delete
                                    </LinkButton>
                                    <Spacer height={4} />
                                </>
                            )}
                        {props.flavour ===
                            ManageContactsModalFlavour.AddressBook && (
                            <LinkButton onClick={onSetContactAsDefault}>
                                Set as default
                            </LinkButton>
                        )}
                    </Stack>
                </HorizontalStack>
            </Box>
            <Spacer height={24} />
            <LinkButton onClick={onAddNewContact}>Add new contact</LinkButton>
            <Spacer height={24} />
            <HorizontalStack width="100%" align="spread">
                {props.flavour ===
                    ManageContactsModalFlavour.ModifyAfterBooking && (
                    <Button secondary onClick={props.onCancel}>
                        Cancel
                    </Button>
                )}
                <Button onClick={onDone}>Done</Button>
            </HorizontalStack>
        </div>
    );
}

export enum ManageContactsModalFlavour {
    AddressBook,
    ModifyAfterBooking,
}

function useManageContactsModal(
    flavour: ManageContactsModalFlavour,
    locationContext?: LocationContext
) {
    const showChangeContactModal = useModal<Contact>();

    async function show(savedLocationId: UUID) {
        const p = new Promise<Contact | undefined>((resolve) => {
            showChangeContactModal(
                (done) => {
                    return (
                        <ManageContactsModal
                            savedLocationId={savedLocationId}
                            onDone={done}
                            onCancel={done}
                            flavour={flavour}
                            locationContext={locationContext}
                        ></ManageContactsModal>
                    );
                },
                (data) => {
                    resolve(data);
                }
            );
        });

        return p;
    }

    return show;
}

export default useManageContactsModal;
