/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CheckIfAddressIsResidentialRequest,
  CheckIfAddressIsResidentialResponse,
  GetStartedSetStripePaymentMethodId,
  SelectQuoteRequest,
  SetAddressAndContactRequest,
  SetBrokerDetailsRequest,
  SetEmergencyDetailsRequest,
} from '../models/index';
import {
    CheckIfAddressIsResidentialRequestFromJSON,
    CheckIfAddressIsResidentialRequestToJSON,
    CheckIfAddressIsResidentialResponseFromJSON,
    CheckIfAddressIsResidentialResponseToJSON,
    GetStartedSetStripePaymentMethodIdFromJSON,
    GetStartedSetStripePaymentMethodIdToJSON,
    SelectQuoteRequestFromJSON,
    SelectQuoteRequestToJSON,
    SetAddressAndContactRequestFromJSON,
    SetAddressAndContactRequestToJSON,
    SetBrokerDetailsRequestFromJSON,
    SetBrokerDetailsRequestToJSON,
    SetEmergencyDetailsRequestFromJSON,
    SetEmergencyDetailsRequestToJSON,
} from '../models/index';

export interface PostCheckIfAddressIsResidentialRequest {
    checkIfAddressIsResidentialRequest?: CheckIfAddressIsResidentialRequest;
}

export interface PostGetStartedSelectQuoteRequest {
    selectQuoteRequest?: SelectQuoteRequest;
}

export interface PostGetStartedSetBrokerDetailsRequest {
    setBrokerDetailsRequest?: SetBrokerDetailsRequest;
}

export interface PostGetStartedSetEmergencyDetailsRequest {
    setEmergencyDetailsRequest?: SetEmergencyDetailsRequest;
}

export interface PostGetStartedSetStripePaymentMethodIdRequest {
    getStartedSetStripePaymentMethodId?: GetStartedSetStripePaymentMethodId;
}

export interface PostShipmentsSetAddressAndContactRequest {
    setAddressAndContactRequest?: SetAddressAndContactRequest;
}

/**
 * 
 */
export class GetStartedApi extends runtime.BaseAPI {

    /**
     * 
     * 
     */
    async postCheckIfAddressIsResidentialRaw(requestParameters: PostCheckIfAddressIsResidentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckIfAddressIsResidentialResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/get-started/check-if-address-is-residential`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckIfAddressIsResidentialRequestToJSON(requestParameters['checkIfAddressIsResidentialRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckIfAddressIsResidentialResponseFromJSON(jsonValue));
    }

    /**
     * 
     * 
     */
    async postCheckIfAddressIsResidential(requestParameters: PostCheckIfAddressIsResidentialRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckIfAddressIsResidentialResponse> {
        const response = await this.postCheckIfAddressIsResidentialRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Select quote in get started
     */
    async postGetStartedSelectQuoteRaw(requestParameters: PostGetStartedSelectQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/get-started/select-quote`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SelectQuoteRequestToJSON(requestParameters['selectQuoteRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Select quote in get started
     */
    async postGetStartedSelectQuote(requestParameters: PostGetStartedSelectQuoteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postGetStartedSelectQuoteRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postGetStartedSetBrokerDetailsRaw(requestParameters: PostGetStartedSetBrokerDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/get-started/set-broker-details`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetBrokerDetailsRequestToJSON(requestParameters['setBrokerDetailsRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postGetStartedSetBrokerDetails(requestParameters: PostGetStartedSetBrokerDetailsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postGetStartedSetBrokerDetailsRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postGetStartedSetEmergencyDetailsRaw(requestParameters: PostGetStartedSetEmergencyDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/get-started/set-emergency-details`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetEmergencyDetailsRequestToJSON(requestParameters['setEmergencyDetailsRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postGetStartedSetEmergencyDetails(requestParameters: PostGetStartedSetEmergencyDetailsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postGetStartedSetEmergencyDetailsRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postGetStartedSetStripePaymentMethodIdRaw(requestParameters: PostGetStartedSetStripePaymentMethodIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/get-started/set-stripe-payment-method-id`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetStartedSetStripePaymentMethodIdToJSON(requestParameters['getStartedSetStripePaymentMethodId']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postGetStartedSetStripePaymentMethodId(requestParameters: PostGetStartedSetStripePaymentMethodIdRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postGetStartedSetStripePaymentMethodIdRaw(requestParameters, initOverrides);
    }

    /**
     * 
     * 
     */
    async postShipmentsSetAddressAndContactRaw(requestParameters: PostShipmentsSetAddressAndContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/get-started/set-address-and-contact`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetAddressAndContactRequestToJSON(requestParameters['setAddressAndContactRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     * 
     */
    async postShipmentsSetAddressAndContact(requestParameters: PostShipmentsSetAddressAndContactRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postShipmentsSetAddressAndContactRaw(requestParameters, initOverrides);
    }

}
