import { CircleButton } from 'Cargo/Controls/CircleButton';
import FullWidthLayout from 'Cargo/Layout/FullWidthLayout';
import Spacer from 'Cargo/Layout/Spacer';
import { H2 } from 'Cargo/Text/Text';
import { Invitation } from 'generated-openapi-client';
import { useOnce } from 'Hooks/useOnce';
import React, { useState } from 'react';
import { useUserService } from 'Services/UserService';
import InvitationsSection from './Components/InvitationsSection';
import UsersInCompanySection from './Components/UsersInCompanySection';
import { useInviteModal } from './Modals/InviteModal';

function ViewCompanyScreen() {
    const showModal = useInviteModal();
    const service = useUserService();
    const [invitations, setInvitations] = useState<Invitation[]>([]);

    async function loadInvitations() {
        const _invitations = await service.listInvitationsForCompany();
        setInvitations(_invitations);
    }

    function onInvite() {
        showModal(function () {
            loadInvitations();
        });
    }

    useOnce(function () {
        loadInvitations();
    });

    return (
        <>
            <FullWidthLayout
                header={
                    <>
                        Manage Users
                        <span
                            style={{
                                position: 'relative',
                                top: '-3px',
                                marginLeft: '6px',
                            }}
                        >
                            <CircleButton onClick={onInvite} id="invite_user" />
                        </span>
                    </>
                }
                microcopy="Invite colleagues and view who has access to this account"
                content={
                    <>
                        <Spacer height={16} />
                        {invitations.length > 0 && (
                            <>
                                <InvitationsSection
                                    invitations={invitations}
                                    loadInvitations={loadInvitations}
                                    onInvite={onInvite}
                                />
                                <Spacer height={32} />
                                <H2>Existing Users</H2>
                                <Spacer height={16} />
                            </>
                        )}
                        <UsersInCompanySection />
                    </>
                }
            />
        </>
    );
}

export default ViewCompanyScreen;
