/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendInvitationResponse
 */
export interface SendInvitationResponse {
    /**
     * 
     * @type {string}
     * @memberof SendInvitationResponse
     */
    invitationUrl: string;
}

/**
 * Check if a given object implements the SendInvitationResponse interface.
 */
export function instanceOfSendInvitationResponse(value: object): boolean {
    if (!('invitationUrl' in value)) return false;
    return true;
}

export function SendInvitationResponseFromJSON(json: any): SendInvitationResponse {
    return SendInvitationResponseFromJSONTyped(json, false);
}

export function SendInvitationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendInvitationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'invitationUrl': json['invitationUrl'],
    };
}

export function SendInvitationResponseToJSON(value?: SendInvitationResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'invitationUrl': value['invitationUrl'],
    };
}

