/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddSavedContactRequest,
  AddSavedLocationRequest,
  DeleteAddressBookEntryRequest,
  GetAllSavedLocationsResponse,
  GetBranchesResponse,
  ModifySavedContactRequest,
  ModifySavedLocationRequest,
  SavedLocation,
  SetDefaultContactRequest,
} from '../models/index';
import {
    AddSavedContactRequestFromJSON,
    AddSavedContactRequestToJSON,
    AddSavedLocationRequestFromJSON,
    AddSavedLocationRequestToJSON,
    DeleteAddressBookEntryRequestFromJSON,
    DeleteAddressBookEntryRequestToJSON,
    GetAllSavedLocationsResponseFromJSON,
    GetAllSavedLocationsResponseToJSON,
    GetBranchesResponseFromJSON,
    GetBranchesResponseToJSON,
    ModifySavedContactRequestFromJSON,
    ModifySavedContactRequestToJSON,
    ModifySavedLocationRequestFromJSON,
    ModifySavedLocationRequestToJSON,
    SavedLocationFromJSON,
    SavedLocationToJSON,
    SetDefaultContactRequestFromJSON,
    SetDefaultContactRequestToJSON,
} from '../models/index';

export interface GetSavedLocationsDeleteContactRequest {
    savedContactId: string;
}

export interface GetSavedLocationsGetOneRequest {
    savedLocationId: string;
}

export interface PostAddressBookDeleteRequest {
    deleteAddressBookEntryRequest?: DeleteAddressBookEntryRequest;
}

export interface PostSavedLocationsAddRequest {
    addSavedLocationRequest?: AddSavedLocationRequest;
}

export interface PostSavedLocationsAddContactRequest {
    addSavedContactRequest?: AddSavedContactRequest;
}

export interface PostSavedLocationsAddDefaultContactRequest {
    addSavedContactRequest?: AddSavedContactRequest;
}

export interface PostSavedLocationsModifyRequest {
    modifySavedLocationRequest?: ModifySavedLocationRequest;
}

export interface PostSavedLocationsModifyContactRequest {
    modifySavedContactRequest?: ModifySavedContactRequest;
}

export interface PostSavedLocationsSetDefaultContactRequest {
    setDefaultContactRequest?: SetDefaultContactRequest;
}

export interface PostSavedLocationsToggleBranchRequest {
    savedLocationId: string;
}

export interface PostSavedLocationsToggleDefaultDeliveryLocationRequest {
    savedLocationId: string;
}

export interface PostSavedLocationsToggleDefaultPickupLocationRequest {
    savedLocationId: string;
}

export interface PostSavedLocationsToggleFavouriteRequest {
    savedLocationId: string;
}

/**
 * 
 */
export class SavedLocationsApi extends runtime.BaseAPI {

    /**
     * Your GET endpoint
     */
    async getAllSavedLocationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAllSavedLocationsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/saved-locations/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAllSavedLocationsResponseFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getAllSavedLocations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAllSavedLocationsResponse> {
        const response = await this.getAllSavedLocationsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getBranchesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBranchesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/saved-locations/branches`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBranchesResponseFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getBranches(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBranchesResponse> {
        const response = await this.getBranchesRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     * Your GET endpoint
     */
    async getSavedLocationsDeleteContactRaw(requestParameters: GetSavedLocationsDeleteContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['savedContactId'] == null) {
            throw new runtime.RequiredError(
                'savedContactId',
                'Required parameter "savedContactId" was null or undefined when calling getSavedLocationsDeleteContact().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['savedContactId'] != null) {
            queryParameters['savedContactId'] = requestParameters['savedContactId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/saved-locations/delete-contact`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     * Your GET endpoint
     */
    async getSavedLocationsDeleteContact(requestParameters: GetSavedLocationsDeleteContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getSavedLocationsDeleteContactRaw(requestParameters, initOverrides);
    }

    /**
     * Your GET endpoint
     */
    async getSavedLocationsGetOneRaw(requestParameters: GetSavedLocationsGetOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SavedLocation>> {
        if (requestParameters['savedLocationId'] == null) {
            throw new runtime.RequiredError(
                'savedLocationId',
                'Required parameter "savedLocationId" was null or undefined when calling getSavedLocationsGetOne().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['savedLocationId'] != null) {
            queryParameters['savedLocationId'] = requestParameters['savedLocationId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/saved-locations/get-one`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SavedLocationFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getSavedLocationsGetOne(requestParameters: GetSavedLocationsGetOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SavedLocation> {
        const response = await this.getSavedLocationsGetOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async postAddressBookDeleteRaw(requestParameters: PostAddressBookDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/saved-locations/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteAddressBookEntryRequestToJSON(requestParameters['deleteAddressBookEntryRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postAddressBookDelete(requestParameters: PostAddressBookDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postAddressBookDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postSavedLocationsAddRaw(requestParameters: PostSavedLocationsAddRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/saved-locations/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddSavedLocationRequestToJSON(requestParameters['addSavedLocationRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postSavedLocationsAdd(requestParameters: PostSavedLocationsAddRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSavedLocationsAddRaw(requestParameters, initOverrides);
    }

    /**
     * Add a new contact
     */
    async postSavedLocationsAddContactRaw(requestParameters: PostSavedLocationsAddContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/saved-locations/add-contact`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddSavedContactRequestToJSON(requestParameters['addSavedContactRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add a new contact
     */
    async postSavedLocationsAddContact(requestParameters: PostSavedLocationsAddContactRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSavedLocationsAddContactRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postSavedLocationsAddDefaultContactRaw(requestParameters: PostSavedLocationsAddDefaultContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/saved-locations/add-default-contact`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddSavedContactRequestToJSON(requestParameters['addSavedContactRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postSavedLocationsAddDefaultContact(requestParameters: PostSavedLocationsAddDefaultContactRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSavedLocationsAddDefaultContactRaw(requestParameters, initOverrides);
    }

    /**
     * savedLocations
     */
    async postSavedLocationsModifyRaw(requestParameters: PostSavedLocationsModifyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/saved-locations/modify`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModifySavedLocationRequestToJSON(requestParameters['modifySavedLocationRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * savedLocations
     */
    async postSavedLocationsModify(requestParameters: PostSavedLocationsModifyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSavedLocationsModifyRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postSavedLocationsModifyContactRaw(requestParameters: PostSavedLocationsModifyContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/saved-locations/modify-contact`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModifySavedContactRequestToJSON(requestParameters['modifySavedContactRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postSavedLocationsModifyContact(requestParameters: PostSavedLocationsModifyContactRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSavedLocationsModifyContactRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postSavedLocationsSetDefaultContactRaw(requestParameters: PostSavedLocationsSetDefaultContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/saved-locations/set-default-contact`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetDefaultContactRequestToJSON(requestParameters['setDefaultContactRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postSavedLocationsSetDefaultContact(requestParameters: PostSavedLocationsSetDefaultContactRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSavedLocationsSetDefaultContactRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postSavedLocationsToggleBranchRaw(requestParameters: PostSavedLocationsToggleBranchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['savedLocationId'] == null) {
            throw new runtime.RequiredError(
                'savedLocationId',
                'Required parameter "savedLocationId" was null or undefined when calling postSavedLocationsToggleBranch().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['savedLocationId'] != null) {
            queryParameters['savedLocationId'] = requestParameters['savedLocationId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/saved-locations/toggle-branch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postSavedLocationsToggleBranch(requestParameters: PostSavedLocationsToggleBranchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSavedLocationsToggleBranchRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postSavedLocationsToggleDefaultDeliveryLocationRaw(requestParameters: PostSavedLocationsToggleDefaultDeliveryLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['savedLocationId'] == null) {
            throw new runtime.RequiredError(
                'savedLocationId',
                'Required parameter "savedLocationId" was null or undefined when calling postSavedLocationsToggleDefaultDeliveryLocation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['savedLocationId'] != null) {
            queryParameters['savedLocationId'] = requestParameters['savedLocationId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/saved-locations/toggle-default-delivery-location`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postSavedLocationsToggleDefaultDeliveryLocation(requestParameters: PostSavedLocationsToggleDefaultDeliveryLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSavedLocationsToggleDefaultDeliveryLocationRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postSavedLocationsToggleDefaultPickupLocationRaw(requestParameters: PostSavedLocationsToggleDefaultPickupLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['savedLocationId'] == null) {
            throw new runtime.RequiredError(
                'savedLocationId',
                'Required parameter "savedLocationId" was null or undefined when calling postSavedLocationsToggleDefaultPickupLocation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['savedLocationId'] != null) {
            queryParameters['savedLocationId'] = requestParameters['savedLocationId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/saved-locations/toggle-default-pickup-location`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postSavedLocationsToggleDefaultPickupLocation(requestParameters: PostSavedLocationsToggleDefaultPickupLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSavedLocationsToggleDefaultPickupLocationRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postSavedLocationsToggleFavouriteRaw(requestParameters: PostSavedLocationsToggleFavouriteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['savedLocationId'] == null) {
            throw new runtime.RequiredError(
                'savedLocationId',
                'Required parameter "savedLocationId" was null or undefined when calling postSavedLocationsToggleFavourite().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['savedLocationId'] != null) {
            queryParameters['savedLocationId'] = requestParameters['savedLocationId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/saved-locations/toggle-favourite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postSavedLocationsToggleFavourite(requestParameters: PostSavedLocationsToggleFavouriteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSavedLocationsToggleFavouriteRaw(requestParameters, initOverrides);
    }

}
