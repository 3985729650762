export function groupBy<GroupType, ItemType>(
    items: ItemType[],
    grouper: (_: ItemType) => GroupType
): Array<{ key: GroupType; value: ItemType[] }> {
    const map = new Map<string, ItemType[]>();
    items.forEach(function (item) {
        const key = grouper(item);
        const jsonKey = JSON.stringify(key);
        const values = map.get(jsonKey) || []; // initialize if not existing
        values.push(item);
        map.set(jsonKey, values);
    });

    const results: Array<{ key: GroupType; value: ItemType[] }> = [];
    map.forEach(function (value, key) {
        results.push({ key: JSON.parse(key), value });
    });
    return results;
}
