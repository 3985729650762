import styled from 'styled-components/macro';

export const ViewShipmentSectionTable = styled.div`
    & table {
        width: 946px;
    }

    & tr {
        height: 48px;
    }

    & tr:nth-child(odd) {
        background-color: #f7f7f7;
    }

    & td {
        padding: 16px;
    }

    & th {
        padding: 16px;
        background-color: #fff;
    }
`;
