import Colors from 'Cargo/Colors';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import { ShipmentState } from 'generated-openapi-client';
import styled from 'styled-components/macro';

interface ColumnProps {
    twoColumns?: boolean;
}

const Column = styled.div<ColumnProps>`
    width: ${(props) => (props.twoColumns ? '50%' : '33%')};
`;

interface CirclePartProps {
    color: string;
}

function CirclePart(props: CirclePartProps) {
    return (
        <div style={{ zIndex: 100 }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
            >
                <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                    <g transform="translate(-272 -1298)">
                        <g transform="translate(251 1083)">
                            <g
                                stroke={props.color}
                                strokeWidth="6"
                                transform="translate(21 215)"
                            >
                                <circle cx="15" cy="15" r="12"></circle>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
}

interface LinePartProps {
    color: string;
}

function LinePart(props: LinePartProps) {
    return (
        <div
            style={{
                position: 'relative',
                backgroundColor: props.color,
                width: '100%',
                height: '10px',
                marginLeft: '-5px',
                marginRight: '-5px',
            }}
        ></div>
    );
}

interface ProgressProps {
    shipmentState: ShipmentState;
    twoColumns?: boolean;
}

function Progress(props: ProgressProps) {
    const shipmentState = props.shipmentState;

    if (
        shipmentState === ShipmentState.Quoted ||
        shipmentState === ShipmentState.QuoteRequested
    ) {
        return (
            <HorizontalStack width="100%">
                <Column twoColumns={props.twoColumns}>
                    <HorizontalStack>
                        <CirclePart color={Colors.VeryLightGray} />
                        <LinePart color={Colors.VeryLightGray} />
                    </HorizontalStack>
                </Column>
                <Column twoColumns={props.twoColumns}>
                    <HorizontalStack>
                        <CirclePart color={Colors.VeryLightGray} />
                        {!props.twoColumns && (
                            <LinePart color={Colors.VeryLightGray} />
                        )}
                    </HorizontalStack>
                </Column>
                {!props.twoColumns && (
                    <Column>
                        <CirclePart color={Colors.VeryLightGray} />
                    </Column>
                )}
            </HorizontalStack>
        );
    }

    if (
        shipmentState === ShipmentState.BookingRequested ||
        shipmentState === ShipmentState.BookingConfirmed ||
        shipmentState === ShipmentState.BookingFailed
    ) {
        return (
            <HorizontalStack width="100%">
                <Column>
                    <HorizontalStack>
                        <CirclePart color={Colors.Gold} />
                        <LinePart color={Colors.VeryLightGray} />
                    </HorizontalStack>
                </Column>
                <Column>
                    <HorizontalStack>
                        <CirclePart color={Colors.VeryLightGray} />
                        <LinePart color={Colors.VeryLightGray} />
                    </HorizontalStack>
                </Column>
                <Column>
                    <CirclePart color={Colors.VeryLightGray} />
                </Column>
            </HorizontalStack>
        );
    }

    if (
        shipmentState === ShipmentState.Cancelled ||
        shipmentState === ShipmentState.OnHold
    ) {
        return (
            <HorizontalStack width="100%">
                <Column>
                    <HorizontalStack>
                        <CirclePart color={Colors.VeryLightGray} />
                        <LinePart color={Colors.VeryLightGray} />
                    </HorizontalStack>
                </Column>
                <Column>
                    <HorizontalStack>
                        <CirclePart color={Colors.VeryLightGray} />
                        <LinePart color={Colors.VeryLightGray} />
                    </HorizontalStack>
                </Column>
                <Column>
                    <CirclePart color={Colors.VeryLightGray} />
                </Column>
            </HorizontalStack>
        );
    }

    if (shipmentState === ShipmentState.InTransit) {
        return (
            <HorizontalStack width="100%">
                <Column>
                    <HorizontalStack>
                        <CirclePart color={Colors.Blue} />
                        <LinePart color={Colors.Blue} />
                    </HorizontalStack>
                </Column>
                <Column>
                    <HorizontalStack>
                        <CirclePart color={Colors.Blue} />
                        <LinePart color={Colors.VeryLightGray} />
                    </HorizontalStack>
                </Column>
                <Column>
                    <CirclePart color={Colors.VeryLightGray} />
                </Column>
            </HorizontalStack>
        );
    }

    if (shipmentState === ShipmentState.Lost) {
        return (
            <HorizontalStack width="100%">
                <Column>
                    <HorizontalStack>
                        <CirclePart color={Colors.VeryLightGray} />
                        <LinePart color={Colors.VeryLightGray} />
                    </HorizontalStack>
                </Column>
                <Column>
                    <HorizontalStack>
                        <CirclePart color={Colors.VeryLightGray} />
                        <LinePart color={Colors.VeryLightGray} />
                    </HorizontalStack>
                </Column>
                <Column>
                    <CirclePart color={Colors.VeryLightGray} />
                </Column>
            </HorizontalStack>
        );
    }

    if (shipmentState === ShipmentState.Delivered) {
        return (
            <HorizontalStack width="100%">
                <Column>
                    <HorizontalStack>
                        <CirclePart color={Colors.DarkGreen} />
                        <LinePart color={Colors.DarkGreen} />
                    </HorizontalStack>
                </Column>
                <Column>
                    <HorizontalStack>
                        <CirclePart color={Colors.DarkGreen} />
                        <LinePart color={Colors.DarkGreen} />
                    </HorizontalStack>
                </Column>
                <Column>
                    <CirclePart color={Colors.DarkGreen} />
                </Column>
            </HorizontalStack>
        );
    }

    console.error('Unhandled : ' + shipmentState);
    throw new Error('Unhandled : ' + shipmentState);
}
export default Progress;
