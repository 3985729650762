import Button from 'Cargo/Controls/Button';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import { useModalsSlice } from 'Cargo/Modal/modalsSlice';
import { useModal } from 'Cargo/Modal/useModal';
import { Legalese, ModalTitle } from 'Cargo/Text/Text';
import AddressInput from 'Features/Locations/Components/AddressInput';
import { findSavedLocationForLocation } from 'Features/Locations/Helpers/findSavedLocationForLocation';
import { useSavedLocations } from 'Features/Locations/Hooks/useSavedLocations';
import { useSavedLocationsService } from 'Features/Locations/Services/SavedLocationsService';
import { errorMessagesForLocation } from 'Features/Locations/Validators/errorMessagesForLocation';
import { useShipmentService } from 'Services/ShipmentService';
import {
    SavedLocation,
    Shipment,
    ShipmentState,
} from 'generated-openapi-client';
import { Location } from 'generated-openapi-client/models/Location';
import { LocationContext } from 'generated-openapi-client/models/LocationContext';
import { useState } from 'react';
import { useUpdatedBOLModal } from './UpdatedBOLModal';

interface ModifyAddressModalProps {
    location: Location;
    onDone: (updatedLocation: Location) => void;
    onCancel: () => void;
    locationContext: LocationContext | undefined;
}

function ModifyAddressModal(props: ModifyAddressModalProps) {
    const [location, setLocation] = useState(props.location);
    const [forceValidation, setForceValidation] = useState(false);
    const locationErrorMessages = errorMessagesForLocation(location, false);

    function isValid() {
        if (locationErrorMessages.address.addressLine !== undefined) {
            return false;
        }

        if (locationErrorMessages.businessName !== undefined) {
            return false;
        }

        return true;
    }

    function onDone() {
        if (isValid()) {
            props.onDone(location);
        } else {
            setForceValidation(true);
        }
    }

    return (
        <div style={{ width: '520px' }}>
            <ModalTitle>Change Address</ModalTitle>
            <Legalese>
                Enter updated details for this shipment. Changes will also be
                saved to your address book
            </Legalese>
            <Spacer height={8} />
            <AddressInput
                enabled={true}
                businessName={location.businessName || ''}
                businessNameChanged={function (newBusinessName) {
                    setLocation({
                        ...location,
                        businessName: newBusinessName,
                    });
                }}
                locationType={location.locationType}
                address={location.address}
                addressChanged={function (newAddress) {
                    const newLocation = {
                        ...location,
                        address: {
                            ...location.address,
                            ...newAddress,
                        },
                    };
                    setLocation(newLocation);
                }}
                forceValidation={forceValidation}
                businessNameErrorMessages={locationErrorMessages.businessName}
                addressErrorMessages={locationErrorMessages.address}
                addressOptions={[]}
                allowCityAndPostalCodeEditing={false}
                warnAboutPotentialBusinessNameErrors={true}
            />
            <Spacer height={24} />
            <HorizontalStack width="100%" align="spread">
                <Button secondary onClick={props.onCancel}>
                    Cancel
                </Button>

                <Button onClick={onDone}>Done</Button>
            </HorizontalStack>
        </div>
    );
}

function useModifyAddressModal(locationContext?: LocationContext) {
    const showChangeContactModal = useModal<Location>();

    async function show(location: Location) {
        const p = new Promise<Location | undefined>((resolve) => {
            showChangeContactModal(
                (done) => {
                    return (
                        <ModifyAddressModal
                            onDone={done}
                            onCancel={done}
                            locationContext={locationContext}
                            location={location}
                        ></ModifyAddressModal>
                    );
                },
                (data) => {
                    resolve(data);
                }
            );
        });

        return p;
    }

    return show;
}

function findSavedLocationId(
    locationContext: LocationContext,
    shipment: Shipment,
    savedLocations: Array<SavedLocation>
) {
    const location =
        locationContext === LocationContext.Pickup
            ? shipment.pickupLocation
            : shipment.deliveryLocation;

    return findSavedLocationForLocation(savedLocations, location)
        ?.savedLocationId;
}

function useModifyAddressAfterBookingModal(
    shipment: Shipment,
    locationContext: LocationContext
) {
    const showChangeAddressModal = useModifyAddressModal(locationContext);
    const showUpdatedBOLModal = useUpdatedBOLModal();
    const shipmentService = useShipmentService();
    const savedLocationsService = useSavedLocationsService();
    const { setLoading } = useModalsSlice();
    const { savedLocations } = useSavedLocations();

    // Returns if the broker has changed, hence we should reload
    async function show(): Promise<boolean> {
        const savedLocationId = findSavedLocationId(
            locationContext,
            shipment,
            savedLocations
        );

        const originalLocation =
            locationContext === LocationContext.Pickup
                ? shipment.pickupLocation
                : shipment.deliveryLocation;

        console.log(`savedLocationId=${savedLocationId}`);

        if (savedLocationId === undefined) {
            // We should handle this - seems like a real edge case
            // where they have modified the location after booking
            throw new Error('No saved location found');
        }

        // We need to show a screen that allows them to select from any of the saved brokers,
        // and optionally modify one of them or add a new one
        // const newSavedBrokerId = await showSwitchBrokerModal(currentBroker);
        const updatedLocation = await showChangeAddressModal(originalLocation);

        console.log(`updatedLocation`, { updatedLocation });

        if (updatedLocation === undefined) {
            // Cancel pressed
            return false;
        }

        function areAddressesEqual(location1: Location, location2: Location) {
            // Note the email address is missing here. We don't put the
            // contact's email on BOLs or send to the carrier.
            // So if that's all that has changed
            return (
                location1.businessName === location2.businessName &&
                location1.address.addressLine ===
                    location2.address.addressLine &&
                location1.address.addressLine2 ===
                    location2.address.addressLine2 &&
                location1.address.city === location2.address.city &&
                location1.address.countryCode ===
                    location2.address.countryCode &&
                location1.address.postalCode === location2.address.postalCode &&
                location1.address.stateOrProvinceCode ===
                    location2.address.stateOrProvinceCode
            );
        }

        if (areAddressesEqual(updatedLocation, originalLocation)) {
            // Nothing changed
            return false;
        }

        setLoading(true);

        // Make an API call to updated the saved location
        if (savedLocationId !== undefined) {
            await savedLocationsService.modifyLocation(
                savedLocationId,
                updatedLocation
            );
        } else {
            console.warn(`No savedLocationId`);
        }

        // Make an API call to change the broker
        await shipmentService.updateLocation(
            shipment.shipmentId,
            locationContext,
            updatedLocation
        );
        setLoading(false);

        // If the shipment is in transit, then it's too late to give the driver
        // a new BOL. But if it's still showing as pending pickup, it'd worth showing them this screen
        if (shipment.shipmentState !== ShipmentState.InTransit) {
            await showUpdatedBOLModal();
        }

        return true;
    }

    return show;
}

export default useModifyAddressAfterBookingModal;
