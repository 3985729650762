import LinkButton from 'Cargo/Controls/LinkButton';
import Tooltip from 'Cargo/Controls/Tooltip';
import DynamicHeightSpacer from 'Cargo/Layout/DynamicSpacer';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { UUID } from 'Cargo/Types/types';
import { ErrorMessageType } from 'Cargo/Validation';
import AdditionalChargesQuestionBubble from 'Features/BookShipment/Components/QuestionBubbles/AdditionalChargesQuestionBubble';
import LineItemContainer from 'Features/LineItems/Components/LineItemContainer';
import { AddLineItemType } from 'Features/LineItems/Types/lineItemTypes';
import { LineItemErrors } from 'Features/LineItems/Validators/errorMessagesForLineItem';
import { LineItem, SavedCommodity } from 'generated-openapi-client';
import { totalWeightForLineItems } from 'Helpers/lineItemCalculations';
import { Units } from 'Helpers/units';
import { useState } from 'react';
import BookShipmentSection from './BookShipmentSection';
import { SelectInitialSavedCommodity } from './SelectInitialSavedCommodity';

interface MultipleLineItemsContainerProps {
    lineItems: Array<AddLineItemType>;
    forceValidation: boolean;
    errorMessages: Map<string, LineItemErrors>;
    onUpdateLineItem: (lineItemId: UUID, value: Partial<LineItem>) => void;
    addAnotherLineItem: () => void;
    removeLineItemById: (lineItemId: UUID) => void;
    shouldRenderFreightClass: boolean;
    setHasNmfcError: (lineItemId: UUID, hasNmfcError: boolean) => void;
    savedCommodities: Array<SavedCommodity>;
    addLineItemFromSavedCommodity: (_: SavedCommodity) => void;
    onRearrangeLengthWidth: (lineItemId: UUID) => void;
}

interface AddAnotherLineItemButtonProps {
    addAnotherLineItem: () => void;
    savedCommodities: Array<SavedCommodity>;
    onSelectSavedCommodity: (_: SavedCommodity) => void;
    numLineItems: number;
}

function AddAnotherLineItemButton(props: AddAnotherLineItemButtonProps) {
    if (props.savedCommodities.length === 0) {
        return (
            <LinkButton
                id={`addMoreToThisShipment${props.numLineItems}`}
                leftIcon="plus-circle"
                label="Add more to this shipment"
                onClick={props.addAnotherLineItem}
            />
        );
    }

    return (
        <Tooltip
            id={`addMoreToThisShipment${props.numLineItems}`}
            placement="bottom"
            tooltip={
                <Stack
                    style={{ padding: '32px' }}
                    id="selectedCommoditiesOptions"
                >
                    <div>What would you like to ship?</div>
                    <Spacer height={16} />
                    {props.savedCommodities.map(function (sc) {
                        return (
                            <LinkButton
                                key={sc.savedCommodityId}
                                style={{ fontSize: '20px' }}
                                onClick={function () {
                                    props.onSelectSavedCommodity(sc);
                                }}
                            >
                                {sc.description}
                            </LinkButton>
                        );
                    })}
                    <Spacer height={16} />
                    <LinkButton
                        style={{ fontSize: '20px' }}
                        onClick={props.addAnotherLineItem}
                    >
                        Something else
                    </LinkButton>
                </Stack>
            }
        >
            <LinkButton
                leftIcon="plus-circle"
                label="Add more to this shipment"
            />
        </Tooltip>
    );
}

function MultipleLineItemsContainer(props: MultipleLineItemsContainerProps) {
    const {
        lineItems,
        forceValidation,
        errorMessages,
        savedCommodities,
        onRearrangeLengthWidth,
    } = props;

    const [units, setUnits] = useState(Units.Imperial);

    if (lineItems.length === 0 && savedCommodities.length > 0) {
        return (
            <SelectInitialSavedCommodity
                savedCommodities={savedCommodities}
                onSomethingElse={props.addAnotherLineItem}
                onSelectSavedCommodity={props.addLineItemFromSavedCommodity}
            />
        );
    }

    return (
        <>
            {lineItems.map((lineItem, index) => {
                const multipleLineItems = lineItems.length > 1;
                const errorMessagesForLineItem = errorMessages.get(
                    lineItem.lineItemId
                );
                const isNotLastElement = index !== lineItems.length - 1;
                if (errorMessagesForLineItem === undefined) {
                    throw new Error('Missing error messages for lineItem');
                }

                return (
                    <>
                        <LineItemContainer
                            badge={undefined}
                            index={index + 1}
                            numberLineItems={lineItems.length}
                            key={lineItem.lineItemId}
                            lineItem={lineItem}
                            onChange={(value: Partial<LineItem>) => {
                                props.onUpdateLineItem(
                                    lineItem.lineItemId,
                                    value
                                );
                            }}
                            onRearrangeLengthWidth={() =>
                                onRearrangeLengthWidth(lineItem.lineItemId)
                            }
                            showRemoveLineItemButton={multipleLineItems}
                            onAddLineItem={props.addAnotherLineItem}
                            onRemoveLineItem={() => {
                                props.removeLineItemById(lineItem.lineItemId);
                            }}
                            lineItemErrorMessages={errorMessagesForLineItem}
                            forceValidation={forceValidation}
                            shouldRenderFreightClass={
                                props.shouldRenderFreightClass
                            }
                            setHasNmfcError={function (hasNmfcError: boolean) {
                                props.setHasNmfcError(
                                    lineItem.lineItemId,
                                    hasNmfcError
                                );
                            }}
                            units={units}
                            setUnits={setUnits}
                        />
                        {isNotLastElement && <Spacer height={64} />}
                    </>
                );
            })}
            <DynamicHeightSpacer minHeight={16} maxHeight={40} />
            <HorizontalStack align="center" width="100%">
                <AddAnotherLineItemButton
                    savedCommodities={props.savedCommodities}
                    addAnotherLineItem={props.addAnotherLineItem}
                    onSelectSavedCommodity={props.addLineItemFromSavedCommodity}
                    numLineItems={props.lineItems.length}
                />
            </HorizontalStack>
        </>
    );
}

interface WeightSummaryProps {
    lineItems: AddLineItemType[];
}

function WeightSummary(props: WeightSummaryProps) {
    const totalWeight = totalWeightForLineItems(props.lineItems);
    if (totalWeight === undefined) {
        return <></>;
    }

    if (props.lineItems.length < 2) {
        return <></>;
    }

    return (
        <Stack>
            <Spacer height={8} />
            <div style={{ fontSize: '14px', fontWeight: 400 }}>
                Total Weight: {totalWeight.toLocaleString()}lb
            </div>
        </Stack>
    );
}
interface ShipmentContentsSection2Props {
    lineItems: Array<AddLineItemType>;
    forceValidation: boolean;
    errorMessageForLineItems: Map<string, LineItemErrors>;
    overallErrorMessage: ErrorMessageType;
    shouldRenderFreightClass: boolean;
    index: number;
    onUpdateLineItem: (lineItemId: UUID, value: Partial<LineItem>) => void;
    addAnotherLineItem: () => void;
    removeLineItemById: (lineItemId: UUID) => void;
    setHasNmfcError: (lineItemId: UUID, hasNmfcError: boolean) => void;
    savedCommodities: Array<SavedCommodity>;
    addLineItemFromSavedCommodity: (_: SavedCommodity) => void;
    onRearrangeLengthWidth: (lineItemId: UUID) => void;
}
function ShipmentContentsSection2(props: ShipmentContentsSection2Props) {
    return (
        <BookShipmentSection
            index={props.index}
            header="Shipment Contents"
            description={
                <Stack>
                    <HorizontalStack>
                        Please be accurate with the details you enter below
                        <AdditionalChargesQuestionBubble />
                    </HorizontalStack>
                    <WeightSummary lineItems={props.lineItems} />
                </Stack>
            }
            id="shipment-contents"
        >
            <Spacer height={32} />
            <MultipleLineItemsContainer
                lineItems={props.lineItems}
                forceValidation={props.forceValidation}
                errorMessages={props.errorMessageForLineItems}
                onUpdateLineItem={props.onUpdateLineItem}
                addAnotherLineItem={props.addAnotherLineItem}
                removeLineItemById={props.removeLineItemById}
                shouldRenderFreightClass={props.shouldRenderFreightClass}
                setHasNmfcError={props.setHasNmfcError}
                savedCommodities={props.savedCommodities}
                addLineItemFromSavedCommodity={
                    props.addLineItemFromSavedCommodity
                }
                onRearrangeLengthWidth={props.onRearrangeLengthWidth}
            />
        </BookShipmentSection>
    );
}
export default ShipmentContentsSection2;
