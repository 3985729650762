import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from 'Cargo/Colors';
import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { ToolTipWrapper } from './QuestionBubble';

interface LinkButtonBodyProps {
    align: string | undefined;
    white: boolean | undefined;
    red: boolean | undefined;
    regularWeight?: boolean;
}

function primaryColor(props: LinkButtonBodyProps) {
    if (props.white) {
        return 'white';
    }

    if (props.red) {
        return Colors.Red;
    }

    return Colors.Blue;
}

const LinkButtonBody = styled.a<LinkButtonBodyProps>`
    font-weight: ${(props) =>
        props.regularWeight
            ? 'var(--nhu-font-weight-regular)'
            : 'var(--nhu-font-weight-bold)'};
    font-size: 14px;
    color: ${(props) => primaryColor(props)};
    text-align: ${(props) => props.align || 'center'};

    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    &:not([href]) {
        color: ${(props) => primaryColor(props)};
    }

    &:not([href]):hover {
        text-decoration: underline;
        color: ${(props) => primaryColor(props)};
    }

    &.disabled:hover {
        filter: brightness(1);
        text-decoration: none;
        color: var(--freightsimple-color-light-text);
    }

    &.disabled {
        color: var(--freightsimple-color-light-text);
        cursor: default;
    }
`;

interface LinkButtonProps {
    /** label to display on the button */
    label?: string;
    children?: ReactNode;

    /** Display a left arrow */
    leftArrow?: boolean;
    rightArrow?: boolean;

    onClick?: (_: void) => void;

    to?: string;
    align?: string;

    white?: boolean;
    red?: boolean;

    regularWeight?: boolean;

    confirm?: string;

    style?: CSSProperties;

    // FontAwesome icon name
    rightIcon?: IconName;
    leftIcon?: IconName;

    newTab?: boolean;

    disabled?: boolean;
    // If it is disabled, optionally explain why
    disabledReason?: ReactNode;

    id?: string;
}

interface PotentialToolTipWrapperProps {
    disabled: boolean | undefined;
    disabledReason: ReactNode | undefined;

    children: ReactNode;
}

function PotentialToolTipWrapper(props: PotentialToolTipWrapperProps) {
    if (props.disabled) {
        return (
            <ToolTipWrapper description={props.disabledReason}>
                {props.children}
            </ToolTipWrapper>
        );
    } else {
        return <>{props.children}</>;
    }
}

const LinkButton: React.FC<LinkButtonProps> = (props: LinkButtonProps) => {
    if (!props.label && !props.children && !props.rightIcon) {
        throw new Error('Need to specify label or children');
    }

    function onClick() {
        if(props.disabled) return;
        let ok = true;
        if (props.confirm) {
            ok = confirm(props.confirm);
        }

        if (ok) {
            if (props.onClick) {
                return props.onClick();
            }
        } else {
            return false;
        }
    }

    function leftArrow() {
        if (props.leftArrow) {
            return (
                <FontAwesomeIcon
                    icon={['fas', 'arrow-circle-left']}
                    style={{
                        width: '14px',
                        height: '14px',
                        marginRight: '3px',
                        position: 'relative',
                        color: props.white ? Colors.White : Colors.Blue,
                        top: '1px',
                    }}
                />
            );
        } else {
            return <></>;
        }
    }

    function rightArrow() {
        if (props.rightArrow) {
            return (
                <FontAwesomeIcon
                    icon={['fas', 'arrow-circle-right']}
                    style={{
                        width: '14px',
                        height: '14px',
                        marginLeft: '3px',
                        position: 'relative',
                        color: props.white ? Colors.White : Colors.Blue,
                        top: '1px',
                    }}
                />
            );
        } else {
            return <></>;
        }
    }

    function rightIcon() {
        if (props.rightIcon) {
            return (
                <FontAwesomeIcon
                    icon={['fas', props.rightIcon]}
                    style={{
                        width: '14px',
                        height: '14px',
                        marginLeft: '3px',
                        position: 'relative',
                        color: props.white ? Colors.White : Colors.Blue,
                        top: '1px',
                    }}
                />
            );
        }
    }

    function leftIcon() {
        if (props.leftIcon) {
            return (
                <FontAwesomeIcon
                    icon={['fas', props.leftIcon]}
                    style={{
                        width: '14px',
                        height: '14px',
                        marginRight: '3px',
                        position: 'relative',
                        color: props.white ? Colors.White : Colors.Blue,
                        top: '1px',
                    }}
                />
            );
        }
    }

    return (
        <PotentialToolTipWrapper
            disabled={props.disabled}
            disabledReason={props.disabledReason}
        >
            <LinkButtonBody
                onClick={onClick}
                href={props.to}
                align={props.align}
                white={props.white}
                red={props.red}
                regularWeight={props.regularWeight}
                style={props.style}
                target={props.newTab ? '_new' : undefined}
                className={props.disabled ? 'disabled' : ''}
                id={props.id}
            >
                {leftArrow()}
                {leftIcon()}
                {props.children || props.label}
                {rightIcon()}
                {rightArrow()}
            </LinkButtonBody>
        </PotentialToolTipWrapper>
    );
};
export default LinkButton;
