import { ErrorMessageType } from 'Cargo/Validation';
import { isEmptyOrUndefined } from 'Helpers/isNotEmptyOrUndefined';
import {
    convertFromUnit,
    convertToUnit,
    Dimension,
    Units,
} from 'Helpers/units';
import React from 'react';
import Input from './Input';

interface NumberInputProps {
    id?: string;
    label?: string;
    autoComplete?: string;
    value?: number;
    onChange?: (e: number | undefined) => void;
    name?: string;
    enabled?: boolean;
    autoFocus?: boolean;
    required?: boolean;
    pattern?: string;
    readOnly?: boolean;
    hidden?: boolean;
    width?: number;
    errorMessage: ErrorMessageType;
    forceValidation: boolean;
    hideErrorIcon?: boolean;
    labelPadding?: number;
    validateOnTimeoutMs?: number;
    onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
    units: Units;
    dimension: Dimension;
    onlyDigits?: boolean;
}

const NumberInput: React.FC<NumberInputProps> = (props: NumberInputProps) => {
    const value =
        convertToUnit(props.value, props.units, props.dimension)?.toString() ??
        '';

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (!props.onlyDigits) return;

        if (['e', 'E', '+', '-', '.'].includes(e.key)) {
            e.preventDefault();
        }
    }

    function handleChange(input: string) {
        if (isEmptyOrUndefined(input)) {
            props.onChange?.(undefined);
            return;
        }

        const number = Number(input);

        if (Number.isNaN(number)) {
            return;
        }

        props.onChange?.(convertFromUnit(number, props.units, props.dimension));
    }

    return (
        <Input
            id={props.id}
            label={props.label}
            autoComplete={props.autoComplete}
            value={value}
            onChange={handleChange}
            name={props.name}
            enabled={props.enabled}
            autoFocus={props.autoFocus}
            required={props.required}
            readOnly={props.readOnly}
            hidden={props.hidden}
            width={props.width}
            onKeyDown={handleKeyDown}
            type="number"
            errorMessage={props.errorMessage}
            forceValidation={props.forceValidation}
            hideErrorIcon={props.hideErrorIcon}
            labelPadding={props.labelPadding}
            validateOnTimeoutMs={props.validateOnTimeoutMs}
            onBlur={props.onBlur}
        />
    );
};
export default NumberInput;
