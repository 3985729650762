/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';

/**
 * 
 * @export
 * @interface SavedContact
 */
export interface SavedContact {
    /**
     * 
     * @type {string}
     * @memberof SavedContact
     */
    savedContactId: string;
    /**
     * 
     * @type {Contact}
     * @memberof SavedContact
     */
    contact: Contact;
    /**
     * 
     * @type {string}
     * @memberof SavedContact
     */
    savedLocationId: string;
}

/**
 * Check if a given object implements the SavedContact interface.
 */
export function instanceOfSavedContact(value: object): boolean {
    if (!('savedContactId' in value)) return false;
    if (!('contact' in value)) return false;
    if (!('savedLocationId' in value)) return false;
    return true;
}

export function SavedContactFromJSON(json: any): SavedContact {
    return SavedContactFromJSONTyped(json, false);
}

export function SavedContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): SavedContact {
    if (json == null) {
        return json;
    }
    return {
        
        'savedContactId': json['savedContactId'],
        'contact': ContactFromJSON(json['contact']),
        'savedLocationId': json['savedLocationId'],
    };
}

export function SavedContactToJSON(value?: SavedContact | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'savedContactId': value['savedContactId'],
        'contact': ContactToJSON(value['contact']),
        'savedLocationId': value['savedLocationId'],
    };
}

