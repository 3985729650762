import Colors from 'Cargo/Colors';
import Link from 'Cargo/Controls/Link';
import LinkButton from 'Cargo/Controls/LinkButton';
import CreditCardLogo from 'Cargo/Icons/CreditCardLogo';
import RedExclamationAnimatedIcon from 'Cargo/Icons/RedExclamationAnimatedIcon';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { Currency, Invoice } from 'generated-openapi-client';
import { InvoiceState } from 'generated-openapi-client/models/InvoiceState';
import { InvoiceType } from 'generated-openapi-client/models/InvoiceType';
import { PaymentMethod } from 'generated-openapi-client/models/PaymentMethod';
import moment from 'moment';
import { ReactNode } from 'react';
import styled from 'styled-components/macro';
import ViewShipmentSection from './ViewShipmentSection';
import { ViewShipmentSectionTable } from './ViewShipmentSectionTable';

interface ItemProps {
    invoice: Invoice;
}

function textColor(props: ItemProps, def: string) {
    if (props.invoice.invoiceState === InvoiceState.Voided) {
        return Colors.VeryLightText;
    } else {
        return def;
    }
}

const ChargeDescription = styled.div<ItemProps>`
    font-weight: var(--nhu-font-weight-medium);
    font-size: 18px;
    color: ${(props) => textColor(props, '#545454')};
`;
const ChargeDate = styled.div<ItemProps>`
    font-weight: var(--nhu-font-weight-light);
    font-size: 16px;
    color: ${(props) => textColor(props, '#858585')};
`;
const CardDesciption = styled.div`
    font-weight: var(--nhu-font-weight-light);
    font-size: 16px;
    color: #858585;
`;
const PriceText = styled.div<ItemProps>`
    font-weight: var(--nhu-font-weight-medium);
    font-size: 18px;
    color: ${(props) => textColor(props, '#545454')};
`;
const CurrencyText = styled.div<ItemProps>`
    font-weight: var(--nhu-font-weight-medium);
    font-size: 18px;
    color: ${(props) => textColor(props, Colors.LightText)};
`;

interface UnpaidTypeProps {
    isOverdueOrFailed: boolean;
}

const UnpaidType = styled.div<UnpaidTypeProps>`
    font-weight: var(--nhu-font-weight-regular);
    font-size: 16px;
    color: ${(props) =>
        props.isOverdueOrFailed ? Colors.White : Colors.NormalText};
    background-color: ${(props) =>
        props.isOverdueOrFailed ? Colors.Red : Colors.Gold};
    border-radius: 10px;
    padding: 2px 10px;
`;

const VoidedType = styled.div`
    font-weight: ${Colors.VeryLightGray};
    font-size: 16px;
    color: ${Colors.VeryLightText};
`;

const SettlementStatus = styled.div`
    font-weight: var(--nhu-font-weight-light);
    font-size: 12px;
    color: ${Colors.LightText};
`;

interface BillingSectionProps {
    children?: ReactNode;
    invoices: Array<Invoice>;
    onDownloadInvoice: (invoiceIdentifier: string, key: string) => void;
}

export function invoiceDescription(invoiceType: InvoiceType): string {
    switch (invoiceType) {
        case InvoiceType.ShipmentCharge:
            return 'Shipment Charge';
        case InvoiceType.AdditionalCharge:
            return 'Additional Charge';
        case InvoiceType.ShipmentRefund:
            return 'Refund';
        case InvoiceType.AdditionalChargeRefund:
            return 'Refund';
    }
}

function pricePrefix(invoiceType: InvoiceType): string {
    switch (invoiceType) {
        case InvoiceType.ShipmentCharge:
            return '';
        case InvoiceType.AdditionalCharge:
            return '';
        case InvoiceType.ShipmentRefund:
            return '-';
        case InvoiceType.AdditionalChargeRefund:
            return '-';
    }
}

function describePaymentMethod(paymentMethod: PaymentMethod | undefined) {
    if (paymentMethod === undefined) {
        return '';
    }

    switch (paymentMethod) {
        case PaymentMethod.BankAccount:
            return 'Direct Debit';
        case PaymentMethod.Cash:
            return 'Cash';
        case PaymentMethod.Cheque:
            return 'Cheque';
        case PaymentMethod.CreditCard:
            return 'Credit Card';
        case PaymentMethod.Eft:
            return 'EFT';
        case PaymentMethod.InteracETransfer:
            return 'Interac eTransfer';
        case PaymentMethod.Wire:
            return 'Wire';
    }
}

interface PaymentDetailsProps {
    invoice: Invoice;
    isOverdue: boolean;
}

function PaymentDetails(props: PaymentDetailsProps) {
    const invoice = props.invoice;

    if (invoice.invoiceState == InvoiceState.Issued) {
        return (
            <HorizontalStack>
                <UnpaidType
                    isOverdueOrFailed={
                        props.isOverdue || invoice.lastPaymentAttemptFailed
                    }
                >
                    {invoice.lastPaymentAttemptFailed
                        ? 'Failed'
                        : props.isOverdue
                        ? 'Overdue'
                        : 'Unpaid'}
                </UnpaidType>
            </HorizontalStack>
        );
    }

    if (invoice.invoiceState == InvoiceState.Voided) {
        return (
            <HorizontalStack>
                <VoidedType>Voided</VoidedType>
            </HorizontalStack>
        );
    }

    if (
        invoice.paymentMethod == PaymentMethod.CreditCard &&
        invoice.brand !== undefined
    ) {
        return (
            <Stack>
                <HorizontalStack>
                    <CreditCardLogo size="small" brand={invoice.brand} />
                    <Spacer width={4} />
                    <CardDesciption>
                        Card Ending ... {invoice.lastFourDigits}
                    </CardDesciption>
                </HorizontalStack>
                {invoice.invoiceState === InvoiceState.SettlementPending && (
                    <SettlementStatus>
                        Waiting for Confirmation
                    </SettlementStatus>
                )}
            </Stack>
        );
    } else if (
        invoice.paymentMethod == PaymentMethod.BankAccount &&
        invoice.brand !== undefined
    ) {
        return (
            <HorizontalStack>
                <Stack>
                    <CardDesciption>{invoice.brand}</CardDesciption>
                    {invoice.invoiceState ===
                        InvoiceState.SettlementPending && (
                        <SettlementStatus>
                            Waiting for Confirmation
                        </SettlementStatus>
                    )}
                </Stack>
            </HorizontalStack>
        );
    } else {
        return (
            <HorizontalStack>
                <Stack>
                    <CardDesciption>
                        {describePaymentMethod(invoice.paymentMethod)}
                    </CardDesciption>
                    {invoice.invoiceState ===
                        InvoiceState.SettlementPending && (
                        <SettlementStatus>
                            Waiting for Confirmation
                        </SettlementStatus>
                    )}
                </Stack>
            </HorizontalStack>
        );
    }

    return <CardDesciption>Unknown</CardDesciption>;
}

function BillingSection(props: BillingSectionProps) {
    const { invoices } = props;

    function isOverdue(dueDate: string) {
        const today = moment().startOf('day');
        const startOfDueDate = moment(dueDate).clone().startOf('day');

        return startOfDueDate.isBefore(today);
    }

    function anyFailedPayments(): boolean {
        return invoices.some((invoice) => invoice.lastPaymentAttemptFailed);
    }

    return (
        <ViewShipmentSection
            title="Billing"
            subtitle={
                <Stack align="left">
                    <div>
                        Here are all the invoices attached to this shipment. To
                        find other invoices, or summaries please go to the{' '}
                        <Link to="/view-invoices">billing section</Link>
                    </div>
                    {anyFailedPayments() && (
                        <>
                            <Spacer height={16} />
                            <div>
                                There are failed payment attempts below. Please
                                update your{' '}
                                <Link to="/credit-cards">payment methods</Link>{' '}
                                and <Link to="/payments">retry the payment</Link>,
                                or contact us to resolve.
                            </div>
                        </>
                    )}
                </Stack>
            }
            id="billing-section"
        >
            <ViewShipmentSectionTable>
                <table>
                    <tbody>
                        {invoices.map((invoice, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <ChargeDescription invoice={invoice}>
                                            {invoiceDescription(
                                                invoice.invoiceType
                                            )}
                                        </ChargeDescription>
                                    </td>
                                    <td>
                                        {invoice.invoiceState ===
                                            InvoiceState.Issued && (
                                            <ChargeDate invoice={invoice}>
                                                <HorizontalStack>
                                                    <span>Due </span>
                                                    <Spacer width={4} />
                                                    <span>
                                                        {moment(
                                                            invoice.dueDate
                                                        ).format(
                                                            'Do MMMM YYYY'
                                                        )}
                                                    </span>
                                                    {isOverdue(
                                                        invoice.dueDate
                                                    ) && (
                                                        <>
                                                            <Spacer width={4} />
                                                            <RedExclamationAnimatedIcon />
                                                        </>
                                                    )}
                                                </HorizontalStack>
                                            </ChargeDate>
                                        )}
                                        {invoice.invoiceState !==
                                            InvoiceState.Issued && (
                                            <ChargeDate invoice={invoice}>
                                                {moment(
                                                    invoice.invoiceDate
                                                ).format('Do MMMM YYYY')}
                                            </ChargeDate>
                                        )}
                                    </td>
                                    <td>
                                        <PaymentDetails
                                            invoice={invoice}
                                            isOverdue={isOverdue(
                                                invoice.dueDate
                                            )}
                                        />
                                    </td>
                                    <td>
                                        <HorizontalStack>
                                            <PriceText invoice={invoice}>
                                                $
                                                {pricePrefix(
                                                    invoice.invoiceType
                                                )}
                                                {invoice.amount.toFixed(2)}
                                            </PriceText>
                                            <Spacer width={4} />
                                            <CurrencyText invoice={invoice}>
                                                {invoice.currency ===
                                                Currency.Cad
                                                    ? 'CAD'
                                                    : 'USD'}
                                            </CurrencyText>
                                        </HorizontalStack>
                                    </td>
                                    <td>
                                        <LinkButton
                                            regularWeight={true}
                                            onClick={function () {
                                                props.onDownloadInvoice(
                                                    invoice.invoiceIdentifier,
                                                    invoice.invoicePdfKey
                                                );
                                            }}
                                            style={{
                                                fontSize: '16px',
                                            }}
                                        >
                                            Download Invoice
                                        </LinkButton>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </ViewShipmentSectionTable>
        </ViewShipmentSection>
    );
}
export default BillingSection;
