import { useSiteMargin } from 'Cargo/Layout/DynamicSpacer';
import { Page, PageContainer } from 'Cargo/Layout/Page';
import ScrollToTop from 'Cargo/Layout/ScrollToTop';
import { useModalsProvider } from 'Cargo/Modal/useModalsProvider';
import AcceptInvitationScreen from 'Features/Authentication/AcceptInvitationScreen';
import { CreateAccountScreen } from 'Features/Authentication/CreateAccountScreen';
import { QuickLoginScreen } from 'Features/Authentication/QuickLoginScreen';
import RequestResetPasswordScreen from 'Features/Authentication/RequestResetPasswordScreen';
import ResetPasswordScreen from 'Features/Authentication/ResetPasswordScreen';
import SignInScreen from 'Features/Authentication/SignInScreen';
import { useAuthentication } from 'Features/Authentication/Slices/authenticationSlice';
import BookShipmentRouter from 'Features/BookShipment/BookShipmentRouter';
import ExternalAddCreditCardScreen from 'Features/CreditCards/ExternalAddCreditCardScreen';
import DashboardRouter from 'Features/Dashboard/DashboardRouter';
import { FooterContainer } from 'Features/Footer/Components/Footer';
import NavBar from 'Features/NavBar/Components/NavBar';
import { ReferralScreen } from 'Features/Referral/ReferralScreen';
import ResetScreen from 'Features/Reset/ResetScreen';
import { LookUpTrackingScreen } from 'Features/Tracking/LookUpTrackingScreen';
import TrackShipmentScreen from 'Features/Tracking/TrackShipmentScreen';
import UploadPhotosScreen from 'Features/UploadPhotos/UploadPhotosScreen';
import { useVerifyToken } from 'Hooks/useVerifyToken';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import NotAuthedRoute from 'Routing/NotAuthedRoute';
import styled from 'styled-components/macro';

// eslint-disable-next-line local-rules/no-import-styled
const AppContainer = styled.div`
    border-radius: 5px;
    padding: 0;

    animation: fadein 0.3s;
    min-height: 80vh;
`;

interface ContentContainerProps {
    padding: number;
}

const ContentContainer = styled.div<ContentContainerProps>`
    padding-left: ${(props) => props.padding}px;
    padding-right: ${(props) => props.padding}px;
`;

const NavBarredAppRouter: React.FC = () => {
    console.log(`NavBarredAppRouter`);
    const { isAuthenticated } = useAuthentication();
    const paddingWidth = useSiteMargin();
    const navigate = useNavigate();

    const location = useLocation();

    useEffect(
        function () {
            // Basically if the url is exactly dashboard.freightsimple.com/
            if (location.pathname == '/') {
                if (!isAuthenticated) {
                    navigate('/book/details', { replace: true });
                }
            }
        },
        [location, isAuthenticated]
    );

    function showNavBar() {
        if (location.pathname == '/sign-in') {
            return false;
        }

        if (location.pathname == '/create-account') {
            return false;
        }

        if (location.pathname == '/upload-photos') {
            return false;
        }

        return true;
    }

    function getPaddingWidth() {
        if (location.pathname == '/sign-in') {
            return 0;
        }

        if (location.pathname == '/create-account') {
            return 0;
        }

        return paddingWidth;
    }

    return (
        <>
            {showNavBar() && <NavBar />}
            <ContentContainer padding={getPaddingWidth()}>
                <PageContainer>
                    {isAuthenticated && <DashboardRouter />}

                    <Page path="/book/">{() => <BookShipmentRouter />}</Page>

                    <NotAuthedRoute exact path="/sign-in">
                        <SignInScreen />
                    </NotAuthedRoute>

                    <NotAuthedRoute exact path="/referral">
                        <ReferralScreen />
                    </NotAuthedRoute>

                    <NotAuthedRoute exact path="/create-account">
                        <CreateAccountScreen />
                    </NotAuthedRoute>

                    <NotAuthedRoute exact path="/reset-password">
                        <ResetPasswordScreen />
                    </NotAuthedRoute>

                    <NotAuthedRoute exact path="/request-reset-password">
                        <RequestResetPasswordScreen />
                    </NotAuthedRoute>

                    <NotAuthedRoute exact path="/qli">
                        <QuickLoginScreen />
                    </NotAuthedRoute>

                    <NotAuthedRoute exact path="/accept-invitation">
                        <AcceptInvitationScreen />
                    </NotAuthedRoute>

                    <NotAuthedRoute exact path="/track">
                        <LookUpTrackingScreen />
                    </NotAuthedRoute>

                    {/* See not below */}
                    <Page exact path="/track-shipment">
                        {() => {
                            return <TrackShipmentScreen />;
                        }}
                    </Page>

                    {/* CS Sept 2023. I suspect some email relays/clients are rewriting urls that mention tracking and obfuscate any mention of a trackingId. So we're adding a new endpoint that doesn't mention track/trackingId to work around this */}
                    <Page exact path="/t">
                        {() => {
                            return <TrackShipmentScreen />;
                        }}
                    </Page>

                    <Page exact path="/reset">
                        {() => {
                            return <ResetScreen />;
                        }}
                    </Page>
                </PageContainer>
            </ContentContainer>
            {/* We don't want the container CSS to apply */}
            <Page exact path="/upload-photos">
                {() => {
                    return <UploadPhotosScreen />;
                }}
            </Page>
        </>
    );
};

const AppRouter: React.FC = () => {
    useVerifyToken();

    const ModalsProvider = useModalsProvider();

    // In production / demo use the real intercom (as they're both customer accessible)
    const INTERCOM_APP_ID =
        process.env.REACT_APP_FS_ENVIRONMENT === 'production' ||
        process.env.REACT_APP_FS_ENVIRONMENT === 'demo'
            ? 'oj0p8brq'
            : 'w7bho49n';

    return (
        <IntercomProvider appId={INTERCOM_APP_ID}>
            <ModalsProvider>
                <AppContainer>
                    <FooterContainer>
                        <ScrollToTop />
                        <Routes>
                            <Route
                                path="/add-credit-card"
                                element={<ExternalAddCreditCardScreen />}
                            />
                            <Route path="*" element={<NavBarredAppRouter />} />
                        </Routes>
                    </FooterContainer>
                </AppContainer>
            </ModalsProvider>
        </IntercomProvider>
    );
};

export default AppRouter;
