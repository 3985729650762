import Colors from 'Cargo/Colors';
import Input from 'Cargo/Controls/Input';
import Link from 'Cargo/Controls/Link';
import {
    QuestionBubbleHeader,
    QuestionBubbleLabel,
    SmallQuestionBubble,
} from 'Cargo/Controls/QuestionBubble';
import Icon from 'Cargo/Icons/Icon';
import ProgressSpinner, {
    ProgressSpinnerSizes,
} from 'Cargo/Icons/ProgressSpinner';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Stack from 'Cargo/Layout/Stack';
import { ErrorMessageType } from 'Cargo/Validation';
import { useSelectNmfcSubItemModal } from 'Features/LineItems/Modals/SelectNmfcSubItemModal';
import { NmfcSubItemChoice } from 'generated-openapi-client';
import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { NmfcQuestionBubble } from './QuestionBubbles/NmfcQuestionBubble';

export function NMFCFreightClassQuestionBubble() {
    return (
        <SmallQuestionBubble
            description={
                <>
                    <QuestionBubbleHeader>
                        FreightSimple automatically calculates Freight Class
                    </QuestionBubbleHeader>
                    <QuestionBubbleLabel>
                        <div>
                            There is no need to enter the Freight class (eg. 50,
                            110, etc) when quoting for freight with
                            FreightSimple.
                        </div>
                    </QuestionBubbleLabel>
                    <QuestionBubbleLabel>
                        <div>
                            Certain commodities have 5-6 digit NMFC codes which
                            can often help reduce shipping rates. If you would
                            link help finding out the codes for your shipments
                            please contact us and we can help
                        </div>
                    </QuestionBubbleLabel>
                </>
            }
            learnMoreUrl="https://help.freightsimple.com/freight-101/nmfc-codes-and-freight-class"
        />
    );
}

function warningForMistakenlyEnteredFreightClass(
    nmfcCode: string
): ReactNode | undefined {
    const freightClassOptions = [
        '50',
        '55',
        '60',
        '65',
        '70',
        '77.5',
        '85',
        '92.5',
        '100',
        '110',
        '125',
        '150',
        '175',
        '200',
        '250',
        '300',
        '400',
        '500',
    ];

    if (freightClassOptions.includes(nmfcCode)) {
        return (
            <HorizontalStack>
                <span style={{ color: Colors.LightText }}>
                    If you have a 5-6 digit NMFC code, enter it, else leave
                    blank <NMFCFreightClassQuestionBubble />
                </span>
            </HorizontalStack>
        );
    } else {
        return undefined;
    }
}

interface NmfcInputInsetProps {
    loading: boolean;
}

function NmfcInputInset(props: NmfcInputInsetProps) {
    if (props.loading) {
        return (
            <div style={{ marginBottom: '4px' }}>
                <ProgressSpinner size={ProgressSpinnerSizes.Small} />
            </div>
        );
    }

    return <></>;
}

interface SubtextProps {
    description: string | undefined;
}

const SubtextBody = styled.div`
    font-size: 12px;
    margin-top: 2px;
    margin-bottom: 4px;
    color: var(--freightsimple-color-light-text);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 337px;
`;

function nmfcLabelContent(freightClass: undefined | string) {
    if (freightClass === undefined) {
        return (
            <>
                NMFC Code <NmfcQuestionBubble />
            </>
        );
    } else {
        return (
            <>
                NMFC Code (Freight Class {freightClass}) <NmfcQuestionBubble />
            </>
        );
    }
}

function Subtext(props: SubtextProps) {
    if (props.description === undefined) {
        return <></>;
    }

    return (
        <HorizontalStack>
            <Icon
                name="check"
                color={Colors.DarkGreen}
                size={12}
                solid
                style={{ marginRight: '4px' }}
            />
            <SubtextBody>{props.description}</SubtextBody>
        </HorizontalStack>
    );
}

const NmfcLabel = styled.div`
    height: 12px;
    padding: 0;
    font-size: 12px;
    line-height: 1.5;
    color: ${Colors.LightText};
    margin-top: 6px;
`;

interface ConfirmedProps {
    value: string;
    onClear: () => void;
    description: string | undefined;
    freightClass: string | undefined;
}

function Confirmed(props: ConfirmedProps) {
    const [hover, setHover] = useState(false);
    const [hoverCrossButton, setHoverCrossButton] = useState(false);
    return (
        <div
            onMouseEnter={function () {
                setHover(true);
            }}
            onMouseLeave={function () {
                setHover(false);
            }}
        >
            <Stack align="left">
                <NmfcLabel>{nmfcLabelContent(props.freightClass)}</NmfcLabel>
                <HorizontalStack
                    align="spread"
                    style={{
                        height: '37px',
                        paddingTop: '2px',
                        paddingBottom: '0',
                        width: '349px',
                        color: '#495057',
                        fontWeight: `bold`,
                        fontSize: '20px',
                        borderBottom: '1px solid #858585',
                        marginTop: '2px',
                    }}
                >
                    <div>{props.value}</div>
                    {hover && (
                        <div
                            onMouseEnter={function () {
                                setHoverCrossButton(true);
                            }}
                            onMouseLeave={function () {
                                setHoverCrossButton(false);
                            }}
                            style={{ cursor: 'pointer' }}
                            onClick={props.onClear}
                        >
                            <Icon
                                name="times-circle"
                                color={
                                    hoverCrossButton
                                        ? Colors.MidGray
                                        : Colors.LightGray
                                }
                                size={20}
                                solid
                                style={{
                                    marginRight: '4px',
                                    position: 'relative',
                                }}
                            />
                        </div>
                    )}
                </HorizontalStack>
                <Subtext description={props.description} />
            </Stack>
        </div>
    );
}

interface NmfcInputErrorProps {
    message: ReactNode;
    subItems: Array<NmfcSubItemChoice> | undefined;
    onSelectSubItem: (_: string) => void;
}

function NmfcInputError(props: NmfcInputErrorProps) {
    const show = useSelectNmfcSubItemModal();

    async function onSelectSubItem() {
        if (props.subItems === undefined) {
            throw new Error('Should not be here');
        }

        const nmfcCode = await show(props.subItems);
        if (nmfcCode !== undefined) {
            console.log(`onSelectSubItem: ${nmfcCode}`);
            props.onSelectSubItem(nmfcCode);
        }
    }

    if (props.subItems) {
        return (
            <HorizontalStack verticalAlign="middle">
                <div style={{ color: Colors.LightText }}>
                    {props.message}.{' '}
                    <Link onClick={onSelectSubItem}>Select subitem</Link>
                </div>
            </HorizontalStack>
        );
    } else {
        return <>{props.message}</>;
    }
}

const PARTIAL_NMFC_REGEX = new RegExp(/^\d{0,6}(-\d?)?$/i);
const COMPLETE_NMFC_REGEX = new RegExp(/^\d{4,6}(-\d)?$/i);

interface NmfcInputProps {
    nmfcCode: string | undefined;
    onChange: (newValue: string | undefined) => void;
    loading: boolean;
    description: string | undefined;
    confirmed: boolean;
    errorMessage: ErrorMessageType;
    clearError: () => void;
    subItems: Array<NmfcSubItemChoice> | undefined;
    freightClass?: string;
}
function NmfcInput(props: NmfcInputProps) {
    const [nmfcCode, setNmfcCode] = useState(props.nmfcCode || '');
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

    function enqueueChange(value: string) {
        const newId = setTimeout(function () {
            props.onChange(value);
        }, 2000);

        setTimeoutId(newId);
    }

    function clearQueuedChange() {
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(undefined);
        }
    }

    useEffect(
        function () {
            setNmfcCode(props.nmfcCode || '');
        },
        [props.nmfcCode]
    );

    function onChange(value: string | undefined) {
        // Lock it while loading
        if (props.loading) {
            return;
        }

        if (value === undefined) {
            setNmfcCode('');
            props.onChange(undefined);
            return;
        }

        props.clearError();

        if (PARTIAL_NMFC_REGEX.test(value)) {
            console.log(`matches partial - setNmfcCode : ${value}`);
            setNmfcCode(value);
        }

        if (COMPLETE_NMFC_REGEX.test(value)) {
            clearQueuedChange();
            enqueueChange(value.replace(/^0+/, ''));
        } else {
            props.onChange(undefined);
            clearQueuedChange();
        }
    }

    if (props.confirmed) {
        if (nmfcCode === undefined) {
            throw new Error('nmfcCode is undefined');
        }

        return (
            <HorizontalStack verticalAlign="top">
                <Confirmed
                    value={nmfcCode}
                    onClear={function () {
                        onChange(undefined);
                    }}
                    description={props.description}
                    freightClass={props.freightClass}
                />
            </HorizontalStack>
        );
    } else {
        const mistakenFreightClassWarning =
            warningForMistakenlyEnteredFreightClass(nmfcCode);
        return (
            <HorizontalStack verticalAlign="top">
                <Input
                    id="nmfcInput"
                    type="text"
                    value={nmfcCode}
                    width={349}
                    label={<>{nmfcLabelContent(props.freightClass)}</>}
                    placeholder="If known, enter the NMFC Code"
                    onChange={onChange}
                    inset={<NmfcInputInset loading={props.loading} />}
                    validateOnTimeoutMs={10000}
                    errorMessage={
                        props.errorMessage === undefined ? (
                            mistakenFreightClassWarning
                        ) : (
                            <NmfcInputError
                                message={props.errorMessage}
                                subItems={props.subItems}
                                onSelectSubItem={props.onChange}
                            />
                        )
                    }
                    warn={
                        props.subItems !== undefined ||
                        mistakenFreightClassWarning !== undefined
                    }
                    forceValidation={props.errorMessage !== undefined}
                />
            </HorizontalStack>
        );
    }
}
export default NmfcInput;
