import Link from 'Cargo/Controls/Link';
import ProgressSpinner, {
    ProgressSpinnerSizes,
} from 'Cargo/Icons/ProgressSpinner';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import { ErrorMessage, WarningMessage } from 'Cargo/Text/Text';
import { UUID } from 'Cargo/Types/types';
import SavedLocationsDropdown from 'Features/BookShipment/Components/SavedLocationsDropdown';
import { AddLocationLocationType } from 'Features/Locations/Types/locationTypes';
import { LocationErrors } from 'Features/Locations/Validators/errorMessagesForLocation';
import { capitalize } from 'Helpers/capitalize';
import {
    Location,
    LocationContext,
    SavedLocation,
} from 'generated-openapi-client';
import { useState } from 'react';
import BookShipmentSection from '../BookShipmentSection';
import LocationBox2 from './LocationBox2';
import NewLocationDetails from './NewLocationDetails';

function extractErrorMessages(errorMessages: LocationErrors) {
    if (errorMessages.locationType) {
        return errorMessages.locationType;
    }

    if (errorMessages.address.postalCode) {
        return errorMessages.address.postalCode;
    }
}

interface LocationSection2Props {
    location: AddLocationLocationType;
    selectedSavedLocationId: UUID | undefined;
    enteringNew: boolean;
    savedLocations: Array<SavedLocation>;
    defaultLocationId: undefined | UUID;
    onSelectSavedLocation: (
        savedLocationId: UUID,
        savedLocation: Location
    ) => void;
    onUpdateLocation: (location: AddLocationLocationType) => void;
    onNewLocation: () => void;
    errorMessages: LocationErrors;
    warningMessages: LocationErrors;
    forceValidation: boolean;
    index: number;
    id: string;
    context: LocationContext;
    onSelectFromAddressBook: () => void;
    disablePostalCodeEntry: boolean;
    pickupDate: string;
}

interface LocationSectionDescriptionProps {
    enteringNew: boolean;
    onSelectFromAddressBook: () => void;
    context: LocationContext;
    savedLocations: Array<SavedLocation>;
}

function LocationSectionDescription(props: LocationSectionDescriptionProps) {
    if (props.enteringNew) {
        if (props.savedLocations.length > 0) {
            return (
                <>
                    Enter a new {props.context} location{' '}
                    <Link onClick={props.onSelectFromAddressBook}>
                        or select one from your address book
                    </Link>
                </>
            );
        } else {
            return <>Enter the details of your {props.context} location</>;
        }
    } else {
        return <>Select a location from your address book.</>;
    }
}

function LocationSection2(props: LocationSection2Props) {
    const [transitioning, setTransitioning] = useState(false);
    const {
        enteringNew,
        selectedSavedLocationId,
        location,
        savedLocations,
        context,
        onSelectFromAddressBook,
    } = props;

    const extractedErrorMessages = extractErrorMessages(props.errorMessages);
    const extractedWarningMessages = extractErrorMessages(
        props.warningMessages
    );

    return (
        <>
            <BookShipmentSection
                index={props.index}
                header={`${capitalize(props.context)} Location`}
                id={props.id}
                description={
                    <LocationSectionDescription
                        enteringNew={enteringNew}
                        onSelectFromAddressBook={function () {
                            setTransitioning(true);
                            setTimeout(function () {
                                onSelectFromAddressBook();
                                setTransitioning(false);
                            }, 250);
                        }}
                        savedLocations={savedLocations}
                        context={context}
                    />
                }
                minHeight={
                    props.context === LocationContext.Pickup ? '414px' : '495px'
                }
            >
                {transitioning && (
                    <div>
                        <HorizontalStack
                            width="803px"
                            style={{ height: '315px' }}
                            verticalAlign="middle"
                            align="center"
                        >
                            <ProgressSpinner
                                size={ProgressSpinnerSizes.Small}
                            />
                        </HorizontalStack>
                        {props.context === LocationContext.Delivery && (
                            <Spacer height={93} />
                        )}
                    </div>
                )}
                {!transitioning && !enteringNew && selectedSavedLocationId && (
                    <div>
                        <SavedLocationsDropdown
                            savedLocations={savedLocations}
                            savedLocationId={selectedSavedLocationId}
                            defaultLocationId={props.defaultLocationId}
                            context={props.context}
                            onSetSavedLocationId={function (
                                newSavedLocationId
                            ) {
                                const newSavedLocation = savedLocations.find(
                                    (sl) =>
                                        sl.savedLocationId ===
                                        newSavedLocationId
                                );
                                if (newSavedLocation === undefined) {
                                    throw new Error(
                                        'No newSavedLocation. Should never get here'
                                    );
                                }
                                props.onSelectSavedLocation(
                                    newSavedLocationId,
                                    newSavedLocation.location
                                );
                            }}
                            onAddLocation={function () {
                                setTransitioning(true);
                                setTimeout(function () {
                                    props.onNewLocation();
                                    setTransitioning(false);
                                }, 250);
                            }}
                        />
                        <Spacer height={16} />
                        <LocationBox2
                            location={location}
                            context={props.context}
                        />
                        {props.forceValidation && extractedErrorMessages && (
                            <>
                                <Spacer height={8} />
                                <ErrorMessage>
                                    {extractedErrorMessages}
                                </ErrorMessage>
                            </>
                        )}
                        {props.forceValidation &&
                            !extractedErrorMessages &&
                            extractedWarningMessages && (
                                <>
                                    <Spacer height={8} />
                                    <WarningMessage>
                                        {extractedWarningMessages}
                                    </WarningMessage>
                                </>
                            )}
                        {props.context === LocationContext.Delivery && (
                            <Spacer height={93} />
                        )}
                    </div>
                )}
                {!transitioning && enteringNew && (
                    <div>
                        <NewLocationDetails
                            locationErrorMessages={props.errorMessages}
                            locationWarningMessages={props.warningMessages}
                            context={props.context}
                            setLocation={props.onUpdateLocation}
                            location={location}
                            forceValidation={props.forceValidation}
                            disablePostalCodeEntry={
                                props.disablePostalCodeEntry
                            }
                            pickupDate={props.pickupDate}
                        />
                    </div>
                )}
            </BookShipmentSection>
        </>
    );
}
export default LocationSection2;
