/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetCouponResponse
 */
export interface GetCouponResponse {
    /**
     * 
     * @type {number}
     * @memberof GetCouponResponse
     */
    amount?: number;
}

/**
 * Check if a given object implements the GetCouponResponse interface.
 */
export function instanceOfGetCouponResponse(value: object): boolean {
    return true;
}

export function GetCouponResponseFromJSON(json: any): GetCouponResponse {
    return GetCouponResponseFromJSONTyped(json, false);
}

export function GetCouponResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCouponResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'amount': json['amount'] == null ? undefined : json['amount'],
    };
}

export function GetCouponResponseToJSON(value?: GetCouponResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'amount': value['amount'],
    };
}

