import { useState } from 'react';
import { useDropzone } from 'react-dropzone';

export function useCustomsDocsDropzone() {
    const maxFiles = 10;
    const [files, setFiles] = useState<Array<File>>([]);

    const onDrop = function (acceptedFiles: Array<File>) {
        setFiles((prev) => {
            const availableSpace = maxFiles - files.length;

            if (acceptedFiles.length > availableSpace) {
                return [...prev, ...acceptedFiles.slice(0, availableSpace)];
            }

            return [...prev, ...acceptedFiles];
        });
    };

    const dropzoneState = useDropzone({
        onDrop,
        maxFiles,
        accept: 'application/pdf',
    });

    return { dropzoneState, files, setFiles };
}
