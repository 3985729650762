import { ErrorMessageType } from 'Cargo/Validation';

export function errorMessageForFreightClass(
    freightClass: string | undefined,
    isFreightClassRequired: boolean
): ErrorMessageType {
    if (!isFreightClassRequired) {
        // Freight Class is not relevant to domestic Canada
        return undefined;
    }

    if (isFreightClassRequired && freightClass === undefined) {
        return 'Required';
    }
}
