import Button from 'Cargo/Controls/Button';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { useModal } from 'Cargo/Modal/useModal';
import { H2, Microcopy } from 'Cargo/Text/Text';
import React, { ReactElement, ReactNode } from 'react';

interface AlertModalOptions {
    buttons?: {
        primary: string;
    };
}

interface AlertModalProps {
    onDone: () => void;
    message: ReactNode;
    title: ReactNode;
    options?: AlertModalOptions;
}

function AlertModal(props: AlertModalProps): ReactElement {
    return (
        <Stack align="center">
            <H2>{props.title}</H2>
            <Microcopy>{props.message}</Microcopy>
            <Spacer height={24} />
            <HorizontalStack>
                <Button
                    id="doneButton"
                    size="large"
                    onClick={() => {
                        props.onDone();
                    }}
                >
                    {props.options?.buttons?.primary || `Ok!`}
                </Button>
            </HorizontalStack>
        </Stack>
    );
}

function useAlertModal(): (
    title: ReactNode,
    message: ReactNode,
    options?: AlertModalOptions
) => Promise<void> {
    const showModal = useModal<void>();

    function confirm(
        title: ReactNode,
        message: ReactNode,
        options?: AlertModalOptions
    ): Promise<void> {
        const p = new Promise<void>((resolve) => {
            showModal(
                (done) => {
                    return (
                        <AlertModal
                            onDone={() => {
                                done();
                            }}
                            message={message}
                            title={title}
                            options={options}
                        />
                    );
                },
                () => {
                    resolve();
                },
                {
                    preventBackdropClick: true,
                }
            );
        });

        return p;
    }
    return confirm;
}

export default useAlertModal;
