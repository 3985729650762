// TODO we should generate this file
import { TemperatureHandling } from 'generated-openapi-client';

const temperatureHandlingTypes = [
    {
        name: 'No Special Handling',
        type: TemperatureHandling.NoSpecialHandling,
    },
    {
        name: 'Protect From Freezing',
        type: TemperatureHandling.ProtectFromFreezing,
    },
    {
        name: 'Keep Refrigerated',
        type: TemperatureHandling.KeepRefrigerated,
    },
    {
        name: 'Keep Frozen',
        type: TemperatureHandling.KeepFrozen,
    },
];

export function getTemperatureHandlingTypesForDropdown() {
    return temperatureHandlingTypes.map((t) => {
        return {
            item: t.type,
            description: t.name,
        };
    });
}
