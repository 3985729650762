import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { nameAccessorials } from 'Data/AccessorialTypes';
import { formatHandlingUnitTypeWithQuantity } from 'Data/HandlingUnitTypes';
import { describeLocationType } from 'Data/LocationTypes';
import { describeHazardClass } from 'Features/DangerousGoods/Helpers/describeHazardClass';
import { AddLineItemType } from 'Features/LineItems/Types/lineItemTypes';
import { AddLocationLocationType } from 'Features/Locations/Types/locationTypes';
import { formatLocation } from 'Features/Quotes/Components/QuoteRow/Helpers/formatLocation';
import { describeHours } from 'Helpers/describeHours';
import { formatPhone } from 'Helpers/formatPhone';
import {
    totalWeightForLineItem,
    totalWeightForLineItems,
} from 'Helpers/lineItemCalculations';
import {
    Contact,
    LineItem,
    Location,
    LocationContext,
    PreQuoteLocation,
    ShipmentState,
    TemperatureHandling,
} from 'generated-openapi-client';
import {
    Column,
    ColumnHeader,
    ColumnSectionHeader,
    LineItemText1,
    LineItemText2,
    LocationTypeDescription,
    NotesSection,
    PrimaryText,
    SecondaryText,
} from './QuoteRowSharedStyles';

interface PickupColumnProps {
    pickupDate: moment.Moment;
    pickupLocation: Location | AddLocationLocationType | PreQuoteLocation;
    pickupContact: Contact | undefined;
    lineItems: Array<LineItem | AddLineItemType>;
    shipmentState: ShipmentState;
    pickupReferenceNumber?: string;
    pickupBoothNumber?: string;
    notes?: string;
}

function WhenSection(props: PickupColumnProps) {
    const { pickupLocation, pickupDate } = props;

    const formattedDate = pickupDate.format('dddd Do MMMM YYYY');
    const formattedHours = describeHours(pickupLocation.hours);
    const windowDescription = `Pickup window: ${formattedHours}`;

    return (
        <>
            <ColumnSectionHeader>When</ColumnSectionHeader>
            <PrimaryText>{formattedDate}</PrimaryText>
            <SecondaryText>{windowDescription}</SecondaryText>
        </>
    );
}

function WhereSection(props: PickupColumnProps) {
    const { pickupLocation } = props;

    const locationTypeDescription = describeLocationType(
        pickupLocation.locationType
    );
    const formattedLocation = formatLocation(pickupLocation);

    if (pickupLocation.businessName === undefined) {
        return (
            <>
                <ColumnSectionHeader>Where</ColumnSectionHeader>
                <PrimaryText>{formattedLocation}</PrimaryText>
                <SecondaryText>{locationTypeDescription}</SecondaryText>
            </>
        );
    } else {
        return (
            <>
                <ColumnSectionHeader>Where</ColumnSectionHeader>
                <PrimaryText>{pickupLocation.businessName}</PrimaryText>
                <SecondaryText>
                    Location Type:{' '}
                    <LocationTypeDescription>
                        {locationTypeDescription}
                    </LocationTypeDescription>
                </SecondaryText>
                <SecondaryText>
                    {pickupLocation.address.addressLine}
                </SecondaryText>
                <SecondaryText>
                    {pickupLocation.address.addressLine2}
                </SecondaryText>
                <SecondaryText>{formattedLocation}</SecondaryText>
            </>
        );
    }
}

function ContactSection(props: PickupColumnProps) {
    const { pickupContact } = props;

    if (pickupContact?.contactName === undefined) {
        return <></>;
    } else {
        return (
            <>
                <ColumnSectionHeader>Contact</ColumnSectionHeader>
                <PrimaryText>{pickupContact?.contactName}</PrimaryText>
                <SecondaryText>{pickupContact?.emailAddress}</SecondaryText>
                <SecondaryText>
                    {formatPhone(
                        pickupContact?.phoneNumber as string,
                        pickupContact?.phoneNumberExtension
                    )}
                </SecondaryText>
            </>
        );
    }
}

function SpecialServicesSection(props: PickupColumnProps) {
    const { pickupLocation } = props;

    const lines = nameAccessorials(
        pickupLocation.accessorials,
        LocationContext.Pickup,
        pickupLocation.locationType
    );

    return (
        <>
            <ColumnSectionHeader>Special Services</ColumnSectionHeader>
            <ul style={{ paddingInlineStart: '20px', marginBottom: '0' }}>
                {lines.map((line, index) => {
                    return (
                        <li key={index}>
                            <SecondaryText>{line}</SecondaryText>
                        </li>
                    );
                })}
            </ul>
        </>
    );
}

function PayloadSection(props: PickupColumnProps) {
    const { lineItems } = props;

    const formattedLineItems = lineItems.map((li) => {
        const secondary = `${formatHandlingUnitTypeWithQuantity(
            li.numberHandlingUnits,
            li.handlingUnitType
        )} ${li.description}`;

        let tertiary = `${li.length}" x ${li.width}" x ${li.height}"`;

        if (lineItems.length > 1) {
            tertiary += ` - ${totalWeightForLineItem(li)}lb`;
        }

        if (li.isStackable) {
            tertiary += ` - Stackable`;
        } else {
            tertiary += ` - Not Stackable`;
        }

        if (
            li.temperatureHandling === TemperatureHandling.ProtectFromFreezing
        ) {
            tertiary += ` - Protect From Freezing`;
        }

        if (li.temperatureHandling === TemperatureHandling.KeepRefrigerated) {
            tertiary += ` - Keep Refrigerated`;
        }

        if (li.temperatureHandling === TemperatureHandling.KeepFrozen) {
            tertiary += ` - Keep Frozen`;
        }

        let dangerous = undefined;

        if (li.isDangerous && li.dangerousClassification) {
            dangerous = `Dangerous: ${
                li.dangerousUnNumber
            }, ${describeHazardClass(li.dangerousClassification)}`;
        }

        let nmfcItemNumber = undefined;

        if (li.nmfcItemNumber) {
            nmfcItemNumber = `NMFC: ${li.nmfcItemNumber}`;
        }

        return {
            secondary,
            tertiary,
            nmfcItemNumber,
            dangerous,
        };
    });

    return (
        <>
            <ColumnSectionHeader>Payload</ColumnSectionHeader>
            <PrimaryText>
                Total Weight: {totalWeightForLineItems(props.lineItems)}lb
            </PrimaryText>
            {formattedLineItems.map((li, index) => {
                return (
                    <Stack
                        align="left"
                        key={index}
                        style={{ marginBottom: '4px' }}
                    >
                        <LineItemText1>{li.secondary}</LineItemText1>
                        <LineItemText2>{li.tertiary}</LineItemText2>
                        {li.nmfcItemNumber && (
                            <LineItemText2>{li.nmfcItemNumber}</LineItemText2>
                        )}
                        {li.dangerous && (
                            <LineItemText2>{li.dangerous}</LineItemText2>
                        )}
                    </Stack>
                );
            })}
            <Spacer height={4} />
        </>
    );
}

function PickupColumnForPickup(props: PickupColumnProps) {
    return (
        <Column>
            <Stack align="left">
                <ColumnHeader>Pickup</ColumnHeader>
                <WhenSection {...props} />
                <WhereSection {...props} />
                <ContactSection {...props} />
                <PayloadSection {...props} />
                <SpecialServicesSection {...props} />
                <NotesSection
                    reference={props.pickupReferenceNumber}
                    boothNumber={props.pickupBoothNumber}
                    locationType={props.pickupLocation.locationType}
                    notes={props.pickupLocation.notes}
                    onModify={undefined}
                />
            </Stack>
        </Column>
    );
}
export default PickupColumnForPickup;
