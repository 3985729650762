import React, { ReactNode } from 'react';
import { animated, config, useSpring } from '@react-spring/web';

interface SlideDownProps {
    children: ReactNode;
    upright: boolean;
}

function Flip(props: SlideDownProps) {
    const factor = props.upright ? 1 : -1;
    const slideInStyles = useSpring({
        config: { ...config.gentle },
        from: { transform: `scaleY(${factor * -1})` },
        to: {
            transform: `scaleY(${factor})`,
        },
    });
    return (
        <animated.div style={{ ...slideInStyles, overflow: 'hidden' }}>
            <div>{props.children}</div>
        </animated.div>
    );
}
export default Flip;
