/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';
import type { InvoiceState } from './InvoiceState';
import {
    InvoiceStateFromJSON,
    InvoiceStateFromJSONTyped,
    InvoiceStateToJSON,
} from './InvoiceState';
import type { InvoiceType } from './InvoiceType';
import {
    InvoiceTypeFromJSON,
    InvoiceTypeFromJSONTyped,
    InvoiceTypeToJSON,
} from './InvoiceType';
import type { PaymentMethod } from './PaymentMethod';
import {
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
} from './PaymentMethod';

/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * 
     * @type {Currency}
     * @memberof Invoice
     */
    currency: Currency;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    invoiceDate: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    brand?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    lastFourDigits?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    invoicePdfKey: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    dueDate: string;
    /**
     * 
     * @type {InvoiceState}
     * @memberof Invoice
     */
    invoiceState: InvoiceState;
    /**
     * 
     * @type {InvoiceType}
     * @memberof Invoice
     */
    invoiceType: InvoiceType;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof Invoice
     */
    paymentMethod?: PaymentMethod;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    shipmentId: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    invoiceIdentifier: string;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    lastPaymentAttemptFailed: boolean;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    invoiceId: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    bookedBy: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    branchId?: string;
}

/**
 * Check if a given object implements the Invoice interface.
 */
export function instanceOfInvoice(value: object): boolean {
    if (!('currency' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('invoiceDate' in value)) return false;
    if (!('invoicePdfKey' in value)) return false;
    if (!('dueDate' in value)) return false;
    if (!('invoiceState' in value)) return false;
    if (!('invoiceType' in value)) return false;
    if (!('shipmentId' in value)) return false;
    if (!('invoiceIdentifier' in value)) return false;
    if (!('lastPaymentAttemptFailed' in value)) return false;
    if (!('invoiceId' in value)) return false;
    if (!('bookedBy' in value)) return false;
    return true;
}

export function InvoiceFromJSON(json: any): Invoice {
    return InvoiceFromJSONTyped(json, false);
}

export function InvoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Invoice {
    if (json == null) {
        return json;
    }
    return {
        
        'currency': CurrencyFromJSON(json['currency']),
        'amount': json['amount'],
        'invoiceDate': json['invoiceDate'],
        'brand': json['brand'] == null ? undefined : json['brand'],
        'lastFourDigits': json['lastFourDigits'] == null ? undefined : json['lastFourDigits'],
        'invoicePdfKey': json['invoicePdfKey'],
        'dueDate': json['dueDate'],
        'invoiceState': InvoiceStateFromJSON(json['invoiceState']),
        'invoiceType': InvoiceTypeFromJSON(json['invoiceType']),
        'paymentMethod': json['paymentMethod'] == null ? undefined : PaymentMethodFromJSON(json['paymentMethod']),
        'shipmentId': json['shipmentId'],
        'invoiceIdentifier': json['invoiceIdentifier'],
        'lastPaymentAttemptFailed': json['lastPaymentAttemptFailed'],
        'invoiceId': json['invoiceId'],
        'bookedBy': json['bookedBy'],
        'branchId': json['branchId'] == null ? undefined : json['branchId'],
    };
}

export function InvoiceToJSON(value?: Invoice | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'currency': CurrencyToJSON(value['currency']),
        'amount': value['amount'],
        'invoiceDate': value['invoiceDate'],
        'brand': value['brand'],
        'lastFourDigits': value['lastFourDigits'],
        'invoicePdfKey': value['invoicePdfKey'],
        'dueDate': value['dueDate'],
        'invoiceState': InvoiceStateToJSON(value['invoiceState']),
        'invoiceType': InvoiceTypeToJSON(value['invoiceType']),
        'paymentMethod': PaymentMethodToJSON(value['paymentMethod']),
        'shipmentId': value['shipmentId'],
        'invoiceIdentifier': value['invoiceIdentifier'],
        'lastPaymentAttemptFailed': value['lastPaymentAttemptFailed'],
        'invoiceId': value['invoiceId'],
        'bookedBy': value['bookedBy'],
        'branchId': value['branchId'],
    };
}

