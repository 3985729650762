import { FreightClaimLineItem } from 'generated-openapi-client';
import { sumOf } from './sumOf';

export function calculateTotalFreightClaim(
    claimLineItems: FreightClaimLineItem[]
): number {
    return sumOf(claimLineItems, (o) =>
        o.unitCost === undefined || o.piecesAffected === undefined
            ? 0
            : o.unitCost * o.piecesAffected
    );
}
