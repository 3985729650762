import Badge from 'Cargo/Controls/Badge';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components/macro';

const BookShipmentSectionHeader = styled.div`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 28px;
    color: var(--freightsimple-color-normal-text);
`;
const BookShipmentSectionDescription = styled.div`
    font-weight: var(--nhu-font-weight-light);
    font-size: 14px;
    color: var(--freightsimple-color-normal-text);
    max-width: 618px;
`;

interface BookShipmentSectionProps {
    header: ReactNode;
    description?: ReactNode;
    children?: ReactNode;
    index: number;
    id?: string;
    minHeight?: string;
}
function BookShipmentSection(props: BookShipmentSectionProps) {
    const style: CSSProperties = {};
    if (props.minHeight) {
        style.minHeight = props.minHeight;
    }
    style.marginBottom = '64px';
    return (
        <HorizontalStack verticalAlign="top" style={style} id={props.id}>
            <Stack align="center">
                <div
                    style={{
                        width: '72px',
                        marginTop: '64px',
                        marginBottom: '16px',
                    }}
                >
                    <Badge value={props.index.toString()} />
                </div>
                <BookShipmentSectionHeader>
                    {props.header}
                </BookShipmentSectionHeader>
                {props.description && (
                    <BookShipmentSectionDescription>
                        {props.description}
                    </BookShipmentSectionDescription>
                )}
                <Spacer height={8} />
                {props.children}
            </Stack>
        </HorizontalStack>
    );
}
export default BookShipmentSection;
