/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PreBookingShipment } from './PreBookingShipment';
import {
    PreBookingShipmentFromJSON,
    PreBookingShipmentFromJSONTyped,
    PreBookingShipmentToJSON,
} from './PreBookingShipment';
import type { Shipment } from './Shipment';
import {
    ShipmentFromJSON,
    ShipmentFromJSONTyped,
    ShipmentToJSON,
} from './Shipment';

/**
 * 
 * @export
 * @interface GetShipmentsResponse
 */
export interface GetShipmentsResponse {
    /**
     * 
     * @type {Array<Shipment>}
     * @memberof GetShipmentsResponse
     */
    booked: Array<Shipment>;
    /**
     * 
     * @type {Array<PreBookingShipment>}
     * @memberof GetShipmentsResponse
     */
    quoted: Array<PreBookingShipment>;
}

/**
 * Check if a given object implements the GetShipmentsResponse interface.
 */
export function instanceOfGetShipmentsResponse(value: object): boolean {
    if (!('booked' in value)) return false;
    if (!('quoted' in value)) return false;
    return true;
}

export function GetShipmentsResponseFromJSON(json: any): GetShipmentsResponse {
    return GetShipmentsResponseFromJSONTyped(json, false);
}

export function GetShipmentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetShipmentsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'booked': ((json['booked'] as Array<any>).map(ShipmentFromJSON)),
        'quoted': ((json['quoted'] as Array<any>).map(PreBookingShipmentFromJSON)),
    };
}

export function GetShipmentsResponseToJSON(value?: GetShipmentsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'booked': ((value['booked'] as Array<any>).map(ShipmentToJSON)),
        'quoted': ((value['quoted'] as Array<any>).map(PreBookingShipmentToJSON)),
    };
}

