import Colors from 'Cargo/Colors';
import Box from 'Cargo/Layout/Box';
import Spacer from 'Cargo/Layout/Spacer';
import { ReactNode } from 'react';
import styled from 'styled-components/macro';

interface ViewShipmentSectionProps {
    children: ReactNode;
    title: ReactNode | undefined;
    subtitle?: ReactNode;
    id: string;
    warn?: boolean;
}

const SectionTitle = styled.div`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 30px;
    color: var(--freightsimple-color-normal-text);
`;

const SectionSubtitle = styled.div`
    font-weight: var(--nhu-font-weight-light);
    font-size: 18px;
    color: var(--freightsimple-color-light-text);
`;

function ViewShipmentSection(props: ViewShipmentSectionProps) {
    return (
        <div
            id={props.id}
            style={{
                width: '100%',
            }}
        >
            <Box
                style={{
                    width: '100%',
                    padding: '24px 40px 40px 40px',
                    marginBottom: '48px',
                }}
            >
                {props.warn && (
                    <div
                        style={{
                            width: '100%',
                            height: '16px',
                            backgroundColor: Colors.Gold,
                            marginBottom: '24px',
                        }}
                    ></div>
                )}
                {props.title && <SectionTitle>{props.title}</SectionTitle>}
                {props.subtitle && (
                    <SectionSubtitle>{props.subtitle}</SectionSubtitle>
                )}
                {props.title && <Spacer height={32} />}
                {!props.title && <Spacer height={8} />}
                {props.children}
            </Box>
        </div>
    );
}
export default ViewShipmentSection;
