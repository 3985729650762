import { useCustomsDocsApi } from 'apis';
import { CustomsDocsUploadMode } from 'Apis/CustomsDocsApi';
import Button from 'Cargo/Controls/Button';
import Stack from 'Cargo/Layout/Stack';
import { useModal } from 'Cargo/Modal/useModal';
import { UUID } from 'Cargo/Types/types';
import { CustomsDocsSubmitSection } from 'Features/CustomDocs/Components/CustomsDocsSubmitSection';
import { useCustomsDocsDropzone } from 'Features/CustomDocs/Hooks/useCustomsDocsDropzone';
import { useState } from 'react';

interface UploadCustomsDocsModalProps {
    trackingId: UUID;
    onFinished: () => void;
    onCancel: () => void;
}

function UploadCustomsDocsModal(props: UploadCustomsDocsModalProps) {
    const customsDocsApi = useCustomsDocsApi();
    const [loading, setLoading] = useState(false);
    const [loadingSuccess, setLoadingSuccess] = useState(false);
    const [loadingFailure, setLoadingFailure] = useState(false);

    const { files, setFiles, dropzoneState } = useCustomsDocsDropzone();

    async function onUpload() {
        setLoadingSuccess(false);
        setLoadingFailure(false);
        setLoading(true);
        try {
            await customsDocsApi.uploadWithTrackingId(
                props.trackingId,
                CustomsDocsUploadMode.Initial,
                files
            );
            setLoadingSuccess(true);
            setTimeout(function () {
                props.onFinished();
            }, 1000);
        } catch (e) {
            console.error(`Failure uploading customs docs`, { e });
            setLoadingFailure(true);
        } finally {
            setLoading(false);
        }
    }

    function acceptedFilesCount() {
        if (files.length === 0) {
            return '';
        }

        if (files.length === 1) {
            return ' (1 file)';
        }

        if (files.length > 1) {
            return ` (${files.length} file)`;
        }
    }

    return (
        <Stack align="center" width="100%" style={{ padding: '32px 64px' }}>
            <CustomsDocsSubmitSection
                setFiles={setFiles}
                dropzoneState={dropzoneState}
                files={files}
                subtitle={
                    <>
                        <p>
                            If you have the customs documents, please upload
                            them here. This will help ensure this shipment goes
                            smoothly.
                        </p>
                        <p>
                            A copy must also be provided to the driver at
                            pickup.
                        </p>
                    </>
                }
            />
            <Button
                size="large"
                onClick={onUpload}
                loadingSuccess={loadingSuccess}
                loading={loading}
                loadingFailure={loadingFailure}
                disabled={files.length === 0 || loading || loadingSuccess}
            >
                Upload Customs Docs {acceptedFilesCount()}
            </Button>
        </Stack>
    );
}

export function useUploadCustomsDocsModal(trackingId: UUID) {
    const showModal = useModal<boolean>();

    async function show(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            showModal(
                (done) => {
                    return (
                        <UploadCustomsDocsModal
                            onCancel={() => done(false)}
                            onFinished={() => {
                                done(true);
                            }}
                            trackingId={trackingId}
                        />
                    );
                },
                async (data) => {
                    if (data !== undefined) {
                        resolve(data);
                    }
                },
                {
                    preventBackdropClick: false,
                }
            );
        });
    }
    return show;
}
