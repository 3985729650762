import Stack from 'Cargo/Layout/Stack';
import { FlowItem } from 'Features/BookShipment/Types/FlowItem';
import { ReactNode } from 'react';
import BookShipmentNavigationSection from './BookShipmentNavigationSection';

interface BookShipmentNavigationProps {
    currentFlowIdentifier: string;
    completedFlowItems: Array<string>;
    unlockedFlowItems: Array<string>;
    additionalContentProvider?: (
        flowIdentifier: string
    ) => ReactNode | undefined;

    finishBookingFlow: Array<FlowItem>;
    alwaysShowRequestQuoteFlow: boolean;

    onNavigate: (
        currentFlowItem: string,
        proposedFlowItem: string
    ) => Promise<void>;
}

interface SectionProps {
    title: string;
    flow: Array<FlowItem>;
    completedFlowItems: Array<string>;
    unlockedFlowItems: Array<string>;
    currentFlowIdentifier: string;
    additionalContentProvider?: (
        flowIdentifier: string
    ) => ReactNode | undefined;

    onNavigate: (
        currentFlowItem: string,
        proposedFlowItem: string
    ) => Promise<void>;
}

function Section(props: SectionProps) {
    return (
        <>
            <BookShipmentNavigationSection
                currentFlowIdentifier={props.currentFlowIdentifier}
                flow={props.flow}
                completedFlowItems={props.completedFlowItems}
                unlockedFlowItems={props.unlockedFlowItems}
                additionalContentProvider={props.additionalContentProvider}
                onNavigate={props.onNavigate}
            ></BookShipmentNavigationSection>
        </>
    );
}

function BookShipmentNavigation(props: BookShipmentNavigationProps) {
    console.log("^^^^ BookShipmentNavigation", { props })
    const finishBookingFlow = props.finishBookingFlow;

    return (
        <>
            <Stack align="left" width="352px" id="BookShipmentNavigation">
                <Section
                    title="Finalize Shipment"
                    flow={finishBookingFlow}
                    completedFlowItems={props.completedFlowItems}
                    currentFlowIdentifier={props.currentFlowIdentifier}
                    unlockedFlowItems={props.unlockedFlowItems}
                    additionalContentProvider={props.additionalContentProvider}
                    onNavigate={props.onNavigate}
                ></Section>
            </Stack>
        </>
    );
}
export default BookShipmentNavigation;
