import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import { useLayoutEffect, useRef, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const ResizeObserver: any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useHeight({ on = true /* no value means on */ } = {} as any) {
    const ref = useRef<HTMLDivElement>();
    const [height, setHeight] = useState(0);
    const heightRef = useRef(height);
    const [ro] = useState(() => {
        const callback = () => {
            // Using requestAnimationFrame supposedly quiets 'ResizeObserver loop limit exceeded' error that showed up in Cypress
            // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
            window.requestAnimationFrame(() => {
                if (
                    ref.current &&
                    heightRef.current !== ref.current.offsetHeight
                ) {
                    heightRef.current = ref.current.offsetHeight;
                    setHeight(ref.current.offsetHeight);
                }
            });
        };

        try {
            return new ResizeObserver(callback);
        } catch (e) {
            return new ResizeObserverPolyfill(callback);
        }
    });
    useLayoutEffect(() => {
        if (on && ref.current) {
            setHeight(ref.current.offsetHeight);
            ro.observe(ref.current, {});
        }
        return () => ro.disconnect();
    }, [on, ref.current]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return [ref, height as any];
}
