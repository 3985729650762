import HorizontalStack, { AlignOptions } from 'Cargo/Layout/HorizontalStack';
import * as React from 'react';

interface LargeCreditCardLogoProps {
    brand: string;
    align?: AlignOptions;
}

function LargeCreditCardLogo(props: LargeCreditCardLogoProps) {
    const { brand } = props;

    return (
        <HorizontalStack
            align={props.align || 'left'}
            style={{ width: '250px', height: '100px' }}
            verticalAlign="middle"
        >
            <img
                style={{
                    maxHeight: '250px',
                    maxWidth: '100px',
                }}
                alt={brand}
                src={`/images/largeCreditCardLogos/${brand}.png`}
            />
        </HorizontalStack>
    );
}

export default LargeCreditCardLogo;
