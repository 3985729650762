/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';

/**
 * 
 * @export
 * @interface ModifySavedContactRequest
 */
export interface ModifySavedContactRequest {
    /**
     * 
     * @type {string}
     * @memberof ModifySavedContactRequest
     */
    savedContactId: string;
    /**
     * 
     * @type {Contact}
     * @memberof ModifySavedContactRequest
     */
    contact: Contact;
}

/**
 * Check if a given object implements the ModifySavedContactRequest interface.
 */
export function instanceOfModifySavedContactRequest(value: object): boolean {
    if (!('savedContactId' in value)) return false;
    if (!('contact' in value)) return false;
    return true;
}

export function ModifySavedContactRequestFromJSON(json: any): ModifySavedContactRequest {
    return ModifySavedContactRequestFromJSONTyped(json, false);
}

export function ModifySavedContactRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModifySavedContactRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'savedContactId': json['savedContactId'],
        'contact': ContactFromJSON(json['contact']),
    };
}

export function ModifySavedContactRequestToJSON(value?: ModifySavedContactRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'savedContactId': value['savedContactId'],
        'contact': ContactToJSON(value['contact']),
    };
}

