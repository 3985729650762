import { useSavedBrokersApi } from 'apis';
import Button from 'Cargo/Controls/Button';
import LinkButton from 'Cargo/Controls/LinkButton';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import { useModalsSlice } from 'Cargo/Modal/modalsSlice';
import { useModal } from 'Cargo/Modal/useModal';
import { Microcopy, ModalTitle } from 'Cargo/Text/Text';
import { UUID } from 'Cargo/Types/types';
import BrokerBox from 'Features/Brokers/Components/BrokerBox';
import SavedBrokersDropdown from 'Features/Brokers/Components/SavedBrokersDropdown';
import useAddBrokerModal from 'Features/Brokers/Hooks/useAddBrokerModal';
import useModifyBrokerModal from 'Features/Brokers/Hooks/useModifyBrokerModal';
import { Broker, SavedBroker } from 'generated-openapi-client';
import { ShipmentState } from 'generated-openapi-client/models/ShipmentState';
import { useOnce } from 'Hooks/useOnce';
import React, { useState } from 'react';
import { useShipmentService } from 'Services/ShipmentService';
import { useUpdatedBOLModal } from './UpdatedBOLModal';

interface SwitchBrokerModalProps {
    broker: Broker;
    onDone: (selectedSavedBrokerId: UUID) => void;
    onCancel: () => void;
}

function SwitchBrokerModal(props: SwitchBrokerModalProps) {
    const { setLoading } = useModalsSlice();
    const [savedBrokers, setSavedBrokers] = useState<Array<SavedBroker>>([]);
    const showModifyBrokerModal = useModifyBrokerModal();
    const savedBrokersApi = useSavedBrokersApi();
    // const [loadingSavedBrokers, setLoadingSavedBrokers] = useState(false);
    const [selectedSavedBrokerId, setSelectedSavedBrokerId] = useState<
        UUID | undefined
    >();

    async function loadSavedBrokers() {
        const savedBrokersResponse = await savedBrokersApi.getAllSavedBrokers();
        setSavedBrokers(savedBrokersResponse.items);

        // First time loading
        if (selectedSavedBrokerId === undefined) {
            setSelectedSavedBrokerId(
                findSavedBrokerId(props.broker, savedBrokersResponse.items)
            );
        }
    }

    useOnce(async function () {
        setLoading(true);
        await loadSavedBrokers();
        setLoading(false);
    });

    const showAddBrokerModal = useAddBrokerModal();

    async function onAddBroker() {
        const data = await showAddBrokerModal();
        setLoading(true);
        console.log('showAddBrokerModal - done with modal');
        const newBroker = data.broker;
        await savedBrokersApi.postSavedBrokersAdd({
            addSavedBrokerRequest: { broker: newBroker },
        });
        console.log(`showAddBrokerModal - saved ${newBroker.savedBrokerId}`);
        await loadSavedBrokers();
        setSelectedSavedBrokerId(newBroker.savedBrokerId);
        setLoading(false);
    }

    const selectedSavedBroker = savedBrokers.find(function (sb) {
        return sb.savedBrokerId === selectedSavedBrokerId;
    });

    function onSwitch(newSavedBrokerId: UUID) {
        console.log('onSwitch');
        setSelectedSavedBrokerId(newSavedBrokerId);
        const newSelectedBroker = savedBrokers.find(function (sb) {
            return sb.savedBrokerId === newSavedBrokerId;
        })?.broker;

        if (newSelectedBroker === undefined) {
            throw new Error('Missing saved broker');
        }
    }

    return (
        <div id="change-customs-broker" style={{ width: '420px' }}>
            <ModalTitle>Change Customs Broker</ModalTitle>
            <Microcopy>Select the customs broker for this shipment</Microcopy>
            <Spacer height={8} />
            <SavedBrokersDropdown
                savedBrokerId={selectedSavedBrokerId}
                savedBrokers={savedBrokers}
                onSetSavedBrokerId={onSwitch}
            />
            {selectedSavedBroker !== undefined && (
                <>
                    <Spacer height={24} />
                    <BrokerBox
                        key={selectedSavedBrokerId}
                        broker={selectedSavedBroker.broker}
                        onModify={async function () {
                            const result = await showModifyBrokerModal(
                                selectedSavedBroker
                            );
                            await savedBrokersApi.postSavedBrokersModify({
                                modifySavedBrokerRequest: result,
                            });
                            await loadSavedBrokers();
                        }}
                        showMenu={false}
                        onDelete={undefined}
                        preferredNorthbound={false}
                        preferredSouthbound={false}
                        onSelectPreferred={undefined}
                    />
                </>
            )}
            <Spacer height={24} />
            <LinkButton onClick={onAddBroker}>
                Add new customs broker
            </LinkButton>
            <Spacer height={24} />
            <HorizontalStack width="100%" align="spread">
                <Button secondary onClick={props.onCancel}>
                    Cancel
                </Button>
                <Button
                    onClick={function () {
                        if (selectedSavedBrokerId !== undefined) {
                            props.onDone(selectedSavedBrokerId);
                        }
                    }}
                    disabled={selectedSavedBrokerId === undefined}
                >
                    Done
                </Button>
            </HorizontalStack>
        </div>
    );
}

function useSwitchBrokerModal() {
    const showModal = useModal<UUID>();

    async function show(broker: Broker): Promise<UUID | undefined> {
        return new Promise<UUID | undefined>((resolve) => {
            showModal(
                (done) => {
                    return (
                        <SwitchBrokerModal
                            broker={broker}
                            onDone={done}
                            onCancel={function () {
                                done(undefined);
                            }}
                        ></SwitchBrokerModal>
                    );
                },
                (data) => {
                    resolve(data);
                }
            );
        });
    }

    return show;
}

function findSavedBrokerId(currentBroker: Broker, savedBrokers: SavedBroker[]) {
    const matchingSavedBroker = savedBrokers.find(function (b) {
        return (
            b.broker.businessName === currentBroker?.businessName &&
            b.broker.address.postalCode === currentBroker.address.postalCode
        );
    });

    console.log(`!!! findSavedBrokerId`, {
        currentBroker,
        matchingSavedBroker,
    });

    return matchingSavedBroker?.savedBrokerId;
}

function useChangeBrokerAfterBookingModal(
    shipmentId: UUID,
    shipmentState: ShipmentState,
    currentBroker: Broker | undefined
) {
    const showSwitchBrokerModal = useSwitchBrokerModal();
    const showUpdatedBOLModal = useUpdatedBOLModal();
    const shipmentService = useShipmentService();
    const { setLoading } = useModalsSlice();

    if (currentBroker === undefined) {
        return () => false;
    }

    // Returns if the broker has changed, hence we should reload
    async function show(): Promise<boolean> {
        if (currentBroker === undefined) {
            return false;
        }

        // We need to show a screen that allows them to select from any of the saved brokers,
        // and optionally modify one of them or add a new one
        const newSavedBrokerId = await showSwitchBrokerModal(currentBroker);

        console.log(`newSavedBrokerId=${newSavedBrokerId}`);

        if (newSavedBrokerId === undefined) {
            // Cancelled
            return false;
        }

        setLoading(true);
        // Make an API call to change the broker
        await shipmentService.setBroker(shipmentId, newSavedBrokerId);
        setLoading(false);

        // If the shipment is in transit, then it's too late to give the driver
        // a new BOL. But if it's still showing as pending pickup, it'd worth showing them this screen
        if (shipmentState !== ShipmentState.InTransit) {
            await showUpdatedBOLModal();
        }

        // Do we need to do something with the custom documents?
        // CS: Jan 2022: I'm currently taking the stance that we do nothing
        // - If they're not yet uploaded then nothing to do anyway
        // - If they have been uploaded, then they're still probably mostly valid, and hopefully
        //   the carrier can work with them
        // We should at some point give the customer the ability to upload replacement customs docs

        return true;
    }

    return show;
}

export default useChangeBrokerAfterBookingModal;
