import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatedContainer } from 'Cargo/Animations/AnimatedContainer';
import { RotationContainer } from 'Cargo/Animations/RotationContainer';
import Colors from 'Cargo/Colors';
import Icon from 'Cargo/Icons/Icon';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import React, { ReactNode, useState } from 'react';
import styled from 'styled-components/macro';

export interface ThreeDotMenuItem {
    name: string;
    subitems?: Array<string>;
    action?: (() => void) | ((_: number) => void);
    selectedIndex?: number;
    disabled?: boolean;
    disabledExplanation?: string;
}
interface ThreeDotMenuProps {
    items: Array<ThreeDotMenuItem>;
    color?: string;
}

const MenuContainer = styled.div`
    min-width: 280px;
    padding: 0;
    position: absolute;
    z-index: 500;
    margin-top: 16px;
    right: -24px;
    background-color: white;
`;

const MenuDivider = styled.div`
    height: 1px;
    width: 100%;
    background-color: #bbb;
`;

const MenuItemBody = styled.div`
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 32px;
    padding-right: 32px;
    font-weight: var(--nhu-font-weight-bold);
    font-size: 14px;
    color: ${Colors.Blue};
    width: 100%;
    background-color: white;
    cursor: pointer;

    &:hover {
        background-color: ${Colors.Blue};
        color: white;
    }
`;

const DisabledMenuItem = styled.div`
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 32px;
    padding-right: 32px;
    font-weight: var(--nhu-font-weight-bold);
    font-size: 14px;
    color: ${Colors.VeryLightText};
    width: 100%;
    background-color: white;
    cursor: not-allowed;
`;

const DisabledExplanation = styled.div`
    font-weight: var(--nhu-font-weight-light);
    font-size: 12px;
`;

export function divider() {
    return {
        name: 'Divider',
    };
}

const MenuItemSubitemTitle = styled.div`
    padding-top: 12px;
    padding-bottom: 4px;
    padding-left: 32px;
    padding-right: 32px;
    font-weight: var(--nhu-font-weight-bold);
    font-size: 12px;
    color: ${Colors.LightText};
    width: 100%;
    background-color: white;
    cursor: pointer;

    &:hover {
        background-color: ${Colors.Blue};
        color: white;
    }
`;

const MenuItemSubitem = styled.div`
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 32px;
    font-weight: var(--nhu-font-weight-bold);
    font-size: 12px;
    color: ${Colors.Blue};
    width: 100%;
    background-color: white;
    cursor: pointer;

    &:hover {
        background-color: ${Colors.Blue};
        color: white;
    }
`;

interface MenuItemProps {
    children: ReactNode;
    href?: string | undefined;
    onClick?: () => void | Promise<void>;
}

function MenuItem(props: MenuItemProps) {
    return (
        <a href={props.href} onClick={props.onClick} style={{ width: '100%' }}>
            <MenuItemBody>{props.children}</MenuItemBody>
        </a>
    );
}

interface MenuItemWithSubitemsProps {
    name: string;
    subitems: Array<string>;
    selectedIndex?: number;
    onClick?: (index: number) => void | Promise<void>;
}

function MenuItemWithSubitems(props: MenuItemWithSubitemsProps) {
    return (
        <>
            <MenuItemSubitemTitle>{props.name}</MenuItemSubitemTitle>
            {props.subitems.map((si, index) => {
                return (
                    <a
                        key={index}
                        onClick={function () {
                            props.onClick?.(index);
                        }}
                        style={{ width: '100%' }}
                    >
                        <MenuItemSubitem>
                            <HorizontalStack align="left">
                                {index === props.selectedIndex && (
                                    <Icon
                                        name="check-circle"
                                        color={Colors.Green}
                                        size={16}
                                        solid
                                    />
                                )}
                                {index !== props.selectedIndex && (
                                    <Spacer width={16} />
                                )}
                                <Spacer width={8} />
                                {si}
                            </HorizontalStack>
                        </MenuItemSubitem>
                    </a>
                );
            })}
            <Spacer height={8} />
        </>
    );
}

function ButtonContent(props: ThreeDotMenuProps & { show: boolean }) {
    return (
        <HorizontalStack verticalAlign="middle" align="spread">
            <RotationContainer open={props.show}>
                <FontAwesomeIcon
                    icon={['fas', 'ellipsis-v']}
                    style={{
                        width: '19px',
                        height: '19px',
                        color: props.color || Colors.Blue,
                        margin: 'auto',
                    }}
                />
            </RotationContainer>
        </HorizontalStack>
    );
}

interface MenuProps {
    show: boolean;
    items: Array<ThreeDotMenuItem>;
}

function Menu(props: MenuProps) {
    return (
        <MenuContainer>
            <AnimatedContainer open={props.show}>
                <Stack align="left">
                    {props.items.map((i, index) => {
                        if (i.name === 'Divider') {
                            return <MenuDivider></MenuDivider>;
                        }

                        if (i.subitems !== undefined) {
                            return (
                                <MenuItemWithSubitems
                                    name={i.name}
                                    onClick={i.action}
                                    subitems={i.subitems}
                                    selectedIndex={i.selectedIndex}
                                ></MenuItemWithSubitems>
                            );
                        }

                        if (i.disabled) {
                            return (
                                <DisabledMenuItem key={index}>
                                    <Stack align="left">
                                        <span>{i.name}</span>
                                        <DisabledExplanation>
                                            {i.disabledExplanation}
                                        </DisabledExplanation>
                                    </Stack>
                                </DisabledMenuItem>
                            );
                        }

                        return (
                            <MenuItem
                                key={index}
                                onClick={i.action as () => void}
                            >
                                {i.name}
                            </MenuItem>
                        );
                    })}
                </Stack>
            </AnimatedContainer>
        </MenuContainer>
    );
}

function ThreeDotMenu(props: ThreeDotMenuProps) {
    const [isInButton, setIsInButton] = useState(false);
    const [isInMenu, setIsInMenu] = useState(false);

    function enterButton() {
        setIsInButton(true);
    }
    function leaveButton() {
        setTimeout(function () {
            setIsInButton(false);
        }, 300);
    }

    function enterMenu() {
        setIsInMenu(true);
    }
    function leaveMenu() {
        setIsInMenu(false);
    }

    const show = isInButton || isInMenu;

    return (
        <div className="threeDotMenu">
            <div
                onMouseEnter={enterButton}
                onMouseLeave={leaveButton}
                style={{ cursor: 'pointer' }}
            >
                <ButtonContent show={show} {...props} />
            </div>

            <div
                onMouseEnter={enterMenu}
                onMouseLeave={leaveMenu}
                style={{ position: 'absolute' }}
            >
                <Menu show={show} items={props.items} />
            </div>
        </div>
    );
}
export default ThreeDotMenu;
