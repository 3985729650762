/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Broker } from './Broker';
import {
    BrokerFromJSON,
    BrokerFromJSONTyped,
    BrokerToJSON,
} from './Broker';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';
import type { EmergencyContactDetails } from './EmergencyContactDetails';
import {
    EmergencyContactDetailsFromJSON,
    EmergencyContactDetailsFromJSONTyped,
    EmergencyContactDetailsToJSON,
} from './EmergencyContactDetails';
import type { EquipmentType } from './EquipmentType';
import {
    EquipmentTypeFromJSON,
    EquipmentTypeFromJSONTyped,
    EquipmentTypeToJSON,
} from './EquipmentType';
import type { LineItem } from './LineItem';
import {
    LineItemFromJSON,
    LineItemFromJSONTyped,
    LineItemToJSON,
} from './LineItem';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';
import type { Quote } from './Quote';
import {
    QuoteFromJSON,
    QuoteFromJSONTyped,
    QuoteToJSON,
} from './Quote';
import type { ShipmentState } from './ShipmentState';
import {
    ShipmentStateFromJSON,
    ShipmentStateFromJSONTyped,
    ShipmentStateToJSON,
} from './ShipmentState';

/**
 * 
 * @export
 * @interface Shipment
 */
export interface Shipment {
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    shipmentId: string;
    /**
     * 
     * @type {ShipmentState}
     * @memberof Shipment
     */
    shipmentState: ShipmentState;
    /**
     * 
     * @type {Quote}
     * @memberof Shipment
     */
    selectedQuote?: Quote;
    /**
     * 
     * @type {Array<LineItem>}
     * @memberof Shipment
     */
    lineItems: Array<LineItem>;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    pickupDate: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    pickupDeadline?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    deliveryDeadline?: string;
    /**
     * 
     * @type {EquipmentType}
     * @memberof Shipment
     */
    equipmentType?: EquipmentType;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    proNumber?: string;
    /**
     * 
     * @type {Location}
     * @memberof Shipment
     */
    pickupLocation: Location;
    /**
     * 
     * @type {Location}
     * @memberof Shipment
     */
    deliveryLocation: Location;
    /**
     * 
     * @type {Contact}
     * @memberof Shipment
     */
    pickupContact: Contact;
    /**
     * 
     * @type {Contact}
     * @memberof Shipment
     */
    deliveryContact: Contact;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    expectedDeliveryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    expectedDeliveryHours?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    actualDeliveryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    actualDeliveryTime?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    pickupReferenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    deliveryReferenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    trId: string;
    /**
     * 
     * @type {Broker}
     * @memberof Shipment
     */
    broker?: Broker;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    trackingNote: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    bookedAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    isReviewingPriorToBookingShipment: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    needsCustomsDocs: boolean;
    /**
     * 
     * @type {EmergencyContactDetails}
     * @memberof Shipment
     */
    emergencyContactDetails?: EmergencyContactDetails;
    /**
     * 
     * @type {Array<string>}
     * @memberof Shipment
     */
    finalizeBookingStages: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    bookedBy: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    latestedPredictedDeliveryDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    addInsuranceToShipment: boolean;
    /**
     * 
     * @type {number}
     * @memberof Shipment
     */
    insuranceAmount: number;
    /**
     * 
     * @type {Currency}
     * @memberof Shipment
     */
    insuranceCurrency: Currency;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    appointmentDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    branchId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    exclusiveUseNeeded: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    tarpRequired: boolean;
    /**
     * 
     * @type {number}
     * @memberof Shipment
     */
    linearFeet?: number;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    pickupBoothNumber: string;
    /**
     * 
     * @type {string}
     * @memberof Shipment
     */
    deliveryBoothNumber: string;
    /**
     * 
     * @type {boolean}
     * @memberof Shipment
     */
    hasFreightClaims?: boolean;
}

/**
 * Check if a given object implements the Shipment interface.
 */
export function instanceOfShipment(value: object): boolean {
    if (!('shipmentId' in value)) return false;
    if (!('shipmentState' in value)) return false;
    if (!('lineItems' in value)) return false;
    if (!('pickupDate' in value)) return false;
    if (!('pickupLocation' in value)) return false;
    if (!('deliveryLocation' in value)) return false;
    if (!('pickupContact' in value)) return false;
    if (!('deliveryContact' in value)) return false;
    if (!('pickupReferenceNumber' in value)) return false;
    if (!('deliveryReferenceNumber' in value)) return false;
    if (!('trId' in value)) return false;
    if (!('trackingNote' in value)) return false;
    if (!('isReviewingPriorToBookingShipment' in value)) return false;
    if (!('needsCustomsDocs' in value)) return false;
    if (!('finalizeBookingStages' in value)) return false;
    if (!('bookedBy' in value)) return false;
    if (!('addInsuranceToShipment' in value)) return false;
    if (!('insuranceAmount' in value)) return false;
    if (!('insuranceCurrency' in value)) return false;
    if (!('exclusiveUseNeeded' in value)) return false;
    if (!('tarpRequired' in value)) return false;
    if (!('pickupBoothNumber' in value)) return false;
    if (!('deliveryBoothNumber' in value)) return false;
    return true;
}

export function ShipmentFromJSON(json: any): Shipment {
    return ShipmentFromJSONTyped(json, false);
}

export function ShipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Shipment {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentId': json['shipmentId'],
        'shipmentState': ShipmentStateFromJSON(json['shipmentState']),
        'selectedQuote': json['selectedQuote'] == null ? undefined : QuoteFromJSON(json['selectedQuote']),
        'lineItems': ((json['lineItems'] as Array<any>).map(LineItemFromJSON)),
        'pickupDate': json['pickupDate'],
        'pickupDeadline': json['pickupDeadline'] == null ? undefined : json['pickupDeadline'],
        'deliveryDeadline': json['deliveryDeadline'] == null ? undefined : json['deliveryDeadline'],
        'equipmentType': json['equipmentType'] == null ? undefined : EquipmentTypeFromJSON(json['equipmentType']),
        'proNumber': json['proNumber'] == null ? undefined : json['proNumber'],
        'pickupLocation': LocationFromJSON(json['pickupLocation']),
        'deliveryLocation': LocationFromJSON(json['deliveryLocation']),
        'pickupContact': ContactFromJSON(json['pickupContact']),
        'deliveryContact': ContactFromJSON(json['deliveryContact']),
        'expectedDeliveryDate': json['expectedDeliveryDate'] == null ? undefined : json['expectedDeliveryDate'],
        'expectedDeliveryHours': json['expectedDeliveryHours'] == null ? undefined : json['expectedDeliveryHours'],
        'actualDeliveryDate': json['actualDeliveryDate'] == null ? undefined : json['actualDeliveryDate'],
        'actualDeliveryTime': json['actualDeliveryTime'] == null ? undefined : json['actualDeliveryTime'],
        'pickupReferenceNumber': json['pickupReferenceNumber'],
        'deliveryReferenceNumber': json['deliveryReferenceNumber'],
        'trId': json['trId'],
        'broker': json['broker'] == null ? undefined : BrokerFromJSON(json['broker']),
        'trackingNote': json['trackingNote'],
        'bookedAt': json['bookedAt'] == null ? undefined : json['bookedAt'],
        'isReviewingPriorToBookingShipment': json['isReviewingPriorToBookingShipment'],
        'needsCustomsDocs': json['needsCustomsDocs'],
        'emergencyContactDetails': json['emergencyContactDetails'] == null ? undefined : EmergencyContactDetailsFromJSON(json['emergencyContactDetails']),
        'finalizeBookingStages': json['finalizeBookingStages'],
        'bookedBy': json['bookedBy'],
        'latestedPredictedDeliveryDate': json['latestedPredictedDeliveryDate'] == null ? undefined : json['latestedPredictedDeliveryDate'],
        'addInsuranceToShipment': json['addInsuranceToShipment'],
        'insuranceAmount': json['insuranceAmount'],
        'insuranceCurrency': CurrencyFromJSON(json['insuranceCurrency']),
        'appointmentDate': json['appointmentDate'] == null ? undefined : json['appointmentDate'],
        'branchId': json['branchId'] == null ? undefined : json['branchId'],
        'exclusiveUseNeeded': json['exclusiveUseNeeded'],
        'tarpRequired': json['tarpRequired'],
        'linearFeet': json['linearFeet'] == null ? undefined : json['linearFeet'],
        'pickupBoothNumber': json['pickupBoothNumber'],
        'deliveryBoothNumber': json['deliveryBoothNumber'],
        'hasFreightClaims': json['hasFreightClaims'] == null ? undefined : json['hasFreightClaims'],
    };
}

export function ShipmentToJSON(value?: Shipment | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentId': value['shipmentId'],
        'shipmentState': ShipmentStateToJSON(value['shipmentState']),
        'selectedQuote': QuoteToJSON(value['selectedQuote']),
        'lineItems': ((value['lineItems'] as Array<any>).map(LineItemToJSON)),
        'pickupDate': value['pickupDate'],
        'pickupDeadline': value['pickupDeadline'],
        'deliveryDeadline': value['deliveryDeadline'],
        'equipmentType': EquipmentTypeToJSON(value['equipmentType']),
        'proNumber': value['proNumber'],
        'pickupLocation': LocationToJSON(value['pickupLocation']),
        'deliveryLocation': LocationToJSON(value['deliveryLocation']),
        'pickupContact': ContactToJSON(value['pickupContact']),
        'deliveryContact': ContactToJSON(value['deliveryContact']),
        'expectedDeliveryDate': value['expectedDeliveryDate'],
        'expectedDeliveryHours': value['expectedDeliveryHours'],
        'actualDeliveryDate': value['actualDeliveryDate'],
        'actualDeliveryTime': value['actualDeliveryTime'],
        'pickupReferenceNumber': value['pickupReferenceNumber'],
        'deliveryReferenceNumber': value['deliveryReferenceNumber'],
        'trId': value['trId'],
        'broker': BrokerToJSON(value['broker']),
        'trackingNote': value['trackingNote'],
        'bookedAt': value['bookedAt'],
        'isReviewingPriorToBookingShipment': value['isReviewingPriorToBookingShipment'],
        'needsCustomsDocs': value['needsCustomsDocs'],
        'emergencyContactDetails': EmergencyContactDetailsToJSON(value['emergencyContactDetails']),
        'finalizeBookingStages': value['finalizeBookingStages'],
        'bookedBy': value['bookedBy'],
        'latestedPredictedDeliveryDate': value['latestedPredictedDeliveryDate'],
        'addInsuranceToShipment': value['addInsuranceToShipment'],
        'insuranceAmount': value['insuranceAmount'],
        'insuranceCurrency': CurrencyToJSON(value['insuranceCurrency']),
        'appointmentDate': value['appointmentDate'],
        'branchId': value['branchId'],
        'exclusiveUseNeeded': value['exclusiveUseNeeded'],
        'tarpRequired': value['tarpRequired'],
        'linearFeet': value['linearFeet'],
        'pickupBoothNumber': value['pickupBoothNumber'],
        'deliveryBoothNumber': value['deliveryBoothNumber'],
        'hasFreightClaims': value['hasFreightClaims'],
    };
}

