/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryCode } from './CountryCode';
import {
    CountryCodeFromJSON,
    CountryCodeFromJSONTyped,
    CountryCodeToJSON,
} from './CountryCode';

/**
 * 
 * @export
 * @interface PreQuoteAddress
 */
export interface PreQuoteAddress {
    /**
     * 
     * @type {string}
     * @memberof PreQuoteAddress
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof PreQuoteAddress
     */
    stateOrProvinceCode: string;
    /**
     * 
     * @type {string}
     * @memberof PreQuoteAddress
     */
    postalCode: string;
    /**
     * 
     * @type {CountryCode}
     * @memberof PreQuoteAddress
     */
    countryCode: CountryCode;
    /**
     * 
     * @type {string}
     * @memberof PreQuoteAddress
     */
    addressLine?: string;
    /**
     * 
     * @type {string}
     * @memberof PreQuoteAddress
     */
    addressLine2?: string;
}

/**
 * Check if a given object implements the PreQuoteAddress interface.
 */
export function instanceOfPreQuoteAddress(value: object): boolean {
    if (!('city' in value)) return false;
    if (!('stateOrProvinceCode' in value)) return false;
    if (!('postalCode' in value)) return false;
    if (!('countryCode' in value)) return false;
    return true;
}

export function PreQuoteAddressFromJSON(json: any): PreQuoteAddress {
    return PreQuoteAddressFromJSONTyped(json, false);
}

export function PreQuoteAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreQuoteAddress {
    if (json == null) {
        return json;
    }
    return {
        
        'city': json['city'],
        'stateOrProvinceCode': json['stateOrProvinceCode'],
        'postalCode': json['postalCode'],
        'countryCode': CountryCodeFromJSON(json['countryCode']),
        'addressLine': json['addressLine'] == null ? undefined : json['addressLine'],
        'addressLine2': json['addressLine2'] == null ? undefined : json['addressLine2'],
    };
}

export function PreQuoteAddressToJSON(value?: PreQuoteAddress | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'city': value['city'],
        'stateOrProvinceCode': value['stateOrProvinceCode'],
        'postalCode': value['postalCode'],
        'countryCode': CountryCodeToJSON(value['countryCode']),
        'addressLine': value['addressLine'],
        'addressLine2': value['addressLine2'],
    };
}

