/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';
import type { LineItem } from './LineItem';
import {
    LineItemFromJSON,
    LineItemFromJSONTyped,
    LineItemToJSON,
} from './LineItem';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';
import type { ShipmentState } from './ShipmentState';
import {
    ShipmentStateFromJSON,
    ShipmentStateFromJSONTyped,
    ShipmentStateToJSON,
} from './ShipmentState';

/**
 * 
 * @export
 * @interface ShipmentForTracking
 */
export interface ShipmentForTracking {
    /**
     * 
     * @type {ShipmentState}
     * @memberof ShipmentForTracking
     */
    shipmentState: ShipmentState;
    /**
     * 
     * @type {Array<LineItem>}
     * @memberof ShipmentForTracking
     */
    lineItems: Array<LineItem>;
    /**
     * 
     * @type {string}
     * @memberof ShipmentForTracking
     */
    proNumber?: string;
    /**
     * 
     * @type {Location}
     * @memberof ShipmentForTracking
     */
    pickupLocation: Location;
    /**
     * 
     * @type {Location}
     * @memberof ShipmentForTracking
     */
    deliveryLocation: Location;
    /**
     * 
     * @type {Contact}
     * @memberof ShipmentForTracking
     */
    pickupContact: Contact;
    /**
     * 
     * @type {Contact}
     * @memberof ShipmentForTracking
     */
    deliveryContact: Contact;
    /**
     * 
     * @type {string}
     * @memberof ShipmentForTracking
     */
    expectedDeliveryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentForTracking
     */
    expectedDeliveryHours?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentForTracking
     */
    actualDeliveryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentForTracking
     */
    actualDeliveryTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentForTracking
     */
    deliveryDeadline?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentForTracking
     */
    deliveryReferenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentForTracking
     */
    trId: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentForTracking
     */
    pickupDate: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentForTracking
     */
    carrierIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentForTracking
     */
    serviceDisplayName: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentForTracking
     */
    trackingNote: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentForTracking
     */
    latestExpectedDeliveryDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShipmentForTracking
     */
    needsCustomsDocs: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShipmentForTracking
     */
    appointmentDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentForTracking
     */
    pickupBoothNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentForTracking
     */
    deliveryBoothNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentForTracking
     */
    pickupReferenceNumber: string;
}

/**
 * Check if a given object implements the ShipmentForTracking interface.
 */
export function instanceOfShipmentForTracking(value: object): boolean {
    if (!('shipmentState' in value)) return false;
    if (!('lineItems' in value)) return false;
    if (!('pickupLocation' in value)) return false;
    if (!('deliveryLocation' in value)) return false;
    if (!('pickupContact' in value)) return false;
    if (!('deliveryContact' in value)) return false;
    if (!('deliveryReferenceNumber' in value)) return false;
    if (!('trId' in value)) return false;
    if (!('pickupDate' in value)) return false;
    if (!('carrierIdentifier' in value)) return false;
    if (!('serviceDisplayName' in value)) return false;
    if (!('trackingNote' in value)) return false;
    if (!('needsCustomsDocs' in value)) return false;
    if (!('pickupBoothNumber' in value)) return false;
    if (!('deliveryBoothNumber' in value)) return false;
    if (!('pickupReferenceNumber' in value)) return false;
    return true;
}

export function ShipmentForTrackingFromJSON(json: any): ShipmentForTracking {
    return ShipmentForTrackingFromJSONTyped(json, false);
}

export function ShipmentForTrackingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentForTracking {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentState': ShipmentStateFromJSON(json['shipmentState']),
        'lineItems': ((json['lineItems'] as Array<any>).map(LineItemFromJSON)),
        'proNumber': json['proNumber'] == null ? undefined : json['proNumber'],
        'pickupLocation': LocationFromJSON(json['pickupLocation']),
        'deliveryLocation': LocationFromJSON(json['deliveryLocation']),
        'pickupContact': ContactFromJSON(json['pickupContact']),
        'deliveryContact': ContactFromJSON(json['deliveryContact']),
        'expectedDeliveryDate': json['expectedDeliveryDate'] == null ? undefined : json['expectedDeliveryDate'],
        'expectedDeliveryHours': json['expectedDeliveryHours'] == null ? undefined : json['expectedDeliveryHours'],
        'actualDeliveryDate': json['actualDeliveryDate'] == null ? undefined : json['actualDeliveryDate'],
        'actualDeliveryTime': json['actualDeliveryTime'] == null ? undefined : json['actualDeliveryTime'],
        'deliveryDeadline': json['deliveryDeadline'] == null ? undefined : json['deliveryDeadline'],
        'deliveryReferenceNumber': json['deliveryReferenceNumber'],
        'trId': json['trId'],
        'pickupDate': json['pickupDate'],
        'carrierIdentifier': json['carrierIdentifier'],
        'serviceDisplayName': json['serviceDisplayName'],
        'trackingNote': json['trackingNote'],
        'latestExpectedDeliveryDate': json['latestExpectedDeliveryDate'] == null ? undefined : json['latestExpectedDeliveryDate'],
        'needsCustomsDocs': json['needsCustomsDocs'],
        'appointmentDate': json['appointmentDate'] == null ? undefined : json['appointmentDate'],
        'pickupBoothNumber': json['pickupBoothNumber'],
        'deliveryBoothNumber': json['deliveryBoothNumber'],
        'pickupReferenceNumber': json['pickupReferenceNumber'],
    };
}

export function ShipmentForTrackingToJSON(value?: ShipmentForTracking | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentState': ShipmentStateToJSON(value['shipmentState']),
        'lineItems': ((value['lineItems'] as Array<any>).map(LineItemToJSON)),
        'proNumber': value['proNumber'],
        'pickupLocation': LocationToJSON(value['pickupLocation']),
        'deliveryLocation': LocationToJSON(value['deliveryLocation']),
        'pickupContact': ContactToJSON(value['pickupContact']),
        'deliveryContact': ContactToJSON(value['deliveryContact']),
        'expectedDeliveryDate': value['expectedDeliveryDate'],
        'expectedDeliveryHours': value['expectedDeliveryHours'],
        'actualDeliveryDate': value['actualDeliveryDate'],
        'actualDeliveryTime': value['actualDeliveryTime'],
        'deliveryDeadline': value['deliveryDeadline'],
        'deliveryReferenceNumber': value['deliveryReferenceNumber'],
        'trId': value['trId'],
        'pickupDate': value['pickupDate'],
        'carrierIdentifier': value['carrierIdentifier'],
        'serviceDisplayName': value['serviceDisplayName'],
        'trackingNote': value['trackingNote'],
        'latestExpectedDeliveryDate': value['latestExpectedDeliveryDate'],
        'needsCustomsDocs': value['needsCustomsDocs'],
        'appointmentDate': value['appointmentDate'],
        'pickupBoothNumber': value['pickupBoothNumber'],
        'deliveryBoothNumber': value['deliveryBoothNumber'],
        'pickupReferenceNumber': value['pickupReferenceNumber'],
    };
}

