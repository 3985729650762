import Animation from 'Cargo/Animations/Animation';
import React from 'react';

const BlueInfoAnimatedIcon: React.FC = () => {
    return (
        <Animation
            style={{
                width: '25px',
                height: '25px',
                marginBottom: '8px',
                marginRight: '0',
            }}
            lottieFilesUrl="https://edit.lottiefiles.com/?src=https%3A%2F%2Fassets3.lottiefiles.com%2Fpackages%2Flf20_rqft7rgi.json"
            pathToAnimation="/animations/blue-info.json"
        />
    );
};
export default BlueInfoAnimatedIcon;
