import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import { useLayoutEffect, useRef, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const ResizeObserver: any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useWidth({ on = true /* no value means on */ } = {} as any) {
    const ref = useRef<HTMLDivElement>();
    const [width, setWidth] = useState(0);
    const widthRef = useRef(width);
    const [ro] = useState(() => {
        const callback = () => {
            // Using requestAnimationFrame supposedly quiets 'ResizeObserver loop limit exceeded' error that showed up in Cypress
            // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
            window.requestAnimationFrame(() => {
                if (
                    ref.current &&
                    widthRef.current !== ref.current.offsetWidth
                ) {
                    widthRef.current = ref.current.offsetWidth;
                    setWidth(ref.current.offsetWidth);
                }
            });
        };

        try {
            return new ResizeObserver(callback);
        } catch (e) {
            return new ResizeObserverPolyfill(callback);
        }
    });
    useLayoutEffect(() => {
        if (on && ref.current) {
            setWidth(ref.current.offsetWidth);
            ro.observe(ref.current, {});
        }
        return () => ro.disconnect();
    }, [on, ref.current]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return [ref, width as any];
}
