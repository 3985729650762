import Animation from 'Cargo/Animations/Animation';
import React from 'react';

const RedExclamationAnimatedIcon: React.FC = () => {
    return (
        <Animation
            style={{
                width: '25px',
                height: '25px',
                marginBottom: '8px',
                marginRight: '0',
            }}
            lottieFilesUrl="https://edit.lottiefiles.com/?src=https%3A%2F%2Fassets4.lottiefiles.com%2Fpackages%2Flf20_HYlQBb.json"
            pathToAnimation="/animations/red-exclamation.json"
        />
    );
};
export default RedExclamationAnimatedIcon;
