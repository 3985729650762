/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangePickupDatePreBookingRequest
 */
export interface ChangePickupDatePreBookingRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangePickupDatePreBookingRequest
     */
    shipmentId: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePickupDatePreBookingRequest
     */
    pickupDate: string;
}

/**
 * Check if a given object implements the ChangePickupDatePreBookingRequest interface.
 */
export function instanceOfChangePickupDatePreBookingRequest(value: object): boolean {
    if (!('shipmentId' in value)) return false;
    if (!('pickupDate' in value)) return false;
    return true;
}

export function ChangePickupDatePreBookingRequestFromJSON(json: any): ChangePickupDatePreBookingRequest {
    return ChangePickupDatePreBookingRequestFromJSONTyped(json, false);
}

export function ChangePickupDatePreBookingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangePickupDatePreBookingRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentId': json['shipmentId'],
        'pickupDate': json['pickupDate'],
    };
}

export function ChangePickupDatePreBookingRequestToJSON(value?: ChangePickupDatePreBookingRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentId': value['shipmentId'],
        'pickupDate': value['pickupDate'],
    };
}

