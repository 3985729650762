import { UUID } from 'Cargo/Types/types';
import { fileNameForDocumentType } from 'Helpers/fileNameForDocumentType';
import { useTrackingApi } from 'apis';
import {
    Document,
    DocumentType,
    ShipmentForTracking,
    TrackingInfo,
} from 'generated-openapi-client';

interface TrackingService {
    getShipmentForTracking: (trackingId: UUID) => Promise<ShipmentForTracking>;
    getTracking: (trackingId: UUID) => Promise<TrackingInfo>;
    getTrackingIdForProNumber: (proNumber: string) => Promise<UUID | undefined>;
    getDocumentsForTracking: (trackingId: UUID) => Promise<Document[]>;
    downloadPickupPackage: (trackingId: UUID) => Promise<void>;
    downloadDocument: (
        tId: UUID,
        documentId: UUID,
        documentType: DocumentType
    ) => void;
}

export function useTrackingService(): TrackingService {
    const trackingApi = useTrackingApi();

    return {
        getTrackingIdForProNumber: async function (
            proNumber: string
        ): Promise<UUID | undefined> {
            const response =
                await trackingApi.getTrackingGetTrackingIdForProNumber({
                    proNumber,
                });
            console.log(`!!!! getTrackingIdForProNumber`, { response });
            return response.trId;
        },

        getShipmentForTracking: async function (
            tId: UUID
        ): Promise<ShipmentForTracking> {
            const response =
                await trackingApi.getTrackingGetShipmentForTracking({
                    tId,
                });

            return response;
        },

        getTracking: async function (tId: UUID): Promise<TrackingInfo> {
            const response = await trackingApi.getTrackingGetTracking({
                tId,
            });

            return response;
        },

        getDocumentsForTracking: async function (
            tId: UUID
        ): Promise<Document[]> {
            const response =
                await trackingApi.getTrackingListDocumentsForTracking({
                    tId,
                });
            return response.documents;
        },

        downloadPickupPackage: async function (tId: UUID) {
            const pickupPackage = await trackingApi.getTrackingPickupPackagePdf(
                {
                    tId,
                }
            );

            const linkSource = `data:application/pdf;base64,${pickupPackage.base64EncodedPdf}`;
            const downloadLink = document.createElement('a');
            const fileName = 'pickupPackage.pdf';
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        },

        downloadDocument: async function (
            tId: UUID,
            documentId: UUID,
            documentType: DocumentType
        ) {
            const response = await trackingApi.getTrackingDocumentPdf({
                tId,
                documentId,
            });

            const linkSource = `data:application/pdf;base64,${response.base64EncodedPdf}`;
            const downloadLink = document.createElement('a');
            const fileName = fileNameForDocumentType(documentType);
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        },
    };
}
