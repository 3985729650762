/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreditCard
 */
export interface CreditCard {
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    stripePaymentMethodId: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    brand: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    lastFourDigits: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    expiry: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreditCard
     */
    isDefault: boolean;
}

/**
 * Check if a given object implements the CreditCard interface.
 */
export function instanceOfCreditCard(value: object): boolean {
    if (!('stripePaymentMethodId' in value)) return false;
    if (!('brand' in value)) return false;
    if (!('lastFourDigits' in value)) return false;
    if (!('expiry' in value)) return false;
    if (!('name' in value)) return false;
    if (!('isDefault' in value)) return false;
    return true;
}

export function CreditCardFromJSON(json: any): CreditCard {
    return CreditCardFromJSONTyped(json, false);
}

export function CreditCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreditCard {
    if (json == null) {
        return json;
    }
    return {
        
        'stripePaymentMethodId': json['stripePaymentMethodId'],
        'brand': json['brand'],
        'lastFourDigits': json['lastFourDigits'],
        'expiry': json['expiry'],
        'name': json['name'],
        'isDefault': json['isDefault'],
    };
}

export function CreditCardToJSON(value?: CreditCard | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'stripePaymentMethodId': value['stripePaymentMethodId'],
        'brand': value['brand'],
        'lastFourDigits': value['lastFourDigits'],
        'expiry': value['expiry'],
        'name': value['name'],
        'isDefault': value['isDefault'],
    };
}

