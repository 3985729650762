import LinkButton from 'Cargo/Controls/LinkButton';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import { Legalese } from 'Cargo/Text/Text';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

function Footer() {
    function showHelpCenterLink() {
        if (location.pathname == '/sign-in') {
            return true;
        }

        if (location.pathname == '/create-account') {
            return true;
        }

        return false;
    }

    const currentYear = new Date().getFullYear();

    return (
        <HorizontalStack
            align="center"
            style={{
                width: '100%',
                height: '32px',
                marginBottom: '16px',
                marginTop: '32px',
            }}
            verticalAlign="bottom"
        >
            <Legalese>
                Copyright © FreightSimple Technologies, Inc. 2021-{currentYear}
            </Legalese>
            <Spacer width={16} />
            <Legalese>
                <LinkButton
                    regularWeight={true}
                    to="https://www.freightsimple.com/terms-of-service"
                >
                    View Terms of Service
                </LinkButton>
            </Legalese>
            <Spacer width={16} />
            <Legalese>
                <LinkButton
                    regularWeight={true}
                    to="https://www.freightsimple.com/privacy-policy"
                >
                    View Privacy Policy
                </LinkButton>
            </Legalese>
            {showHelpCenterLink() && (
                <>
                    <Spacer width={16} />
                    <Legalese>
                        <LinkButton
                            regularWeight={true}
                            to="https://help.freightsimple.com"
                        >
                            Help Center
                        </LinkButton>
                    </Legalese>
                </>
            )}
        </HorizontalStack>
    );
}

interface FooterContainerProps {
    children: ReactNode;
}

const Site = styled.div`
    display: flex;
    min-height: 100vh;
    flex-direction: column;
`;

const SiteContent = styled.div`
    flex: 1;
`;

export function FooterContainer(props: FooterContainerProps) {
    const location = useLocation();

    function showFooter() {
        if (location.pathname == '/upload-photos') {
            return false;
        }

        if (location.pathname == '/create-account') {
            return false;
        }

        if (location.pathname == '/sign-in') {
            return false;
        }

        return true;
    }
    return (
        <Site>
            <SiteContent>{props.children}</SiteContent>
            {showFooter() && <Footer />}
        </Site>
    );
}
