/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HandlingUnitType } from './HandlingUnitType';
import {
    HandlingUnitTypeFromJSON,
    HandlingUnitTypeFromJSONTyped,
    HandlingUnitTypeToJSON,
} from './HandlingUnitType';
import type { TemperatureHandling } from './TemperatureHandling';
import {
    TemperatureHandlingFromJSON,
    TemperatureHandlingFromJSONTyped,
    TemperatureHandlingToJSON,
} from './TemperatureHandling';

/**
 * 
 * @export
 * @interface SavedCommodity
 */
export interface SavedCommodity {
    /**
     * 
     * @type {string}
     * @memberof SavedCommodity
     */
    savedCommodityId: string;
    /**
     * 
     * @type {string}
     * @memberof SavedCommodity
     */
    nmfcItemNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SavedCommodity
     */
    description: string;
    /**
     * 
     * @type {HandlingUnitType}
     * @memberof SavedCommodity
     */
    handlingUnitType: HandlingUnitType;
    /**
     * 
     * @type {string}
     * @memberof SavedCommodity
     */
    dangerousUnNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SavedCommodity
     */
    dangerousClassification?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SavedCommodity
     */
    isDangerous: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SavedCommodity
     */
    isStackable: boolean;
    /**
     * 
     * @type {TemperatureHandling}
     * @memberof SavedCommodity
     */
    temperatureHandling: TemperatureHandling;
    /**
     * 
     * @type {string}
     * @memberof SavedCommodity
     */
    dangerousPackingGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof SavedCommodity
     */
    dangerousProperShippingName?: string;
    /**
     * 
     * @type {string}
     * @memberof SavedCommodity
     */
    dangerousTechnicalName?: string;
    /**
     * 
     * @type {number}
     * @memberof SavedCommodity
     */
    dangerousNumberPackages?: number;
    /**
     * 
     * @type {string}
     * @memberof SavedCommodity
     */
    dangerousPackagingType?: string;
}

/**
 * Check if a given object implements the SavedCommodity interface.
 */
export function instanceOfSavedCommodity(value: object): boolean {
    if (!('savedCommodityId' in value)) return false;
    if (!('description' in value)) return false;
    if (!('handlingUnitType' in value)) return false;
    if (!('isDangerous' in value)) return false;
    if (!('isStackable' in value)) return false;
    if (!('temperatureHandling' in value)) return false;
    return true;
}

export function SavedCommodityFromJSON(json: any): SavedCommodity {
    return SavedCommodityFromJSONTyped(json, false);
}

export function SavedCommodityFromJSONTyped(json: any, ignoreDiscriminator: boolean): SavedCommodity {
    if (json == null) {
        return json;
    }
    return {
        
        'savedCommodityId': json['savedCommodityId'],
        'nmfcItemNumber': json['nmfcItemNumber'] == null ? undefined : json['nmfcItemNumber'],
        'description': json['description'],
        'handlingUnitType': HandlingUnitTypeFromJSON(json['handlingUnitType']),
        'dangerousUnNumber': json['dangerousUnNumber'] == null ? undefined : json['dangerousUnNumber'],
        'dangerousClassification': json['dangerousClassification'] == null ? undefined : json['dangerousClassification'],
        'isDangerous': json['isDangerous'],
        'isStackable': json['isStackable'],
        'temperatureHandling': TemperatureHandlingFromJSON(json['temperatureHandling']),
        'dangerousPackingGroup': json['dangerousPackingGroup'] == null ? undefined : json['dangerousPackingGroup'],
        'dangerousProperShippingName': json['dangerousProperShippingName'] == null ? undefined : json['dangerousProperShippingName'],
        'dangerousTechnicalName': json['dangerousTechnicalName'] == null ? undefined : json['dangerousTechnicalName'],
        'dangerousNumberPackages': json['dangerousNumberPackages'] == null ? undefined : json['dangerousNumberPackages'],
        'dangerousPackagingType': json['dangerousPackagingType'] == null ? undefined : json['dangerousPackagingType'],
    };
}

export function SavedCommodityToJSON(value?: SavedCommodity | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'savedCommodityId': value['savedCommodityId'],
        'nmfcItemNumber': value['nmfcItemNumber'],
        'description': value['description'],
        'handlingUnitType': HandlingUnitTypeToJSON(value['handlingUnitType']),
        'dangerousUnNumber': value['dangerousUnNumber'],
        'dangerousClassification': value['dangerousClassification'],
        'isDangerous': value['isDangerous'],
        'isStackable': value['isStackable'],
        'temperatureHandling': TemperatureHandlingToJSON(value['temperatureHandling']),
        'dangerousPackingGroup': value['dangerousPackingGroup'],
        'dangerousProperShippingName': value['dangerousProperShippingName'],
        'dangerousTechnicalName': value['dangerousTechnicalName'],
        'dangerousNumberPackages': value['dangerousNumberPackages'],
        'dangerousPackagingType': value['dangerousPackagingType'],
    };
}

