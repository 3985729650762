/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Broker } from './Broker';
import {
    BrokerFromJSON,
    BrokerFromJSONTyped,
    BrokerToJSON,
} from './Broker';

/**
 * 
 * @export
 * @interface SavedBroker
 */
export interface SavedBroker {
    /**
     * 
     * @type {string}
     * @memberof SavedBroker
     */
    savedBrokerId: string;
    /**
     * 
     * @type {Broker}
     * @memberof SavedBroker
     */
    broker: Broker;
}

/**
 * Check if a given object implements the SavedBroker interface.
 */
export function instanceOfSavedBroker(value: object): boolean {
    if (!('savedBrokerId' in value)) return false;
    if (!('broker' in value)) return false;
    return true;
}

export function SavedBrokerFromJSON(json: any): SavedBroker {
    return SavedBrokerFromJSONTyped(json, false);
}

export function SavedBrokerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SavedBroker {
    if (json == null) {
        return json;
    }
    return {
        
        'savedBrokerId': json['savedBrokerId'],
        'broker': BrokerFromJSON(json['broker']),
    };
}

export function SavedBrokerToJSON(value?: SavedBroker | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'savedBrokerId': value['savedBrokerId'],
        'broker': BrokerToJSON(value['broker']),
    };
}

