import Dropdown from 'Cargo/Controls/Dropdown';
import { DropdownElement } from 'Cargo/Controls/DropdownElement';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import { ErrorMessageType } from 'Cargo/Validation';
import { getHandlingUnitTypesForDropdown } from 'Data/HandlingUnitTypes';
import { HandlingUnitType } from 'generated-openapi-client';
import React from 'react';
import { IsStackableQuestionBubble } from './QuestionBubbles/IsStackableQuestionBubble';

interface IsStackableControlProps {
    isStackable: boolean | undefined;
    onChange: (value: boolean) => void;
    usePlural: boolean;
    handlingUnitType: HandlingUnitType | undefined;
    errorMessage: ErrorMessageType;
    forceValidation: boolean;
}

const IsStackableControl: React.FC<IsStackableControlProps> = (
    props: IsStackableControlProps
) => {
    function descriptionWithHandlingUnitType() {
        const handlingTypes = getHandlingUnitTypesForDropdown(props.usePlural);

        const item = handlingTypes.find(
            (t) => t.item === props.handlingUnitType
        );

        if (item === undefined) {
            throw new Error(
                `Missing handling unit type ${props.handlingUnitType}`
            );
        }

        const handlingTypeDescription = item.description;

        const verb = props.usePlural ? 'are' : 'is';

        return `${handlingTypeDescription} ${verb} stackable`;
    }

    function generateOptions() {
        const stackableDescription =
            props.handlingUnitType === undefined ||
                props.handlingUnitType === HandlingUnitType.Other
                ? 'Stackable'
                : descriptionWithHandlingUnitType();

        return [
            {
                item: false,
                description: (
                    <DropdownElement>
                        <HorizontalStack width="388px" align="spread">
                            Not stackable
                            <img src="/not-stackable.png" width="32" />
                        </HorizontalStack>
                    </DropdownElement>
                ),
            },
            {
                item: true,
                description: (
                    <DropdownElement>
                        <HorizontalStack width="388px" align="spread">
                            {stackableDescription}
                            <img src="/stackable.png" width="32" />
                        </HorizontalStack>
                    </DropdownElement>
                ),
            },
        ];
    }

    return (
        <>
            <Dropdown
                id="isStackableControl"
                unselectedDescription="Can other freight be put on top of it?"
                placeholder={
                    <>
                        Is Stackable?
                        <IsStackableQuestionBubble />
                    </>
                }
                onOptionSelected={props.onChange}
                options={generateOptions()}
                selectedItem={props.isStackable}
                width={407}
                errorMessage={props.errorMessage}
                forceValidation={props.forceValidation}
            ></Dropdown>
        </>
    );
};
export default IsStackableControl;
