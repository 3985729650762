import {
    isHoliday,
    isHolidayForCountry,
    getHolidaysByDate,
} from 'freightsimple-utilities/dist/holidays/moment';
import { getCountriesOfHoliday } from 'freightsimple-utilities/dist/holidays';
import { CountryCode } from 'generated-openapi-client';
import moment from 'moment';

interface HolidayMessageAlertProps {
    date: moment.Moment;
    country?: CountryCode;
}

export function HolidayMessageAlert({
    date,
    country,
}: HolidayMessageAlertProps) {
    let isHolidayDate = isHoliday(date);

    if (country) {
        isHolidayDate = isHolidayForCountry(date, country);
    }

    if (!isHolidayDate) {
        return <></>;
    }

    const holidays = getHolidaysByDate(date);

    return (
        <div style={{ display: 'flex', columnGap: '10px' }}>
            <span>{'⚠️'}</span>
            <div>
                {holidays.map((h, i) => (
                    <p style={{ margin: 0, padding: 0 }} key={i}>
                        {h.description + ' in '}{' '}
                        {getCountriesOfHoliday(h).join(' and ') + '.'}
                    </p>
                ))}
            </div>
        </div>
    );
}
