/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FreightClaim,
} from '../models/index';
import {
    FreightClaimFromJSON,
    FreightClaimToJSON,
} from '../models/index';

export interface GetByShipmentIdRequest {
    shipmentId: string;
}

/**
 * 
 */
export class FreightClaimsApi extends runtime.BaseAPI {

    /**
     * Get Freight Claims for Shipment
     * Your GET endpoint
     */
    async getByShipmentIdRaw(requestParameters: GetByShipmentIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FreightClaim>>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling getByShipmentId().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/freight-claims/get-by-shipment-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FreightClaimFromJSON));
    }

    /**
     * Get Freight Claims for Shipment
     * Your GET endpoint
     */
    async getByShipmentId(requestParameters: GetByShipmentIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FreightClaim>> {
        const response = await this.getByShipmentIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
