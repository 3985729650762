import Button from 'Cargo/Controls/Button';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import { Label } from 'Cargo/Text/Text';
import ContactQuestionBubble from 'Features/BookShipment/Components/Addresses/QuestionBubbles/ContactQuestionBubble';
import LargeContactInput from 'Features/Contacts/Components/LargeContactInput';
import { LocationContext } from 'generated-openapi-client';
import { useState } from 'react';
import { AddContactContactType } from '../Types/contactTypes';
import {
    EmailRequired,
    errorMessagesForContact,
} from '../Validators/errorMessagesForContact';

interface AddContactPageProps {
    contact: AddContactContactType;
    contactChanged: (_: AddContactContactType) => void;
    onDone: () => void;
    onPrevious: () => void;
}
function AddContactPage(props: AddContactPageProps) {
    const { contact, contactChanged, onPrevious, onDone } = props;
    const [forceValidation, setForceValidation] = useState(false);
    const errorMessages = errorMessagesForContact(
        contact,
        EmailRequired.EmailNotRequired
    );

    function isValid() {
        if (
            errorMessages.contactName ||
            errorMessages.emailAddress ||
            errorMessages.phoneNumber
        ) {
            return false;
        } else {
            return true;
        }
    }

    async function onNext() {
        if (!isValid()) {
            setForceValidation(true);
            return;
        }

        onDone();
    }

    return (
        <>
            <Label>
                Contact Details
                <ContactQuestionBubble context={LocationContext.Any} />
            </Label>
            <LargeContactInput
                contact={contact}
                contactChanged={contactChanged}
                forceValidation={forceValidation}
                errorMessages={errorMessages}
            ></LargeContactInput>
            <Spacer height={32} />
            <HorizontalStack width="100%" align="spread">
                <Button onClick={onPrevious} secondary>
                    Previous
                </Button>
                <Button onClick={onNext}>Next</Button>
            </HorizontalStack>
        </>
    );
}
export default AddContactPage;
