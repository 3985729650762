import Colors from 'Cargo/Colors';
import Dropdown, { dropdownDivider } from 'Cargo/Controls/Dropdown';
import { DropdownElement } from 'Cargo/Controls/DropdownElement';
import Input from 'Cargo/Controls/Input';
import Link from 'Cargo/Controls/Link';
import LinkButton from 'Cargo/Controls/LinkButton';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import { UUID } from 'Cargo/Types/types';
import {
    BuildingIcon,
    DefaultLocation,
    FavouriteStar,
} from 'Features/Locations/Components/LocationBox';
import { useFilterSavedLocations } from 'Features/Locations/Helpers/filterSavedLocation';
import {
    Location,
    LocationContext,
    SavedLocation,
} from 'generated-openapi-client';
import { formatPostalCode } from 'Helpers/formatPostalCode';
import React, { useState } from 'react';
import styled from 'styled-components/macro';

const LinkHolder = styled.div`
    margin-left: 16px;
    margin-top: 8px;
`;

interface SavedLocationsDropdownProps {
    savedLocationId: UUID;
    onSetSavedLocationId: (locationId: UUID, contactId: UUID) => void;
    savedLocations: Array<SavedLocation>;
    width?: number;
    onAddLocation: () => void;
    defaultLocationId: undefined | UUID;
    context: LocationContext;
}

const SavedLocationsDropdownItemTitle = styled.div`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 16px;
`;

const SavedLocationsDropdownItemDescription = styled.div`
    font-weight: var(--nhu-font-weight-regular);
    filter: brightness(80%);
    font-size: 12px;
`;

const SavedLocationsDropdown: React.FC<SavedLocationsDropdownProps> = (
    props: SavedLocationsDropdownProps
) => {
    const [filter, setFilter] = useState('');

    function summarizeAddress(location: Location) {
        if (!location.address) {
            throw new Error('Missing location.address');
        }
        return (
            <DropdownElement>
                <div style={{ paddingTop: '4px', paddingBottom: '4px' }}>
                    <SavedLocationsDropdownItemTitle>
                        {location.businessName}
                    </SavedLocationsDropdownItemTitle>
                    <SavedLocationsDropdownItemDescription>
                        {location.address.city},{' '}
                        {formatPostalCode(location.address.postalCode)},{' '}
                        {location.address.stateOrProvinceCode}
                    </SavedLocationsDropdownItemDescription>
                </div>
            </DropdownElement>
        );
    }

    const doFilter = useFilterSavedLocations(filter);

    function dropdownLocationOptions(locations: Array<SavedLocation>) {
        if (!locations) {
            return [];
        }

        const options = [];

        options.push({
            item: undefined,
            description: (
                <Input
                    type="text"
                    placeholder="Type to filter locations"
                    autoFocus
                    value={filter}
                    onChange={setFilter}
                />
            ),
            isHeader: true,
        });

        options.push(dropdownDivider());

        options.push({
            item: undefined,
            description: (
                <span>
                    Default {props.context} <DefaultLocation />
                </span>
            ),
            isHeader: true,
        });

        const defaultLocation = locations.find(
            (sl) => sl.savedLocationId === props.defaultLocationId
        );

        if (defaultLocation) {
            options.push({
                item: defaultLocation.savedLocationId,
                description: summarizeAddress(defaultLocation.location),
            });
        } else {
            options.push({
                item: undefined,
                description: (
                    <span style={{ color: Colors.LightText }}>
                        You do not has a default {props.context.toLowerCase()}{' '}
                        location set in your{' '}
                        <Link to="/address-book" newTab>
                            address book
                        </Link>
                    </span>
                ),
                // TODO: Header should be renamed
                isHeader: true,
            });
        }

        options.push(dropdownDivider());

        options.push({
            item: undefined,
            description: (
                <span>
                    Favourite Locations <FavouriteStar />
                </span>
            ),
            isHeader: true,
        });

        function filterOutDefaultLocation(sl: SavedLocation) {
            // Reject if there is alresdy a default location
            if (
                props.defaultLocationId &&
                sl.savedLocationId === props.defaultLocationId
            ) {
                return false;
            } else {
                return true;
            }
        }

        const favouriteLocations = locations
            .filter((sl) => sl.isFavourite)
            .filter(doFilter)
            .filter(filterOutDefaultLocation);

        if (favouriteLocations.length > 0) {
            favouriteLocations.forEach((i) =>
                options.push({
                    item: i.savedLocationId,
                    description: summarizeAddress(i.location),
                })
            );
        } else {
            options.push({
                item: undefined,
                description: (
                    <span style={{ color: Colors.LightText }}>
                        You do not have any locations marked as favourite in
                        your{' '}
                        <Link to="/address-book" newTab>
                            address book
                        </Link>
                    </span>
                ),
                // TODO: Header should be renamed
                isHeader: true,
            });
        }

        options.push(dropdownDivider());

        options.push({
            item: undefined,
            description: (
                <span>
                    Branch Locations <BuildingIcon />
                </span>
            ),
            isHeader: true,
        });

        const branchLocations = locations
            .filter((sl) => sl.isBranch)
            .filter(doFilter)
            .filter(filterOutDefaultLocation);

        if (branchLocations.length > 0) {
            branchLocations.forEach((i) =>
                options.push({
                    item: i.savedLocationId,
                    description: summarizeAddress(i.location),
                })
            );
        } else {
            options.push({
                item: undefined,
                description: (
                    <span style={{ color: Colors.LightText }}>
                        You do not have any locations marked as branches in your{' '}
                        <Link to="/address-book" newTab>
                            address book
                        </Link>
                    </span>
                ),
                // TODO: Header should be renamed
                isHeader: true,
            });
        }

        const otherLocations = locations
            .filter((sl) => !sl.isFavourite)
            .filter((sl) => !sl.isBranch)
            .filter(doFilter)
            .filter(filterOutDefaultLocation);

        if (otherLocations.length > 0) {
            options.push(dropdownDivider());
            options.push({
                item: undefined,
                description: 'Other Locations',
                isHeader: true,
            });

            otherLocations.forEach((i) =>
                options.push({
                    item: i.savedLocationId,
                    description: summarizeAddress(i.location),
                })
            );
        }

        return options;
    }

    function onOptionSelected(item: string) {
        if (item === 'add-new') {
            props.onAddLocation();
        } else {
            const location = props.savedLocations.find(
                (l) => l.savedLocationId === item
            );
            if (location === undefined) {
                throw new Error('Missing location');
            }

            props.onSetSavedLocationId(item, location.defaultSavedContactId);
        }
    }

    return (
        <>
            <HorizontalStack>
                <Dropdown
                    selectedItem={props.savedLocationId}
                    onOptionSelected={onOptionSelected}
                    options={dropdownLocationOptions(props.savedLocations)}
                    unselectedDescription="Select an address"
                    maxDropdownHeight="540px"
                    width={480}
                />
                <LinkHolder>
                    <LinkButton onClick={props.onAddLocation}>
                        or enter a new postal code
                    </LinkButton>
                </LinkHolder>
            </HorizontalStack>
        </>
    );
};
export default SavedLocationsDropdown;
