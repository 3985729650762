import Colors from 'Cargo/Colors';
import Button from 'Cargo/Controls/Button';
import Input from 'Cargo/Controls/Input';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { useModal } from 'Cargo/Modal/useModal';
import { Label, Microcopy, ModalTitle } from 'Cargo/Text/Text';
import { Partialize } from 'Cargo/Types/types';
import DangerousGoodsControl from 'Features/DangerousGoods/Components/DangerousGoodsControl';
import HandlingUnitTypeControl from 'Features/LineItems/Components/LineItem/HandlingUnitTypeControl';
import IsStackableControl from 'Features/LineItems/Components/LineItem/IsStackableControl';
import NmfcInput from 'Features/LineItems/Components/LineItem/NmfcInput';
import TemperatureHandlingControl from 'Features/LineItems/Components/LineItem/TemperatureHandlingControl';
import { useLookupNmfcCode } from 'Features/LineItems/Hooks/useLookupNmfcCode';
import { useNmfcDescription } from 'Features/LineItems/Slices/nmfcDescriptionCacheSlice';
import {
    HandlingUnitType,
    SavedCommodity,
    TemperatureHandling,
} from 'generated-openapi-client';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { errorMessagesForSavedCommodity } from '../Validators/errorMessagesForSavedCommodity';

interface AddModifySavedCommodityModalProps {
    onConfirm: (savedCommodity: SavedCommodity) => void;
    onCancel: () => void;
    savedCommodityToModify: SavedCommodity | undefined;
}

type AddSavedCommodityType = Partialize<
    SavedCommodity,
    'handlingUnitType' | 'isStackable'
>;

function emptySavedCommodity(): AddSavedCommodityType {
    return {
        savedCommodityId: uuidv4(),
        description: '',
        handlingUnitType: undefined,
        isDangerous: false,
        temperatureHandling: TemperatureHandling.NoSpecialHandling,
    };
}

function AddModifySavedCommodityModal(
    props: AddModifySavedCommodityModalProps
) {
    const [savedCommodity, setSavedCommodity] = useState<AddSavedCommodityType>(
        props.savedCommodityToModify || emptySavedCommodity()
    );
    const [forceValidation, setForceValidation] = useState(false);
    const { onCancel } = props;
    const {
        nmfcLookupInProgress,
        lookupNmfcCodeNoDimensions,
        nmfcErrorMessage,
        clearNmfcError,
        nmfcSubItems,
    } = useLookupNmfcCode();

    const nmfcDescription = useNmfcDescription(savedCommodity.nmfcItemNumber);

    const errorMessages = errorMessagesForSavedCommodity(savedCommodity);

    function isValid() {
        console.log(`anyErrorMessages`, { nmfcErrorMessage, errorMessages });
        const anyErrorMessages = [
            nmfcErrorMessage,
            errorMessages.handlingUnitType,
            errorMessages.description,
            errorMessages.isStackable,
        ].some((e) => e !== undefined);

        return !anyErrorMessages;
    }

    function onConfirm() {
        setForceValidation(true);

        if (!isValid()) {
            return;
        }

        props.onConfirm(savedCommodity as SavedCommodity);
    }

    function onHandlingUnitTypeChange(value: HandlingUnitType) {
        setSavedCommodity((sc) => ({
            ...sc,
            handlingUnitType: value,
        }));
    }

    function onDescriptionChange(value: string) {
        setSavedCommodity((sc) => ({ ...sc, description: value }));
    }

    async function onNmfcChange(newNmfcCode: string | undefined) {
        console.log(`!!!! onNmfcChange : ${newNmfcCode}`);
        if (newNmfcCode) {
            const response = await lookupNmfcCodeNoDimensions(newNmfcCode);

            console.log(`!!!! onNmfcChange - got response`, { response });

            if (response) {
                setSavedCommodity((sc) => ({
                    ...sc,
                    nmfcItemNumber: newNmfcCode,
                }));
            } else {
                // TODO: Handle this
            }
        } else {
            setSavedCommodity((sc) => ({ ...sc, nmfcItemNumber: undefined }));
        }
    }

    function onIsStackableChange(value: boolean) {
        setSavedCommodity((sc) => ({ ...sc, isStackable: value }));
    }

    function onTemperatureHandlingChange(value: TemperatureHandling) {
        setSavedCommodity((sc) => ({ ...sc, temperatureHandling: value }));
    }

    function onDangerousChange(value: Partial<AddSavedCommodityType>) {
        setSavedCommodity((sc) => ({ ...sc, ...value }));
    }

    return (
        <Stack align="left" id="AddModifySavedCommodityModal">
            <div
                style={{
                    width: '100%',
                    borderBottom: `1px solid ${Colors.VeryLightGray}`,
                    paddingBottom: '4px',
                    marginBottom: '32px',
                }}
            >
                <ModalTitle>Saved Commodity</ModalTitle>
                <Microcopy>
                    Enter details of something you ship often to speed up
                    requesting quotes
                </Microcopy>
            </div>

            <HorizontalStack verticalAlign="top">
                <Stack align="left">
                    <Label>Contents</Label>
                    <HandlingUnitTypeControl
                        value={savedCommodity.handlingUnitType}
                        onChange={onHandlingUnitTypeChange}
                        usePluralForm={true}
                        placeholder="Handling Unit Type"
                    />
                    <Spacer height={16} />
                    <Input
                        label="Description"
                        type="text"
                        name="contents_description"
                        value={savedCommodity.description}
                        onChange={onDescriptionChange}
                        width={349}
                        forceValidation={forceValidation}
                        errorMessage={errorMessages.description}
                        placeholder="Enter Description"
                    ></Input>
                    <NmfcInput
                        onChange={onNmfcChange}
                        nmfcCode={savedCommodity.nmfcItemNumber}
                        loading={nmfcLookupInProgress}
                        description={nmfcDescription}
                        confirmed={savedCommodity.nmfcItemNumber !== undefined}
                        errorMessage={nmfcErrorMessage}
                        clearError={clearNmfcError}
                        subItems={nmfcSubItems}
                    />
                </Stack>
                <Spacer width={32} />
                <Stack align="left">
                    <Label>Special Handling</Label>
                    <IsStackableControl
                        isStackable={savedCommodity.isStackable}
                        onChange={onIsStackableChange}
                        usePlural={true}
                        handlingUnitType={savedCommodity.handlingUnitType}
                        errorMessage={errorMessages.isStackable}
                        forceValidation={forceValidation}
                    />
                    <Spacer height={16} />
                    <TemperatureHandlingControl
                        temperatureHandling={savedCommodity.temperatureHandling}
                        onChange={onTemperatureHandlingChange}
                        errorMessage={errorMessages.temperatureHandling}
                        forceValidation={forceValidation}
                    />
                    <Spacer height={20} />
                    <DangerousGoodsControl
                        lineItem={savedCommodity}
                        onChange={function (update) {
                            onDangerousChange(update);
                        }}
                        errorMessage={undefined}
                        forceValidation={forceValidation}
                    />
                </Stack>
            </HorizontalStack>
            <Spacer height={32} />
            <HorizontalStack width="100%" align="right">
                <Button onClick={onCancel} secondary>
                    Cancel
                </Button>
                <Spacer width={16} />
                <Button onClick={onConfirm}>Confirm</Button>
            </HorizontalStack>
        </Stack>
    );
}

export function useAddModifySavedCommodityModal() {
    const showModal = useModal<SavedCommodity>();
    function show(savedCommodityToModify?: SavedCommodity) {
        const p = new Promise<SavedCommodity | undefined>((resolve) => {
            showModal(
                (done) => {
                    return (
                        <AddModifySavedCommodityModal
                            savedCommodityToModify={savedCommodityToModify}
                            onConfirm={done}
                            onCancel={function () {
                                done();
                            }}
                        />
                    );
                },
                (confirmed) => {
                    resolve(confirmed);
                },
                {
                    preventBackdropClick: true,
                }
            );
        });

        return p;
    }

    return show;
}
