import Input from 'Cargo/Controls/Input';
import PhoneInput from 'Cargo/Controls/PhoneInput';
import Form from 'Cargo/Layout/Form';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import { EmergencyContactDetails } from 'generated-openapi-client';
import React from 'react';
import { EmergencyContactDetailsErrors } from '../Validators/errorMessagesForEmergencyContact';

interface EmergencyContactInputProps {
    onUpdateEmergencyContactDetails: (
        _: Partial<EmergencyContactDetails>
    ) => void;
    emergencyContactDetails: Partial<EmergencyContactDetails>;
    errorMessages: EmergencyContactDetailsErrors;
    forceValidation: boolean;
}

export function EmergencyContactInput(props: EmergencyContactInputProps) {
    return (
        <>
            <Form.Group>
                <HorizontalStack width="520px" verticalAlign="top">
                    <Input
                        type="text"
                        name="emergency_contact_name"
                        label={`Contact Name`}
                        value={props.emergencyContactDetails.contactName}
                        onChange={(value: string) =>
                            props.onUpdateEmergencyContactDetails({
                                contactName: value,
                            })
                        }
                        required
                        errorMessage={props.errorMessages?.contactName}
                        forceValidation={props.forceValidation}
                        width={520}
                    ></Input>
                </HorizontalStack>
                <HorizontalStack width="520px" verticalAlign="top">
                    <PhoneInput
                        phoneNumber={
                            props.emergencyContactDetails.contactPhoneNumber
                        }
                        extension={
                            props.emergencyContactDetails
                                .contactPhoneNumberExtension
                        }
                        onPhoneNumberChange={(value: string) => {
                            props.onUpdateEmergencyContactDetails({
                                contactPhoneNumber: value,
                            });
                        }}
                        onExtensionChange={(value: string) => {
                            props.onUpdateEmergencyContactDetails({
                                contactPhoneNumberExtension: value,
                            });
                        }}
                        phoneNumberErrorMessage={
                            props.errorMessages.contactPhoneNumber
                        }
                        extensionErrorMessage={undefined}
                        forceValidation={props.forceValidation}
                    />
                </HorizontalStack>
            </Form.Group>
        </>
    );
}
