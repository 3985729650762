import { LargeIconButton } from 'Cargo/Controls/LargeIconButton';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { Label } from 'Cargo/Text/Text';
import { AccountType } from 'Features/Authentication/Hooks/useCreateAccount';
import { useIsAuthViewHeightToSmall } from 'Features/Authentication/Hooks/useIsAuthViewHeightTooSmall';
import { Dispatch, SetStateAction } from 'react';

interface CreateAccountPageAccountTypeProps {
    accountType: AccountType | undefined;
    setAccountType: Dispatch<SetStateAction<AccountType | undefined>>;
}

export function CreateAccountPageAccountType(
    props: CreateAccountPageAccountTypeProps
) {
    const { accountType, setAccountType } = props;
    const isViewHeightTooSmall = useIsAuthViewHeightToSmall();
    return (
        <Stack align="left" style={{ width: '100%' }}>
            <Label>
                First, are you shipping on behalf of an individual, a business,
                or a broker/logistics company?
            </Label>

            <Spacer height={32} />
            <HorizontalStack style={{ width: '100%', justifyContent: isViewHeightTooSmall ? "space-between" : "space-between"}}>
                <LargeIconButton
                    size={isViewHeightTooSmall ? 136 : 176}
                    isSelected={accountType == AccountType.Personal}
                    icon="person"
                    text="Personal Shipment"
                    onClick={function () {
                        setAccountType(AccountType.Personal);
                    }}
                />
                <LargeIconButton
                    size={isViewHeightTooSmall ? 136 : 176}
                    isSelected={accountType == AccountType.Business}
                    icon="industry"
                    text="Business Shipments"
                    onClick={function () {
                        setAccountType(AccountType.Business);
                    }}
                />
                <LargeIconButton
                    size={isViewHeightTooSmall ? 136 : 176}
                    isSelected={accountType == AccountType.Broker}
                    icon="truck"
                    text="Logistics Company"
                    onClick={function () {
                        setAccountType(AccountType.Broker);
                    }}
                />
            </HorizontalStack>
        </Stack>
    );
}
