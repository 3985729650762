/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryCode } from './CountryCode';
import {
    CountryCodeFromJSON,
    CountryCodeFromJSONTyped,
    CountryCodeToJSON,
} from './CountryCode';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    stateOrProvinceCode: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    postalCode: string;
    /**
     * 
     * @type {CountryCode}
     * @memberof Address
     */
    countryCode: CountryCode;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    addressLine: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    addressLine2?: string;
}

/**
 * Check if a given object implements the Address interface.
 */
export function instanceOfAddress(value: object): boolean {
    if (!('city' in value)) return false;
    if (!('stateOrProvinceCode' in value)) return false;
    if (!('postalCode' in value)) return false;
    if (!('countryCode' in value)) return false;
    if (!('addressLine' in value)) return false;
    return true;
}

export function AddressFromJSON(json: any): Address {
    return AddressFromJSONTyped(json, false);
}

export function AddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): Address {
    if (json == null) {
        return json;
    }
    return {
        
        'city': json['city'],
        'stateOrProvinceCode': json['stateOrProvinceCode'],
        'postalCode': json['postalCode'],
        'countryCode': CountryCodeFromJSON(json['countryCode']),
        'addressLine': json['addressLine'],
        'addressLine2': json['addressLine2'] == null ? undefined : json['addressLine2'],
    };
}

export function AddressToJSON(value?: Address | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'city': value['city'],
        'stateOrProvinceCode': value['stateOrProvinceCode'],
        'postalCode': value['postalCode'],
        'countryCode': CountryCodeToJSON(value['countryCode']),
        'addressLine': value['addressLine'],
        'addressLine2': value['addressLine2'],
    };
}

