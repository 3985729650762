import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import { useAuthentication } from 'Features/Authentication/Slices/authenticationSlice';
import React from 'react';
import SignedInRightNavMenu from './SignedInRightNavMenu';
import SignedOutRightNavMenu from './SignedOutRightNavMenu';

export function NavRightSide() {
    const { isAuthenticated } = useAuthentication();
    return (
        <HorizontalStack align="right">
            {isAuthenticated ? (
                <SignedInRightNavMenu />
            ) : (
                <SignedOutRightNavMenu />
            )}
        </HorizontalStack>
    );
}
