/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';
import type { EquipmentType } from './EquipmentType';
import {
    EquipmentTypeFromJSON,
    EquipmentTypeFromJSONTyped,
    EquipmentTypeToJSON,
} from './EquipmentType';
import type { LineItem } from './LineItem';
import {
    LineItemFromJSON,
    LineItemFromJSONTyped,
    LineItemToJSON,
} from './LineItem';
import type { PreQuoteLocation } from './PreQuoteLocation';
import {
    PreQuoteLocationFromJSON,
    PreQuoteLocationFromJSONTyped,
    PreQuoteLocationToJSON,
} from './PreQuoteLocation';

/**
 * 
 * @export
 * @interface CreateShipment2Request
 */
export interface CreateShipment2Request {
    /**
     * 
     * @type {string}
     * @memberof CreateShipment2Request
     */
    shipmentId: string;
    /**
     * 
     * @type {EquipmentType}
     * @memberof CreateShipment2Request
     */
    equipmentType?: EquipmentType;
    /**
     * 
     * @type {string}
     * @memberof CreateShipment2Request
     */
    pickupDate: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipment2Request
     */
    deliveryDeadline?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipment2Request
     */
    pickupDeadline?: string;
    /**
     * 
     * @type {PreQuoteLocation}
     * @memberof CreateShipment2Request
     */
    pickupLocation: PreQuoteLocation;
    /**
     * 
     * @type {PreQuoteLocation}
     * @memberof CreateShipment2Request
     */
    deliveryLocation: PreQuoteLocation;
    /**
     * 
     * @type {Array<LineItem>}
     * @memberof CreateShipment2Request
     */
    lineItems: Array<LineItem>;
    /**
     * 
     * @type {string}
     * @memberof CreateShipment2Request
     */
    pickupReferenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipment2Request
     */
    deliveryReferenceNumber: string;
    /**
     * 
     * @type {Contact}
     * @memberof CreateShipment2Request
     */
    pickupContact?: Contact;
    /**
     * 
     * @type {Contact}
     * @memberof CreateShipment2Request
     */
    deliveryContact?: Contact;
    /**
     * 
     * @type {boolean}
     * @memberof CreateShipment2Request
     */
    addInsuranceToShipment: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateShipment2Request
     */
    insuranceAmount: number;
    /**
     * 
     * @type {Currency}
     * @memberof CreateShipment2Request
     */
    insuranceCurrency: Currency;
    /**
     * 
     * @type {string}
     * @memberof CreateShipment2Request
     */
    branchId?: string;
}

/**
 * Check if a given object implements the CreateShipment2Request interface.
 */
export function instanceOfCreateShipment2Request(value: object): boolean {
    if (!('shipmentId' in value)) return false;
    if (!('pickupDate' in value)) return false;
    if (!('pickupLocation' in value)) return false;
    if (!('deliveryLocation' in value)) return false;
    if (!('lineItems' in value)) return false;
    if (!('pickupReferenceNumber' in value)) return false;
    if (!('deliveryReferenceNumber' in value)) return false;
    if (!('addInsuranceToShipment' in value)) return false;
    if (!('insuranceAmount' in value)) return false;
    if (!('insuranceCurrency' in value)) return false;
    return true;
}

export function CreateShipment2RequestFromJSON(json: any): CreateShipment2Request {
    return CreateShipment2RequestFromJSONTyped(json, false);
}

export function CreateShipment2RequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateShipment2Request {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentId': json['shipmentId'],
        'equipmentType': json['equipmentType'] == null ? undefined : EquipmentTypeFromJSON(json['equipmentType']),
        'pickupDate': json['pickupDate'],
        'deliveryDeadline': json['deliveryDeadline'] == null ? undefined : json['deliveryDeadline'],
        'pickupDeadline': json['pickupDeadline'] == null ? undefined : json['pickupDeadline'],
        'pickupLocation': PreQuoteLocationFromJSON(json['pickupLocation']),
        'deliveryLocation': PreQuoteLocationFromJSON(json['deliveryLocation']),
        'lineItems': ((json['lineItems'] as Array<any>).map(LineItemFromJSON)),
        'pickupReferenceNumber': json['pickupReferenceNumber'],
        'deliveryReferenceNumber': json['deliveryReferenceNumber'],
        'pickupContact': json['pickupContact'] == null ? undefined : ContactFromJSON(json['pickupContact']),
        'deliveryContact': json['deliveryContact'] == null ? undefined : ContactFromJSON(json['deliveryContact']),
        'addInsuranceToShipment': json['addInsuranceToShipment'],
        'insuranceAmount': json['insuranceAmount'],
        'insuranceCurrency': CurrencyFromJSON(json['insuranceCurrency']),
        'branchId': json['branchId'] == null ? undefined : json['branchId'],
    };
}

export function CreateShipment2RequestToJSON(value?: CreateShipment2Request | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentId': value['shipmentId'],
        'equipmentType': EquipmentTypeToJSON(value['equipmentType']),
        'pickupDate': value['pickupDate'],
        'deliveryDeadline': value['deliveryDeadline'],
        'pickupDeadline': value['pickupDeadline'],
        'pickupLocation': PreQuoteLocationToJSON(value['pickupLocation']),
        'deliveryLocation': PreQuoteLocationToJSON(value['deliveryLocation']),
        'lineItems': ((value['lineItems'] as Array<any>).map(LineItemToJSON)),
        'pickupReferenceNumber': value['pickupReferenceNumber'],
        'deliveryReferenceNumber': value['deliveryReferenceNumber'],
        'pickupContact': ContactToJSON(value['pickupContact']),
        'deliveryContact': ContactToJSON(value['deliveryContact']),
        'addInsuranceToShipment': value['addInsuranceToShipment'],
        'insuranceAmount': value['insuranceAmount'],
        'insuranceCurrency': CurrencyToJSON(value['insuranceCurrency']),
        'branchId': value['branchId'],
    };
}

