/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { Hours } from './Hours';
import {
    HoursFromJSON,
    HoursFromJSONTyped,
    HoursToJSON,
} from './Hours';
import type { LatitudeLongitude } from './LatitudeLongitude';
import {
    LatitudeLongitudeFromJSON,
    LatitudeLongitudeFromJSONTyped,
    LatitudeLongitudeToJSON,
} from './LatitudeLongitude';
import type { LocationType } from './LocationType';
import {
    LocationTypeFromJSON,
    LocationTypeFromJSONTyped,
    LocationTypeToJSON,
} from './LocationType';

/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {LocationType}
     * @memberof Location
     */
    locationType: LocationType;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    businessName: string;
    /**
     * 
     * @type {Address}
     * @memberof Location
     */
    address: Address;
    /**
     * 
     * @type {Hours}
     * @memberof Location
     */
    hours: Hours;
    /**
     * 
     * @type {LatitudeLongitude}
     * @memberof Location
     */
    latitudeLongitude: LatitudeLongitude;
    /**
     * 
     * @type {Array<string>}
     * @memberof Location
     */
    accessorials: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    notes: string;
}

/**
 * Check if a given object implements the Location interface.
 */
export function instanceOfLocation(value: object): boolean {
    if (!('locationType' in value)) return false;
    if (!('businessName' in value)) return false;
    if (!('address' in value)) return false;
    if (!('hours' in value)) return false;
    if (!('latitudeLongitude' in value)) return false;
    if (!('accessorials' in value)) return false;
    if (!('notes' in value)) return false;
    return true;
}

export function LocationFromJSON(json: any): Location {
    return LocationFromJSONTyped(json, false);
}

export function LocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Location {
    if (json == null) {
        return json;
    }
    return {
        
        'locationType': LocationTypeFromJSON(json['locationType']),
        'businessName': json['businessName'],
        'address': AddressFromJSON(json['address']),
        'hours': HoursFromJSON(json['hours']),
        'latitudeLongitude': LatitudeLongitudeFromJSON(json['latitudeLongitude']),
        'accessorials': json['accessorials'],
        'notes': json['notes'],
    };
}

export function LocationToJSON(value?: Location | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'locationType': LocationTypeToJSON(value['locationType']),
        'businessName': value['businessName'],
        'address': AddressToJSON(value['address']),
        'hours': HoursToJSON(value['hours']),
        'latitudeLongitude': LatitudeLongitudeToJSON(value['latitudeLongitude']),
        'accessorials': value['accessorials'],
        'notes': value['notes'],
    };
}

