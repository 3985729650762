import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthorizationApi2 } from 'Apis/AuthorizationApi2';
import auth0 from 'auth0-js';
import { UUID } from 'Cargo/Types/types';
import { apiServerUrl, auth0Creds, cookieDomain } from 'environment';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

export interface User {
    userId: string;
    email: string;
    name: string;
    companyName: string;
    companyId: UUID;
}

// Be careful when adding to the slice, as they might be serialized into local storage. So new field might be missing.
export interface AuthenticationSliceType {
    accessToken?: string;
    user?: User;
    redirectUrl?: string;
    emailAddressForReset: string;
}

export function emptyAuthenticationSlice(): AuthenticationSliceType {
    return {
        emailAddressForReset: '',
    };
}

function initialState(allowReload: boolean): AuthenticationSliceType {
    const serializedState = localStorage.getItem('authentication');
    if (serializedState && allowReload) {
        try {
            return JSON.parse(serializedState);
        } catch (e) {
            console.warn('Failed to parse serialized authenticationSlice', {
                e,
            });
            return emptyAuthenticationSlice();
        }
    } else {
        return emptyAuthenticationSlice();
    }
}

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: initialState(true),
    reducers: {
        storeAuthentication(
            state,
            action: PayloadAction<{
                accessToken: string;
                user: User;
            }>
        ) {
            state.accessToken = action.payload.accessToken;
            state.user = action.payload.user;
        },

        clearStoredAuthentication() {
            return emptyAuthenticationSlice();
        },

        setRedirectUrl(state, action: PayloadAction<string>) {
            state.redirectUrl = action.payload;
        },

        setEmailAddressForReset(state, action: PayloadAction<string>) {
            state.emailAddressForReset = action.payload;
        },
    },
});

export const {
    storeAuthentication,
    clearStoredAuthentication,
    setRedirectUrl,
    setEmailAddressForReset,
} = authenticationSlice.actions;

export const writeAuthenticationSliceToLocalStorage = (
    state: AuthenticationSliceType
) => {
    localStorage.setItem('authentication', JSON.stringify(state));
};

export const useAuthentication = () => {
    const [, setCookie, removeCookie] = useCookies(['fsSignedIn']);
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(
        (state: RootState) => state.authentication.accessToken !== undefined
    );

    const accessToken =
        useSelector((state: RootState) => state.authentication.accessToken) ??
        '';

    const redirectUrl = useSelector((state: RootState) => {
        return state.authentication.redirectUrl;
    });

    const user = useSelector((state: RootState) => state.authentication.user);

    if (isAuthenticated && !accessToken) {
        throw new Error('Authenticated, but missing accessToken');
    }

    if (isAuthenticated && !user) {
        throw new Error('Authenticated, but missing user');
    }

    const signOut = () => {
        const auth = new auth0.WebAuth({
            domain: auth0Creds().domain,
            clientID: auth0Creds().clientId,
            scope: 'openid profile email',
        });

        // When we sign in, set a cookie so the marketing pages can pick up if we are logged in
        console.log('Removing cookie fsSignedIn');
        removeCookie('fsSignedIn', {
            domain: cookieDomain(),
        });

        dispatch(clearStoredAuthentication());

        auth.logout({});
    };

    const authenticate = async (accessToken: string, idToken: string) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const parsedIdToken: any = jwt_decode(idToken);

        const userId = parsedIdToken.sub;
        const email = parsedIdToken.email;
        const name = parsedIdToken['https://freightsimple.net/name'];
        const companyName =
            parsedIdToken['https://freightsimple.net/companyName'];
        const companyId = parsedIdToken['https://freightsimple.net/companyId'];

        // When we sign in, set a cookie so the marketing pages can pick up if we are logged in
        setCookie('fsSignedIn', true, {
            expires: moment().add(1, 'year').toDate(),
            domain: cookieDomain(),
            secure: true,
        });

        dispatch(
            storeAuthentication({
                accessToken,
                user: {
                    userId,
                    email,
                    name,
                    companyName,
                    companyId,
                },
            })
        );
    };

    // Do a deep check on the token to make sure it's still valid
    async function checkAuthenticated(): Promise<boolean> {
        try {
            if (!isAuthenticated) {
                return false;
            }

            const status = await new AuthorizationApi2(
                apiServerUrl(),
                accessToken
            ).postAuthorizationVerifyToken();

            return status === 200;
        } catch (e) {
            console.error(`checkAuthenticated - ${e}`);
            return false;
        }
    }

    // This is used to verify the token and log the user out if the token is
    // no longer valid
    const verifyToken = async () => {
        console.log('!!! verifyToken implementation');
        if (!isAuthenticated) {
            return;
        }

        const status = await new AuthorizationApi2(
            apiServerUrl(),
            accessToken
        ).postAuthorizationVerifyToken();

        if (status === 401) {
            console.warn('Signing out because authorization api failed', {
                status,
            });
            signOut();
        }
    };

    return {
        // Are we currently authed? Doesn't double-check with auth0 though
        isAuthenticated,
        // Deeper check - checks with auth0 so we can be really confident
        checkAuthenticated,

        accessToken,
        user,
        authenticate,
        signOut,
        verifyToken,
        redirectUrl,
    };
};

export default authenticationSlice.reducer;
