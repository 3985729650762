import { ShipmentState } from 'generated-openapi-client';

export function nameForShipmentState(state: ShipmentState): string {
    switch (state) {
        case ShipmentState.Quoted:
            return 'Quoted';
        case ShipmentState.QuoteRequested:
            return 'Quoted';
        case ShipmentState.BookingConfirmed:
            return 'Booked';
        case ShipmentState.BookingRequested:
            return 'Preparing';
        case ShipmentState.BookingFailed:
            return 'Preparing';
        case ShipmentState.InTransit:
            return 'In Transit';
        case ShipmentState.Delivered:
            return 'Delivered';
        case ShipmentState.Cancelled:
            return 'Cancelled';
        case ShipmentState.Lost:
            return 'Lost';
        case ShipmentState.OnHold:
            return 'On Hold'
    }
}
