import Animation from 'Cargo/Animations/Animation';
import Button from 'Cargo/Controls/Button';
import Input from 'Cargo/Controls/Input';
import LinkButton from 'Cargo/Controls/LinkButton';
import Box from 'Cargo/Layout/Box';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { ErrorMessage, H2, Label, Legalese, Microcopy } from 'Cargo/Text/Text';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useRequestResetPassword } from './Hooks/useRequestResetPassword';

function RequestResetPasswordScreen() {
    const emailAddressForReset = useSelector(
        (state: RootState) => state.authentication.emailAddressForReset
    );

    const [email, setEmail] = useState(emailAddressForReset);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const { requestReset } = useRequestResetPassword();
    const [loading, setLoading] = useState(false);
    const [wasSuccess, setWasSuccess] = useState(false);

    async function onRequestResetPassword() {
        setLoading(true);
        const success = await requestReset(email);

        if (!success) {
            setErrorMessage('Something went wrong. Please try again');
        } else {
            setWasSuccess(true);
        }
        setLoading(false);
    }

    if (wasSuccess) {
        return (
            <Stack align="center" width="100%">
                <H2>Reset Password Email Sent</H2>
                <Microcopy>
                    Please check your email to find the next steps for resetting
                    your password
                </Microcopy>
                <Legalese>
                    (and please check your spam if you can&apos;t find it at
                    first)
                </Legalese>
                <Animation
                    pathToAnimation="/animations/email-sent.json"
                    lottieFilesUrl="https://lottiefiles.com/6391-email-sent"
                    loop={true}
                    speed={0.5}
                />
            </Stack>
        );
    }

    return (
        <Stack align="center" width="100%" style={{ marginTop: '32px' }}>
            <Box>
                <div style={{ padding: '48px' }}>
                    <Stack align="left" width="429px">
                        <H2>Request Password Reset</H2>
                        <Microcopy>
                            Enter your email address below. If an account
                            exists, you will receive an email containing
                            instructions on how to reset your password.
                        </Microcopy>
                        <Spacer height={56} />

                        <Label>Email</Label>
                        <Input
                            type="text"
                            value={email}
                            onChange={(value) => setEmail(value)}
                            width={429}
                            errorMessage={undefined}
                            forceValidation={true}
                            autoComplete="email"
                            name="forgot_password_email"
                        />
                    </Stack>
                    <Spacer height={32} />
                    <Button
                        onClick={onRequestResetPassword}
                        label="Request Reset Password"
                        width="300px"
                        size="large"
                        loading={loading}
                    />
                    <Spacer height={16} />
                    {errorMessage && (
                        <ErrorMessage>{errorMessage}</ErrorMessage>
                    )}
                    <Spacer height={16} />
                    <LinkButton to="/sign-in">Back to sign in</LinkButton>
                </div>
            </Box>
        </Stack>
    );
}
export default RequestResetPasswordScreen;
