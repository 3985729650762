import { useWidth } from 'Cargo/Helpers/useWidth';
import React, { ReactNode } from 'react';
import { animated, config, useSpring } from '@react-spring/web';

interface SlideRightProps {
    children: ReactNode;
    show: boolean;
}

function SlideRight(props: SlideRightProps) {
    const [widthRef] = useWidth();
    const slideInStyles = useSpring({
        config: { ...config.stiff },
        from: { opacity: 0, width: 0 },
        to: {
            opacity: props.show ? 1 : 0,
            width: props.show ? 200 : 0, // This 200 is hardcoded, 'width' variable didn't seem to work
        },
    });
    return (
        <animated.div
            style={{ ...slideInStyles, overflow: 'hidden', height: '100%' }}
        >
            <div ref={widthRef}>{props.children}</div>
        </animated.div>
    );
}
export default SlideRight;
