import Animation from 'Cargo/Animations/Animation';
import Button from 'Cargo/Controls/Button';
import Input from 'Cargo/Controls/Input';
import LinkButton from 'Cargo/Controls/LinkButton';
import Box from 'Cargo/Layout/Box';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { ErrorMessage, H2, Label, Microcopy } from 'Cargo/Text/Text';
import { ErrorMessageType } from 'Cargo/Validation';
import useQuery from 'Hooks/useQuery';
import { useState } from 'react';
import { useResetPassword } from './Hooks/useResetPassword';
import { useSignInDirectly } from './Hooks/useSignIn';

function ResetPasswordScreen() {
    const { resetPassword, resetErrorMessage } = useResetPassword();
    const [signInErrorMessage, setSignInErrorMessage] = useState<ErrorMessageType>();
    const [newPassword, setNewPassword] = useState('');
    const [accessToken, setAccessToken] = useState('');
    const [idToken, setIdToken] = useState('');
    const [loading, setLoading] = useState(false);
    const query = useQuery();
    const [wasSuccess, setWasSuccess] = useState(false);

    const { signIn, continueAfterReset } = useSignInDirectly();

    const email = query.email as string;
    const token = query.token as string;

    async function onResetPassword() {
        setLoading(true);
        const success = await resetPassword(email, token, newPassword);

        if (success) {
            try {
                const { accessToken: _accessToken, idToken: _idToken } =
                    await signIn(email, newPassword);
                setAccessToken(_accessToken);
                setIdToken(_idToken);

                setWasSuccess(true);
            } catch (errorMessage) {
                // Should probably be throwing/catching an Error rather than a string?
                setSignInErrorMessage(errorMessage as string | undefined);
            }
        }
        setLoading(false);
    }

    function onContinueAfterReset() {
        continueAfterReset(accessToken, idToken);
    }

    if (wasSuccess) {
        return (
            <Stack align="center" width="100%">
                <H2>Password Successfully Reset!</H2>
                <Microcopy>You can now continue to use FreightSimple</Microcopy>
                <Animation
                    pathToAnimation="/animations/success-blue.json"
                    lottieFilesUrl="https://lottiefiles.com/4431-success"
                    loop={true}
                    speed={0.5}
                />
                <Button
                    onClick={onContinueAfterReset}
                    label="Continue"
                    width="300px"
                    size="large"
                />
            </Stack>
        );
    }

    return (
        <Stack align="center" width="100%">
            <Box>
                <div style={{ padding: '48px' }}>
                    <Stack align="left" width="429px">
                        <H2>Reset your password</H2>
                        <Spacer height={56} />

                        <Label>New Password</Label>
                        <Input
                            type="password"
                            value={newPassword}
                            onChange={(value) => setNewPassword(value)}
                            width={429}
                            errorMessage={undefined}
                            forceValidation={true}
                            autoComplete="new-password"
                            name="new_password"
                        />
                    </Stack>
                    <Spacer height={32} />
                    <Button
                        onClick={onResetPassword}
                        label="Reset Password"
                        width="300px"
                        size="large"
                        loading={loading}
                    />
                    <Spacer height={16} />
                    {resetErrorMessage && (
                        <ErrorMessage>{resetErrorMessage}</ErrorMessage>
                    )}
                    {signInErrorMessage && (
                        <ErrorMessage>{signInErrorMessage}</ErrorMessage>
                    )}
                    <Spacer height={16} />
                    <LinkButton to="/sign-in">Back to sign in</LinkButton>
                </div>
            </Box>
        </Stack>
    );
}
export default ResetPasswordScreen;
