import {
    LocationContext,
    PreBookingShipment,
    SavedLocation,
} from 'generated-openapi-client';
import React from 'react';
import AddressScreenComponent from './Components/AddressScreenComponent';

interface BookShipmentDeliveryAddressScreenProps {
    shipment: PreBookingShipment;
    savedLocations: Array<SavedLocation>;
    loading: boolean;
    onComplete: () => void;
    nextIdentifier: string;
    onPrevious: () => Promise<void>;
    markLocationAsFavourite: boolean;
    onMarkLocationAsFavourite: (newValue: boolean) => void;
    onModify: () => void;
}

function BookShipmentDeliveryAddressScreen(
    props: BookShipmentDeliveryAddressScreenProps
) {
    return (
        <AddressScreenComponent
            identifier={'delivery-address'}
            savedLocations={props.savedLocations}
            context={LocationContext.Delivery}
            shipment={props.shipment}
            nextIdentifier={props.nextIdentifier}
            onPrevious={props.onPrevious}
            loading={props.loading}
            onComplete={props.onComplete}
            markLocationAsFavourite={props.markLocationAsFavourite}
            onMarkLocationAsFavourite={props.onMarkLocationAsFavourite}
            onModify={props.onModify}
            isSameDayPickup={false}
        />
    );
}

export default BookShipmentDeliveryAddressScreen;
