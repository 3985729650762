/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HasBookedOnlyOneShipmentResponse
 */
export interface HasBookedOnlyOneShipmentResponse {
    /**
     * 
     * @type {boolean}
     * @memberof HasBookedOnlyOneShipmentResponse
     */
    hasBookedOnlyOneShipment: boolean;
}

/**
 * Check if a given object implements the HasBookedOnlyOneShipmentResponse interface.
 */
export function instanceOfHasBookedOnlyOneShipmentResponse(value: object): boolean {
    if (!('hasBookedOnlyOneShipment' in value)) return false;
    return true;
}

export function HasBookedOnlyOneShipmentResponseFromJSON(json: any): HasBookedOnlyOneShipmentResponse {
    return HasBookedOnlyOneShipmentResponseFromJSONTyped(json, false);
}

export function HasBookedOnlyOneShipmentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HasBookedOnlyOneShipmentResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'hasBookedOnlyOneShipment': json['hasBookedOnlyOneShipment'],
    };
}

export function HasBookedOnlyOneShipmentResponseToJSON(value?: HasBookedOnlyOneShipmentResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'hasBookedOnlyOneShipment': value['hasBookedOnlyOneShipment'],
    };
}

