import {
    QuestionBubbleHeader,
    QuestionBubbleLabel,
    SmallQuestionBubble,
} from 'Cargo/Controls/QuestionBubble';
import Spacer from 'Cargo/Layout/Spacer';
import React from 'react';

function PredictedLatestDeliveryDateQuestionBubble() {
    return (
        <SmallQuestionBubble
            description={
                <>
                    <QuestionBubbleHeader>
                        Predicted Latest Delivery Date
                    </QuestionBubbleHeader>
                    <QuestionBubbleLabel>
                        <div>
                            When a quote is generated, FreightSimple
                            automatically analyzes data from thousands of
                            shipments, and predicts a realistic window for when
                            your freight will deliver based on the carrier,
                            service type, industry capacity, and locations
                            involved.
                        </div>
                        <Spacer height={16} />
                        <div>
                            We believe 99% of shipments will deliver within this
                            date. A realistic delivery window can help you plan
                            your business better.
                        </div>
                    </QuestionBubbleLabel>
                </>
            }
            learnMoreUrl="https://help.freightsimple.com/using-freightsimple/tracking/when-will-my-shipment-deliver"
        />
    );
}
export default PredictedLatestDeliveryDateQuestionBubble;
