import NewLocationDetails from 'Features/BookShipment/Components/Location/NewLocationDetails';
import { warningMessagesForDeliveryLocation } from 'Features/Locations/Validators/errorMessagesForDeliveryLocation';
import { errorMessagesForLocation } from 'Features/Locations/Validators/errorMessagesForLocation';
import { LocationContext } from 'generated-openapi-client/models/LocationContext';
import { Shipment } from 'generated-openapi-client/models/Shipment';

interface DeliveryDetailsPageProp {
    shipment: Shipment;
}

export function DeliveryDetailsPage(props: DeliveryDetailsPageProp) {
    const errorMessages = errorMessagesForLocation(
        props.shipment.deliveryLocation,
        false
    );
    const warningMessages = warningMessagesForDeliveryLocation(
        props.shipment.pickupLocation,
        props.shipment.deliveryLocation
    );

    return (
        <NewLocationDetails
            locationErrorMessages={errorMessages}
            locationWarningMessages={warningMessages}
            context={LocationContext.Delivery}
            setLocation={() => {}}
            location={props.shipment.deliveryLocation}
            forceValidation={false}
            disablePostalCodeEntry={false}
            pickupDate={props.shipment.pickupDate}
        />
    );
}
