import {
    QuestionBubbleHeader,
    QuestionBubbleLabel,
    QuestionBubbleLabelStrong,
    SmallQuestionBubble,
} from 'Cargo/Controls/QuestionBubble';
import React from 'react';
import {
    AccessorialQuestionBubble,
    AccessorialQuestionBubbleProps,
} from './AccessorialQuestionBubbleProps';
import { describeLocationContext } from 'Helpers/describeLocationContext';

const AppointmentRequiredQuestionBubble: AccessorialQuestionBubble = (
    props: AccessorialQuestionBubbleProps
) => {
    return (
        <SmallQuestionBubble
            description={
                <>
                    <QuestionBubbleHeader>
                        {`Delivery appointment required at ${describeLocationContext(props.locationContext)}`}
                    </QuestionBubbleHeader>
                    <QuestionBubbleLabel>
                        {`Check this box if you need the carrier to deliver within a specific time window or two hours or less. They will contact the delivery contact to confirm the time.`}
                    </QuestionBubbleLabel>
                    <QuestionBubbleLabelStrong>
                        {`Please note - if the delivery contact requests a delivery appointment that is a significant number of days after the freight arrives at the delivery terminal, storage fees may apply.`}
                    </QuestionBubbleLabelStrong>
                </>
            }
            learnMoreUrl="https://help.freightsimple.com/using-freightsimple/quoting/pickup-and-delivery-details#delivery-appointment"
        />
    );
};
export default AppointmentRequiredQuestionBubble;
