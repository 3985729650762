import { useUsersApi2 } from 'apis';
import { ErrorMessageType } from 'Cargo/Validation';
import {
    errorMessageForEmail,
    errorMessageForPhoneNumber,
} from 'Features/Contacts/Validators/errorMessagesForContact';
import { CreateAccountException } from 'generated-openapi-client';
import React, { useState } from 'react';
import PasswordStrengthBubble from '../Components/QuestionBubbles/PasswordStrengthBubble';

interface AcceptInviteErrors {
    email: ErrorMessageType;
    password: ErrorMessageType;
    name: ErrorMessageType;
    generateErrorMessage: ErrorMessageType;
}

export function useAcceptInvitation(
    suggestedEmail: string,
    suggestedName: string
) {
    const [email, setEmail] = useState(suggestedEmail);
    const [password, setPassword] = useState('');
    const [name, setName] = useState(suggestedName);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberExtension, setPhoneNumberExtension] = useState('');

    const [additionalErrorMessages, setAdditionalErrorMessages] = useState<
        Partial<AcceptInviteErrors>
    >({});

    const [generalErrorMessage, setGeneralErrorMessage] =
        useState<ErrorMessageType>();
    const [acceptInvitationInProgress, setCreateAccountInProgress] =
        useState(false);
    const usersApi = useUsersApi2();

    const baseErrorMessages = {
        email: errorMessageForEmail(email),
        password: password.length > 0 ? undefined : 'Required',
        name: name.length > 0 ? undefined : 'Required',
        phoneNumber: errorMessageForPhoneNumber(phoneNumber),
    };

    const isValid =
        baseErrorMessages.email === undefined &&
        password.length > 0 &&
        baseErrorMessages.phoneNumber === undefined &&
        name.length > 0;

    const errorMessages = {
        ...baseErrorMessages,
        ...additionalErrorMessages,
    };

    async function acceptInvitation(inviteToken: string): Promise<boolean> {
        setAdditionalErrorMessages({});
        setCreateAccountInProgress(true);
        setGeneralErrorMessage(undefined);

        const request = {
            emailAddress: email,
            name,
            password,
            inviteToken,
            phoneNumber,
            phoneNumberExtension,
        };

        try {
            await usersApi.postUsersAcceptInvitation(request);
        } catch (error) {
            const e = error as CreateAccountException;
            console.log(`got e`, { e, error });
            if (e.message.toLowerCase().includes('password')) {
                setAdditionalErrorMessages({
                    password: (
                        <>
                            {e.message}
                            <PasswordStrengthBubble />
                        </>
                    ),
                });
            } else if (e.message.toLowerCase().includes('email')) {
                setAdditionalErrorMessages({
                    email: <>{e.message}</>,
                });
            } else {
                setGeneralErrorMessage(e.message);
            }

            setCreateAccountInProgress(false);
            return false;
        }

        // await signIn(email, password);
        setCreateAccountInProgress(false);
        return true;
    }

    return {
        email,
        setEmail,
        password,
        setPassword,
        name,
        setName,
        phoneNumber,
        setPhoneNumber,
        phoneNumberExtension,
        setPhoneNumberExtension,
        generalErrorMessage,
        acceptInvitation,
        acceptInvitationInProgress,
        isValid,
        errorMessages,
    };
}
