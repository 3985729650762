import axios from 'axios';
import {
    Configuration,
    OpenFreightClaimRequest,
    FreightClaimsApi as OpenAPIFreightClaimsApi,
} from 'generated-openapi-client';

export class FreightClaimsApi extends OpenAPIFreightClaimsApi {
    constructor(configuration: Configuration) {
        super(configuration);
    }

    async upload(
        request: OpenFreightClaimRequest,
        originalInvoiceFiles: Array<File>,
        photosFiles: Array<File>,
        otherDocumentsFiles: Array<File>
    ): Promise<void> {
        console.log('claims.config', this.configuration);

        let tokenString: string | null = null;

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            tokenString = await token('JWT', []);
        }

        if (!tokenString) {
            console.error('No authorization token');
            return;
        }

        const options = {
            headers: {
                Authorization: `Bearer ${tokenString}`,
                'Content-Type': 'multipart/form-data',
            },
        };

        const formData = new FormData();

        originalInvoiceFiles.forEach(function (file, index) {
            console.log(`Appending ${index} ${file.name} ${file.size}`);
            formData.append(`originalInvoiceFile${index}`, file, file.name);
        });

        photosFiles.forEach(function (file, index) {
            console.log(`Appending ${index} ${file.name} ${file.size}`);
            formData.append(`photosFile${index}`, file, file.name);
        });

        otherDocumentsFiles.forEach(function (file, index) {
            console.log(`Appending ${index} ${file.name} ${file.size}`);
            formData.append(`otherDocumentsFile${index}`, file, file.name);
        });

        const encodedRequest = encodeURIComponent(JSON.stringify(request));

        await axios.post(
            `${this.configuration.basePath}/freight-claims/open?request=${encodedRequest}`,
            formData,
            options
        );
    }
}
