/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { LocationContext } from './LocationContext';
import {
    LocationContextFromJSON,
    LocationContextFromJSONTyped,
    LocationContextToJSON,
} from './LocationContext';

/**
 * 
 * @export
 * @interface SetAddressRequest
 */
export interface SetAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof SetAddressRequest
     */
    shipmentId: string;
    /**
     * 
     * @type {LocationContext}
     * @memberof SetAddressRequest
     */
    locationContext: LocationContext;
    /**
     * 
     * @type {string}
     * @memberof SetAddressRequest
     */
    businessName: string;
    /**
     * 
     * @type {Address}
     * @memberof SetAddressRequest
     */
    address: Address;
}

/**
 * Check if a given object implements the SetAddressRequest interface.
 */
export function instanceOfSetAddressRequest(value: object): boolean {
    if (!('shipmentId' in value)) return false;
    if (!('locationContext' in value)) return false;
    if (!('businessName' in value)) return false;
    if (!('address' in value)) return false;
    return true;
}

export function SetAddressRequestFromJSON(json: any): SetAddressRequest {
    return SetAddressRequestFromJSONTyped(json, false);
}

export function SetAddressRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetAddressRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentId': json['shipmentId'],
        'locationContext': LocationContextFromJSON(json['locationContext']),
        'businessName': json['businessName'],
        'address': AddressFromJSON(json['address']),
    };
}

export function SetAddressRequestToJSON(value?: SetAddressRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentId': value['shipmentId'],
        'locationContext': LocationContextToJSON(value['locationContext']),
        'businessName': value['businessName'],
        'address': AddressToJSON(value['address']),
    };
}

