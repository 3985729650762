/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FreightClaimState = {
    Created: 'created',
    Cancelled: 'cancelled'
} as const;
export type FreightClaimState = typeof FreightClaimState[keyof typeof FreightClaimState];


export function instanceOfFreightClaimState(value: any): boolean {
    return Object.values(FreightClaimState).includes(value);
}

export function FreightClaimStateFromJSON(json: any): FreightClaimState {
    return FreightClaimStateFromJSONTyped(json, false);
}

export function FreightClaimStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): FreightClaimState {
    return json as FreightClaimState;
}

export function FreightClaimStateToJSON(value?: FreightClaimState | null): any {
    return value as any;
}

