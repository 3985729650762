import React, { CSSProperties, ReactNode } from 'react';
import { animated, config, useSpring } from '@react-spring/web';

interface FadeProps {
    children: ReactNode;
    show: boolean;
    style?: CSSProperties;
}

function Fade(props: FadeProps) {
    const slideInStyles = useSpring({
        config: { ...config.gentle },
        from: { opacity: 0 },
        to: {
            opacity: props.show ? 1 : 0,
        },
    });
    return (
        <animated.div
            style={{ ...slideInStyles, ...props.style, overflow: 'hidden' }}
        >
            {props.children}
        </animated.div>
    );
}
export default Fade;
