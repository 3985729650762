import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from 'Cargo/Colors';
import styled from 'styled-components/macro';

const CircleButtonHoverContainer = styled.span`
    cursor: pointer;
    &:hover {
        filter: brightness(1.2);
    }
`;

interface CircleButtonProps {
    onClick: () => void;
    id?: string;
}

export function CircleButton(props: CircleButtonProps) {
    return (
        <CircleButtonHoverContainer onClick={props.onClick} id={props.id}>
            <FontAwesomeIcon
                icon={['fas', 'plus-circle']}
                style={{
                    width: '20px',
                    height: '20px',
                    color: Colors.Blue,
                }}
            />
        </CircleButtonHoverContainer>
    );
}
