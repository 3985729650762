import { CardNumberElement } from '@stripe/react-stripe-js';
import { StripeCardNumberElementChangeEvent } from '@stripe/stripe-js';
import { InvalidLabel } from 'Cargo/Controls/Input';
import CreditCardLogo from 'Cargo/Icons/CreditCardLogo';
import Spacer from 'Cargo/Layout/Spacer';
import { ErrorMessageType } from 'Cargo/Validation';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { StripeElementOptions } from './StripeElementOptions';

const StyledControlContainer = styled.div`
    position: relative; /* for absolute child element */
    /* display: inline-block; to take the width of the input */
    /* width: 522px; */
`;

const Inset = styled.div`
    position: absolute; /* to align it to right and positon it over the input */
    bottom: 0;
    right: 0;
    padding-bottom: 8px;
`;

interface CreditCardNumberProps {
    onBrandChange?: (brandName: string) => void;
    onError: (message: ErrorMessageType) => void;
    errorMessage: ErrorMessageType;
    validated: boolean;
}

function CreditCardNumber(props: CreditCardNumberProps) {
    const [creditCardBrand, setCreditCardBrand] = useState<string | null>();

    const showErrorMessage =
        props.validated && props.errorMessage !== undefined;

    return (
        <div
            style={{
                width: '360px',
                height: '32px',
                paddingTop: '8px',
                paddingBottom: '1px',
            }}
        >
            <StyledControlContainer>
                <CardNumberElement
                    id="cardNumber"
                    onChange={(event: StripeCardNumberElementChangeEvent) => {
                        setCreditCardBrand(event.brand);
                        props.onBrandChange?.(event.brand);

                        props.onError(event.error?.message);
                    }}
                    options={StripeElementOptions}
                ></CardNumberElement>
                {creditCardBrand && (
                    <Inset>
                        <CreditCardLogo
                            size="regular"
                            brand={creditCardBrand}
                        />
                    </Inset>
                )}
            </StyledControlContainer>
            {showErrorMessage && (
                <InvalidLabel>{props.errorMessage}</InvalidLabel>
            )}
            {!showErrorMessage && <Spacer height={18} />}
        </div>
    );
}
export default CreditCardNumber;
