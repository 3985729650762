import { CurrencyDropdown } from 'Cargo/Controls/CurrencyDropdown';
import Input from 'Cargo/Controls/Input';
import DynamicHeightSpacer from 'Cargo/Layout/DynamicSpacer';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { Label } from 'Cargo/Text/Text';
import {
    AccountType,
    CreateAccountErrorMessages,
} from 'Features/Authentication/Hooks/useCreateAccount';
import { Currency } from 'generated-openapi-client';
import ShipmentFrequencyDropdown from '../ShipmentFrequencyDropdown';

interface CreateAccountPageCompanyDetailsPersonalVersionProps {
    isPersonalSelected: boolean | undefined;
    companyName: string;
    setCompanyName: (_: string) => void;
    companyCurrency: Currency | undefined;
    setCompanyCurrency: (_: Currency) => void;
    shipmentFrequency: string | undefined;
    setShipmentFrequency: (_: string) => void;
    forceValidation: boolean;
    errorMessages: CreateAccountErrorMessages;
}

function CreateAccountPageCompanyDetailsPersonalVersion(
    props: CreateAccountPageCompanyDetailsPersonalVersionProps
) {
    const {
        companyCurrency,
        setCompanyCurrency,
        forceValidation,
        errorMessages,
    } = props;

    return (
        <Stack align="left" style={{ width: '100%' }}>
            <Label>What is your billing currency?</Label>
            <Spacer height={5} />

            <CurrencyDropdown
                currency={companyCurrency}
                onCurrencyChange={setCompanyCurrency}
                width={330}
                enabled={true}
                errorMessage={errorMessages.companyCurrency}
                forceValidation={forceValidation}
                id="signup_currency_dropdown"
            />
        </Stack>
    );
}

interface CreateAccountPageCompanyDetailsBusinessVersionProps {
    isPersonalSelected: boolean | undefined;
    companyName: string;
    setCompanyName: (_: string) => void;
    companyCurrency: Currency | undefined;
    setCompanyCurrency: (_: Currency) => void;
    shipmentFrequency: string | undefined;
    setShipmentFrequency: (_: string) => void;
    forceValidation: boolean;
    errorMessages: CreateAccountErrorMessages;
}

function CreateAccountPageCompanyDetailsBusinessVersion(
    props: CreateAccountPageCompanyDetailsBusinessVersionProps
) {
    const {
        isPersonalSelected,
        companyName,
        setCompanyName,
        companyCurrency,
        setCompanyCurrency,
        shipmentFrequency,
        setShipmentFrequency,
        forceValidation,
        errorMessages,
    } = props;

    return (
        <Stack align="left" style={{ width: '100%' }}>
            {!isPersonalSelected && (
                <>
                    <Label>Enter Company Name</Label>
                    <Input
                        type="text"
                        placeholder="Enter company name"
                        value={companyName}
                        onChange={(value) => setCompanyName(value)}
                        errorMessage={errorMessages.companyName}
                        forceValidation={forceValidation}
                        autoComplete="organization"
                        name="signup_company_name"
                    />
                    <DynamicHeightSpacer maxHeight={18} minHeight={4} />
                </>
            )}
            <HorizontalStack>
                {!isPersonalSelected && (
                    <>
                        <Stack
                            align="left"
                            width="398px"
                            style={{
                                height: '102px',
                                position: 'relative',
                                top: '0',
                            }}
                        >
                            <Label>
                                How often do you ship LTL/FTL freight?
                            </Label>
                            <Spacer height={5} />
                            <ShipmentFrequencyDropdown
                                shipmentFrequency={shipmentFrequency}
                                onShipmentFrequencySelected={
                                    setShipmentFrequency
                                }
                                errorMessage={errorMessages.shipmentFrequency}
                                forceValidation={forceValidation}
                                width={398}
                            />
                        </Stack>
                        <Spacer width={32} />
                    </>
                )}
                <Stack
                    align="left"
                    width="130px"
                    style={{
                        height: '102px',
                        position: 'relative',
                        top: '0',
                    }}
                >
                    <Label>Billing Currency</Label>
                    <Spacer height={5} />

                    <CurrencyDropdown
                        currency={companyCurrency}
                        onCurrencyChange={setCompanyCurrency}
                        width={130}
                        enabled={true}
                        errorMessage={errorMessages.companyCurrency}
                        forceValidation={forceValidation}
                        id="signup_currency_dropdown"
                    />
                </Stack>
            </HorizontalStack>
        </Stack>
    );
}

interface CreateAccountPageCompanyDetailsProps {
    accountType: AccountType | undefined;
    isPersonalSelected: boolean | undefined;
    companyName: string;
    setCompanyName: (_: string) => void;
    companyCurrency: Currency | undefined;
    setCompanyCurrency: (_: Currency) => void;
    shipmentFrequency: string | undefined;
    setShipmentFrequency: (_: string) => void;
    forceValidation: boolean;
    errorMessages: CreateAccountErrorMessages;
}

export function CreateAccountPageCompanyDetails(
    props: CreateAccountPageCompanyDetailsProps
) {
    const { accountType } = props;

    if (accountType === undefined) {
        return <></>;
    }

    if (accountType === AccountType.Personal) {
        return <CreateAccountPageCompanyDetailsPersonalVersion {...props} />;
    } else {
        return <CreateAccountPageCompanyDetailsBusinessVersion {...props} />;
    }
}
