/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';
import type { EquipmentType } from './EquipmentType';
import {
    EquipmentTypeFromJSON,
    EquipmentTypeFromJSONTyped,
    EquipmentTypeToJSON,
} from './EquipmentType';

/**
 * Represents a response from a carrier to a particular quote
 * @export
 * @interface Quote
 */
export interface Quote {
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    quoteId: string;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    price: number;
    /**
     * 
     * @type {Currency}
     * @memberof Quote
     */
    currency: Currency;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    carrierIdentifier: string;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    transitBusinessDays?: number;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    serviceDisplayName: string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    expectedDeliveryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    quotedPickupDate: string;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    taxAmount: number;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    latestTransitBusinessDays?: number;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    latestEstimatedDeliveryDate?: string;
    /**
     * 
     * @type {EquipmentType}
     * @memberof Quote
     */
    equipmentType?: EquipmentType;
}

/**
 * Check if a given object implements the Quote interface.
 */
export function instanceOfQuote(value: object): boolean {
    if (!('quoteId' in value)) return false;
    if (!('price' in value)) return false;
    if (!('currency' in value)) return false;
    if (!('carrierIdentifier' in value)) return false;
    if (!('serviceDisplayName' in value)) return false;
    if (!('quotedPickupDate' in value)) return false;
    if (!('taxAmount' in value)) return false;
    return true;
}

export function QuoteFromJSON(json: any): Quote {
    return QuoteFromJSONTyped(json, false);
}

export function QuoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): Quote {
    if (json == null) {
        return json;
    }
    return {
        
        'quoteId': json['quoteId'],
        'price': json['price'],
        'currency': CurrencyFromJSON(json['currency']),
        'carrierIdentifier': json['carrierIdentifier'],
        'transitBusinessDays': json['transitBusinessDays'] == null ? undefined : json['transitBusinessDays'],
        'serviceDisplayName': json['serviceDisplayName'],
        'expectedDeliveryDate': json['expectedDeliveryDate'] == null ? undefined : json['expectedDeliveryDate'],
        'quotedPickupDate': json['quotedPickupDate'],
        'taxAmount': json['taxAmount'],
        'latestTransitBusinessDays': json['latestTransitBusinessDays'] == null ? undefined : json['latestTransitBusinessDays'],
        'latestEstimatedDeliveryDate': json['latestEstimatedDeliveryDate'] == null ? undefined : json['latestEstimatedDeliveryDate'],
        'equipmentType': json['equipmentType'] == null ? undefined : EquipmentTypeFromJSON(json['equipmentType']),
    };
}

export function QuoteToJSON(value?: Quote | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'quoteId': value['quoteId'],
        'price': value['price'],
        'currency': CurrencyToJSON(value['currency']),
        'carrierIdentifier': value['carrierIdentifier'],
        'transitBusinessDays': value['transitBusinessDays'],
        'serviceDisplayName': value['serviceDisplayName'],
        'expectedDeliveryDate': value['expectedDeliveryDate'],
        'quotedPickupDate': value['quotedPickupDate'],
        'taxAmount': value['taxAmount'],
        'latestTransitBusinessDays': value['latestTransitBusinessDays'],
        'latestEstimatedDeliveryDate': value['latestEstimatedDeliveryDate'],
        'equipmentType': EquipmentTypeToJSON(value['equipmentType']),
    };
}

