/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const BookingSuspendedReason = {
    AlreadyOverCreditLimit: 'AlreadyOverCreditLimit',
    OverdueInvoices: 'OverdueInvoices',
    NotSuspended: 'NotSuspended',
    WillBeTooFarBeyondCreditLimit: 'WillBeTooFarBeyondCreditLimit',
    NeedsAccountVerification: 'NeedsAccountVerification'
} as const;
export type BookingSuspendedReason = typeof BookingSuspendedReason[keyof typeof BookingSuspendedReason];


export function instanceOfBookingSuspendedReason(value: any): boolean {
    return Object.values(BookingSuspendedReason).includes(value);
}

export function BookingSuspendedReasonFromJSON(json: any): BookingSuspendedReason {
    return BookingSuspendedReasonFromJSONTyped(json, false);
}

export function BookingSuspendedReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingSuspendedReason {
    return json as BookingSuspendedReason;
}

export function BookingSuspendedReasonToJSON(value?: BookingSuspendedReason | null): any {
    return value as any;
}

