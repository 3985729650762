/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaymentMethod } from './PaymentMethod';
import {
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
} from './PaymentMethod';

/**
 * 
 * @export
 * @interface SetDefaultPaymentMethodRequest
 */
export interface SetDefaultPaymentMethodRequest {
    /**
     * 
     * @type {string}
     * @memberof SetDefaultPaymentMethodRequest
     */
    stripePaymentMethodId: string;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof SetDefaultPaymentMethodRequest
     */
    paymentMethod: PaymentMethod;
    /**
     * 
     * @type {boolean}
     * @memberof SetDefaultPaymentMethodRequest
     */
    newPaymentMethod: boolean;
}

/**
 * Check if a given object implements the SetDefaultPaymentMethodRequest interface.
 */
export function instanceOfSetDefaultPaymentMethodRequest(value: object): boolean {
    if (!('stripePaymentMethodId' in value)) return false;
    if (!('paymentMethod' in value)) return false;
    if (!('newPaymentMethod' in value)) return false;
    return true;
}

export function SetDefaultPaymentMethodRequestFromJSON(json: any): SetDefaultPaymentMethodRequest {
    return SetDefaultPaymentMethodRequestFromJSONTyped(json, false);
}

export function SetDefaultPaymentMethodRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetDefaultPaymentMethodRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'stripePaymentMethodId': json['stripePaymentMethodId'],
        'paymentMethod': PaymentMethodFromJSON(json['paymentMethod']),
        'newPaymentMethod': json['newPaymentMethod'],
    };
}

export function SetDefaultPaymentMethodRequestToJSON(value?: SetDefaultPaymentMethodRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'stripePaymentMethodId': value['stripePaymentMethodId'],
        'paymentMethod': PaymentMethodToJSON(value['paymentMethod']),
        'newPaymentMethod': value['newPaymentMethod'],
    };
}

