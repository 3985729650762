import Stack from 'Cargo/Layout/Stack';
import useConfirmModal from 'Cargo/Modal/useConfirmModal';
import { ModalTitle } from 'Cargo/Text/Text';
import AddContactPage from 'Features/Contacts/Components/AddContactPage';
import { AddContactContactType } from 'Features/Contacts/Types/contactTypes';
import AddLocationModalAddressPage from 'Features/Locations/Components/AddLocationModalAddressPage';
import AddLocationModalDetailsPage from 'Features/Locations/Components/AddLocationModalDetailsPage';
import { AddLocationLocationType } from 'Features/Locations/Types/locationTypes';
import {
    Contact,
    Location,
    LocationContext,
    LocationType,
    SavedLocation,
} from 'generated-openapi-client';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import AddLocationModalOtherPage from '../Components/AddLocationModalOtherPage';

export interface AddLocationModalCallbackData {
    location: SavedLocation;
    shouldSaveToAddressBook: boolean;
}

export enum AddLocationModalSavingBehaviour {
    Always,
    Ask,
}

interface LocationModalProps {
    onFinished: (params: AddLocationModalCallbackData) => void;
    onCancel: () => void;
    context: LocationContext;
    savingBehaviour: AddLocationModalSavingBehaviour;
}

function AddLocationModal(props: LocationModalProps) {
    const confirm = useConfirmModal(
        'Do you want save this location to your address book?',
        'If you save it to your address book, this location will be easily available for future shipments. Otherwise, this location will just be used for this one shipment.',
        {
            buttons: {
                primary: 'Yes',
                secondary: 'No',
            },
        }
    );

    const { onCancel, onFinished } = props;

    const [location, setLocation] = useState<AddLocationLocationType>({
        locationType: LocationType.Warehouse,
        businessName: undefined,
        address: {},
        accessorials: [],
        latitudeLongitude: undefined,
        notes: '',
        hours: {
            openFrom: '10:00',
            openUntil: '16:00',
        },
    });

    const [contact, setContact] = useState<AddContactContactType>({});

    enum Page {
        Details,
        Address,
        Contact,
        Other,
    }

    const [currentPage, setCurrentPage] = useState(Page.Details);

    function onSwitchToDetailsPage() {
        setCurrentPage(Page.Details);
    }

    function onSwitchToAddressPage() {
        setCurrentPage(Page.Address);
    }

    function onSwitchToContactPage() {
        setCurrentPage(Page.Contact);
    }

    function onSwitchToOtherPage() {
        setCurrentPage(Page.Other);
    }

    async function onDone() {
        // When adding we should ask if they want to add it to the address book
        const shouldSaveToAddressBook =
            props.savingBehaviour === AddLocationModalSavingBehaviour.Ask
                ? await confirm()
                : true;

        const locationId = uuidv4();
        const contactId = uuidv4();

        onFinished({
            shouldSaveToAddressBook,
            location: {
                savedLocationId: locationId,
                location: location as Location,
                contacts: [
                    {
                        savedContactId: contactId,
                        savedLocationId: locationId,
                        contact: contact as Contact,
                    },
                ],
                defaultSavedContactId: contactId,
                isFavourite: true,
                isBranch: false,
            },
        });
    }

    return (
        <Stack align="center">
            <Stack align="left">
                <ModalTitle>Add Location</ModalTitle>

                {currentPage === Page.Details && (
                    <AddLocationModalDetailsPage
                        onSwitchToAddressPage={onSwitchToAddressPage}
                        setLocation={setLocation}
                        location={location}
                        onCancel={onCancel}
                        microcopy="Please tell us about the location you are adding"
                        context={props.context}
                    />
                )}

                {currentPage === Page.Address && (
                    <AddLocationModalAddressPage
                        setLocation={setLocation}
                        location={location}
                        onPrevious={onSwitchToDetailsPage}
                        onSwitchToContactPage={onSwitchToContactPage}
                    />
                )}

                {currentPage === Page.Contact && (
                    <AddContactPage
                        contactChanged={function (
                            updatedContact: AddContactContactType
                        ) {
                            setContact({
                                ...contact,
                                ...updatedContact,
                            });
                        }}
                        contact={contact}
                        onPrevious={onSwitchToAddressPage}
                        onDone={onSwitchToOtherPage}
                    />
                )}

                {currentPage === Page.Other && (
                    <AddLocationModalOtherPage
                        setLocation={setLocation}
                        location={location}
                        onPrevious={onSwitchToContactPage}
                        onDone={onDone}
                    />
                )}
            </Stack>
        </Stack>
    );
}

export default AddLocationModal;
