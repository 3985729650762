/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SavedLocation } from './SavedLocation';
import {
    SavedLocationFromJSON,
    SavedLocationFromJSONTyped,
    SavedLocationToJSON,
} from './SavedLocation';

/**
 * 
 * @export
 * @interface GetAllSavedLocationsResponse
 */
export interface GetAllSavedLocationsResponse {
    /**
     * 
     * @type {Array<SavedLocation>}
     * @memberof GetAllSavedLocationsResponse
     */
    items: Array<SavedLocation>;
    /**
     * 
     * @type {string}
     * @memberof GetAllSavedLocationsResponse
     */
    defaultPickupLocationId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAllSavedLocationsResponse
     */
    defaultDeliveryLocationId?: string;
}

/**
 * Check if a given object implements the GetAllSavedLocationsResponse interface.
 */
export function instanceOfGetAllSavedLocationsResponse(value: object): boolean {
    if (!('items' in value)) return false;
    return true;
}

export function GetAllSavedLocationsResponseFromJSON(json: any): GetAllSavedLocationsResponse {
    return GetAllSavedLocationsResponseFromJSONTyped(json, false);
}

export function GetAllSavedLocationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAllSavedLocationsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(SavedLocationFromJSON)),
        'defaultPickupLocationId': json['defaultPickupLocationId'] == null ? undefined : json['defaultPickupLocationId'],
        'defaultDeliveryLocationId': json['defaultDeliveryLocationId'] == null ? undefined : json['defaultDeliveryLocationId'],
    };
}

export function GetAllSavedLocationsResponseToJSON(value?: GetAllSavedLocationsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(SavedLocationToJSON)),
        'defaultPickupLocationId': value['defaultPickupLocationId'],
        'defaultDeliveryLocationId': value['defaultDeliveryLocationId'],
    };
}

