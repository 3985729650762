/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InvoiceWarningState = {
    BookingSuspendedDueToOverdueInvoices: 'BookingSuspendedDueToOverdueInvoices',
    BookingSuspendedDueToOverCreditLimit: 'BookingSuspendedDueToOverCreditLimit',
    BookingWarningInvoicesCloseToOverdue: 'BookingWarningInvoicesCloseToOverdue',
    BookingWarningCloseToCreditLimit: 'BookingWarningCloseToCreditLimit',
    NoWarning: 'NoWarning',
    BookingSuspendedNeedsAccountVerification: 'BookingSuspendedNeedsAccountVerification'
} as const;
export type InvoiceWarningState = typeof InvoiceWarningState[keyof typeof InvoiceWarningState];


export function instanceOfInvoiceWarningState(value: any): boolean {
    return Object.values(InvoiceWarningState).includes(value);
}

export function InvoiceWarningStateFromJSON(json: any): InvoiceWarningState {
    return InvoiceWarningStateFromJSONTyped(json, false);
}

export function InvoiceWarningStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceWarningState {
    return json as InvoiceWarningState;
}

export function InvoiceWarningStateToJSON(value?: InvoiceWarningState | null): any {
    return value as any;
}

