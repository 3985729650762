import { ErrorMessageType } from 'Cargo/Validation';
import { Hours } from 'generated-openapi-client';
import React from 'react';
import NeedsAfterHoursQuestionBubble from '../Components/QuestionBubbles/NeedsAfterHoursQuestionBubble';

export function parseHours(hoursString: string) {
    const parts = hoursString.split(':');

    const hours = parts[0];
    const minutes = parts[1];

    return parseInt(hours) + (minutes === '30' ? 0.5 : 0);
}

export function diffHours(
    hoursFromString: string,
    hoursUntilString: string
): number {
    const hoursFrom = parseHours(hoursFromString);
    const hoursUntil = parseHours(hoursUntilString);

    if (hoursFrom < hoursUntil) {
        return hoursUntil - hoursFrom;
    } else if (hoursFrom === hoursUntil) {
        if (hoursFrom == 0) {
            return 24;
        } else {
            return 0;
        }
    } else if (hoursFrom > hoursUntil) {
        return 24 + hoursUntil - hoursFrom;
    } else {
        throw new Error('Should not be here');
    }
}

export function doesWindowContainHours(
    hoursFromString: string,
    hoursUntilString: string,
    searchStart: number,
    searchEnd: number
) {
    if (searchEnd <= searchStart) {
        throw new Error('This search window is not supported');
    }

    const hoursFrom = parseHours(hoursFromString);
    const hoursUntil = parseHours(hoursUntilString);

    // Rule in/out simple 24 hour and 0 hour cases
    if (hoursFrom == hoursUntil) {
        if (hoursFrom === 0) {
            return true;
        } else {
            return false;
        }
    }

    // Not dealing with a wrap around
    if (hoursFrom < hoursUntil) {
        // Totally straddling
        // Needs to be the same size or surround it to count
        if (hoursFrom <= searchStart && hoursUntil >= searchEnd) {
            return true;
        } else {
            return false;
        }
    }

    // Eg this is a window like 23:00 -> 13:00
    // We can break it into two windows
    // 23-24 && 0-13
    if (hoursFrom > hoursUntil) {
        if (
            (0 <= searchStart && hoursUntil >= searchEnd) ||
            hoursFrom <= searchStart
        ) {
            return true;
        } else {
            return false;
        }
    }
}

export function contains2HoursIn9To5(
    hoursFromString: string,
    hoursUntilString: string
) {
    // Try every 2 hour block (including half hours) from 9-11am until 3-5pm
    for (let startTime = 9; startTime <= 15; startTime += 0.5) {
        const contains = doesWindowContainHours(
            hoursFromString,
            hoursUntilString,
            startTime,
            startTime + 2
        );
        if (contains) {
            return true;
        }
    }

    return false;
}

export function contains4HoursInNoonTo5(
    hoursFromString: string,
    hoursUntilString: string
) {
    // Try every 4 hour block (including half hours) from noon-4pm until 1pm-5pm
    for (let startTime = 12; startTime <= 13; startTime += 0.5) {
        const contains = doesWindowContainHours(
            hoursFromString,
            hoursUntilString,
            startTime,
            startTime + 4
        );
        if (contains) {
            return true;
        }
    }

    return false;
}

export function warningMessageForPickupHours(hours: Hours): ErrorMessageType {
    const parsedUntil = parseHours(hours.openUntil);
    if (parsedUntil < 14) {
        return (
            <span>
                Generally carriers do not begin pickups until 12pm
                <br /> Carriers will do their best to make your cutoff time, but
                consider extending it
            </span>
        );
    }
}

export function errorMessageForHours(
    hours: Hours,
    allowAfterHours: boolean,
    checkHoursAreValidForAPickupToday: boolean
): ErrorMessageType {
    // is pickup happening today? we need to make sure the window is good for the afternoon
    if (checkHoursAreValidForAPickupToday) {
        if (!contains4HoursInNoonTo5(hours.openFrom, hours.openUntil)) {
            return 'For same day pickup, the window must include at least 4 hours between noon and 5pm';
        }
    }

    if (parseHours(hours.openFrom) > parseHours(hours.openUntil)) {
        return 'Close time is before open time';
    }

    const d = diffHours(hours.openFrom, hours.openUntil);
    if (d < 2) {
        return 'Window must be at least 2 hours';
    } else {
        // There's a two hour window
        if (!allowAfterHours) {
            if (contains2HoursIn9To5(hours.openFrom, hours.openUntil)) {
                return undefined;
            } else {
                return (
                    <>
                        <span>
                            Window must contain at least 2 hours between 9am-5pm
                        </span>
                        <NeedsAfterHoursQuestionBubble />
                    </>
                );
            }
        } else {
            // 2 hours & we have after hours

            return undefined;
        }
    }
}
