/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Invitation
 */
export interface Invitation {
    /**
     * 
     * @type {Date}
     * @memberof Invitation
     */
    expiresAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof Invitation
     */
    lastSent: Date;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    link?: string;
}

/**
 * Check if a given object implements the Invitation interface.
 */
export function instanceOfInvitation(value: object): boolean {
    if (!('email' in value)) return false;
    if (!('name' in value)) return false;
    if (!('lastSent' in value)) return false;
    return true;
}

export function InvitationFromJSON(json: any): Invitation {
    return InvitationFromJSONTyped(json, false);
}

export function InvitationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Invitation {
    if (json == null) {
        return json;
    }
    return {
        
        'expiresAt': json['expiresAt'] == null ? undefined : (new Date(json['expiresAt'])),
        'email': json['email'],
        'name': json['name'],
        'lastSent': (new Date(json['lastSent'])),
        'link': json['link'] == null ? undefined : json['link'],
    };
}

export function InvitationToJSON(value?: Invitation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'expiresAt': value['expiresAt'] == null ? undefined : ((value['expiresAt']).toISOString()),
        'email': value['email'],
        'name': value['name'],
        'lastSent': ((value['lastSent']).toISOString()),
        'link': value['link'],
    };
}

