import { IconName } from '@fortawesome/fontawesome-svg-core';
import Colors from 'Cargo/Colors';
import Icon from 'Cargo/Icons/Icon';
import styled from 'styled-components/macro';

const IconContainer = styled.div`
    &:hover {
        filter: brightness(1.2);
    }
    width: 16px;
    height: 16px;
    cursor: pointer;
`;

interface IconButtonProps {
    icon: IconName;
    onClick: (_: void) => void;
}

export function IconButton(props: IconButtonProps) {
    function onClick() {
        props.onClick();
    }

    return (
        <IconContainer onClick={onClick}>
            <Icon
                // No idea where this offset is coming from
                // not need this to vertically centre
                style={{ position: 'relative', top: '-4px' }}
                name={props.icon}
                color={Colors.MidGray}
                size={16}
                solid
            />
        </IconContainer>
    );
}
