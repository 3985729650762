import Colors from 'Cargo/Colors';
import Icon from 'Cargo/Icons/Icon';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { ReactNode } from 'react';
import styled from 'styled-components/macro';
import {
    CustomsDocsDropzone,
    CustomsDocsDropzoneProps,
} from './CustomsDocsDropzone';

const SectionTitle = styled.div`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 30px;
    color: var(--freightsimple-color-normal-text);
`;

const SectionSubtitle = styled.div`
    font-weight: var(--nhu-font-weight-light);
    font-size: 18px;
    color: var(--freightsimple-color-light-text);
    text-align: center;

    & strong {
        color: var(--freightsimple-color-normal-text);
    }
`;

interface CustomsDocsSubmitSectionProps extends CustomsDocsDropzoneProps {
    title?: ReactNode;
    subtitle?: ReactNode;
}

export function CustomsDocsSubmitSection({
    title,
    subtitle,
    ...props
}: CustomsDocsSubmitSectionProps) {
    return (
        <Stack align="center" width="100%">
            <Icon
                name="globe-americas"
                color={Colors.Blue}
                size={80}
                solid
                style={{ marginBottom: '16px', marginTop: '16px' }}
            />
            <SectionTitle>{title}</SectionTitle>
            <SectionSubtitle>{subtitle}</SectionSubtitle>
            <Spacer height={32} />
            <Stack width="100%" align="center">
                <CustomsDocsDropzone {...props} />
            </Stack>
            <Spacer height={32} />
        </Stack>
    );
}
