import Link from 'Cargo/Controls/Link';
import Box from 'Cargo/Layout/Box';
import Spacer from 'Cargo/Layout/Spacer';
import BookShipmentNavigationFooter2 from 'Features/BookShipment/Components/BookShipmentNavigationFooter2';
import BookShipmentScreenLayout from 'Features/BookShipment/Layout/BookShipmentScreenLayout';
import {
    AddBrokerBrokerType,
    emptyBrokerInfo,
} from 'Features/Brokers/Types/brokerTypes';
import { errorMessagesForBroker } from 'Features/Brokers/Validators/errorMessagesForBroker';
import ContactInput from 'Features/Contacts/Components/ContactInput';
import { AddContactContactType } from 'Features/Contacts/Types/contactTypes';
import AddressInput from 'Features/Locations/Components/AddressInput';
import { useGetStartedApi } from 'apis';
import {
    Address,
    Broker,
    Contact,
    LocationType,
    PostGetStartedSetBrokerDetailsRequest,
    PreBookingShipment,
} from 'generated-openapi-client';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import BookShipmentSmallSection from './Components/BookShipmentSmallSection';
import { BookShipmentBrokerScreenAlreadySaved } from './Components/Broker/BookShipmentBrokerScreenAlreadySaved';
import { setCurrentScreenIsDirty } from './Slices/bookShipmentSlice';

interface BookShipmentBrokerScreenProps {
    shipment: PreBookingShipment;
    onComplete: () => void;
    nextIdentifier: string;
    onPrevious: () => Promise<void>;
}

function BookShipmentBrokerScreen(props: BookShipmentBrokerScreenProps) {
    const { shipment } = props;
    const { shipmentId } = shipment;
    const [forceValidation, setForceValidation] = useState(false);
    const dispatch = useDispatch();

    const [broker, setBroker] = useState<AddBrokerBrokerType>(
        emptyBrokerInfo()
    );

    const comparisonContacts: Map<string, AddContactContactType> = new Map();
    comparisonContacts.set('pickup', shipment.pickupContact || {});
    comparisonContacts.set('delivery', shipment.deliveryContact || {});

    const brokerErrors = errorMessagesForBroker(broker, comparisonContacts);
    const getStartedApi = useGetStartedApi();

    if (shipment.broker !== undefined) {
        return (
            <div id="broker-info">
                <BookShipmentScreenLayout
                    pageHeading={`Customs Broker Info`}
                    pageSubheading={
                        <>
                            <div>
                                Brokerage issues can cause delays in shipments.
                                If there is a mistake in the details you
                                entered, you can modify them.
                            </div>
                            <Spacer height={4} />
                            <div>
                                Learn more about brokerage{' '}
                                <Link>at FreightSimple&apos;s help center</Link>
                            </div>
                        </>
                    }
                >
                    <BookShipmentBrokerScreenAlreadySaved
                        broker={shipment.broker}
                        shipmentId={shipment.shipmentId}
                    />
                </BookShipmentScreenLayout>
            </div>
        );
    }

    function isValid() {
        const anyErrorMessages = [
            brokerErrors.businessName,
            brokerErrors.address.addressLine,
            brokerErrors.address.city,
            brokerErrors.address.postalCode,
            brokerErrors.address.stateOrProvinceCode,
            brokerErrors.contact.emailAddress,
            brokerErrors.contact.contactName,
            brokerErrors.contact.phoneNumber,
        ].some((e) => e !== undefined);

        return !anyErrorMessages;
    }

    async function nextIsValid() {
        setForceValidation(true);
        if (isValid()) {
            const request: PostGetStartedSetBrokerDetailsRequest = {
                setBrokerDetailsRequest: {
                    shipmentId,
                    broker: broker as Broker,
                },
            };

            console.log({ request });

            // TODO: Move this to a different API
            await getStartedApi.postGetStartedSetBrokerDetails(request);
            props.onComplete();
            return true;
        } else {
            return false;
        }
    }

    function updateContact(contact: Partial<Contact>) {
        dispatch(setCurrentScreenIsDirty());
        setBroker({
            ...broker,
            contact: {
                ...broker.contact,
                ...contact,
            },
        });
    }

    function updateAddress(address: Partial<Address>) {
        dispatch(setCurrentScreenIsDirty());
        setBroker({
            ...broker,
            address: {
                ...broker.address,
                ...address,
            },
        });
    }

    function setBusinessName(businessName: string) {
        dispatch(setCurrentScreenIsDirty());
        setBroker({
            ...broker,
            businessName,
        });
    }

    let i = 1;

    return (
        <div id="broker-info">
            <BookShipmentScreenLayout
                pageHeading={`Customs Broker Info`}
                pageSubheading={
                    <>
                        Please enter the details of your customs broker.
                        Brokerage issues can cause delays in shipments. Learn
                        more about brokerage <Link to="https://help.freightsimple.com/freight-101/cross-border-shipping">here</Link>
                    </>
                }
            >
                <BookShipmentSmallSection
                    header={undefined}
                    description={undefined}
                    index={i++}
                >
                    <Box width={608}>
                        <AddressInput
                            enabled={true}
                            businessName={broker.businessName || ''}
                            businessNameChanged={setBusinessName}
                            locationType={LocationType.Warehouse}
                            address={broker.address}
                            addressChanged={updateAddress}
                            forceValidation={forceValidation}
                            businessNameErrorMessages={
                                brokerErrors.businessName
                            }
                            addressErrorMessages={brokerErrors.address}
                            addressOptions={[]}
                            displayName={'Broker'}
                            allowCityAndPostalCodeEditing={true}
                            warnAboutPotentialBusinessNameErrors={false}
                        />
                    </Box>
                </BookShipmentSmallSection>
                <Spacer height={32} />
                <BookShipmentSmallSection
                    header="Contact Details"
                    description="Enter the customs broker's contact details"
                    index={i++}
                >
                    <Box width={608}>
                        <ContactInput
                            contact={broker.contact}
                            contactChanged={updateContact}
                            forceValidation={forceValidation}
                            errorMessages={brokerErrors.contact}
                            warningMessages={brokerErrors.contact}
                        ></ContactInput>
                    </Box>
                </BookShipmentSmallSection>
                <BookShipmentNavigationFooter2
                    next="Next"
                    onPrevious={props.onPrevious}
                    nextUrl={`/book/${props.nextIdentifier}?shipmentId=${shipmentId}`}
                    nextIsValid={nextIsValid}
                    nextIsEnabled={() => true}
                    nextIdentifier={props.nextIdentifier}
                    hidePreviousButton={false}
                />
            </BookShipmentScreenLayout>
        </div>
    );
}

export default BookShipmentBrokerScreen;
