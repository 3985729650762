import Button from 'Cargo/Controls/Button';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { Label, Microcopy } from 'Cargo/Text/Text';
import AddressInput from 'Features/Locations/Components/AddressInput';
import { Address, LocationType } from 'generated-openapi-client';
import React, { useState } from 'react';
import { AddBrokerBrokerType } from '../Types/brokerTypes';
import { errorMessagesForBroker } from '../Validators/errorMessagesForBroker';

interface AddLocationModalAddressPage {
    broker: AddBrokerBrokerType;
    setBroker: (_: AddBrokerBrokerType) => void;
    onSwitchToContactPage: () => void;
    onCancel: () => void;
}

function AddBrokerModalAddressPage(props: AddLocationModalAddressPage) {
    const { broker, setBroker, onSwitchToContactPage, onCancel } = props;
    const [forceValidation, setForceValidation] = useState(false);

    function updateAddress(addressUpdates: Partial<Address>) {
        const address = {
            ...broker.address,
            ...addressUpdates,
        };

        setBroker({
            ...broker,
            address,
        });
    }

    const errorMessages = errorMessagesForBroker(broker);

    function setBusinessName(businessName: string) {
        setBroker({
            ...broker,
            businessName,
        });
    }

    function isValid() {
        if (errorMessages.address.addressLine !== undefined) {
            return false;
        }

        if (errorMessages.businessName !== undefined) {
            return false;
        }

        return true;
    }

    async function onNext() {
        if (isValid()) {
            onSwitchToContactPage();
        } else {
            setForceValidation(true);
        }
    }

    return (
        <>
            <Microcopy>
                Please enter the remaining parts of the address
            </Microcopy>
            <Stack align="left">
                <>
                    <Spacer height={16} />

                    <Label>Address Details</Label>
                    <AddressInput
                        enabled={true}
                        businessName={broker.businessName || ''}
                        businessNameChanged={setBusinessName}
                        locationType={LocationType.Warehouse}
                        address={broker.address}
                        addressChanged={updateAddress}
                        forceValidation={forceValidation}
                        businessNameErrorMessages={errorMessages.businessName}
                        addressErrorMessages={errorMessages.address}
                        addressOptions={[]}
                        allowCityAndPostalCodeEditing={true}
                        warnAboutPotentialBusinessNameErrors={false}
                    />
                    <Spacer height={32} />
                    <HorizontalStack width="100%" align="spread">
                        <Button onClick={onCancel} secondary>
                            Cancel
                        </Button>
                        <Button onClick={onNext}>Next</Button>
                    </HorizontalStack>
                </>
            </Stack>
        </>
    );
}

export default AddBrokerModalAddressPage;
