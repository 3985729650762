import Colors from 'Cargo/Colors';
import { ShipmentState } from 'generated-openapi-client';

export function colorForShipmentState(state: ShipmentState): string {
    switch (state) {
        case ShipmentState.Quoted:
            return Colors.VeryLightGray;
        case ShipmentState.QuoteRequested:
            return Colors.VeryLightGray;
        case ShipmentState.BookingConfirmed:
            return Colors.Gold;
        case ShipmentState.BookingRequested:
            return Colors.Gold;
        case ShipmentState.BookingFailed:
            return Colors.Gold;
        case ShipmentState.InTransit:
            return Colors.Blue;
        case ShipmentState.Delivered:
            return Colors.DarkGreen;
        case ShipmentState.OnHold:
            return Colors.MidGray;
        case ShipmentState.Cancelled:
            return 'black';
        case ShipmentState.Lost:
            return 'black';
    }
}
