import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import { ErrorMessageType } from 'Cargo/Validation';
import React from 'react';
import Input from './Input';

interface PhoneInputProps {
    id?: string;
    phoneNumber?: string;
    extension?: string;
    onPhoneNumberChange: (e: string) => void;
    onExtensionChange: (e: string) => void;
    phoneNumberErrorMessage: ErrorMessageType;
    extensionErrorMessage: ErrorMessageType;
    forceValidation: boolean;
    preferPlaceholder?: boolean;
    width?: number;
}

export function formatPhoneNumberWithExtension(
    phoneNumber: string | undefined,
    extension: string | undefined
) {
    if (extension === undefined || extension.trim() === '') {
        return formatPhoneNumber(phoneNumber);
    } else {
        return `${formatPhoneNumber(phoneNumber)} Ext. ${extension}`;
    }
}

export function formatPhoneNumber(phoneNumber: string | undefined): string {
    if (phoneNumber === undefined || phoneNumber === '') {
        return '';
    }

    const rawDigits = phoneNumber.replace(/\D/g, '');

    // We want to format a number like either
    // +1 (778) 563-568
    // (778) 563-5684

    if (rawDigits.startsWith('1')) {
        const countryPart = rawDigits.substring(0, 1);
        const areaPart = rawDigits.substring(1, 4);
        const numberPart1 = rawDigits.substring(4, 7);
        const numberPart2 = rawDigits.substring(7, 11);

        if (areaPart.length === 0) {
            return `+${countryPart}`;
        } else if (numberPart1.length === 0) {
            return `+${countryPart} (${areaPart})`;
        } else if (numberPart2.length === 0) {
            return `+${countryPart} (${areaPart}) ${numberPart1}`;
        } else {
            return `+${countryPart} (${areaPart}) ${numberPart1}-${numberPart2}`;
        }
    } else {
        const areaPart = rawDigits.substring(0, 3);
        const numberPart1 = rawDigits.substring(3, 6);
        const numberPart2 = rawDigits.substring(6, 10);

        if (numberPart1.length === 0) {
            return `(${areaPart})`;
        } else if (numberPart2.length === 0) {
            return `(${areaPart}) ${numberPart1}`;
        } else {
            return `(${areaPart}) ${numberPart1}-${numberPart2}`;
        }
    }
}

function unformatPhoneNumber(phoneNumber: string) {
    return phoneNumber.replace(/\D/g, '');
}

function sanitizePhoneExtension(phoneNumberExtension: string) {
    return phoneNumberExtension.replace(/\D/g, '').substr(0, 6);
}

const PhoneInput: React.FC<PhoneInputProps> = (props: PhoneInputProps) => {
    return (
        <HorizontalStack align="left" width="100%">
            <Input
                type="tel"
                name="contact_phone_number_phone"
                label={props.preferPlaceholder ? undefined : `Phone Number`}
                placeholder={
                    props.preferPlaceholder ? `Phone Number` : undefined
                }
                pattern="^\+?1?\d{10}$"
                value={formatPhoneNumber(props.phoneNumber)}
                // Enough for +1 (778) 668 5574
                maxLength={17}
                onChange={(
                    newValue: string,
                    e: React.FormEvent<HTMLInputElement> | undefined
                ) => {
                    // Get the raw digits, which
                    console.log(`newValue = ${newValue}`);
                    let newRawDigits = unformatPhoneNumber(newValue);
                    console.log(`1# newRawDigits = ${newRawDigits}`);

                    // We have a problem when hitting backspace when we get to +1 (777),
                    // Because when we strip the non-numbers, we end up with the same result
                    // and get stuck in a cycle
                    //
                    // So if we're in a cycle where the new raw digits equals the old raw digits
                    // then remove the last of the new raw digits to perform the delete we
                    // wanted to do
                    if (
                        newRawDigits === props.phoneNumber &&
                        newRawDigits.length < 10
                    ) {
                        newRawDigits = newRawDigits.slice(0, -1);
                        console.log(`1a# newRawDigits = ${newRawDigits}`);
                    }

                    if (newRawDigits[0] === '1') {
                        newRawDigits = newRawDigits.substr(0, 11);
                        console.log(`1b# newRawDigits = ${newRawDigits}`);
                    } else {
                        newRawDigits = newRawDigits.substr(0, 10);
                        console.log(`1c# newRawDigits = ${newRawDigits}`);
                    }

                    const formatted = formatPhoneNumber(newRawDigits);
                    console.log(`formatted = ${formatted}`);

                    // This is special code to deal with putting the cursor behind the ) while typing
                    // if the formatting inserted a )  eg +1 (77<cursor>), not +1 (77)<cursor>

                    // Basically, if it ends with ) and the cursor is at the end
                    if (
                        formatted.endsWith(')') &&
                        e?.currentTarget.selectionStart === newValue.length &&
                        e?.currentTarget.selectionEnd === newValue.length
                    ) {
                        const currentTarget = e.currentTarget;

                        // We need a timeout to break out of the render cycle
                        // There is probably a more elegant way,
                        // but this seems to work
                        setTimeout(() => {
                            currentTarget.setSelectionRange(
                                formatted.length - 1,
                                formatted.length - 1
                            );
                        }, 10);
                    }

                    props.onPhoneNumberChange(newRawDigits);
                }}
                required
                errorMessage={props.phoneNumberErrorMessage}
                forceValidation={props.forceValidation}
                width={props.width}
            ></Input>
            <Spacer width={10} />
            <Input
                type="tel"
                name="contact_phone_number_extension"
                label={props.preferPlaceholder ? undefined : `Ext.`}
                placeholder={props.preferPlaceholder ? `Ext.` : undefined}
                value={props.extension || ''}
                onChange={(value: string) => {
                    const sanitized = sanitizePhoneExtension(value);

                    props.onExtensionChange(sanitized);
                }}
                errorMessage={props.extensionErrorMessage}
                forceValidation={props.forceValidation}
                width={80}
            ></Input>
        </HorizontalStack>
    );
};
export default PhoneInput;
