import DynamicHeightSpacer from 'Cargo/Layout/DynamicSpacer';
import Stack from 'Cargo/Layout/Stack';
import { Legalese } from 'Cargo/Text/Text';
import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';

interface GetStartedScreenLayoutProps {
    pageHeading: ReactNode;
    pageSubheading?: ReactNode;
    children: ReactNode;
    width?: number;
}

type GetStartedScreenLayoutImplementationProps = GetStartedScreenLayoutProps & {
    hackPleaseRemove?: void;
};

const Header = styled.h2`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 22px;
    color: var(--freightsimple-color-normal-text);
    margin-top: 4px;
`;

function BookShipmentScreenLayoutImplementation(
    props: GetStartedScreenLayoutImplementationProps
) {
    return (
        <>
            <div style={{ width: `${props.width || 808}px` }}>
                <Stack align="left">
                    <Header>{props.pageHeading}</Header>
                    {props.pageSubheading && (
                        <Legalese style={{ maxWidth: '608px' }}>
                            {props.pageSubheading}
                        </Legalese>
                    )}

                    <DynamicHeightSpacer minHeight={8} maxHeight={64} />
                    {props.children}
                </Stack>
            </div>
        </>
    );
}

function BookShipmentScreenLayout(props: GetStartedScreenLayoutProps) {
    return <BookShipmentScreenLayoutImplementation {...props} />;
}
export default BookShipmentScreenLayout;
