import { useModalsSlice } from 'Cargo/Modal/modalsSlice';
import useManageContactsModal, {
    ManageContactsModalFlavour,
} from 'Features/Contacts/Hooks/useManageContactsModal';
import { findSavedLocationForLocation } from 'Features/Locations/Helpers/findSavedLocationForLocation';
import { useSavedLocations } from 'Features/Locations/Hooks/useSavedLocations';
import {
    Contact,
    SavedLocation,
    Shipment,
    ShipmentState,
} from 'generated-openapi-client';
import { LocationContext } from 'generated-openapi-client/models/LocationContext';
import { useShipmentService } from 'Services/ShipmentService';
import { useUpdatedBOLModal } from './UpdatedBOLModal';

function findSavedLocationId(
    locationContext: LocationContext,
    shipment: Shipment,
    savedLocations: Array<SavedLocation>
) {
    const location =
        locationContext === LocationContext.Pickup
            ? shipment.pickupLocation
            : shipment.deliveryLocation;

    return findSavedLocationForLocation(savedLocations, location)
        ?.savedLocationId;
}

function useModifyContactAfterBookingModal(
    shipment: Shipment,
    locationContext: LocationContext
) {
    const showManageContactsModal = useManageContactsModal(
        ManageContactsModalFlavour.ModifyAfterBooking,
        locationContext
    );
    const showUpdatedBOLModal = useUpdatedBOLModal();
    const shipmentService = useShipmentService();
    const { setLoading } = useModalsSlice();
    const { savedLocations } = useSavedLocations();

    // Returns if the broker has changed, hence we should reload
    async function show(): Promise<boolean> {
        const savedLocationId = findSavedLocationId(
            locationContext,
            shipment,
            savedLocations
        );

        const originalContact =
            locationContext === LocationContext.Pickup
                ? shipment.pickupContact
                : shipment.deliveryContact;

        console.log(`savedLocationId=${savedLocationId}`);

        if (savedLocationId === undefined) {
            // We should handle this - seems like a real edge case
            // where they have modified the location after booking
            throw new Error('No saved location found');
        }

        // We need to show a screen that allows them to select from any of the saved brokers,
        // and optionally modify one of them or add a new one
        // const newSavedBrokerId = await showSwitchBrokerModal(currentBroker);
        const updatedContact = await showManageContactsModal(savedLocationId);

        console.log(`updatedContact`, { updatedContact });

        if (updatedContact === undefined) {
            // Cancel pressed
            return false;
        }

        function areContactsEqual(contact1: Contact, contact2: Contact) {
            // Note the email address is missing here. We don't put the
            // contact's email on BOLs or send to the carrier.
            // So if that's all that has changed
            return (
                contact1.contactName === contact2.contactName &&
                contact1.emailAddress === contact2.emailAddress &&
                contact1.phoneNumber === contact2.phoneNumber &&
                contact1.phoneNumberExtension === contact2.phoneNumberExtension
            );
        }

        if (areContactsEqual(updatedContact, originalContact)) {
            // Nothing changed
            return false;
        }

        setLoading(true);
        // Make an API call to change the broker
        await shipmentService.updateContact(
            shipment.shipmentId,
            locationContext,
            updatedContact
        );
        setLoading(false);

        // If the shipment is in transit, then it's too late to give the driver
        // a new BOL. But if it's still showing as pending pickup, it'd worth showing them this screen
        if (shipment.shipmentState !== ShipmentState.InTransit) {
            await showUpdatedBOLModal();
        }

        // Do we need to do something with the custom documents?
        // CS: Jan 2022: I'm currently taking the stance that we do nothing
        // - If they're not yet uploaded then nothing to do anyway
        // - If they have been uploaded, then they're still probably mostly valid, and hopefully
        //   the carrier can work with them
        // We should at some point give the customer the ability to upload replacement customs docs

        return true;
    }

    return show;
}

export default useModifyContactAfterBookingModal;
