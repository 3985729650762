import Colors from 'Cargo/Colors';
import Link from 'Cargo/Controls/Link';
import Tooltip from 'Cargo/Controls/Tooltip';
import Icon from 'Cargo/Icons/Icon';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { User } from 'Features/Authentication/Slices/authenticationSlice';
import { SavedLocation, User as OpenAPIUser } from 'generated-openapi-client';
import { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { UserDropdownType } from '../Hooks/useUserDropdown';

interface UserDropdownProps {
    type: UserDropdownType;
    user: User;
    users: OpenAPIUser[];
    branches: SavedLocation[];
    selectedUserId: string | undefined;
    selectedBranchId: string | undefined | BranchFilterOption;
    onSelectUser: (selectedUserId: string | undefined) => void;
    onSelectBranch: (
        selectedBranchId: string | undefined | BranchFilterOption
    ) => void;
    showUncodedOption: boolean;
}

const UserDropdownLabel = styled.div`
    color: var(--freightsimple-color-light-text);
    font-size: 12px;
    font-weight: var(--nhu-font-weight-regular);
`;
const UserDropdownValue = styled.div`
    color: var(--freightsimple-color-normal-text);
    font-size: 18px;
    font-weight: var(--nhu-font-weight-bold);
    margin-top: -4px;
    margin-bottom: 8px;
`;

const UserDropdownBody = styled.div``;

const UserDropdownItemBody = styled.div`
    font-size: 14px;
    font-weight: var(--nhu-font-weight-bold);
    cursor: pointer;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 16px;
    padding-right: 48px;

    color: ${Colors.NormalText};

    &:hover {
        background-color: ${Colors.VeryLightGray};
    }
`;

const UserDropdownDivider = styled.div`
    border-bottom: 1px solid ${Colors.VeryLightGray};
`;

const UserDropdownHeader = styled.div`
    color: var(--freightsimple-color-light-text);
    font-size: 12px;
    font-weight: var(--nhu-font-weight-regular);
    margin-top: 8px;
    padding-left: 40px;
    padding-right: 48px;
`;

const UserDropdownNoOtherUsers = styled.div`
    color: var(--freightsimple-color-light-text);
    font-size: 12px;
    font-weight: var(--nhu-font-weight-light);
    margin-top: 8px;
    padding-left: 40px;
    padding-right: 48px;
`;

interface UserDropdownItemProps {
    children: ReactNode;
    selectedUserId: string | undefined | BranchFilterOption;
    userId: string | undefined;
    onSelect: (_: string | undefined) => void;
}

function UserDropdownItem(props: UserDropdownItemProps) {
    const isSelected = props.selectedUserId === props.userId;
    return (
        <UserDropdownItemBody
            onClick={function () {
                props.onSelect(props.userId);
            }}
        >
            <HorizontalStack>
                {isSelected && (
                    <Icon
                        name="check-circle"
                        color={Colors.Green}
                        size={16}
                        solid
                    />
                )}
                {!isSelected && <Spacer width={16} />}
                <Spacer width={8} />
                {props.children}
            </HorizontalStack>
        </UserDropdownItemBody>
    );
}

export enum BranchFilterOption {
    Uncoded = 'uncoded',
}

interface UserDropdownBranchItemProps {
    children: ReactNode;
    selectedBranchId: string | undefined | BranchFilterOption;
    branchId: string | undefined | BranchFilterOption;
    onSelect: (_: string | undefined | BranchFilterOption) => void;
}

function UserDropdownBranchItem(props: UserDropdownBranchItemProps) {
    const isSelected = props.selectedBranchId === props.branchId;
    return (
        <UserDropdownItemBody
            onClick={function () {
                props.onSelect(props.branchId);
            }}
        >
            <HorizontalStack>
                {isSelected && (
                    <Icon
                        name="check-circle"
                        color={Colors.Green}
                        size={16}
                        solid
                    />
                )}
                {!isSelected && <Spacer width={16} />}
                <Spacer width={8} />
                {props.children}
            </HorizontalStack>
        </UserDropdownItemBody>
    );
}

export function UserDropdown(props: UserDropdownProps) {
    function title() {
        switch (props.type) {
            case UserDropdownType.Booking:
                return 'Booked By';
            case UserDropdownType.Quoting:
                return 'Quoted By';
        }
    }

    console.log(`!!!! Rendering UserDropdown`, { props });
    const users = props.users;
    const branches = props.branches;
    const selectedUserId = props.selectedUserId;
    const filteredUsers = props.users.filter(function (user) {
        return user.userId !== props.user.userId;
    });

    const selectedUser = users.find(function (user) {
        return user.userId === selectedUserId;
    });

    const selectedBranch = branches.find(function (branch) {
        return branch.savedLocationId === props.selectedBranchId;
    });

    function getSelectedName() {
        if (selectedUser) {
            return selectedUser.name;
        }

        if (selectedBranch) {
            return selectedBranch.location.businessName;
        }

        console.log(`@@@ getSelectedName`, { props });

        if (props.selectedBranchId === BranchFilterOption.Uncoded) {
            console.log(`@@@ getSelectedName - returning not coded`, { props });
            return 'Not Coded to Branch';
        }

        return `Any User / Branch`;
    }

    const selectedUserName = getSelectedName();

    console.log(`UserDropdown`, {
        users,
        selectedUser,
        selectedUserName,
        selectedUserId,
    });

    return (
        <Tooltip
            id="userDropdown"
            placement="bottom"
            tooltip={
                <UserDropdownBody>
                    <UserDropdownItem
                        userId={undefined}
                        selectedUserId={
                            props.selectedUserId || props.selectedBranchId
                        }
                        onSelect={function () {
                            props.onSelectUser(undefined);
                            props.onSelectBranch(undefined);
                        }}
                    >
                        Any User / Branch
                    </UserDropdownItem>
                    <UserDropdownDivider />
                    <UserDropdownHeader>You</UserDropdownHeader>
                    <UserDropdownItem
                        userId={props.user.userId}
                        selectedUserId={props.selectedUserId}
                        onSelect={function (userId) {
                            props.onSelectUser(userId);
                            props.onSelectBranch(undefined);
                        }}
                    >
                        {props.user.name}
                    </UserDropdownItem>
                    <UserDropdownDivider />
                    {branches.length > 0 && (
                        <>
                            <UserDropdownHeader>Branches</UserDropdownHeader>
                            <>
                                {branches.map(function (branch) {
                                    return (
                                        <UserDropdownBranchItem
                                            branchId={branch.savedLocationId}
                                            selectedBranchId={
                                                props.selectedBranchId
                                            }
                                            onSelect={function (branchId) {
                                                props.onSelectUser(undefined);
                                                props.onSelectBranch(branchId);
                                            }}
                                        >
                                            {branch.location.businessName}
                                        </UserDropdownBranchItem>
                                    );
                                })}
                                {props.showUncodedOption && (
                                    <UserDropdownBranchItem
                                        branchId="uncoded"
                                        selectedBranchId={
                                            props.selectedBranchId
                                        }
                                        onSelect={function () {
                                            props.onSelectUser(undefined);
                                            props.onSelectBranch(
                                                BranchFilterOption.Uncoded
                                            );
                                        }}
                                    >
                                        Not Coded to Branch
                                    </UserDropdownBranchItem>
                                )}
                            </>
                        </>
                    )}
                    <UserDropdownDivider />
                    <UserDropdownHeader>Other Users</UserDropdownHeader>
                    {filteredUsers.map(function (user) {
                        return (
                            <UserDropdownItem
                                userId={user.userId}
                                selectedUserId={props.selectedUserId}
                                onSelect={function (userId) {
                                    props.onSelectUser(userId);
                                    props.onSelectBranch(undefined);
                                }}
                            >
                                {user.name}
                            </UserDropdownItem>
                        );
                    })}
                    {users.length === 0 && (
                        <UserDropdownNoOtherUsers>
                            You can invite others to this company{' '}
                            <Link to="/manage-users">here</Link>
                        </UserDropdownNoOtherUsers>
                    )}
                </UserDropdownBody>
            }
        >
            <Stack align="right">
                <UserDropdownLabel>{title()}</UserDropdownLabel>
                <UserDropdownValue>{selectedUserName}</UserDropdownValue>
            </Stack>
        </Tooltip>
    );
}
