import { useAuthentication } from 'Features/Authentication/Slices/authenticationSlice';
import { useEffect, useState } from 'react';

export function useVerifyToken() {
    const { verifyToken } = useAuthentication();
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>();

    useEffect(
        function () {
            // Make sure in the background we are actually authenticated
            console.log(`!!! running verifyToken from useEffect`);
            verifyToken();

            // And every 5 minutes verify the token
            if (intervalId === undefined) {
                const iid = setInterval(() => {
                    console.log(`!!! running verifyToken from interval`);
                    verifyToken();
                }, 1000 * 60 * 5);
                setIntervalId(iid);
            }
        },
        // Since intervalId is modified ONLY in the effect, we don't want it as a dep
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
}
