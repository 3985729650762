import { useSavedBrokersApi } from 'apis';
import { UUID } from 'Cargo/Types/types';
import {
    Broker,
    PostSaveBrokersSetPreferredRequest,
    PostSavedBrokersAddRequest,
    PostSavedBrokersDeleteRequest,
    PostSavedBrokersModifyRequest,
    PreferredBrokerType,
    SavedBroker,
} from 'generated-openapi-client';

export function useSavedBrokersService() {
    const savedBrokersApi = useSavedBrokersApi();

    return {
        addBroker: async function (broker: SavedBroker) {
            const request: PostSavedBrokersAddRequest = {
                addSavedBrokerRequest: {
                    broker,
                },
            };

            await savedBrokersApi.postSavedBrokersAdd(request);
        },

        modifyBroker: async function (brokerId: UUID, broker: Broker) {
            const request: PostSavedBrokersModifyRequest = {
                modifySavedBrokerRequest: {
                    savedBrokerId: brokerId,
                    broker: broker as Broker,
                },
            };

            await savedBrokersApi.postSavedBrokersModify(request);
        },

        deleteSavedBroker: async function (savedBrokerId: UUID) {
            const request: PostSavedBrokersDeleteRequest = {
                deleteSavedBrokerRequest: {
                    savedBrokerId: savedBrokerId,
                },
            };
            await savedBrokersApi.postSavedBrokersDelete(request);
        },

        setPreferred: async function (
            savedBrokerId: UUID,
            preferredType: PreferredBrokerType
        ) {
            const request: PostSaveBrokersSetPreferredRequest = {
                setPreferredBrokerRequest: {
                    savedBrokerId,
                    preferredType,
                },
            };

            await savedBrokersApi.postSaveBrokersSetPreferred(request);
        },
    };
}
