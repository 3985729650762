import { AddLocationLocationType } from 'Features/Locations/Types/locationTypes';
import { LatitudeLongitude } from 'generated-openapi-client';
import mapboxgl from 'mapbox-gl';
import { useState } from 'react';
import { latitudeLongitudeToNumbers } from './latitudeLongitudeToNumbers';

function renderPopup(location: Partial<AddLocationLocationType>) {
    const cityState = `${location.address?.city}, ${location.address?.stateOrProvinceCode}`;

    if (location.businessName) {
        return `<div><strong>${location.businessName}</strong></div><div>${cityState}</div>`;
    } else {
        return `<div><strong>${cityState}</strong></div>`;
    }
}

export function useRenderPopups() {
    const [pickupPopup, setPickupPopup] = useState<
        mapboxgl.Popup | undefined
    >();
    const [deliveryPopup, setDeliveryPopup] = useState<
        mapboxgl.Popup | undefined
    >();

    return function renderPopups(
        map: mapboxgl.Map,
        pickup: Partial<AddLocationLocationType> | undefined,
        delivery: Partial<AddLocationLocationType> | undefined
    ) {
        console.log('renderPopups');
        if (pickupPopup !== undefined) {
            pickupPopup.remove();
        }

        if (deliveryPopup !== undefined) {
            deliveryPopup.remove();
        }

        if (
            pickup !== undefined &&
            pickup.latitudeLongitude !== undefined &&
            pickup.latitudeLongitude.latitude !== undefined &&
            pickup.latitudeLongitude.longitude !== undefined
        ) {
            console.log('renderPopups - adding pickup');
            const coords = latitudeLongitudeToNumbers(
                pickup.latitudeLongitude as Required<LatitudeLongitude>
            );

            console.log('renderPopups - adding pickup', { coords });
            setPickupPopup(
                new mapboxgl.Popup({
                    closeOnClick: false,
                    closeButton: false,
                    anchor: 'right',
                })
                    .setLngLat(coords)
                    .setHTML(renderPopup(pickup))
                    .addTo(map)
            );
        }

        if (
            delivery !== undefined &&
            delivery.latitudeLongitude !== undefined &&
            delivery.latitudeLongitude.latitude !== undefined &&
            delivery.latitudeLongitude.longitude !== undefined
        ) {
            const coords = latitudeLongitudeToNumbers(
                delivery.latitudeLongitude as Required<LatitudeLongitude>
            );
            setDeliveryPopup(
                new mapboxgl.Popup({
                    closeOnClick: false,
                    closeButton: false,
                    anchor: 'left',
                })
                    .setLngLat(coords)
                    .setHTML(renderPopup(delivery))
                    .addTo(map)
            );
        }
    };
}
