/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    contactName: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    phoneNumberExtension?: string;
}

/**
 * Check if a given object implements the Contact interface.
 */
export function instanceOfContact(value: object): boolean {
    if (!('contactName' in value)) return false;
    if (!('phoneNumber' in value)) return false;
    return true;
}

export function ContactFromJSON(json: any): Contact {
    return ContactFromJSONTyped(json, false);
}

export function ContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): Contact {
    if (json == null) {
        return json;
    }
    return {
        
        'emailAddress': json['emailAddress'] == null ? undefined : json['emailAddress'],
        'contactName': json['contactName'],
        'phoneNumber': json['phoneNumber'],
        'phoneNumberExtension': json['phoneNumberExtension'] == null ? undefined : json['phoneNumberExtension'],
    };
}

export function ContactToJSON(value?: Contact | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'emailAddress': value['emailAddress'],
        'contactName': value['contactName'],
        'phoneNumber': value['phoneNumber'],
        'phoneNumberExtension': value['phoneNumberExtension'],
    };
}

