import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { H3 } from 'Cargo/Text/Text';
import { UUID } from 'Cargo/Types/types';
import { LogisticsQuoteRow } from 'Features/BookShipment/Components/DedicatedLogistics/LogisticsQuoteRow';
import QuoteRow from 'Features/Quotes/Components/QuoteRow/QuoteRow';
import { SortQuotesOptions } from 'Features/Quotes/Components/SortQuotes';
import {
    EquipmentType,
    LineItem,
    PreQuoteLocation,
    Quote,
    SharedDedicated,
} from 'generated-openapi-client';
import { Currency } from 'generated-openapi-client/models/Currency';
import _ from 'lodash';

interface ViewQuotesProps {
    shipmentId: UUID;
    pickupDate: moment.Moment;
    pickupDeadline: string | undefined;
    quotes: Readonly<Array<Quote>>;
    sortBy: SortQuotesOptions;
    onBook: (quoteId: UUID) => void;
    pickupLocation: PreQuoteLocation;
    deliveryLocation: PreQuoteLocation;
    deliveryDeadline: string | undefined;
    lineItems: Array<LineItem>;
    linearFeet: number | undefined;
    notes: string | undefined;
    addInsuranceToShipment: boolean;
    insuranceAmount: number;
    insuranceCurrency: Currency;
    equipmentType: EquipmentType | undefined;
    exclusiveUse: boolean;
    tarpRequired: boolean;
    onRequestManualQuotes: (
        shipmentId: UUID,
        equipmentType: EquipmentType,
        sharedDedicated: SharedDedicated,
        tarpRequired: boolean | undefined,
        linearFeet: number | undefined,
        notes: string
    ) => Promise<void>;
}

function ViewQuotes(props: ViewQuotesProps) {
    const {
        quotes,
        onBook,
        pickupDate,
        pickupLocation,
        deliveryLocation,
        deliveryDeadline,
        lineItems,
        linearFeet,
        notes,
        onRequestManualQuotes,
        sortBy,
        addInsuranceToShipment,
        insuranceAmount,
        insuranceCurrency,
        equipmentType,
        exclusiveUse,
        tarpRequired,
        pickupDeadline,
    } = props;

    if (!quotes) {
        throw new Error('Missing quotes');
    }

    if (quotes.length === 0) {
        return <></>;
    } else if (quotes.length === 1) {
        return (
            <>
                <QuoteRow
                    pickupDeadline={pickupDeadline}
                    pickupDate={pickupDate}
                    pickupLocation={pickupLocation}
                    deliveryLocation={deliveryLocation}
                    lineItems={lineItems}
                    key={quotes[0].quoteId}
                    quote={quotes[0]}
                    onSelectQuote={() => {
                        onBook(quotes[0].quoteId);
                    }}
                    notes={notes}
                    addInsuranceToShipment={addInsuranceToShipment}
                    insuranceAmount={insuranceAmount}
                    insuranceCurrency={insuranceCurrency}
                    deliveryDeadline={deliveryDeadline}
                ></QuoteRow>
                <LogisticsQuoteRow
                    pickupDeadline={pickupDeadline}
                    shipmentId={props.shipmentId}
                    pickupDate={pickupDate.toISOString()}
                    pickupLocation={pickupLocation}
                    deliveryLocation={deliveryLocation}
                    lineItems={lineItems}
                    onRequestManualQuotes={onRequestManualQuotes}
                    equipmentType={equipmentType}
                    exclusiveUse={exclusiveUse}
                    linearFeet={linearFeet}
                    tarpRequired={tarpRequired}
                    deliveryDeadline={deliveryDeadline}
                />
            </>
        );
    } else {
        const firstQuote = quotes[0];
        const otherQuotes = quotes.slice(1);

        // Show the logistics offer if we have fewer than 4 carriers returning quotes
        const uniqueCarrierCount = _.uniq(
            quotes.map((q) => q.carrierIdentifier)
        ).length;
        const noCheapQuotes = quotes.every(function (q) {
            q.price > 3500;
        });

        const showLogistics = uniqueCarrierCount < 4 || noCheapQuotes;

        const sortByDescription =
            sortBy === SortQuotesOptions.SortByLowestPrice
                ? 'Lowest Price'
                : 'Fastest Quote';

        return (
            <>
                <Spacer height={32} />
                <Stack width="100%" align="left" style={{ marginLeft: '92px' }}>
                    <H3>{sortByDescription}</H3>
                </Stack>
                <Spacer height={8} />
                <QuoteRow
                    pickupDeadline={pickupDeadline}
                    pickupDate={pickupDate}
                    pickupLocation={pickupLocation}
                    deliveryLocation={deliveryLocation}
                    lineItems={lineItems}
                    key={firstQuote.quoteId}
                    quote={firstQuote}
                    onSelectQuote={() => {
                        onBook(firstQuote.quoteId);
                    }}
                    notes={notes}
                    addInsuranceToShipment={addInsuranceToShipment}
                    insuranceAmount={insuranceAmount}
                    insuranceCurrency={insuranceCurrency}
                    deliveryDeadline={deliveryDeadline}
                ></QuoteRow>

                <Spacer height={32} />
                <Stack width="100%" align="left" style={{ marginLeft: '92px' }}>
                    <H3>Other Quotes</H3>
                </Stack>
                <Spacer height={8} />

                {otherQuotes.map((quote: Quote) => {
                    return (
                        <QuoteRow
                            pickupDeadline={pickupDeadline}
                            pickupDate={pickupDate}
                            pickupLocation={pickupLocation}
                            deliveryLocation={deliveryLocation}
                            lineItems={lineItems}
                            key={quote.quoteId}
                            quote={quote}
                            onSelectQuote={() => {
                                onBook(quote.quoteId);
                            }}
                            notes={notes}
                            addInsuranceToShipment={addInsuranceToShipment}
                            insuranceAmount={insuranceAmount}
                            insuranceCurrency={insuranceCurrency}
                            deliveryDeadline={deliveryDeadline}
                        ></QuoteRow>
                    );
                })}

                {showLogistics && (
                    <LogisticsQuoteRow
                        pickupDeadline={pickupDeadline}
                        shipmentId={props.shipmentId}
                        pickupDate={pickupDate.toISOString()}
                        pickupLocation={pickupLocation}
                        deliveryLocation={deliveryLocation}
                        deliveryDeadline={deliveryDeadline}
                        lineItems={lineItems}
                        onRequestManualQuotes={onRequestManualQuotes}
                        linearFeet={linearFeet}
                        equipmentType={equipmentType}
                        exclusiveUse={exclusiveUse}
                        tarpRequired={tarpRequired}
                    />
                )}
            </>
        );
    }

    return <></>;
}

export default ViewQuotes;
