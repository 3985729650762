import Colors from 'Cargo/Colors';
import Icon from 'Cargo/Icons/Icon';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';

interface CirclePartProps {
    color: string;
    textColor: string;
    index: number;
    showCheck: boolean;
}

function CirclePart(props: CirclePartProps) {
    const radius = 16;
    return (
        <div style={{ zIndex: 100 }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={radius * 2}
                height={radius * 2}
                viewBox={`0 0 ${radius * 2} ${radius * 2}`}
            >
                <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                    <g transform="translate(-272 -1298)">
                        <g transform="translate(251 1083)">
                            <g fill={props.color} transform="translate(21 215)">
                                <circle
                                    cx={radius}
                                    cy={radius}
                                    r={radius}
                                ></circle>
                                {!props.showCheck && (
                                    <text
                                        fill={props.textColor}
                                        fontFamily="NeueHaasUnicaW1G-Bold, Neue Haas Unica W1G"
                                        fontSize={16}
                                        fontWeight="bold"
                                    >
                                        <tspan x="11" y="22">
                                            {props.index}
                                        </tspan>
                                    </text>
                                )}
                                {props.showCheck && (
                                    <g transform="translate(6, 6) scale(0.6)">
                                        <Icon
                                            size={45}
                                            solid={true}
                                            color={Colors.NormalText}
                                            name="check"
                                        />
                                    </g>
                                )}
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
}

interface LinePartProps {
    color: string;
}

function LinePart(props: LinePartProps) {
    return (
        <div
            style={{
                position: 'relative',
                backgroundColor: props.color,
                width: '100%',
                height: '4px',
                marginLeft: '16px',
                marginRight: '16px',
            }}
        ></div>
    );
}

interface StagesProps {
    currentStage: number;
    totalStages: number;
}

export function Stages(props: StagesProps) {
    const items = Array.from(Array(props.totalStages).keys());

    function colorForCircle(index: number) {
        if (index < props.currentStage) {
            return Colors.Green;
        } else if (index === props.currentStage) {
            return Colors.Blue;
        } else {
            return Colors.LightGray;
        }
    }

    function colorForText(index: number) {
        if (index === props.currentStage) {
            return Colors.White;
        } else {
            return Colors.NormalText;
        }
    }

    return (
        <HorizontalStack>
            {items.map(function (index) {
                return (
                    <>
                        <CirclePart
                            color={colorForCircle(index)}
                            textColor={colorForText(index)}
                            index={index + 1}
                            showCheck={index < props.currentStage}
                        />
                        {index !== props.totalStages - 1 && (
                            <LinePart color={colorForCircle(index + 1)} />
                        )}
                    </>
                );
            })}
        </HorizontalStack>
    );
}
