import { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components/macro';

interface LinkProps {
    onClick?: () => void;
    children: ReactNode;
    to?: string;
    newTab?: boolean;
    style?: CSSProperties;
}

const LinkBody = styled.a`
    color: var(--freightsimple-color-blue);
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    &:not([href]) {
        color: var(--freightsimple-color-blue);
    }

    &:not([href]):hover {
        text-decoration: underline;
        color: var(--freightsimple-color-blue);
    }
`;

// Simple Link component for when we want to make part of some text clickable
// LinkButton brings too much styling/weight/opinions, so we have to fight against what
// it is trying to do for this use case
function Link(props: LinkProps) {
    return (
        <LinkBody
            onClick={props.onClick}
            href={props.to}
            target={props.newTab ? '_new' : undefined}
            style={props.style}
        >
            {props.children}
        </LinkBody>
    );
}
export default Link;
