import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { animated, config, useSpring, useTransition } from '@react-spring/web';
import Colors from 'Cargo/Colors';
import Icon from 'Cargo/Icons/Icon';
import ProgressSpinner, {
    ProgressSpinnerSizes,
} from 'Cargo/Icons/ProgressSpinner';
import FirstToMatch from 'Cargo/Layout/FirstToMatch';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components/macro';

interface ButtonProps {
    /** Size of the button */
    size?: 'small' | 'large' | 'xl';

    /** The label to display on the button */
    label?: string;
    children?: ReactNode;

    /** Display a right arrow on the button */
    arrow?: 'left' | 'right';

    // FontAwesome icon name
    icon?: IconName;

    onClick?: (_: void) => void;

    width?: string;

    id?: string;

    disabled?: boolean;

    secondary?: boolean;
    danger?: boolean;

    loading?: boolean;
    loadingSuccess?: boolean;
    loadingFailure?: boolean;

    ariaLabel?: string;

    textStyle?: CSSProperties;
    className?: string;
    stackStyle?: CSSProperties;
}

interface ButtonBodyProps {
    width?: string;
    secondary?: boolean;
    danger?: boolean;
}

interface ButtonTextProps {
    secondary?: boolean;
    disabled: boolean;
    danger?: boolean;
}

function calculateHoverBrightness(props: ButtonBodyProps) {
    if (props.secondary) {
        return 1.05
    }
    return 1.2
}

function calculateBackgroundColor(props: ButtonBodyProps) {
    if (props.secondary && !props.danger) {
        return Colors.VeryVeryLightBlue
    }

    if (props.secondary && props.danger) {
        return Colors.LightRed
    }

    if (props.danger) {
        return Colors.Red
    }

    return 'var(--freightsimple-color-blue)'
}

const SmallButtonBody = styled.div<ButtonBodyProps>`
    cursor: pointer;

    &:hover {
        filter: brightness(${props => calculateHoverBrightness(props)});
    }

    &.disabled:hover {
        filter: brightness(1);
    }

    &.disabled {
        background-color: ${Colors.LightGray};
        cursor: not-allowed;
    }

    background-color: ${props => calculateBackgroundColor(props)};
    border-radius: 8px;
    min-width: 155px;
    height: 31px;
    padding: 5px 22px;
    ${(props) => (props.width ? `width: ${props.width}` : '')};
`;

function getButtonText(props: ButtonTextProps) {
    if (props.disabled) {
        return Colors.LightText;
    }

    if (props.secondary && !props.danger) {
        return Colors.Blue;
    }

    if (props.secondary && props.danger) {
        return Colors.NormalText
    }

    if (props.danger) {
        return Colors.White
    }

    return Colors.White;
}

const SmallButtonText = styled.div<ButtonTextProps>`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 14px;
    text-align: center;
    position: relative;
    color: ${(props) => getButtonText(props)};
`;

const LargeButtonBody = styled.div<ButtonBodyProps>`
    cursor: pointer;

    &:hover {
        filter: brightness(${calculateHoverBrightness});
    }

    &.disabled:hover {
        filter: brightness(1);
    }

    &.disabled {
        background-color: ${Colors.LightGray};
        cursor: not-allowed;
    }

    &:focus {
    }

    background-color: ${props => calculateBackgroundColor(props)};
    border-radius: 10px;
    min-width: 210px;
    height: 42px;
    padding: 7px 24px;
    ${(props) => (props.width ? `width: ${props.width}` : '')};
`;

const ExtraLargeButtonBody = styled.div<ButtonBodyProps>`
    cursor: pointer;

    &:hover {
        filter: brightness(${props => calculateHoverBrightness(props)});
    }

    &.disabled:hover {
        filter: brightness(1);
    }

    &.disabled {
        background-color: ${Colors.LightGray};
        cursor: not-allowed;
    }

    &:focus {
    }

    background-color: ${calculateBackgroundColor};
    border-radius: 10px;
    min-width: 250px;
    height: 52px;
    padding: 7px 24px;
    ${(props) => (props.width ? `width: ${props.width}` : '')};
`;

const LargeButtonText = styled.div<ButtonTextProps>`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 18px;
    color: ${(props) => getButtonText(props)};
    text-align: center;
`;

const ExtraLargeButtonText = styled.div<ButtonTextProps>`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 22px;
    color: ${(props) => getButtonText(props)};
    text-align: center;
`;

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
    const buttonSize = props.size || 'small';

    function onClick() {
        if (props.disabled) {
            return;
        }

        return props.onClick && props?.onClick();
    }

    function calculateIconSize() {
        switch (buttonSize) {
            case 'small':
                return '14px';
            case 'large':
                return '18px';
            case 'xl':
                return '22px';
        }
    }

    const iconSize = calculateIconSize();

    function arrowIconColor(props: ButtonProps): string {
        if (props.secondary && !props.danger) {
            return Colors.Blue
        }

        if (props.secondary && props.danger) {
            return Colors.NormalText
        }

        if (props.danger) {
            return Colors.White
        }

        return 'white'
    }

    function rightArrow() {
        if (props.arrow === 'right') {
            return (
                <FontAwesomeIcon
                    icon={['fas', 'arrow-circle-right']}
                    style={{
                        width: iconSize,
                        height: iconSize,
                        marginLeft: '5px',
                        color: arrowIconColor(props)
                    }}
                />
            );
        } else {
            return <></>;
        }
    }

    function rightIcon() {
        if (props.icon !== undefined) {
            return (
                <FontAwesomeIcon
                    icon={['fas', props.icon]}
                    style={{
                        width: iconSize,
                        height: iconSize,
                        marginLeft: '5px',
                        color: arrowIconColor(props)
                    }}
                />
            );
        } else {
            return <></>;
        }
    }

    function leftArrow() {
        if (props.arrow === 'left') {
            return (
                <FontAwesomeIcon
                    icon={['fas', 'arrow-circle-left']}
                    style={{
                        width: iconSize,
                        height: iconSize,
                        marginRight: '5px',
                        color: arrowIconColor(props)
                    }}
                />
            );
        } else {
            return <></>;
        }
    }

    function buttonBody() {
        function buttonBody() {
            switch (buttonSize) {
                case 'small':
                    return SmallButtonBody;
                case 'large':
                    return LargeButtonBody;
                case 'xl':
                    return ExtraLargeButtonBody;
            }
        }

        function buttonText() {
            switch (buttonSize) {
                case 'small':
                    return SmallButtonText;
                case 'large':
                    return LargeButtonText;
                case 'xl':
                    return ExtraLargeButtonText;
            }
        }

        const ButtonBody = buttonBody();
        const ButtonText = buttonText();

        return (
            <ButtonBody
                onClick={onClick}
                width={props.width}
                id={props.id}
                className={`${props.disabled ? 'disabled' : 'enabled'}${props.className ? ' ' + props.className : ''
                    }`}
                secondary={props.secondary}
                danger={props.danger}
                aria-label={props.ariaLabel}
            >
                <ButtonText
                    secondary={props.secondary}
                    danger={props.danger}
                    style={props.textStyle}
                    disabled={props.disabled || false}
                >
                    {leftArrow()}
                    {props.label}
                    {props.children}
                    {rightArrow()}
                    {rightIcon()}
                </ButtonText>
            </ButtonBody>
        );
    }

    function failureSuccessIcons() {
        return (
            <FirstToMatch
                components={[
                    {
                        when: props.loadingFailure && !props.loading,
                        show: (
                            <Icon
                                name="times"
                                color={Colors.Red}
                                size={16}
                                solid
                                style={{ marginLeft: '16px' }}
                            />
                        ),
                    },
                    {
                        when: props.loadingSuccess && !props.loading,
                        show: (
                            <Icon
                                name="check"
                                color={Colors.Green}
                                size={16}
                                solid
                                style={{ marginLeft: '16px' }}
                            />
                        ),
                    },
                ]}
            />
        );
    }

    return (
        <HorizontalStack style={props.stackStyle}>
            {buttonBody()}
            <LoadingSpinner loading={props.loading ?? false} />
            {failureSuccessIcons()}
        </HorizontalStack>
    );
};

function LoadingSpinner({ loading }: { loading: boolean }) {
    const widthAnimation = useSpring({
        display: 'grid',
        gridTemplateColumns: loading ? '1fr' : '0fr',
        config: { ...config.gentle },
    });

    const transitions = useTransition(loading, {
        from: { opacity: 0, scale: 0, x: -8 },
        enter: { opacity: 1, scale: 1, x: 8 },
        leave: { opacity: 0, scale: 0, x: -8 },
    });

    return (
        <animated.div style={{ ...widthAnimation }}>
            {transitions(
                (style, item) =>
                    item && (
                        <animated.div style={{ ...style, overflow: 'hidden' }}>
                            <ProgressSpinner
                                size={ProgressSpinnerSizes.Small}
                            />
                        </animated.div>
                    )
            )}
        </animated.div>
    );
}

export default Button;
