/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PreferredBrokerType } from './PreferredBrokerType';
import {
    PreferredBrokerTypeFromJSON,
    PreferredBrokerTypeFromJSONTyped,
    PreferredBrokerTypeToJSON,
} from './PreferredBrokerType';

/**
 * 
 * @export
 * @interface SetPreferredBrokerRequest
 */
export interface SetPreferredBrokerRequest {
    /**
     * 
     * @type {string}
     * @memberof SetPreferredBrokerRequest
     */
    savedBrokerId: string;
    /**
     * 
     * @type {PreferredBrokerType}
     * @memberof SetPreferredBrokerRequest
     */
    preferredType: PreferredBrokerType;
}

/**
 * Check if a given object implements the SetPreferredBrokerRequest interface.
 */
export function instanceOfSetPreferredBrokerRequest(value: object): boolean {
    if (!('savedBrokerId' in value)) return false;
    if (!('preferredType' in value)) return false;
    return true;
}

export function SetPreferredBrokerRequestFromJSON(json: any): SetPreferredBrokerRequest {
    return SetPreferredBrokerRequestFromJSONTyped(json, false);
}

export function SetPreferredBrokerRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetPreferredBrokerRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'savedBrokerId': json['savedBrokerId'],
        'preferredType': PreferredBrokerTypeFromJSON(json['preferredType']),
    };
}

export function SetPreferredBrokerRequestToJSON(value?: SetPreferredBrokerRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'savedBrokerId': value['savedBrokerId'],
        'preferredType': PreferredBrokerTypeToJSON(value['preferredType']),
    };
}

