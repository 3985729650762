/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LookUpEmailResponse
 */
export interface LookUpEmailResponse {
    /**
     * 
     * @type {boolean}
     * @memberof LookUpEmailResponse
     */
    isUsed: boolean;
}

/**
 * Check if a given object implements the LookUpEmailResponse interface.
 */
export function instanceOfLookUpEmailResponse(value: object): boolean {
    if (!('isUsed' in value)) return false;
    return true;
}

export function LookUpEmailResponseFromJSON(json: any): LookUpEmailResponse {
    return LookUpEmailResponseFromJSONTyped(json, false);
}

export function LookUpEmailResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LookUpEmailResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'isUsed': json['isUsed'],
    };
}

export function LookUpEmailResponseToJSON(value?: LookUpEmailResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isUsed': value['isUsed'],
    };
}

