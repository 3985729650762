import Dropdown from 'Cargo/Controls/Dropdown';
import { ErrorMessageType } from 'Cargo/Validation';
import { getTemperatureHandlingTypesForDropdown } from 'Data/TemperatureHandlingTypes';
import { TemperatureHandling } from 'generated-openapi-client';
import React from 'react';
import { TemperatureHandlingQuestionBubble } from './QuestionBubbles/TemperatureHandlingQuestionBubble';

interface TemperatureHandlingControlProps {
    temperatureHandling: TemperatureHandling;
    onChange: (value: TemperatureHandling) => void;
    errorMessage: ErrorMessageType;
    forceValidation: boolean;
}

const TemperatureHandlingControl: React.FC<TemperatureHandlingControlProps> = (
    props: TemperatureHandlingControlProps
) => {
    return (
        <Dropdown
            placeholder={
                <>
                    Temperature Requirements
                    <TemperatureHandlingQuestionBubble />
                </>
            }
            onOptionSelected={(value) => {
                props.onChange(value);
            }}
            options={getTemperatureHandlingTypesForDropdown()}
            selectedItem={props.temperatureHandling}
            width={407}
            errorMessage={props.errorMessage}
            forceValidation={props.forceValidation}
        ></Dropdown>
    );
};
export default TemperatureHandlingControl;
