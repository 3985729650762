/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NmfcSubItemChoice
 */
export interface NmfcSubItemChoice {
    /**
     * 
     * @type {string}
     * @memberof NmfcSubItemChoice
     */
    freightClass: string;
    /**
     * 
     * @type {string}
     * @memberof NmfcSubItemChoice
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof NmfcSubItemChoice
     */
    nmfcCodeWithSubItem: string;
}

/**
 * Check if a given object implements the NmfcSubItemChoice interface.
 */
export function instanceOfNmfcSubItemChoice(value: object): boolean {
    if (!('freightClass' in value)) return false;
    if (!('description' in value)) return false;
    if (!('nmfcCodeWithSubItem' in value)) return false;
    return true;
}

export function NmfcSubItemChoiceFromJSON(json: any): NmfcSubItemChoice {
    return NmfcSubItemChoiceFromJSONTyped(json, false);
}

export function NmfcSubItemChoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): NmfcSubItemChoice {
    if (json == null) {
        return json;
    }
    return {
        
        'freightClass': json['freightClass'],
        'description': json['description'],
        'nmfcCodeWithSubItem': json['nmfcCodeWithSubItem'],
    };
}

export function NmfcSubItemChoiceToJSON(value?: NmfcSubItemChoice | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'freightClass': value['freightClass'],
        'description': value['description'],
        'nmfcCodeWithSubItem': value['nmfcCodeWithSubItem'],
    };
}

