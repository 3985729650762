import { MicroErrorQuestionBubble } from 'Cargo/Controls/QuestionBubble';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import { ErrorMessageType, ErrorObjectType } from 'Cargo/Validation';
import TooLightQuestionBubble from 'Features/LineItems/Components/LineItem/QuestionBubbles/TooLightQuestionBubble';
import { AddLineItemType } from 'Features/LineItems/Types/lineItemTypes';
import { errorMessageForFreightClass } from 'Features/Locations/Validators/errorMessageForFreightClass';
import { TemperatureHandling } from 'generated-openapi-client';
import moment from 'moment';
import TooManyHandlingUnitsQuestionBubble from '../Components/LineItem/QuestionBubbles/TooManyHandlingUnitsQuestionBubble';

export interface LineItemErrors extends ErrorObjectType {
    handlingUnit: ErrorMessageType;
    description: ErrorMessageType;
    length: ErrorMessageType;
    width: ErrorMessageType;
    height: ErrorMessageType;
    weight: ErrorMessageType;
    isDangerous: ErrorMessageType;
    temperatureHandling: ErrorMessageType;
    isStackable: ErrorMessageType;
    freightClass: ErrorMessageType;
}

const prohibitedDescriptions = [
    'Antique',
    'Antiques',
    'Art',
    'Works of Art',
    'Original Art',
    'Painting',
    'Paintings',
    'Artwork',
    'Artworks',
    'Precious Stones',
    'Jewelry',
    'Currency',
    'Blood',
    'Corpse',
    'Corpses',
    'ATV',
    'All Terrain Vehicle',
    'Car',
    'Motorcycle',
    'Seadoo',
    'Skidoo',
    'Snow mobile',
    'Snowmobile',
    'Dirt bike',
    'Quad bike',
    'Original Stone Sculpture',
    'Original Sculpture',
];

function errorMessageForDescriptionForProhibitedCommodity(description: string) {
    const vd = prohibitedDescriptions.map((d) => d.toLowerCase());
    if (vd.includes(description.toLowerCase())) {
        return 'This appears to be a prohibited item';
    } else {
        return undefined;
    }
}

export function errorMessageForDescription(
    description: string | undefined
): ErrorMessageType {
    if (description === undefined || description === '') {
        return 'Required';
    }

    const MINIMUM_DESCRIPTION_CHARACTERS = 5;

    if (description.length < MINIMUM_DESCRIPTION_CHARACTERS) {
        return `Should be at least ${MINIMUM_DESCRIPTION_CHARACTERS} characters`;
    }

    return errorMessageForDescriptionForProhibitedCommodity(description);
}

function errorMessageForSingleDimension(
    value: number | undefined,
    adjective: string
): ErrorMessageType {
    if (value === undefined) {
        return 'Required';
    }

    if (value === 0) {
        return 'Should not be zero';
    }

    if (value < 0) {
        return 'Should not be negative';
    }

    // Check it's not bigger than 53ft - which is the length of a truck
    if (value > 53 * 12) {
        return 'Too ' + adjective;
    }
}

function errorMessageForHeight(lineItem: AddLineItemType): ErrorMessageType {
    const value = lineItem.height;
    if (value === undefined || value === null) {
        return 'Required';
    }

    const errorMessage = errorMessageForSingleDimension(value, 'tall');

    if (errorMessage !== undefined) {
        return errorMessage;
    }

    if (value > 102) {
        return 'Too tall';
    }

    if (
        lineItem.width !== undefined &&
        lineItem.length !== undefined &&
        lineItem.height !== undefined &&
        lineItem.numberHandlingUnits !== undefined
    ) {
        const totalVolumeCubicFt =
            (lineItem.width *
                lineItem.length *
                lineItem.height *
                lineItem.numberHandlingUnits) /
            (12 * 12 * 12);
        if (totalVolumeCubicFt > 53 * 8 * 8) {
            // The actual error message is on length
            return <span>{'\u00A0'}</span>;
        }

        if (totalVolumeCubicFt < 1) {
            // The actual error message is on length
            return <span>{'\u00A0'}</span>;
        }
    }
}

// This used to be 96x96, but now we're allowing flatbed, make it essentially the
// size of a flatbed
function isTooLarge(width: number, length: number) {
    if (length > 50 * 12 && width > 96) {
        return true;
    } else {
        return false;
    }
}

function errorMessageForWidth(lineItem: AddLineItemType): ErrorMessageType {
    const widthValue = lineItem.width;
    const lengthValue = lineItem.length;
    if (widthValue === undefined || widthValue === null) {
        return 'Required';
    }

    const errorMessage = errorMessageForSingleDimension(widthValue, 'wide');

    if (errorMessage !== undefined) {
        return errorMessage;
    }

    if (lengthValue !== undefined) {
        if (isTooLarge(widthValue, lengthValue)) {
            // The actual error message is on length
            // This is to pad the space
            return <span>{'\u00A0'}</span>;
        }
    }

    if (
        lineItem.width !== undefined &&
        lineItem.length !== undefined &&
        lineItem.height !== undefined &&
        lineItem.numberHandlingUnits !== undefined
    ) {
        const totalVolumeCubicFt =
            (lineItem.width *
                lineItem.length *
                lineItem.height *
                lineItem.numberHandlingUnits) /
            (12 * 12 * 12);
        if (totalVolumeCubicFt > 53 * 8 * 8) {
            // The actual error message is on length
            return <span>{'\u00A0'}</span>;
        }

        if (totalVolumeCubicFt < 1) {
            // The actual error message is on length
            return <span>{'\u00A0'}</span>;
        }
    }
}

function errorMessageForLength(lineItem: AddLineItemType): ErrorMessageType {
    if (lineItem.length === undefined || lineItem.length === null) {
        return 'Required';
    }

    const errorMessage = errorMessageForSingleDimension(
        lineItem.length,
        'long'
    );

    if (errorMessage !== undefined) {
        return errorMessage;
    }

    if (lineItem.width !== undefined) {
        if (isTooLarge(lineItem.width, lineItem.length)) {
            return 'Too large';
        }
    }

    if (
        lineItem.width !== undefined &&
        lineItem.length !== undefined &&
        lineItem.height !== undefined &&
        lineItem.numberHandlingUnits !== undefined
    ) {
        const totalVolumeCubicFt =
            (lineItem.width *
                lineItem.length *
                lineItem.height *
                lineItem.numberHandlingUnits) /
            (12 * 12 * 12);
        if (totalVolumeCubicFt > 53 * 8 * 8) {
            return 'Too large';
        }

        if (totalVolumeCubicFt < 1) {
            return 'Too small';
        }
    }
}

function errorMessageForWeight(
    weightPerHandlingUnit: number | undefined,
    totalWeightForShipment: number | undefined
): ErrorMessageType {
    if (weightPerHandlingUnit === undefined || weightPerHandlingUnit === null) {
        return 'Required';
    }

    if (weightPerHandlingUnit === 0) {
        return 'Should not be zero';
    }

    if (totalWeightForShipment !== undefined && totalWeightForShipment < 60) {
        return (
            <HorizontalStack>
                Too light <TooLightQuestionBubble />
            </HorizontalStack>
        );
    }
    if (weightPerHandlingUnit < 20) {
        return (
            <HorizontalStack>
                Too light <TooLightQuestionBubble />
            </HorizontalStack>
        );
    }

    // 48,000 is the max a flatbed can handle
    if (totalWeightForShipment && totalWeightForShipment > 48000) {
        return (
            <>
                Too heavy{' '}
                <MicroErrorQuestionBubble
                    description={
                        <div style={{ width: '300px' }}>
                            <strong>Too heavy</strong>
                            <Spacer height={8} />
                            <p>
                                Total pallets must be no more than{' '}
                                <strong>48000lb</strong>.
                            </p>
                            <p>
                                Please make sure to enter the weight of each
                                handling unit, not the combined weight of the
                                shipment.
                            </p>
                        </div>
                    }
                />
            </>
        );
    }
}

function checkValid(errorMessages: LineItemErrors) {
    if (
        errorMessages.description ||
        errorMessages.handlingUnit ||
        errorMessages.height ||
        errorMessages.isDangerous ||
        errorMessages.isStackable ||
        errorMessages.length ||
        errorMessages.temperatureHandling ||
        errorMessages.weight ||
        errorMessages.width ||
        errorMessages.freightClass
    ) {
        return false;
    } else {
        return true;
    }
}

// We used to have error messages for some temperature handling (eg. frozen) but now we accept them
export function errorMessageForTemperatureHandling(
    // Saved commodities have no pickupDate
    pickupDate: string | undefined,
    temperatureHandling: TemperatureHandling
): ErrorMessageType {
    if (temperatureHandling === TemperatureHandling.ProtectFromFreezing) {
        if (pickupDate !== undefined) {
            // Not supported April(3)-October(9)
            const monthValue = moment(pickupDate).month(); // Should be 0-11
            if (monthValue >= 3 && monthValue <= 9) {
                return `Not supported April-October`;
            }
        }
    }
    return undefined;
}

function errorMessageForHandlingUnit(
    handlingUnitType: string | undefined,
    numberHandlingUnits: number
) {
    if (handlingUnitType === undefined) {
        return 'Required';
    }

    if (numberHandlingUnits > 100) {
        return (
            <>
                Too many handling units <TooManyHandlingUnitsQuestionBubble />
            </>
        );
    }

    if (handlingUnitType === 'Other') {
        return 'Contact support to check this is shippable';
    }

    if (handlingUnitType === 'Loose') {
        return 'Not permitted. Must be properly package for shipping';
    }

    return undefined;
}

export function errorMessagesForLineItem(
    pickupDate: string,
    li: AddLineItemType,
    totalWeightForShipment: number | undefined,
    totalHandlingUnitsForShipment: number,
    isFreightClassRequired: boolean
): { errorMessages: LineItemErrors; isValid: boolean } {
    const errorMessages = {
        handlingUnit: errorMessageForHandlingUnit(
            li.handlingUnitType,
            totalHandlingUnitsForShipment
        ),
        description: errorMessageForDescription(li.description),
        length: errorMessageForLength(li),
        width: errorMessageForWidth(li),
        height: errorMessageForHeight(li),
        weight: errorMessageForWeight(
            li.weightPerHandlingUnit,
            totalWeightForShipment
        ),
        isDangerous: undefined,
        temperatureHandling: errorMessageForTemperatureHandling(
            pickupDate,
            li.temperatureHandling
        ),
        isStackable: li.isStackable !== undefined ? undefined : 'Required',
        freightClass: errorMessageForFreightClass(
            li.freightClass,
            isFreightClassRequired
        ),
    };

    const isValid = checkValid(errorMessages);

    console.log(`errorMessagesForLineItem`, { errorMessages, isValid });

    return { errorMessages, isValid };
}
