// eslint-disable-next-line local-rules/no-import-styled
import styled from 'styled-components/macro';

export const Divider = styled.div`
    width: 700px;
    height: 1px;
    background-color: #ccc;
    margin-top: 60px;
    margin-bottom: 60px;
`;
