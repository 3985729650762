/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SavedBroker } from './SavedBroker';
import {
    SavedBrokerFromJSON,
    SavedBrokerFromJSONTyped,
    SavedBrokerToJSON,
} from './SavedBroker';

/**
 * 
 * @export
 * @interface AddSavedBrokerRequest
 */
export interface AddSavedBrokerRequest {
    /**
     * 
     * @type {SavedBroker}
     * @memberof AddSavedBrokerRequest
     */
    broker: SavedBroker;
}

/**
 * Check if a given object implements the AddSavedBrokerRequest interface.
 */
export function instanceOfAddSavedBrokerRequest(value: object): boolean {
    if (!('broker' in value)) return false;
    return true;
}

export function AddSavedBrokerRequestFromJSON(json: any): AddSavedBrokerRequest {
    return AddSavedBrokerRequestFromJSONTyped(json, false);
}

export function AddSavedBrokerRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddSavedBrokerRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'broker': SavedBrokerFromJSON(json['broker']),
    };
}

export function AddSavedBrokerRequestToJSON(value?: AddSavedBrokerRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'broker': SavedBrokerToJSON(value['broker']),
    };
}

