import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CouponSliceType {
    amount?: number;
}

export function emptyCouponSlice(): CouponSliceType {
    return {
        amount: undefined,
    };
}

function initialState(): CouponSliceType {
    return emptyCouponSlice();
}

export const couponSlice = createSlice({
    name: 'authentication',
    initialState: initialState(),
    reducers: {
        storeCoupon(
            state,
            action: PayloadAction<{
                amount: number | undefined;
            }>
        ) {
            state.amount = action.payload.amount;
        },
    },
});

export const { storeCoupon } = couponSlice.actions;
