import SlideDown from 'Cargo/Animations/SlideDown';
import RevealButton from 'Cargo/Controls/RevealButton';
import { useHeight } from 'Cargo/Helpers/useHeight';
import { ReactElement, ReactNode, useState } from 'react';
import { animated, config, useSpring } from '@react-spring/web';
import Box from './Box';
import HorizontalStack from './HorizontalStack';
import Spacer from './Spacer';
import Stack from './Stack';

interface RevealBoxProps {
    header: (open: boolean) => ReactNode;
    body: (open: boolean) => ReactNode;
}

interface AnimatedContainerProps {
    open: boolean;
    children: ReactNode;
}

function AnimatedContainer(props: AnimatedContainerProps): ReactElement {
    const [heightRef, height] = useHeight();
    const slideInStyles = useSpring({
        config: { ...config.stiff },
        from: { height: '96px' },
        to: {
            height: props.open ? height + 8 + 'px' : '96px',
        },
    });
    return (
        <animated.div
            style={{ ...slideInStyles, overflow: 'hidden', width: '100%' }}
        >
            <div ref={heightRef}>{props.children}</div>
        </animated.div>
    );
}

function RevealBox(props: RevealBoxProps) {
    const [open, setOpen] = useState(false);

    const width = 1152;

    function toggleOpen() {
        setOpen(!open);
    }

    return (
        <div className="revealBox">
            <div
                style={{
                    position: 'absolute',
                    width: width + 'px',
                    height: '72px',
                    zIndex: 100,
                    cursor: 'pointer',
                }}
                onClick={toggleOpen}
                className="toggleButton"
            ></div>
            <Box
                style={{
                    width: width + 'px',
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginBottom: '16px',
                }}
            >
                <AnimatedContainer open={open}>
                    <Stack align="left" width="100%">
                        <HorizontalStack
                            align="spread"
                            width="100%"
                            verticalAlign="middle"
                            style={{ height: '96px' }}
                        >
                            {props.header(open)}

                            <RevealButton
                                open={open}
                                onToggle={() => setOpen(!open)}
                            ></RevealButton>
                        </HorizontalStack>
                        <Spacer height={32} />
                        <SlideDown show={open}>
                            <div
                                style={{
                                    height: '750px',
                                    width: '100%',
                                }}
                            >
                                {props.body(open)}
                            </div>
                        </SlideDown>
                    </Stack>
                </AnimatedContainer>
            </Box>
        </div>
    );
}
export default RevealBox;
