import { useSavedLocationsApi } from 'apis';
import Colors from 'Cargo/Colors';
import Button from 'Cargo/Controls/Button';
import { CircleButton } from 'Cargo/Controls/CircleButton';
import TabBar from 'Cargo/Controls/TabBar';
import Icon from 'Cargo/Icons/Icon';
import { Centered, CenteredContainer } from 'Cargo/Layout/Container';
import FullWidthLayout from 'Cargo/Layout/FullWidthLayout';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import useConfirmModal from 'Cargo/Modal/useConfirmModal';
import PageTitle from 'Cargo/Text/PageTitle';
import { H1, H3, Microcopy } from 'Cargo/Text/Text';
import { UUID } from 'Cargo/Types/types';
import useManageContactsModal, {
    ManageContactsModalFlavour,
} from 'Features/Contacts/Hooks/useManageContactsModal';
import { useFilterSavedLocations } from 'Features/Locations/Helpers/filterSavedLocation';
import {
    FilterShipmentsDescription,
    renderFilterShipmentsDescriptionAsQueryParams,
} from 'Features/ViewShipments/Modals/FilterShipmentsModal';
import { SortShipmentOptions } from 'Features/ViewShipments/Modals/SortShipmentsModal';
import {
    Location,
    LocationContext,
    SavedContact,
    SavedLocation,
} from 'generated-openapi-client';
import { useOnce } from 'Hooks/useOnce';
import React, { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router-dom';
import LocationBox from './Components/LocationBox';
import useAddLocationModal from './Hooks/useAddLocationModal';
import useModifyLocationModal from './Hooks/useModifyLocationModal';
import { AddLocationModalSavingBehaviour } from './Modals/AddLocationModal';
import { useSavedLocationsService } from './Services/SavedLocationsService';

interface AddLocationButtonProps {
    showAddLocationModal: () => void;
}

function AddLocationButton(props: AddLocationButtonProps) {
    return (
        <Button size="large" onClick={props.showAddLocationModal}>
            <Icon
                name="plus"
                color={Colors.White}
                size={20}
                solid
                style={{
                    position: 'relative',
                    top: '2px',
                    marginRight: '16px',
                }}
            />{' '}
            Add New Location
        </Button>
    );
}

enum AddressBookTabs {
    FavouriteLocations,
    BranchLocations,
    AllLocations,
}

interface AddressTabProps {
    filter: string;
    showAddLocationModal: () => void;
    filteredLocations: Array<SavedLocation>;
    showModifyLocationModal: (savedLocation: SavedLocation) => void;
    showChangeContactModal: (
        savedLocationId: UUID,
        defaultContact: SavedContact,
        contacts: Array<SavedContact>,
        defaultSavedContactId: UUID | undefined
    ) => void;
    onToggleFavourite: (savedLocationId: UUID) => void;
    onToggleBranch: (savedLocationId: UUID) => void;
    onDelete: (savedLocationId: UUID) => void;
    onToggleDefaultPickupLocation: (savedLocationId: UUID) => void;
    onToggleDefaultDeliveryLocation: (savedLocationId: UUID) => void;
    defaultPickupLocationId: UUID | undefined;
    defaultDeliveryLocationId: UUID | undefined;
    selectedTab: AddressBookTabs;
    onViewShipmentsToThisLocation: (savedLocation: SavedLocation) => void;
    onViewShipmentsFromThisLocation: (savedLocation: SavedLocation) => void;
}

interface LargeAddLocationButtonProps {
    onClick: () => void;
}

function LargeAddLocationButton(props: LargeAddLocationButtonProps) {
    return (
        <Button size="large" onClick={props.onClick}>
            <Icon
                name="plus"
                color={Colors.White}
                size={20}
                solid
                style={{
                    position: 'relative',
                    top: '2px',
                    marginRight: '16px',
                }}
            />{' '}
            Add New Location
        </Button>
    );
}

function AddressTab(props: AddressTabProps) {
    const {
        filter,
        showAddLocationModal,
        filteredLocations,
        showModifyLocationModal,
        showChangeContactModal,
        onToggleFavourite,
        onToggleBranch,
        onDelete,
        onToggleDefaultPickupLocation,
        onToggleDefaultDeliveryLocation,
        defaultPickupLocationId,
        defaultDeliveryLocationId,
        selectedTab,
        onViewShipmentsToThisLocation,
        onViewShipmentsFromThisLocation,
    } = props;

    if (
        filter === '' &&
        filteredLocations.length === 0 &&
        selectedTab === AddressBookTabs.FavouriteLocations
    ) {
        return (
            <CenteredContainer>
                <Centered>
                    <Stack>
                        <H1>You don&apos;t have any favourite locations yet</H1>
                        <Microcopy>
                            Favourite locations to book faster new time. You can
                            either select a location from &quot;All
                            Locations&quot; and mark as a favourite, or add a
                            new location here
                        </Microcopy>
                        <Spacer height={32} />
                        <LargeAddLocationButton
                            onClick={showAddLocationModal}
                        />
                    </Stack>
                </Centered>
            </CenteredContainer>
        );
    }

    if (
        filter === '' &&
        filteredLocations.length === 0 &&
        selectedTab === AddressBookTabs.BranchLocations
    ) {
        return (
            <CenteredContainer>
                <Centered>
                    <Stack>
                        <H1>You don&apos;t have any branches yet</H1>
                        <Microcopy>
                            Branches allow you to more easily manage a larger
                            organization. You can either select a location from
                            &quot;All Locations&quot; and mark as a favourite,
                            or add a new location here
                        </Microcopy>
                        <Spacer height={32} />
                        <LargeAddLocationButton
                            onClick={showAddLocationModal}
                        />
                    </Stack>
                </Centered>
            </CenteredContainer>
        );
    }

    if (filter !== '' && filteredLocations.length === 0) {
        return (
            <CenteredContainer>
                <Centered>
                    <Stack>
                        <H3>It looks like nothing matches this filter</H3>
                    </Stack>
                </Centered>
            </CenteredContainer>
        );
    }

    return (
        <>
            <>
                {filteredLocations &&
                    filteredLocations.map((item) => {
                        const location = item.location;
                        const defaultContact = item.contacts.find(
                            (c) =>
                                c.savedContactId === item.defaultSavedContactId
                        );

                        if (defaultContact === undefined) {
                            throw new Error('Missing contact');
                        }

                        return (
                            <>
                                <LocationBox
                                    key={item.savedLocationId}
                                    location={location}
                                    contact={defaultContact.contact}
                                    contactCount={item.contacts.length}
                                    onModify={function () {
                                        showModifyLocationModal(item);
                                    }}
                                    onChangeContact={() => {
                                        showChangeContactModal(
                                            item.savedLocationId,
                                            defaultContact,
                                            item.contacts,
                                            item.defaultSavedContactId
                                        );
                                    }}
                                    isFavourite={item.isFavourite}
                                    isBranch={item.isBranch}
                                    onToggleFavourite={function () {
                                        onToggleFavourite(item.savedLocationId);
                                    }}
                                    onToggleBranch={function () {
                                        onToggleBranch(item.savedLocationId);
                                    }}
                                    showMenu={true}
                                    onDelete={function () {
                                        onDelete(item.savedLocationId);
                                    }}
                                    context={LocationContext.Any}
                                    isDefaultPickupLocation={
                                        defaultPickupLocationId ===
                                        item.savedLocationId
                                    }
                                    isDefaultDeliveryLocation={
                                        defaultDeliveryLocationId ===
                                        item.savedLocationId
                                    }
                                    onToggleDefaultPickupLocation={function () {
                                        onToggleDefaultPickupLocation(
                                            item.savedLocationId
                                        );
                                    }}
                                    onToggleDefaultDeliveryLocation={function () {
                                        onToggleDefaultDeliveryLocation(
                                            item.savedLocationId
                                        );
                                    }}
                                    onViewShipmentsToThisLocation={function () {
                                        onViewShipmentsToThisLocation(item);
                                    }}
                                    onViewShipmentsFromThisLocation={function () {
                                        onViewShipmentsFromThisLocation(item);
                                    }}
                                />
                                <Spacer height={32} />
                            </>
                        );
                    })}
            </>
        </>
    );
}

// eslint-disable-next-line local-rules/no-import-styled
const AddressBookScreen: React.FC = () => {
    const savedLocationsService = useSavedLocationsService();

    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('');
    const doFilter = useFilterSavedLocations(filter);
    const navigate = useNavigate();

    const [locations, setLocations] = useState<
        undefined | Array<SavedLocation>
    >();

    console.log({ locations });

    const [selectedTab, setSelectedTab] = useState(
        AddressBookTabs.FavouriteLocations
    );
    const [defaultPickupLocationId, setDefaultPickupLocationId] = useState<
        string | undefined
    >();
    const [defaultDeliveryLocationId, setDefaultDeliveryLocationId] = useState<
        string | undefined
    >();
    const savedLocationsApi = useSavedLocationsApi();

    const confirmDelete = useConfirmModal(
        'Confirm delete',
        'Are you sure you want to delete this location?'
    );

    async function loadSavedLocations() {
        setLoading(true);
        const savedLocationsResponse =
            await savedLocationsApi.getAllSavedLocations();
        setLocations(
            savedLocationsResponse.items.sort((a, b) => {
                return a.location.businessName.localeCompare(
                    b.location.businessName
                );
            })
        );
        setDefaultPickupLocationId(
            savedLocationsResponse.defaultPickupLocationId
        );
        setDefaultDeliveryLocationId(
            savedLocationsResponse.defaultDeliveryLocationId
        );
        setLoading(false);

        if (savedLocationsResponse.items.some((i) => i.isFavourite)) {
            setSelectedTab(AddressBookTabs.FavouriteLocations);
        } else if (savedLocationsResponse.items.some((i) => i.isBranch)) {
            setSelectedTab(AddressBookTabs.BranchLocations);
        } else {
            setSelectedTab(AddressBookTabs.AllLocations);
        }
    }

    useOnce(loadSavedLocations);

    async function onNewLocation(location: SavedLocation) {
        await savedLocationsService.addLocation(location);
        await loadSavedLocations();
    }

    async function onModifyLocation(
        locationId: UUID,
        updatedLocation: Location
    ) {
        // Need to update address book on the server
        await savedLocationsService.modifyLocation(locationId, updatedLocation);
        await loadSavedLocations();
    }

    const showAddLocationModal = useAddLocationModal(
        LocationContext.Any,
        AddLocationModalSavingBehaviour.Always,
        function (data) {
            onNewLocation(data.location);
            loadSavedLocations();
        }
    );

    const showModifyLocationModal = useModifyLocationModal(
        LocationContext.Any,
        function (data) {
            onModifyLocation(data.savedLocationId, data.location);
        }
    );

    const showChangeContactModal = useManageContactsModal(
        ManageContactsModalFlavour.AddressBook
    );

    function filterSavedLocations() {
        return locations
            ?.filter(function (location) {
                if (selectedTab === AddressBookTabs.FavouriteLocations) {
                    if (!location.isFavourite) {
                        return false;
                    } else {
                        return true;
                    }
                } else if (selectedTab === AddressBookTabs.BranchLocations) {
                    if (!location.isBranch) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            })
            .filter(doFilter);
    }

    const filteredLocations = filterSavedLocations();

    useHotkeys('ctrl+l', () => showAddLocationModal());

    async function onDelete(savedLocationId: UUID) {
        const confirmed = await confirmDelete();

        if (confirmed) {
            await savedLocationsService.deleteSavedLocation(savedLocationId);
            await loadSavedLocations();
        }
    }

    async function onToggleFavourite(savedLocationId: UUID) {
        await savedLocationsService.toggleIsFavourite(savedLocationId);
        await loadSavedLocations();
    }

    async function onToggleBranch(savedLocationId: UUID) {
        await savedLocationsService.toggleIsBranch(savedLocationId);
        await loadSavedLocations();
    }

    async function onToggleDefaultPickupLocation(savedLocationId: UUID) {
        await savedLocationsService.toggleDefaultPickupLocation(
            savedLocationId
        );
        await loadSavedLocations();
    }

    async function onToggleDefaultDeliveryLocation(savedLocationId: UUID) {
        await savedLocationsService.toggleDefaultDeliveryLocation(
            savedLocationId
        );
        await loadSavedLocations();
    }

    async function onViewShipmentsToThisLocation(savedLocation: SavedLocation) {
        const params: FilterShipmentsDescription = {
            deliveryBusinessName: savedLocation.location.businessName,
            deliveryPostalCode: savedLocation.location.address.postalCode,
        };
        navigate(
            `/view-all-shipments?${renderFilterShipmentsDescriptionAsQueryParams(
                params,
                SortShipmentOptions.PickupDate
            )}`
        );
    }

    async function onViewShipmentsFromThisLocation(
        savedLocation: SavedLocation
    ) {
        const params: FilterShipmentsDescription = {
            pickupBusinessName: savedLocation.location.businessName,
            pickupPostalCode: savedLocation.location.address.postalCode,
        };
        navigate(
            `/view-all-shipments?${renderFilterShipmentsDescriptionAsQueryParams(
                params,
                SortShipmentOptions.PickupDate
            )}`
        );
    }

    const numberFavouriteLocations = locations
        ?.filter(doFilter)
        .filter((sl) => sl.isFavourite).length;
    const numberBranchLocations = locations
        ?.filter(doFilter)
        .filter((sl) => sl.isBranch).length;
    const numberAllLocations = locations?.filter(doFilter).length;

    function tabSuffix(count: number | undefined) {
        if (count === undefined) {
            return '';
        }

        return ` (${count})`;
    }

    return (
        <>
            <PageTitle>Address Book</PageTitle>
            <FullWidthLayout
                header={
                    <>
                        Address Book
                        <span
                            style={{
                                position: 'relative',
                                top: '-3px',
                                marginLeft: '6px',
                            }}
                        >
                            <CircleButton onClick={showAddLocationModal} />
                        </span>
                    </>
                }
                microcopy="Your address book contains every location you've shipped from or to. You can add favourite locations to use in future shipments."
                loading={loading}
                showEmptyState={locations?.length === 0}
                emptyState={
                    <Stack>
                        <H1>Your address book is empty</H1>
                        <Microcopy>
                            Your address book contains every location you've
                            shipped from or to. You can favourite locations,
                            mark them as default for pickup or delivery
                        </Microcopy>
                        <Spacer height={32} />
                        <AddLocationButton
                            showAddLocationModal={showAddLocationModal}
                        />
                    </Stack>
                }
                content={
                    <>
                        <div style={{ minHeight: '640px', maxWidth: '1004px' }}>
                            <TabBar
                                selectedTab={selectedTab}
                                onTabSelected={setSelectedTab}
                                tabs={[
                                    {
                                        type: AddressBookTabs.FavouriteLocations,
                                        title: `Favourite Locations${tabSuffix(
                                            numberFavouriteLocations
                                        )}`,
                                    },
                                    {
                                        type: AddressBookTabs.BranchLocations,
                                        title: `Branch Locations${tabSuffix(
                                            numberBranchLocations
                                        )}`,
                                    },
                                    {
                                        type: AddressBookTabs.AllLocations,
                                        title: `All Locations${tabSuffix(
                                            numberAllLocations
                                        )}`,
                                    },
                                ]}
                                filter={filter}
                                onFilterChange={setFilter}
                                showFilter={
                                    locations?.length === undefined ||
                                    locations?.length > 0
                                }
                            />
                            <AddressTab
                                filter={filter}
                                showAddLocationModal={showAddLocationModal}
                                filteredLocations={filteredLocations || []}
                                showModifyLocationModal={
                                    showModifyLocationModal
                                }
                                showChangeContactModal={async function (
                                    savedLocationId: UUID
                                ) {
                                    await showChangeContactModal(
                                        savedLocationId
                                    );
                                    await loadSavedLocations();
                                }}
                                onToggleFavourite={onToggleFavourite}
                                onToggleBranch={onToggleBranch}
                                onDelete={onDelete}
                                onToggleDefaultPickupLocation={
                                    onToggleDefaultPickupLocation
                                }
                                onToggleDefaultDeliveryLocation={
                                    onToggleDefaultDeliveryLocation
                                }
                                defaultPickupLocationId={
                                    defaultPickupLocationId
                                }
                                defaultDeliveryLocationId={
                                    defaultDeliveryLocationId
                                }
                                selectedTab={selectedTab}
                                onViewShipmentsToThisLocation={
                                    onViewShipmentsToThisLocation
                                }
                                onViewShipmentsFromThisLocation={
                                    onViewShipmentsFromThisLocation
                                }
                            />
                        </div>
                    </>
                }
            />
        </>
    );
};

export default AddressBookScreen;
