/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Broker } from './Broker';
import {
    BrokerFromJSON,
    BrokerFromJSONTyped,
    BrokerToJSON,
} from './Broker';

/**
 * 
 * @export
 * @interface SetBrokerDetailsRequest
 */
export interface SetBrokerDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof SetBrokerDetailsRequest
     */
    shipmentId: string;
    /**
     * 
     * @type {Broker}
     * @memberof SetBrokerDetailsRequest
     */
    broker: Broker;
}

/**
 * Check if a given object implements the SetBrokerDetailsRequest interface.
 */
export function instanceOfSetBrokerDetailsRequest(value: object): boolean {
    if (!('shipmentId' in value)) return false;
    if (!('broker' in value)) return false;
    return true;
}

export function SetBrokerDetailsRequestFromJSON(json: any): SetBrokerDetailsRequest {
    return SetBrokerDetailsRequestFromJSONTyped(json, false);
}

export function SetBrokerDetailsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetBrokerDetailsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentId': json['shipmentId'],
        'broker': BrokerFromJSON(json['broker']),
    };
}

export function SetBrokerDetailsRequestToJSON(value?: SetBrokerDetailsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentId': value['shipmentId'],
        'broker': BrokerToJSON(value['broker']),
    };
}

