/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SavedBroker } from './SavedBroker';
import {
    SavedBrokerFromJSON,
    SavedBrokerFromJSONTyped,
    SavedBrokerToJSON,
} from './SavedBroker';

/**
 * 
 * @export
 * @interface GetAllSavedBrokersResponse
 */
export interface GetAllSavedBrokersResponse {
    /**
     * 
     * @type {Array<SavedBroker>}
     * @memberof GetAllSavedBrokersResponse
     */
    items: Array<SavedBroker>;
    /**
     * 
     * @type {string}
     * @memberof GetAllSavedBrokersResponse
     */
    preferredNorthboundBrokerId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAllSavedBrokersResponse
     */
    preferredSouthboundBrokerId?: string;
}

/**
 * Check if a given object implements the GetAllSavedBrokersResponse interface.
 */
export function instanceOfGetAllSavedBrokersResponse(value: object): boolean {
    if (!('items' in value)) return false;
    return true;
}

export function GetAllSavedBrokersResponseFromJSON(json: any): GetAllSavedBrokersResponse {
    return GetAllSavedBrokersResponseFromJSONTyped(json, false);
}

export function GetAllSavedBrokersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAllSavedBrokersResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(SavedBrokerFromJSON)),
        'preferredNorthboundBrokerId': json['preferredNorthboundBrokerId'] == null ? undefined : json['preferredNorthboundBrokerId'],
        'preferredSouthboundBrokerId': json['preferredSouthboundBrokerId'] == null ? undefined : json['preferredSouthboundBrokerId'],
    };
}

export function GetAllSavedBrokersResponseToJSON(value?: GetAllSavedBrokersResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(SavedBrokerToJSON)),
        'preferredNorthboundBrokerId': value['preferredNorthboundBrokerId'],
        'preferredSouthboundBrokerId': value['preferredSouthboundBrokerId'],
    };
}

