import Badge from 'Cargo/Controls/Badge';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { ReactNode } from 'react';
import styled from 'styled-components/macro';

const SubmitClaimSectionHeader = styled.div`
    font-weight: var(--nhu-font-weight-medium);
    font-size: 24px;
    color: var(--freightsimple-color-normal-text);
`;

const SubmitClaimSectionDescription = styled.div`
    font-weight: var(--nhu-font-weight-regular);
    font-size: 16px;
    color: var(--freightsimple-color-light-text);
`;

interface SubmitClaimSectionProps {
    index: string;
    title: string;
    description: string;
    content: ReactNode;
}

export function SubmitClaimSection(props: SubmitClaimSectionProps) {
    return (
        <HorizontalStack verticalAlign="top">
            <div
                style={{
                    position: 'relative',
                    top: '8px',
                    marginRight: '32px',
                }}
            >
                <Badge value={props.index} />
            </div>
            <Stack align="left" style={{ marginBottom: '64px' }}>
                <HorizontalStack verticalAlign="top">
                    <Stack align="left">
                        <SubmitClaimSectionHeader>
                            {props.title}
                        </SubmitClaimSectionHeader>
                        <SubmitClaimSectionDescription>
                            {props.description}
                        </SubmitClaimSectionDescription>
                    </Stack>
                </HorizontalStack>
                <Spacer height={16} />
                {props.content}
            </Stack>
        </HorizontalStack>
    );
}
