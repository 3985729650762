import { ErrorMessageType } from 'Cargo/Validation';

export function errorMessageForBusinessName(
    businessName: string | undefined
): ErrorMessageType {
    if (businessName === undefined || businessName === '') {
        return 'Required';
    }

    if (businessName.length < 4) {
        return 'Too short';
    }
}
