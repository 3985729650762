/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EquipmentType = {
    DryVan: 'dry_van',
    FlatBed: 'flat_bed',
    Reefer: 'reefer'
} as const;
export type EquipmentType = typeof EquipmentType[keyof typeof EquipmentType];


export function instanceOfEquipmentType(value: any): boolean {
    return Object.values(EquipmentType).includes(value);
}

export function EquipmentTypeFromJSON(json: any): EquipmentType {
    return EquipmentTypeFromJSONTyped(json, false);
}

export function EquipmentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentType {
    return json as EquipmentType;
}

export function EquipmentTypeToJSON(value?: EquipmentType | null): any {
    return value as any;
}

