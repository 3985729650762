import Colors from 'Cargo/Colors';
import Banner, { BannerStyle } from 'Cargo/Controls/Banner';
import { QuestionBubbleLabel } from 'Cargo/Controls/QuestionBubble';
import ThreeDotMenu, { ThreeDotMenuItem } from 'Cargo/Controls/ThreeDotMenu';
import Tooltip from 'Cargo/Controls/Tooltip';
import BankLogo from 'Cargo/Icons/BankLogo';
import Icon from 'Cargo/Icons/Icon';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import {
    Table,
    TableCell,
    TableHeaderCell,
    TableHeaderRow,
    TableRow,
} from 'Cargo/Layout/Table';
import { CreditCard } from 'generated-openapi-client';
import { BankAccount } from 'generated-openapi-client/models/BankAccount';
import styled from 'styled-components/macro';

interface BankAccountsSectionProps {
    bankAccounts: Array<BankAccount>;
    creditCards: Array<CreditCard>;
    setDefaultBankAccount: (stripePaymentMethodId: string) => void;
    removeBankAccount: (stripePaymentMethodId: string) => void;
    allowRemoveAccountButton: boolean;
}

const DefaultPill = styled.div`
    padding: 4px 16px;
    background-color: ${Colors.DarkGreen};
    border-radius: 4px;
`;

const DefaultLabel = styled.div`
    color: ${Colors.White};
    font-size: 12px;
    font-weight: var(--nhu-font-weight-medium);
`;

const AccountNumberLabel = styled.div`
    color: var(--freightsimple-color-light-text);
    font-size: 16px;
    font-weight: var(--nhu-font-weight-light);
`;

function BankAccountsSection(props: BankAccountsSectionProps) {
    const {
        bankAccounts,
        creditCards,
        setDefaultBankAccount,
        removeBankAccount,
        allowRemoveAccountButton,
    } = props;

    function itemsForPaymentMethod(bankAccount: BankAccount) {
        const { stripePaymentMethodId } = bankAccount;
        const items: Array<ThreeDotMenuItem> = [];

        items.push({
            name: 'Make Default',
            action: function () {
                setDefaultBankAccount(stripePaymentMethodId);
            },
            disabled: bankAccount.isDefault,
            disabledExplanation: 'This is already default',
        });

        items.push({
            name: 'Remove Bank Account',
            action: function () {
                removeBankAccount(stripePaymentMethodId);
            },
            disabled: !allowRemoveAccountButton,
            disabledExplanation: 'Cannot remove last payment method',
        });

        return items;
    }

    const defaultCreditCard = creditCards.some(function (cc) {
        return cc.isDefault;
    });
    const defaultBankAccount = bankAccounts.some(function (ba) {
        return ba.isDefault;
    });
    const showBanner = defaultCreditCard && !defaultBankAccount;

    return (
        <>
            {showBanner && (
                <>
                    <Spacer height={16} />
                    <Banner
                        bannerStyle={BannerStyle.Warning}
                        message="Your current default payment method is set to a credit card"
                    />
                </>
            )}
            <Spacer height={16} />
            <Table>
                <TableHeaderRow>
                    <TableHeaderCell>Saved Bank Accounts</TableHeaderCell>
                    <TableHeaderCell
                        style={{ width: '260px' }}
                    ></TableHeaderCell>
                    <TableHeaderCell
                        style={{ width: '80px' }}
                    ></TableHeaderCell>
                    <TableHeaderCell
                        style={{ width: '100px' }}
                    ></TableHeaderCell>
                </TableHeaderRow>
                {bankAccounts.map((bankAccount, index) => {
                    return (
                        <TableRow key={index}>
                            <TableCell>
                                <HorizontalStack verticalAlign="middle">
                                    <BankLogo
                                        institutionNumber={
                                            bankAccount.institutionNumber
                                        }
                                        stripeBankName={bankAccount.bankName}
                                    />
                                </HorizontalStack>
                            </TableCell>
                            <TableCell>
                                <AccountNumberLabel>
                                    Account Ending {bankAccount.lastFourDigits}
                                </AccountNumberLabel>
                            </TableCell>
                            <TableCell>
                                {bankAccount.isDefault && (
                                    <Tooltip
                                        tooltip={
                                            <div style={{ padding: '10px' }}>
                                                <QuestionBubbleLabel>
                                                    This is the default payment
                                                    method that will be used for
                                                    shipments.
                                                </QuestionBubbleLabel>
                                            </div>
                                        }
                                    >
                                        <DefaultPill>
                                            <HorizontalStack align="spread">
                                                <Icon
                                                    name="check"
                                                    color={Colors.White}
                                                    size={14}
                                                    solid
                                                />
                                                <Spacer width={4} />
                                                <DefaultLabel>
                                                    Default
                                                </DefaultLabel>
                                            </HorizontalStack>
                                        </DefaultPill>
                                    </Tooltip>
                                )}
                            </TableCell>
                            <TableCell>
                                <HorizontalStack
                                    align="right"
                                    style={{ paddingRight: '40px' }}
                                >
                                    <ThreeDotMenu
                                        color={Colors.MidGray}
                                        items={itemsForPaymentMethod(
                                            bankAccount
                                        )}
                                    />
                                </HorizontalStack>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </Table>
        </>
    );
}
export default BankAccountsSection;
