import Animation from 'Cargo/Animations/Animation';
import React from 'react';

function UpdatedBillOfLadingAnimation() {
    return (
        <Animation
            style={{ width: '200px' }}
            speed={1.0}
            loop={true}
            pathToAnimation="/animations/updated-bill-of-lading-animation.json"
            lottieFilesUrl="https://lottiefiles.com/22128-document"
        />
    );
}
export default UpdatedBillOfLadingAnimation;
