import Link from 'Cargo/Controls/Link';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { nameAccessorials } from 'Data/AccessorialTypes';
import { describeLocationType } from 'Data/LocationTypes';
import DeliveryDeadlineQuestionBubble from 'Features/BookShipment/Components/LocationDetails/DeliveryDeadlineQuestionBubble';
import { AddLocationLocationType } from 'Features/Locations/Types/locationTypes';
import { formatLocation } from 'Features/Quotes/Components/QuoteRow/Helpers/formatLocation';
import { QuoteLike } from 'Features/ViewShipments/Components/DetailsSection';
import { formatPhone } from 'Helpers/formatPhone';
import {
    Contact,
    Location,
    LocationContext,
    PreQuoteLocation,
    ShipmentState,
} from 'generated-openapi-client';
import moment from 'moment';
import EstimatedEarliestDeliveryDateQuestionBubble from './EstimatedEarliestDeliveryDateQuestionBubble';
import PredictedLatestDeliveryDateQuestionBubble from './PredictedLatestDeliveryDateQuestionBubble';
import {
    Column,
    ColumnHeader,
    ColumnSectionHeader,
    LocationTypeDescription,
    ModifyLink,
    NotesSection,
    PrimaryText,
    ReferenceNumber,
    SecondaryText,
} from './QuoteRowSharedStyles';

interface DeliveryColumnProps {
    deliveryHours: string | undefined;
    deliveryLocation: Location | AddLocationLocationType | PreQuoteLocation;
    deliveryContact: Contact | undefined;
    quote: QuoteLike | undefined;
    shipmentState: ShipmentState;
    actualDeliveryDate: string | undefined;
    actualDeliveryTime: string | undefined;
    expectedDeliveryDate: string | undefined;
    latestExpectedDeliveryDate: string | undefined;
    deliveryDeadline: string | undefined;
    deliveryReferenceNumber?: string;
    deliveryBoothNumber?: string;
    onModifyDetails?: () => void;
    onModifyDeliveryAddress?: () => void;
    onModifyDeliveryContact?: () => void;
    onModifyDeliveryReferenceAndNotes?: () => void;
    showNotesSection: boolean;
    // Is this in a two column layout?
    twoColumn?: boolean;
    hideExpectedDelivery?: boolean;
}

function WhenSection(props: DeliveryColumnProps) {
    const { deliveryHours, shipmentState } = props;

    if (
        shipmentState === ShipmentState.Lost ||
        shipmentState === ShipmentState.Cancelled
    ) {
        return <></>;
    }

    const onHold = props.shipmentState === ShipmentState.OnHold;

    if (onHold) {
        return (
            <>
                <ColumnSectionHeader>When</ColumnSectionHeader>
                <PrimaryText>On Hold</PrimaryText>
            </>
        );
    } else if (shipmentState === ShipmentState.Delivered) {
        const formattedDate = moment(props.actualDeliveryDate).format(
            'dddd Do MMMM YYYY'
        );

        const formattedTime = props.actualDeliveryTime
            ? moment(props.actualDeliveryTime, 'HH:mm').format('h:mma')
            : undefined;

        return (
            <>
                <ColumnSectionHeader>When</ColumnSectionHeader>
                <PrimaryText>{formattedDate}</PrimaryText>
                {formattedTime && (
                    <SecondaryText>Delivered at: {formattedTime}</SecondaryText>
                )}
            </>
        );
    } else {
        const formattedDate =
            props.expectedDeliveryDate !== undefined
                ? moment(props.expectedDeliveryDate).format('dddd Do MMMM YYYY')
                : 'Unknown Delivery Date';

        const showPredictedLatestDate =
            props.latestExpectedDeliveryDate &&
            moment(props.expectedDeliveryDate).isBefore(
                moment(props.latestExpectedDeliveryDate)
            );

        const showDeliveryDeadline = props.deliveryDeadline !== undefined;

        const formattedPredictedLatestDate = props.latestExpectedDeliveryDate
            ? moment(props.latestExpectedDeliveryDate).format(
                  'dddd Do MMMM YYYY'
              )
            : '';

        const formattedDeliveryDeadline = props.deliveryDeadline
            ? moment(props.deliveryDeadline).format('dddd Do MMMM YYYY')
            : '';

        return (
            <>
                {!props.hideExpectedDelivery && (
                    <>
                        <ColumnSectionHeader>
                            Earliest Delivery
                            <EstimatedEarliestDeliveryDateQuestionBubble />
                        </ColumnSectionHeader>
                        <PrimaryText>{formattedDate}</PrimaryText>
                    </>
                )}
                {deliveryHours && (
                    <SecondaryText>
                        Delivery window: {deliveryHours}
                    </SecondaryText>
                )}
                {showPredictedLatestDate && (
                    <>
                        <ColumnSectionHeader>
                            Latest Delivery
                            <PredictedLatestDeliveryDateQuestionBubble />
                        </ColumnSectionHeader>
                        <PrimaryText>
                            {formattedPredictedLatestDate}
                        </PrimaryText>
                    </>
                )}
                {showDeliveryDeadline && (
                    <>
                        <ColumnSectionHeader>
                            Delivery Deadline
                            <DeliveryDeadlineQuestionBubble />
                        </ColumnSectionHeader>
                        <PrimaryText>{formattedDeliveryDeadline}</PrimaryText>
                    </>
                )}
            </>
        );
    }
}

function WhereSection(props: DeliveryColumnProps) {
    const { deliveryLocation } = props;

    const locationTypeDescription = describeLocationType(
        deliveryLocation.locationType
    );
    const formattedLocation = formatLocation(deliveryLocation);

    if (deliveryLocation.businessName === undefined) {
        return (
            <>
                <ColumnSectionHeader>Where</ColumnSectionHeader>
                <PrimaryText>{formattedLocation}</PrimaryText>
                <SecondaryText>{locationTypeDescription}</SecondaryText>
            </>
        );
    } else {
        return (
            <>
                <ColumnSectionHeader>Where</ColumnSectionHeader>
                <PrimaryText>{deliveryLocation.businessName}</PrimaryText>
                {props.deliveryReferenceNumber && (
                    <ReferenceNumber>
                        Reference: {props.deliveryReferenceNumber}
                    </ReferenceNumber>
                )}
                <SecondaryText>
                    Location Type:{' '}
                    <LocationTypeDescription>
                        {locationTypeDescription}
                    </LocationTypeDescription>
                </SecondaryText>
                <SecondaryText>
                    {deliveryLocation.address.addressLine}
                </SecondaryText>
                <SecondaryText>
                    {deliveryLocation.address.addressLine2}
                </SecondaryText>
                <SecondaryText>{formattedLocation}</SecondaryText>
                {props.onModifyDeliveryAddress && (
                    <ModifyLink>
                        <Link onClick={props.onModifyDeliveryAddress}>
                            Change address
                        </Link>
                    </ModifyLink>
                )}
            </>
        );
    }
}

function ContactSection(props: DeliveryColumnProps) {
    const { deliveryContact } = props;

    if (deliveryContact?.contactName === undefined) {
        return <></>;
    } else {
        return (
            <>
                <ColumnSectionHeader>Contact</ColumnSectionHeader>
                <PrimaryText>{deliveryContact?.contactName}</PrimaryText>
                <SecondaryText>{deliveryContact?.emailAddress}</SecondaryText>
                <SecondaryText>
                    {formatPhone(
                        deliveryContact?.phoneNumber as string,
                        deliveryContact?.phoneNumberExtension
                    )}
                </SecondaryText>
                {props.onModifyDeliveryContact && (
                    <ModifyLink>
                        <Link onClick={props.onModifyDeliveryContact}>
                            Change contact
                        </Link>
                    </ModifyLink>
                )}
            </>
        );
    }
}

function SpecialServicesSection(props: DeliveryColumnProps) {
    const { deliveryLocation } = props;

    const lines = nameAccessorials(
        deliveryLocation.accessorials,
        LocationContext.Delivery,
        deliveryLocation.locationType
    );

    return (
        <>
            <ColumnSectionHeader>Special Services</ColumnSectionHeader>
            <ul style={{ paddingInlineStart: '20px', marginBottom: '0' }}>
                {lines.map((line, index) => {
                    return (
                        <li key={index}>
                            <SecondaryText>{line}</SecondaryText>
                        </li>
                    );
                })}
            </ul>
            {props.onModifyDetails && (
                <>
                    <Spacer height={8} />
                    <ModifyLink>
                        <Link onClick={props.onModifyDetails}>
                            Modify special services
                        </Link>
                    </ModifyLink>
                </>
            )}
        </>
    );
}

function deliveryLabelForShipmentState(shipmentState: ShipmentState) {
    const deliveryLabel = 'Delivery';

    switch (shipmentState) {
        case ShipmentState.QuoteRequested:
        case ShipmentState.Quoted:
        case ShipmentState.BookingRequested:
        case ShipmentState.BookingConfirmed:
        case ShipmentState.OnHold:
        case ShipmentState.InTransit:
            return deliveryLabel;
        case ShipmentState.Delivered:
            return 'Delivered';
        case ShipmentState.Cancelled:
            return 'Delivery (Cancelled)';
        case ShipmentState.Lost:
            return 'Delivery (Lost)';
    }
}

function DeliveryColumn(props: DeliveryColumnProps) {
    const label = deliveryLabelForShipmentState(props.shipmentState);
    return (
        <Column twoColumn={props.twoColumn}>
            <Stack align="left">
                <ColumnHeader>{label}</ColumnHeader>
                <WhenSection {...props} />
                <WhereSection {...props} />
                <ContactSection {...props} />
                {props.showNotesSection && (
                    <NotesSection
                        reference={props.deliveryReferenceNumber}
                        boothNumber={props.deliveryBoothNumber}
                        notes={props.deliveryLocation.notes}
                        locationType={props.deliveryLocation.locationType}
                        onModify={props.onModifyDeliveryReferenceAndNotes}
                    />
                )}
                <SpecialServicesSection {...props} />
            </Stack>
        </Column>
    );
}
export default DeliveryColumn;
