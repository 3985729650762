import Colors from 'Cargo/Colors';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import PageTitle from 'Cargo/Text/PageTitle';
import { H2, Microcopy } from 'Cargo/Text/Text';
import { UUID } from 'Cargo/Types/types';
import { PreBookingShipment } from 'generated-openapi-client';
import { EquipmentType } from 'generated-openapi-client/models/EquipmentType';
import { SharedDedicated } from 'generated-openapi-client/models/SharedDedicated';
import {
    DedicatedLogisticsOffer,
    DedicatedLogisticsOfferButtonPosition,
} from './DedicatedLogistics/DedicatedLogisticsOffer';
import { TwoColumnShipmentView } from './DedicatedLogistics/TwoColumnShipmentView';

interface NoQuotesProps {
    onModify: () => void;
    onRequestManualQuotes: (
        shipmentId: UUID,
        equipmentType: EquipmentType,
        sharedDedicated: SharedDedicated,
        tarpRequired: boolean | undefined,
        linearFeet: number | undefined,
        notes: string
    ) => Promise<void>;
    shipment: PreBookingShipment;
}

export function NoQuotes(props: NoQuotesProps) {
    const { onModify, shipment, onRequestManualQuotes } = props;

    if (shipment.manualQuotingOpen) {
        return (
            <>
                <PageTitle>Waiting for manual quotes</PageTitle>

                <HorizontalStack
                    style={{ padding: '64px' }}
                    verticalAlign="top"
                >
                    <Stack align="left">
                        <H2>🦸‍♀️ Searching for the best quotes</H2>
                        <Spacer height={24} />
                        <Stack align="left">
                            <Microcopy>
                                Our logistics team is looking for manual quotes
                                for your shipment
                            </Microcopy>
                            <Spacer height={8} />
                            <Microcopy>
                                When quotes are ready you will be able to find
                                them here
                            </Microcopy>
                            <Spacer height={24} />
                            {shipment.manualQuotingNotes !== '' && (
                                <>
                                    <Microcopy>
                                        You provided these instructions to our
                                        team:
                                    </Microcopy>
                                    <div style={{ fontStyle: 'italic' }}>
                                        {shipment.manualQuotingNotes}
                                    </div>
                                </>
                            )}
                        </Stack>
                    </Stack>
                    <Spacer width={64} />
                    <TwoColumnShipmentView
                        pickupDeadline={props.shipment.pickupDeadline}
                        lineItems={props.shipment.lineItems}
                        pickupDate={props.shipment.pickupDate}
                        pickupLocation={props.shipment.pickupLocation}
                        deliveryLocation={props.shipment.deliveryLocation}
                        deliveryDeadline={props.shipment.deliveryDeadline}
                        equipmentType={props.shipment.equipmentType}
                        exclusiveUse={props.shipment.exclusiveUseNeeded}
                        tarpRequired={props.shipment.tarpRequired}
                        linearFeet={props.shipment.linearFeet}
                    />
                </HorizontalStack>
            </>
        );
    } else {
        return (
            <>
                <PageTitle>No quotes</PageTitle>
                <Stack align="center">
                    <div
                        style={{
                            color: Colors.LightText,
                            fontSize: '15px',
                            marginBottom: '4px',
                        }}
                    >
                        Sorry, we couldn&apos;t find any instant quotes for your
                        shipment
                    </div>
                    <H2 style={{ fontStyle: 'italic' }}>
                        ...but our dedicated logistics team can help
                    </H2>
                    <DedicatedLogisticsOffer
                        onModify={onModify}
                        pickupDeadline={props.shipment.pickupDeadline}
                        onRequestManualQuotes={onRequestManualQuotes}
                        shipmentId={props.shipment.shipmentId}
                        lineItems={props.shipment.lineItems}
                        pickupDate={props.shipment.pickupDate}
                        pickupLocation={props.shipment.pickupLocation}
                        deliveryLocation={props.shipment.deliveryLocation}
                        deliveryDeadline={props.shipment.deliveryDeadline}
                        buttonPosition={
                            DedicatedLogisticsOfferButtonPosition.Top
                        }
                        equipmentType={props.shipment.equipmentType}
                        exclusiveUse={props.shipment.exclusiveUseNeeded}
                        tarpRequired={props.shipment.tarpRequired}
                        linearFeet={props.shipment.linearFeet}
                    />
                </Stack>
            </>
        );
    }
}
