import Colors from 'Cargo/Colors';
import ProgressSpinner, {
    ProgressSpinnerSizes,
} from 'Cargo/Icons/ProgressSpinner';
import { H1, Microcopy } from 'Cargo/Text/Text';
import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { Centered, CenteredContainer } from './Container';
import FirstToMatch, { FirstToMatchEnum } from './FirstToMatch';
import HorizontalStack from './HorizontalStack';
import Spacer from './Spacer';
import Stack from './Stack';

const Header = styled.div`
    height: 64px;
    width: 100%;
`;

const Content = styled.div`
    width: 100%;
    height: 100%;
    min-height: 300px;
`;

// TODO: Make a shared component (LoadingShipment looks similar)
function Loading() {
    return (
        <CenteredContainer>
            <Centered>
                <ProgressSpinner size={ProgressSpinnerSizes.Small} />
            </Centered>
        </CenteredContainer>
    );
}

interface FullWidthLayoutProps {
    header: ReactNode;
    microcopy?: ReactNode;
    content: ReactElement | undefined;
    rightContent?: ReactNode;
    loading?: boolean;
    showEmptyState?: boolean;
    emptyState?: ReactElement;
}

function FullWidthLayout(props: FullWidthLayoutProps) {
    if (props.loading) {
        return <Loading />;
    }
    return (
        <Stack
            align="left"
            width="100%"
            style={{ height: '100%', paddingTop: '48px' }}
        >
            {!props.showEmptyState && (
                <Header>
                    <HorizontalStack
                        verticalAlign="bottom"
                        align="spread"
                        style={{
                            width: '100%',
                            height: '100%',
                            borderBottom: `1px solid ${Colors.VeryVeryLightBlue}`,
                            paddingBottom: '8px',
                        }}
                    >
                        <Stack align="left">
                            <H1 style={{ marginBottom: '2px' }}>
                                {props.header}
                            </H1>
                            <Microcopy>{props.microcopy}</Microcopy>
                        </Stack>
                        <HorizontalStack
                            style={{ height: '100%' }}
                            verticalAlign="middle"
                        >
                            {props.rightContent}
                        </HorizontalStack>
                    </HorizontalStack>
                </Header>
            )}
            <Spacer height={16} />
            <Content>
                <FirstToMatch
                    components={[
                        {
                            when: props.showEmptyState,
                            show: (
                                <CenteredContainer>
                                    <Centered>{props.emptyState}</Centered>
                                </CenteredContainer>
                            ),
                        },
                        {
                            when: FirstToMatchEnum.Default,
                            show: props.content || <></>,
                        },
                    ]}
                />
            </Content>
        </Stack>
    );
}
export default FullWidthLayout;
