import Link from 'Cargo/Controls/Link';
import { isEmptyOrUndefined } from 'Helpers/isNotEmptyOrUndefined';
import { LocationType } from 'generated-openapi-client';
import styled from 'styled-components/macro';

interface ColumnProps {
    twoColumn?: boolean;
}

export const Column = styled.div<ColumnProps>`
    width: ${(props) => (props.twoColumn ? '50%' : '33%')};
    padding-right: 24px;
`;

export const ColumnHeader = styled.div`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 18px;
    color: var(--freightsimple-color-light-text);
`;

export const ColumnSectionHeader = styled.div`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 14px;
    color: var(--freightsimple-color-normal-text);
    margin-top: 16px;
`;

export const PrimaryText = styled.div`
    font-weight: var(--nhu-font-weight-medium);
    font-size: 18px;
    color: var(--freightsimple-color-normal-text);
`;

export const SecondaryText = styled.div`
    font-weight: var(--nhu-font-weight-light);
    font-size: 14px;
    color: var(--freightsimple-color-light-text);
`;

export const ModifyLink = styled.div`
    font-weight: var(--nhu-font-weight-light);
    font-size: 14px;
    color: var(--freightsimple-color-light-text);
`;

export const LineItemText1 = styled.div`
    font-weight: var(--nhu-font-weight-light);
    font-size: 14px;
    color: #333;
`;

export const LineItemText2 = styled.div`
    font-weight: var(--nhu-font-weight-light);
    font-size: 12px;
    color: #666;
`;

export const LocationTypeDescription = styled.span`
    color: var(--freightsimple-color-normal-text);
`;

export const ReferenceNumber = styled.div`
    font-size: 14px;
`;

function describeBoothNumber(boothNumber: string | undefined) {
    if (boothNumber === undefined) {
        return undefined;
    }

    if (isEmptyOrUndefined(boothNumber)) {
        return undefined;
    }

    if (boothNumber.toLowerCase().includes('booth')) {
        return boothNumber;
    } else {
        return `Booth: ${boothNumber}`;
    }
}

export function NotesSection(props: {
    reference: string | undefined;
    boothNumber: string | undefined;
    notes: string | undefined;
    locationType: string | undefined;
    onModify: (() => void) | undefined;
}) {
    const { notes, reference, boothNumber, locationType } = props;

    const missingNotes = notes === undefined || notes === '';
    const missingReference = reference === undefined || reference === '';
    const missingBoothNumber =
        isEmptyOrUndefined(boothNumber) ||
        locationType !== LocationType.TradeShow;
    const hasBoothNumber = !missingBoothNumber;

    const empty = missingNotes && missingReference && missingBoothNumber;

    return (
        <>
            <ColumnSectionHeader>
                Reference Number &amp; Notes
            </ColumnSectionHeader>
            {reference && (
                <ReferenceNumber>Reference: {reference}</ReferenceNumber>
            )}
            {notes && <SecondaryText>{notes}</SecondaryText>}
            {hasBoothNumber && (
                <SecondaryText>
                    {describeBoothNumber(boothNumber)}
                </SecondaryText>
            )}
            {empty && <SecondaryText>Nothing specified</SecondaryText>}
            {props.onModify && (
                <ModifyLink>
                    <Link onClick={props.onModify}>Change</Link>
                </ModifyLink>
            )}
        </>
    );
}
