import { CenteredContainer } from 'Cargo/Layout/Container';
import useQuery from 'Hooks/useQuery';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function ReferralScreen() {
    console.log(`ReferralScreen`);
    const query = useQuery();
    const navigate = useNavigate();

    const referralCode = query.code as string | undefined;

    console.log(`query`, { query });

    useEffect(
        function () {
            if (referralCode !== undefined && referralCode !== '') {
                console.log(`setting local storage : ${referralCode}`);
                localStorage.setItem('referralCode', referralCode);

                navigate('/create-account');
            }
        },
        [referralCode]
    );

    if (referralCode === undefined || referralCode === '') {
        return <CenteredContainer>⚠️ Unknown Referral Code</CenteredContainer>;
    }

    // We should have redirected
    return <></>;
}
