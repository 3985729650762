import { animated, useSpring } from '@react-spring/web';
import { ReactNode } from 'react';

interface RotationContainerProps {
    open: boolean;
    children: ReactNode;
}

export function RotationContainer(props: RotationContainerProps) {
    const styles = useSpring({
        transform: props.open ? 'rotateZ(180deg)' : 'rotateZ(0deg)',
    });
    return (
        <animated.div
            style={{
                ...styles,
            }}
        >
            {props.children}
        </animated.div>
    );
}
