import ProgressSpinner, {
    ProgressSpinnerSizes,
} from 'Cargo/Icons/ProgressSpinner';
import Stack from 'Cargo/Layout/Stack';
import { H2 } from 'Cargo/Text/Text';
import React from 'react';

function BookingInProgress() {
    return (
        <Stack align="center">
            <H2>Completing Booking</H2>
            <ProgressSpinner size={ProgressSpinnerSizes.Medium} />
        </Stack>
    );
}
export default BookingInProgress;
