import { useGetStartedApi } from 'apis';
import Spacer from 'Cargo/Layout/Spacer';
import { H0 } from 'Cargo/Text/Text';
import { DangerousGoodsComponent } from 'Features/DangerousGoods/Components/DangerousGoodsComponent';
import { errorMessagesForEmergencyContact } from 'Features/DangerousGoods/Validators/errorMessagesForEmergencyContact';
import BookShipmentNavigationFooter2 from 'Features/BookShipment/Components/BookShipmentNavigationFooter2';
import {
    EmergencyContactDetails,
    PostGetStartedSetEmergencyDetailsRequest,
    PreBookingShipment,
} from 'generated-openapi-client';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentScreenIsDirty } from './Slices/bookShipmentSlice';

interface BookShipmentDangerousGoodsScreenProps {
    shipment: PreBookingShipment;
    onComplete: () => void;
    nextIdentifier: string;
    onPrevious: () => Promise<void>;
}

export function BookShipmentDangerousGoodsScreen(
    props: BookShipmentDangerousGoodsScreenProps
) {
    const [forceValidation, setForceValidation] = useState(false);
    const { shipment, onComplete, nextIdentifier, onPrevious } = props;
    const { shipmentId } = shipment;
    const dispatch = useDispatch();

    const [emergencyContactDetails, setEmergencyContactDetails] = useState<
        Partial<EmergencyContactDetails>
    >(shipment.emergencyContactDetails || {});

    const emergencyContactDetailsErrors = errorMessagesForEmergencyContact(
        emergencyContactDetails
    );
    const getStartedApi = useGetStartedApi();

    function isValid() {
        const anyErrorMessages = [
            emergencyContactDetailsErrors.contactName,
            emergencyContactDetailsErrors.contactPhoneNumber,
            emergencyContactDetailsErrors.contactPhoneNumberExtension,
        ].some((e) => e !== undefined);

        return !anyErrorMessages;
    }

    async function nextIsValid() {
        setForceValidation(true);
        if (isValid()) {
            const request: PostGetStartedSetEmergencyDetailsRequest = {
                setEmergencyDetailsRequest: {
                    shipmentId,
                    emergencyDetails:
                        emergencyContactDetails as EmergencyContactDetails,
                },
            };

            console.log({ request });

            // TODO: Move this to a different API
            await getStartedApi.postGetStartedSetEmergencyDetails(request);
            onComplete();
            return true;
        } else {
            return false;
        }
    }

    function onUpdateEmergencyContactDetails(
        update: Partial<EmergencyContactDetails>
    ) {
        dispatch(setCurrentScreenIsDirty());
        setEmergencyContactDetails({
            ...emergencyContactDetails,
            ...update,
        });
    }

    return (
        <div id="dangerous-goods-section">
            <H0>Dangerous Goods</H0>
            <div>
                24 Hour emergency contact details are required for shipping
                dangerous goods
            </div>
            <Spacer height={32} />
            <DangerousGoodsComponent
                emergencyContactDetails={emergencyContactDetails}
                onUpdateEmergencyContactDetails={
                    onUpdateEmergencyContactDetails
                }
                forceValidation={forceValidation}
                emergencyContactDetailsErrors={emergencyContactDetailsErrors}
            />
            <Spacer height={32} />
            <BookShipmentNavigationFooter2
                next="Next"
                onPrevious={onPrevious}
                nextUrl={`/book/${props.nextIdentifier}?shipmentId=${shipmentId}`}
                nextIsValid={nextIsValid}
                nextIsEnabled={() => true}
                nextIdentifier={nextIdentifier}
                hidePreviousButton={false}
            />
        </div>
    );
}
