import { ErrorMessageType } from 'Cargo/Validation';
import { AddContactContactType } from 'Features/Contacts/Types/contactTypes';
import {
    ContactErrors,
    EmailRequired,
    errorMessagesForContact,
} from 'Features/Contacts/Validators/errorMessagesForContact';
import { errorMessageForBusinessName } from 'Features/Locations/Validators/errorMessageForBusinessName';
import {
    AddressErrors,
    errorMessagesForAddress,
} from 'Features/Locations/Validators/errorMessagesForAddress';
import { Broker } from 'generated-openapi-client';
import { AddBrokerBrokerType } from '../Types/brokerTypes';

export interface BrokerErrors {
    businessName: ErrorMessageType;
    address: AddressErrors;
    contact: ContactErrors;
}

export function errorMessagesForBroker(
    broker: AddBrokerBrokerType | Broker,
    comparisonContacts?: Map<string, AddContactContactType>
): BrokerErrors {
    return {
        businessName: errorMessageForBusinessName(broker.businessName),
        address: errorMessagesForAddress(broker.address),
        contact: errorMessagesForContact(
            broker.contact,
            EmailRequired.EmailRequired,
            comparisonContacts
        ),
    };
}
