import Dropdown from 'Cargo/Controls/Dropdown';
import Input from 'Cargo/Controls/Input';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import { ErrorMessageType } from 'Cargo/Validation';
import { Address, CountryCode } from 'generated-openapi-client';
import React from 'react';

interface BillingAddressInputProps {
    address?: Partial<Address>;
    addressChanged: (_: Partial<Address>) => void;
    enabled: boolean;
    forceValidation: boolean;
    errorMessageForAddress: ErrorMessageType;
    errorMessageForCity: ErrorMessageType;
    errorMessageForStateOrProvinceCode: ErrorMessageType;
    errorMessageForPostalCode: ErrorMessageType;
    errorMessageForCountry: ErrorMessageType;
}

const BillingAddressInput: React.FC<BillingAddressInputProps> = (
    props: BillingAddressInputProps
) => {
    const enabled = props.enabled;

    function postalCodeDescription() {
        if (props.address?.countryCode === undefined) {
            return `Postal Code / Zipcode`;
        }

        if (props.address.countryCode === CountryCode.Canada) {
            return `Postal Code`;
        }

        if (props.address.countryCode === CountryCode.UnitedStates) {
            return `Zipcode`;
        }
    }

    function provinceDescription() {
        if (props.address?.countryCode === undefined) {
            return `Province / State`;
        }

        if (props.address.countryCode === CountryCode.Canada) {
            return `Province`;
        }

        if (props.address.countryCode === CountryCode.UnitedStates) {
            return `State`;
        }
    }

    return (
        <>
            <HorizontalStack align="left" verticalAlign="top">
                <Input
                    type="text"
                    name="address"
                    label={`Address`}
                    value={props.address?.addressLine}
                    enabled={enabled}
                    onChange={(value: string) =>
                        props.addressChanged({
                            addressLine: value,
                        })
                    }
                    required
                    width={470}
                    errorMessage={props.errorMessageForAddress}
                    forceValidation={props.forceValidation}
                ></Input>
            </HorizontalStack>
            <HorizontalStack align="left" verticalAlign="top">
                <Input
                    type="text"
                    name="address2"
                    label={`Other details (eg. Unit / Floor / Door)`}
                    value={props.address?.addressLine2}
                    enabled={enabled}
                    onChange={(value: string) =>
                        props.addressChanged({
                            addressLine2: value,
                        })
                    }
                    required
                    width={470}
                ></Input>
            </HorizontalStack>
            <HorizontalStack align="left" verticalAlign="top">
                <Input
                    type="text"
                    name="city"
                    label={`City`}
                    value={props.address?.city}
                    enabled={enabled}
                    onChange={(value: string) =>
                        props.addressChanged({
                            city: value,
                        })
                    }
                    width={300}
                    errorMessage={props.errorMessageForCity}
                    forceValidation={props.forceValidation}
                ></Input>
                <Spacer width={20} />
                <Input
                    type="text"
                    name="province"
                    label={provinceDescription()}
                    value={props.address?.stateOrProvinceCode}
                    enabled={enabled}
                    onChange={(value: string) =>
                        props.addressChanged({
                            stateOrProvinceCode: value,
                        })
                    }
                    width={150}
                    errorMessage={props.errorMessageForStateOrProvinceCode}
                    forceValidation={props.forceValidation}
                ></Input>
            </HorizontalStack>
            <HorizontalStack align="left" verticalAlign="top">
                <Input
                    type="text"
                    name="postal_code"
                    label={postalCodeDescription()}
                    value={props.address?.postalCode}
                    enabled={enabled}
                    onChange={(value: string) =>
                        props.addressChanged({
                            postalCode: value,
                        })
                    }
                    width={225}
                    errorMessage={props.errorMessageForPostalCode}
                    forceValidation={props.forceValidation}
                ></Input>
                <Spacer width={20} />
                <Dropdown
                    unselectedDescription="Select country"
                    placeholder="Country"
                    selectedItem={props.address?.countryCode}
                    onOptionSelected={(item: CountryCode) => {
                        props.addressChanged({
                            countryCode: item,
                        });
                    }}
                    options={[
                        { item: CountryCode.Canada, description: 'Canada' },
                        {
                            item: CountryCode.UnitedStates,
                            description: 'United States',
                        },
                    ]}
                    width={225}
                    errorMessage={props.errorMessageForCountry}
                    forceValidation={props.forceValidation}
                />
            </HorizontalStack>
        </>
    );
};
export default BillingAddressInput;
