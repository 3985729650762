import { H1 } from 'Cargo/Text/Text';
import { Shipment } from 'generated-openapi-client/models/Shipment';
import React from 'react';

interface DeliveryNotesPageProp {
    shipment: Shipment;
}

export function DeliveryNotesPage(props: DeliveryNotesPageProp) {
    console.log({ props });
    return <H1>Delivery Notes</H1>;
}
