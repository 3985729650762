/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TrackingLine } from './TrackingLine';
import {
    TrackingLineFromJSON,
    TrackingLineFromJSONTyped,
    TrackingLineToJSON,
} from './TrackingLine';

/**
 * 
 * @export
 * @interface TrackingInfo
 */
export interface TrackingInfo {
    /**
     * 
     * @type {Array<TrackingLine>}
     * @memberof TrackingInfo
     */
    trackingLines: Array<TrackingLine>;
}

/**
 * Check if a given object implements the TrackingInfo interface.
 */
export function instanceOfTrackingInfo(value: object): boolean {
    if (!('trackingLines' in value)) return false;
    return true;
}

export function TrackingInfoFromJSON(json: any): TrackingInfo {
    return TrackingInfoFromJSONTyped(json, false);
}

export function TrackingInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrackingInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'trackingLines': ((json['trackingLines'] as Array<any>).map(TrackingLineFromJSON)),
    };
}

export function TrackingInfoToJSON(value?: TrackingInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'trackingLines': ((value['trackingLines'] as Array<any>).map(TrackingLineToJSON)),
    };
}

