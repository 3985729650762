import { AddLocationLocationType } from 'Features/Locations/Types/locationTypes';
import { LatitudeLongitude } from 'generated-openapi-client';
import { calculateBoundingBox } from 'Helpers/calculateBoundingBox';
import { calculatePointOnArc } from 'Helpers/calculatePointsOnArc';
import { latitudeLongitudeToNumbers } from './latitudeLongitudeToNumbers';

export function zoomMap(
    map: mapboxgl.Map,
    pickup: Partial<AddLocationLocationType> | undefined,
    delivery: Partial<AddLocationLocationType> | undefined
) {
    const missingPickupPoint =
        pickup == undefined ||
        pickup.latitudeLongitude === undefined ||
        pickup.latitudeLongitude.latitude === undefined ||
        pickup.latitudeLongitude.longitude === undefined;

    const missingDeliveryPoint =
        delivery == undefined ||
        delivery.latitudeLongitude === undefined ||
        delivery.latitudeLongitude.latitude === undefined ||
        delivery.latitudeLongitude.longitude === undefined;

    if (missingPickupPoint && missingDeliveryPoint) {
        map.setCenter([-90, 44]);
        map.zoomTo(3);
    } else if (missingPickupPoint && !missingDeliveryPoint) {
        map.setCenter(
            latitudeLongitudeToNumbers(
                delivery?.latitudeLongitude as Required<LatitudeLongitude>
            )
        );
        map.zoomTo(5);
    } else if (!missingPickupPoint && missingDeliveryPoint) {
        map.setCenter(
            latitudeLongitudeToNumbers(
                pickup?.latitudeLongitude as Required<LatitudeLongitude>
            )
        );
        map.zoomTo(5);
    } else {
        const points: Array<[number, number]> = [];

        points.push(
            latitudeLongitudeToNumbers(
                pickup?.latitudeLongitude as Required<LatitudeLongitude>
            )
        );

        // Since we have both a pickup and delivery location
        // we will be showing an arc
        // so make sure that the most extreme point on the arc is within bounds
        points.push(
            latitudeLongitudeToNumbers(
                calculatePointOnArc(
                    pickup?.latitudeLongitude as Required<LatitudeLongitude>,
                    delivery?.latitudeLongitude as Required<LatitudeLongitude>,
                    0.5
                )
            )
        );
        points.push(
            latitudeLongitudeToNumbers(
                delivery?.latitudeLongitude as Required<LatitudeLongitude>
            )
        );

        // Make sure the midpoint of the arc is also on the map
        const boundingBox = calculateBoundingBox(points);

        try {
            map.fitBounds(boundingBox, {
                padding: 100,
            });
        } catch (e) {
            // This might happen if the bounding box is zero
            // So just zoom out for safety
            map.setCenter([-90, 44]);
            map.zoomTo(3);
        }
    }
}
