import Dropdown from 'Cargo/Controls/Dropdown';
import { DropdownElement } from 'Cargo/Controls/DropdownElement';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import { ErrorMessageType } from 'Cargo/Validation';
import { DangerousGoodsQuestionBubble } from 'Features/LineItems/Components/LineItem/QuestionBubbles/DangerousGoodsQuestionBubble';
import { LineItem } from 'generated-openapi-client';
import React from 'react';
import styled from 'styled-components/macro';
import { describeHazardClass } from '../Helpers/describeHazardClass';
import useDangerousGoodsDetailsModal from '../Modals/SelectUNNumberModal/useDangerousGoodsDetailsModal';

interface DangerousGoodsControlProps {
    lineItem: Partial<LineItem>;
    onChange: (update: Partial<LineItem>) => void;
    errorMessage: ErrorMessageType;
    forceValidation: boolean;
}

const DescriptionForUNNumber = styled.div`
    font-size: 20px;
    font-weight: var(--nhu-font-weight-bold);
`;
const DescriptionForClassification = styled.div`
    font-size: 16px;
    font-weight: var(--nhu-font-weight-light);
    color: var(--freightsimple-color-light-text);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

function DescriptionForDangerousItem(props: DangerousGoodsControlProps) {
    if (props.lineItem.dangerousUnNumber === undefined) {
        return <>Dangerous</>;
    }

    return (
        <DropdownElement>
            <HorizontalStack align="left" style={{ maxWidth: '329px' }}>
                <DescriptionForUNNumber>
                    {props.lineItem.dangerousUnNumber}
                </DescriptionForUNNumber>
                <Spacer width={8} />
                <DescriptionForClassification>
                    {describeHazardClass(
                        props.lineItem.dangerousClassification || ''
                    )}
                </DescriptionForClassification>
            </HorizontalStack>
        </DropdownElement>
    );
}

const DangerousGoodsControl: React.FC<DangerousGoodsControlProps> = (
    props: DangerousGoodsControlProps
) => {
    const show = useDangerousGoodsDetailsModal();

    function onOptionSelected(dangerous: boolean) {
        async function handleDangerous() {
            const update = await show(props.lineItem);
            if (update) {
                props.onChange(update);
            }
        }

        if (dangerous) {
            handleDangerous();
        } else {
            props.onChange({
                isDangerous: false,
                dangerousUnNumber: undefined,
                dangerousClassification: undefined,
                dangerousNumberPackages: undefined,
                dangerousPackagingType: undefined,
                dangerousProperShippingName: undefined,
                dangerousPackingGroup: undefined,
                dangerousTechnicalName: undefined,
            });
        }

        return;
    }
    return (
        <>
            <Dropdown
                id="dangerousGoodsDropdown"
                placeholder={
                    <>
                        Dangerous?
                        <DangerousGoodsQuestionBubble />
                    </>
                }
                onOptionSelected={onOptionSelected}
                options={[
                    {
                        item: false,
                        description: 'Not dangerous',
                    },
                    {
                        item: true,
                        description: <DescriptionForDangerousItem {...props} />,
                    },
                ]}
                selectedItem={props.lineItem.isDangerous}
                width={407}
                errorMessage={props.errorMessage}
                forceValidation={props.forceValidation}
            ></Dropdown>
        </>
    );
};
export default DangerousGoodsControl;
