import { H1 } from 'Cargo/Text/Text';
import { Shipment } from 'generated-openapi-client/models/Shipment';
import React from 'react';

interface PickupAddressPageProp {
    shipment: Shipment;
}

export function PickupAddressPage(props: PickupAddressPageProp) {
    console.log({ props });
    return <H1>Pickup Address</H1>;
}
