/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LatitudeLongitude
 */
export interface LatitudeLongitude {
    /**
     * 
     * @type {number}
     * @memberof LatitudeLongitude
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof LatitudeLongitude
     */
    latitude?: number;
}

/**
 * Check if a given object implements the LatitudeLongitude interface.
 */
export function instanceOfLatitudeLongitude(value: object): boolean {
    return true;
}

export function LatitudeLongitudeFromJSON(json: any): LatitudeLongitude {
    return LatitudeLongitudeFromJSONTyped(json, false);
}

export function LatitudeLongitudeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LatitudeLongitude {
    if (json == null) {
        return json;
    }
    return {
        
        'longitude': json['longitude'] == null ? undefined : json['longitude'],
        'latitude': json['latitude'] == null ? undefined : json['latitude'],
    };
}

export function LatitudeLongitudeToJSON(value?: LatitudeLongitude | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'longitude': value['longitude'],
        'latitude': value['latitude'],
    };
}

