/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SelectQuoteRequest
 */
export interface SelectQuoteRequest {
    /**
     * 
     * @type {string}
     * @memberof SelectQuoteRequest
     */
    shipmentId: string;
    /**
     * 
     * @type {string}
     * @memberof SelectQuoteRequest
     */
    quoteId: string;
}

/**
 * Check if a given object implements the SelectQuoteRequest interface.
 */
export function instanceOfSelectQuoteRequest(value: object): boolean {
    if (!('shipmentId' in value)) return false;
    if (!('quoteId' in value)) return false;
    return true;
}

export function SelectQuoteRequestFromJSON(json: any): SelectQuoteRequest {
    return SelectQuoteRequestFromJSONTyped(json, false);
}

export function SelectQuoteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectQuoteRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentId': json['shipmentId'],
        'quoteId': json['quoteId'],
    };
}

export function SelectQuoteRequestToJSON(value?: SelectQuoteRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentId': value['shipmentId'],
        'quoteId': value['quoteId'],
    };
}

