/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Shipment } from './Shipment';
import {
    ShipmentFromJSON,
    ShipmentFromJSONTyped,
    ShipmentToJSON,
} from './Shipment';

/**
 * 
 * @export
 * @interface GetOneShipmentResponse
 */
export interface GetOneShipmentResponse {
    /**
     * 
     * @type {Shipment}
     * @memberof GetOneShipmentResponse
     */
    shipment: Shipment;
}

/**
 * Check if a given object implements the GetOneShipmentResponse interface.
 */
export function instanceOfGetOneShipmentResponse(value: object): boolean {
    if (!('shipment' in value)) return false;
    return true;
}

export function GetOneShipmentResponseFromJSON(json: any): GetOneShipmentResponse {
    return GetOneShipmentResponseFromJSONTyped(json, false);
}

export function GetOneShipmentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOneShipmentResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'shipment': ShipmentFromJSON(json['shipment']),
    };
}

export function GetOneShipmentResponseToJSON(value?: GetOneShipmentResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipment': ShipmentToJSON(value['shipment']),
    };
}

