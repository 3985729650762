import Button from 'Cargo/Controls/Button';
import Dropdown from 'Cargo/Controls/Dropdown';
import Input from 'Cargo/Controls/Input';
import { TextArea } from 'Cargo/Controls/Textarea';
import Box from 'Cargo/Layout/Box';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { useModal } from 'Cargo/Modal/useModal';
import { Legalese, ModalTitle } from 'Cargo/Text/Text';
import { ErrorMessageType } from 'Cargo/Validation';
import { displayNameForCarrier } from 'Data/Carriers';
import { Quote } from 'generated-openapi-client';
import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components/macro';

interface DeleteQuotedShipmentModalProps {
    onConfirm: (deletionReason: string) => void;
    onCancel: () => void;
    cheapestQuote: Quote | undefined;
}

enum ReasonIdentifier {
    ShipmentNotHappening,
    BetterRateElsewhere,
    ComparingWithPastRates,
    Other,
}

const BestRateInfo = styled.div`
    font-size: 14px;
    margin-bottom: 8px;
`;

interface BetterRatesInfoProps {
    onChange: (_: string) => void;
    cheapestQuote: Quote | undefined;
}

function BetterRatesInfo(props: BetterRatesInfoProps) {
    const [carrierName, setCarrierName] = useState('');
    const [rate, setRate] = useState('');
    const [note, setNote] = useState('');

    function describe(cn: string, r: string, n: string) {
        return [cn, r, n].filter((p) => p !== '').join(', ');
    }

    const { cheapestQuote } = props;

    return (
        <>
            <Box>
                <Stack align="left">
                    <HorizontalStack>
                        <Stack align="left">
                            {cheapestQuote !== undefined && (
                                <BestRateInfo>
                                    FreightSimple found rates from{' '}
                                    <strong>${cheapestQuote.price}</strong> with{' '}
                                    <strong>
                                        {displayNameForCarrier(
                                            cheapestQuote.carrierIdentifier
                                        )}
                                    </strong>
                                    .
                                </BestRateInfo>
                            )}
                            {props.cheapestQuote === undefined && (
                                <BestRateInfo>
                                    We&apos;re sorry that FreightSimple could
                                    not find a rate for this shipment
                                </BestRateInfo>
                            )}
                            <BestRateInfo>
                                Please tell us more about the rate you ended up
                                booking
                            </BestRateInfo>
                        </Stack>
                        {cheapestQuote !== undefined && (
                            <>
                                <Spacer width={32} />
                                <img
                                    src={`/carrierLogosSquare/${cheapestQuote?.carrierIdentifier}.png`}
                                />
                            </>
                        )}
                    </HorizontalStack>

                    <Input
                        type="text"
                        label="Carrier / Broker used"
                        value={carrierName}
                        onChange={function (newValue) {
                            setCarrierName(newValue);
                            props.onChange(describe(newValue, rate, note));
                        }}
                    />
                    <Input
                        type="text"
                        label="Rate (in $ CAD / USD)"
                        value={rate}
                        onChange={function (newValue) {
                            setRate(newValue);
                            props.onChange(
                                describe(carrierName, newValue, note)
                            );
                        }}
                    />
                    <Input
                        type="text"
                        label="Any other notes?"
                        value={note}
                        onChange={function (newValue) {
                            setNote(newValue);
                            props.onChange(
                                describe(carrierName, rate, newValue)
                            );
                        }}
                    />
                </Stack>
            </Box>
        </>
    );
}

interface ReasonDropdownProps {
    reasonIdentifier: ReasonIdentifier | undefined;
    onReasonChange: (newReasonIdentiifer: ReasonIdentifier) => void;
    errorMessage: ErrorMessageType;
    forceValidation: boolean;
}

const options = [
    {
        item: ReasonIdentifier.ShipmentNotHappening,
        description: "Shipment isn't happening",
    },
    {
        item: ReasonIdentifier.BetterRateElsewhere,
        description: 'A better rate was found elsewhere',
    },
    {
        item: ReasonIdentifier.ComparingWithPastRates,
        description:
            'I was comparing rates against a shipment I made in the past',
    },
    {
        item: ReasonIdentifier.Other,
        description: 'Other',
    },
];

function ReasonDropdown(props: ReasonDropdownProps) {
    return (
        <Dropdown<ReasonIdentifier>
            unselectedDescription="Select a reason"
            selectedItem={props.reasonIdentifier}
            onOptionSelected={props.onReasonChange}
            options={options}
            width={600}
            errorMessage={props.errorMessage}
            forceValidation={props.forceValidation}
            disableValidateOnBlur={true}
        />
    );
}

function DeleteQuotedShipmentModal(props: DeleteQuotedShipmentModalProps) {
    const [reasonIdentifier, setReasonIdentifier] = useState<
        undefined | ReasonIdentifier
    >();

    console.log('!!!! reasonIdentifier', { reasonIdentifier });

    const [moreInfo, setMoreInfo] = useState<string>('');
    const [forceValidation, setForceValidation] = useState(false);

    const errorMessage =
        reasonIdentifier === undefined ? 'Required' : undefined;

    function showMoreInfo() {
        if (reasonIdentifier === undefined) {
            return false;
        }

        if (reasonIdentifier === ReasonIdentifier.ShipmentNotHappening) {
            return false;
        }

        if (reasonIdentifier === ReasonIdentifier.BetterRateElsewhere) {
            return true;
        }

        if (reasonIdentifier === ReasonIdentifier.ComparingWithPastRates) {
            return true;
        }

        if (reasonIdentifier === ReasonIdentifier.Other) {
            return true;
        }
    }

    function reasonText() {
        const text = options.find((o) => o.item === reasonIdentifier);

        if (text === undefined) {
            throw new Error('Should not get here');
        }

        if (showMoreInfo()) {
            return text.description + ':' + moreInfo;
        } else {
            return text.description;
        }
    }

    function placeholderForReason(
        reasonIdentifier: ReasonIdentifier | undefined
    ) {
        if (reasonIdentifier === ReasonIdentifier.Other) {
            return 'Your feedback helps us make FreightSimple better for you. Please leave your comments in this box!';
        }

        if (reasonIdentifier === ReasonIdentifier.BetterRateElsewhere) {
            return 'Help us improve FreightSimple by letting us know if there are carriers or rates you would like to see available in FreightSimple';
        }

        if (reasonIdentifier === ReasonIdentifier.ComparingWithPastRates) {
            return 'Help us improve FreightSimple by letting us know if we are doing better or worse than other providers you have used in the past';
        }

        return '';
    }

    return (
        <Stack align="left" style={{ padding: '16px', width: '640px' }}>
            <Spacer height={16} />
            <ModalTitle>Delete shipment</ModalTitle>
            <Legalese>
                You can delete this shipment and the associated quotes if you no
                longer need to book it. Please help us improve FreightSimple by
                letting us know why you&apos;re deleting it
            </Legalese>
            <Spacer height={32} />
            <ReasonDropdown
                reasonIdentifier={reasonIdentifier}
                onReasonChange={function (
                    newReasonIdentifier: ReasonIdentifier
                ) {
                    console.log(`!!!! newReasonIdentifier`, {
                        newReasonIdentifier,
                    });
                    setReasonIdentifier(newReasonIdentifier);
                }}
                errorMessage={errorMessage}
                forceValidation={forceValidation}
            />
            <Spacer height={8} />
            {reasonIdentifier === ReasonIdentifier.BetterRateElsewhere && (
                <BetterRatesInfo
                    onChange={function (newValue) {
                        setMoreInfo(newValue);
                    }}
                    cheapestQuote={props.cheapestQuote}
                />
            )}
            {(reasonIdentifier === ReasonIdentifier.Other ||
                reasonIdentifier ===
                    ReasonIdentifier.ComparingWithPastRates) && (
                <>
                    <TextArea
                        rows={4}
                        style={{ width: '520px' }}
                        value={moreInfo}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                            setMoreInfo(e.currentTarget.value)
                        }
                        name="moreInfo"
                        autoComplete="off"
                        placeholder={placeholderForReason(reasonIdentifier)}
                    />
                </>
            )}
            <Spacer height={32} />
            <HorizontalStack>
                <Button secondary onClick={props.onCancel}>
                    Cancel
                </Button>
                <Spacer width={16} />
                <Button
                    disabled={errorMessage !== undefined}
                    onClick={function () {
                        // Validate
                        if (errorMessage !== undefined) {
                            setForceValidation(true);
                            return;
                        }

                        props.onConfirm(reasonText());
                    }}
                >
                    Confirm
                </Button>
            </HorizontalStack>
            <Spacer height={16} />
        </Stack>
    );
}

export function useDeleteQuotedShipmentModal() {
    const showModal = useModal<string>();
    function show(cheapestQuote: Quote | undefined) {
        const p = new Promise<string | undefined>((resolve) => {
            showModal(
                (done) => {
                    return (
                        <DeleteQuotedShipmentModal
                            onConfirm={done}
                            onCancel={function () {
                                done();
                            }}
                            cheapestQuote={cheapestQuote}
                        />
                    );
                },
                (confirmed) => {
                    resolve(confirmed);
                },
                {
                    preventBackdropClick: true,
                }
            );
        });

        return p;
    }

    return show;
}
