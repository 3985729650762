/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CountryCode = {
    UnitedStates: 'UnitedStates',
    Canada: 'Canada'
} as const;
export type CountryCode = typeof CountryCode[keyof typeof CountryCode];


export function instanceOfCountryCode(value: any): boolean {
    return Object.values(CountryCode).includes(value);
}

export function CountryCodeFromJSON(json: any): CountryCode {
    return CountryCodeFromJSONTyped(json, false);
}

export function CountryCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CountryCode {
    return json as CountryCode;
}

export function CountryCodeToJSON(value?: CountryCode | null): any {
    return value as any;
}

