import Dropdown from 'Cargo/Controls/Dropdown';
import { DropdownElement } from 'Cargo/Controls/DropdownElement';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import { ShipmentState } from 'generated-openapi-client';
import React from 'react';

interface ShipmentStatesDropdownProps {
    shipmentState: ShipmentState | undefined;
    onShipmentStateChange: (newValue: ShipmentState | undefined) => void;
}

interface StateRowProps {
    icon: string;
    title: string;
}

function StateRow(props: StateRowProps) {
    return (
        <DropdownElement>
            <HorizontalStack>
                <div style={{ width: '32px' }}>{props.icon}</div>
                <div>{props.title}</div>
            </HorizontalStack>
        </DropdownElement>
    );
}

function ShipmentStatesDropdown(props: ShipmentStatesDropdownProps) {
    const options = [
        {
            item: 'any-state',
            description: <>Any status</>,
        },
        {
            item: ShipmentState.BookingConfirmed,
            description: <StateRow icon="🟡" title="Booked" />,
        },
        {
            item: ShipmentState.InTransit,
            description: <StateRow icon="🔵" title="In Transit" />,
        },

        {
            item: ShipmentState.Delivered,
            description: <StateRow icon="🟢" title="Delivered" />,
        },

        {
            item: ShipmentState.Cancelled,
            description: <StateRow icon="⚫️" title="Cancelled" />,
        },

        {
            item: ShipmentState.Lost,
            description: <StateRow icon="⚫️" title="Lost" />,
        },
    ];

    return (
        <Dropdown<ShipmentState | string>
            unselectedDescription="Any status"
            selectedItem={props.shipmentState}
            onOptionSelected={function (newState: ShipmentState | string) {
                if (newState === 'any-state') {
                    props.onShipmentStateChange(undefined);
                } else {
                    props.onShipmentStateChange(newState as ShipmentState);
                }
            }}
            options={options}
            width={200}
            errorMessage={undefined}
            forceValidation={false}
            placeholder="Shipment Status"
        />
    );
}
export default ShipmentStatesDropdown;
