import { AddLineItemType } from 'Features/LineItems/Types/lineItemTypes';
import { LineItem } from 'generated-openapi-client';

export function totalWeightForLineItems(
    lineItems: Array<LineItem | AddLineItemType>
): number | undefined {
    let totalWeight = 0;

    for (let i = 0; i < lineItems.length; i++) {
        const lineItem = lineItems[i];

        if (lineItem.weightPerHandlingUnit === undefined) {
            return undefined;
        }

        totalWeight +=
            lineItem.weightPerHandlingUnit * lineItem.numberHandlingUnits;
    }

    return totalWeight;
}

export function totalHandlingUnitsForLineItems(
    lineItems: Array<LineItem | AddLineItemType>
): number {
    let totalNumberHandlingUnits = 0;

    for (let i = 0; i < lineItems.length; i++) {
        const lineItem = lineItems[i];

        totalNumberHandlingUnits += lineItem.numberHandlingUnits;
    }

    return totalNumberHandlingUnits;
}

export function totalWeightForLineItem(
    lineItem: LineItem | AddLineItemType
): number | undefined {
    if (lineItem.weightPerHandlingUnit === undefined) {
        return undefined;
    }

    return lineItem.weightPerHandlingUnit * lineItem.numberHandlingUnits;
}
