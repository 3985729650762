/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BookShipmentRequest,
  ChangePickupDatePreBookingRequest,
  CreateShipment2Request,
  GetOneShipmentResponse,
  GetShipmentsResponse,
  HasBookedOnlyOneShipmentResponse,
  HasPaymentMethodResponse,
  Invoice,
  ListDocumentsResponse,
  LocationContext,
  LookUpReferenceNumberResponse,
  LookupNmfcCodeForLineItemRequest,
  LookupNmfcCodeForLineItemResponse,
  LookupNmfcCodeNoDimensionsRequest,
  LookupNmfcCodeNoDimensionsResponse,
  PdfResponse,
  PollQuotesResponse,
  PreBookingShipment,
  RequestManualQuotesRequest,
  RequestPickupDateChangeRequest,
  SelectQuoteRequest,
  SetAddressRequest,
  SetContactRequest,
  SetReferenceAndNotesRequest,
  SwapTrackingIdForShipmentIdResponse,
  TrackingInfo,
} from '../models/index';
import {
    BookShipmentRequestFromJSON,
    BookShipmentRequestToJSON,
    ChangePickupDatePreBookingRequestFromJSON,
    ChangePickupDatePreBookingRequestToJSON,
    CreateShipment2RequestFromJSON,
    CreateShipment2RequestToJSON,
    GetOneShipmentResponseFromJSON,
    GetOneShipmentResponseToJSON,
    GetShipmentsResponseFromJSON,
    GetShipmentsResponseToJSON,
    HasBookedOnlyOneShipmentResponseFromJSON,
    HasBookedOnlyOneShipmentResponseToJSON,
    HasPaymentMethodResponseFromJSON,
    HasPaymentMethodResponseToJSON,
    InvoiceFromJSON,
    InvoiceToJSON,
    ListDocumentsResponseFromJSON,
    ListDocumentsResponseToJSON,
    LocationContextFromJSON,
    LocationContextToJSON,
    LookUpReferenceNumberResponseFromJSON,
    LookUpReferenceNumberResponseToJSON,
    LookupNmfcCodeForLineItemRequestFromJSON,
    LookupNmfcCodeForLineItemRequestToJSON,
    LookupNmfcCodeForLineItemResponseFromJSON,
    LookupNmfcCodeForLineItemResponseToJSON,
    LookupNmfcCodeNoDimensionsRequestFromJSON,
    LookupNmfcCodeNoDimensionsRequestToJSON,
    LookupNmfcCodeNoDimensionsResponseFromJSON,
    LookupNmfcCodeNoDimensionsResponseToJSON,
    PdfResponseFromJSON,
    PdfResponseToJSON,
    PollQuotesResponseFromJSON,
    PollQuotesResponseToJSON,
    PreBookingShipmentFromJSON,
    PreBookingShipmentToJSON,
    RequestManualQuotesRequestFromJSON,
    RequestManualQuotesRequestToJSON,
    RequestPickupDateChangeRequestFromJSON,
    RequestPickupDateChangeRequestToJSON,
    SelectQuoteRequestFromJSON,
    SelectQuoteRequestToJSON,
    SetAddressRequestFromJSON,
    SetAddressRequestToJSON,
    SetContactRequestFromJSON,
    SetContactRequestToJSON,
    SetReferenceAndNotesRequestFromJSON,
    SetReferenceAndNotesRequestToJSON,
    SwapTrackingIdForShipmentIdResponseFromJSON,
    SwapTrackingIdForShipmentIdResponseToJSON,
    TrackingInfoFromJSON,
    TrackingInfoToJSON,
} from '../models/index';

export interface GetShipmentsDocumentPdfRequest {
    documentId: string;
}

export interface GetShipmentsGetOneRequest {
    shipmentId: string;
}

export interface GetShipmentsGetPreBookingShipmentRequest {
    shipmentId: string;
}

export interface GetShipmentsGetTrackingRequest {
    shipmentId: string;
}

export interface GetShipmentsHasPaymentMethodRequest {
    shipmentId: string;
}

export interface GetShipmentsInvoicePdfRequest {
    key: string;
    shipmentId: string;
}

export interface GetShipmentsListDocumentsRequest {
    shipmentId: string;
}

export interface GetShipmentsListInvoicesRequest {
    shipmentId: string;
}

export interface GetShipmentsPickupPackagePdfRequest {
    shipmentId: string;
}

export interface GetShipmentsPollQuotesRequest {
    shipmentId: string;
}

export interface LookUpReferenceNumberRequest {
    referenceNumber: string;
    locationContext: LocationContext;
}

export interface PostBookShipmentRequest {
    bookShipmentRequest?: BookShipmentRequest;
}

export interface PostShipmentSetReferenceAndNotesRequest {
    setReferenceAndNotesRequest?: SetReferenceAndNotesRequest;
}

export interface PostShipmentsChangePickupDatePreBookingRequest {
    changePickupDatePreBookingRequest?: ChangePickupDatePreBookingRequest;
}

export interface PostShipmentsCreate2Request {
    createShipment2Request?: CreateShipment2Request;
}

export interface PostShipmentsDeleteQuotedShipmentRequest {
    shipmentId: string;
    reason: string;
}

export interface PostShipmentsLookupNmfcCodeForLineItemRequest {
    lookupNmfcCodeForLineItemRequest?: LookupNmfcCodeForLineItemRequest;
}

export interface PostShipmentsLookupNmfcCodeNoDimensionsRequest {
    lookupNmfcCodeNoDimensionsRequest?: LookupNmfcCodeNoDimensionsRequest;
}

export interface PostShipmentsMarkReviewingPriorToBookingShipmentRequest {
    shipmentId: string;
}

export interface PostShipmentsRequestManualQuotesRequest {
    requestManualQuotesRequest?: RequestManualQuotesRequest;
}

export interface PostShipmentsRequestPickupDateChangeRequest {
    requestPickupDateChangeRequest?: RequestPickupDateChangeRequest;
}

export interface PostShipmentsSelectQuoteRequest {
    selectQuoteRequest?: SelectQuoteRequest;
}

export interface PostShipmentsSetAddressRequest {
    setAddressRequest?: SetAddressRequest;
}

export interface PostShipmentsSetBrokerRequest {
    shipmentId: string;
    savedBrokerId: string;
}

export interface PostShipmentsSetContactRequest {
    setContactRequest?: SetContactRequest;
}

export interface PostShipmentsSwapTrackingIdForShipmentIdRequest {
    tId: string;
}

/**
 * 
 */
export class ShipmentsApi extends runtime.BaseAPI {

    /**
     * Your GET endpoint
     */
    async getShipmentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetShipmentsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/get-shipments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetShipmentsResponseFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getShipments(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetShipmentsResponse> {
        const response = await this.getShipmentsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getShipmentsDocumentPdfRaw(requestParameters: GetShipmentsDocumentPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PdfResponse>> {
        if (requestParameters['documentId'] == null) {
            throw new runtime.RequiredError(
                'documentId',
                'Required parameter "documentId" was null or undefined when calling getShipmentsDocumentPdf().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['documentId'] != null) {
            queryParameters['documentId'] = requestParameters['documentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/document-pdf`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PdfResponseFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getShipmentsDocumentPdf(requestParameters: GetShipmentsDocumentPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PdfResponse> {
        const response = await this.getShipmentsDocumentPdfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     * Your GET endpoint
     */
    async getShipmentsGetOneRaw(requestParameters: GetShipmentsGetOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOneShipmentResponse>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling getShipmentsGetOne().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/get-one-shipment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOneShipmentResponseFromJSON(jsonValue));
    }

    /**
     * 
     * Your GET endpoint
     */
    async getShipmentsGetOne(requestParameters: GetShipmentsGetOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOneShipmentResponse> {
        const response = await this.getShipmentsGetOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getShipmentsGetPreBookingShipmentRaw(requestParameters: GetShipmentsGetPreBookingShipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreBookingShipment>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling getShipmentsGetPreBookingShipment().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/get-pre-booking-shipment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PreBookingShipmentFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getShipmentsGetPreBookingShipment(requestParameters: GetShipmentsGetPreBookingShipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreBookingShipment> {
        const response = await this.getShipmentsGetPreBookingShipmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getShipmentsGetTrackingRaw(requestParameters: GetShipmentsGetTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrackingInfo>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling getShipmentsGetTracking().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/get-tracking`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrackingInfoFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getShipmentsGetTracking(requestParameters: GetShipmentsGetTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrackingInfo> {
        const response = await this.getShipmentsGetTrackingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getShipmentsHasBookedOnlyOneShipmentRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HasBookedOnlyOneShipmentResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/has-booked-only-one-shipment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HasBookedOnlyOneShipmentResponseFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getShipmentsHasBookedOnlyOneShipment(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HasBookedOnlyOneShipmentResponse> {
        const response = await this.getShipmentsHasBookedOnlyOneShipmentRaw(initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getShipmentsHasPaymentMethodRaw(requestParameters: GetShipmentsHasPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HasPaymentMethodResponse>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling getShipmentsHasPaymentMethod().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/has-payment-method`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HasPaymentMethodResponseFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getShipmentsHasPaymentMethod(requestParameters: GetShipmentsHasPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HasPaymentMethodResponse> {
        const response = await this.getShipmentsHasPaymentMethodRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getShipmentsInvoicePdfRaw(requestParameters: GetShipmentsInvoicePdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PdfResponse>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getShipmentsInvoicePdf().'
            );
        }

        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling getShipmentsInvoicePdf().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['key'] != null) {
            queryParameters['key'] = requestParameters['key'];
        }

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/invoice-pdf`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PdfResponseFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getShipmentsInvoicePdf(requestParameters: GetShipmentsInvoicePdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PdfResponse> {
        const response = await this.getShipmentsInvoicePdfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getShipmentsListDocumentsRaw(requestParameters: GetShipmentsListDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListDocumentsResponse>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling getShipmentsListDocuments().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/list-documents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListDocumentsResponseFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getShipmentsListDocuments(requestParameters: GetShipmentsListDocumentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListDocumentsResponse> {
        const response = await this.getShipmentsListDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getShipmentsListInvoicesRaw(requestParameters: GetShipmentsListInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Invoice>>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling getShipmentsListInvoices().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/list-invoices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InvoiceFromJSON));
    }

    /**
     * Your GET endpoint
     */
    async getShipmentsListInvoices(requestParameters: GetShipmentsListInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Invoice>> {
        const response = await this.getShipmentsListInvoicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getShipmentsPickupPackagePdfRaw(requestParameters: GetShipmentsPickupPackagePdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PdfResponse>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling getShipmentsPickupPackagePdf().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/pickup-package-pdf`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PdfResponseFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getShipmentsPickupPackagePdf(requestParameters: GetShipmentsPickupPackagePdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PdfResponse> {
        const response = await this.getShipmentsPickupPackagePdfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getShipmentsPollQuotesRaw(requestParameters: GetShipmentsPollQuotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PollQuotesResponse>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling getShipmentsPollQuotes().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/poll-quotes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PollQuotesResponseFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getShipmentsPollQuotes(requestParameters: GetShipmentsPollQuotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PollQuotesResponse> {
        const response = await this.getShipmentsPollQuotesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Looks up for previous shipments with the same reference number
     * Your GET endpoint
     */
    async lookUpReferenceNumberRaw(requestParameters: LookUpReferenceNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LookUpReferenceNumberResponse>> {
        if (requestParameters['referenceNumber'] == null) {
            throw new runtime.RequiredError(
                'referenceNumber',
                'Required parameter "referenceNumber" was null or undefined when calling lookUpReferenceNumber().'
            );
        }

        if (requestParameters['locationContext'] == null) {
            throw new runtime.RequiredError(
                'locationContext',
                'Required parameter "locationContext" was null or undefined when calling lookUpReferenceNumber().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['referenceNumber'] != null) {
            queryParameters['referenceNumber'] = requestParameters['referenceNumber'];
        }

        if (requestParameters['locationContext'] != null) {
            queryParameters['locationContext'] = requestParameters['locationContext'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/look-up-reference-number`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LookUpReferenceNumberResponseFromJSON(jsonValue));
    }

    /**
     * Looks up for previous shipments with the same reference number
     * Your GET endpoint
     */
    async lookUpReferenceNumber(requestParameters: LookUpReferenceNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LookUpReferenceNumberResponse> {
        const response = await this.lookUpReferenceNumberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Actually books a shipment with the carrier
     * Book Shipment
     */
    async postBookShipmentRaw(requestParameters: PostBookShipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/book`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BookShipmentRequestToJSON(requestParameters['bookShipmentRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Actually books a shipment with the carrier
     * Book Shipment
     */
    async postBookShipment(requestParameters: PostBookShipmentRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postBookShipmentRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postShipmentSetReferenceAndNotesRaw(requestParameters: PostShipmentSetReferenceAndNotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipment/set-reference-and-notes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetReferenceAndNotesRequestToJSON(requestParameters['setReferenceAndNotesRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postShipmentSetReferenceAndNotes(requestParameters: PostShipmentSetReferenceAndNotesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postShipmentSetReferenceAndNotesRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postShipmentsChangePickupDatePreBookingRaw(requestParameters: PostShipmentsChangePickupDatePreBookingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/change-pickup-date-pre-booking`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePickupDatePreBookingRequestToJSON(requestParameters['changePickupDatePreBookingRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postShipmentsChangePickupDatePreBooking(requestParameters: PostShipmentsChangePickupDatePreBookingRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postShipmentsChangePickupDatePreBookingRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postShipmentsCreate2Raw(requestParameters: PostShipmentsCreate2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateShipment2RequestToJSON(requestParameters['createShipment2Request']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postShipmentsCreate2(requestParameters: PostShipmentsCreate2Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postShipmentsCreate2Raw(requestParameters, initOverrides);
    }

    /**
     * Delete Quoted Shipment
     */
    async postShipmentsDeleteQuotedShipmentRaw(requestParameters: PostShipmentsDeleteQuotedShipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling postShipmentsDeleteQuotedShipment().'
            );
        }

        if (requestParameters['reason'] == null) {
            throw new runtime.RequiredError(
                'reason',
                'Required parameter "reason" was null or undefined when calling postShipmentsDeleteQuotedShipment().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['reason'] != null) {
            queryParameters['reason'] = requestParameters['reason'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/delete-quoted-shipment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Quoted Shipment
     */
    async postShipmentsDeleteQuotedShipment(requestParameters: PostShipmentsDeleteQuotedShipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postShipmentsDeleteQuotedShipmentRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postShipmentsLookupNmfcCodeForLineItemRaw(requestParameters: PostShipmentsLookupNmfcCodeForLineItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LookupNmfcCodeForLineItemResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/shipments/lookup-nmfc-code-for-line-item`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LookupNmfcCodeForLineItemRequestToJSON(requestParameters['lookupNmfcCodeForLineItemRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LookupNmfcCodeForLineItemResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async postShipmentsLookupNmfcCodeForLineItem(requestParameters: PostShipmentsLookupNmfcCodeForLineItemRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LookupNmfcCodeForLineItemResponse> {
        const response = await this.postShipmentsLookupNmfcCodeForLineItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async postShipmentsLookupNmfcCodeNoDimensionsRaw(requestParameters: PostShipmentsLookupNmfcCodeNoDimensionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LookupNmfcCodeNoDimensionsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/shipments/lookup-nmfc-code-no-dimensions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LookupNmfcCodeNoDimensionsRequestToJSON(requestParameters['lookupNmfcCodeNoDimensionsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LookupNmfcCodeNoDimensionsResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async postShipmentsLookupNmfcCodeNoDimensions(requestParameters: PostShipmentsLookupNmfcCodeNoDimensionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LookupNmfcCodeNoDimensionsResponse> {
        const response = await this.postShipmentsLookupNmfcCodeNoDimensionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async postShipmentsMarkReviewingPriorToBookingShipmentRaw(requestParameters: PostShipmentsMarkReviewingPriorToBookingShipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling postShipmentsMarkReviewingPriorToBookingShipment().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/mark-reviewing-prior-to-booking-shipment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postShipmentsMarkReviewingPriorToBookingShipment(requestParameters: PostShipmentsMarkReviewingPriorToBookingShipmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postShipmentsMarkReviewingPriorToBookingShipmentRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postShipmentsRequestManualQuotesRaw(requestParameters: PostShipmentsRequestManualQuotesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/request-manual-quotes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestManualQuotesRequestToJSON(requestParameters['requestManualQuotesRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postShipmentsRequestManualQuotes(requestParameters: PostShipmentsRequestManualQuotesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postShipmentsRequestManualQuotesRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postShipmentsRequestPickupDateChangeRaw(requestParameters: PostShipmentsRequestPickupDateChangeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/request-pickup-date-change`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestPickupDateChangeRequestToJSON(requestParameters['requestPickupDateChangeRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postShipmentsRequestPickupDateChange(requestParameters: PostShipmentsRequestPickupDateChangeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postShipmentsRequestPickupDateChangeRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postShipmentsSelectQuoteRaw(requestParameters: PostShipmentsSelectQuoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/select-quote`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SelectQuoteRequestToJSON(requestParameters['selectQuoteRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postShipmentsSelectQuote(requestParameters: PostShipmentsSelectQuoteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postShipmentsSelectQuoteRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postShipmentsSetAddressRaw(requestParameters: PostShipmentsSetAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/set-address`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetAddressRequestToJSON(requestParameters['setAddressRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postShipmentsSetAddress(requestParameters: PostShipmentsSetAddressRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postShipmentsSetAddressRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postShipmentsSetBrokerRaw(requestParameters: PostShipmentsSetBrokerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['shipmentId'] == null) {
            throw new runtime.RequiredError(
                'shipmentId',
                'Required parameter "shipmentId" was null or undefined when calling postShipmentsSetBroker().'
            );
        }

        if (requestParameters['savedBrokerId'] == null) {
            throw new runtime.RequiredError(
                'savedBrokerId',
                'Required parameter "savedBrokerId" was null or undefined when calling postShipmentsSetBroker().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['shipmentId'] != null) {
            queryParameters['shipmentId'] = requestParameters['shipmentId'];
        }

        if (requestParameters['savedBrokerId'] != null) {
            queryParameters['savedBrokerId'] = requestParameters['savedBrokerId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/set-broker`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postShipmentsSetBroker(requestParameters: PostShipmentsSetBrokerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postShipmentsSetBrokerRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postShipmentsSetContactRaw(requestParameters: PostShipmentsSetContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/set-contact`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetContactRequestToJSON(requestParameters['setContactRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postShipmentsSetContact(requestParameters: PostShipmentsSetContactRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postShipmentsSetContactRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postShipmentsSwapTrackingIdForShipmentIdRaw(requestParameters: PostShipmentsSwapTrackingIdForShipmentIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SwapTrackingIdForShipmentIdResponse>> {
        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling postShipmentsSwapTrackingIdForShipmentId().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['tId'] != null) {
            queryParameters['tId'] = requestParameters['tId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shipments/swap-t-id-for-shipment-id`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SwapTrackingIdForShipmentIdResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async postShipmentsSwapTrackingIdForShipmentId(requestParameters: PostShipmentsSwapTrackingIdForShipmentIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SwapTrackingIdForShipmentIdResponse> {
        const response = await this.postShipmentsSwapTrackingIdForShipmentIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
