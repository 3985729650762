/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ShipmentState = {
    QuoteRequested: 'quote_requested',
    Quoted: 'quoted',
    BookingRequested: 'booking_requested',
    BookingConfirmed: 'booking_confirmed',
    BookingFailed: 'booking_failed',
    InTransit: 'in_transit',
    Delivered: 'delivered',
    Cancelled: 'cancelled',
    Lost: 'lost',
    OnHold: 'on_hold'
} as const;
export type ShipmentState = typeof ShipmentState[keyof typeof ShipmentState];


export function instanceOfShipmentState(value: any): boolean {
    return Object.values(ShipmentState).includes(value);
}

export function ShipmentStateFromJSON(json: any): ShipmentState {
    return ShipmentStateFromJSONTyped(json, false);
}

export function ShipmentStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentState {
    return json as ShipmentState;
}

export function ShipmentStateToJSON(value?: ShipmentState | null): any {
    return value as any;
}

