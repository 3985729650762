import Colors from 'Cargo/Colors';
import Icon from 'Cargo/Icons/Icon';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import moment from 'moment';
import React, { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import styled from 'styled-components/macro';

const DateLabel = styled.div`
    margin-top: 7px;
    font-size: 12px;
    color: ${Colors.LightText};
`;

interface ClearButtonProps {
    date: undefined | string;
    onClear: (newValue: undefined | string) => void;
}

function ClearButton(props: ClearButtonProps) {
    if (!props.date) {
        return <></>;
    }

    return (
        <span
            onClick={function () {
                props.onClear(undefined);
            }}
            style={{ cursor: 'pointer' }}
        >
            <Icon name="times" color={Colors.Red} size={12} solid />
        </span>
    );
}

interface DateFromToDropdownsProps {
    fromText: string;
    toText: string;
    startDate: undefined | string;
    endDate: undefined | string;
    setStartDate: (newValue: undefined | string) => void;
    setEndDate: (newValue: undefined | string) => void;
}

function DateFromToDropdowns(props: DateFromToDropdownsProps) {
    const { startDate, endDate, setStartDate, setEndDate } = props;
    const [startDateFocused, setStartDateFocused] = useState<boolean>(false);
    const [endDateFocused, setEndDateFocused] = useState<boolean>(false);

    function asMoment(value: string | undefined) {
        if (value === undefined) {
            return null;
        }

        return moment(value);
    }

    function onStartDateChanged(m: moment.Moment | null) {
        setStartDate(m?.format('YYYY-MM-DD'));
    }

    function onEndDateChanged(m: moment.Moment | null) {
        setEndDate(m?.format('YYYY-MM-DD'));
    }

    return (
        <div className="DateFromToDropdowns">
            <HorizontalStack>
                <Stack align="left">
                    <DateLabel>
                        {props.fromText}{' '}
                        <ClearButton date={startDate} onClear={setStartDate} />
                    </DateLabel>
                    <SingleDatePicker
                        date={asMoment(startDate)}
                        id="date-dropdown"
                        onDateChange={onStartDateChanged}
                        focused={startDateFocused}
                        onFocusChange={({ focused }) =>
                            setStartDateFocused(focused)
                        }
                        hideKeyboardShortcutsPanel={true}
                        isOutsideRange={() => {
                            return false;
                        }}
                        placeholder=""
                    />
                </Stack>

                <Spacer width={16} />
                <Stack align="left">
                    <DateLabel>
                        {props.toText}{' '}
                        <ClearButton date={endDate} onClear={setEndDate} />
                    </DateLabel>
                    <SingleDatePicker
                        date={asMoment(endDate)}
                        id="date-dropdown"
                        onDateChange={onEndDateChanged}
                        focused={endDateFocused}
                        onFocusChange={({ focused }) =>
                            setEndDateFocused(focused)
                        }
                        isOutsideRange={() => {
                            return false;
                        }}
                        hideKeyboardShortcutsPanel={true}
                        placeholder=""
                    />
                </Stack>
            </HorizontalStack>
        </div>
    );
}
export default DateFromToDropdowns;
