import { useStripe } from '@stripe/react-stripe-js';
import { usePaymentMethodsApi } from 'apis';
import Button from 'Cargo/Controls/Button';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import useAlertModal from 'Cargo/Modal/useAlertModal';
import { useModal } from 'Cargo/Modal/useModal';
import { ModalTitle } from 'Cargo/Text/Text';
import { useAuthentication } from 'Features/Authentication/Slices/authenticationSlice';
import { ModalBody } from 'Features/ViewShipments/Modals/UpdatedBOLModal';

interface StripeBankAdviceModalProps {
    onDone: () => void;
}

function StripeBankAdviceModal(props: StripeBankAdviceModalProps) {
    return (
        <Stack align="center" style={{ padding: '16px', width: '800px' }}>
            <ModalTitle>
                Connect Bank Account with Void Cheque Information
            </ModalTitle>
            <Spacer height={32} />
            <ModalBody>
                Connecting your bank account enables easy convenient payments
                for your freight. You have the option to connect with your
                online bank's username and password, if you have it.
            </ModalBody>
            <Spacer height={16} />
            <ModalBody>
                More likely, you want to enter your account's void cheque
                information. This is called 'Manually Verify'.
            </ModalBody>
            <Spacer height={16} />
            <img
                src="/manualBankVerify.png"
                style={{ margin: '32px', width: '500px' }}
            />
            <ModalBody>
                Make sure you click on 'Manually Verify' at the bottom of the
                next screen to enter void cheque info.
            </ModalBody>
            <Spacer height={32} />
            <HorizontalStack align="center">
                <Button onClick={props.onDone}>Got it!</Button>
            </HorizontalStack>
        </Stack>
    );
}

export function useStripeBankAdviceModal() {
    const showModal = useModal<string>();
    function show() {
        const p = new Promise<string | undefined>((resolve) => {
            showModal(
                (done) => {
                    return <StripeBankAdviceModal onDone={done} />;
                },
                (confirmed) => {
                    resolve(confirmed);
                },
                {
                    preventBackdropClick: true,
                }
            );
        });

        return p;
    }

    return show;
}

export function useAddNewBankAccount(
    refresh: (newPaymentMethodId: string | undefined) => Promise<void>
) {
    const stripe = useStripe();
    const paymentMethodsApi = usePaymentMethodsApi();
    const { user } = useAuthentication();
    const alert = useAlertModal();
    const showBankAdviseModal = useStripeBankAdviceModal();

    return async function () {
        if (stripe === null) {
            console.error('stripe is null');
            return;
        }

        const response =
            await paymentMethodsApi.postPaymentMethodsGenerateSaveBankAccountIntent();
        const clientSecret = response.clientSecret;

        await showBankAdviseModal();
        const result = await stripe.confirmAcssDebitSetup(clientSecret, {
            payment_method: {
                billing_details: {
                    name: user?.name,
                    email: user?.email,
                },
            },
        });

        if (result.error) {
            // Inform the customer that there was an error.
            console.log('An error was logged', {
                message: result.error.message,
            });

            if (
                result.error.message !==
                'Please provide complete payment details.'
            ) {
                await alert('Error', result.error.message);
            }

            refresh(undefined);
        } else {
            // Handle next step based on SetupIntent's status.
            console.log('SetupIntent ID: ' + result.setupIntent.id);
            console.log('SetupIntent status: ' + result.setupIntent.status);
            if (result.setupIntent?.payment_method) {
                refresh(result.setupIntent?.payment_method);
            } else {
                refresh(undefined);
            }
        }
    };
}
