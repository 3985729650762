import { useSavedLocationsApi } from 'apis';
import { useAuthentication } from 'Features/Authentication/Slices/authenticationSlice';
import { SavedLocation } from 'generated-openapi-client';
import { useOnce } from 'Hooks/useOnce';
import { useState } from 'react';

export function useSavedLocations() {
    const { isAuthenticated } = useAuthentication();
    const savedLocationsApi = useSavedLocationsApi();
    const [loadingSavedLocations, setLoadingSavedLocations] = useState(true);

    const [savedLocations, setSavedLocations] = useState<Array<SavedLocation>>(
        []
    );
    const [defaultPickupLocationId, setDefaultPickupLocationId] = useState<
        string | undefined
    >();
    const [defaultDeliveryLocationId, setDefaultDeliveryLocationId] = useState<
        string | undefined
    >();

    async function loadSavedLocations() {
        if (!isAuthenticated) {
            setLoadingSavedLocations(false);
            return;
        }

        setLoadingSavedLocations(true);
        const savedLocationsResponse =
            await savedLocationsApi.getAllSavedLocations();

        console.log(`savedLocationsResponse`, { savedLocationsResponse });

        const items = savedLocationsResponse.items;
        items.sort(function (a, b) {
            return a.location.businessName.localeCompare(
                b.location.businessName
            );
        });
        setSavedLocations(items);
        setDefaultPickupLocationId(
            savedLocationsResponse.defaultPickupLocationId
        );
        setDefaultDeliveryLocationId(
            savedLocationsResponse.defaultDeliveryLocationId
        );

        setLoadingSavedLocations(false);
    }

    useOnce(async () => {
        await loadSavedLocations();
    });

    return {
        savedLocations,
        loadingSavedLocations,
        defaultPickupLocationId,
        defaultDeliveryLocationId,
    };
}
