import axios from 'axios';
import {
    AcceptInvitationRequest,
    CreateAccountException,
    CreateAccountRequest,
    LookUpEmailResponse,
    ResetPasswordException,
    ResetPasswordRequest,
    SharePrefilledInfoRequest,
} from 'generated-openapi-client';

export class UsersApi2 {
    baseUrl: string;
    accessToken: string;

    constructor(baseUrl: string, accessToken: string) {
        this.baseUrl = baseUrl;
        this.accessToken = accessToken;
    }

    async postUsersCreateAccount(request: CreateAccountRequest): Promise<void> {
        const options = {
            headers: { Authorization: `Bearer ${this.accessToken}` },
            validateStatus: () => true,
        };

        const response = await axios.post(
            `${this.baseUrl}/users/create-account`,
            request,
            options
        );

        if (response.status === 200) {
            return;
        }

        if (response.status === 400) {
            throw response.data as CreateAccountException;
        }
    }

    async postUsersResetPassword(request: ResetPasswordRequest): Promise<void> {
        const options = {
            headers: { Authorization: `Bearer ${this.accessToken}` },
            validateStatus: () => true,
        };

        const response = await axios.post(
            `${this.baseUrl}/users/reset-password`,
            request,
            options
        );

        if (response.status === 200) {
            return;
        }

        if (response.status === 400) {
            throw response.data as ResetPasswordException;
        }
    }

    async postUsersSharePrefilledInfo(
        request: SharePrefilledInfoRequest
    ): Promise<void> {
        const options = {
            headers: { Authorization: `Bearer ${this.accessToken}` },
            validateStatus: () => true,
        };

        const response = await axios.post(
            `${this.baseUrl}/users/share-prefilled-info`,
            request,
            options
        );

        if (response.status === 200) {
            return;
        }

        if (response.status === 400) {
            throw Error('Something went wrong');
        }
    }

    async postUsersAcceptInvitation(
        request: AcceptInvitationRequest
    ): Promise<void> {
        const options = {
            headers: { Authorization: `Bearer ${this.accessToken}` },
            validateStatus: () => true,
        };

        const response = await axios.post(
            `${this.baseUrl}/users/accept-invitation`,
            request,
            options
        );

        if (response.status === 200) {
            return;
        }

        if (response.status === 400) {
            throw response.data as CreateAccountException;
        }
    }

    async isEmailUsed(email: string): Promise<boolean | undefined> {
        try {
            const response = (
                await axios.get(
                    `${this.baseUrl}/users/look-up-email?email=${email}`
                )
            ).data as LookUpEmailResponse;
            return response.isUsed;
        } catch (e) {
            console.error(e);
        }
    }
}
