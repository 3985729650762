import Colors from 'Cargo/Colors';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Stack from 'Cargo/Layout/Stack';
import { H3 } from 'Cargo/Text/Text';
import { Currency } from 'generated-openapi-client';

interface InvoiceSectionProps {
    sectionTitle: string;
    totalCost: number;
    currency: Currency;
    shipmentCount: number;
}
function InvoiceSection(props: InvoiceSectionProps) {
    function shipmentCountDescription() {
        if (props.shipmentCount >= 3) {
            return `, ${props.shipmentCount} shipments`;
        } else {
            return ``;
        }
    }

    return (
        <HorizontalStack
            style={{ width: '1220px', marginTop: '24px', marginBottom: '8px' }}
            align="spread"
        >
            <Stack align="left">
                <H3>{props.sectionTitle}</H3>
                <div
                    style={{
                        marginTop: '-4px',
                        marginBottom: '8px',
                        fontSize: '14px',
                        color: Colors.LightText,
                    }}
                >
                    Total Spend : $
                    {props.totalCost.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                    })}{' '}
                    {props.currency}
                    {shipmentCountDescription()}
                </div>
            </Stack>
            {/* <LinkButton>Download All</LinkButton> */}
        </HorizontalStack>
    );
}
export default InvoiceSection;
