import { animated, config, useSpring } from '@react-spring/web';
import Animation from 'Cargo/Animations/Animation';
import { ReactNode } from 'react';

interface DisappearProps {
    children: ReactNode;
}

function Disappear(props: DisappearProps) {
    const styles = useSpring({
        config: { ...config.slow },
        from: {
            opacity: 1,
            width: 32,
            height: 32,
            marginLeft: 0,
            marginTop: 0,
        },
        to: {
            opacity: 0,
            width: 0,
            height: 0,
            marginLeft: 16,
            marginTop: 16,
        },
        delay: 2500,
    });
    return (
        <animated.div style={{ ...styles, overflow: 'hidden' }}>
            {props.children}
        </animated.div>
    );
}

function QuotingSuccessAnimation() {
    return (
        <Disappear>
            <div style={{ width: '32px', height: '32px' }}>
                <Animation
                    pathToAnimation="/animations/green-success-spinner.json"
                    lottieFilesUrl="https://lottiefiles.com/9917-success"
                    loop={false}
                />
            </div>
        </Disappear>
    );
}
export default QuotingSuccessAnimation;
