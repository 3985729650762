/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CountryCode } from './CountryCode';
import {
    CountryCodeFromJSON,
    CountryCodeFromJSONTyped,
    CountryCodeToJSON,
} from './CountryCode';

/**
 * 
 * @export
 * @interface LookUpCityResponse
 */
export interface LookUpCityResponse {
    /**
     * 
     * @type {CountryCode}
     * @memberof LookUpCityResponse
     */
    countryCode?: CountryCode;
    /**
     * 
     * @type {string}
     * @memberof LookUpCityResponse
     */
    stateOrProvinceCode?: string;
    /**
     * 
     * @type {string}
     * @memberof LookUpCityResponse
     */
    city?: string;
    /**
     * 
     * @type {number}
     * @memberof LookUpCityResponse
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof LookUpCityResponse
     */
    latitude?: number;
    /**
     * 
     * @type {string}
     * @memberof LookUpCityResponse
     */
    distributionWarehouseName?: string;
}

/**
 * Check if a given object implements the LookUpCityResponse interface.
 */
export function instanceOfLookUpCityResponse(value: object): boolean {
    return true;
}

export function LookUpCityResponseFromJSON(json: any): LookUpCityResponse {
    return LookUpCityResponseFromJSONTyped(json, false);
}

export function LookUpCityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LookUpCityResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'countryCode': json['countryCode'] == null ? undefined : CountryCodeFromJSON(json['countryCode']),
        'stateOrProvinceCode': json['stateOrProvinceCode'] == null ? undefined : json['stateOrProvinceCode'],
        'city': json['city'] == null ? undefined : json['city'],
        'longitude': json['longitude'] == null ? undefined : json['longitude'],
        'latitude': json['latitude'] == null ? undefined : json['latitude'],
        'distributionWarehouseName': json['distributionWarehouseName'] == null ? undefined : json['distributionWarehouseName'],
    };
}

export function LookUpCityResponseToJSON(value?: LookUpCityResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'countryCode': CountryCodeToJSON(value['countryCode']),
        'stateOrProvinceCode': value['stateOrProvinceCode'],
        'city': value['city'],
        'longitude': value['longitude'],
        'latitude': value['latitude'],
        'distributionWarehouseName': value['distributionWarehouseName'],
    };
}

