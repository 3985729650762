import Button from 'Cargo/Controls/Button';
import Input from 'Cargo/Controls/Input';
import { ReactionType, useReaction } from 'Cargo/Hooks/Reaction';
import ProgressSpinner, {
    ProgressSpinnerSizes,
} from 'Cargo/Icons/ProgressSpinner';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { useModal } from 'Cargo/Modal/useModal';
import { Legalese, Microcopy, ModalTitle } from 'Cargo/Text/Text';
import { sumOf } from 'Helpers/sumOf';
import { usePaymentMethodsApi } from 'apis';
import { BankAccount, CreditCard } from 'generated-openapi-client';
import { Invoice } from 'generated-openapi-client/models/Invoice';
import { PaymentMethod } from 'generated-openapi-client/models/PaymentMethod';
import { useEffect, useState } from 'react';
import { PaymentMethodsDropdown } from '../Components/PaymentMethodsDropdown';

interface SelectPaymentMethodAndConfirmPaymentModal {
    onFinished: () => void;
    selectedInvoices: Invoice[];
}

function SelectPaymentMethodAndConfirmPaymentModal(
    props: SelectPaymentMethodAndConfirmPaymentModal
) {
    const [bankAccounts, setBankAccounts] = useState<Array<BankAccount>>([]);
    const [creditCards, setCreditCards] = useState<Array<CreditCard>>([]);
    const [isChequePaymentPermitted, setIsChequePaymentPermitted] =
        useState(false);
    const [isEFTPaymentPermitted, setIsEFTPaymentPermitted] = useState(false);
    const [isETransferPaymentPermitted, setIsETransferPaymentPermitted] =
        useState(false);
    const [isWirePaymentPermitted, setIsWirePaymentPermitted] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const paymentMethodsApi = usePaymentMethodsApi();
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
        string | undefined
    >(undefined);
    const [paymentInProgress, setPaymentInProgress] = useState(false);
    const [paymentReference, setPaymentReference] = useState('');

    async function loadPaymentMethods() {
        const response = await paymentMethodsApi.getAllPaymentMethods();
        console.log({ response });
        setBankAccounts(response.bankAccounts);
        setCreditCards(response.creditCards);
        setIsChequePaymentPermitted(response.isChequePaymentPermitted);
        setIsEFTPaymentPermitted(response.isEftPaymentPermitted);
        setIsETransferPaymentPermitted(response.isEtransferPaymentPermitted);
        setIsWirePaymentPermitted(response.isWirePaymentPermitted);
        setSelectedPaymentMethod(response.defaultPaymentMethod);
        setInitialLoading(false);
    }

    function anyPaymentMethods(): boolean {
        return (
            bankAccounts.length > 0 ||
            creditCards.length > 0 ||
            isChequePaymentPermitted ||
            isEFTPaymentPermitted ||
            isETransferPaymentPermitted ||
            isWirePaymentPermitted
        );
    }

    const { showReaction } = useReaction();

    const isBankAccount =
        bankAccounts.find(
            (ba) => ba.stripePaymentMethodId == selectedPaymentMethod
        ) !== undefined;

    const isCreditCard =
        creditCards.find(
            (cc) => cc.stripePaymentMethodId == selectedPaymentMethod
        ) !== undefined;

    useEffect(() => {
        loadPaymentMethods();
    }, []);

    function onCancel() {
        props.onFinished();
    }

    function paymentMethod(): PaymentMethod {
        if (isBankAccount) {
            return PaymentMethod.BankAccount;
        }

        if (isCreditCard) {
            return PaymentMethod.CreditCard;
        }

        if (selectedPaymentMethod == 'cheque') {
            return PaymentMethod.Cheque;
        }

        if (selectedPaymentMethod == 'etransfer') {
            return PaymentMethod.InteracETransfer;
        }

        if (selectedPaymentMethod == 'eft') {
            return PaymentMethod.Eft;
        }

        if (selectedPaymentMethod == 'wire') {
            return PaymentMethod.Wire;
        }

        throw new Error('Unknown payment method');
    }

    async function onConfirm() {
        setPaymentInProgress(true);
        try {
            const response =
                await paymentMethodsApi.postPaymentMethodsPayInvoices({
                    payInvoicesRequest: {
                        paymentMethod: paymentMethod(),
                        paymentMethodId: selectedPaymentMethod,
                        paymentReference,
                        invoiceIds: props.selectedInvoices.map(
                            (invoice) => invoice.invoiceId
                        ),
                    },
                });
            console.log({ response });
            showReaction(ReactionType.Success);
            await props.onFinished();
        } catch (e) {
            //
        }

        setPaymentInProgress(false);
    }

    function describeNumberInvoices() {
        // Zero should not be an option
        if (props.selectedInvoices.length <= 0) {
            throw new Error('invoices length is zero - should not be');
        }
        const currency = props.selectedInvoices[0].currency;
        const invoicesDescription =
            props.selectedInvoices.length === 1 ? `invoice` : `invoices`;
        const invoicesTotal = sumOf(
            props.selectedInvoices,
            (invoice) => invoice.amount
        );
        return `${
            props.selectedInvoices.length
        } ${invoicesDescription}, totaling $${invoicesTotal.toFixed(
            2
        )} ${currency}`;
    }

    function confirmText() {
        if (isBankAccount) {
            return 'Process Bank Payment';
        }

        if (isCreditCard) {
            return 'Process Card Payment';
        }

        if (selectedPaymentMethod == 'cheque') {
            return 'Confirm Cheque Sent';
        }

        if (selectedPaymentMethod == 'etransfer') {
            return 'Confirm eTransfer Sent';
        }

        if (selectedPaymentMethod == 'eft') {
            return 'Confirm EFT Sent';
        }

        if (selectedPaymentMethod == 'wire') {
            return 'Confirm Wire Sent';
        }

        return 'Confirm';
    }

    function paymentDescription() {
        if (isBankAccount) {
            return '';
        }

        if (isCreditCard) {
            return '';
        }

        if (selectedPaymentMethod == 'cheque') {
            return `Please check with our team for the correct address to mail it to.`;
        }

        if (selectedPaymentMethod == 'etransfer') {
            return `Please send eTransfer to invoices@freightsimple.com`;
        }

        if (selectedPaymentMethod == 'eft') {
            return `Please check with our team for the correct EFT instructions.`;
        }

        if (selectedPaymentMethod == 'wire') {
            return `Please check with our team for the correct wire instructions.`;
        }
    }

    function showPaymentReferenceInput(): boolean {
        if (isBankAccount) {
            return false;
        }

        if (isCreditCard) {
            return false;
        }

        return true;
    }

    return (
        <Stack align="center" style={{ width: '550px' }}>
            <Stack align="left">
                {initialLoading && (
                    <ProgressSpinner size={ProgressSpinnerSizes.Small} />
                )}
                {!initialLoading && !anyPaymentMethods() && (
                    <>
                        <ModalTitle>No Payment Methods</ModalTitle>
                        <>
                            You don't have any payment methods on file. Please
                            add a 'Credit Card' or a 'Bank Account' from the
                            Finance menu. Alternatively please talk to our
                            support team about other options.
                        </>
                        <Spacer height={32} />
                        <HorizontalStack width="100%" align="center">
                            <Button onClick={onCancel}>Done</Button>
                        </HorizontalStack>
                    </>
                )}
                {!initialLoading && anyPaymentMethods() && (
                    <>
                        <ModalTitle>Confirm Payment</ModalTitle>
                        <Legalese>
                            You have selected {describeNumberInvoices()} to pay{' '}
                        </Legalese>
                        <Spacer height={24} />
                        <Microcopy>
                            Please select the method you would like to use to
                            pay these
                        </Microcopy>
                        <PaymentMethodsDropdown
                            cards={creditCards}
                            bankAccounts={bankAccounts}
                            isChequePaymentPermitted={isChequePaymentPermitted}
                            isEFTPaymentPermitted={isEFTPaymentPermitted}
                            isETransferPaymentPermitted={
                                isETransferPaymentPermitted
                            }
                            isWirePaymentPermitted={isWirePaymentPermitted}
                            selectedStripePaymentMethodId={
                                selectedPaymentMethod
                            }
                            onOptionSelected={setSelectedPaymentMethod}
                        />
                        <Spacer height={32} />

                        {showPaymentReferenceInput() && (
                            <div
                                style={{
                                    width: '500px',
                                    padding: '16px',
                                    border: '1px solid #ddd',
                                    paddingTop: '32px',
                                }}
                            >
                                <Microcopy>{paymentDescription()}</Microcopy>
                                <Spacer height={16} />
                                <Input
                                    label="(Optional) If you have a payment reference number, enter it below"
                                    placeholder=""
                                    type="text"
                                    value={paymentReference}
                                    onChange={setPaymentReference}
                                />
                            </div>
                        )}

                        <Spacer height={32} />
                        <HorizontalStack width="100%" align="right">
                            <Button onClick={onCancel} secondary>
                                Cancel
                            </Button>
                            <Spacer width={16} />
                            <Button
                                loading={paymentInProgress}
                                onClick={onConfirm}
                            >
                                {confirmText()}
                            </Button>
                        </HorizontalStack>
                    </>
                )}
            </Stack>
        </Stack>
    );
}

export function useSelectPaymentMethodAndConfirmPaymentModal() {
    const showModal = useModal<void>();

    async function show(selectedInvoices: Invoice[]): Promise<void> {
        return new Promise<void>((resolve) => {
            showModal(
                (done) => {
                    return (
                        <SelectPaymentMethodAndConfirmPaymentModal
                            onFinished={() => {
                                done();
                            }}
                            selectedInvoices={selectedInvoices}
                        />
                    );
                },
                async (data) => {
                    resolve(data);
                },
                {
                    preventBackdropClick: true,
                }
            );
        });
    }
    return show;
}
