/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    userId: string;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    lastLoginAt: Date;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phoneExtension: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    accountingNotificationsEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    allShipmentNotificationsEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    branchName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    branchId?: string;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    if (!('email' in value)) return false;
    if (!('name' in value)) return false;
    if (!('userId' in value)) return false;
    if (!('lastLoginAt' in value)) return false;
    if (!('phone' in value)) return false;
    if (!('phoneExtension' in value)) return false;
    if (!('accountingNotificationsEnabled' in value)) return false;
    if (!('allShipmentNotificationsEnabled' in value)) return false;
    return true;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'name': json['name'],
        'userId': json['userId'],
        'lastLoginAt': (new Date(json['lastLoginAt'])),
        'phone': json['phone'],
        'phoneExtension': json['phoneExtension'],
        'accountingNotificationsEnabled': json['accountingNotificationsEnabled'],
        'allShipmentNotificationsEnabled': json['allShipmentNotificationsEnabled'],
        'branchName': json['branchName'] == null ? undefined : json['branchName'],
        'branchId': json['branchId'] == null ? undefined : json['branchId'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'name': value['name'],
        'userId': value['userId'],
        'lastLoginAt': ((value['lastLoginAt']).toISOString()),
        'phone': value['phone'],
        'phoneExtension': value['phoneExtension'],
        'accountingNotificationsEnabled': value['accountingNotificationsEnabled'],
        'allShipmentNotificationsEnabled': value['allShipmentNotificationsEnabled'],
        'branchName': value['branchName'],
        'branchId': value['branchId'],
    };
}

