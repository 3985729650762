/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InvoiceState = {
    Issued: 'Issued',
    Voided: 'Voided',
    SettlementPending: 'SettlementPending',
    Settled: 'Settled'
} as const;
export type InvoiceState = typeof InvoiceState[keyof typeof InvoiceState];


export function instanceOfInvoiceState(value: any): boolean {
    return Object.values(InvoiceState).includes(value);
}

export function InvoiceStateFromJSON(json: any): InvoiceState {
    return InvoiceStateFromJSONTyped(json, false);
}

export function InvoiceStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceState {
    return json as InvoiceState;
}

export function InvoiceStateToJSON(value?: InvoiceState | null): any {
    return value as any;
}

