import {
    QuestionBubbleHeader,
    QuestionBubbleLabel,
    SmallQuestionBubble,
} from 'Cargo/Controls/QuestionBubble';
import Spacer from 'Cargo/Layout/Spacer';

export function TemperatureHandlingQuestionBubble() {
    return (
        <SmallQuestionBubble
            description={
                <>
                    <QuestionBubbleHeader>{`Stackable`}</QuestionBubbleHeader>
                    <QuestionBubbleLabel>
                        <div>
                            If your shipment requires protection from freezing,
                            or refridgerated or frozen handling you can specify
                            it here.
                        </div>
                        <Spacer height={16} />
                        <div>
                            Unfortunatley we don&apos;t currently support
                            refridgerated or frozen shipments
                        </div>
                    </QuestionBubbleLabel>
                </>
            }
            learnMoreUrl="https://help.freightsimple.com/using-freightsimple/quoting/shipment-contents#temperature-handling"
        />
    );
}
