import DatePickerControl from 'Cargo/Controls/DatePickerControl';
import Link from 'Cargo/Controls/Link';
import Spacer from 'Cargo/Layout/Spacer';
import { CountryCode } from 'generated-openapi-client';
import moment from 'moment';
import { memo } from 'react';
import BookShipmentSection from './BookShipmentSection';

interface PickupDateSectionProps {
    pickupCountry?: CountryCode;
    pickupDate: moment.Moment;
    setPickupDate: (_: moment.Moment) => void;
    index: number;
}

export const MemoizedPickupDateSection = memo(PickupDateSection)

function PickupDateSection(props: PickupDateSectionProps) {
    return (
        <BookShipmentSection
            id="pickup-date-section"
            index={props.index}
            header="Pickup Date"
            description={
                <>
                    <div>
                        A pickup can be booked within one month. While most
                        pickups happen on the requested date, this is not
                        guaranteed. Pickup dates can be changed before and after
                        booking.
                    </div>
                    <Spacer height={8} />
                    <div>
                        Read more about pickup dates and ensuring your shipments
                        are on-time{' '}
                        <Link
                            newTab
                            to="https://help.freightsimple.com/using-freightsimple/quoting/pickup-date"
                        >
                            here
                        </Link>
                        .
                    </div>
                </>
            }
        >
            <div style={{ maxWidth: '618px', marginBottom: '32px' }}>
                <Spacer height={26} />
                <DatePickerControl
                    date={props.pickupDate.format('YYYY-MM-DD')}
                    onDateChange={props.setPickupDate}
                    country={props.pickupCountry}
                />
            </div>
        </BookShipmentSection>
    );
}
export default PickupDateSection;
