import { ErrorMessageType } from 'Cargo/Validation';
import { AddContactContactType } from 'Features/Contacts/Types/contactTypes';
import { AddLocationLocationType } from 'Features/Locations/Types/locationTypes';
import { LocationContext, LocationType } from 'generated-openapi-client';

const PHONE_NUMBER_REGEX = new RegExp(/^\d{10}$/i);
const EMAIL_REGEX = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
const NAME_REGEX = new RegExp(
    /^[a-zA-ZÀ-ÿ]+(([',. -][a-zA-ZÀ-ÿ -])?[a-zA-ZÀ-ÿ]*)*$/
);

function isPhoneNumberObviouslyWrong(phoneNumber: string) {
    const obviouslyWrong = [
        '0000000000',
        '1111111111',
        '11111111111', // we allow 11-digit numbers that start with 1
        '2222222222',
        '3333333333',
        '4444444444',
        '5555555555',
        '6666666666',
        '7777777777',
        '8888888888',
        '9999999999',
        '1234567890',
        '1212121212',
        '2323232323',
        '3434343434',
        '4545454545',
        '5656565656',
        '6767676767',
        '7878787878',
        '8989898989',
        '9090909090',
    ];

    if (obviouslyWrong.includes(phoneNumber)) return true;

    return false;
}

export function errorMessageForPhoneNumber(
    phoneNumber: string | undefined
): ErrorMessageType {
    if (phoneNumber === undefined || phoneNumber === '') {
        return 'Required';
    }

    if (phoneNumber.length < 10) {
        return 'Phone number should be 10 digits';
    }

    if (phoneNumber[0] === '1') {
        return 'Phone number should not start with 1';
    }

    if (!PHONE_NUMBER_REGEX.test(phoneNumber)) {
        return 'Please enter a valid phone number';
    }

    if (isPhoneNumberObviouslyWrong(phoneNumber)) {
        return 'Please enter a valid phone number';
    }
}

export function errorMessageForContactName(
    contactName: string | undefined
): ErrorMessageType {
    if (contactName === undefined || contactName === '') {
        return 'Required';
    }

    if (contactName.length === 1) {
        return 'Too short';
    }

    if (!NAME_REGEX.test(contactName.trim())) {
        return 'This does not seem to be a valid name';
    }
}

export function errorMessageForEmail(
    emailAddress: string | undefined
): ErrorMessageType {
    if (emailAddress === undefined || emailAddress === '') {
        return 'Required';
    }

    if (emailAddress.length < 4) {
        return 'Too short';
    }

    if (!EMAIL_REGEX.test(emailAddress)) {
        return 'Please enter a valid email address';
    }
}

export function errorMessageForNotRequiredEmail(
    emailAddress: string | undefined
): ErrorMessageType {
    if (emailAddress === undefined || emailAddress === '') {
        return;
    }
    return errorMessageForEmail(emailAddress)
}

export interface ContactErrors {
    contactName: ErrorMessageType;
    emailAddress: ErrorMessageType;
    phoneNumber: ErrorMessageType;
}

export function warningMessagesForContact(
    contact: AddContactContactType,
    location: AddLocationLocationType,
    context: LocationContext
): ContactErrors {
    function contactNameWarning(): ErrorMessageType {
        if (
            contact.contactName !== undefined &&
            contact.contactName === location.businessName
        ) {
            if (location.locationType !== LocationType.Residential) {
                return `Is this a residential ${context}? If so please include in the quote for accurate pricing`;
            }
        }

        return undefined;
    }

    return {
        contactName: contactNameWarning(),
        phoneNumber: undefined,
        emailAddress: undefined,
    };
}

export enum EmailRequired {
    EmailRequired,
    EmailNotRequired,
}

export function errorMessagesForContact(
    contact: AddContactContactType,
    emailRequired: EmailRequired,
    comparisonContacts?: Map<string, AddContactContactType>
): ContactErrors {
    const errors = {
        phoneNumber: errorMessageForPhoneNumber(contact.phoneNumber),
        contactName: errorMessageForContactName(contact.contactName),
        emailAddress:
            emailRequired === EmailRequired.EmailRequired
                ? errorMessageForEmail(contact.emailAddress)
                : errorMessageForNotRequiredEmail(contact.emailAddress),
    };

    if (errors.phoneNumber === undefined) {
        comparisonContacts?.forEach(function (value, key) {
            if (
                value.phoneNumber !== undefined &&
                contact.phoneNumber !== undefined
            ) {
                if (
                    value.phoneNumber === contact.phoneNumber &&
                    value.phoneNumberExtension === contact.phoneNumberExtension
                ) {
                    errors.phoneNumber = `Same as ${key}`;
                }
            }
        });
    }
    return errors;
}
