/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Hours } from './Hours';
import {
    HoursFromJSON,
    HoursFromJSONTyped,
    HoursToJSON,
} from './Hours';
import type { LatitudeLongitude } from './LatitudeLongitude';
import {
    LatitudeLongitudeFromJSON,
    LatitudeLongitudeFromJSONTyped,
    LatitudeLongitudeToJSON,
} from './LatitudeLongitude';
import type { LocationType } from './LocationType';
import {
    LocationTypeFromJSON,
    LocationTypeFromJSONTyped,
    LocationTypeToJSON,
} from './LocationType';
import type { PreQuoteAddress } from './PreQuoteAddress';
import {
    PreQuoteAddressFromJSON,
    PreQuoteAddressFromJSONTyped,
    PreQuoteAddressToJSON,
} from './PreQuoteAddress';

/**
 * 
 * @export
 * @interface PreQuoteLocation
 */
export interface PreQuoteLocation {
    /**
     * 
     * @type {LocationType}
     * @memberof PreQuoteLocation
     */
    locationType: LocationType;
    /**
     * 
     * @type {string}
     * @memberof PreQuoteLocation
     */
    businessName?: string;
    /**
     * 
     * @type {PreQuoteAddress}
     * @memberof PreQuoteLocation
     */
    address: PreQuoteAddress;
    /**
     * 
     * @type {Hours}
     * @memberof PreQuoteLocation
     */
    hours: Hours;
    /**
     * 
     * @type {LatitudeLongitude}
     * @memberof PreQuoteLocation
     */
    latitudeLongitude: LatitudeLongitude;
    /**
     * 
     * @type {Array<string>}
     * @memberof PreQuoteLocation
     */
    accessorials: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PreQuoteLocation
     */
    notes: string;
}

/**
 * Check if a given object implements the PreQuoteLocation interface.
 */
export function instanceOfPreQuoteLocation(value: object): boolean {
    if (!('locationType' in value)) return false;
    if (!('address' in value)) return false;
    if (!('hours' in value)) return false;
    if (!('latitudeLongitude' in value)) return false;
    if (!('accessorials' in value)) return false;
    if (!('notes' in value)) return false;
    return true;
}

export function PreQuoteLocationFromJSON(json: any): PreQuoteLocation {
    return PreQuoteLocationFromJSONTyped(json, false);
}

export function PreQuoteLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreQuoteLocation {
    if (json == null) {
        return json;
    }
    return {
        
        'locationType': LocationTypeFromJSON(json['locationType']),
        'businessName': json['businessName'] == null ? undefined : json['businessName'],
        'address': PreQuoteAddressFromJSON(json['address']),
        'hours': HoursFromJSON(json['hours']),
        'latitudeLongitude': LatitudeLongitudeFromJSON(json['latitudeLongitude']),
        'accessorials': json['accessorials'],
        'notes': json['notes'],
    };
}

export function PreQuoteLocationToJSON(value?: PreQuoteLocation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'locationType': LocationTypeToJSON(value['locationType']),
        'businessName': value['businessName'],
        'address': PreQuoteAddressToJSON(value['address']),
        'hours': HoursToJSON(value['hours']),
        'latitudeLongitude': LatitudeLongitudeToJSON(value['latitudeLongitude']),
        'accessorials': value['accessorials'],
        'notes': value['notes'],
    };
}

