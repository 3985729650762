import { FlowItem } from '../Types/FlowItem';

export function getNextInFlow(
    flow: Array<FlowItem>,
    currentIdentifier: string
): string {
    const currentIndex = flow.findIndex(function (item) {
        return item.identifier === currentIdentifier;
    });

    if (currentIndex === -1) {
        console.error(`Item not found`, { currentIdentifier, flow });
        throw new Error(`Item not found ${currentIdentifier}`);
    }

    if (currentIndex === flow.length - 1) {
        // Last element, so there is no next
        throw new Error('Already at end of flow');
    }

    return flow[currentIndex + 1].identifier;
}
