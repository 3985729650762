/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';
import type { LocationContext } from './LocationContext';
import {
    LocationContextFromJSON,
    LocationContextFromJSONTyped,
    LocationContextToJSON,
} from './LocationContext';
import type { SavedContact } from './SavedContact';
import {
    SavedContactFromJSON,
    SavedContactFromJSONTyped,
    SavedContactToJSON,
} from './SavedContact';

/**
 * 
 * @export
 * @interface SavedLocation
 */
export interface SavedLocation {
    /**
     * 
     * @type {string}
     * @memberof SavedLocation
     */
    savedLocationId: string;
    /**
     * 
     * @type {Location}
     * @memberof SavedLocation
     */
    location: Location;
    /**
     * 
     * @type {LocationContext}
     * @memberof SavedLocation
     */
    locationContext?: LocationContext;
    /**
     * 
     * @type {Array<SavedContact>}
     * @memberof SavedLocation
     */
    contacts: Array<SavedContact>;
    /**
     * 
     * @type {string}
     * @memberof SavedLocation
     */
    defaultSavedContactId: string;
    /**
     * 
     * @type {boolean}
     * @memberof SavedLocation
     */
    isFavourite: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SavedLocation
     */
    isBranch: boolean;
}

/**
 * Check if a given object implements the SavedLocation interface.
 */
export function instanceOfSavedLocation(value: object): boolean {
    if (!('savedLocationId' in value)) return false;
    if (!('location' in value)) return false;
    if (!('contacts' in value)) return false;
    if (!('defaultSavedContactId' in value)) return false;
    if (!('isFavourite' in value)) return false;
    if (!('isBranch' in value)) return false;
    return true;
}

export function SavedLocationFromJSON(json: any): SavedLocation {
    return SavedLocationFromJSONTyped(json, false);
}

export function SavedLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SavedLocation {
    if (json == null) {
        return json;
    }
    return {
        
        'savedLocationId': json['savedLocationId'],
        'location': LocationFromJSON(json['location']),
        'locationContext': json['locationContext'] == null ? undefined : LocationContextFromJSON(json['locationContext']),
        'contacts': ((json['contacts'] as Array<any>).map(SavedContactFromJSON)),
        'defaultSavedContactId': json['defaultSavedContactId'],
        'isFavourite': json['isFavourite'],
        'isBranch': json['isBranch'],
    };
}

export function SavedLocationToJSON(value?: SavedLocation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'savedLocationId': value['savedLocationId'],
        'location': LocationToJSON(value['location']),
        'locationContext': LocationContextToJSON(value['locationContext']),
        'contacts': ((value['contacts'] as Array<any>).map(SavedContactToJSON)),
        'defaultSavedContactId': value['defaultSavedContactId'],
        'isFavourite': value['isFavourite'],
        'isBranch': value['isBranch'],
    };
}

