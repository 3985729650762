/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HandlingUnitType } from './HandlingUnitType';
import {
    HandlingUnitTypeFromJSON,
    HandlingUnitTypeFromJSONTyped,
    HandlingUnitTypeToJSON,
} from './HandlingUnitType';
import type { TemperatureHandling } from './TemperatureHandling';
import {
    TemperatureHandlingFromJSON,
    TemperatureHandlingFromJSONTyped,
    TemperatureHandlingToJSON,
} from './TemperatureHandling';

/**
 * Represents a line item
 * @export
 * @interface LineItem
 */
export interface LineItem {
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    numberHandlingUnits: number;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    length: number;
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    width: number;
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    height: number;
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    weightPerHandlingUnit: number;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    freightClass?: string;
    /**
     * 
     * @type {HandlingUnitType}
     * @memberof LineItem
     */
    handlingUnitType: HandlingUnitType;
    /**
     * 
     * @type {TemperatureHandling}
     * @memberof LineItem
     */
    temperatureHandling: TemperatureHandling;
    /**
     * 
     * @type {boolean}
     * @memberof LineItem
     */
    isDangerous: boolean;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    nmfcItemNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LineItem
     */
    isStackable: boolean;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    lineItemId: string;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    dangerousUnNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    dangerousClassification?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    dangerousPackingGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    dangerousProperShippingName?: string;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    dangerousTechnicalName?: string;
    /**
     * 
     * @type {number}
     * @memberof LineItem
     */
    dangerousNumberPackages?: number;
    /**
     * 
     * @type {string}
     * @memberof LineItem
     */
    dangerousPackagingType?: string;
}

/**
 * Check if a given object implements the LineItem interface.
 */
export function instanceOfLineItem(value: object): boolean {
    if (!('numberHandlingUnits' in value)) return false;
    if (!('description' in value)) return false;
    if (!('length' in value)) return false;
    if (!('width' in value)) return false;
    if (!('height' in value)) return false;
    if (!('weightPerHandlingUnit' in value)) return false;
    if (!('handlingUnitType' in value)) return false;
    if (!('temperatureHandling' in value)) return false;
    if (!('isDangerous' in value)) return false;
    if (!('isStackable' in value)) return false;
    if (!('lineItemId' in value)) return false;
    return true;
}

export function LineItemFromJSON(json: any): LineItem {
    return LineItemFromJSONTyped(json, false);
}

export function LineItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): LineItem {
    if (json == null) {
        return json;
    }
    return {
        
        'numberHandlingUnits': json['numberHandlingUnits'],
        'description': json['description'],
        'length': json['length'],
        'width': json['width'],
        'height': json['height'],
        'weightPerHandlingUnit': json['weightPerHandlingUnit'],
        'freightClass': json['freightClass'] == null ? undefined : json['freightClass'],
        'handlingUnitType': HandlingUnitTypeFromJSON(json['handlingUnitType']),
        'temperatureHandling': TemperatureHandlingFromJSON(json['temperatureHandling']),
        'isDangerous': json['isDangerous'],
        'nmfcItemNumber': json['nmfcItemNumber'] == null ? undefined : json['nmfcItemNumber'],
        'isStackable': json['isStackable'],
        'lineItemId': json['lineItemId'],
        'dangerousUnNumber': json['dangerousUnNumber'] == null ? undefined : json['dangerousUnNumber'],
        'dangerousClassification': json['dangerousClassification'] == null ? undefined : json['dangerousClassification'],
        'dangerousPackingGroup': json['dangerousPackingGroup'] == null ? undefined : json['dangerousPackingGroup'],
        'dangerousProperShippingName': json['dangerousProperShippingName'] == null ? undefined : json['dangerousProperShippingName'],
        'dangerousTechnicalName': json['dangerousTechnicalName'] == null ? undefined : json['dangerousTechnicalName'],
        'dangerousNumberPackages': json['dangerousNumberPackages'] == null ? undefined : json['dangerousNumberPackages'],
        'dangerousPackagingType': json['dangerousPackagingType'] == null ? undefined : json['dangerousPackagingType'],
    };
}

export function LineItemToJSON(value?: LineItem | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'numberHandlingUnits': value['numberHandlingUnits'],
        'description': value['description'],
        'length': value['length'],
        'width': value['width'],
        'height': value['height'],
        'weightPerHandlingUnit': value['weightPerHandlingUnit'],
        'freightClass': value['freightClass'],
        'handlingUnitType': HandlingUnitTypeToJSON(value['handlingUnitType']),
        'temperatureHandling': TemperatureHandlingToJSON(value['temperatureHandling']),
        'isDangerous': value['isDangerous'],
        'nmfcItemNumber': value['nmfcItemNumber'],
        'isStackable': value['isStackable'],
        'lineItemId': value['lineItemId'],
        'dangerousUnNumber': value['dangerousUnNumber'],
        'dangerousClassification': value['dangerousClassification'],
        'dangerousPackingGroup': value['dangerousPackingGroup'],
        'dangerousProperShippingName': value['dangerousProperShippingName'],
        'dangerousTechnicalName': value['dangerousTechnicalName'],
        'dangerousNumberPackages': value['dangerousNumberPackages'],
        'dangerousPackagingType': value['dangerousPackagingType'],
    };
}

