/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LocationContext } from './LocationContext';
import {
    LocationContextFromJSON,
    LocationContextFromJSONTyped,
    LocationContextToJSON,
} from './LocationContext';

/**
 * 
 * @export
 * @interface SetReferenceAndNotesRequest
 */
export interface SetReferenceAndNotesRequest {
    /**
     * 
     * @type {string}
     * @memberof SetReferenceAndNotesRequest
     */
    shipmentId: string;
    /**
     * 
     * @type {LocationContext}
     * @memberof SetReferenceAndNotesRequest
     */
    locationContext: LocationContext;
    /**
     * 
     * @type {string}
     * @memberof SetReferenceAndNotesRequest
     */
    reference: string;
    /**
     * 
     * @type {string}
     * @memberof SetReferenceAndNotesRequest
     */
    notes: string;
    /**
     * 
     * @type {string}
     * @memberof SetReferenceAndNotesRequest
     */
    boothNumber: string;
}

/**
 * Check if a given object implements the SetReferenceAndNotesRequest interface.
 */
export function instanceOfSetReferenceAndNotesRequest(value: object): boolean {
    if (!('shipmentId' in value)) return false;
    if (!('locationContext' in value)) return false;
    if (!('reference' in value)) return false;
    if (!('notes' in value)) return false;
    if (!('boothNumber' in value)) return false;
    return true;
}

export function SetReferenceAndNotesRequestFromJSON(json: any): SetReferenceAndNotesRequest {
    return SetReferenceAndNotesRequestFromJSONTyped(json, false);
}

export function SetReferenceAndNotesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetReferenceAndNotesRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentId': json['shipmentId'],
        'locationContext': LocationContextFromJSON(json['locationContext']),
        'reference': json['reference'],
        'notes': json['notes'],
        'boothNumber': json['boothNumber'],
    };
}

export function SetReferenceAndNotesRequestToJSON(value?: SetReferenceAndNotesRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentId': value['shipmentId'],
        'locationContext': LocationContextToJSON(value['locationContext']),
        'reference': value['reference'],
        'notes': value['notes'],
        'boothNumber': value['boothNumber'],
    };
}

