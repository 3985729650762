import Button from 'Cargo/Controls/Button';
import Input from 'Cargo/Controls/Input';
import LinkButton from 'Cargo/Controls/LinkButton';
import PhoneInput from 'Cargo/Controls/PhoneInput';
import { NormalQuestionBubble } from 'Cargo/Controls/QuestionBubble';
import Switch from 'Cargo/Controls/Switch';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import useConfirmModal from 'Cargo/Modal/useConfirmModal';
import { useModal } from 'Cargo/Modal/useModal';
import { Label, Microcopy, ModalTitle } from 'Cargo/Text/Text';
import {
    errorMessageForContactName,
    errorMessageForPhoneNumber,
} from 'Features/Contacts/Validators/errorMessagesForContact';
import { useUsersApi } from 'apis';
import { User } from 'generated-openapi-client';
import { useState } from 'react';

interface ManageUserModalProps {
    user: User;
    onFinished: () => Promise<void>;
    onCancel: () => void;
}

function ManageUserModal(props: ManageUserModalProps) {
    const [loading, setLoading] = useState(false);

    const [userName, setUserName] = useState<string>(props.user.name);
    const [userPhone, setUserPhone] = useState<string>(props.user.phone);
    const [userPhoneExtension, setUserPhoneExtension] = useState<string>(
        props.user.phoneExtension
    );
    const [accountingNotifications, setAccountingNotifications] = useState(
        props.user.accountingNotificationsEnabled
    );
    const [allShipmentNotifications, setAllShipmentNotifications] = useState(
        props.user.allShipmentNotificationsEnabled
    );
    const [forceValidation, setForceValidation] = useState(false);

    const userNameErrorMessage = errorMessageForContactName(userName);
    const phoneNumberErrorMessage = errorMessageForPhoneNumber(userPhone);

    const userApi = useUsersApi();

    const confirm = useConfirmModal(
        `Confirm deleting user ${props.user.email}`,
        <>
            <div>
                Please confirm you want to delete this user. They will no longer
                be able to access your account.
            </div>
        </>
    );

    function isValid(): boolean {
        if (userNameErrorMessage !== undefined) {
            return false;
        }

        if (phoneNumberErrorMessage !== undefined) {
            return false;
        }

        return true;
    }

    async function onFinished() {
        setForceValidation(true);

        if (!isValid()) {
            return;
        }

        setLoading(true);

        await userApi.postUsersUpdate({
            updateUserRequest: {
                userId: props.user.userId,
                name: userName,
                phoneNumber: userPhone,
                phoneNumberExtension: userPhoneExtension,
                accountingNotificationsEnabled: accountingNotifications,
                allShipmentNotificationsEnabled: allShipmentNotifications,
            },
        });

        await props.onFinished();
        setLoading(false);
    }

    async function onDelete() {
        const shouldDelete = await confirm();

        if (shouldDelete) {
            setLoading(true);

            await userApi.postUsersDelete({
                deleteUserRequest: {
                    userId: props.user.userId,
                },
            });

            await props.onFinished();
            setLoading(false);
        }
    }

    return (
        <Stack align="center">
            <Stack align="left">
                <ModalTitle>Manage User</ModalTitle>
                <Spacer height={8} />
                <Microcopy>
                    You can update your team member's contact details and
                    notification preferences here
                </Microcopy>
                <Spacer height={24} />
                <Stack align="left">
                    <Label>Full Name</Label>
                    <Spacer height={5} />

                    <Input
                        type="text"
                        placeholder="Enter full name"
                        value={userName}
                        onChange={(value) => setUserName(value)}
                        width={429}
                        errorMessage={userNameErrorMessage}
                        forceValidation={forceValidation}
                    />

                    <Spacer width={64} />

                    <Label>Phone Number</Label>
                    <Spacer height={5} />
                    <PhoneInput
                        phoneNumber={userPhone}
                        extension={userPhoneExtension}
                        onPhoneNumberChange={(value: string) => {
                            setUserPhone(value);
                        }}
                        onExtensionChange={(value: string) => {
                            setUserPhoneExtension(value);
                        }}
                        phoneNumberErrorMessage={phoneNumberErrorMessage}
                        extensionErrorMessage={undefined}
                        forceValidation={forceValidation}
                        preferPlaceholder
                    />

                    <Label>
                        Accounting Notifications?{' '}
                        <NormalQuestionBubble
                            description="When enabled the user will receive notifications about
                            invoices and additional charges"
                        />
                    </Label>
                    <Spacer height={5} />
                    <Switch
                        on={accountingNotifications}
                        onChange={setAccountingNotifications}
                    />

                    <Label>
                        All Shipment Notifications?{' '}
                        <NormalQuestionBubble
                            description="When enabled the user will receive notifications about
                            booking and delivery of all shipments, regardless of who
                            booked"
                        />
                    </Label>

                    <Spacer height={5} />
                    <Switch
                        on={allShipmentNotifications}
                        onChange={setAllShipmentNotifications}
                    />
                </Stack>

                <Spacer height={48} />
                <HorizontalStack align="spread" width="100%">
                    <HorizontalStack align="spread">
                        <Button onClick={props.onCancel} secondary>
                            Cancel
                        </Button>
                        <Spacer width={8} />
                        <Button loading={loading} onClick={onFinished}>
                            Save Changes!
                        </Button>
                    </HorizontalStack>
                    <LinkButton red onClick={onDelete}>
                        Permanently Delete User
                    </LinkButton>
                </HorizontalStack>
            </Stack>
        </Stack>
    );
}

export function useManageUserModal(user: User) {
    const showModal = useModal<void>();

    async function show(): Promise<void> {
        return new Promise<void>((resolve) => {
            showModal(
                (done) => {
                    return (
                        <ManageUserModal
                            onCancel={() => done()}
                            onFinished={async () => {
                                console.log(
                                    `!!!! ManageUsers ManageUserModal.onFinished - calling done`
                                );
                                done();
                            }}
                            user={user}
                        />
                    );
                },
                async () => {
                    resolve();
                },
                {
                    preventBackdropClick: true,
                }
            );
        });
    }
    return show;
}
