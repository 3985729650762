import ProgressSpinner, {
    ProgressSpinnerSizes,
} from 'Cargo/Icons/ProgressSpinner';
import Stack from 'Cargo/Layout/Stack';
import React from 'react';

function LoadingShipment() {
    return (
        <Stack align="center" style={{ marginTop: '300px' }}>
            <ProgressSpinner size={ProgressSpinnerSizes.Small} />
        </Stack>
    );
}
export default LoadingShipment;
