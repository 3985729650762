import Colors from 'Cargo/Colors';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Stack from 'Cargo/Layout/Stack';
import { FlowItem } from 'Features/BookShipment/Types/FlowItem';
import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';

interface BookShipmentNavigationSectionProps {
    currentFlowIdentifier: string;
    flow: Array<FlowItem>;
    additionalContentProvider?: (
        flowIdentifier: string
    ) => ReactNode | undefined;
    completedFlowItems: Array<string>;
    unlockedFlowItems: Array<string>;

    onNavigate: (
        currentFlowItem: string,
        proposedFlowItem: string
    ) => Promise<void>;
}

interface BookShipmentNavigationSectionTextProps {
    isCurrent: boolean;
    isDone: boolean;
    isUnlocked: boolean;
}

function getColor(isCurrent: boolean, isDone: boolean, isUnlocked: boolean) {
    if (isCurrent) {
        return Colors.NormalText;
    }

    if (isDone) {
        return '#ADADAD';
    }

    if (isUnlocked) {
        return Colors.NormalText;
    }

    return Colors.LightText;
}

const BookShipmentNavigationSectionClickable = styled.div`
    cursor: pointer;
    &:hover {
        filter: brightness(1.2);
    }
`;

const BookShipmentNavigationSectionNonClickable = styled.div`
    cursor: default;
`;

const BookShipmentNavigationSectionProgressText = styled.div<BookShipmentNavigationSectionTextProps>`
    font-size: ${(props) => (props.isCurrent ? '20px' : '16px')};
    font-weight: var(--nhu-font-weight-bold);
    color: ${(props) =>
        getColor(props.isCurrent, props.isDone, props.isUnlocked)};
`;

const BookShipmentNavigationSectionAdournment = styled.div<BookShipmentNavigationSectionTextProps>`
    font-size: ${(props) => (props.isCurrent ? '24px' : '16px')};
    font-weight: var(--nhu-font-weight-bold);
    margin-left: 8px;
`;

const BookShipmentNavigationSectionExtraInfo = styled.div`
    font-size: 12px;
    font-weight: var(--nhu-font-weight-regular);
    color: '#696969';
`;

interface BookShipmentNavigationSectionIconProps {
    value: string;
    isDone: boolean;
    isCurrent: boolean;
    isUnlocked: boolean;
}

function BookShipmentNavigationSectionIcon(
    props: BookShipmentNavigationSectionIconProps
) {
    if (props.isDone) {
        return (
            <div
                style={{
                    marginRight: '16px',
                    position: 'relative',
                    top: '0',
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                >
                    <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="none"
                        strokeWidth="1"
                    >
                        <g transform="translate(-48 -284)">
                            <g transform="translate(48 284.5)">
                                <g>
                                    <ellipse
                                        cx="12"
                                        cy="12.48"
                                        fill="#4CEA63"
                                        stroke="#4CEA63"
                                        strokeWidth="3"
                                        rx="10.5"
                                        ry="10.02"
                                    ></ellipse>
                                    <path
                                        fill="#FFF"
                                        fillRule="nonzero"
                                        d="M10.566 19.231l9.2-9.2a.8.8 0 000-1.131l-1.132-1.131a.8.8 0 00-1.131 0L10 15.272l-3.503-3.503a.8.8 0 00-1.131 0L4.234 12.9a.8.8 0 000 1.131l5.2 5.2a.8.8 0 001.132 0z"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        );
    } else {
        const color = getColor(props.isCurrent, props.isDone, props.isUnlocked);

        return (
            <div
                style={{
                    marginRight: '16px',
                    position: 'relative',
                    top: '0',
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="26"
                    viewBox="0 0 24 26"
                >
                    <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="none"
                        strokeWidth="1"
                    >
                        <g transform="translate(-48 -332)">
                            <g transform="translate(48 332.5)">
                                <g>
                                    <text
                                        fill={color}
                                        fontFamily="NeueHaasUnicaW1G-Bold, Neue Haas Unica W1G"
                                        fontSize="16"
                                        fontWeight="bold"
                                    >
                                        <tspan x="7" y="19">
                                            {props.value}
                                        </tspan>
                                    </text>
                                    <ellipse
                                        cx="12"
                                        cy="13.48"
                                        stroke={color}
                                        strokeWidth="3"
                                        rx="10.5"
                                        ry="10.02"
                                    ></ellipse>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        );
    }
}

interface BookShipmentNavigationSectionRowProps {
    index: number;
    flowItem: FlowItem;
    additionalContent: ReactNode | undefined;
    isCurrent: boolean;
    isDone: boolean;
    isUnlocked: boolean;

    // preNavigationCheck: () => boolean;
    // onInvalidate: () => Promise<void>;
    // baseUrlComponent: string;
    // urlQuery: Map<string, string> | undefined;

    currentFlowIdentifier: string;
    onNavigate: (
        currentFlowItem: string,
        proposedFlowItem: string
    ) => Promise<void>;
}

function BookShipmentNavigationSectionRow(
    props: BookShipmentNavigationSectionRowProps
) {
    const isNavigable = props.isUnlocked;

    async function navigate() {
        if (!isNavigable) {
            return;
        }

        props.onNavigate(
            props.currentFlowIdentifier,
            props.flowItem.identifier
        );
    }

    const WrapperType = isNavigable
        ? BookShipmentNavigationSectionClickable
        : BookShipmentNavigationSectionNonClickable;

    return (
        <WrapperType onClick={navigate}>
            <HorizontalStack
                style={{ marginBottom: '24px' }}
                verticalAlign="top"
            >
                <BookShipmentNavigationSectionIcon
                    isDone={props.isDone}
                    isCurrent={props.isCurrent}
                    isUnlocked={props.isUnlocked}
                    value={props.index.toString()}
                />
                <BookShipmentNavigationSectionProgressText
                    isCurrent={props.isCurrent}
                    isDone={props.isDone}
                    isUnlocked={props.isUnlocked}
                    // This is used by the E2E tests to identify which stage is locked
                    className={props.isUnlocked ? 'unlocked' : 'locked'}
                >
                    <Stack align="left">
                        {props.flowItem.description}
                        <BookShipmentNavigationSectionExtraInfo>
                            {props.additionalContent}
                        </BookShipmentNavigationSectionExtraInfo>
                    </Stack>
                </BookShipmentNavigationSectionProgressText>
                {props.flowItem.adournment && (
                    <BookShipmentNavigationSectionAdournment
                        isCurrent={props.isCurrent}
                        isDone={props.isDone}
                        isUnlocked={props.isUnlocked}
                    >
                        {props.flowItem.adournment}
                    </BookShipmentNavigationSectionAdournment>
                )}
            </HorizontalStack>
        </WrapperType>
    );
}

function BookShipmentNavigationSection(
    props: BookShipmentNavigationSectionProps
) {
    return (
        <Stack align="left">
            {props.flow.map((flowItem, index) => {
                return (
                    <BookShipmentNavigationSectionRow
                        key={flowItem.identifier}
                        index={index + 1}
                        flowItem={flowItem}
                        additionalContent={props.additionalContentProvider?.(
                            flowItem.identifier
                        )}
                        isCurrent={
                            flowItem.identifier === props.currentFlowIdentifier
                        }
                        isDone={props.completedFlowItems.includes(
                            flowItem.identifier
                        )}
                        isUnlocked={props.unlockedFlowItems.includes(
                            flowItem.identifier
                        )}
                        onNavigate={props.onNavigate}
                        currentFlowIdentifier={props.currentFlowIdentifier}
                    />
                );
            })}
        </Stack>
    );
}
export default BookShipmentNavigationSection;
