/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddSavedBrokerRequest,
  DeleteSavedBrokerRequest,
  GetAllSavedBrokersResponse,
  ModifySavedBrokerRequest,
  SetPreferredBrokerRequest,
} from '../models/index';
import {
    AddSavedBrokerRequestFromJSON,
    AddSavedBrokerRequestToJSON,
    DeleteSavedBrokerRequestFromJSON,
    DeleteSavedBrokerRequestToJSON,
    GetAllSavedBrokersResponseFromJSON,
    GetAllSavedBrokersResponseToJSON,
    ModifySavedBrokerRequestFromJSON,
    ModifySavedBrokerRequestToJSON,
    SetPreferredBrokerRequestFromJSON,
    SetPreferredBrokerRequestToJSON,
} from '../models/index';

export interface PostSaveBrokersSetPreferredRequest {
    setPreferredBrokerRequest?: SetPreferredBrokerRequest;
}

export interface PostSavedBrokersAddRequest {
    addSavedBrokerRequest?: AddSavedBrokerRequest;
}

export interface PostSavedBrokersDeleteRequest {
    deleteSavedBrokerRequest?: DeleteSavedBrokerRequest;
}

export interface PostSavedBrokersModifyRequest {
    modifySavedBrokerRequest?: ModifySavedBrokerRequest;
}

/**
 * 
 */
export class SavedBrokersApi extends runtime.BaseAPI {

    /**
     * Your GET endpoint
     */
    async getAllSavedBrokersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAllSavedBrokersResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/saved-brokers/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAllSavedBrokersResponseFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getAllSavedBrokers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAllSavedBrokersResponse> {
        const response = await this.getAllSavedBrokersRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async postSaveBrokersSetPreferredRaw(requestParameters: PostSaveBrokersSetPreferredRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/save-brokers/set-preferred`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetPreferredBrokerRequestToJSON(requestParameters['setPreferredBrokerRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postSaveBrokersSetPreferred(requestParameters: PostSaveBrokersSetPreferredRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSaveBrokersSetPreferredRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postSavedBrokersAddRaw(requestParameters: PostSavedBrokersAddRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/saved-brokers/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddSavedBrokerRequestToJSON(requestParameters['addSavedBrokerRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postSavedBrokersAdd(requestParameters: PostSavedBrokersAddRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSavedBrokersAddRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postSavedBrokersDeleteRaw(requestParameters: PostSavedBrokersDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/saved-brokers/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteSavedBrokerRequestToJSON(requestParameters['deleteSavedBrokerRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postSavedBrokersDelete(requestParameters: PostSavedBrokersDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSavedBrokersDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * savedBrokers
     */
    async postSavedBrokersModifyRaw(requestParameters: PostSavedBrokersModifyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/saved-brokers/modify`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModifySavedBrokerRequestToJSON(requestParameters['modifySavedBrokerRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * savedBrokers
     */
    async postSavedBrokersModify(requestParameters: PostSavedBrokersModifyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSavedBrokersModifyRaw(requestParameters, initOverrides);
    }

}
