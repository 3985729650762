import Animation from 'Cargo/Animations/Animation';
import Button from 'Cargo/Controls/Button';
import { useDynamicHeight } from 'Cargo/Layout/DynamicSpacer';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import PageTitle from 'Cargo/Text/PageTitle';
import { Description, H2, Microcopy, Strong } from 'Cargo/Text/Text';
import { displayNameForCarrier } from 'Data/Carriers';
import { useOnce } from 'Hooks/useOnce';
import useQuery from 'Hooks/useQuery';
import { useShipmentService } from 'Services/ShipmentService';
import { Shipment } from 'generated-openapi-client';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingShipment from './Components/LoadingShipment';

function ShipmentBookedScreen() {
    const query = useQuery();
    const navigate = useNavigate();

    const shipmentsService = useShipmentService();

    const shipmentId = query.shipmentId as string;

    const [shipment, setShipment] = useState<Shipment | undefined>();

    async function loadShipment() {
        try {
            const response = await shipmentsService.getShipment(shipmentId);
            console.log({ response });

            setShipment(response);
        } catch (e) {
            navigate('/');
        }
    }

    const animationHeight = useDynamicHeight(300, 350);

    useOnce(async () => {
        loadShipment();
    });

    if (shipment === undefined) {
        return <LoadingShipment />;
    }

    const { selectedQuote, pickupDate } = shipment;

    if (selectedQuote === undefined) {
        // Should never get here
        throw new Error('Missing selectedQuote');
    }

    const { carrierIdentifier } = selectedQuote;

    const carrierDisplayName = displayNameForCarrier(carrierIdentifier);

    function onClick() {
        navigate('/view-shipment?shipmentId=' + shipmentId);
    }

    return (
        <>
            <PageTitle>Shipment Booked</PageTitle>
            <Spacer height={48} />

            <Stack align="center">
                <H2>Congratulations! Your Shipment is Booked!</H2>
                <Spacer height={8} />
                <Description>
                    {carrierDisplayName} will pick up your freight on{' '}
                    <Strong>
                        {moment(pickupDate).format('dddd Do MMMM YYYY')}
                    </Strong>
                    .
                </Description>
                <Spacer height={16} />

                <Animation
                    pathToAnimation="/animations/success.json"
                    lottieFilesUrl="https://edit.lottiefiles.com/?src=https%3A%2F%2Fassets7.lottiefiles.com%2Fprivate_files%2Flf30_P2wyYh.json"
                    loop={true}
                    style={{ height: animationHeight }}
                />

                <Spacer height={16} />
                <Microcopy>
                    Before the driver arrives, you must print your pickup
                    package. We&apos;ll explain that next.
                </Microcopy>
                <Spacer height={16} />
                <Button
                    onClick={onClick}
                    label="View Shipment"
                    size="large"
                    arrow="right"
                />
                <Spacer height={8} />
            </Stack>
        </>
    );
}

export default ShipmentBookedScreen;
