/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Currency = {
    Usd: 'USD',
    Cad: 'CAD'
} as const;
export type Currency = typeof Currency[keyof typeof Currency];


export function instanceOfCurrency(value: any): boolean {
    return Object.values(Currency).includes(value);
}

export function CurrencyFromJSON(json: any): Currency {
    return CurrencyFromJSONTyped(json, false);
}

export function CurrencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Currency {
    return json as Currency;
}

export function CurrencyToJSON(value?: Currency | null): any {
    return value as any;
}

