/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';

/**
 * 
 * @export
 * @interface ModifySavedLocationRequest
 */
export interface ModifySavedLocationRequest {
    /**
     * 
     * @type {string}
     * @memberof ModifySavedLocationRequest
     */
    savedLocationId: string;
    /**
     * 
     * @type {Location}
     * @memberof ModifySavedLocationRequest
     */
    location: Location;
}

/**
 * Check if a given object implements the ModifySavedLocationRequest interface.
 */
export function instanceOfModifySavedLocationRequest(value: object): boolean {
    if (!('savedLocationId' in value)) return false;
    if (!('location' in value)) return false;
    return true;
}

export function ModifySavedLocationRequestFromJSON(json: any): ModifySavedLocationRequest {
    return ModifySavedLocationRequestFromJSONTyped(json, false);
}

export function ModifySavedLocationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModifySavedLocationRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'savedLocationId': json['savedLocationId'],
        'location': LocationFromJSON(json['location']),
    };
}

export function ModifySavedLocationRequestToJSON(value?: ModifySavedLocationRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'savedLocationId': value['savedLocationId'],
        'location': LocationToJSON(value['location']),
    };
}

