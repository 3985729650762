import { LatitudeLongitude } from 'generated-openapi-client';

function toRadians(degrees: number): number {
    return (Math.PI / 180) * degrees;
}

function toDegrees(radians: number): number {
    return (radians * 180) / Math.PI;
}

/**
 *
 * @param p1 Coordinates of start of arc
 * @param p2 Coordinates of end of arc
 * @param f Ratio along the arc (between 0..1)
 */
export function calculatePointOnArc(
    p1: Required<LatitudeLongitude>,
    p2: Required<LatitudeLongitude>,
    f: number
): Required<LatitudeLongitude> {
    const lat1 = toRadians(p1.latitude);
    const lon1 = toRadians(p1.longitude);
    const lat2 = toRadians(p2.latitude);
    const lon2 = toRadians(p2.longitude);

    const acos = Math.acos;
    const sin = Math.sin;
    const cos = Math.cos;
    const atan2 = Math.atan2;
    const sqrt = Math.sqrt;

    const d = acos(
        sin(lat1) * sin(lat2) + cos(lat1) * cos(lat2) * cos(lon1 - lon2)
    );

    // f=0 > A=1, B=0
    // x = cos(lat1)*cos(lon1)
    // y = cos(lat1)*sun(lon1)
    //

    // https://edwilliams.org/avform.htm#Intermediate
    const A = sin((1 - f) * d) / sin(d);
    const B = sin(f * d) / sin(d);
    const x = A * cos(lat1) * cos(lon1) + B * cos(lat2) * cos(lon2);
    const y = A * cos(lat1) * sin(lon1) + B * cos(lat2) * sin(lon2);
    const z = A * sin(lat1) + B * sin(lat2);
    const latitude = atan2(z, sqrt(x * x + y * y));
    const longitude = atan2(y, x);

    return {
        latitude: toDegrees(latitude),
        longitude: toDegrees(longitude),
    };
}

export function calculatePointsOnArc(
    p1: Required<LatitudeLongitude>,
    p2: Required<LatitudeLongitude>,
    numberOfSegments: number
): Array<Required<LatitudeLongitude>> {
    // We take 0..1 and divide up into numberOfSegments

    const increment = 1.0 / numberOfSegments;

    const points = [];

    for (let i = 0; i < numberOfSegments + 1; i++) {
        const f = increment * i;

        const p = calculatePointOnArc(p1, p2, f);
        points.push(p);
    }

    return points;
}
