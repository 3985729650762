import Button from 'Cargo/Controls/Button';
import LinkButton from 'Cargo/Controls/LinkButton';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import { useModal } from 'Cargo/Modal/useModal';
import { Microcopy, ModalTitle } from 'Cargo/Text/Text';
import { UUID } from 'Cargo/Types/types';
import BrokerBox from 'Features/Brokers/Components/BrokerBox';
import SavedBrokersDropdown from 'Features/Brokers/Components/SavedBrokersDropdown';
import useAddBrokerModal from 'Features/Brokers/Hooks/useAddBrokerModal';
import useModifyBrokerModal from 'Features/Brokers/Hooks/useModifyBrokerModal';
import { Broker, SavedBroker } from 'generated-openapi-client';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface SwitchBrokerModalProps {
    savedBrokerId: UUID;
    savedBrokers: Array<SavedBroker>;
    onDone: () => void;
    onBrokerChanged: (updatedBroker: Broker) => void;
}

function SwitchBrokerModal(props: SwitchBrokerModalProps) {
    const showModifyBrokerModal = useModifyBrokerModal();
    const [selectedSavedBrokerId, setSelectedSavedBrokerId] = useState(
        props.savedBrokerId
    );

    const showAddBrokerModal = useAddBrokerModal();

    async function onAddBroker() {
        const data = await showAddBrokerModal();
        console.log(`showAddBrokerModal`);
        props.onBrokerChanged(data.broker.broker);
        props.onDone();
    }

    const selectedSavedBroker = props.savedBrokers.find(function (sb) {
        return sb.savedBrokerId === selectedSavedBrokerId;
    });

    if (selectedSavedBroker === undefined) {
        throw new Error('Missing selectedSavedBroker - this should not happen');
    }

    function onSwitch(newSavedBrokerId: UUID) {
        console.log('onSwitch');
        setSelectedSavedBrokerId(newSavedBrokerId);
        const newSelectedBroker = props.savedBrokers.find(function (sb) {
            return sb.savedBrokerId === newSavedBrokerId;
        })?.broker;

        if (newSelectedBroker === undefined) {
            throw new Error('Missing saved broker');
        }
        props.onBrokerChanged(newSelectedBroker);
    }

    return (
        <div style={{ width: '640px' }}>
            <ModalTitle>Select Customs Broker</ModalTitle>
            <Microcopy>
                Select from one of your saved customs broker for this shipment
            </Microcopy>
            <Spacer height={8} />
            <SavedBrokersDropdown
                savedBrokerId={selectedSavedBrokerId}
                savedBrokers={props.savedBrokers}
                onSetSavedBrokerId={onSwitch}
            />
            <Spacer height={24} />
            <BrokerBox
                key={selectedSavedBrokerId}
                broker={selectedSavedBroker.broker}
                onModify={async function () {
                    const data = await showModifyBrokerModal(
                        selectedSavedBroker
                    );
                    if (data !== undefined) {
                        props.onBrokerChanged(data.broker);
                    }
                    props.onDone();
                }}
                showMenu={false}
                onDelete={undefined}
                preferredNorthbound={false}
                preferredSouthbound={false}
                onSelectPreferred={undefined}
            />
            <Spacer height={24} />
            <LinkButton onClick={onAddBroker}>
                Add new customs broker
            </LinkButton>
            <Spacer height={24} />
            <HorizontalStack width="100%" align="spread">
                <Button
                    onClick={function () {
                        props.onDone();
                    }}
                >
                    Done
                </Button>
            </HorizontalStack>
        </div>
    );
}

function useSwitchBrokerModal() {
    const showModal = useModal<SavedBroker>();

    async function show(
        selectedSavedBrokerId: UUID,
        savedBrokers: Array<SavedBroker>,
        onBrokerChanged: (updatedBroker: Broker) => void
    ) {
        return new Promise<Broker | undefined>((resolve) => {
            showModal(
                (done) => {
                    return (
                        <SwitchBrokerModal
                            savedBrokerId={selectedSavedBrokerId}
                            savedBrokers={savedBrokers}
                            onDone={done}
                            onBrokerChanged={onBrokerChanged}
                        ></SwitchBrokerModal>
                    );
                },
                (data) => {
                    resolve(data?.broker);
                }
            );
        });
    }

    return show;
}

function useChangeBrokerModal() {
    const showSwitchBrokerModal = useSwitchBrokerModal();
    const showModifyBrokerModal = useModifyBrokerModal();

    async function show(
        selectedSavedBrokerId: string | undefined,
        currentBroker: Broker,
        savedBrokers: Array<SavedBroker>,
        onBrokerChanged: (updatedBroker: Broker) => void
    ) {
        // There's basically two situations we might be in
        // 1) There's only one saved broker or the saved broker info on this shipment doesn't match
        //    any of the saved brokers. In this case we just want to quickly allow them to modify
        //    the broker info
        //
        // 2) There's multiple saved brokers. In this case we need to show a more sophisticated screen
        //    that allows them to select from any of the saved broker, and optionally modify one of them or
        //    add a new one
        if (savedBrokers.length <= 1 || selectedSavedBrokerId === undefined) {
            const savedBroker = savedBrokers.find(function (sb) {
                return sb.savedBrokerId === selectedSavedBrokerId;
            }) ?? { savedBrokerId: uuidv4(), broker: currentBroker };
            const data = await showModifyBrokerModal(savedBroker);
            const broker = data?.broker;
            if (broker !== undefined) {
                onBrokerChanged(broker);
            }
        } else {
            const broker = await showSwitchBrokerModal(
                selectedSavedBrokerId,
                savedBrokers,
                onBrokerChanged
            );

            if (broker !== undefined) {
                onBrokerChanged(broker);
            }
        }
    }

    return show;
}

export default useChangeBrokerModal;
