// We're going to store all the serializable modals data in Redux, and store non-serializable in a global store

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Modal from 'Cargo/Modal/Modal';
import { ModalInstance } from 'Cargo/Modal/useModalsProvider';
import { UUID } from 'Cargo/Types/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

export interface ModalsSliceType {
    modalIds: Array<UUID>;
    closingSet: Array<UUID>;
    showLoading: boolean;
}

function emptyModalsSlice(): ModalsSliceType {
    return {
        modalIds: [],
        closingSet: [],
        showLoading: false,
    };
}

export const modalsSlice = createSlice({
    name: 'modals',
    initialState: emptyModalsSlice(),
    reducers: {
        addModalAction(state, action: PayloadAction<UUID>) {
            state.modalIds.push(action.payload);
        },

        setLoadingAction(state, action: PayloadAction<boolean>) {
            state.showLoading = action.payload;
        },

        startClosingModalByIdAction(state, action: PayloadAction<UUID>) {
            state.closingSet.push(action.payload);
        },

        removeModalAction(state, action: PayloadAction<UUID>) {
            state.closingSet = state.closingSet.filter(
                (m) => m !== action.payload
            );
            state.modalIds = state.modalIds.filter((m) => m !== action.payload);
        },
    },
});

const {
    addModalAction,
    startClosingModalByIdAction,
    removeModalAction,
    setLoadingAction,
} = modalsSlice.actions;

const globalModalStore = new Map<UUID, ModalInstance>();

export function useModalsSlice() {
    const dispatch = useDispatch();
    const { modalIds, closingSet, showLoading } = useSelector(
        (state: RootState) => state.modals
    );

    function addModal(modalInstance: ModalInstance) {
        globalModalStore.set(modalInstance.id, modalInstance);
        dispatch(addModalAction(modalInstance.id));
    }

    function startClosingModalById(id: UUID) {
        dispatch(startClosingModalByIdAction(id));
    }

    function setLoading(isLoading: boolean) {
        dispatch(setLoadingAction(isLoading));
    }

    function removeModal(id: UUID) {
        document.body.style.overflow = 'unset';
        dispatch(removeModalAction(id));
        globalModalStore.delete(id);
    }

    function renderModals() {
        return modalIds.map((id, index) => {
            const modal = globalModalStore.get(id);

            if (modal === undefined) {
                return <></>;
            }

            document.body.style.overflow = 'hidden';

            return (
                <Modal
                    showLoading={showLoading}
                    id={modal.id}
                    index={index}
                    onBackdropClick={() => modal.onBackdropClick()}
                    key={modal.id}
                    closing={closingSet.includes(modal.id)}
                    padding={modal.padding}
                    simpleModal={modal.simpleModal}
                >
                    {modal.contents}
                </Modal>
            );
        });
    }

    return {
        addModal,
        startClosingModalById,
        removeModal,
        renderModals,
        setLoading,
        showLoading,
    };
}
