/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SavedCommodity,
} from '../models/index';
import {
    SavedCommodityFromJSON,
    SavedCommodityToJSON,
} from '../models/index';

export interface PostSavedCommoditiesAddRequest {
    savedCommodity?: SavedCommodity;
}

export interface PostSavedCommoditiesDeleteRequest {
    savedCommodityId: string;
}

export interface PostSavedCommoditiesModifyRequest {
    savedCommodity?: SavedCommodity;
}

/**
 * 
 */
export class SavedCommoditiesApi extends runtime.BaseAPI {

    /**
     * Your GET endpoint
     */
    async getSavedCommoditiesAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SavedCommodity>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/saved-commodities/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SavedCommodityFromJSON));
    }

    /**
     * Your GET endpoint
     */
    async getSavedCommoditiesAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SavedCommodity>> {
        const response = await this.getSavedCommoditiesAllRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async postSavedCommoditiesAddRaw(requestParameters: PostSavedCommoditiesAddRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/saved-commodities/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SavedCommodityToJSON(requestParameters['savedCommodity']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postSavedCommoditiesAdd(requestParameters: PostSavedCommoditiesAddRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSavedCommoditiesAddRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postSavedCommoditiesDeleteRaw(requestParameters: PostSavedCommoditiesDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['savedCommodityId'] == null) {
            throw new runtime.RequiredError(
                'savedCommodityId',
                'Required parameter "savedCommodityId" was null or undefined when calling postSavedCommoditiesDelete().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['savedCommodityId'] != null) {
            queryParameters['savedCommodityId'] = requestParameters['savedCommodityId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/saved-commodities/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postSavedCommoditiesDelete(requestParameters: PostSavedCommoditiesDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSavedCommoditiesDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postSavedCommoditiesModifyRaw(requestParameters: PostSavedCommoditiesModifyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/saved-commodities/modify`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SavedCommodityToJSON(requestParameters['savedCommodity']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postSavedCommoditiesModify(requestParameters: PostSavedCommoditiesModifyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSavedCommoditiesModifyRaw(requestParameters, initOverrides);
    }

}
