import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faCalendar,
    faCheckCircle,
    faCheckSquare,
    faCircle,
    faClock,
    faCopy,
    faCreditCard,
    faEye,
    faEyeSlash,
    faMap,
    faPlusSquare,
} from '@fortawesome/free-regular-svg-icons';

library.add(
    faCheckSquare,
    faCheckCircle,
    faMap,
    faCalendar,
    faPlusSquare,
    faCircle,
    faClock,
    faCreditCard,
    faCopy,
    faEye,
    faEyeSlash
);
