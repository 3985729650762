import { useModal } from 'Cargo/Modal/useModal';
import AddLocationModal, {
    AddLocationModalCallbackData,
    AddLocationModalSavingBehaviour,
} from 'Features/Locations/Modals/AddLocationModal';
import { LocationContext } from 'generated-openapi-client';
import React from 'react';

function useAddLocationModal(
    context: LocationContext,
    savingBehaviour: AddLocationModalSavingBehaviour,
    callback: (data: AddLocationModalCallbackData) => void
): () => void {
    const showModal = useModal<AddLocationModalCallbackData>();

    function show() {
        showModal(
            (done) => {
                return (
                    <AddLocationModal
                        context={context}
                        onCancel={() => done()}
                        savingBehaviour={savingBehaviour}
                        onFinished={(params) => {
                            done(params);
                        }}
                    />
                );
            },
            (data) => {
                if (data !== undefined) {
                    callback(data);
                }
            },
            {
                preventBackdropClick: true,
            }
        );
    }
    return show;
}

export default useAddLocationModal;
