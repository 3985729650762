import {
    QuestionBubbleHeader,
    QuestionBubbleLabel,
    SmallQuestionBubble,
} from 'Cargo/Controls/QuestionBubble';
import { LocationContext } from 'generated-openapi-client';
import React from 'react';
import {
    AccessorialQuestionBubble,
    AccessorialQuestionBubbleProps,
} from './AccessorialQuestionBubbleProps';
import { describeLocationContext } from 'Helpers/describeLocationContext';

const LiftGateRequiredQuestionBubble: AccessorialQuestionBubble = (
    props: AccessorialQuestionBubbleProps
) => {
    let description =
        props.locationContext == LocationContext.Pickup
            ? `Check this box if you are unable to load the freight onto the truck at your pickup location.`
            : `Check this box if you are unable to unload the freight off the truck at your delivery location.`;

    description += ` For example, if you don't have a dock, loading bay, or a forklift.`;

    return (
        <SmallQuestionBubble
            description={
                <>
                    <QuestionBubbleHeader>
                        {`Lift gate required at ${describeLocationContext(props.locationContext)}`}
                    </QuestionBubbleHeader>
                    <QuestionBubbleLabel>{description}</QuestionBubbleLabel>
                </>
            }
            learnMoreUrl="https://help.freightsimple.com/using-freightsimple/quoting/pickup-and-delivery-details#lift-gate-required"
        />
    );
};
export default LiftGateRequiredQuestionBubble;
