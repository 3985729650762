import { useAuthentication } from 'Features/Authentication/Slices/authenticationSlice';
import React, { useEffect } from 'react';
import { clearSlices } from 'store';

function ResetScreen() {
    const { signOut } = useAuthentication();
    useEffect(function () {
        clearSlices();
        signOut();
    }, []);

    return <></>;
}

export default ResetScreen;
