import { LocationContext } from 'generated-openapi-client';

export function describeLocationContext(
    locationContext: LocationContext
) {
    switch (locationContext) {
        case LocationContext.Pickup: return 'Pickup';
        case LocationContext.Delivery: return 'Delivery';
        case LocationContext.Any: return 'Location';
    }
}
