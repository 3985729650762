import Dropdown from 'Cargo/Controls/Dropdown';
import { UUID } from 'Cargo/Types/types';
import { SavedContact } from 'generated-openapi-client';
import React from 'react';

interface SavedContactsDropdownProps {
    savedContactId: UUID;
    onSetSavedContactId: (_: string) => void;
    savedContacts: Array<SavedContact>;
    width?: number;
}

const SavedContactsDropdown: React.FC<SavedContactsDropdownProps> = (
    props: SavedContactsDropdownProps
) => {
    function dropdownContactOptions(contacts: Array<SavedContact>) {
        if (!contacts) {
            return [];
        }

        return contacts.map((i) => ({
            item: i.savedContactId,
            description: i.contact.contactName,
        }));
    }

    return (
        <>
            <Dropdown
                selectedItem={props.savedContactId}
                onOptionSelected={props.onSetSavedContactId}
                options={dropdownContactOptions(props.savedContacts)}
                width={520}
                unselectedDescription="Select a contact"
                placeholder="Select one of your saved contacts, or enter new details"
            />
        </>
    );
};
export default SavedContactsDropdown;
