import Colors from 'Cargo/Colors';
import BlueInfoAnimatedIcon from 'Cargo/Icons/BlueInfoAnimatedIcon';
import RedExclamationAnimatedIcon from 'Cargo/Icons/RedExclamationAnimatedIcon';
import YellowExclamationAnimatedIcon from 'Cargo/Icons/YellowExclamationAnimatedIcon';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { ReactNode } from 'react';
import styled from 'styled-components/macro';

interface BannerWrapperProps {
    bannerStyle: BannerStyle;
}

function bannerBackgroundColor(bannerStyle: BannerStyle) {
    switch (bannerStyle) {
        case BannerStyle.Success:
            return Colors.Green;
        case BannerStyle.Neutral:
            return Colors.VeryLightGray;
        case BannerStyle.Info:
            return Colors.VeryVeryLightBlue;
        case BannerStyle.Warning:
            return Colors.LightGold;
        case BannerStyle.Error:
            return Colors.LightRed;
    }
}

function bannerBorderColor(bannerStyle: BannerStyle) {
    switch (bannerStyle) {
        case BannerStyle.Success:
            return Colors.Green;
        case BannerStyle.Neutral:
            return Colors.LightGray;
        case BannerStyle.Info:
            return Colors.VeryLightBlue;
        case BannerStyle.Warning:
            return Colors.Gold;
        case BannerStyle.Error:
            return Colors.Red;
    }
}

const BannerWrapper = styled.div<BannerWrapperProps>`
    width: 100%;
    background-color: ${(props) => bannerBackgroundColor(props.bannerStyle)};
    color: var(--freightsimple-color-normal-text);
    font-weight: var(--nhu-font-weight-regular);
    padding: 8px 16px;
    border: 1px solid ${(props) => bannerBorderColor(props.bannerStyle)};
    border-radius: 10px;
`;

export enum BannerStyle {
    Success,
    Neutral,
    Info,
    Warning,
    Error,
}

interface BannerProps {
    title?: ReactNode;
    message: ReactNode;
    bannerStyle: BannerStyle;
    right?: ReactNode;
    hideIcon?: boolean;
}

// TODO: Move, not used in this file
export const BannerHeader = styled.span`
    font-weight: var(--nhu-font-weight-medium);
    margin-right: 8px;
`;

// TODO: Move, not used in this file
export const BannerBody = styled.span`
    font-size: 14px;
`;

const BannerTitle = styled.div`
    font-weight: var(--nhu-font-weight-medium);
    margin-left: 8px;
`;
const BannerMessage = styled.div`
    font-size: 14px;
    // Colors.LightText is too light against a yellow background
    color: #707070;
    margin-top: -4px;
    margin-left: 8px;
`;

const Banner: React.FC<BannerProps> = (props: BannerProps) => {
    return (
        <BannerWrapper bannerStyle={props.bannerStyle}>
            <HorizontalStack align="spread">
                <HorizontalStack align="left" verticalAlign="middle">
                    {!props.hideIcon && (
                        <>
                            <div style={{ marginTop: '3px' }}>
                                {props.bannerStyle === BannerStyle.Info && (
                                    <BlueInfoAnimatedIcon />
                                )}
                                {props.bannerStyle === BannerStyle.Warning && (
                                    <YellowExclamationAnimatedIcon />
                                )}
                                {props.bannerStyle === BannerStyle.Error && (
                                    <RedExclamationAnimatedIcon />
                                )}
                            </div>

                            <Spacer width={8} />
                        </>
                    )}
                    {props.title && (
                        <Stack align="left">
                            <BannerTitle>{props.title}</BannerTitle>
                            <BannerMessage>{props.message}</BannerMessage>
                        </Stack>
                    )}
                    {!props.title && <span>{props.message}</span>}
                </HorizontalStack>
                {props.right}
            </HorizontalStack>
        </BannerWrapper>
    );
};
export default Banner;
