import Colors from 'Cargo/Colors';
import { AddLocationLocationType } from 'Features/Locations/Types/locationTypes';
import { LatitudeLongitude, ShipmentState } from 'generated-openapi-client';
import { calculatePointsOnArc } from 'Helpers/calculatePointsOnArc';
import mapboxgl from 'mapbox-gl';
import { latitudeLongitudeToNumbers } from './latitudeLongitudeToNumbers';

function pathColorForShipmentState(state: ShipmentState): string {
    switch (state) {
        case ShipmentState.Quoted:
            return Colors.Blue;
        case ShipmentState.QuoteRequested:
            return Colors.Blue;
        case ShipmentState.BookingConfirmed:
            return Colors.MidGray;
        case ShipmentState.BookingRequested:
            return Colors.MidGray;
        case ShipmentState.BookingFailed:
            return Colors.MidGray;
        case ShipmentState.OnHold:
            return Colors.MidGray;
        case ShipmentState.InTransit:
            return Colors.Blue;
        case ShipmentState.Delivered:
            return Colors.DarkGreen;
        case ShipmentState.Cancelled:
            return 'black';
        case ShipmentState.Lost:
            return 'black';
    }
}

function addArc(
    map: mapboxgl.Map,
    pickup: Partial<AddLocationLocationType> | undefined,
    delivery: Partial<AddLocationLocationType> | undefined,
    shipmentState: ShipmentState
) {
    if (
        pickup === undefined ||
        pickup.latitudeLongitude === undefined ||
        pickup.latitudeLongitude.latitude === undefined ||
        pickup.latitudeLongitude.longitude === undefined
    ) {
        return;
    }

    if (
        delivery === undefined ||
        delivery.latitudeLongitude === undefined ||
        delivery.latitudeLongitude.latitude === undefined ||
        delivery.latitudeLongitude.longitude === undefined
    ) {
        return;
    }

    const pathColor = pathColorForShipmentState(shipmentState);
    const points = calculatePointsOnArc(
        pickup.latitudeLongitude as Required<LatitudeLongitude>,
        delivery.latitudeLongitude as Required<LatitudeLongitude>,
        10
    ).map((c) => latitudeLongitudeToNumbers(c));

    for (let i = 0; i < points.length - 1; i++) {
        map.addSource('routeSource' + i, {
            type: 'geojson',
            data: {
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'LineString',
                    coordinates: [points[i], points[i + 1]],
                },
            },
        });

        map.addLayer({
            id: 'route' + i,
            source: 'routeSource' + i,
            type: 'line',
            paint: {
                'line-width': 2,
                'line-color': pathColor,
            },
        });
    }
}

function removeArc(map: mapboxgl.Map) {
    for (let i = 0; i < 10; i++) {
        if (map.getLayer('route' + i)) {
            map.removeLayer('route' + i);
        }

        if (map.getSource('routeSource' + i)) {
            map.removeSource('routeSource' + i);
        }
    }
}

export function setupArc(
    map: mapboxgl.Map,
    pickup: Partial<AddLocationLocationType> | undefined,
    delivery: Partial<AddLocationLocationType> | undefined,
    shipmentState: ShipmentState
) {
    if (pickup === undefined || delivery === undefined) {
        removeArc(map);
    } else {
        // We might be moving the arc
        removeArc(map);
        addArc(map, pickup, delivery, shipmentState);
    }
}
