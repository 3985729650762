import { useSavedLocationsApi, useUsersApi } from 'apis';
import Button from 'Cargo/Controls/Button';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { useModal } from 'Cargo/Modal/useModal';
import { H2, Microcopy } from 'Cargo/Text/Text';
import LoadingShipment from 'Features/BookShipment/Components/LoadingShipment';
import BranchesDropdown from 'Features/Branches/Components/BranchesDropdown';
import { User } from 'generated-openapi-client';
import { SavedLocation } from 'generated-openapi-client/models/SavedLocation';
import { useOnce } from 'Hooks/useOnce';
import { useState } from 'react';

interface ChangeBranchModalProps {
    user: User;
    onDone: () => void;
    onCancel: () => void;
}

function ChangeBranchModal(props: ChangeBranchModalProps) {
    const usersApi = useUsersApi();
    const savedLocationsApi = useSavedLocationsApi();

    const [changing, setChanging] = useState(false);
    const [branchId, setBranchId] = useState(props.user.branchId);
    const [dirty, setDirty] = useState(false);
    const [branches, setBranches] = useState<undefined | SavedLocation[]>(
        undefined
    );

    async function loadBranches() {
        const response = await savedLocationsApi.getBranches();
        setBranches(response.items);
    }

    useOnce(function () {
        loadBranches();
    });

    async function onChange() {
        try {
            setChanging(true);
            // Magic string
            if (branchId === undefined || branchId === 'no_branch_assigned') {
                await usersApi.postUsersClearBranch({
                    clearBranchRequest: {
                        userId: props.user.userId,
                    },
                });
            } else {
                await usersApi.postUsersSetBranch({
                    setBranchRequest: {
                        userId: props.user.userId,
                        locationId: branchId,
                    },
                });
            }

            props.onDone();
        } catch (e) {
            console.error('Something went wrong requesting the invitation', {
                e,
            });
        }
        setChanging(false);
    }

    if (branches === undefined) {
        return <LoadingShipment />;
    }

    return (
        <>
            <Stack align="center" width="500px" style={{ padding: '16px' }}>
                <H2>Assign to branch</H2>
                <div style={{ width: '450px' }}>
                    <Microcopy style={{ textAlign: 'center' }}>
                        Which branch would you like to assign this user to?
                    </Microcopy>
                </div>
                <Spacer height={16} />
                <Stack align="left">
                    <BranchesDropdown
                        branchId={branchId}
                        setBranchId={function (_branchId) {
                            if (_branchId !== branchId) {
                                setDirty(true);
                                setBranchId(_branchId);
                            }
                        }}
                        branches={branches}
                    />
                </Stack>
                <Spacer height={40} />
                <HorizontalStack>
                    <Button size="large" onClick={props.onCancel} secondary>
                        Cancel
                    </Button>
                    <Spacer width={16} />
                    <Button
                        id="branchesAssignButton"
                        disabled={!dirty}
                        size="large"
                        onClick={onChange}
                        loading={changing}
                    >
                        Assign
                    </Button>
                </HorizontalStack>
            </Stack>
        </>
    );
}

export function useChangeBranchModal(user: User) {
    const showModal = useModal<boolean>();

    function show(callback: (shouldReload: boolean) => void) {
        showModal(
            (done) => {
                return (
                    <ChangeBranchModal
                        user={user}
                        onCancel={() => done(false)}
                        onDone={() => done(true)}
                    ></ChangeBranchModal>
                );
            },
            function (inviteSent: boolean | undefined) {
                callback(inviteSent ?? false);
            },
            {
                preventBackdropClick: true,
            }
        );
    }

    return show;
}
