import Colors from 'Cargo/Colors';
import {
    FreightClaim,
    FreightClaimLineItem,
    FreightClaimType,
} from 'generated-openapi-client';
import { CSSProperties } from 'react';

interface AffectedFreightClaimItemProps {
    item: FreightClaimLineItem;
    freightClaim: FreightClaim;
}

export function AffectedFreightClaimItem({
    item,
}: AffectedFreightClaimItemProps) {
    if (!item.unitCost || !item.piecesAffected) {
        return null;
    }

    const claimType =
        item.claimType === FreightClaimType.Loss ? 'lost' : 'damaged';
    const totalItem = item.unitCost * item.piecesAffected;
    const isSingleItem = item.piecesAffected === 1;
    const totalWeight =
        (item.weightPerHandlingUnit ?? 100) * (item.numberHandlingUnits ?? 1);

    const secondaryText: CSSProperties = {
        fontSize: '14px',
        color: Colors.LightText,
        fontWeight: 300,
    };

    const highlightText: CSSProperties = {
        color: Colors.DarkRed,
        filter: 'brightness(0.85)',
    };

    return (
        <div>
            <section>
                Claim for ${totalItem}{' '}
                {!isSingleItem && (
                    <span style={secondaryText}>
                        &bull; ${item.unitCost}/piece
                    </span>
                )}
            </section>
            <section>
                {isSingleItem ? (
                    <>1 {item.condition?.toLowerCase()} piece </>
                ) : (
                    <>
                        {item.piecesAffected} of {item.piecesTotal}{' '}
                        {item.condition?.toLowerCase()} pieces{' '}
                    </>
                )}
                <span style={highlightText}>{claimType}</span>
            </section>
            <section>
                <div style={secondaryText}>
                    in {item.numberHandlingUnits} x {item.handlingUnitType}{' '}
                    {item.description}
                </div>
                <div style={secondaryText}>
                    {item.width}"x{item.height} {item.length}"x{item.width}
                    "x {item.height}", {totalWeight}lb total
                </div>
            </section>
        </div>
    );
}
