import Colors from 'Cargo/Colors';
import Box from 'Cargo/Layout/Box';
import Spacer from 'Cargo/Layout/Spacer';
import { Label } from 'Cargo/Text/Text';
import { ReferenceNumberInput } from 'Features/ReferenceNumbers/Components/ReferenceNumberInput';
import { useValidateReferenceNumber } from 'Features/ReferenceNumbers/Hooks/useValidateReferenceNumber';
import { LocationContext } from 'generated-openapi-client';
import BookShipmentSection from './BookShipmentSection';

interface ReferenceNumbersSectionProps {
    pickupReferenceNumber: string | undefined;
    deliveryReferenceNumber: string | undefined;
    onPickupReferenceNumberChanged: (newValue: string) => void;
    onDeliveryReferenceNumberChanged: (newValue: string) => void;
    index: number;
}
function ReferenceNumbersSection(props: ReferenceNumbersSectionProps) {
    const pickupValidation = useValidateReferenceNumber({
        locationContext: LocationContext.Pickup,
        originalReferenceNumber: undefined,
    });

    const deliveryValidation = useValidateReferenceNumber({
        locationContext: LocationContext.Delivery,
        originalReferenceNumber: undefined,
    });
    return (
        <BookShipmentSection
            index={props.index}
            header={
                <>
                    Reference Numbers{' '}
                    <span
                        style={{
                            color: Colors.LightText,
                            fontWeight: 300,
                            fontSize: '20px',
                        }}
                    >
                        Optional
                    </span>
                </>
            }
            description="Set reference numbers to help identify your shipments. You will have another opportunity to set these after getting quotes."
        >
            <Spacer height={32} />
            <Box width={824}>
                <Spacer height={16} />
                <Label>Pickup Reference Number</Label>
                <ReferenceNumberInput
                    validation={pickupValidation}
                    placeholder="Enter pickup reference number"
                    width={520}
                    value={props.pickupReferenceNumber}
                    onChange={(newValue: string) =>
                        props.onPickupReferenceNumberChanged(newValue)
                    }
                    maxLength={256}
                />
                <Spacer height={16} />
                <Label>Delivery Reference Number</Label>
                <ReferenceNumberInput
                    validation={deliveryValidation}
                    placeholder="Enter delivery reference number"
                    width={520}
                    value={props.deliveryReferenceNumber}
                    onChange={(newValue: string) =>
                        props.onDeliveryReferenceNumberChanged(newValue)
                    }
                    maxLength={256}
                />
            </Box>
        </BookShipmentSection>
    );
}
export default ReferenceNumbersSection;
