import { useShipmentsApi } from 'apis';
import Button from 'Cargo/Controls/Button';
import DockHoursInput from 'Cargo/Controls/DockHoursInput';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import useAlertModal from 'Cargo/Modal/useAlertModal';
import { useModal } from 'Cargo/Modal/useModal';
import { H1, Legalese } from 'Cargo/Text/Text';
import { errorMessageForHours, warningMessageForPickupHours } from 'Features/Locations/Validators/errorMessageForHours';
import { Shipment } from 'generated-openapi-client';
import React, { useState } from 'react';
import ChangeDateControl from '../Components/ChangeDateControl';

interface ChangePickupDateModalProps {
    onDone: () => void;
    shipment: Shipment;
}

function ChangePickupDateModal(props: ChangePickupDateModalProps) {
    const showAlert = useAlertModal();
    const [processing, setProcessing] = useState(false);
    const [openFrom, setOpenFrom] = useState(
        props.shipment.pickupLocation.hours.openFrom
    );
    const [openUntil, setOpenUntil] = useState(
        props.shipment.pickupLocation.hours.openUntil
    );
    const [date, setDate] = useState(props.shipment.pickupDate);
    const [forceValidation, setForceValidation] = useState(false);
    const shipmentsApi = useShipmentsApi();

    const allowAfterHours = props.shipment.pickupLocation.accessorials.includes(
        'SCHEDULING_AFTER_HOURS'
    );

    const hoursErrorMessage = errorMessageForHours(
        { openFrom, openUntil },
        allowAfterHours,
        // TODO: probably ditch the today param?
        false
    );

    const warningMessage = warningMessageForPickupHours({openFrom, openUntil})

    async function onConfirm() {
        if (hoursErrorMessage !== undefined) {
            setForceValidation(true);
            return;
        }

        setProcessing(true);
        try {
            await shipmentsApi.postShipmentsRequestPickupDateChange({
                requestPickupDateChangeRequest: {
                    shipmentId: props.shipment.shipmentId,
                    newPickupDate: date,
                    newPickupHours: {
                        openFrom,
                        openUntil,
                    },
                },
            });
            setProcessing(false);
            await showAlert(
                'Received',
                'The pickup date and time have now been updated, and the change has been communicated to the carrier.'
            );

            props.onDone();
        } catch (e) {
            setProcessing(false);
            await showAlert(
                'Error',
                'Sorry, something went wrong. Our team has been alerted.'
            );
        }
    }

    return (
        <Stack align="left" style={{ padding: '16px', width: '800px' }}>
            <Spacer height={16} />
            <H1>Change Pickup Date or Hours</H1>
            <Legalese>
                Select the updated date and times when your shipment will be
                ready for pickup.
            </Legalese>
            <Spacer height={32} />
            <ChangeDateControl
                country={props.shipment.pickupLocation.address.countryCode}
                currentPickupDate={props.shipment.pickupDate}
                date={date}
                onDateChange={function (m) {
                    setDate(m.format('YYYY-MM-DD'));
                }}
            />

            <Spacer height={16} />
            <DockHoursInput
                openFrom={openFrom}
                openUntil={openUntil}
                setOpenFrom={setOpenFrom}
                setOpenUntil={setOpenUntil}
                errorMessage={hoursErrorMessage}
                forceValidation={forceValidation}
                warningMessage={warningMessage}
            />

            <Spacer height={64} />
            <HorizontalStack>
                <Button secondary onClick={props.onDone}>
                    Cancel
                </Button>
                <Spacer width={16} />
                <Button onClick={onConfirm} loading={processing}>
                    Confirm
                </Button>
            </HorizontalStack>
            <Spacer height={16} />
        </Stack>
    );
}

export function useChangePickupDate(shipment: Shipment) {
    const showModal = useModal<string>();
    function show() {
        const p = new Promise<void>((resolve) => {
            showModal(
                (done) => {
                    return (
                        <ChangePickupDateModal
                            onDone={done}
                            shipment={shipment}
                        />
                    );
                },
                () => {
                    resolve();
                },
                {
                    preventBackdropClick: true,
                }
            );
        });

        return p;
    }

    return show;
}
