import { useSavedLocationsApi } from 'apis';
import { UUID } from 'Cargo/Types/types';
import { AddContactContactType } from 'Features/Contacts/Types/contactTypes';
import {
    Contact,
    Location,
    PostAddressBookDeleteRequest,
    PostSavedLocationsAddContactRequest,
    PostSavedLocationsAddRequest,
    PostSavedLocationsModifyContactRequest,
    PostSavedLocationsModifyRequest,
    PostSavedLocationsSetDefaultContactRequest,
    PostSavedLocationsToggleBranchRequest,
    PostSavedLocationsToggleFavouriteRequest,
    SavedContact,
    SavedLocation,
} from 'generated-openapi-client';

export function useSavedLocationsService() {
    const savedLocationsApi = useSavedLocationsApi();

    return {
        getLocation: async function (
            savedLocationId: UUID
        ): Promise<SavedLocation> {
            return await savedLocationsApi.getSavedLocationsGetOne({
                savedLocationId,
            });
        },

        addLocation: async function (location: SavedLocation) {
            const request: PostSavedLocationsAddRequest = {
                addSavedLocationRequest: {
                    location,
                },
            };

            await savedLocationsApi.postSavedLocationsAdd(request);
        },

        modifyLocation: async function (locationId: UUID, location: Location) {
            const request: PostSavedLocationsModifyRequest = {
                modifySavedLocationRequest: {
                    savedLocationId: locationId,
                    location: location as Location,
                },
            };

            await savedLocationsApi.postSavedLocationsModify(request);
        },

        toggleIsFavourite: async function (savedLocationId: UUID) {
            const request: PostSavedLocationsToggleFavouriteRequest = {
                savedLocationId,
            };
            await savedLocationsApi.postSavedLocationsToggleFavourite(request);
        },

        toggleIsBranch: async function (savedLocationId: UUID) {
            const request: PostSavedLocationsToggleBranchRequest = {
                savedLocationId,
            };
            await savedLocationsApi.postSavedLocationsToggleBranch(request);
        },

        toggleDefaultPickupLocation: async function (savedLocationId: UUID) {
            const request = {
                savedLocationId,
            };
            await savedLocationsApi.postSavedLocationsToggleDefaultPickupLocation(
                request
            );
        },

        toggleDefaultDeliveryLocation: async function (savedLocationId: UUID) {
            const request = {
                savedLocationId,
            };
            await savedLocationsApi.postSavedLocationsToggleDefaultDeliveryLocation(
                request
            );
        },

        deleteSavedLocation: async function (savedLocationId: UUID) {
            const request: PostAddressBookDeleteRequest = {
                deleteAddressBookEntryRequest: {
                    addressBookEntryId: savedLocationId,
                },
            };
            await savedLocationsApi.postAddressBookDelete(request);
        },

        addContact: async function (contact: SavedContact) {
            const request: PostSavedLocationsAddContactRequest = {
                addSavedContactRequest: contact,
            };

            await savedLocationsApi.postSavedLocationsAddContact(request);
        },

        deleteContact: async function (savedContactId: UUID) {
            await savedLocationsApi.getSavedLocationsDeleteContact({
                savedContactId,
            });
        },

        addDefaultContact: async function (contact: SavedContact) {
            const request: PostSavedLocationsAddContactRequest = {
                addSavedContactRequest: contact,
            };

            await savedLocationsApi.postSavedLocationsAddDefaultContact(
                request
            );
        },

        setDefaultContact: async function (
            savedLocationId: UUID,
            savedContactId: UUID
        ) {
            const request: PostSavedLocationsSetDefaultContactRequest = {
                setDefaultContactRequest: {
                    savedLocationId,
                    savedContactId,
                },
            };

            await savedLocationsApi.postSavedLocationsSetDefaultContact(
                request
            );
        },

        modifyContact: async function (
            savedContactId: UUID,
            contact: AddContactContactType
        ) {
            const request: PostSavedLocationsModifyContactRequest = {
                modifySavedContactRequest: {
                    savedContactId,
                    // TODO: Assert
                    contact: contact as Contact,
                },
            };
            await savedLocationsApi.postSavedLocationsModifyContact(request);
        },
    };
}
