import Colors from 'Cargo/Colors';
import LinkButton from 'Cargo/Controls/LinkButton';
import MiniMap from 'Cargo/Controls/MiniMap';
import Pill, { PillStyle } from 'Cargo/Controls/Pill';
import ThreeDotMenu, { divider } from 'Cargo/Controls/ThreeDotMenu';
import Icon from 'Cargo/Icons/Icon';
import Box, { BoxStyle } from 'Cargo/Layout/Box';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { nameAccessorials } from 'Data/AccessorialTypes';
import { describeLocationType } from 'Data/LocationTypes';
import { Contact, Location, LocationContext } from 'generated-openapi-client';
import { describeHours } from 'Helpers/describeHours';
import { formatPhone } from 'Helpers/formatPhone';
import { fullStateOrProvinceName } from 'Helpers/fullStateOrProvinceName';
import styled from 'styled-components/macro';

const BusinessName = styled.div`
    font-weight: var(--nhu-font-weight-medium);
    font-size: 18px;
    color: var(--freightsimple-color-normal-text);
`;

const SectionLabel = styled.div`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 12px;
    color: var(--freightsimple-color-normal-text);
`;
const SectionLine = styled.div`
    font-weight: var(--nhu-font-weight-light);
    font-size: 12px;
    color: var(--freightsimple-color-light-text);
`;

interface LocationBoxMenuProps {
    onModify: () => void;
    onDelete?: () => void;
    isFavourite: boolean;
    isBranch: boolean;
    onToggleFavourite?: () => void;
    onToggleBranch?: () => void;
    isDefaultPickupLocation: boolean;
    isDefaultDeliveryLocation: boolean;
    onToggleDefaultPickupLocation: () => void;
    onToggleDefaultDeliveryLocation: () => void;
    onViewShipmentsFromThisLocation: () => void;
    onViewShipmentsToThisLocation: () => void;
    onChangeContact: () => void;
}

function LocationBoxMenu(props: LocationBoxMenuProps) {
    const items = [];
    items.push({
        name: 'Modify',
        action: function () {
            props.onModify();
        },
    });

    items.push({
        name: 'Manage Contacts',
        action: function () {
            props.onChangeContact();
        },
    });

    items.push(divider());
    items.push({
        name: props.isFavourite ? 'Unmark as favourite' : 'Mark as favourite',
        action: function () {
            props.onToggleFavourite?.();
        },
    });

    items.push({
        name: props.isBranch ? 'Unmark as branch' : 'Mark as branch',
        action: function () {
            props.onToggleBranch?.();
        },
    });

    items.push({
        name: props.isDefaultPickupLocation
            ? 'Unset as default pickup location'
            : 'Set as default pickup location',
        action: function () {
            props.onToggleDefaultPickupLocation();
        },
    });

    items.push({
        name: props.isDefaultDeliveryLocation
            ? 'Unset as default delivery location'
            : 'Set as default delivery location',
        action: function () {
            props.onToggleDefaultDeliveryLocation();
        },
    });

    items.push(divider());

    items.push({
        name: 'View Shipments From This Location',
        action: function () {
            props.onViewShipmentsFromThisLocation();
        },
    });

    items.push({
        name: 'View Shipments To This Location',
        action: function () {
            props.onViewShipmentsToThisLocation();
        },
    });

    if (props.onDelete) {
        items.push(divider());
        items.push({
            name: 'Delete',
            action: function () {
                if (props.onDelete !== undefined) {
                    props.onDelete();
                }
            },
        });
    }

    return <ThreeDotMenu items={items} />;
}

export function FavouriteStar() {
    return (
        <Icon
            name="star"
            color={Colors.Gold}
            size={16}
            solid
            style={{ marginLeft: '8px' }}
        />
    );
}

export function BuildingIcon() {
    return (
        <Icon
            name="building"
            color={Colors.DarkGray}
            size={16}
            solid
            style={{ marginLeft: '8px' }}
        />
    );
}

export function DefaultLocation() {
    return (
        <Icon
            name="warehouse"
            color={Colors.LightText}
            size={16}
            solid
            style={{ marginLeft: '8px' }}
        />
    );
}

function PickupDefault() {
    return (
        <span style={{ marginLeft: '16px' }}>
            <Pill pillStyle={PillStyle.Yellow}>
                Pickup Default <DefaultLocation />
            </Pill>
        </span>
    );
}

function DeliveryDefault() {
    return (
        <span style={{ marginLeft: '16px' }}>
            <Pill pillStyle={PillStyle.Yellow}>
                Delivery Default <DefaultLocation />
            </Pill>
        </span>
    );
}

interface LocationBoxProps {
    location: Location;
    contact: Contact | undefined;
    contactCount: number;
    onModify: () => void;
    onChangeContact: () => void;

    showMenu: boolean;
    onDelete?: () => void;
    context: LocationContext;
    isFavourite: boolean;
    isBranch: boolean;
    onToggleFavourite?: () => void;
    onToggleBranch?: () => void;
    isDefaultPickupLocation: boolean;
    isDefaultDeliveryLocation: boolean;
    onToggleDefaultPickupLocation: () => void;
    onToggleDefaultDeliveryLocation: () => void;
    onViewShipmentsFromThisLocation: () => void;
    onViewShipmentsToThisLocation: () => void;
}
function LocationBox(props: LocationBoxProps) {
    const { businessName, address, notes } = props.location;
    const contact = props.contact;
    const contactCount = props.contactCount;

    const { latitude, longitude } = props.location.latitudeLongitude;
    const { postalCode, stateOrProvinceCode, city } = props.location.address;

    const accessorials = nameAccessorials(
        props.location.accessorials,
        props.context,
        props.location.locationType
    );

    const hoursDescription = describeHours(props.location.hours);

    return (
        <Box
            width={1004}
            style={{ minHeight: '242px', padding: '32px' }}
            boxStyle={BoxStyle.Tight}
        >
            <HorizontalStack verticalAlign="top" style={{ height: '100%' }}>
                <MiniMap
                    latitude={latitude}
                    longitude={longitude}
                    postalCode={postalCode}
                    stateOrProvinceCode={stateOrProvinceCode}
                    city={city}
                    width={384}
                    height={160}
                />
                <Spacer width={56} />
                <Stack align="left">
                    <HorizontalStack align="spread" width="100%">
                        <BusinessName>
                            {businessName}{' '}
                            {props.isFavourite && <FavouriteStar />}
                            {props.isBranch && <BuildingIcon />}
                            {props.isDefaultPickupLocation && <PickupDefault />}
                            {props.isDefaultDeliveryLocation && (
                                <DeliveryDefault />
                            )}
                        </BusinessName>
                        {props.showMenu && (
                            <LocationBoxMenu
                                onModify={props.onModify}
                                onDelete={props.onDelete}
                                isFavourite={props.isFavourite}
                                isBranch={props.isBranch}
                                onToggleFavourite={props.onToggleFavourite}
                                onToggleBranch={props.onToggleBranch}
                                isDefaultPickupLocation={
                                    props.isDefaultPickupLocation
                                }
                                isDefaultDeliveryLocation={
                                    props.isDefaultDeliveryLocation
                                }
                                onToggleDefaultPickupLocation={
                                    props.onToggleDefaultPickupLocation
                                }
                                onToggleDefaultDeliveryLocation={
                                    props.onToggleDefaultDeliveryLocation
                                }
                                onViewShipmentsToThisLocation={
                                    props.onViewShipmentsToThisLocation
                                }
                                onViewShipmentsFromThisLocation={
                                    props.onViewShipmentsFromThisLocation
                                }
                                onChangeContact={props.onChangeContact}
                            />
                        )}
                        {!props.showMenu && (
                            <LinkButton onClick={props.onModify}>
                                Modify
                            </LinkButton>
                        )}
                    </HorizontalStack>
                    <Spacer height={16} />
                    <HorizontalStack width="100%" verticalAlign="top">
                        <Stack align="left" width="174px">
                            <SectionLabel>Address</SectionLabel>
                            <SectionLine>{address.addressLine}</SectionLine>
                            {address.addressLine2 && (
                                <SectionLine>
                                    {address.addressLine2}
                                </SectionLine>
                            )}
                            <SectionLine>{address.city}</SectionLine>
                            <SectionLine>
                                {fullStateOrProvinceName(
                                    address.stateOrProvinceCode
                                )}
                            </SectionLine>
                            <SectionLine>{address.postalCode}</SectionLine>
                            <Spacer height={8} />
                            <SectionLabel>Default Hours</SectionLabel>
                            <SectionLine>{hoursDescription}</SectionLine>
                        </Stack>
                        <Stack align="left" width="180px">
                            <SectionLabel>
                                {contactCount > 1 ? 'Contacts' : 'Contact'}
                            </SectionLabel>
                            {contact && (
                                <>
                                    <SectionLine>
                                        {contact.contactName}
                                    </SectionLine>
                                    <SectionLine>
                                        {contact.emailAddress}
                                    </SectionLine>
                                    <SectionLine>
                                        {formatPhone(
                                            contact.phoneNumber,
                                            contact.phoneNumberExtension
                                        )}
                                    </SectionLine>
                                    {contactCount > 1 && (
                                        <>
                                            <Spacer height={4}></Spacer>
                                            <SectionLine>
                                                (and {contactCount - 1} more)
                                            </SectionLine>
                                        </>
                                    )}
                                </>
                            )}
                            {!contact && (
                                <SectionLine>
                                    There are no saved contacts
                                </SectionLine>
                            )}
                            <Spacer height={8} />
                            <SectionLabel>Location Type</SectionLabel>
                            <SectionLine>
                                {describeLocationType(
                                    props.location.locationType
                                )}
                            </SectionLine>
                        </Stack>
                        <Stack align="left" width="138px">
                            <SectionLabel>Special Services</SectionLabel>
                            {accessorials.map((a, index) => {
                                return (
                                    <SectionLine key={index}>{a}</SectionLine>
                                );
                            })}
                            <Spacer height={8} />
                            <SectionLabel>Notes</SectionLabel>
                            <SectionLine>
                                {notes || 'No notes entered'}
                            </SectionLine>
                        </Stack>
                    </HorizontalStack>
                </Stack>
            </HorizontalStack>
        </Box>
    );
}
export default LocationBox;
