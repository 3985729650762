import React, { ReactElement } from 'react';

export enum FirstToMatchEnum {
    Default,
}

interface FirstToMatchElement {
    when?: boolean | FirstToMatchEnum;
    show: ReactElement;
}

interface FirstToMatchProps {
    components: Array<FirstToMatchElement>;
}
function FirstToMatch(props: FirstToMatchProps): ReactElement {
    function toFind(c: FirstToMatchElement) {
        if (c.when === undefined) {
            return false;
        }

        if (c.when === FirstToMatchEnum.Default) {
            return true;
        }

        return c.when;
    }
    return props.components.find(toFind)?.show ?? <></>;
}
export default FirstToMatch;
