import { useRef, useState } from 'react';

import Colors from 'Cargo/Colors';
import { ReactionType, useReaction } from 'Cargo/Hooks/Reaction';
import Logo from 'Cargo/Icons/Logo';
import ProgressSpinner, {
    ProgressSpinnerSizes,
} from 'Cargo/Icons/ProgressSpinner';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { useOnce } from 'Hooks/useOnce';
import useQuery from 'Hooks/useQuery';
import { useTrackingService } from 'Services/TrackingService';
import { usePhotosApi } from 'apis';
import { ShipmentForTracking } from 'generated-openapi-client';
import styled from 'styled-components/macro';
import { SmallUploadPhotoButton } from './Components/SmallUploadPhotoButton';
import { UploadPhotoButton } from './Components/UploadPhotoButton';
import { UploadPhotosShipmentDescription } from './Components/UploadPhotosShipmentDescription';

const Explanation = styled.div`
    text-align: center;
    font-size: 14px;
    color: ${Colors.LightText};
    margin-left: 20px;
    margin-right: 20px;
`;

function UploadPhotosScreen() {
    const query = useQuery();
    const trackingId = query.trackingId as string;
    const photosApi = usePhotosApi();
    const trackingService = useTrackingService();
    const { showReaction } = useReaction();
    const [shipment, setShipment] = useState<ShipmentForTracking | undefined>();
    const [loading, setLoading] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [lastUploadedImage, setLastUploadedImage] = useState<
        File | undefined
    >();

    async function loadShipment() {
        const response = await trackingService.getShipmentForTracking(
            trackingId
        );
        console.log({ response });

        setShipment(response);
    }

    useOnce(async () => {
        loadShipment();
    });

    async function onUpload(file: File) {
        setLoading(true);
        try {
            await photosApi.uploadPhotoWithTrackingId(trackingId, file);
            showReaction(ReactionType.Success);
        } catch (e: unknown) {
            console.error(`Failure uploading photo`, { e });
            alert('Ooops something went wrong');
        } finally {
            setLoading(false);
        }
    }

    if (shipment === undefined) {
        return (
            <Stack align="center">
                <Logo />
                <Spacer height={16} />
                <ProgressSpinner size={ProgressSpinnerSizes.Medium} />
            </Stack>
        );
    }

    return (
        <Stack align="center">
            <Spacer height={16} />
            <Logo />
            <Spacer height={8} />
            <div style={{ textAlign: 'center' }}>
                Please take multiple clear photos of all the handling units in
                your shipment
            </div>
            <Spacer height={16} />
            {loading && <ProgressSpinner size={ProgressSpinnerSizes.Medium} />}
            {!loading && lastUploadedImage && (
                <div>
                    <img
                        width={'200px'}
                        src={URL.createObjectURL(lastUploadedImage)}
                    />
                </div>
            )}
            <Spacer height={24} />
            {!loading && (
                <>
                    <label htmlFor="image_uploads">
                        {lastUploadedImage === undefined && (
                            <UploadPhotoButton />
                        )}
                        {lastUploadedImage !== undefined && (
                            <SmallUploadPhotoButton />
                        )}
                    </label>
                    <input
                        style={{ opacity: 0 }}
                        type="file"
                        ref={inputRef}
                        accept="image/jpeg"
                        capture="environment"
                        name="image_uploads"
                        id="image_uploads"
                        onChange={(event) => {
                            if (event.target.files) {
                                const file = event.target.files[0];
                                setLastUploadedImage(file);
                                onUpload(file);

                                if (inputRef.current) {
                                    inputRef.current.value = '';
                                }
                            }
                        }}
                    />
                </>
            )}
            <UploadPhotosShipmentDescription
                pickupCompanyName={shipment.pickupLocation.businessName}
                pickupCity={shipment.pickupLocation.address.city}
                pickupState={
                    shipment.pickupLocation.address.stateOrProvinceCode
                }
                deliveryCompanyName={shipment.deliveryLocation.businessName}
                deliveryCity={shipment.deliveryLocation.address.city}
                deliveryState={
                    shipment.deliveryLocation.address.stateOrProvinceCode
                }
                lineItems={shipment.lineItems}
            />
            <Explanation>
                Photos are important if the freight goes missing, is damaged or
                there is a dispute about the contents
            </Explanation>
        </Stack>
    );
}

export default UploadPhotosScreen;
