"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canadianProvicesNames = exports.canadianProvicesAbbreviations = exports.canadianProvinceCodeMap = void 0;
exports.canadianProvinceCodeMap = {
    AB: "Alberta",
    BC: "British Columbia",
    MB: "Manitoba",
    NB: "New Brunswick",
    NL: "Newfoundland",
    NT: "North West Territories",
    NS: "Nova Scotia",
    NU: "Nunavut",
    ON: "Ontario",
    PE: "Prince Edward Island",
    QC: "Quebec",
    SK: "Saskatchewan",
    YT: "Yukon",
};
exports.canadianProvicesAbbreviations = Object.entries(exports.canadianProvinceCodeMap).map(([provinceAbbreviation]) => provinceAbbreviation);
exports.canadianProvicesNames = Object.entries(exports.canadianProvinceCodeMap).map(([_, provinceName]) => provinceName);
