/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Hours } from './Hours';
import {
    HoursFromJSON,
    HoursFromJSONTyped,
    HoursToJSON,
} from './Hours';

/**
 * 
 * @export
 * @interface RequestPickupDateChangeRequest
 */
export interface RequestPickupDateChangeRequest {
    /**
     * 
     * @type {string}
     * @memberof RequestPickupDateChangeRequest
     */
    shipmentId: string;
    /**
     * 
     * @type {string}
     * @memberof RequestPickupDateChangeRequest
     */
    newPickupDate: string;
    /**
     * 
     * @type {Hours}
     * @memberof RequestPickupDateChangeRequest
     */
    newPickupHours: Hours;
}

/**
 * Check if a given object implements the RequestPickupDateChangeRequest interface.
 */
export function instanceOfRequestPickupDateChangeRequest(value: object): boolean {
    if (!('shipmentId' in value)) return false;
    if (!('newPickupDate' in value)) return false;
    if (!('newPickupHours' in value)) return false;
    return true;
}

export function RequestPickupDateChangeRequestFromJSON(json: any): RequestPickupDateChangeRequest {
    return RequestPickupDateChangeRequestFromJSONTyped(json, false);
}

export function RequestPickupDateChangeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestPickupDateChangeRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentId': json['shipmentId'],
        'newPickupDate': json['newPickupDate'],
        'newPickupHours': HoursFromJSON(json['newPickupHours']),
    };
}

export function RequestPickupDateChangeRequestToJSON(value?: RequestPickupDateChangeRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentId': value['shipmentId'],
        'newPickupDate': value['newPickupDate'],
        'newPickupHours': HoursToJSON(value['newPickupHours']),
    };
}

