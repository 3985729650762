import { useUsersApi } from 'apis';
import Animation from 'Cargo/Animations/Animation';
import Colors from 'Cargo/Colors';
import Button from 'Cargo/Controls/Button';
import Input from 'Cargo/Controls/Input';
import LinkButton from 'Cargo/Controls/LinkButton';
import Icon from 'Cargo/Icons/Icon';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { useModal } from 'Cargo/Modal/useModal';
import { H2, Legalese, Microcopy } from 'Cargo/Text/Text';
import { useAuthentication } from 'Features/Authentication/Slices/authenticationSlice';
import {
    errorMessageForContactName,
    errorMessageForEmail,
} from 'Features/Contacts/Validators/errorMessagesForContact';
import { ResponseError } from 'generated-openapi-client';
import { useEffect, useState } from 'react';
import useClipboard from 'react-use-clipboard';
import styled from 'styled-components/macro';

interface InviteModalProps {
    onInviteSent: () => void;
    onCancel: () => void;
}

const LinkText = styled.pre`
    background-color: ${Colors.VeryLightGray};
    border: 1px solid ${Colors.LightGray};
    color: ${Colors.NormalText};
    padding: 10px;
    margin: 0;
    max-width: 512px;
    word-wrap: break-word;
    white-space: pre-wrap;
`;

function InviteModal(props: InviteModalProps) {
    const usersApi = useUsersApi();
    const { user } = useAuthentication();
    const companyName = user?.companyName ?? '';
    const [inviteeEmailAddress, setInviteeEmailAddress] = useState('');
    const [inviteeName, setInviteeName] = useState('');
    const [forceValidation, setForceValidation] = useState(false);

    const [wasSuccess, setWasSuccess] = useState(false);
    const [url, setUrl] = useState('');
    const [isCopied, setCopied] = useClipboard(url);

    const errorMessageForInviteeEmailAddress =
        errorMessageForEmail(inviteeEmailAddress);
    const errorMessageForInviteeName = errorMessageForContactName(inviteeName);
    const [loading, setLoading] = useState(false);
    const [isInvitationAlreadySent, setIsInvitationAlreadySent] = useState(false);

    function isValid(): boolean {
        if (errorMessageForInviteeName !== undefined) {
            return false;
        }

        if (errorMessageForInviteeEmailAddress !== undefined) {
            return false;
        }

        return true;
    }

    useEffect(() => {
        setIsInvitationAlreadySent(false);
    }, [inviteeEmailAddress]);

    async function resendInvitation() {
        setLoading(true);
        try {
            const response = await usersApi.postUsersResendInvitation({
                resendInvitationRequest: {
                    inviteeEmailAddress: inviteeEmailAddress,
                },
            });
            setUrl(response.invitationUrl)
            setWasSuccess(true);
        } catch (e) {
            console.error('Something went wrong resending', { e });
        }
        setLoading(false);
    }

    async function onSendInvitation() {
        setForceValidation(true);

        if (!isValid()) {
            return;
        }

        try {
            setLoading(true);
            const response = await usersApi.postUsersSendInvitation({
                sendInvitationRequest: {
                    inviteeEmailAddress,
                    inviteeName,
                },
            });
            setUrl(response.invitationUrl);
            setWasSuccess(true);
        } catch (e) {
            console.error('Something went wrong requesting the invitation', {
                e,
            });

            if (e instanceof ResponseError) {
                if (e.response.status === 409) {
                    setIsInvitationAlreadySent(true);
                }
            }
        }
        setLoading(false);
    }

    if (wasSuccess) {
        return (
            <Stack align="center" width="100%">
                <H2>Invite Email Sent</H2>
                <Microcopy>
                    Please ask the invitee to check their inbox
                </Microcopy>
                <Legalese>
                    (and their spam folder if they can&apos;t find it at first)
                </Legalese>
                <Animation
                    pathToAnimation="/animations/email-sent.json"
                    lottieFilesUrl="https://lottiefiles.com/6391-email-sent"
                    loop={true}
                    speed={0.5}
                />
                <Microcopy>
                    Alternatively, you can copy this url and send it to them
                    yourself
                </Microcopy>
                <Spacer height={16} />

                <LinkText id="invitationUrl">{url}</LinkText>
                <Spacer height={8} />
                <HorizontalStack verticalAlign="middle">
                    <LinkButton onClick={setCopied}>
                        Click here to copy to clipboard
                    </LinkButton>
                    {isCopied && (
                        <Icon
                            name="check"
                            color={Colors.Green}
                            size={16}
                            solid
                            style={{ marginLeft: '8px' }}
                        />
                    )}
                </HorizontalStack>
                <Spacer height={32} />
                <Button size="large" onClick={props.onInviteSent}>
                    Done
                </Button>
            </Stack>
        );
    }

    return (
        <>
            <Stack align="center" width="900px" style={{ padding: '32px' }}>
                <H2>Invite someone to join {companyName}</H2>
                <div style={{ width: '650px' }}>
                    <Microcopy style={{ textAlign: 'center' }}>
                        Enter the details of someone you would like to receive
                        an invite to join this company in FreightSimple. They
                        will receive an email with instructions on how to create
                        their account.
                    </Microcopy>
                </div>
                <Spacer height={32} />
                <Stack align="left">
                    <Input
                        type="text"
                        label="Email"
                        value={inviteeEmailAddress}
                        onChange={(value) => setInviteeEmailAddress(value)}
                        width={429}
                        errorMessage={errorMessageForInviteeEmailAddress}
                        forceValidation={forceValidation}
                        autoComplete="email"
                        name="invitee_email_address"
                    />
                    <Spacer height={16} />
                    <Input
                        type="text"
                        label="Full Name"
                        value={inviteeName}
                        onChange={(value) => setInviteeName(value)}
                        width={429}
                        errorMessage={errorMessageForInviteeName}
                        forceValidation={forceValidation}
                        autoComplete="name"
                        name="invitee_full_name"
                    />
                </Stack>
                {isInvitationAlreadySent && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Animation
                            style={{ width: '40px' }}
                            lottieFilesUrl="https://edit.lottiefiles.com/?src=https%3A%2F%2Fassets4.lottiefiles.com%2Fpackages%2Flf20_HYlQBb.json"
                            pathToAnimation="/animations/yellow-exclamation.json"
                        />
                        Invitation to
                        <span
                            style={{ fontWeight: 500, marginInline: '0.5ex' }}
                        >
                            {inviteeEmailAddress}
                        </span>
                        already sent!
                    </div>
                )}
                <Spacer height={40} />
                <HorizontalStack>
                    <Button size="large" onClick={props.onCancel} secondary>
                        Cancel
                    </Button>
                    <Spacer width={16} />
                    {isInvitationAlreadySent ? (
                        <Button
                            size="large"
                            onClick={resendInvitation}
                            loading={loading}
                        >
                            Resend Invitation
                        </Button>
                    ) : (
                        <Button
                            size="large"
                            onClick={onSendInvitation}
                            loading={loading}
                        >
                            Send Invitation
                        </Button>
                    )}
                </HorizontalStack>
                <Spacer height={40} />
            </Stack>
        </>
    );
}

export function useInviteModal() {
    const showModal = useModal<boolean>();

    function show(callback: (shouldReload: boolean) => void) {
        showModal(
            (done) => {
                return (
                    <InviteModal
                        onCancel={() => done(false)}
                        onInviteSent={() => done(true)}
                    ></InviteModal>
                );
            },
            function (inviteSent: boolean | undefined) {
                callback(inviteSent ?? false);
            },
            {
                preventBackdropClick: true,
            }
        );
    }

    return show;
}
