import { usePaymentMethodsApi } from 'apis';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { Microcopy, ModalTitle } from 'Cargo/Text/Text';
import CardEntryForm from 'Features/BookShipment/Components/CardEntryForm';
import StoreCreditCardButton from 'Features/CreditCards/Components/StoreCreditCardButton';
import useStoreCreditCard from 'Features/CreditCards/Hooks/useStoreCreditCard';
import React, { useState } from 'react';
import AddCreditCardLegalease from '../Components/AddCreditCardLegalease';

interface AddCreditCardModalProps {
    onCardAdded: () => void;
}

const AddCreditCardModal: React.FC<AddCreditCardModalProps> = (
    props: AddCreditCardModalProps
) => {
    const {
        storeCreditCard,
        billingAddress,
        billingName,
        setBillingName,
        setBillingAddress,
        errorMessageForBillingName,
        errorMessageForCreditCardNumber,
        errorMessageForCreditCardExpiration,
        errorMessageForCreditCardCVC,
        setErrorMessageForCreditCardNumber,
        setErrorMessageForCreditCardExpiration,
        setErrorMessageForCreditCardCVC,
        errorMessageForAddress,
        errorMessageForCity,
        errorMessageForStateOrProvinceCode,
        errorMessageForPostalCode,
        errorMessageForCountry,
        isValid,
    } = useStoreCreditCard();
    const paymentMethodsApi = usePaymentMethodsApi();

    const [storingInProgress, setStoringInProgress] = useState(false);
    const [storingSuccess, setStoringSuccess] = useState(false);
    const [storingFailure, setStoringFailure] = useState(false);
    const [failureMessage, setFailureMessage] = useState<string>();

    const [validated, setValidated] = useState(false);

    async function onStoreCreditCard() {
        setValidated(true);

        if (!isValid) {
            return;
        }

        const saveCardIntentResponse =
            await paymentMethodsApi.postGenerateSaveCardIntent();

        const clientSecret = saveCardIntentResponse.clientSecret;

        setStoringSuccess(false);
        setStoringFailure(false);
        setStoringInProgress(true);

        const result = await storeCreditCard(clientSecret);

        setStoringInProgress(false);

        if (result.setupIntent) {
            setStoringSuccess(true);
            props.onCardAdded();
        } else {
            setStoringFailure(true);
            setFailureMessage(result.error.message);
        }
    }

    return (
        <Stack align="left" width="900px">
            <ModalTitle>Add Credit Card</ModalTitle>
            <Microcopy>
                FreightSimple accepts Visa, Mastercard, and American Express
            </Microcopy>
            <CardEntryForm
                addresses={[]}
                billingAddress={billingAddress}
                billingName={billingName}
                setBillingAddress={setBillingAddress}
                setBillingName={setBillingName}
                forceValidation={validated}
                errorMessageForBillingName={errorMessageForBillingName}
                errorMessageForCreditCardNumber={
                    errorMessageForCreditCardNumber
                }
                errorMessageForCreditCardExpiration={
                    errorMessageForCreditCardExpiration
                }
                errorMessageForCreditCardCVC={errorMessageForCreditCardCVC}
                errorMessageForAddress={errorMessageForAddress}
                errorMessageForCity={errorMessageForCity}
                errorMessageForStateOrProvinceCode={
                    errorMessageForStateOrProvinceCode
                }
                errorMessageForPostalCode={errorMessageForPostalCode}
                errorMessageForCountry={errorMessageForCountry}
                onCreditCardCVCError={setErrorMessageForCreditCardCVC}
                onCreditCardExpirationError={
                    setErrorMessageForCreditCardExpiration
                }
                onCreditCardNumberError={setErrorMessageForCreditCardNumber}
                onValidate={() => setValidated(true)}
                sideBySideLayout
            />
            <Spacer height={32} />
            <StoreCreditCardButton
                onClick={onStoreCreditCard}
                storingInProgress={storingInProgress}
                storingSuccess={storingSuccess}
                storingFailure={storingFailure}
                failureMessage={failureMessage}
            />
            <Spacer height={8} />
            <AddCreditCardLegalease />
        </Stack>
    );
};

export default AddCreditCardModal;
