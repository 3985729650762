import { AddLocationLocationType } from 'Features/Locations/Types/locationTypes';
import { CountryCode } from 'generated-openapi-client';

export function isDomesticCanada(
    pickup: AddLocationLocationType,
    delivery: AddLocationLocationType
) {
    return (
        pickup.address.countryCode === CountryCode.Canada &&
        delivery.address.countryCode === CountryCode.Canada
    );
}
