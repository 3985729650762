import { AddLineItemType } from 'Features/LineItems/Types/lineItemTypes';
import { HandlingUnitType, LineItem } from 'generated-openapi-client';

interface HandlingUnitTypeInfo {
    singular: string;
    plural: string;
    type: HandlingUnitType;
    preposition: string;
    alternateMenuName?: string;
}

const handlingUnitTypes: HandlingUnitTypeInfo[] = [
    {
        singular: 'Bin',
        plural: 'Bins',
        type: HandlingUnitType.Bin,
        preposition: 'in',
    },
    {
        singular: 'Box',
        plural: 'Boxes',
        type: HandlingUnitType.Box,
        preposition: 'in',
    },
    {
        singular: 'Bundle',
        plural: 'Bundles',
        type: HandlingUnitType.Bundle,
        preposition: 'in',
    },
    {
        singular: 'Cage',
        plural: 'Cages',
        type: HandlingUnitType.Cage,
        preposition: 'in',
    },
    {
        singular: 'Crate',
        plural: 'Crates',
        type: HandlingUnitType.Crate,
        preposition: 'in',
    },
    {
        singular: 'Drum',
        plural: 'Drums',
        type: HandlingUnitType.Drum,
        preposition: 'in',
    },
    {
        singular: 'Pallet',
        plural: 'Pallets',
        type: HandlingUnitType.Pallet,
        preposition: 'on',
    },
    {
        singular: 'Reel',
        plural: 'Reels',
        type: HandlingUnitType.Reel,
        preposition: 'in',
    },
    {
        singular: 'Roll',
        plural: 'Rolls',
        type: HandlingUnitType.Roll,
        preposition: 'in',
    },
    {
        singular: 'Skid',
        plural: 'Skids',
        type: HandlingUnitType.Skid,
        preposition: 'on',
    },
    {
        singular: 'Item',
        plural: 'Items',
        type: HandlingUnitType.Loose,
        alternateMenuName: 'Loose Item',
        preposition: '',
    },
    {
        singular: 'Unit',
        plural: 'Units',
        type: HandlingUnitType.Other,
        alternateMenuName: 'Other',
        preposition: 'in',
    },
];

export function getHandlingUnitTypesForDropdown(pluralForm: boolean) {
    return handlingUnitTypes.map((t) => {
        return {
            item: t.type,
            description: t.alternateMenuName
                ? t.alternateMenuName
                : pluralForm
                ? t.plural
                : t.singular,
        };
    });
}

function getHandlingUnitTypeInfo(handlingUnitType: HandlingUnitType) {
    const item = handlingUnitTypes.find((t) => t.type === handlingUnitType);

    if (item === undefined) {
        throw new Error(
            `Missing handling unit type ${handlingUnitType.toString()}`
        );
    }

    return item;
}

export function getPrepositionForHandlingUnitType(
    handlingUnitType: HandlingUnitType
) {
    const item = getHandlingUnitTypeInfo(handlingUnitType);

    return item.preposition;
}

export function getSingularFormForHandlingUnitType(
    handlingUnitType: HandlingUnitType
) {
    const item = getHandlingUnitTypeInfo(handlingUnitType);

    return item.singular;
}

export function getPluralFormForHandlingUnitType(
    handlingUnitType: HandlingUnitType
) {
    const item = getHandlingUnitTypeInfo(handlingUnitType);

    return item.plural;
}

export function getSingularOrPluralFormForHandlingUnitType(
    handlingUnitType: HandlingUnitType,
    numberItems: number
) {
    if (numberItems === 1) {
        return getSingularFormForHandlingUnitType(handlingUnitType);
    } else {
        return getPluralFormForHandlingUnitType(handlingUnitType);
    }
}

export function formatHandlingUnitTypeWithQuantity(
    numberHandlingUnits: number,
    handlingUnitType: HandlingUnitType
) {
    const item = getHandlingUnitTypeInfo(handlingUnitType);

    if (numberHandlingUnits > 1) {
        return `${numberHandlingUnits} x ${item.plural}`;
    } else {
        return `${numberHandlingUnits} x ${item.singular}`;
    }
}

export function getSinglularFormOfHandlingUnitTypeForLineItems(
    lineItems: Array<LineItem | AddLineItemType>
) {
    const handlingUnitTypes = lineItems.map(
        (lineItem) => lineItem.handlingUnitType
    );

    const handlingUnitTypesSet = new Set<HandlingUnitType>();

    handlingUnitTypes.forEach((handlingUnitType) => {
        handlingUnitTypesSet.add(handlingUnitType);
    });

    if (handlingUnitTypesSet.size != 1) {
        return 'Piece';
    } else {
        return getSingularFormForHandlingUnitType(
            Array.from(handlingUnitTypesSet)[0]
        );
    }
}
