import { AddLocationLocationType } from 'Features/Locations/Types/locationTypes';
import { CountryCode, LocationType } from 'generated-openapi-client';
import { emptyLocationErrorMessages } from './emptyErrorMessages';
import {
    LocationErrors,
    errorMessagesForLocation,
} from './errorMessagesForLocation';

export function errorMessagesForDeliveryLocation(
    pickupLocation: AddLocationLocationType,
    deliveryLocation: AddLocationLocationType
): LocationErrors {
    const errorMessages = errorMessagesForLocation(deliveryLocation, false);

    if (
        pickupLocation.address.postalCode ===
        deliveryLocation.address.postalCode
    ) {
        if (pickupLocation.address.countryCode === CountryCode.Canada) {
            errorMessages.address.postalCode =
                'This is the same as the pickup postal code';
        }
    }

    if (
        pickupLocation.locationType === LocationType.Residential &&
        deliveryLocation.locationType === LocationType.Residential
    ) {
        errorMessages.locationType =
            'FreightSimple does not support purely residential moves';
    }

    return errorMessages;
}

export function warningMessagesForDeliveryLocation(
    pickupLocation: AddLocationLocationType | undefined,
    deliveryLocation: AddLocationLocationType
): LocationErrors {
    const errorMessages = emptyLocationErrorMessages();

    if (
        pickupLocation !== undefined &&
        pickupLocation.address.postalCode ===
            deliveryLocation.address.postalCode
    ) {
        if (pickupLocation.address.countryCode === CountryCode.UnitedStates) {
            errorMessages.address.postalCode =
                '⚠️ This is the same as the pickup zipcode';
        }
    }

    if (
        deliveryLocation.locationType === LocationType.Residential &&
        !deliveryLocation.accessorials.includes('LOGISTICS_LIFT_GATE_REQUIRED')
    ) {
        return {
            ...errorMessages,
            locationType: 'Typically residental deliveries require lift gates',
        };
    }

    if (
        deliveryLocation.locationType === LocationType.Residential &&
        !deliveryLocation.accessorials.includes(
            'SCHEDULING_APPOINTMENT_REQUIRED'
        )
    ) {
        return {
            ...errorMessages,
            locationType:
                'Typically residental deliveries require an appointment',
        };
    }

    return errorMessages;
}
