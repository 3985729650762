import { datadogRum } from '@datadog/browser-rum';
import Colors from 'Cargo/Colors';
import { useSiteMargin } from 'Cargo/Layout/DynamicSpacer';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import { LogRocketProject } from 'environment';
import { useAuthentication } from 'Features/Authentication/Slices/authenticationSlice';
import LogRocket from 'logrocket';
import React, { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { NavLeftSide } from './LeftSide/NavLeftSide';
import { NavRightSide } from './RightSide/NavRightSide';
import { SubNavBar } from './SubNavBar/SubNavBar';

const NavBar: React.FC = () => {
    const { boot, getVisitorId, update } = useIntercom();
    const { isAuthenticated, user } = useAuthentication();

    const paddingWidth = useSiteMargin();

    useEffect(() => {
        setTimeout(() => {
            if (
                process.env.REACT_APP_FS_ENVIRONMENT === 'preprod' ||
                process.env.REACT_APP_FS_ENVIRONMENT === 'production' ||
                process.env.REACT_APP_FS_ENVIRONMENT === 'demo'
            ) {
                const visitorId = getVisitorId();
                if (isAuthenticated && user) {
                    if (!user.email.endsWith('@freightsimple.com')) {
                        boot({
                            email: user.email,
                            name: user.name,
                            company: {
                                companyId: user.companyId || '',
                            },
                        });
                    }

                    LogRocket.identify(visitorId, {
                        name: user.name || '',
                        email: user.email || 'Unknown email',

                        // Add your own custom user variables here, ie:
                        // subscriptionType: 'pro'
                    });

                    try {
                        if (
                            process.env.REACT_APP_FS_ENVIRONMENT ===
                                'production' ||
                            process.env.REACT_APP_FS_ENVIRONMENT === 'demo'
                        ) {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            FS.identify(user.email, {
                                name: user.name,
                                companyId: user.companyId,
                                companyName: user.companyName,
                                email: user.email,
                                displayName: user.name,
                            });
                        }
                    } catch (e) {
                        console.error('Error identifying fullstory', e);
                    }

                    try {
                        datadogRum.setUser({
                            id: user.userId,
                            name: user.name,
                            email: user.email,
                        });
                    } catch (e) {
                        console.error('Error identifying datadog', e);
                    }
                } else {
                    boot();
                    LogRocket.identify(visitorId);
                }

                if (LogRocketProject != undefined) {
                    update({
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        logrocketURL: `https://app.logrocket.com/${LogRocketProject}/sessions?u=${visitorId}`,
                    });
                }
            }
        }, 1000);
    }, [isAuthenticated]);

    function getBackgroundColor() {
        if (isAuthenticated) {
            return Colors.SubtleBlueBackground;
        } else {
            return Colors.White;
        }
    }

    function getBorderStyle() {
        if (isAuthenticated) {
            return `1px solid ${Colors.VeryVeryLightBlue}`;
        } else {
            return `none`;
        }
    }

    return (
        <>
            <div
                style={{
                    backgroundColor: getBackgroundColor(),
                    borderBottom: getBorderStyle(),
                }}
            >
                <Spacer height={4} />
                <HorizontalStack
                    align="spread"
                    width="100%"
                    padding={`0 ${paddingWidth}px 0 ${paddingWidth}px`}
                >
                    <NavLeftSide />
                    <NavRightSide />
                </HorizontalStack>
                <Spacer height={4} />
            </div>

            {isAuthenticated && (
                <div
                    style={{
                        paddingLeft: `${paddingWidth}px`,
                        paddingRight: `${paddingWidth}px`,
                    }}
                >
                    <SubNavBar />
                </div>
            )}
        </>
    );
};

export default NavBar;
