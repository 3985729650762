import Link from 'Cargo/Controls/Link';
import {
    NormalQuestionBubble,
    QuestionBubbleHeader,
    QuestionBubbleLabel,
} from 'Cargo/Controls/QuestionBubble';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import React from 'react';

function DockHoursQuestionBubble() {
    return (
        <NormalQuestionBubble
            description={
                <>
                    <QuestionBubbleHeader>Dock Hours</QuestionBubbleHeader>
                    <QuestionBubbleLabel>
                        <Stack align="left">
                            Please put the full hours when your dock will be
                            open. If you have a preference for, say, morning
                            delivery please add a note below instead.
                            <Spacer height={16} />
                            <Link to="https://help.freightsimple.com/using-freightsimple/booking-your-shipment/pickup-and-delivery-addresses-and-hours#hours">
                                Learn more
                            </Link>
                        </Stack>
                    </QuestionBubbleLabel>
                </>
            }
        />
    );
}
export default DockHoursQuestionBubble;
