import Colors from 'Cargo/Colors';
import LinkButton from 'Cargo/Controls/LinkButton';
import ThreeDotMenu from 'Cargo/Controls/ThreeDotMenu';
import Icon from 'Cargo/Icons/Icon';
import Box from 'Cargo/Layout/Box';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { Broker, PreferredBrokerType } from 'generated-openapi-client';
import { formatPhone } from 'Helpers/formatPhone';
import { fullStateOrProvinceName } from 'Helpers/fullStateOrProvinceName';
import React from 'react';
import styled from 'styled-components/macro';
import PreferredBrokerPill from './PreferredBrokerPill';

const BusinessName = styled.div`
    font-weight: var(--nhu-font-weight-medium);
    font-size: 18px;
    color: var(--freightsimple-color-normal-text);
`;

const SectionLabel = styled.div`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 12px;
    color: var(--freightsimple-color-normal-text);
    height: 16px;
`;

const SectionLine = styled.div`
    font-weight: var(--nhu-font-weight-light);
    font-size: 12px;
    color: var(--freightsimple-color-light-text);
`;

interface BrokerBoxProps {
    broker: Broker;
    onModify: () => void;
    showMenu: boolean;
    onDelete?: () => void;
    preferredNorthbound: boolean;
    preferredSouthbound: boolean;
    onSelectPreferred?: (preferredType: PreferredBrokerType) => void;
}
function BrokerBox(props: BrokerBoxProps) {
    const { businessName, address, contact } = props.broker;
    const { preferredNorthbound, preferredSouthbound } = props;

    function generateSelectedIndex() {
        if (preferredNorthbound && preferredSouthbound) {
            return 0;
        }

        if (preferredNorthbound) {
            return 1;
        }

        if (preferredSouthbound) {
            return 2;
        }

        return 3;
    }

    function onSelectIndex(index: number) {
        if (props.onSelectPreferred === undefined) {
            return;
        }

        if (index === 0) {
            return props.onSelectPreferred(PreferredBrokerType.All);
        }

        if (index === 1) {
            return props.onSelectPreferred(PreferredBrokerType.Northbound);
        }

        if (index === 2) {
            return props.onSelectPreferred(PreferredBrokerType.Southbound);
        }

        if (index === 3) {
            return props.onSelectPreferred(PreferredBrokerType.None);
        }
    }

    return (
        <Box width={420} style={{ height: '260px', padding: '32px' }}>
            <HorizontalStack verticalAlign="top" style={{ height: '100%' }}>
                <Stack align="left">
                    <HorizontalStack align="spread" width="100%">
                        <HorizontalStack>
                            <Icon
                                name="globe-americas"
                                color={Colors.Blue}
                                size={20}
                                solid
                                style={{
                                    marginRight: '8px',
                                    position: 'relative',
                                    top: '2px',
                                }}
                            />
                            <BusinessName>{businessName}</BusinessName>
                        </HorizontalStack>

                        {props.showMenu && (
                            <ThreeDotMenu
                                items={[
                                    {
                                        name: 'Modify',
                                        action: function () {
                                            props.onModify();
                                        },
                                    },
                                    {
                                        name: 'Divider',
                                        action: function () {},
                                    },
                                    {
                                        name: 'Mark Preferred For',
                                        subitems: [
                                            'All Shipments',
                                            'United States Pickups',
                                            'Canada Pickups',
                                            'None',
                                        ],
                                        selectedIndex: generateSelectedIndex(),
                                        action: function (
                                            selectedIndex: number
                                        ) {
                                            onSelectIndex(selectedIndex);
                                        },
                                    },
                                    {
                                        name: 'Divider',
                                        action: function () {},
                                    },
                                    {
                                        name: 'Delete',
                                        action: function () {
                                            if (props.onDelete !== undefined) {
                                                props.onDelete();
                                            }
                                        },
                                    },
                                ]}
                            />
                        )}
                        {!props.showMenu && (
                            <LinkButton onClick={props.onModify}>
                                Modify
                            </LinkButton>
                        )}
                    </HorizontalStack>
                    <Spacer height={32} />
                    <PreferredBrokerPill
                        preferredNorthbound={preferredNorthbound}
                        preferredSouthbound={preferredSouthbound}
                    />
                    <Spacer height={16} />
                    <HorizontalStack width="100%" verticalAlign="top">
                        <Stack align="left" width="174px">
                            <SectionLabel>Address</SectionLabel>
                            <SectionLine>{address.addressLine}</SectionLine>
                            {address.addressLine2 && (
                                <SectionLine>
                                    {address.addressLine2}
                                </SectionLine>
                            )}
                            <SectionLine>{address.city}</SectionLine>
                            <SectionLine>
                                {fullStateOrProvinceName(
                                    address.stateOrProvinceCode
                                )}
                            </SectionLine>
                            <SectionLine>{address.postalCode}</SectionLine>
                        </Stack>

                        <Stack align="left" width="180px">
                            <SectionLabel>Contact</SectionLabel>
                            <SectionLine>{contact.contactName}</SectionLine>
                            <SectionLine>{contact.emailAddress}</SectionLine>
                            <SectionLine>
                                {formatPhone(
                                    contact.phoneNumber,
                                    contact.phoneNumberExtension
                                )}
                            </SectionLine>
                        </Stack>
                    </HorizontalStack>
                </Stack>
            </HorizontalStack>
        </Box>
    );
}
export default BrokerBox;
