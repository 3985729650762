/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SavedLocation } from './SavedLocation';
import {
    SavedLocationFromJSON,
    SavedLocationFromJSONTyped,
    SavedLocationToJSON,
} from './SavedLocation';

/**
 * 
 * @export
 * @interface GetBranchesResponse
 */
export interface GetBranchesResponse {
    /**
     * 
     * @type {Array<SavedLocation>}
     * @memberof GetBranchesResponse
     */
    items: Array<SavedLocation>;
    /**
     * 
     * @type {string}
     * @memberof GetBranchesResponse
     */
    currentUserBranchId?: string;
}

/**
 * Check if a given object implements the GetBranchesResponse interface.
 */
export function instanceOfGetBranchesResponse(value: object): boolean {
    if (!('items' in value)) return false;
    return true;
}

export function GetBranchesResponseFromJSON(json: any): GetBranchesResponse {
    return GetBranchesResponseFromJSONTyped(json, false);
}

export function GetBranchesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBranchesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(SavedLocationFromJSON)),
        'currentUserBranchId': json['currentUserBranchId'] == null ? undefined : json['currentUserBranchId'],
    };
}

export function GetBranchesResponseToJSON(value?: GetBranchesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(SavedLocationToJSON)),
        'currentUserBranchId': value['currentUserBranchId'],
    };
}

