import Input from 'Cargo/Controls/Input';
import PhoneInput from 'Cargo/Controls/PhoneInput';
import DynamicHeightSpacer from 'Cargo/Layout/DynamicSpacer';
import Stack from 'Cargo/Layout/Stack';
import { Label } from 'Cargo/Text/Text';
import { CreateAccountErrorMessages } from 'Features/Authentication/Hooks/useCreateAccount';

interface CreateAccountPageNameAndPhoneNumberProps {
    name: string;
    setName: (_: string) => void;
    phoneNumber: string;
    setPhoneNumber: (_: string) => void;
    phoneNumberExtension: string;
    setPhoneNumberExtension: (_: string) => void;
    forceValidation: boolean;
    errorMessages: CreateAccountErrorMessages;
}

export function CreateAccountPageNameAndPhoneNumber(
    props: CreateAccountPageNameAndPhoneNumberProps
) {
    const {
        name,
        setName,
        phoneNumber,
        setPhoneNumber,
        phoneNumberExtension,
        setPhoneNumberExtension,
        forceValidation,
        errorMessages,
    } = props;

    return (
        <Stack align="left" style={{ width: '100%' }}>
            <Label>What is your full name?</Label>
            <Input
                type="text"
                value={name}
                onChange={(value) => setName(value)}
                errorMessage={errorMessages.name}
                forceValidation={forceValidation}
                name="signup_name"
            />
            <DynamicHeightSpacer maxHeight={18} minHeight={4} />
            <Label>What is your direct phone number?</Label>
            <PhoneInput
                phoneNumber={phoneNumber}
                extension={phoneNumberExtension}
                onPhoneNumberChange={(value: string) => {
                    setPhoneNumber(value);
                }}
                onExtensionChange={(value: string) => {
                    setPhoneNumberExtension(value);
                }}
                phoneNumberErrorMessage={errorMessages.phoneNumber}
                extensionErrorMessage={undefined}
                forceValidation={forceValidation}
                preferPlaceholder
            />
        </Stack>
    );
}
