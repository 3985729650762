import { ErrorMessageType } from 'Cargo/Validation';
import { Address } from 'generated-openapi-client';
import { errorMessageForPostalCode } from './errorMessageForPostalCode';
import { errorMessageForRequiredField } from './errorMessageForRequiredField';

export interface AddressErrors {
    addressLine: ErrorMessageType;
    addressLine2: ErrorMessageType;
    city: ErrorMessageType;
    postalCode: ErrorMessageType;
    stateOrProvinceCode: ErrorMessageType;
    country: ErrorMessageType;
}

export function errorMessageForAddressLine1(
    addressLine1: string | undefined
): ErrorMessageType {
    if (addressLine1 === undefined || addressLine1 === '') {
        return 'Required';
    }
}

export function errorMessagesForAddress(
    address: Partial<Address>
): AddressErrors {
    return {
        addressLine: errorMessageForAddressLine1(address.addressLine),
        addressLine2: undefined,
        city: errorMessageForRequiredField(address.city),
        stateOrProvinceCode: errorMessageForRequiredField(
            address.stateOrProvinceCode
        ),
        postalCode: errorMessageForPostalCode(address.postalCode, address.city),
        country: errorMessageForRequiredField(address.countryCode),
    };
}
