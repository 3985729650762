/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrackingLine
 */
export interface TrackingLine {
    /**
     * 
     * @type {string}
     * @memberof TrackingLine
     */
    message: string;
    /**
     * 
     * @type {Date}
     * @memberof TrackingLine
     */
    timestamp: Date;
}

/**
 * Check if a given object implements the TrackingLine interface.
 */
export function instanceOfTrackingLine(value: object): boolean {
    if (!('message' in value)) return false;
    if (!('timestamp' in value)) return false;
    return true;
}

export function TrackingLineFromJSON(json: any): TrackingLine {
    return TrackingLineFromJSONTyped(json, false);
}

export function TrackingLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrackingLine {
    if (json == null) {
        return json;
    }
    return {
        
        'message': json['message'],
        'timestamp': (new Date(json['timestamp'])),
    };
}

export function TrackingLineToJSON(value?: TrackingLine | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'message': value['message'],
        'timestamp': ((value['timestamp']).toISOString()),
    };
}

