/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LookupNmfcCodeForLineItemRequest
 */
export interface LookupNmfcCodeForLineItemRequest {
    /**
     * 
     * @type {string}
     * @memberof LookupNmfcCodeForLineItemRequest
     */
    nmfcItemNumber: string;
    /**
     * 
     * @type {number}
     * @memberof LookupNmfcCodeForLineItemRequest
     */
    length: number;
    /**
     * 
     * @type {number}
     * @memberof LookupNmfcCodeForLineItemRequest
     */
    width: number;
    /**
     * 
     * @type {number}
     * @memberof LookupNmfcCodeForLineItemRequest
     */
    height: number;
    /**
     * 
     * @type {number}
     * @memberof LookupNmfcCodeForLineItemRequest
     */
    weightPerHandlingUnit: number;
}

/**
 * Check if a given object implements the LookupNmfcCodeForLineItemRequest interface.
 */
export function instanceOfLookupNmfcCodeForLineItemRequest(value: object): boolean {
    if (!('nmfcItemNumber' in value)) return false;
    if (!('length' in value)) return false;
    if (!('width' in value)) return false;
    if (!('height' in value)) return false;
    if (!('weightPerHandlingUnit' in value)) return false;
    return true;
}

export function LookupNmfcCodeForLineItemRequestFromJSON(json: any): LookupNmfcCodeForLineItemRequest {
    return LookupNmfcCodeForLineItemRequestFromJSONTyped(json, false);
}

export function LookupNmfcCodeForLineItemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LookupNmfcCodeForLineItemRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'nmfcItemNumber': json['nmfcItemNumber'],
        'length': json['length'],
        'width': json['width'],
        'height': json['height'],
        'weightPerHandlingUnit': json['weightPerHandlingUnit'],
    };
}

export function LookupNmfcCodeForLineItemRequestToJSON(value?: LookupNmfcCodeForLineItemRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'nmfcItemNumber': value['nmfcItemNumber'],
        'length': value['length'],
        'width': value['width'],
        'height': value['height'],
        'weightPerHandlingUnit': value['weightPerHandlingUnit'],
    };
}

