import { SavedLocation } from 'generated-openapi-client/models/SavedLocation';

export function errorMessageForBranches(
    branches: SavedLocation[],
    branchId: undefined | string
) {
    console.log(`@@@ errorMessageForBranches`, { branches, branchId });
    // No branches, so can't be an error
    if (branches.length === 0) {
        return undefined;
    }

    if (branches.length > 0 && branchId === undefined) {
        console.log(`@@@ Required`);
        return 'Required';
    }

    console.log(`@@@ errorMessageForBranches returning undefined`);
    return undefined;
}
