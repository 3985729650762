import Button from 'Cargo/Controls/Button';
import DockHoursInput from 'Cargo/Controls/DockHoursInput';
import { TextArea } from 'Cargo/Controls/Textarea';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { Label, Microcopy } from 'Cargo/Text/Text';
import NotesQuestionBubble from 'Features/BookShipment/Components/Addresses/QuestionBubbles/NotesQuestionBubble';
import { errorMessagesForLocation } from 'Features/Locations/Validators/errorMessagesForLocation';
import React, { ChangeEvent, useState } from 'react';
import { AddLocationLocationType } from '../Types/locationTypes';

interface AddLocationModalOtherPage {
    location: AddLocationLocationType;
    setLocation: (_: AddLocationLocationType) => void;
    onDone: () => void;
    onPrevious: () => void;
}

function AddLocationModalOtherPage(props: AddLocationModalOtherPage) {
    const { location, setLocation, onDone, onPrevious } = props;
    const [forceValidation, setForceValidation] = useState(false);

    // TODO: False
    const locationErrorMessages = errorMessagesForLocation(location, false);

    function setNotes(notes: string) {
        setLocation({
            ...location,
            notes,
        });
    }

    function setHoursOpenFrom(hoursFrom: string) {
        setLocation({
            ...location,
            hours: {
                openFrom: hoursFrom,
                openUntil: location.hours.openUntil,
            },
        });
    }

    function setHoursOpenUntil(hoursUntil: string) {
        setLocation({
            ...location,
            hours: {
                openFrom: location.hours.openFrom,
                openUntil: hoursUntil,
            },
        });
    }

    function isValid() {
        if (locationErrorMessages.hours !== undefined) {
            return false;
        }

        return true;
    }

    async function onNext() {
        if (isValid()) {
            onDone();
        } else {
            setForceValidation(true);
        }
    }

    return (
        <>
            <Microcopy>Please enter other details</Microcopy>
            <Stack align="left">
                <>
                    <Spacer height={16} />

                    <Label>Dock Hours</Label>
                    <DockHoursInput
                        openFrom={props.location.hours?.openFrom}
                        openUntil={props.location.hours?.openUntil}
                        setOpenFrom={setHoursOpenFrom}
                        setOpenUntil={setHoursOpenUntil}
                        errorMessage={locationErrorMessages.hours}
                        forceValidation={forceValidation}
                    />
                    <Spacer height={32} />
                    <Label>
                        Notes
                        <NotesQuestionBubble />
                    </Label>
                    <TextArea
                        rows={4}
                        style={{ width: '520px' }}
                        value={props.location.notes}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                            setNotes(e.currentTarget.value);
                        }}
                        name="notes"
                        autoComplete="off"
                    />
                    <Spacer height={32} />
                    <HorizontalStack width="100%" align="spread">
                        <Button onClick={onPrevious} secondary>
                            Previous
                        </Button>
                        <Button onClick={onNext}>Done</Button>
                    </HorizontalStack>
                </>
            </Stack>
        </>
    );
}

export default AddLocationModalOtherPage;
