/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Broker } from './Broker';
import {
    BrokerFromJSON,
    BrokerFromJSONTyped,
    BrokerToJSON,
} from './Broker';

/**
 * 
 * @export
 * @interface ModifySavedBrokerRequest
 */
export interface ModifySavedBrokerRequest {
    /**
     * 
     * @type {string}
     * @memberof ModifySavedBrokerRequest
     */
    savedBrokerId: string;
    /**
     * 
     * @type {Broker}
     * @memberof ModifySavedBrokerRequest
     */
    broker: Broker;
}

/**
 * Check if a given object implements the ModifySavedBrokerRequest interface.
 */
export function instanceOfModifySavedBrokerRequest(value: object): boolean {
    if (!('savedBrokerId' in value)) return false;
    if (!('broker' in value)) return false;
    return true;
}

export function ModifySavedBrokerRequestFromJSON(json: any): ModifySavedBrokerRequest {
    return ModifySavedBrokerRequestFromJSONTyped(json, false);
}

export function ModifySavedBrokerRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModifySavedBrokerRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'savedBrokerId': json['savedBrokerId'],
        'broker': BrokerFromJSON(json['broker']),
    };
}

export function ModifySavedBrokerRequestToJSON(value?: ModifySavedBrokerRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'savedBrokerId': value['savedBrokerId'],
        'broker': BrokerToJSON(value['broker']),
    };
}

