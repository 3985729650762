/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BankAccount } from './BankAccount';
import {
    BankAccountFromJSON,
    BankAccountFromJSONTyped,
    BankAccountToJSON,
} from './BankAccount';
import type { CreditCard } from './CreditCard';
import {
    CreditCardFromJSON,
    CreditCardFromJSONTyped,
    CreditCardToJSON,
} from './CreditCard';

/**
 * 
 * @export
 * @interface GetAllPaymentMethodsResponse
 */
export interface GetAllPaymentMethodsResponse {
    /**
     * 
     * @type {Array<CreditCard>}
     * @memberof GetAllPaymentMethodsResponse
     */
    creditCards: Array<CreditCard>;
    /**
     * 
     * @type {Array<BankAccount>}
     * @memberof GetAllPaymentMethodsResponse
     */
    bankAccounts: Array<BankAccount>;
    /**
     * 
     * @type {boolean}
     * @memberof GetAllPaymentMethodsResponse
     */
    isEtransferPaymentPermitted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAllPaymentMethodsResponse
     */
    isChequePaymentPermitted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAllPaymentMethodsResponse
     */
    isWirePaymentPermitted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetAllPaymentMethodsResponse
     */
    isEftPaymentPermitted: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAllPaymentMethodsResponse
     */
    defaultPaymentMethod?: string;
}

/**
 * Check if a given object implements the GetAllPaymentMethodsResponse interface.
 */
export function instanceOfGetAllPaymentMethodsResponse(value: object): boolean {
    if (!('creditCards' in value)) return false;
    if (!('bankAccounts' in value)) return false;
    if (!('isEtransferPaymentPermitted' in value)) return false;
    if (!('isChequePaymentPermitted' in value)) return false;
    if (!('isWirePaymentPermitted' in value)) return false;
    if (!('isEftPaymentPermitted' in value)) return false;
    return true;
}

export function GetAllPaymentMethodsResponseFromJSON(json: any): GetAllPaymentMethodsResponse {
    return GetAllPaymentMethodsResponseFromJSONTyped(json, false);
}

export function GetAllPaymentMethodsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAllPaymentMethodsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'creditCards': ((json['creditCards'] as Array<any>).map(CreditCardFromJSON)),
        'bankAccounts': ((json['bankAccounts'] as Array<any>).map(BankAccountFromJSON)),
        'isEtransferPaymentPermitted': json['isEtransferPaymentPermitted'],
        'isChequePaymentPermitted': json['isChequePaymentPermitted'],
        'isWirePaymentPermitted': json['isWirePaymentPermitted'],
        'isEftPaymentPermitted': json['isEftPaymentPermitted'],
        'defaultPaymentMethod': json['defaultPaymentMethod'] == null ? undefined : json['defaultPaymentMethod'],
    };
}

export function GetAllPaymentMethodsResponseToJSON(value?: GetAllPaymentMethodsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'creditCards': ((value['creditCards'] as Array<any>).map(CreditCardToJSON)),
        'bankAccounts': ((value['bankAccounts'] as Array<any>).map(BankAccountToJSON)),
        'isEtransferPaymentPermitted': value['isEtransferPaymentPermitted'],
        'isChequePaymentPermitted': value['isChequePaymentPermitted'],
        'isWirePaymentPermitted': value['isWirePaymentPermitted'],
        'isEftPaymentPermitted': value['isEftPaymentPermitted'],
        'defaultPaymentMethod': value['defaultPaymentMethod'],
    };
}

