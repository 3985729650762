import Dropdown from 'Cargo/Controls/Dropdown';
import { getCarriers } from 'Data/Carriers';

interface CarriersDropdownProps {
    carrierIdentifier: string | undefined;
    onCarrierIdentifierChanged: (
        updatedCarrierIdentifier: string | undefined
    ) => void;
}
function CarriersDropdown(props: CarriersDropdownProps) {
    const options = getCarriers()
        .filter((c) => c.identifier !== 'dummy')
        .map(function (carrier) {
            return {
                item: carrier.identifier,
                description: carrier.displayName,
            };
        });

    const fullOptions = [
        { item: 'any-carrier', description: <>Any carrier</> },
        ...options,
    ];

    return (
        <Dropdown
            unselectedDescription="Any carrier"
            selectedItem={props.carrierIdentifier}
            onOptionSelected={function (newCarrier: string) {
                if (newCarrier === 'any-carrier') {
                    props.onCarrierIdentifierChanged(undefined);
                } else {
                    props.onCarrierIdentifierChanged(newCarrier);
                }
            }}
            options={fullOptions}
            width={200}
            errorMessage={undefined}
            forceValidation={false}
            placeholder="Carrier"
        />
    );
}
export default CarriersDropdown;
