import { usePostalCodesApi } from 'apis';
import { Address, PreQuoteLocation } from 'generated-openapi-client';
import { useState } from 'react';
import { useOnce } from 'Hooks/useOnce';

export function useAddressOptions(
    location: PreQuoteLocation,
    updateAddress: (addressUpdate: Partial<Address>) => void
) {
    const [addressOptions, setAddressOptions] = useState<Array<string>>([]);
    const postalCodesApi = usePostalCodesApi();

    const postalCode = location.address.postalCode;

    useOnce(async () => {
        if (!postalCode) {
            throw new Error('Missing postalCode');
        }
        const _addressOptions =
            await postalCodesApi.getPostalCodesLookUpPotentialAddresses({
                postalCode,
            });
        setAddressOptions(_addressOptions);

        if (
            _addressOptions.length === 1 &&
            (location.address.addressLine === '' ||
                location.address.addressLine === undefined)
        ) {
            updateAddress({ addressLine: _addressOptions[0] });
        }
    });

    return { addressOptions };
}
