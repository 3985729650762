/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PaymentMethod } from './PaymentMethod';
import {
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
} from './PaymentMethod';

/**
 * 
 * @export
 * @interface PayInvoicesRequest
 */
export interface PayInvoicesRequest {
    /**
     * 
     * @type {PaymentMethod}
     * @memberof PayInvoicesRequest
     */
    paymentMethod: PaymentMethod;
    /**
     * 
     * @type {string}
     * @memberof PayInvoicesRequest
     */
    paymentMethodId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PayInvoicesRequest
     */
    invoiceIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PayInvoicesRequest
     */
    paymentReference: string;
}

/**
 * Check if a given object implements the PayInvoicesRequest interface.
 */
export function instanceOfPayInvoicesRequest(value: object): boolean {
    if (!('paymentMethod' in value)) return false;
    if (!('invoiceIds' in value)) return false;
    if (!('paymentReference' in value)) return false;
    return true;
}

export function PayInvoicesRequestFromJSON(json: any): PayInvoicesRequest {
    return PayInvoicesRequestFromJSONTyped(json, false);
}

export function PayInvoicesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayInvoicesRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'paymentMethod': PaymentMethodFromJSON(json['paymentMethod']),
        'paymentMethodId': json['paymentMethodId'] == null ? undefined : json['paymentMethodId'],
        'invoiceIds': json['invoiceIds'],
        'paymentReference': json['paymentReference'],
    };
}

export function PayInvoicesRequestToJSON(value?: PayInvoicesRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'paymentMethod': PaymentMethodToJSON(value['paymentMethod']),
        'paymentMethodId': value['paymentMethodId'],
        'invoiceIds': value['invoiceIds'],
        'paymentReference': value['paymentReference'],
    };
}

