import Button from 'Cargo/Controls/Button';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import LargePrice from 'Cargo/Text/LargePrice';
import { useCoupon } from 'Features/Coupons/Hooks/useCoupon';
import { AddLineItemType } from 'Features/LineItems/Types/lineItemTypes';
import { AddLocationLocationType } from 'Features/Locations/Types/locationTypes';
import { formatPrice } from 'Helpers/formatPrice';
import { isDomesticCanada } from 'Helpers/isDomesticCanada';
import {
    LineItem,
    Location,
    PreQuoteLocation,
    Quote,
    ShipmentState,
} from 'generated-openapi-client';
import { Currency } from 'generated-openapi-client/models/Currency';
import moment from 'moment';
import styled from 'styled-components/macro';
import DeliveryColumn from './DeliveryColumn';
import PickupColumn from './PickupColumn';
import Progress from './Progress';
import TransitColumn from './TransitColumn';

const ServiceType = styled.div`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 24px;
    color: var(--freightsimple-color-normal-text);
`;
const DeliveryDateDescription = styled.div`
    font-weight: var(--nhu-font-weight-regular);
    font-size: 18px;
    color: var(--freightsimple-color-light-text);
`;

const TaxDescription = styled.div`
    font-weight: var(--nhu-font-weight-light);
    font-size: 12px;
    color: var(--freightsimple-color-normal-text);
    text-align: right;
    line-height: 1;
`;

interface QuoteRowBodyProps {
    pickupDate: moment.Moment;
    pickupDeadline: string | undefined;
    pickupLocation: Location | AddLocationLocationType | PreQuoteLocation;
    lineItems: Array<LineItem | AddLineItemType>;
    deliveryLocation: Location | AddLocationLocationType | PreQuoteLocation;
    addInsuranceToShipment: boolean;
    insuranceAmount: number;
    insuranceCurrency: Currency;
    deliveryDeadline: string | undefined;
    quote: Quote;
    onSelectQuote: () => void;
    notes: string | undefined;
}
function QuoteRowBody(props: QuoteRowBodyProps) {
    const { couponAmount } = useCoupon();
    const { quote, pickupLocation, deliveryLocation, pickupDate, lineItems, pickupDeadline } =
        props;

    const serviceType = quote.serviceDisplayName;

    const expectedDeliveryDateLabel = 'Earliest Delivery';

    const expectedDeliveryDateMoment = quote.expectedDeliveryDate
        ? moment(quote.expectedDeliveryDate)
        : undefined;

    const formattedDeliveryDate =
        expectedDeliveryDateMoment?.format('dddd Do MMMM YYYY') || 'Unknown';

    function describeTransitDays(
        earliestTransitDays: number | undefined,
        latestTransitDays: number | undefined
    ) {
        if (earliestTransitDays === undefined) {
            return undefined;
        } else {
            if (
                latestTransitDays == undefined ||
                latestTransitDays === earliestTransitDays
            ) {
                if (earliestTransitDays === 1) {
                    return `${earliestTransitDays} Business Day`;
                } else {
                    return `${earliestTransitDays} Business Days`;
                }
            } else {
                return `${earliestTransitDays}-${latestTransitDays} Business Days`;
            }
        }
    }

    const transitDaysDescription = describeTransitDays(
        quote.transitBusinessDays,
        quote.latestTransitBusinessDays
    );
    const transitDaysLabel = transitDaysDescription
        ? `: ${transitDaysDescription}`
        : '';

    function taxDescription() {
        const couponDescription =
            couponAmount === undefined ? '' : ' (Coupon Applied)';
        if (quote.taxAmount === 0) {
            return `Inclusive of all taxes${couponDescription}`;
        } else {
            const beforeTax = formatPrice(quote.price - quote.taxAmount);
            return `Total before tax : $${beforeTax}${couponDescription}`;
        }
    }

    return (
        <Stack align="left" width="100%">
            <HorizontalStack width="100%" align="spread">
                <Stack align="left">
                    <ServiceType>
                        {serviceType} {transitDaysLabel}
                    </ServiceType>
                    <DeliveryDateDescription>
                        {expectedDeliveryDateLabel}: {formattedDeliveryDate}
                    </DeliveryDateDescription>
                </Stack>
                <Stack align="right">
                    <LargePrice
                        price={props.quote.price}
                        currency={props.quote.currency}
                    />
                    <Spacer height={4} />
                    <TaxDescription>{taxDescription()}</TaxDescription>
                </Stack>
            </HorizontalStack>
            <Spacer height={16}></Spacer>
            <HorizontalStack width="100%" align="right">
                <Button
                    size="large"
                    onClick={props.onSelectQuote}
                    className="selectQuote"
                >
                    Select Quote
                </Button>
            </HorizontalStack>
            <Spacer height={48}></Spacer>
            <Progress shipmentState={ShipmentState.Quoted} />
            <Spacer height={16}></Spacer>
            <HorizontalStack width="100%" verticalAlign="top">
                <PickupColumn
                    pickupDeadline={pickupDeadline}
                    lineItems={lineItems}
                    pickupDate={pickupDate}
                    pickupLocation={pickupLocation}
                    pickupContact={undefined}
                    quote={quote}
                    shipmentState={ShipmentState.Quoted}
                    isDomesticCanada={isDomesticCanada(
                        pickupLocation,
                        deliveryLocation
                    )}
                    showNotesSection={false}
                />
                <TransitColumn
                    quote={quote}
                    shipmentState={ShipmentState.Quoted}
                    proNumber={undefined}
                    broker={undefined}
                    addInsuranceToShipment={props.addInsuranceToShipment}
                    insuranceAmount={props.insuranceAmount}
                    insuranceCurrency={props.insuranceCurrency}
                />
                <DeliveryColumn
                    quote={quote}
                    deliveryHours={undefined}
                    deliveryLocation={deliveryLocation}
                    deliveryContact={undefined}
                    shipmentState={ShipmentState.Quoted}
                    actualDeliveryDate={undefined}
                    actualDeliveryTime={undefined}
                    deliveryDeadline={props.deliveryDeadline}
                    expectedDeliveryDate={quote.expectedDeliveryDate}
                    latestExpectedDeliveryDate={
                        quote.latestEstimatedDeliveryDate
                    }
                    showNotesSection={false}
                />
            </HorizontalStack>
        </Stack>
    );
}
export default QuoteRowBody;
