import { useSiteMargin } from 'Cargo/Layout/DynamicSpacer';
import { AddLocationLocationType } from 'Features/Locations/Types/locationTypes';
import { ShipmentState } from 'generated-openapi-client';
import mapboxgl from 'mapbox-gl';
import React, { useEffect, useRef, useState } from 'react';
import { loadImages } from './Helpers/images';
import { useRenderPopups } from './Helpers/renderPopups';
import { setDeliveryLocation, setPickupLocation } from './Helpers/setLocations';
import { setupArc } from './Helpers/setupArc';
import { setupMarker } from './Helpers/setupMarker';
import { zoomMap } from './Helpers/zoomMap';

// https://github.com/alex3165/react-mapbox-gl/issues/931
// https://github.com/mapbox/mapbox-gl-js/issues/10173#issuecomment-753662795
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
mapboxgl.workerClass =
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

// This is our public key, so not a big deal being in the code
// since it's public on the website anyway
mapboxgl.accessToken =
    'pk.eyJ1IjoiY2hyaXN0b3BoZXJzdG90dCIsImEiOiJjazdmZnZ1eDIwMmR0M25wcnMxOWMzNTIzIn0.LBCxe4yd1d0SohHTSvzSmg';

interface ShipmentMapProps {
    pickup: Partial<AddLocationLocationType>;
    delivery: Partial<AddLocationLocationType>;

    shipmentState: ShipmentState;
}

function NotActualRoute() {
    return (
        <div
            style={{
                position: 'absolute',
                zIndex: 100,
                right: '10px',
                top: '5px',
            }}
        >
            Disclaimer: Not actual route ;)
        </div>
    );
}

export function ShipmentMap(props: ShipmentMapProps) {
    const [map, setMap] = useState<mapboxgl.Map | undefined>();
    const mapContainer = useRef<HTMLDivElement>(null);
    const requestRef = React.useRef<number | undefined>();
    const [mapLoaded, setMapLoaded] = useState(false);

    const offset = 0; // useDynamicHeight(0, 40);
    // This undoes the margin in app router
    const marginLeftOffset = useSiteMargin();

    const renderPopups = useRenderPopups();

    function setupLocations(
        map: mapboxgl.Map,
        pickup: Partial<AddLocationLocationType>,
        delivery: Partial<AddLocationLocationType>,
        shipmentState: ShipmentState
    ) {
        setPickupLocation(map, pickup, shipmentState);
        setDeliveryLocation(map, delivery, shipmentState);

        renderPopups(map, pickup, delivery);

        setupArc(map, pickup, delivery, shipmentState);

        zoomMap(map, pickup, delivery);

        setupMarker(map, pickup, delivery, shipmentState, requestRef);
    }

    async function setupLocationsWrapper(map: mapboxgl.Map) {
        let remaining = 3;

        // We sometimes get exceptions that the style is not loaded. I can't find a way to wait for mapbox to properly have styles loaded
        // So let's just catch and retry to work around it
        function retry(f: () => void) {
            try {
                f();
            } catch (e) {
                remaining--;
                if (remaining > 0) {
                    setTimeout(function () {
                        retry(f);
                    }, 500);
                } else {
                    console.error('Ran out of retries');
                }
            }
        }

        retry(function () {
            setupLocations(
                map,
                props.pickup,
                props.delivery,
                props.shipmentState
            );
        });
    }

    useEffect(() => {
        async function initializeMap() {
            const currentMapContainer = mapContainer.current;
            if (currentMapContainer == null) {
                return;
            }

            const mapToInitialize = new mapboxgl.Map({
                container: currentMapContainer,
                style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location
                center: [-90, 44],
                zoom: 3,
            });

            // Turn off all interactivity
            mapToInitialize.boxZoom.disable();
            mapToInitialize.scrollZoom.disable();
            mapToInitialize.dragRotate.disable();
            mapToInitialize.dragPan.disable();
            mapToInitialize.keyboard.disable();
            mapToInitialize.doubleClickZoom.disable();
            mapToInitialize.touchZoomRotate.disable();

            mapToInitialize.resize();

            await loadImages(mapToInitialize);

            setMapLoaded(true);
            setMap(mapToInitialize);
        }

        if (map === undefined) {
            initializeMap();
        }
    }, []);

    useEffect(() => {
        return function () {
            if (requestRef.current) {
                cancelAnimationFrame(requestRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (map === undefined) {
            return;
        }

        if (!mapLoaded) {
            map.on('load', function () {
                setupLocationsWrapper(map);
                setMapLoaded(true);
            });
        } else {
            setupLocationsWrapper(map);
        }
    }, [
        props.pickup.latitudeLongitude?.latitude,
        props.pickup.latitudeLongitude?.longitude,
        props.delivery.latitudeLongitude?.latitude,
        props.delivery.latitudeLongitude?.longitude,
        props.shipmentState,
        map,
    ]);

    function showNotActualRoute() {
        if (
            props.pickup.latitudeLongitude?.latitude &&
            props.delivery.latitudeLongitude?.latitude
        ) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            <div style={{ height: '292px' }}>
                <div
                    ref={mapContainer}
                    style={{
                        width: '100vw',
                        height: '292px',
                        position: 'absolute',
                        marginLeft: `-${marginLeftOffset}px`,
                        marginTop: `-${offset}px`,
                    }}
                >
                    {showNotActualRoute() && <NotActualRoute />}
                </div>
            </div>
        </>
    );
}
