import Container from 'react-bootstrap/Container';
import styled from 'styled-components/macro';

export default Container;

export const CenteredContainer = styled.div`
    margin-top: 180px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
`;

export const Centered = styled.div`
    margin: auto;
`;
