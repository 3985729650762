import Colors from 'Cargo/Colors';
import { useDynamicHeight } from 'Cargo/Layout/DynamicSpacer';
import { ReactNode } from 'react';
import styled from 'styled-components/macro';

export const ModalTitle = styled.div`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 24px;
    color: var(--freightsimple-color-normal-text);

    text-align: left;
`;

interface LabelProps {
    children: ReactNode;
}

interface InnerLabelProps {
    marginTopHeight: number;
    marginBottomHeight: number;
}

const InnerLabel = styled.div<InnerLabelProps>`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 16px;
    color: var(--freightsimple-color-normal-text);
    margin-bottom: ${(props) => props.marginBottomHeight}px;
    margin-top: ${(props) => props.marginTopHeight}px;
`;

export function Label(props: LabelProps) {
    const marginBottomHeight = useDynamicHeight(0, 5);
    const marginTopHeight = useDynamicHeight(4, 15);
    return (
        <InnerLabel
            marginTopHeight={marginTopHeight}
            marginBottomHeight={marginBottomHeight}
        >
            {props.children}
        </InnerLabel>
    );
}

export const Legalese = styled.div`
    color: var(--freightsimple-color-light-text);
    font-size: 14px;
`;

export const Microcopy = styled.div`
    color: var(--freightsimple-color-light-text);
    font-size: 16px;
`;

export const Block = styled.div`
    color: var(--freightsimple-color-dark-text);
    font-size: 14px;
`;

export const Subtitle = styled.div`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 18px;
    color: var(--freightsimple-color-normal-text);
`;

export const ErrorMessage = styled.div`
    font-weight: var(--nhu-font-weight-medium);
    font-size: 18px;
    color: var(--freightsimple-color-red);
    text-align: 'center';
`;

export const WarningMessage = styled.div`
    font-weight: var(--nhu-font-weight-medium);
    font-size: 18px;
    color: ${Colors.NormalText};
    text-align: 'center';
`;

export const Header = styled.div`
    font-weight: var(--nhu-font-weight-regular);
    font-size: 24px;
    color: var(--freightsimple-color-normal-text);
    text-align: center;
`;

export const Description = styled.div`
    font-weight: var(--nhu-font-weight-light);
    font-size: 18px;
    color: var(--freightsimple-color-normal-text);
    text-align: center;
`;

export const H0 = styled.h1`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 32px;
    color: var(--freightsimple-color-normal-text);
`;

export const H1 = styled.h1`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 28px;
    color: var(--freightsimple-color-normal-text);
`;

export const H2 = styled.h2`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 24px;
    color: var(--freightsimple-color-normal-text);
`;

export const H3 = styled.h2`
    font-weight: var(--nhu-font-weight-medium);
    font-size: 18px;
    color: var(--freightsimple-color-normal-text);
`;

export const Strong = styled.strong`
    font-weight: var(--nhu-font-weight-bold);
`;
