/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetDefaultContactRequest
 */
export interface SetDefaultContactRequest {
    /**
     * 
     * @type {string}
     * @memberof SetDefaultContactRequest
     */
    savedLocationId: string;
    /**
     * 
     * @type {string}
     * @memberof SetDefaultContactRequest
     */
    savedContactId: string;
}

/**
 * Check if a given object implements the SetDefaultContactRequest interface.
 */
export function instanceOfSetDefaultContactRequest(value: object): boolean {
    if (!('savedLocationId' in value)) return false;
    if (!('savedContactId' in value)) return false;
    return true;
}

export function SetDefaultContactRequestFromJSON(json: any): SetDefaultContactRequest {
    return SetDefaultContactRequestFromJSONTyped(json, false);
}

export function SetDefaultContactRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetDefaultContactRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'savedLocationId': json['savedLocationId'],
        'savedContactId': json['savedContactId'],
    };
}

export function SetDefaultContactRequestToJSON(value?: SetDefaultContactRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'savedLocationId': value['savedLocationId'],
        'savedContactId': value['savedContactId'],
    };
}

