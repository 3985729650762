/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LookUpReferenceNumberResponse
 */
export interface LookUpReferenceNumberResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof LookUpReferenceNumberResponse
     */
    shipmentIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof LookUpReferenceNumberResponse
     */
    isValid: boolean;
    /**
     * 
     * @type {string}
     * @memberof LookUpReferenceNumberResponse
     */
    message: string;
}

/**
 * Check if a given object implements the LookUpReferenceNumberResponse interface.
 */
export function instanceOfLookUpReferenceNumberResponse(value: object): boolean {
    if (!('shipmentIds' in value)) return false;
    if (!('isValid' in value)) return false;
    if (!('message' in value)) return false;
    return true;
}

export function LookUpReferenceNumberResponseFromJSON(json: any): LookUpReferenceNumberResponse {
    return LookUpReferenceNumberResponseFromJSONTyped(json, false);
}

export function LookUpReferenceNumberResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LookUpReferenceNumberResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentIds': json['shipmentIds'],
        'isValid': json['isValid'],
        'message': json['message'],
    };
}

export function LookUpReferenceNumberResponseToJSON(value?: LookUpReferenceNumberResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentIds': value['shipmentIds'],
        'isValid': value['isValid'],
        'message': value['message'],
    };
}

