import Input from 'Cargo/Controls/Input';
import PhoneInput from 'Cargo/Controls/PhoneInput';
import Form from 'Cargo/Layout/Form';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import { ContactErrors } from 'Features/Contacts/Validators/errorMessagesForContact';
import { Contact } from 'generated-openapi-client';
import React from 'react';

interface ContactInputProps {
    contactChanged: (_: Partial<Contact>) => void;
    contact: Partial<Contact>;
    errorMessages: ContactErrors | undefined;
    warningMessages: ContactErrors | undefined;
    forceValidation: boolean;
}

const ContactInput: React.FC<ContactInputProps> = (
    props: ContactInputProps
) => {
    return (
        <>
            <Form.Group>
                <HorizontalStack width="520px" verticalAlign="top">
                    <Input
                        type="text"
                        name="contact_contact_name"
                        label={`Contact Name`}
                        value={props.contact?.contactName || ''}
                        onChange={(value: string) =>
                            props.contactChanged({
                                contactName: value,
                            })
                        }
                        required
                        errorMessage={
                            props.errorMessages?.contactName ||
                            props.warningMessages?.contactName
                        }
                        warn={
                            props.errorMessages?.contactName === undefined &&
                            props.warningMessages?.contactName !== undefined
                        }
                        forceValidation={props.forceValidation}
                        width={520}
                    ></Input>
                </HorizontalStack>
                <HorizontalStack width="520px" verticalAlign="top">
                    <PhoneInput
                        phoneNumber={props.contact.phoneNumber}
                        extension={props.contact.phoneNumberExtension}
                        onPhoneNumberChange={(value: string) => {
                            props.contactChanged({ phoneNumber: value });
                        }}
                        onExtensionChange={(value: string) => {
                            props.contactChanged({
                                phoneNumberExtension: value,
                            });
                        }}
                        phoneNumberErrorMessage={
                            props.errorMessages?.phoneNumber
                        }
                        extensionErrorMessage={undefined}
                        forceValidation={props.forceValidation}
                    />

                    <Spacer width={20} />
                    <Input
                        type="email"
                        name="contact_email_address"
                        label={`Email Address`}
                        value={props.contact?.emailAddress || ''}
                        required
                        onChange={(value: string) =>
                            props.contactChanged({
                                emailAddress: value,
                            })
                        }
                        errorMessage={props.errorMessages?.emailAddress}
                        forceValidation={props.forceValidation}
                        width={250}
                    ></Input>
                </HorizontalStack>
            </Form.Group>
        </>
    );
};
export default ContactInput;
