import { useModal } from 'Cargo/Modal/useModal';
import AddCreditCardModal from 'Features/CreditCards/Modals/AddCreditCardModal';
import React from 'react';

function useAddCreditCardModal(callback: () => void): () => void {
    const showModal = useModal();

    function show() {
        showModal(
            (done) => {
                return (
                    <AddCreditCardModal
                        onCardAdded={() => {
                            done();
                        }}
                    />
                );
            },
            () => {
                callback();
            }
        );
    }
    return show;
}

export default useAddCreditCardModal;
