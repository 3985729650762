import moment from 'moment';

export function describeDateRelativeWithYear(date: string) {
    const m = moment(date).startOf('day');
    const now = moment().startOf('day');

    const diffDays = m.diff(now, 'days');

    if (diffDays === 0) {
        return 'Today';
    }

    if (diffDays === 1) {
        return 'Tomorrow';
    }

    if (diffDays === -1) {
        return 'Yesterday';
    }

    if (now.isoWeek() === m.isoWeek()) {
        return m.format('dddd');
    }

    const formatted = m.format('ddd Do MMM YYYY');

    return formatted.replace('Wednesday', 'Weds');
}

export function describeDateRelative(date: string) {
    const m = moment(date).startOf('day');
    const now = moment().startOf('day');

    const diffDays = m.diff(now, 'days');

    if (diffDays === 0) {
        return 'Today';
    }

    if (diffDays === 1) {
        return 'Tomorrow';
    }

    if (diffDays === -1) {
        return 'Yesterday';
    }

    if (now.isoWeek() === m.isoWeek()) {
        return m.format('dddd');
    }

    const formatted = m.format('dddd Do MMMM');

    return formatted.replace('Wednesday', 'Weds').replace('September', 'Sept');
}
