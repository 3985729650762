import {
    isHoliday,
    isHolidayForCountry,
} from 'freightsimple-utilities/dist/holidays/moment';
import { CountryCode } from 'generated-openapi-client';
import moment from 'moment';

export function isWeekend(d: moment.Moment) {
    return d.isoWeekday() === 6 || d.isoWeekday() === 7;
}

export function nextBusinessDay() {
    let d = moment();

    d = d.add(1, 'day');

    while (isWeekend(d) || isHoliday(d)) {
        d = d.add(1, 'day');
    }

    return d;
}

export function nextBusinessDayForCountry(country: CountryCode) {
    let d = moment();

    d = d.add(1, 'day');

    while (isWeekend(d) || isHolidayForCountry(d, country)) {
        d = d.add(1, 'day');
    }

    return d;
}
