import LinkButton from 'Cargo/Controls/LinkButton';
import Pill, { PillStyle } from 'Cargo/Controls/Pill';
import { ToolTipWrapper } from 'Cargo/Controls/QuestionBubble';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { InvoiceWarningState } from 'generated-openapi-client';
import { useInvoicesStats } from '../Hooks/useInvoicesStats';

function CloseToCreditLimit() {
    return (
        <>
            <ToolTipWrapper
                description={
                    <Stack align="left">
                        <div>
                            You are close to your credit limit. Booking will be
                            disabled when limit is exceeded.
                        </div>
                        <Spacer height={8} />
                        <div>
                            <LinkButton to="/view-invoices">
                                View Invoices
                            </LinkButton>
                        </div>
                    </Stack>
                }
            >
                <Pill pillStyle={PillStyle.Yellow}>
                    <strong>Close to credit limit</strong>
                </Pill>
            </ToolTipWrapper>
            <Spacer width={8} />
        </>
    );
}

function InvoicesDueVerySoon() {
    return (
        <>
            <ToolTipWrapper
                description={
                    <Stack align="left">
                        <div>
                            There are invoices close to being overdue. Booking
                            will be disabled when invoices become overdue.
                        </div>
                        <Spacer height={8} />
                        <div>
                            <LinkButton to="/view-invoices">
                                View Invoices
                            </LinkButton>
                        </div>
                    </Stack>
                }
            >
                <Pill pillStyle={PillStyle.Yellow}>
                    <strong>Invoices close to due date</strong>
                </Pill>
            </ToolTipWrapper>
            <Spacer width={8} />
        </>
    );
}

function BookingDisabledCreditLimitExceeded() {
    return (
        <>
            <ToolTipWrapper
                description={
                    <Stack align="left">
                        <div>
                            You have exceeded your credit limit. Please pay
                            invoices to restore booking functionality
                        </div>
                        <Spacer height={8} />
                        <div>
                            <LinkButton to="/payments">
                                Make a payment
                            </LinkButton>
                        </div>
                    </Stack>
                }
            >
                <Pill pillStyle={PillStyle.Red}>
                    <strong>Booking Paused : Credit Limit Exceeded</strong>
                </Pill>
            </ToolTipWrapper>
            <Spacer width={8} />
        </>
    );
}

function BookingDisabledOverdueInvoices() {
    return (
        <>
            <ToolTipWrapper
                description={
                    <Stack align="left">
                        <div>
                            You have overdue invoices. Please pay immediately to
                            restore booking functionality.
                        </div>
                        <Spacer height={8} />
                        <div>
                            <LinkButton to="/view-invoices">
                                View Invoices
                            </LinkButton>
                        </div>
                    </Stack>
                }
            >
                <Pill pillStyle={PillStyle.Red}>
                    <strong>Booking Paused : Overdue Invoices</strong>
                </Pill>
            </ToolTipWrapper>
            <Spacer width={8} />
        </>
    );
}

function BookingDisabledNeedsAccountVerification() {
    return (
        <>
            <ToolTipWrapper
                description={
                    <Stack align="left">
                        <div>
                            Our team must verify your shipment before you are
                            able to book. Please contact us for assistance. You
                            can use the live chat functionality in the bottom
                            right of the screen, or send an email to
                            support@freightsimple.com
                        </div>
                    </Stack>
                }
            >
                <Pill pillStyle={PillStyle.Yellow}>
                    <strong>Account Verification Required</strong>
                </Pill>
            </ToolTipWrapper>
            <Spacer width={8} />
        </>
    );
}

interface InvoiceWarningProps {
    invoiceWarningState: InvoiceWarningState;
}

export function InvoiceWarning(props: InvoiceWarningProps) {
    const { invoiceWarningState } = props;
    if (
        invoiceWarningState ===
        InvoiceWarningState.BookingSuspendedDueToOverCreditLimit
    ) {
        return <BookingDisabledCreditLimitExceeded />;
    } else if (
        invoiceWarningState ===
        InvoiceWarningState.BookingSuspendedDueToOverdueInvoices
    ) {
        return <BookingDisabledOverdueInvoices />;
    } else if (
        invoiceWarningState ===
        InvoiceWarningState.BookingWarningCloseToCreditLimit
    ) {
        return <CloseToCreditLimit />;
    } else if (
        invoiceWarningState ===
        InvoiceWarningState.BookingWarningInvoicesCloseToOverdue
    ) {
        return <InvoicesDueVerySoon />;
    } else if (
        invoiceWarningState ===
        InvoiceWarningState.BookingSuspendedNeedsAccountVerification
    ) {
        return <BookingDisabledNeedsAccountVerification />;
    } else {
        return <></>;
    }
}

export function NavBarInvoiceWarning() {
    const invoiceStats = useInvoicesStats();

    if (invoiceStats === undefined) {
        return <></>;
    }

    return (
        <InvoiceWarning
            invoiceWarningState={invoiceStats.invoiceWarningState}
        />
    );
}
