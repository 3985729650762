/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NmfcSubItemChoice } from './NmfcSubItemChoice';
import {
    NmfcSubItemChoiceFromJSON,
    NmfcSubItemChoiceFromJSONTyped,
    NmfcSubItemChoiceToJSON,
} from './NmfcSubItemChoice';

/**
 * 
 * @export
 * @interface LookupNmfcCodeNoDimensionsResponse
 */
export interface LookupNmfcCodeNoDimensionsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof LookupNmfcCodeNoDimensionsResponse
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof LookupNmfcCodeNoDimensionsResponse
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof LookupNmfcCodeNoDimensionsResponse
     */
    errorMessage?: string;
    /**
     * 
     * @type {Array<NmfcSubItemChoice>}
     * @memberof LookupNmfcCodeNoDimensionsResponse
     */
    subItemChoices?: Array<NmfcSubItemChoice>;
}

/**
 * Check if a given object implements the LookupNmfcCodeNoDimensionsResponse interface.
 */
export function instanceOfLookupNmfcCodeNoDimensionsResponse(value: object): boolean {
    if (!('success' in value)) return false;
    return true;
}

export function LookupNmfcCodeNoDimensionsResponseFromJSON(json: any): LookupNmfcCodeNoDimensionsResponse {
    return LookupNmfcCodeNoDimensionsResponseFromJSONTyped(json, false);
}

export function LookupNmfcCodeNoDimensionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LookupNmfcCodeNoDimensionsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'success': json['success'],
        'description': json['description'] == null ? undefined : json['description'],
        'errorMessage': json['errorMessage'] == null ? undefined : json['errorMessage'],
        'subItemChoices': json['subItemChoices'] == null ? undefined : ((json['subItemChoices'] as Array<any>).map(NmfcSubItemChoiceFromJSON)),
    };
}

export function LookupNmfcCodeNoDimensionsResponseToJSON(value?: LookupNmfcCodeNoDimensionsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'success': value['success'],
        'description': value['description'],
        'errorMessage': value['errorMessage'],
        'subItemChoices': value['subItemChoices'] == null ? undefined : ((value['subItemChoices'] as Array<any>).map(NmfcSubItemChoiceToJSON)),
    };
}

