import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RotationContainer } from 'Cargo/Animations/RotationContainer';
import Colors from 'Cargo/Colors';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { useAuthentication } from 'Features/Authentication/Slices/authenticationSlice';
import React, { ReactNode, useState } from 'react';
import styled from 'styled-components/macro';

interface UserDetailsDropdownProps {
    name: string;
    company: string;
}

const NavBarUserDetailsName = styled.div`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 14px;
    color: var(--freightsimple-color-normal-text);
    text-align: right;
`;

const NavBarUserDetailsCompany = styled.div`
    font-weight: var(--nhu-font-weight-light);
    font-size: 12px;
    margin-top: -2px;
    color: var(--freightsimple-color-light-text);
    text-align: right;
`;

const MenuContainer = styled.div`
    min-width: 256px;
    padding: 0;
    position: absolute;
    right: 16px;
    z-index: 1000;
    margin-top: 16px;
    border: 1px solid ${Colors.VeryLightGray};
`;

const MenuItemBody = styled.div`
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 32px;
    padding-right: 32px;
    font-weight: var(--nhu-font-weight-bold);
    font-size: 14px;
    color: ${Colors.Blue};
    width: 100%;
    background-color: white;
    cursor: pointer;

    &:hover {
        background-color: ${Colors.Blue};
        color: white;
    }
`;

interface MenuItemProps {
    children: ReactNode;
    href?: string | undefined;
    onClick?: () => void | Promise<void>;
}

function MenuItem(props: MenuItemProps) {
    return (
        <a href={props.href} onClick={props.onClick} style={{ width: '100%' }}>
            <MenuItemBody>{props.children}</MenuItemBody>
        </a>
    );
}

function ButtonContent(props: UserDetailsDropdownProps & { show: boolean }) {
    return (
        <HorizontalStack verticalAlign="middle" align="spread">
            <Stack align="right">
                <NavBarUserDetailsName>{props.name}</NavBarUserDetailsName>
                <NavBarUserDetailsCompany>
                    {props.company}
                </NavBarUserDetailsCompany>
            </Stack>
            <Spacer width={12} />
            <RotationContainer open={props.show}>
                <FontAwesomeIcon
                    icon={['fas', 'chevron-down']}
                    style={{
                        width: '19px',
                        height: '19px',
                        color: Colors.Blue,
                        margin: 'auto',
                    }}
                />
            </RotationContainer>
        </HorizontalStack>
    );
}

interface MenuProps {
    show: boolean;
    companyName: string;
}

function Menu(props: MenuProps) {
    const { signOut } = useAuthentication();

    return (
        <>
            {props.show && (
                <MenuContainer>
                    <MenuItem onClick={signOut}>Sign Out</MenuItem>
                </MenuContainer>
            )}
        </>
    );
}

function UserDetailsDropdown(props: UserDetailsDropdownProps) {
    const [isInButton, setIsInButton] = useState(false);
    const [isInMenu, setIsInMenu] = useState(false);

    function enterButton() {
        setIsInButton(true);
    }
    function leaveButton() {
        setTimeout(function () {
            setIsInButton(false);
        }, 300);
    }

    function enterMenu() {
        setIsInMenu(true);
    }
    function leaveMenu() {
        setIsInMenu(false);
    }

    const show = isInButton || isInMenu;

    return (
        <div>
            <div
                onMouseEnter={enterButton}
                onMouseLeave={leaveButton}
                style={{ cursor: 'pointer' }}
            >
                <ButtonContent show={show} {...props} />
            </div>

            <div onMouseEnter={enterMenu} onMouseLeave={leaveMenu}>
                <Menu show={show} companyName={props.company} />
            </div>
        </div>
    );
}
export default UserDetailsDropdown;
