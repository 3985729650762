export function startsWithNumber(value: string): boolean {
    // Safe assuming upper case
    return /^[0-9]$/.test(value[0]);
}

export function formatPostalCode(value: string): string {
    // Strip whitespace
    let postalCode = value.replace(/\s/g, '').toUpperCase();

    if (startsWithNumber(postalCode)) {
        // Likely a zipcode so do nothing
        return postalCode;
    }

    if (postalCode.length > 3) {
        postalCode =
            postalCode.substring(0, 3) +
            ' ' +
            postalCode.substring(3, postalCode.length);
    }

    return postalCode;
}
