import { isProduction } from 'environment';
import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga';

export function initializeAnalytics(): void {
    if (!isProduction()) {
        return;
    }

    try {
        ReactGA.initialize('UA-183113933-1');
        mixpanel.init('7e3d84ac0a20442247d2cb258e54fa40');
    } catch (e) {
        console.warn(`initializeAnalytics exception`, { e });
    }
}

export function trackAnalyticsPageChange(pathName: string): void {
    if (!isProduction()) {
        return;
    }

    try {
        ReactGA.set({ page: pathName }); // Update the user's current page
        ReactGA.pageview(pathName); // Record a pageview for the given page
    } catch (e) {
        console.warn(`trackAnalyticsPageChange exception`, { e });
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function trackAnalyticsEvent(eventName: string, metadata?: any): void {
    if (!isProduction()) {
        return;
    }

    try {
        const parts = eventName.split('/');
        const category = parts[0];
        const action = parts[1];

        ReactGA.event({
            category,
            action,
        });
        mixpanel.track(eventName, metadata);
    } catch (e) {
        console.warn(`trackAnalyticsEvent exception`, { e });
    }
}

export function identifyAnalyticsUser(userId: string): void {
    if (!isProduction()) {
        return;
    }

    try {
        mixpanel.identify(userId);
        ReactGA.set({ userId: userId });
    } catch (e) {
        console.warn(`identifyMixpanelUser exception`, { e });
    }
}
