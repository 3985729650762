import {
    QuestionBubbleHeader,
    QuestionBubbleLabel,
    SmallQuestionBubble,
} from 'Cargo/Controls/QuestionBubble';
import Spacer from 'Cargo/Layout/Spacer';
import React from 'react';

function EstimatedEarliestDeliveryDateQuestionBubble() {
    return (
        <SmallQuestionBubble
            description={
                <>
                    <QuestionBubbleHeader>
                        Earliest Delivery Date
                    </QuestionBubbleHeader>
                    <QuestionBubbleLabel>
                        <div>
                            Most carriers provide an estimated earliest delivery
                            date when quoting. Assuming everything goes
                            perfectly, this is when your freight will deliver.
                        </div>
                        <Spacer height={16} />
                        <div>
                            This is updated periodically in-transit with the
                            latest information from the carrier.
                        </div>
                    </QuestionBubbleLabel>
                </>
            }
            learnMoreUrl="https://help.freightsimple.com/using-freightsimple/tracking/when-will-my-shipment-deliver"
        />
    );
}
export default EstimatedEarliestDeliveryDateQuestionBubble;
