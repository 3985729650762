import LinkButton from 'Cargo/Controls/LinkButton';
import Box from 'Cargo/Layout/Box';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { SavedCommodity } from 'generated-openapi-client';
import React from 'react';

interface SelectInitialSavedCommodityProps {
    savedCommodities: Array<SavedCommodity>;
    onSomethingElse: () => void;
    onSelectSavedCommodity: (_: SavedCommodity) => void;
}

export function SelectInitialSavedCommodity(
    props: SelectInitialSavedCommodityProps
) {
    return (
        <Box style={{ width: '803px' }}>
            <Spacer height={32} />
            <Stack>
                <div>What would you like to ship?</div>
                <Spacer height={16} />
                {props.savedCommodities.map(function (sc) {
                    return (
                        <LinkButton
                            key={sc.savedCommodityId}
                            style={{ fontSize: '20px' }}
                            onClick={function () {
                                props.onSelectSavedCommodity(sc);
                            }}
                        >
                            {sc.description}
                        </LinkButton>
                    );
                })}
                <Spacer height={16} />
                <LinkButton
                    style={{ fontSize: '20px' }}
                    onClick={props.onSomethingElse}
                >
                    Something else
                </LinkButton>
            </Stack>
            <Spacer height={32} />
        </Box>
    );
}
