import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties } from 'react';

interface IconProps {
    size: number;
    style?: CSSProperties;
    solid: boolean;
    color: string;
    name: IconName;
}
function Icon(props: IconProps) {
    return (
        <FontAwesomeIcon
            icon={[props.solid ? 'fas' : 'far', props.name]}
            style={{
                width: `${props.size}px`,
                height: `${props.size}px`,
                color: props.color,
                ...props.style,
            }}
        />
    );
}
export default Icon;
