/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';
import type { InvoiceWarningState } from './InvoiceWarningState';
import {
    InvoiceWarningStateFromJSON,
    InvoiceWarningStateFromJSONTyped,
    InvoiceWarningStateToJSON,
} from './InvoiceWarningState';

/**
 * 
 * @export
 * @interface InvoiceStatsResponse
 */
export interface InvoiceStatsResponse {
    /**
     * 
     * @type {number}
     * @memberof InvoiceStatsResponse
     */
    paymentTermsDays: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceStatsResponse
     */
    creditAmount: number;
    /**
     * 
     * @type {Currency}
     * @memberof InvoiceStatsResponse
     */
    creditCurrency: Currency;
    /**
     * 
     * @type {number}
     * @memberof InvoiceStatsResponse
     */
    issuedInvoicesTotal: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceStatsResponse
     */
    settlementPendingInvoicesTotal: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceStatsResponse
     */
    overdueIssuedInvoicesTotal: number;
    /**
     * 
     * @type {InvoiceWarningState}
     * @memberof InvoiceStatsResponse
     */
    invoiceWarningState: InvoiceWarningState;
}

/**
 * Check if a given object implements the InvoiceStatsResponse interface.
 */
export function instanceOfInvoiceStatsResponse(value: object): boolean {
    if (!('paymentTermsDays' in value)) return false;
    if (!('creditAmount' in value)) return false;
    if (!('creditCurrency' in value)) return false;
    if (!('issuedInvoicesTotal' in value)) return false;
    if (!('settlementPendingInvoicesTotal' in value)) return false;
    if (!('overdueIssuedInvoicesTotal' in value)) return false;
    if (!('invoiceWarningState' in value)) return false;
    return true;
}

export function InvoiceStatsResponseFromJSON(json: any): InvoiceStatsResponse {
    return InvoiceStatsResponseFromJSONTyped(json, false);
}

export function InvoiceStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceStatsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'paymentTermsDays': json['paymentTermsDays'],
        'creditAmount': json['creditAmount'],
        'creditCurrency': CurrencyFromJSON(json['creditCurrency']),
        'issuedInvoicesTotal': json['issuedInvoicesTotal'],
        'settlementPendingInvoicesTotal': json['settlementPendingInvoicesTotal'],
        'overdueIssuedInvoicesTotal': json['overdueIssuedInvoicesTotal'],
        'invoiceWarningState': InvoiceWarningStateFromJSON(json['invoiceWarningState']),
    };
}

export function InvoiceStatsResponseToJSON(value?: InvoiceStatsResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'paymentTermsDays': value['paymentTermsDays'],
        'creditAmount': value['creditAmount'],
        'creditCurrency': CurrencyToJSON(value['creditCurrency']),
        'issuedInvoicesTotal': value['issuedInvoicesTotal'],
        'settlementPendingInvoicesTotal': value['settlementPendingInvoicesTotal'],
        'overdueIssuedInvoicesTotal': value['overdueIssuedInvoicesTotal'],
        'invoiceWarningState': InvoiceWarningStateToJSON(value['invoiceWarningState']),
    };
}

