import {
    QuestionBubbleHeader,
    QuestionBubbleLabel,
    SmallQuestionBubble,
} from 'Cargo/Controls/QuestionBubble';
import Spacer from 'Cargo/Layout/Spacer';
import React from 'react';

export function NmfcQuestionBubble() {
    return (
        <SmallQuestionBubble
            description={
                <>
                    <QuestionBubbleHeader>
                        (Optional) NMFC Code
                    </QuestionBubbleHeader>
                    <QuestionBubbleLabel>
                        <div>
                            If you have one, enter a 5-6 digit NMFC code here to
                            help carriers understand the type of freight you are
                            shipping more easily. With an NMFC code provided
                            some carriers will be able to offer cheaper quotes.
                        </div>
                        <Spacer height={16} />
                        <div>
                            Using FreightSimple, you don&apos;t need to worry
                            about the freight class (eg. 50, 70, 110, etc),
                            since we calculate it for you.
                        </div>
                        <Spacer height={16} />
                        <div>
                            Our team can help you figure out the correct NMFC
                            code for your shipment.
                        </div>
                    </QuestionBubbleLabel>
                </>
            }
            learnMoreUrl="https://help.freightsimple.com/using-freightsimple/quoting/shipment-contents#nmfc-code"
        />
    );
}
