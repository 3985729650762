import Link from 'Cargo/Controls/Link';
import CarrierLogo from 'Cargo/Icons/CarrierLogo';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { displayNameForCarrier } from 'Data/Carriers';
import { QuoteLike } from 'Features/ViewShipments/Components/DetailsSection';
import { capitalize } from 'Helpers/capitalize';
import { formatPhone } from 'Helpers/formatPhone';
import { Broker, CreditCard, ShipmentState } from 'generated-openapi-client';
import { BankAccount } from 'generated-openapi-client/models/BankAccount';
import { Currency } from 'generated-openapi-client/models/Currency';
import { formatAddress } from './Helpers/formatLocation';
import {
    Column,
    ColumnHeader,
    ColumnSectionHeader,
    ModifyLink,
    PrimaryText,
    SecondaryText,
} from './QuoteRowSharedStyles';

interface TransitColumnProps {
    quote: QuoteLike;
    shipmentState: ShipmentState;
    proNumber: string | undefined;
    broker?: Broker;
    showCarrierLogo?: boolean;
    showPriceSection?: boolean;
    price?: number;
    currency?: string;
    selectedCard?: CreditCard;
    selectedBankAccount?: BankAccount;
    paymentTermsDays?: number;
    onChangePaymentMethod?: () => void;
    onChangeQuote?: () => void;
    onChangeBroker?: () => void;
    addInsuranceToShipment: boolean;
    insuranceAmount: number;
    insuranceCurrency: Currency;
}
function TransitColumn(props: TransitColumnProps) {
    console.log(`props`, { props });
    const { quote, shipmentState } = props;

    const showCarrierLogo =
        props.showCarrierLogo ||
        !(
            shipmentState === ShipmentState.Quoted ||
            shipmentState === ShipmentState.QuoteRequested
        );

    const carrierDisplayName = displayNameForCarrier(quote.carrierIdentifier);
    const serviceDisplayName = quote.serviceDisplayName;

    function formatTransitDays(
        earliest: number | undefined,
        latest: number | undefined
    ) {
        if (earliest === undefined) {
            return 'Unknown';
        } else {
            if (latest === undefined || earliest === latest) {
                if (earliest === 1) {
                    return `${earliest} Business Day`;
                } else {
                    return `${earliest} Business Days`;
                }
            } else {
                return `${earliest}-${latest} Business Days`;
            }
        }
    }

    const transitTimeDescription = quote.transitBusinessDays
        ? `${formatTransitDays(
              quote.transitBusinessDays,
              quote.latestTransitBusinessDays
          )}`
        : 'Unknown';
    const maximumLiabilityDescription = '$2 / pound';

    function showCreditTerms() {
        return (
            props.paymentTermsDays !== undefined && props.paymentTermsDays > 0
        );
    }

    function describeInsurance() {
        return `$${props.insuranceAmount.toLocaleString()} ${
            props.insuranceCurrency
        }`;
    }

    const showInsurance = props.addInsuranceToShipment;
    const insuranceDescription = describeInsurance();

    return (
        <Column>
            <Stack align="left">
                <ColumnHeader>Transit</ColumnHeader>
                {props.proNumber && (
                    <>
                        <ColumnSectionHeader>PRO Number</ColumnSectionHeader>
                        <SecondaryText>{props.proNumber}</SecondaryText>
                    </>
                )}

                <ColumnSectionHeader>Carrier</ColumnSectionHeader>
                {showCarrierLogo && (
                    <div style={{ margin: '8px 0' }}>
                        <CarrierLogo
                            carrierIdentifier={quote.carrierIdentifier}
                            align="left"
                            width={160}
                            height={64}
                        />
                    </div>
                )}
                {!showCarrierLogo && (
                    <SecondaryText>{carrierDisplayName}</SecondaryText>
                )}
                {props.onChangeQuote && (
                    <>
                        <Spacer height={8} />
                        <ModifyLink>
                            <Link onClick={props.onChangeQuote}>
                                Pick a different quote
                            </Link>
                        </ModifyLink>
                    </>
                )}

                <ColumnSectionHeader>Service Type</ColumnSectionHeader>
                <SecondaryText>{serviceDisplayName}</SecondaryText>
                <Spacer height={4} />

                {props.showPriceSection && (
                    <>
                        <ColumnSectionHeader>Price</ColumnSectionHeader>
                        <SecondaryText>
                            ${props.price} {props.currency}
                        </SecondaryText>

                        {showCreditTerms() && (
                            <>
                                <ColumnSectionHeader>
                                    Credit Terms
                                </ColumnSectionHeader>
                                <SecondaryText>
                                    An invoice will be issued.
                                </SecondaryText>
                                <SecondaryText>
                                    Payment will be due in{' '}
                                    {props.paymentTermsDays} days
                                </SecondaryText>
                            </>
                        )}

                        {!showCreditTerms() && props.selectedBankAccount && (
                            <>
                                <ColumnSectionHeader>
                                    Bank Account
                                </ColumnSectionHeader>
                                <SecondaryText>
                                    {props.selectedBankAccount.bankName} ending{' '}
                                    {props.selectedBankAccount.lastFourDigits}
                                </SecondaryText>
                                {props.onChangePaymentMethod && (
                                    <>
                                        <Spacer height={4} />
                                        <SecondaryText>
                                            <ModifyLink>
                                                <Link
                                                    onClick={
                                                        props.onChangePaymentMethod
                                                    }
                                                >
                                                    Change Payment Method
                                                </Link>
                                            </ModifyLink>
                                        </SecondaryText>
                                    </>
                                )}
                            </>
                        )}

                        {!showCreditTerms() && !props.selectedBankAccount && (
                            <>
                                <ColumnSectionHeader>
                                    Credit Card
                                </ColumnSectionHeader>
                                {props.selectedCard && (
                                    <SecondaryText>
                                        <img
                                            style={{
                                                maxHeight: '16px',
                                                marginRight: '2px',
                                            }}
                                            src={`/images/credit-card-brands/${props.selectedCard?.brand}.png`}
                                            alt={props.selectedCard?.brand}
                                        />{' '}
                                        {capitalize(
                                            props.selectedCard?.brand || ''
                                        )}{' '}
                                        Ending{' '}
                                        {props.selectedCard?.lastFourDigits}
                                    </SecondaryText>
                                )}
                                {!props.selectedCard && (
                                    <SecondaryText>
                                        ⚠️ No selected credit card
                                    </SecondaryText>
                                )}
                                {props.onChangePaymentMethod && (
                                    <>
                                        <Spacer height={4} />
                                        <SecondaryText>
                                            <ModifyLink>
                                                <Link
                                                    onClick={
                                                        props.onChangePaymentMethod
                                                    }
                                                >
                                                    Change Payment Method
                                                </Link>
                                            </ModifyLink>
                                        </SecondaryText>
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}

                <ColumnSectionHeader>Transit Time</ColumnSectionHeader>
                <SecondaryText>{transitTimeDescription}</SecondaryText>

                {!showInsurance && (
                    <>
                        <ColumnSectionHeader>
                            Maximum Liability
                        </ColumnSectionHeader>
                        <SecondaryText>
                            {maximumLiabilityDescription}
                        </SecondaryText>
                    </>
                )}
                {showInsurance && (
                    <>
                        <ColumnSectionHeader>Insurance</ColumnSectionHeader>
                        <SecondaryText>{insuranceDescription}</SecondaryText>
                    </>
                )}
                {props.broker && (
                    <>
                        <ColumnSectionHeader>
                            Customs Broker
                        </ColumnSectionHeader>
                        <PrimaryText>{props.broker.businessName}</PrimaryText>
                        <SecondaryText>
                            {props.broker.contact.contactName}
                        </SecondaryText>
                        <SecondaryText>
                            {formatPhone(
                                props.broker.contact.phoneNumber,
                                props.broker.contact.phoneNumberExtension
                            )}
                        </SecondaryText>
                        <SecondaryText>
                            {props.broker.contact.emailAddress}
                        </SecondaryText>
                        <SecondaryText>
                            {props.broker.address.addressLine}
                        </SecondaryText>
                        <SecondaryText>
                            {props.broker.address.addressLine2}
                        </SecondaryText>
                        <SecondaryText>
                            {formatAddress(props.broker.address)}
                        </SecondaryText>
                        {props.onChangeBroker && (
                            <>
                                <Spacer height={4} />
                                <SecondaryText>
                                    <ModifyLink>
                                        <Link onClick={props.onChangeBroker}>
                                            Change Broker
                                        </Link>
                                    </ModifyLink>
                                </SecondaryText>
                            </>
                        )}
                    </>
                )}
            </Stack>
        </Column>
    );
}
export default TransitColumn;
