import Colors from 'Cargo/Colors';
import Button from 'Cargo/Controls/Button';
import Icon from 'Cargo/Icons/Icon';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import styled from 'styled-components/macro';
import ViewShipmentSection from './ViewShipmentSection';
styled;

interface NextStepsSectionProps {
    onDownload: () => void;
}

const ContentsText = styled.div`
    color: ${Colors.LightText};
    font-weight: var(--nhu-font-weight-light);
    font-size: 14px;
`;

function NextStepsSection(props: NextStepsSectionProps) {
    const { onDownload } = props;

    function containsDescription() {
        return `Contains: Pickup Instructions, Packaging Labels, Bill of
Lading, Customs Documents (if applicable)`;
    }

    return (
        <ViewShipmentSection
            title={
                <HorizontalStack
                    align="spread"
                    verticalAlign="middle"
                    style={{ marginTop: '16px' }}
                >
                    <Stack align="left">
                        <div>Next: Your Pickup Package</div>
                    </Stack>
                </HorizontalStack>
            }
            id="next-steps-section"
        >
            <Stack style={{ marginTop: '-16px' }} align="left">
                <div>
                    Please make sure whoever will be present when the driver
                    arrives has a copy of the pickup package. It contains
                    everything they need to ensure the shipment is successful.
                </div>

                <Spacer height={16} />
                <HorizontalStack style={{ marginTop: '8px' }}>
                    <Button size="large" onClick={onDownload}>
                        <Icon
                            name="download"
                            color={Colors.White}
                            size={16}
                            solid
                            style={{ marginRight: '4px' }}
                        />{' '}
                        Download PDF
                    </Button>
                </HorizontalStack>
                <Spacer height={16} />
                <ContentsText>{containsDescription()}</ContentsText>
            </Stack>
        </ViewShipmentSection>
    );
}
export default NextStepsSection;
