import React from 'react';
import styled, { CSSProperties } from 'styled-components/macro';

type VericalAlignOptions = 'middle' | 'top' | 'bottom';
export type AlignOptions = 'left' | 'center' | 'right' | 'spread';

interface HorizontalStackProps {
    children: React.ReactNode;
    verticalAlign?: VericalAlignOptions;
    align?: AlignOptions;
    width?: string;

    /** padding */
    padding?: string;

    style?: CSSProperties;

    spacing?: number;

    id?: string;
}

function verticalAlignToAlignItems(align?: VericalAlignOptions) {
    if (align === undefined) {
        return 'center';
    }
    if (align === 'middle') {
        return 'center';
    }

    if (align === 'top') {
        return 'flex-start';
    }

    if (align === 'bottom') {
        return 'flex-end';
    }
}

function alignToJustify(align?: AlignOptions) {
    if (align === undefined) {
        return 'flex-start';
    }
    if (align === 'left') {
        return 'flex-start';
    }

    if (align === 'center') {
        return 'center';
    }

    if (align === 'right') {
        return 'flex-end';
    }

    if (align === 'spread') {
        return 'space-between';
    }
}

interface HorizontalStackBodyProps {
    spacing?: number;
}

const HorizontalStackBody = styled.div<HorizontalStackBodyProps>`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    padding-left: ${(props) =>
        props.spacing ? `${props.spacing / 2}px` : '0'};
    padding-right: ${(props) =>
        props.spacing ? `${props.spacing / 2}px` : '0'};

    & > * {
        margin-left: ${(props) =>
            props.spacing ? `${props.spacing / 2}px` : '0'};
        margin-right: ${(props) =>
            props.spacing ? `${props.spacing / 2}px` : '0'};
    }
`;

/**
 *
 * @param verticalAlign (Optional) Vertical alignment of elements in the stack (middle|top|bottom)
 * @param align (Optional) Horizontal alignment of elements in the stack (left|center|right|spread)
 * @param width (Optional) Width of the stack (eg. '520px')
 * @param padding (Optional) Padding on the stack
 * @param spacing (Optional) Spacing between elements
 *
 */
const HorizontalStack: React.FC<HorizontalStackProps> = (
    props: HorizontalStackProps
) => {
    const style: CSSProperties = props.style || {};

    if (props.width) {
        style.width = props.width;
    }

    if (props.padding) {
        style.padding = props.padding;
    }

    if (props.align) {
        style.justifyContent = alignToJustify(props.align);
    }

    if (props.verticalAlign) {
        style.alignItems = verticalAlignToAlignItems(props.verticalAlign);
    }

    return (
        <HorizontalStackBody
            style={style}
            spacing={props.spacing}
            id={props.id}
        >
            {props.children}
        </HorizontalStackBody>
    );
};
export default HorizontalStack;
