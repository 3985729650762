import React, { useEffect, useRef, useState } from 'react';

interface ModalFocusTrapProps {
    children: React.ReactNode;
    isActive?: boolean;
    focusFirstElementOnActivation?: boolean;
}

export function ModalFocusTrap({
    children,
    isActive = true,
    focusFirstElementOnActivation = false,
}: ModalFocusTrapProps) {
    const containerRef = useRef<HTMLDivElement>(null);
    const [focusableElements, setFocusableElements] = useState<HTMLElement[]>(
        []
    );
    const previouslyFocusedElement = useRef<HTMLElement | null>(null);

    useEffect(() => {
        if (!isActive || !containerRef.current) return;

        const elements = Array.from(
            containerRef.current.querySelectorAll<HTMLElement>(
                'a[href], button, input, textarea, select, details, div, [tabindex]:not([tabindex="-1"])'
            )
        ).filter(
            (el) =>
                !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden')
        );

        setFocusableElements(elements);

        previouslyFocusedElement.current =
            document.activeElement as HTMLElement;

        if (focusFirstElementOnActivation && elements.length > 0) {
            elements[0].focus();
        }

        // Make elements outside the focus trap inert
        const allElements = document.getElementById('fs-root')?.children ?? [];
        Array.from(allElements).forEach((element) => {
            if (
                element !== containerRef.current &&
                element instanceof HTMLElement
            ) {
                element.setAttribute('inert', '');
            }
        });

        return () => {
            // Remove inert attribute when component unmounts or becomes inactive
            Array.from(allElements).forEach((element) => {
                if (element instanceof HTMLElement) {
                    element.removeAttribute('inert');
                }
            });

            // Restore focus to the previously focused element
            if (previouslyFocusedElement.current) {
                previouslyFocusedElement.current.focus();
            }
        };
    }, [isActive, focusFirstElementOnActivation]);

    useEffect(() => {
        if (!isActive || focusableElements.length === 0) return;

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key !== 'Tab') return;

            const firstElement = focusableElements[0];
            const lastElement = focusableElements[focusableElements.length - 1];

            if (e.shiftKey && document.activeElement === firstElement) {
                e.preventDefault();
                lastElement.focus();
            } else if (!e.shiftKey && document.activeElement === lastElement) {
                e.preventDefault();
                firstElement.focus();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isActive, focusableElements]);

    return (
        <div ref={containerRef} style={{ outline: 'none' }} tabIndex={-1}>
            {children}
        </div>
    );
}

export default ModalFocusTrap;
