/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BankAccount
 */
export interface BankAccount {
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    bankName: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    lastFourDigits: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    stripePaymentMethodId: string;
    /**
     * 
     * @type {boolean}
     * @memberof BankAccount
     */
    isDefault: boolean;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    institutionNumber: string;
}

/**
 * Check if a given object implements the BankAccount interface.
 */
export function instanceOfBankAccount(value: object): boolean {
    if (!('bankName' in value)) return false;
    if (!('lastFourDigits' in value)) return false;
    if (!('stripePaymentMethodId' in value)) return false;
    if (!('isDefault' in value)) return false;
    if (!('institutionNumber' in value)) return false;
    return true;
}

export function BankAccountFromJSON(json: any): BankAccount {
    return BankAccountFromJSONTyped(json, false);
}

export function BankAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): BankAccount {
    if (json == null) {
        return json;
    }
    return {
        
        'bankName': json['bankName'],
        'lastFourDigits': json['lastFourDigits'],
        'stripePaymentMethodId': json['stripePaymentMethodId'],
        'isDefault': json['isDefault'],
        'institutionNumber': json['institutionNumber'],
    };
}

export function BankAccountToJSON(value?: BankAccount | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'bankName': value['bankName'],
        'lastFourDigits': value['lastFourDigits'],
        'stripePaymentMethodId': value['stripePaymentMethodId'],
        'isDefault': value['isDefault'],
        'institutionNumber': value['institutionNumber'],
    };
}

