/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GenerateStripeIntentResponse
 */
export interface GenerateStripeIntentResponse {
    /**
     * 
     * @type {string}
     * @memberof GenerateStripeIntentResponse
     */
    clientSecret: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateStripeIntentResponse
     */
    companyName: string;
}

/**
 * Check if a given object implements the GenerateStripeIntentResponse interface.
 */
export function instanceOfGenerateStripeIntentResponse(value: object): boolean {
    if (!('clientSecret' in value)) return false;
    if (!('companyName' in value)) return false;
    return true;
}

export function GenerateStripeIntentResponseFromJSON(json: any): GenerateStripeIntentResponse {
    return GenerateStripeIntentResponseFromJSONTyped(json, false);
}

export function GenerateStripeIntentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateStripeIntentResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'clientSecret': json['clientSecret'],
        'companyName': json['companyName'],
    };
}

export function GenerateStripeIntentResponseToJSON(value?: GenerateStripeIntentResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clientSecret': value['clientSecret'],
        'companyName': value['companyName'],
    };
}

