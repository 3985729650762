import { formatPhoneNumber } from 'Cargo/Controls/PhoneInput';

export function formatPhone(
    phone: string,
    phoneNumberExtension: string | undefined
) {
    const parts = [];
    parts.push(formatPhoneNumber(phone));
    if (phoneNumberExtension !== undefined && phoneNumberExtension !== '') {
        parts.push(`Ext. ${phoneNumberExtension}`);
    }

    return parts.join(' ');
}
