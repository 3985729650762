import Colors from 'Cargo/Colors';
import Link from 'Cargo/Controls/Link';
import Icon from 'Cargo/Icons/Icon';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import { Legalese } from 'Cargo/Text/Text';
import { UUID } from 'Cargo/Types/types';
import { Document, DocumentType } from 'generated-openapi-client';
import moment from 'moment';

export function UpdateCustomsDocsList({
    documents,
    onDownload,
}: {
    documents: Document[];
    onDownload: (documentId: UUID) => void;
}) {
    const customsDocs = documents.filter(d => d.documentType === DocumentType.CustomsDocument)
    return (
        <ul style={{ padding: 0, margin: 0 }}>
            {customsDocs.map((d) => (
                <li
                    key={d.documentId}
                    style={{
                        alignItems: 'center',
                        padding: '4px 8px 4px 0',
                        marginBlock: '4px',
                    }}
                >
                    <div>
                        <HorizontalStack>
                            <Icon
                                name="file-pdf"
                                color={Colors.Blue}
                                size={20}
                                solid
                                style={{
                                    marginRight: '8px',
                                }}
                            />
                            <div>
                                <Link onClick={() => onDownload(d.documentId)}>
                                    Customs Document
                                </Link>
                                <Legalese>
                                    {moment(d.createdAt).format('Do MMMM YYYY')}
                                </Legalese>
                            </div>
                        </HorizontalStack>
                    </div>
                </li>
            ))}
        </ul>
    );
}
