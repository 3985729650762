import Colors from 'Cargo/Colors';
import { ReactNode } from 'react';
import styled from 'styled-components/macro';

interface PillBodyProps {
    pillStyle: PillStyle;
}

function backgroundColor(pillStyle: PillStyle) {
    switch (pillStyle) {
        case PillStyle.Yellow:
            return Colors.Gold;
        case PillStyle.Red:
            return Colors.Red;
        case PillStyle.Blue:
            return Colors.Blue;
    }
}

function color(pillStyle: PillStyle) {
    switch (pillStyle) {
        case PillStyle.Yellow:
            return Colors.NormalText;
        case PillStyle.Red:
            return Colors.White;
        case PillStyle.Blue:
            return Colors.White;
    }
}

const PillBodyNormal = styled.span<PillBodyProps>`
    background-color: ${(props) => backgroundColor(props.pillStyle)};
    color: ${(props) => color(props.pillStyle)};
    position: relative;
    left: -8px;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
`;

const PillBodySmall = styled.span<PillBodyProps>`
    background-color: ${(props) => backgroundColor(props.pillStyle)};
    color: ${(props) => color(props.pillStyle)};
    position: relative;
    padding: 2px 6px;
    border-radius: 2px;
    font-size: 12px;
`;

export enum PillStyle {
    Yellow,
    Red,
    Blue,
}

export enum PillSize {
    Small,
    Normal,
}

interface PillProps {
    children: ReactNode;
    pillStyle: PillStyle;
    pillSize?: PillSize;
}
function Pill(props: PillProps) {
    if (props.pillSize === PillSize.Normal || undefined) {
        return (
            <PillBodyNormal pillStyle={props.pillStyle}>
                {props.children}
            </PillBodyNormal>
        );
    } else {
        return (
            <PillBodySmall pillStyle={props.pillStyle}>
                {props.children}
            </PillBodySmall>
        );
    }
}
export default Pill;
