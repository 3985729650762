import { useWindowSize } from 'Cargo/Helpers/useWindowSize';
import React from 'react';

interface DynamicSpacerProps {
    minHeight: number;
    maxHeight: number;
}

export function useDynamicHeight(minHeight: number, maxHeight: number) {
    const { height } = useWindowSize();

    let elementHeight = minHeight;

    if (height !== undefined) {
        const m = (maxHeight - minHeight) / 224;
        const c = minHeight - 800 * m;

        elementHeight = m * height + c;
    }

    if (elementHeight < minHeight) {
        return minHeight;
    }

    if (elementHeight > maxHeight) {
        return maxHeight;
    }

    return Math.floor(elementHeight);
}

export function useDynamicWidth(minWidth: number, maxWidth: number) {
    const { width } = useWindowSize();

    let elementHeight = minWidth;

    if (width !== undefined) {
        const m = (maxWidth - minWidth) / (1480 - 1280);
        const c = minWidth - 1280 * m;

        elementHeight = m * width + c;
    }

    if (elementHeight < minWidth) {
        return minWidth;
    }

    if (elementHeight > maxWidth) {
        return maxWidth;
    }

    return Math.floor(elementHeight);
}

export function useSiteMargin() {
    return useDynamicWidth(30, 61);
}

/**
 *
 * This spacer will be at it's min height when the viewport height is below 800px,
 * and at it's max height when the viewport height is 1024px or above
 */
const DynamicHeightSpacer: React.FC<DynamicSpacerProps> = (
    props: DynamicSpacerProps
) => {
    const elementHeight = useDynamicHeight(props.minHeight, props.maxHeight);

    return <div style={{ height: `${elementHeight}px` }}></div>;
};
export default DynamicHeightSpacer;
