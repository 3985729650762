import { PreBookingShipment } from 'generated-openapi-client';

interface QuoteScreenShipmentGroup {
    pickupCity: string;
    pickupStateOrProvinceCode: string;
    deliveryCity: string;
    deliveryStateOrProvinceCode: string;
}

export function generateQuoteScreenShipmentGroupKey(
    shipment: PreBookingShipment
): QuoteScreenShipmentGroup {
    return {
        pickupCity: shipment.pickupLocation.address.city,
        pickupStateOrProvinceCode:
            shipment.pickupLocation.address.stateOrProvinceCode,
        deliveryCity: shipment.deliveryLocation.address.city,
        deliveryStateOrProvinceCode:
            shipment.deliveryLocation.address.stateOrProvinceCode,
    };
}

interface QuoteScreenGroupHeaderProps {
    group: QuoteScreenShipmentGroup;
}

export function QuoteScreenGroupHeader(props: QuoteScreenGroupHeaderProps) {
    const { group } = props;
    return (
        <div style={{ marginBottom: '8px', marginTop: '24px' }}>
            {group.pickupCity}, {group.pickupStateOrProvinceCode} to{' '}
            {group.deliveryCity}, {group.deliveryStateOrProvinceCode}
        </div>
    );
}
