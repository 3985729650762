import Button from 'Cargo/Controls/Button';
import LinkButton from 'Cargo/Controls/LinkButton';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import React from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import IntercomButton from './IntercomButton';

// eslint-disable-next-line local-rules/no-import-styled
// eslint-disable-next-line local-rules/no-import-bootstrap
function SignedOutRightNavMenu() {
    const location = useLocation();
    const navigate = useNavigate();

    const showSignIn = !location.pathname.includes('sign-in');

    function onGetQuotes() {
        navigate('/book/details');
    }

    return (
        <HorizontalStack align="right" spacing={40}>
            <LinkButton
                label="Help Center"
                to="https://help.freightsimple.com"
                newTab
            />
            <IntercomButton />
            {showSignIn && (
                <LinkButton label="Sign In" to="/sign-in"></LinkButton>
            )}
            <Routes>
                {/* Match everything except things beginning with /get-started/ */}
                {/* ^((?!\/get-started\/.*).)*$ */}
                <Route
                    path="/"
                    element={
                        <Button
                            label="Get Your First Quotes In Under A Minute!"
                            onClick={onGetQuotes}
                        />
                    }
                />
                <Route
                    path="/create-account"
                    element={
                        <Button
                            label="Get Your First Quotes In Under A Minute!"
                            onClick={onGetQuotes}
                        />
                    }
                />
                <Route
                    path="/sign-in"
                    element={
                        <Button
                            label="Get Your First Quotes In Under A Minute!"
                            onClick={onGetQuotes}
                        />
                    }
                />
            </Routes>
        </HorizontalStack>
    );
}
export default SignedOutRightNavMenu;
