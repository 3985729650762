import moment from "moment";

export function isDateInThePast(dateString: string | undefined): boolean {
  if (dateString === undefined) {
    return true;
  }

  const startOfDate = moment(dateString).clone().startOf("day");
  const today = moment().startOf("day");

  // In the past
  return startOfDate.isBefore(today);
}
