import { SmallQuestionBubble } from 'Cargo/Controls/QuestionBubble';
import React from 'react';

const InsuranceQuestionBubble: React.FC = () => {
    return (
        <SmallQuestionBubble
            description="Adding insurance helps protect your shipment in the event of a claim. Insurance is provided by our reputable insurance partner, and all insurance comes with a $500 deductible. Please open a live chat for more details."
            learnMoreUrl="https://help.freightsimple.com/using-freightsimple/quoting/insurance"
        />
    );
};
export default InsuranceQuestionBubble;
