import Colors from 'Cargo/Colors';
import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import Spacer from 'Cargo/Layout/Spacer';
import Stack from 'Cargo/Layout/Stack';
import { totalWeightForLineItem } from 'Helpers/lineItemCalculations';
import {
    OpenClaimLineItem,
    Currency,
    FreightClaimType,
    LineItem,
} from 'generated-openapi-client';

import NumberInput from 'Cargo/Controls/NumberInput';
import { Dimension, Units } from 'Helpers/units';
import styled from 'styled-components/macro';
import { ClaimCurrencyInput } from './ClaimCurrencyInput';
import { ClaimTypeDropdown } from './ClaimTypeDropdown';
import { ItemConditionDropdown } from './ItemConditionDropdown';

const RowItem = styled.div`
    font-weight: var(--nhu-font-weight-medium);
    font-size: 20px;
    color: var(--freightsimple-color-normal-text);
`;

const RowItem2 = styled.div`
    font-weight: var(--nhu-font-weight-medium);
    font-size: 16px;
    color: var(--freightsimple-color-normal-text);
`;

const DetailsSectionTitle = styled.div`
    font-weight: var(--nhu-font-weight-bold);
    font-size: 20px;
    color: var(--freightsimple-color-normal-text);
`;

const DetailsSectionSubtitle = styled.div`
    font-weight: var(--nhu-font-weight-normal);
    font-size: 14px;
    color: var(--freightsimple-color-light-text);
`;

const DetailsTitle = styled.div`
    font-weight: var(--nhu-font-weight-medium);
    font-size: 16px;
    color: var(--freightsimple-color-normal-text);
`;

const DimensionLabel = styled.div`
    font-weight: var(--nhu-font-weight-normal);
    font-size: 16px;
    color: var(--freightsimple-color-light-text);
`;

interface DamageClaimLineItemRowProps {
    lineItem: LineItem;
    index: number;
    currency: Currency;
    claimLineItem: OpenClaimLineItem;
    setClaimLineItem: (_: OpenClaimLineItem) => void;
    forceValidation: boolean;
}

const Row = styled.div`
    background-color: #fcfcfc;
    padding: 16px 16px;
    margin: 16px 0;
    border: 1px solid ${Colors.LightGray};
    width: 792px;
`;

const ExtraInfoBox = styled.div`
    background-color: white;
    border: 1px solid ${Colors.LightGray};
    width: 760px;
    padding: 16px 16px;
    margin: 24px 0 0 0;
    min-height: 200px;
`;

export function DamageClaimLineItemRow(props: DamageClaimLineItemRowProps) {
    const { lineItem, index, claimLineItem } = props;
    const {
        claimType,
        amountPerPiece,
        affectedPieces,
        totalPieces,
        condition,
    } = claimLineItem;

    const showExtraInfoBox = claimType !== FreightClaimType.NoDamageOrLoss;

    function extraInfoTitle() {
        switch (claimType) {
            case FreightClaimType.NoDamageOrLoss:
                return '';
            case FreightClaimType.Damage:
                return 'Damage Details';
            case FreightClaimType.Loss:
                return 'Loss Details';
        }
    }

    function extraInfoSubtitle() {
        switch (claimType) {
            case FreightClaimType.NoDamageOrLoss:
                return '';
            case FreightClaimType.Damage:
                return 'Please tell us the complete details of the damage of pieces in this line item';
            case FreightClaimType.Loss:
                return 'Please tell us the complete details of the loss of pieces in this line item';
        }
    }

    function affectedPiecesLabel() {
        switch (claimType) {
            case FreightClaimType.NoDamageOrLoss:
                return '';
            case FreightClaimType.Damage:
                return 'Damaged Pieces';
            case FreightClaimType.Loss:
                return 'Lost Pieces';
        }
    }

    function errorMessageForAffectedPieces() {
        if (affectedPieces === undefined) {
            return 'Required';
        }

        if (affectedPieces === 0) {
            return 'Cannot be zero';
        }

        if (totalPieces !== undefined && affectedPieces > totalPieces) {
            return 'Too high';
        }

        return undefined;
    }

    function errorMessageForTotalPieces() {
        if (totalPieces === undefined) {
            return 'Required';
        }

        if (totalPieces === 0) {
            return 'Cannot be zero';
        }
    }

    function errorMessageForAmountPerPiece() {
        if (amountPerPiece === undefined) {
            return 'Required';
        }

        if (amountPerPiece < 0.01) {
            return 'Cannot be zero';
        }
    }

    return (
        <Row key={index}>
            <Stack align="left">
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 3fr 1fr',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <RowItem>
                        {lineItem.numberHandlingUnits} x{' '}
                        {lineItem.handlingUnitType}
                    </RowItem>
                    <Stack align="left">
                        <RowItem2>{lineItem.description}</RowItem2>
                        <DimensionLabel>
                            {lineItem.length}"x{lineItem.width}"x
                            {lineItem.height}",{' '}
                            {totalWeightForLineItem(lineItem)}lb total
                        </DimensionLabel>
                    </Stack>
                    <ClaimTypeDropdown
                        claimType={claimType}
                        setClaimType={function (_claimType) {
                            props.setClaimLineItem({
                                ...claimLineItem,
                                claimType: _claimType,
                            });
                        }}
                    />
                </div>
                {showExtraInfoBox && (
                    <ExtraInfoBox>
                        <Stack align="left">
                            <DetailsSectionTitle>
                                {extraInfoTitle()}
                            </DetailsSectionTitle>
                            <DetailsSectionSubtitle>
                                {extraInfoSubtitle()}
                            </DetailsSectionSubtitle>
                            <Spacer height={32} />
                            <HorizontalStack verticalAlign="top">
                                <Stack align="left">
                                    <DetailsTitle>
                                        Number of Pieces
                                    </DetailsTitle>
                                    <HorizontalStack>
                                        <NumberInput
                                            id='affected-pieces-input'
                                            onlyDigits
                                            label={affectedPiecesLabel()}
                                            errorMessage={errorMessageForAffectedPieces()}
                                            forceValidation={
                                                props.forceValidation
                                            }
                                            units={Units.Imperial}
                                            dimension={Dimension.Length}
                                            width={100}
                                            value={affectedPieces}
                                            onChange={function (
                                                _affectedPieces
                                            ) {
                                                props.setClaimLineItem({
                                                    ...claimLineItem,
                                                    affectedPieces:
                                                        _affectedPieces,
                                                });
                                            }}
                                        />
                                        <Spacer width={16} />
                                        <NumberInput
                                            id='total-pieces-input'
                                            onlyDigits
                                            label="Total Pieces"
                                            errorMessage={errorMessageForTotalPieces()}
                                            forceValidation={
                                                props.forceValidation
                                            }
                                            units={Units.Imperial}
                                            dimension={Dimension.Length}
                                            width={100}
                                            value={totalPieces}
                                            onChange={function (_totalPieces) {
                                                props.setClaimLineItem({
                                                    ...claimLineItem,
                                                    totalPieces: _totalPieces,
                                                });
                                            }}
                                        />
                                    </HorizontalStack>
                                </Stack>
                                <Spacer width={80} />
                                <Stack align="left">
                                    <DetailsTitle>Piece Value</DetailsTitle>
                                    <HorizontalStack>
                                        <ClaimCurrencyInput
                                            id='piece-value-input'
                                            value={amountPerPiece}
                                            onChange={function (
                                                _amountPerPiece
                                            ) {
                                                props.setClaimLineItem({
                                                    ...claimLineItem,
                                                    amountPerPiece:
                                                        _amountPerPiece,
                                                });
                                            }}
                                            errorMessage={errorMessageForAmountPerPiece()}
                                            forceValidation={
                                                props.forceValidation
                                            }
                                            width={100}
                                            label=""
                                            placeholder="Enter Amount"
                                            enabled={true}
                                        />
                                        <Spacer width={8} />
                                        <div>{props.currency}</div>
                                    </HorizontalStack>
                                </Stack>
                                <Spacer width={80} />
                                <Stack align="left">
                                    <DetailsTitle>Condition</DetailsTitle>
                                    <ItemConditionDropdown
                                        itemCondition={condition}
                                        setItemCondition={function (
                                            _condition
                                        ) {
                                            props.setClaimLineItem({
                                                ...claimLineItem,
                                                condition: _condition,
                                            });
                                        }}
                                    />
                                </Stack>
                            </HorizontalStack>
                            {affectedPieces && amountPerPiece && (
                                <div style={{ color: Colors.LightText }}>
                                    Total claim for {affectedPieces} pieces : $
                                    {(
                                        affectedPieces * amountPerPiece
                                    ).toLocaleString()}{' '}
                                    {props.currency}
                                </div>
                            )}
                        </Stack>
                    </ExtraInfoBox>
                )}
            </Stack>
        </Row>
    );
}
