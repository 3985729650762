/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Quote } from './Quote';
import {
    QuoteFromJSON,
    QuoteFromJSONTyped,
    QuoteToJSON,
} from './Quote';

/**
 * 
 * @export
 * @interface PollQuotesResponse
 */
export interface PollQuotesResponse {
    /**
     * 
     * @type {Array<Quote>}
     * @memberof PollQuotesResponse
     */
    quotes: Array<Quote>;
    /**
     * 
     * @type {boolean}
     * @memberof PollQuotesResponse
     */
    complete: boolean;
}

/**
 * Check if a given object implements the PollQuotesResponse interface.
 */
export function instanceOfPollQuotesResponse(value: object): boolean {
    if (!('quotes' in value)) return false;
    if (!('complete' in value)) return false;
    return true;
}

export function PollQuotesResponseFromJSON(json: any): PollQuotesResponse {
    return PollQuotesResponseFromJSONTyped(json, false);
}

export function PollQuotesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PollQuotesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'quotes': ((json['quotes'] as Array<any>).map(QuoteFromJSON)),
        'complete': json['complete'],
    };
}

export function PollQuotesResponseToJSON(value?: PollQuotesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'quotes': ((value['quotes'] as Array<any>).map(QuoteToJSON)),
        'complete': value['complete'],
    };
}

