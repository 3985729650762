/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    phoneNumberExtension: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserRequest
     */
    accountingNotificationsEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserRequest
     */
    allShipmentNotificationsEnabled: boolean;
}

/**
 * Check if a given object implements the UpdateUserRequest interface.
 */
export function instanceOfUpdateUserRequest(value: object): boolean {
    if (!('userId' in value)) return false;
    if (!('name' in value)) return false;
    if (!('phoneNumber' in value)) return false;
    if (!('phoneNumberExtension' in value)) return false;
    if (!('accountingNotificationsEnabled' in value)) return false;
    if (!('allShipmentNotificationsEnabled' in value)) return false;
    return true;
}

export function UpdateUserRequestFromJSON(json: any): UpdateUserRequest {
    return UpdateUserRequestFromJSONTyped(json, false);
}

export function UpdateUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'name': json['name'],
        'phoneNumber': json['phoneNumber'],
        'phoneNumberExtension': json['phoneNumberExtension'],
        'accountingNotificationsEnabled': json['accountingNotificationsEnabled'],
        'allShipmentNotificationsEnabled': json['allShipmentNotificationsEnabled'],
    };
}

export function UpdateUserRequestToJSON(value?: UpdateUserRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'name': value['name'],
        'phoneNumber': value['phoneNumber'],
        'phoneNumberExtension': value['phoneNumberExtension'],
        'accountingNotificationsEnabled': value['accountingNotificationsEnabled'],
        'allShipmentNotificationsEnabled': value['allShipmentNotificationsEnabled'],
    };
}

