import React from 'react';

interface SpacerProps {
    width?: number;
    height?: number;
}

const Spacer: React.FC<SpacerProps> = (props: SpacerProps) => {
    if (props.width !== undefined && props.height !== undefined) {
        return (
            <div
                style={{
                    width: props.width + 'px',
                    height: props.height + 'px',
                }}
            ></div>
        );
    }

    if (props.width !== undefined) {
        return <div style={{ width: props.width + 'px' }}></div>;
    }

    if (props.height !== undefined) {
        return <div style={{ height: props.height + 'px' }}></div>;
    }

    throw new Error('Must specify width or height');
};
export default Spacer;
