/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendInvitationRequest
 */
export interface SendInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof SendInvitationRequest
     */
    inviteeEmailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof SendInvitationRequest
     */
    inviteeName: string;
}

/**
 * Check if a given object implements the SendInvitationRequest interface.
 */
export function instanceOfSendInvitationRequest(value: object): boolean {
    if (!('inviteeEmailAddress' in value)) return false;
    if (!('inviteeName' in value)) return false;
    return true;
}

export function SendInvitationRequestFromJSON(json: any): SendInvitationRequest {
    return SendInvitationRequestFromJSONTyped(json, false);
}

export function SendInvitationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendInvitationRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'inviteeEmailAddress': json['inviteeEmailAddress'],
        'inviteeName': json['inviteeName'],
    };
}

export function SendInvitationRequestToJSON(value?: SendInvitationRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'inviteeEmailAddress': value['inviteeEmailAddress'],
        'inviteeName': value['inviteeName'],
    };
}

