import { ErrorMessageType } from 'Cargo/Validation';
import { LocationType } from 'generated-openapi-client';
import moment from 'moment';

export function errorMessageForHasDeadline(
    locationType: LocationType,
    hasDeadline: boolean | undefined
) {
    // If it's not a grocery warehouse then deadline stuff is not relevant
    if (locationType !== LocationType.DistributionWarehouse) {
        return undefined;
    }

    if (hasDeadline === undefined) {
        return `Required`;
    }
}

export function errorMessageForDeadline(
    pickupDate: string,
    hasDeadline: boolean | undefined,
    deadlineDate: string | undefined
): ErrorMessageType {
    if (!hasDeadline) {
        return undefined;
    }

    if (hasDeadline && deadlineDate === undefined) {
        return 'Required';
    }

    const pickupMoment = moment(pickupDate).startOf('day');
    const deadelineMoment = moment(deadlineDate).startOf('day');

    if (!deadelineMoment.isAfter(pickupMoment)) {
        return 'Deadline must be after pickup date';
    }

    return undefined;
}
