/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GenerateStripeIntentResponse,
  GetAddCreditCardAuthorizationIdResponse,
  GetAllPaymentMethodsResponse,
  PayInvoicesRequest,
  PayInvoicesResponse,
  RemovePaymentMethodRequest,
  SetDefaultPaymentMethodRequest,
} from '../models/index';
import {
    GenerateStripeIntentResponseFromJSON,
    GenerateStripeIntentResponseToJSON,
    GetAddCreditCardAuthorizationIdResponseFromJSON,
    GetAddCreditCardAuthorizationIdResponseToJSON,
    GetAllPaymentMethodsResponseFromJSON,
    GetAllPaymentMethodsResponseToJSON,
    PayInvoicesRequestFromJSON,
    PayInvoicesRequestToJSON,
    PayInvoicesResponseFromJSON,
    PayInvoicesResponseToJSON,
    RemovePaymentMethodRequestFromJSON,
    RemovePaymentMethodRequestToJSON,
    SetDefaultPaymentMethodRequestFromJSON,
    SetDefaultPaymentMethodRequestToJSON,
} from '../models/index';

export interface GetPaymentMethodsSwapAddCreditCardAuthorizationIdForSaveCardIntentRequest {
    addCreditCardAuthorizationId: string;
}

export interface PostPaymentMethodsPayInvoicesRequest {
    payInvoicesRequest?: PayInvoicesRequest;
}

export interface PostPaymentMethodsRemoveRequest {
    removePaymentMethodRequest?: RemovePaymentMethodRequest;
}

export interface PostPaymentMethodsSetDefaultPaymentMethodRequest {
    setDefaultPaymentMethodRequest?: SetDefaultPaymentMethodRequest;
}

/**
 * 
 */
export class PaymentMethodsApi extends runtime.BaseAPI {

    /**
     * Your GET endpoint
     */
    async getAllPaymentMethodsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAllPaymentMethodsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payment-methods/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAllPaymentMethodsResponseFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getAllPaymentMethods(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAllPaymentMethodsResponse> {
        const response = await this.getAllPaymentMethodsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gives an authenticated user access to the company\'s Add Credit Card Authorization Id. They can then construct a URL containing this token which can be sent to someone who does not have an account. Then that user can swap the token for a save card intent to add their credit card.
     * Your GET endpoint
     */
    async getCreditCardsGetAddCreditCardAuthorizationIdRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAddCreditCardAuthorizationIdResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payment-methods/get-add-credit-card-authorization-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAddCreditCardAuthorizationIdResponseFromJSON(jsonValue));
    }

    /**
     * Gives an authenticated user access to the company\'s Add Credit Card Authorization Id. They can then construct a URL containing this token which can be sent to someone who does not have an account. Then that user can swap the token for a save card intent to add their credit card.
     * Your GET endpoint
     */
    async getCreditCardsGetAddCreditCardAuthorizationId(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAddCreditCardAuthorizationIdResponse> {
        const response = await this.getCreditCardsGetAddCreditCardAuthorizationIdRaw(initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getPaymentMethodsSwapAddCreditCardAuthorizationIdForSaveCardIntentRaw(requestParameters: GetPaymentMethodsSwapAddCreditCardAuthorizationIdForSaveCardIntentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenerateStripeIntentResponse>> {
        if (requestParameters['addCreditCardAuthorizationId'] == null) {
            throw new runtime.RequiredError(
                'addCreditCardAuthorizationId',
                'Required parameter "addCreditCardAuthorizationId" was null or undefined when calling getPaymentMethodsSwapAddCreditCardAuthorizationIdForSaveCardIntent().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['addCreditCardAuthorizationId'] != null) {
            queryParameters['addCreditCardAuthorizationId'] = requestParameters['addCreditCardAuthorizationId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/payment-methods/swap-add-credit-card-authorization-id-for-save-card-intent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GenerateStripeIntentResponseFromJSON(jsonValue));
    }

    /**
     * Your GET endpoint
     */
    async getPaymentMethodsSwapAddCreditCardAuthorizationIdForSaveCardIntent(requestParameters: GetPaymentMethodsSwapAddCreditCardAuthorizationIdForSaveCardIntentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenerateStripeIntentResponse> {
        const response = await this.getPaymentMethodsSwapAddCreditCardAuthorizationIdForSaveCardIntentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generates an intent that be used to securely store credit card information
     * Generate Save Card Intent
     */
    async postGenerateSaveCardIntentRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenerateStripeIntentResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payment-methods/generate-save-card-intent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GenerateStripeIntentResponseFromJSON(jsonValue));
    }

    /**
     * Generates an intent that be used to securely store credit card information
     * Generate Save Card Intent
     */
    async postGenerateSaveCardIntent(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenerateStripeIntentResponse> {
        const response = await this.postGenerateSaveCardIntentRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     * 
     */
    async postPaymentMethodsGenerateSaveBankAccountIntentRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GenerateStripeIntentResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payment-methods/generate-save-bank-account-intent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GenerateStripeIntentResponseFromJSON(jsonValue));
    }

    /**
     * 
     * 
     */
    async postPaymentMethodsGenerateSaveBankAccountIntent(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GenerateStripeIntentResponse> {
        const response = await this.postPaymentMethodsGenerateSaveBankAccountIntentRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async postPaymentMethodsPayInvoicesRaw(requestParameters: PostPaymentMethodsPayInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PayInvoicesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payment-methods/pay-invoices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PayInvoicesRequestToJSON(requestParameters['payInvoicesRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PayInvoicesResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async postPaymentMethodsPayInvoices(requestParameters: PostPaymentMethodsPayInvoicesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PayInvoicesResponse> {
        const response = await this.postPaymentMethodsPayInvoicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async postPaymentMethodsRemoveRaw(requestParameters: PostPaymentMethodsRemoveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payment-methods/remove`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemovePaymentMethodRequestToJSON(requestParameters['removePaymentMethodRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postPaymentMethodsRemove(requestParameters: PostPaymentMethodsRemoveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postPaymentMethodsRemoveRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async postPaymentMethodsSetDefaultPaymentMethodRaw(requestParameters: PostPaymentMethodsSetDefaultPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payment-methods/set-default-payment-method`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetDefaultPaymentMethodRequestToJSON(requestParameters['setDefaultPaymentMethodRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async postPaymentMethodsSetDefaultPaymentMethod(requestParameters: PostPaymentMethodsSetDefaultPaymentMethodRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postPaymentMethodsSetDefaultPaymentMethodRaw(requestParameters, initOverrides);
    }

}
