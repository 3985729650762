/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LocationContext = {
    Pickup: 'pickup',
    Delivery: 'delivery',
    Any: 'any'
} as const;
export type LocationContext = typeof LocationContext[keyof typeof LocationContext];


export function instanceOfLocationContext(value: any): boolean {
    return Object.values(LocationContext).includes(value);
}

export function LocationContextFromJSON(json: any): LocationContext {
    return LocationContextFromJSONTyped(json, false);
}

export function LocationContextFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationContext {
    return json as LocationContext;
}

export function LocationContextToJSON(value?: LocationContext | null): any {
    return value as any;
}

