/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DocumentType } from './DocumentType';
import {
    DocumentTypeFromJSON,
    DocumentTypeFromJSONTyped,
    DocumentTypeToJSON,
} from './DocumentType';

/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    documentId: string;
    /**
     * 
     * @type {DocumentType}
     * @memberof Document
     */
    documentType: DocumentType;
    /**
     * 
     * @type {Date}
     * @memberof Document
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the Document interface.
 */
export function instanceOfDocument(value: object): boolean {
    if (!('documentId' in value)) return false;
    if (!('documentType' in value)) return false;
    if (!('createdAt' in value)) return false;
    return true;
}

export function DocumentFromJSON(json: any): Document {
    return DocumentFromJSONTyped(json, false);
}

export function DocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Document {
    if (json == null) {
        return json;
    }
    return {
        
        'documentId': json['documentId'],
        'documentType': DocumentTypeFromJSON(json['documentType']),
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function DocumentToJSON(value?: Document | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'documentId': value['documentId'],
        'documentType': DocumentTypeToJSON(value['documentType']),
        'createdAt': ((value['createdAt']).toISOString()),
    };
}

