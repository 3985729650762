/* tslint:disable */
/* eslint-disable */
/**
 * FreightSimple Public API
 * API that powers FreightSimple\'s SPA
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';
import type { LocationContext } from './LocationContext';
import {
    LocationContextFromJSON,
    LocationContextFromJSONTyped,
    LocationContextToJSON,
} from './LocationContext';

/**
 * 
 * @export
 * @interface SetContactRequest
 */
export interface SetContactRequest {
    /**
     * 
     * @type {string}
     * @memberof SetContactRequest
     */
    shipmentId: string;
    /**
     * 
     * @type {LocationContext}
     * @memberof SetContactRequest
     */
    locationContext: LocationContext;
    /**
     * 
     * @type {Contact}
     * @memberof SetContactRequest
     */
    contact: Contact;
}

/**
 * Check if a given object implements the SetContactRequest interface.
 */
export function instanceOfSetContactRequest(value: object): boolean {
    if (!('shipmentId' in value)) return false;
    if (!('locationContext' in value)) return false;
    if (!('contact' in value)) return false;
    return true;
}

export function SetContactRequestFromJSON(json: any): SetContactRequest {
    return SetContactRequestFromJSONTyped(json, false);
}

export function SetContactRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetContactRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'shipmentId': json['shipmentId'],
        'locationContext': LocationContextFromJSON(json['locationContext']),
        'contact': ContactFromJSON(json['contact']),
    };
}

export function SetContactRequestToJSON(value?: SetContactRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shipmentId': value['shipmentId'],
        'locationContext': LocationContextToJSON(value['locationContext']),
        'contact': ContactToJSON(value['contact']),
    };
}

