import { useMemo } from 'react';

export function useRandomCreateAccountBackgroundImage() {
    function randomBackgroundImage(): string {
        const array = [
            'createaccount1.jpg',
            'createaccount2.jpg',
            'createaccount3.jpg',
        ];
        const randomIndex = Math.floor(Math.random() * array.length);
        return array[randomIndex];
    }

    const image = useMemo(() => randomBackgroundImage(), []);
    return `url(/create-account-images/${image})`;
}

export function useRandomSigninBackgroundImage() {
    function randomBackgroundImage(): string {
        const array = [
            'signin1.jpg',
            'signin2.jpg',
            'signin3.jpg',
            'signin4.jpg',
            'signin5.jpg',
            'signin6.jpg',
            'signin7.jpg',
            'signin8.jpg',
            'signin9.jpg',
            'signin10.jpg',
        ];
        const randomIndex = Math.floor(Math.random() * array.length);
        return array[randomIndex];
    }

    const image = useMemo(() => randomBackgroundImage(), []);
    return `url(/signin-images/${image})`;
}
