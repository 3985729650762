import React, { ReactNode, useEffect, useRef, useState } from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import styled from 'styled-components/macro';
import Input, { InputProps } from './Input';

type AutoCompleteInputProps = InputProps & {
    autoCompleteOptions: Array<string>;
};

const Menu = styled.ul`
    padding: 0;
    margin: 0;
    overflow-y: scroll;
    max-height: 290px; /* 8.5 times the height of a single row, so we display 8 rows and show enough of the 9th so it is obvious that we scroll */
    overflow-x: clip;
    width: 480px;
    box-shadow: 6px 6px 0px 0px #f5f5f5;
    border: 1px solid #bbb;
    background-color: white;
    position: absolute;
    z-index: 1000;
`;

interface MenuItemProps {
    selected: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MenuItem = styled(({ selected, ...rest }) => (
    <li {...rest} />
))<MenuItemProps>`
    color: var(--freightsimple-color-normal-text);
    list-style-type: none;
    padding: 4px 8px;
    margin: 0;
    font-size: 18px;

    ${(props) =>
        props.selected
            ? `
            background-color: var(--freightsimple-color-blue);
            color:white;
            `
            : ``}
`;

interface MenuItemHolderProps {
    selected: boolean;
    children: ReactNode;
    onMouseDown: () => void;
    onMouseEnter: () => void;
}

function MenuItemHolder(props: MenuItemHolderProps) {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setTimeout(() => {
            if (props.selected && ref.current) {
                scrollIntoView(ref.current, {
                    scrollMode: 'if-needed',
                    block: 'nearest',
                    inline: 'nearest',
                });
            }
        });
    });

    return (
        <div ref={ref}>
            <MenuItem
                selected={props.selected}
                onMouseDown={props.onMouseDown}
                onMouseEnter={props.onMouseEnter}
            >
                {props.children}
            </MenuItem>
        </div>
    );
}

function AutoCompleteInput(props: AutoCompleteInputProps) {
    const { autoCompleteOptions, onChange, ...inputProps } = props;
    const [showMenu, setShowMenu] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [textChanged, setTextChanged] = useState(false);

    function onFocus() {
        if (autoCompleteOptions.length > 1) {
            setShowMenu(true);
        }
    }

    function onBlur() {
        setTextChanged(false);
        setShowMenu(false);
    }

    function doFilter(): Array<string> {
        const value = props.value;
        if (value === undefined || props.value === '' || !textChanged) {
            return autoCompleteOptions;
        }

        let filtered = autoCompleteOptions.filter((option) =>
            option.includes(value.toString())
        );

        if (filtered.length === 1 && filtered[0] === value) {
            return [];
        }

        // Defensive. Just in case this list is massive. Don't show more than 100
        filtered = filtered.slice(0, 100);

        return filtered;
    }

    const itemsToShow = doFilter();

    function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'ArrowDown') {
            const newSelectedIndex = Math.min(
                selectedIndex + 1,
                itemsToShow.length - 1
            );

            setSelectedIndex(newSelectedIndex);
        }

        if (e.key === 'ArrowUp') {
            const newSelectedIndex = Math.max(0, selectedIndex - 1);
            setSelectedIndex(newSelectedIndex);
        }

        if (e.key === 'Enter') {
            props.onChange?.(itemsToShow[selectedIndex], undefined);
            onBlur();
        }
    }

    function onChangeWrapper(
        newValue: string,
        e: React.FormEvent<HTMLInputElement> | undefined
    ) {
        setTextChanged(true);
        setShowMenu(true);
        if (onChange !== undefined) {
            onChange(newValue, e);
        }
    }

    return (
        <>
            <div>
                <div>
                    <Input
                        {...inputProps}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        onChange={onChangeWrapper}
                    />
                </div>
                {showMenu && itemsToShow.length > 0 && (
                    <Menu>
                        {itemsToShow.map((item, index) => {
                            return (
                                <MenuItemHolder
                                    onMouseDown={() => {
                                        props.onChange?.(item, undefined);
                                    }}
                                    onMouseEnter={() => {
                                        setSelectedIndex(index);
                                    }}
                                    selected={index === selectedIndex}
                                    key={index}
                                >
                                    {item}
                                </MenuItemHolder>
                            );
                        })}
                    </Menu>
                )}
            </div>
        </>
    );
}
export default AutoCompleteInput;
