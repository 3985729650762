import {
    QuestionBubbleHeader,
    QuestionBubbleLabel,
    SmallQuestionBubble,
} from 'Cargo/Controls/QuestionBubble';
import React from 'react';

export function NumberPackagesQuestionBubble() {
    return (
        <SmallQuestionBubble
            description={
                <>
                    <QuestionBubbleHeader>Package Count</QuestionBubbleHeader>
                    <QuestionBubbleLabel>
                        <div>
                            A carrier requires to know how a dangerous item is
                            packaged. Please enter the number of packages within
                            each handling unit
                        </div>
                        <div>
                            eg. If you are shipping a crate containing 50 bags,
                            enter 50. If you are shipping a drum that is not
                            further subdivided, enter 1
                        </div>
                    </QuestionBubbleLabel>
                </>
            }
        />
    );
}
