import { Page } from 'Cargo/Layout/Page';
import BankAccountsScreen from 'Features/BankAccounts/BankAccountsScreen';
import ShipmentBookedScreen from 'Features/BookShipment/ShipmentBookedScreen';
import BrokersScreen from 'Features/Brokers/BrokersScreen';
import { SubmitClaimScreen } from 'Features/Claims/SubmitClaimScreen';
import CreditCardsScreen from 'Features/CreditCards/CreditCardsScreen';
import ViewInvoicesScreen from 'Features/Invoices/ViewInvoicesScreen';
import AddressBookScreen from 'Features/Locations/AddressBookScreen';
import PaymentsScreen from 'Features/Payments/PaymentsScreen';
import { SavedCommoditiesScreen } from 'Features/SavedCommodities/SavedCommoditiesScreen';
import ViewCompanyScreen from 'Features/ViewCompany/ViewCompanyScreen';
import ViewAllShipmentsScreen from 'Features/ViewShipments/ViewAllShipmentsScreen';
import ViewShipmentScreen from 'Features/ViewShipments/ViewShipmentScreen';
import DashboardScreen from './DashboardScreen';
import QuotesScreen from './QuotesScreen';

function DashboardRouter() {
    return (
        <>
            <Page exact path="/">
                {() => <DashboardScreen />}
            </Page>

            <Page exact path="/quotes">
                {() => <QuotesScreen />}
            </Page>

            <Page exact path="/view-all-shipments">
                {() => <ViewAllShipmentsScreen />}
            </Page>

            <Page exact path="/address-book">
                {() => <AddressBookScreen />}
            </Page>
            <Page exact path="/brokers">
                {() => <BrokersScreen />}
            </Page>

            <Page exact path="/saved-commodities">
                {() => <SavedCommoditiesScreen />}
            </Page>

            <Page exact path="/credit-cards">
                {() => <CreditCardsScreen />}
            </Page>

            <Page exact path="/payments">
                {() => <PaymentsScreen />}
            </Page>

            <Page exact path="/bank-accounts">
                {() => <BankAccountsScreen />}
            </Page>

            <Page exact path="/manage-users">
                {() => <ViewCompanyScreen />}
            </Page>

            <Page exact path="/view-invoices">
                {() => <ViewInvoicesScreen />}
            </Page>

            <Page exact path="/view-shipment">
                {() => <ViewShipmentScreen />}
            </Page>

            <Page exact path="/submit-claim">
                {() => <SubmitClaimScreen />}
            </Page>

            <Page exact path="/booking-success">
                {() => <ShipmentBookedScreen />}
            </Page>
        </>
    );
}
export default DashboardRouter;
