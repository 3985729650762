import { isNotEmptyOrUndefined } from 'Helpers/isNotEmptyOrUndefined';
import { useRandomCreateAccountBackgroundImage } from 'Helpers/randomBackgroundImage';
import { useOnce } from 'Hooks/useOnce';
import useQuery from 'Hooks/useQuery';
import { useUsersApi2 } from 'apis';
import { useNavigate } from 'react-router-dom';
import { CreateAccountForm2 } from './Components/CreateAccountForm2';
import { AuthenticationScreenContainer } from './AuthenticationScreenContainer';

export function CreateAccountScreen() {
    const navigate = useNavigate();
    const query = useQuery();
    const usersApi = useUsersApi2();

    const prefilledEmail = query.email as string | undefined;
    const prefilledName = query.name as string | undefined;
    const prefilledCode = query.code as string | undefined;

    const backgroundImage = useRandomCreateAccountBackgroundImage();

    useOnce(async function () {
        if (
            isNotEmptyOrUndefined(prefilledEmail) &&
            isNotEmptyOrUndefined(prefilledName)
        ) {
            console.log(`Sending pre-filled info`, {
                prefilledEmail,
                prefilledName,
            });
            try {
                usersApi.postUsersSharePrefilledInfo({
                    email: prefilledEmail as string,
                    name: prefilledName as string,
                });
            } catch (e) {
                console.error(`Error with postUsersSharePrefilledInfo`, { e });
            }
        }
    });

    function onSignInInstead() {
        navigate('/sign-in');
    }

    function onSuccess() {
        navigate('/');
    }


    return (
        <AuthenticationScreenContainer image={backgroundImage}>
            <CreateAccountForm2
                onSignInInstead={onSignInInstead}
                onSuccess={onSuccess}
                prefilledName={prefilledName}
                prefilledCode={prefilledCode}
                prefilledEmail={prefilledEmail}
            />
        </AuthenticationScreenContainer>
    );
}
