import Dropdown from 'Cargo/Controls/Dropdown';
import { FreightClaimItemCondition } from 'generated-openapi-client/models/FreightClaimItemCondition';

interface ItemConditionDropdownProps {
    itemCondition: FreightClaimItemCondition;
    setItemCondition: (_: FreightClaimItemCondition) => void;
}

export function ItemConditionDropdown(props: ItemConditionDropdownProps) {
    const { itemCondition, setItemCondition } = props;
    return (
        <Dropdown<FreightClaimItemCondition>
            selectedItem={itemCondition}
            onOptionSelected={setItemCondition}
            options={[
                {
                    item: FreightClaimItemCondition.New,
                    description: 'New',
                },
                {
                    item: FreightClaimItemCondition.Used,
                    description: 'Used',
                },
            ]}
            width={200}
            errorMessage={undefined}
            forceValidation={false}
            placeholder="Prior to shipping the items were"
        ></Dropdown>
    );
}
