import HorizontalStack from 'Cargo/Layout/HorizontalStack';
import { useAuthentication } from 'Features/Authentication/Slices/authenticationSlice';
import { PotentialCoupon } from 'Features/Coupons/Components/PotentialCoupon';
import { NavBarInvoiceWarning } from 'Features/Invoices/Components/NavBarInvoiceWarning';
import UserDetailsDropdown from './UserDetailsDropdown';

// eslint-disable-next-line local-rules/no-import-bootstrap
function SignedInRightNavMenu() {
    const { user } = useAuthentication();

    const userName = user?.name ?? '';
    const companyName = user?.companyName ?? '';

    return (
        <>
            <HorizontalStack>
                <PotentialCoupon />
                <NavBarInvoiceWarning />
                <UserDetailsDropdown
                    name={userName}
                    company={companyName}
                ></UserDetailsDropdown>
            </HorizontalStack>
        </>
    );
}
export default SignedInRightNavMenu;
